import { each, includes, isArray, find } from 'lodash';
import { sendMessage, waitingForMessage } from '../../../../common/messaging';
import Adcept from './calculations_per_project/calculators/adcept';
import BrandSnapshot from './calculations_per_project/calculators/brand_snapshot';
import ConceptsTest from './calculations_per_project/calculators/concepts_test';
import Naming from './calculations_per_project/calculators/naming';
import Package from './calculations_per_project/calculators/package';
import PackageMc from './calculations_per_project/calculators/package_mc';
import Swipe from './calculations_per_project/calculators/swipe';
import Claim from './calculations_per_project/calculators/claim';
import Flavor from './calculations_per_project/calculators/flavor';

const calculationService = (projectData, priceData, size) => {
  let service;

  switch (projectData.express) {
    case 'adcept':
      service = new Adcept(projectData, priceData, size);
      break;
    case 'brand_snapshot':
      service = new BrandSnapshot(projectData, priceData, size);
      break;
    case 'concepts_test':
      service = new ConceptsTest(projectData, priceData, size);
      break;
    case 'flavor':
      service = new Flavor(projectData, priceData, size).findService();
      break;
    case 'naming':
      service = new Naming(projectData, priceData, size);
      break;
    case 'package':
      service = new Package(projectData, priceData, size);
      break;
    case 'package_mc':
      service = new PackageMc(projectData, priceData, size);
      break;
    case 'swipe':
      service = new Swipe(projectData, priceData, size);
      break;
    case 'claim':
      service = new Claim(projectData, priceData, size);
      break;
    default:
      service = new ConceptsTest(projectData, priceData, size);
  }
  return service;
};

const calculatePrice = (projectData, priceData, size) => (
  calculationService(projectData, priceData, size).totalPrice()
);

const messageKindOnSizeChange = 'Express Admin Update Price';
const sendMessageOnSizeChange = (nsize) => {
  sendMessage({ kind: messageKindOnSizeChange, nsize });
};
const waitingMessageOnSizeChange = (callback) => (
  waitingForMessage((event) => {
    if (event.data.kind === messageKindOnSizeChange) {
      callback(event.data.nsize, event);
    }
  })
);

const convertFormDataToText = (inputFormElement) => {
  const formData = new FormData(inputFormElement);
  const textArr = [];
  for (const [ key, value ] of formData) {
    textArr.push(`${key}: ${value}`);
  }
  return textArr.join("\n\n");
};

const generateFlatHash = (searchHash, keysToFind, resultHash) => {
  each(searchHash, (value, key) => {
    if (includes(keysToFind, key)) {
      resultHash[key] = value;
    } else if (typeof value === 'object' && !isArray(value)) {
      generateFlatHash(value, keysToFind, resultHash);
    }
  });
};


const convertFormDataToHash = (projectDataKeys, formId) => {
  const formElement = document.querySelector(`#${formId}`);
  const formData = new FormData(formElement);
  const result = {};
  const separators = {};
  // Generating hash from the formData
  for (const [ key, value ] of formData) {
    const keyNoRightBracket = key.replace(/]/g, '');
    const innerKeys = keyNoRightBracket.split('[');
    let hashKey = result;
    each(innerKeys, (innerKey, index) => {
      if (innerKey) {
        if (index === innerKeys.length - 1) {
          hashKey[innerKey] = value;
        } else {
          const arrayKey = ((index < innerKeys.length - 1) && !innerKeys[index + 1]);
          const keyArrayOfHashes = ((index < innerKeys.length - 2) && innerKeys[index + 2]);
          if (!separators[innerKey] && arrayKey && keyArrayOfHashes) {
            separators[innerKey] = keyArrayOfHashes;
          }
          if (!hashKey[innerKey]) {
            hashKey[innerKey] = arrayKey ? [] : {};
          }
          hashKey = hashKey[innerKey];
          if (arrayKey) {
            if (keyArrayOfHashes) {
              if (!hashKey.length || separators[innerKey] === keyArrayOfHashes) {
                hashKey.push({});
              }
              hashKey = hashKey[hashKey.length - 1];
            }
          }
        }
      } else if (index === innerKeys.length - 1) {
        hashKey.push(value);
      }
    });
  }
  // Generating of a flat hash to exclude unneeded root keys.
  const finalResult = {};
  generateFlatHash(result, projectDataKeys, finalResult);
  return finalResult;
};
const messageKindOnFormChange = "Admin Express Form Changed";
const sendMessageOnFormChange = (formId) => {
  sendMessage({ kind: messageKindOnFormChange, formId });
};
const waitingMessageOnFormChange = (projectDataKeys, callback) => (
  waitingForMessage((event) => {
    if (event.data.kind === messageKindOnFormChange) {
      const formHash = convertFormDataToHash(projectDataKeys, event.data.formId);
      callback(formHash, event);
    }
  })
);

const smartUpdateProjectsData = (currentData, hashWithUpdates = {}) => {
  const result = { ...currentData };
  each(hashWithUpdates, (value, key) => {
    let needUpdate = true;
    if (isArray(value) && !value.length) {
      // We should ignore the case when we get the blank array while at least one existing item
      // has an existing id, e.g. it is present in the database and to destroy it,
      // they should still be present with the _destroy=true pair, e.g. the list is wrong.
      needUpdate = !find(result[key] || [], (item) => item.id);
    }
    if (needUpdate) {
      result[key] = value;
    }
  });
  return result;
};

export {
  sendMessageOnSizeChange, waitingMessageOnSizeChange,
  sendMessageOnFormChange, waitingMessageOnFormChange,
  calculatePrice, convertFormDataToText,
  smartUpdateProjectsData, calculationService
};
