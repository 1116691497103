import React, { useEffect, useState } from "react";

const Notification = ({ status = "success", delay, icon, showClose, children }) => {
  const [ hide, setHide ] = useState(false);

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setHide(true);
      }, delay);
    }
  }, []);

  return (
    !hide && (
      <div className={ `notification -${status}` } onClick={ () => {setHide(true);} }>
        <div className="notification_grid">
          {!!icon && (
            <div className="notification_icon">
              <svg className={ `notification_svg -${icon}` }>
                <use xlinkHref={ `#svg_${icon}` } />
              </svg>
            </div>
          )}
          <div className="notification_content">
            { children }
          </div>
          {!!showClose && (
            <div className="notification_close">
              <svg className={ `notification_svg -close` }>
                <use xlinkHref={ `#svg_close` } />
              </svg>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Notification;

