const expressStepsHash = (baseUrl) => {
  const stepsUrl =  [ baseUrl, "steps" ].join("/");
  const fullSizeUrl = [ stepsUrl, "full_size" ].join("/");
  const fullSizeSwipeUrl = [ fullSizeUrl, "swipe" ].join("/");
  const fullSizeClaimUrl = [ fullSizeUrl, "claim" ].join("/");
  const fullSizeConceptsTestUrl = [ fullSizeUrl, "concepts-test" ].join("/");
  const fullSizeBaseUrl = [ fullSizeUrl, "base" ].join("/");
  const adceptUrl = [ stepsUrl, "adcept" ].join("/");
  const claimUrl = [ stepsUrl, "claim" ].join("/");
  const conceptsTestUrl = [ stepsUrl, "concepts-test" ].join("/");
  const conceptsTestTooltipsUrl = [ conceptsTestUrl, "tooltips" ].join("/");
  const flavorUrl = [ stepsUrl, "flavor" ].join("/");
  const flavorTooltipsUrl = [ flavorUrl, "tooltips" ].join("/");
  const namingUrl = [ stepsUrl, "naming" ].join("/");
  const namingTooltipsUrl = [ namingUrl, "tooltips" ].join("/");
  const packageUrl = [ stepsUrl, "package" ].join("/");
  const packageTooltipsUrl = [ packageUrl, "tooltips" ].join("/");
  const baseImagesUrl = [ stepsUrl, "base" ].join("/");
  const brandSnapshotUrl = [ stepsUrl, "brand_snapshot" ].join("/");
  return {
    fullSize: {
      swipe: {
        fivePointScale: `${fullSizeSwipeUrl}/five-point-scale.png`,
        markupPositive: `${fullSizeSwipeUrl}/markup-positive.png`,
        markupNegative: `${fullSizeSwipeUrl}/markup-negative.png`,
        swipeAction: `${fullSizeSwipeUrl}/swipe-action.png`,
        attributeRatings: `${fullSizeSwipeUrl}/attribute-ratings.png`
      },
      claim: {
        navExample3: `${fullSizeClaimUrl}/nav-example3.png`
      },
      conceptsTest: {
        attributeAgreement: `${fullSizeConceptsTestUrl}/attribute-agreement.png`,
        conceptFocus: `${fullSizeConceptsTestUrl}/concept_focus.png`,
        preference: `${fullSizeConceptsTestUrl}/preference.png`,
        thoughtBubble: `${fullSizeConceptsTestUrl}/thought_bubble.png`
      },
      base: {
        expressVariant1: `${fullSizeBaseUrl}/express-variant-1.png`,
        expressVariant2: `${fullSizeBaseUrl}/express-variant-2.png`,
        expressVariant3: `${fullSizeBaseUrl}/express-variant-3.png`,
        expressVariant4: `${fullSizeBaseUrl}/express-variant-4.png`,
        introConceptVar1: `${fullSizeBaseUrl}/intro-concept-var-1.png`,
        introConceptVar2: `${fullSizeBaseUrl}/intro-concept-var-2.png`,
        navExample1: `${fullSizeBaseUrl}/nav-example-1.png`,
        navExample2: `${fullSizeBaseUrl}/nav-example-2.png`,
        navExample3: `${fullSizeBaseUrl}/nav-example-3.png`
      }
    },
    base: {
      keyMetrics: `${baseImagesUrl}/key_metrics.png`,
      screenerQuestion: `${baseImagesUrl}/screener_question.png`,
      step12x: `${baseImagesUrl}/step-1-2x.png`,
      step32x: `${baseImagesUrl}/step-3-2x.png`,
      step52x: `${baseImagesUrl}/step-5-2x.png`,
      step62x: `${baseImagesUrl}/step-6-2x.png`,
      step72x: `${baseImagesUrl}/step-7-2x.png`
    },
    adcept: {
      addAds: `${adceptUrl}/add-ads.png`,
      conceptFocus: `${adceptUrl}/concept-focus.png`,
      preference: `${adceptUrl}/preference.png`,
      thoughtBubble: `${adceptUrl}/thought-bubble.png`,
      videoAd: `${adceptUrl}/video-ad.png`
    },
    claim: {
      conceptFocus: `${claimUrl}/concept-focus.png`,
      maxDiff: `${claimUrl}/max-diff.png`,
      testedClaims: `${claimUrl}/tested-claims.png`,
      thoughtBubble: `${claimUrl}/thought-bubble.png`,
      navExample3: `${claimUrl}/nav-example-3.png`
    },
    conceptsTest: {
      conceptFocus: `${conceptsTestUrl}/concept-focus.png`,
      preference: `${conceptsTestUrl}/preference.png`,
      questionType1: `${conceptsTestUrl}/question-type-1.png`,
      questionType2: `${conceptsTestUrl}/question-type-2.png`,
      thoughtBubble: `${conceptsTestUrl}/thought-bubble.png`,
      tooltips: {
        attributeRatings: `${conceptsTestTooltipsUrl}/attribute-ratings.png`,
        conceptFocus: `${conceptsTestTooltipsUrl}/concept-focus.png`,
        fivePointScale: `${conceptsTestTooltipsUrl}/five-point-scale.png`,
        preference: `${conceptsTestTooltipsUrl}/preference.png`,
        thoughtBubble: `${conceptsTestTooltipsUrl}/thought-bubble.png`
      }
    },
    flavor: {
      conceptFocus: `${flavorUrl}/concept-focus.png`,
      flavor: `${flavorUrl}/flavor.png`,
      mode: `${flavorUrl}/mode.png`,
      preference: `${flavorUrl}/preference.png`,
      thoughtBubble: `${flavorUrl}/thought-bubble.png`,
      tooltips: {
        attributeRatings: `${flavorTooltipsUrl}/attribute-ratings.png`,
        fivePointScale: `${flavorTooltipsUrl}/five-point-scale.png`,
        preference: `${flavorTooltipsUrl}/preference.png`,
        thoughtBubble: `${flavorTooltipsUrl}/thought-bubble.png`
      }
    },
    naming: {
      names: `${namingUrl}/names.png`,
      preference: `${namingUrl}/preference.png`,
      questionType1: `${namingUrl}/question-type-1.png`,
      questionType2: `${namingUrl}/question-type-2.png`,
      thoughtBubble: `${namingUrl}/thought-bubble.png`,
      tooltips: {
        fivePointScale: `${namingTooltipsUrl}/five-point-scale.png`,
        nameAttributeAgreement: `${namingTooltipsUrl}/name-attribute-agreement.png`,
        preference: `${namingTooltipsUrl}/preference.png`,
        thoughtBubble: `${namingTooltipsUrl}/thought-bubble.png`
      }
    },
    package: {
      addPackage: `${packageUrl}/add-package.png`,
      conceptFocus: `${packageUrl}/concept-focus.png`,
      mode: `${packageUrl}/mode.png`,
      preference: `${packageUrl}/preference.png`,
      shelfShot: `${packageUrl}/shelf-shot.png`,
      tooltips: {
        conceptFocus: `${packageTooltipsUrl}/concept-focus.png`,
        attributeRatings: `${packageTooltipsUrl}/attribute-ratings.png`,
        fivePointScale: `${packageTooltipsUrl}/five-point-scale.png`,
        preference: `${packageTooltipsUrl}/preference.png`
      }
    },
    brandSnapshot: {
      brandDetails: `${brandSnapshotUrl}/brand-details.png`,
      brandEvaluation: `${brandSnapshotUrl}/brand-evaluation.png`,
      brandScreening: `${brandSnapshotUrl}/brand-screening.png`,
      brandScreening41: `${brandSnapshotUrl}/brand-screening-41.png`,
      brandScreening42: `${brandSnapshotUrl}/brand-screening-42.png`,
      brandScreening43: `${brandSnapshotUrl}/brand-screening-43.png`,
      brandScreening44: `${brandSnapshotUrl}/brand-screening-44.png`,
      brandScreening45: `${brandSnapshotUrl}/brand-screening-45.png`,
      categoryEvaluation: `${brandSnapshotUrl}/category-evaluation.png`,
      customQuestions: `${brandSnapshotUrl}/custom-questions.png`,
      details51: `${brandSnapshotUrl}/details-51.png`,
      details52: `${brandSnapshotUrl}/details-52.png`,
      details53: `${brandSnapshotUrl}/details-53.png`,
      diagnostics: `${brandSnapshotUrl}/diagnostics.png`,
      brandDetailsHigh: `${brandSnapshotUrl}/brand_details_high.png`,
      brandScreeningHigh: `${brandSnapshotUrl}/brand_screening_high.png`,
      categoryEvaluationHigh: `${brandSnapshotUrl}/category_evaluation_high.png`,
      brandEvaluationHigh: `${brandSnapshotUrl}/brand_evaluation_high.png`,
      thoughtBubbleHigh: `${brandSnapshotUrl}/thought_bubble_high.png`,
      customQuestionsHigh: `${brandSnapshotUrl}/custom_questions_high.png`
    }
  };
};

export default expressStepsHash;
