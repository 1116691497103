import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import StartButton from './start_button.js';

const Intro = ({ nextStep, task }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        <h3 className="survey-start_title" { ...renderRawHtml(task) } />
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default Intro;
