import React, { Fragment, useContext } from 'react';
import { map } from 'lodash';
import renderRawHtml from "../../../../../common/render_raw_html";

const SharedPlan = ({ planObject }) => {
  return (
    <div className="shared-plan" >
      <h2 className="-big shared-plan-title" { ...renderRawHtml(planObject.title) } >
      </h2>
      <div className="text-14 -concept-a text-description" { ...renderRawHtml(planObject.description) } >
      </div>
      <div className="features">
        {
          map(planObject.features, (feature, index) => {
            return(
              <div
                key={`sharedPlan${planObject.id}feature${index}`}
                className="text-14 -concept-a feature" { ...renderRawHtml(feature.title) }>
              </div>
            )
          })
        }
      </div>
      <div className="dollar-price">
        <h2 className="value -font-40">${ planObject.price }</h2>
        <div className="text-10 text ">
          Total Account cost per month.
          <br/>
          Account access is $30/mo. Each phone line is $25/mo with Auto Pay and paper-free billing discount or $35/mo without.
          <br/>
          Plus taxes & fees.
        </div>
      </div>
    </div>
  )
};

const SharedPart = ({ list, onClick }) => {
  return (
    <Fragment>
      <h2 className="main-title">Shared data plans</h2>
      <div className="base-plans-header">
        <h2 className="-big base-plans-title">Get the perfect amount of data.</h2>
        <div className="text-16 -concept-a base-plans-description">
          Pick a data plan for you or
          <br/>
          share data with your family.
          <br/>
          Shop phones to get started.
        </div>
        <button className="select-billing-plan get-started" onClick={onClick}>Get started</button>
      </div>
      <div className="split-plans -shared">
        <div className="shared-right-area">
          <div className="shared-plans">
            {
              map(list, (item) => {
                return (
                  <SharedPlan key={`sharedPlan${item.id}`} planObject={item}  />
                )
              })
            }
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default SharedPart;