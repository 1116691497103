import React, {useContext, useRef, useState, createRef, useEffect} from "react";
import SvgIcon from "../../../../../common/components/svg-icon";
import GroupedComments from "./grouped-comments";
import GroupedSelectedComments from "./grouped-selected-comments";
import {MarkupData} from "./markups-logic";

const MarkupsComments = ({}) => {
  const {
    hasSelectedComments, anyCommentVisible, anyHighlightedId, toggleHighlight, moveHighlight, deselectComments,
    toggleAllRespondents, checkAllRespondentsHidden
  } = useContext(MarkupData)

  const [hideAll, setHideAll] = useState(checkAllRespondentsHidden())

  const onClearHighlight = ()=>{
    toggleHighlight(null)
  }

  const onKeyDown = ($event) => {
    if($event.key == 'ArrowDown' || $event.key == 'ArrowUp' || $event.key == 'Down' || $event.key == 'Up'){
      $event.preventDefault();
      moveHighlight($event.key == 'ArrowUp' || $event.key == 'Up');
    }
  }

  const $container = useRef(null)
  let $selected = createRef()

  useEffect(()=>{
    if($selected.current) {
      let delta = $selected.current.offsetTop - $container.current.offsetTop,
        rowHeight = $selected.current.offsetHeight,
        windowHeight = $container.current.offsetHeight,
        scrollPos = $container.current.scrollTop;

      if (delta + rowHeight > scrollPos + windowHeight) {
        $container.current.scroll({top: delta - windowHeight + 1.5 * rowHeight, behavior: 'smooth'})
      }

      if (delta < scrollPos) {
        $container.current.scroll({top: delta - 0.5 * rowHeight, behavior: 'smooth'})
      }
    }
  },[$selected])

  const onToggleAllClick = () => {
    toggleAllRespondents(hideAll);
    setHideAll(!hideAll)
  }

  const onDeselectComments = (event) => {
    deselectComments();
  }

  return (
      <div className="markups_comments">
        <div className="summary-markup_comment -global">
          <div className="summary-markup_comment_title">All</div>
          {!!anyHighlightedId && <span className="summary-markup_comment_action -clear-highlight"
                onClick={onClearHighlight}
                role="button"
                title="Clear Highlight">
            Clear Highlight
          </span>}

          {anyCommentVisible() && <span title="Toggle All"
                                        className="summary-markup_comment_action icon-link -no-text -toggle -help-on -help-top"
                                        onClick={onToggleAllClick}
                                        role="button">
            {!hideAll && <SvgIcon className="icon-link_icon" name="eye-open"/>}
            {hideAll && <SvgIcon className="icon-link_icon" name="eye-closed"/>}
            <span className="icon-link_text">Toggle All</span>
          </span>}

          <span className="-help -margin-top -right">
            <span className="inner">
              Hide/Show a respondent and/or individual comment from the individual markups.
            </span>
          </span>
        </div>

        <div className="summary-markup_comments"
             ref={$container}
             onKeyDown={onKeyDown}
             tabIndex="0">
          {hasSelectedComments() && <div className="summary-markup_comment -group">
            <div className="summary-markup_comment_title">
              Selected
            </div>
            <span className="summary-markup_comment_action icon-link -no-text"
                  onClick={onDeselectComments}
                  role="button"
                  title="Deselect">
              <SvgIcon className="icon-link_icon" name="i-remove-round"/>
            </span>
          </div>}

          <GroupedSelectedComments selRef={$selected}/>

          {hasSelectedComments() && <div className="summary-markup_comment -group">
            <div className="summary-markup_comment_title">
              Others
            </div>
          </div>}

          <GroupedComments selRef={$selected}/>
        </div>
      </div>
  )
}

export default MarkupsComments;