import React from "react";
import ReactGA4 from "react-ga4";
import { includes } from 'lodash';

const initializeGoogleAnalytics = (trackingId) => {
  if (!trackingId) {
    return;
  }
  const page = window.location.pathname;
  const platform = includes(page, "express") || includes(page, "survey") ? "express" : "other";
  ReactGA4.initialize(
    trackingId, {
      gaOptions: { platform }
    }
  );
};

const trackGoogleAnalyticsEvent = (
  trackingId,
  category,
  event_name,
  label,
  data
) => {
  if (!trackingId) {
    return;
  }
  ReactGA4.initialize(trackingId);

  const event_params = {
    category,
    label,
    ...data
  };
  // Send GA4 Event
  ReactGA4.event(event_name, event_params);
};

export { trackGoogleAnalyticsEvent, initializeGoogleAnalytics };
