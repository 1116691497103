import React, { useState } from 'react';
import { filter, fromPairs, map } from "lodash";
import cn from "classnames";
import UploadButton from "../../../../../common/components/upload_button";
import ImageInPopup from "../../../../../common/components/image_in_popup";

const Image = ({ question, index, gridItems, setGridItems, screenerQuestions, setScreenerQuestions, fivePtScaleMode }) => {
  const [ uploading, setUploading ] = useState(false);

  const gridItemsToAnswersImages = (inputData) => {
    const data = inputData || gridItems;
    return fromPairs(map(filter(data, (el) => el.imageData), (el) => ([ el.id, { meta_data: el.imageData, url: el.imageSrc } ])));
  };

  const handleOnChangeEditableGridItemImage = (newData, index) => {
    gridItems[index].imageData = newData.metaData;
    gridItems[index].imageSrc = newData.url;
    setGridItems([ ...gridItems ]);
    handleChangeAnswersImages(question, gridItemsToAnswersImages());
  };

  const handleChangeAnswersImages = (q, imageData) => {
    q.answer_images = imageData;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onInitUploading = () => {
    setUploading(true);
  };

  const onUploaded = (files, index) => {
    const [ file ] = files;
    const { cacheSrc: url, metaData } = file;
    handleOnChangeEditableGridItemImage({ url, metaData }, index);
    setUploading(false);
  };

  const onImageDelete = (index) => {
    gridItems[index].imageData = null;
    gridItems[index].imageSrc = null;
    setGridItems([ ...gridItems ]);
    handleChangeAnswersImages(question, gridItemsToAnswersImages());
  };

  return (
    <>
      {
        !gridItems[index]?.imageData && !fivePtScaleMode &&
        <div className="file-uploader">
          <div className="form-flavor_image -no-image -with-margins -grid">
            <UploadButton
              className="link -delete"
              allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
              onUploaded={ (newData) => { onUploaded(newData, index); } }
              onInit={ onInitUploading }
            >
              <svg className="icon -no-image">
                <use xlinkHref="#svg_i-no-image" />
              </svg>
            </UploadButton>
          </div>
        </div>
      }
      {
        gridItems[index]?.imageData && !fivePtScaleMode &&
        <div className="file-uploader">
          <div className={ cn("form-flavor_image -with-margins", { "-content-flex-center": uploading }) }>
            <UploadButton
              className="link -delete"
              allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
              onUploaded={ (newData) => { onUploaded(newData, index); } }
              onInit={ onInitUploading }
            >
              <ImageInPopup
                className="-pointer"
                customZIndex={ 1062 }
                src={ gridItems[index].imageSrc }
              >
                <img className="-pointer" src={ gridItems[index].imageSrc } alt={ "text" } />
              </ImageInPopup>
            </UploadButton>
            <div className="form-flavor_image_delete">
              <button onClick={ () => { onImageDelete(index); } } className="link -delete">
                <svg className="icon-link_icon icon -i-delete">
                  <use xlinkHref="#svg_i-delete" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};


export default Image;
