import React from "react";
import cn from "classnames";

const Checkbox = ({ label, bool, onChange, className }) => {
  return (
    <div className="checkbox">
      <label className={ cn("checkbox_label", className, { '-checked': bool }) }>
        <input
          type="checkbox"
          checked={ bool }
          onChange={ (event) => { onChange(event.target.checked); } }
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
