import React from 'react';

const GreyStripe = ({ label, value, onClick }) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  };
  return (
    <div className="grey-stripe">
      { !!label && <div className="label">{ label }</div> }
      <div className="clickable-value" onClick={ handleOnClick }>{ value }</div>
    </div>
  );
};

export default GreyStripe;
