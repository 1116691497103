import React, { useContext } from 'react';
import { IndexData } from "../../index-context";
import ClientsDropdown from "../../averages/components/clients_dropdown";

const OverviewModeSelector = () => {
  const {
    init, data, averageMode,
    setAverageMode, loadData,
    selectedOptions, setSelectedOptions,
    onClientsUpdate, clientList
  } = useContext(IndexData);

  const onChangeAvgMode = (newAverageMode) => {
    setAverageMode(newAverageMode);
    loadData(null, null, null, "quintiles", { averageMode: newAverageMode });
  };

  return (
    <div className="comparisons_plate" id="top-average-mode-selector">
      <div className="comparisons_plate-grid" id="top-average-mode-selector-body">
        <div className="radio-tabs">
          <label className="radio-tabs_item tour-swipe-13 tour-claim-15">
            <input
              id="client_overview"
              name="client_"
              checked={ averageMode === 'client' }
              disabled={ !data.client_cmp_enabled }
              onChange={ () => { onChangeAvgMode('client');} }
              type="radio"
            />
            <span className="radio-tabs_label -normal-font -centered">
              <span>Client Database</span>
            </span>
          </label>

          <label className="radio-tabs_item tour-swipe-13 tour-claim-15">
            <input
              id="platform_overview"
              name="platform_"
              checked={ averageMode === 'platform' }
              disabled={ !data.category_cmp_enabled }
              onChange={ () => { onChangeAvgMode('platform');} }
              type="radio"
            />
            <span className="radio-tabs_label -normal-font -centered">
              <span>Category Database</span>
            </span>
          </label>
        </div>
        {
          init.isInternal &&
          <ClientsDropdown
            onUpdate={ onClientsUpdate }
            averageMode={ data.average_mode }
            clientList={ clientList }
            selectedOptions={ selectedOptions }
            setSelectedOptions={ setSelectedOptions }
          />
        }
      </div>
    </div>
  );
};

export default OverviewModeSelector;
