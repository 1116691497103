import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import cn from "classnames";
import StatusCircle from "../../components/status-circle";
import { getMetricKind, getMetricTitle, IndexData } from "../../index-context";
import { averageCountWithFlag, hideCustom, n0, showedAverageValue } from '../../helpers';

const TrFivePointScales = ({ data, checkedConcepts, fivePointScales, nFields }) => {
  const { sort, averageMode, init } = useContext(IndexData);
  const [ averageCountNum, hasValue ] = averageCountWithFlag(
    n0(data[nFields?.avg]), averageMode, init.isInternal, data.view
  );
  return (
    <>
      <tr className="-highlighted">
        <td colSpan={ checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1 }>
          { getMetricKind(data, 'five_point_scale') }
        </td>
        {checkedConcepts.length > 3 && <td colSpan={ checkedConcepts.length + 1 - 3 } />}
        <td className="table-comparison_average-cell -highlighted" />
      </tr>

      {
        !!nFields &&
        <tr>
          <td className="table-comparison_name-cell">(N)</td>
          {
            map(checkedConcepts, (concept, index) => (
              <td key={ `checkedConcepts${concept.id}${index}` }>
                <span>({ concept[nFields.f] })</span>
              </td>
            ))
          }
          <td className="table-comparison_average-cell">
            { hasValue && '(' }
            { averageCountNum }
            { hasValue && ')' }
          </td>
        </tr>
      }

      {
        map(fivePointScales, (values, title) => (
          <Fragment key={ title }>
            <tr>
              <td rowSpan="2" className="table-comparison_name-cell">
                <span className={ cn({ 'sort-link -current': title === sort }) }>
                  {getMetricTitle(data, title)}
                </span>
              </td>
              {
                map(checkedConcepts, (concept, index) => (
                  <td key={ `T2B${title}${concept.id}${index}` }>
                    {
                      !index &&
                      <div className="table-comparison_box-cell">
                        T2B
                      </div>
                    }
                    <StatusCircle
                      values={ values[concept.id] } box="top2"
                      hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                    />
                  </td>
                ))
              }
              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
              </td>
            </tr>

            <tr>
              {
                map(checkedConcepts, (concept, index) => (
                  <td key={ `TB${title}${concept.id}${index}` }>
                    {
                      !index &&
                      <div className="table-comparison_box-cell">
                        TB
                      </div>
                    }
                    <StatusCircle
                      values={ values[concept.id] } box="top"
                      hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                    />
                  </td>
                ))
              }
              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view, "top") }
              </td>
            </tr>
          </Fragment>
        ))
      }
    </>
  );
};

export default TrFivePointScales;
