import React, { useState } from 'react';
import cn from "classnames";
import SvgIcon from "../../../../common/components/svg-icon";
import UploadButton from "../../../../common/components/upload_button";
import ImageInPopup from '../../../../common/components/image_in_popup';

const Answer = ({
  text, placeholder, disabled, deleteAnswer, onChange, hasError, className,
  refs, index, newItemRef, withImages, imageData, onImageDataChange, onImageDelete
}) => {
  const [ uploading, setUploading ] = useState(false);

  const handleChangeV = (event) => {
    onChange(event.target.value);
  };

  const handleDeleteAnswer = (event) => {
    event.preventDefault();
    deleteAnswer();
  };

  const handleDeleteImage = (event) => {
    event.preventDefault();
    onImageDelete();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (index < refs.current.length - 1) {
        refs.current[index + 1].focus();
      } else if (index === refs.current.length - 1 && newItemRef.current) {
        newItemRef.current.focus();
      }
    }
  };

  const assignRef = (element) => {
    if (refs) {
      refs.current[index] = element;
    }
  };

  const onInitUploading = () => {
    setUploading(true);
  };

  const onUploaded = (files) => {
    const [ file ] = files;
    const { cacheSrc: url, metaData } = file;
    onImageDataChange({ url, metaData });
    setUploading(false);
  };

  return (
    <li className={ cn("form-question_answer", className) }>
      <div className="form-question_grid">
        <div className="form-question_grid-item -first-answer-item" />
        {
          withImages && !imageData &&
          <div className="file-uploader">
            <div className="form-flavor_image -no-image -grid">
              <UploadButton
                className="link -delete"
                allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                onUploaded={ onUploaded }
                onInit={ onInitUploading }
              >
                <svg className="icon -no-image">
                  <use xlinkHref="#svg_i-no-image" />
                </svg>
              </UploadButton>
            </div>
          </div>
        }
        {
          withImages && imageData &&
          <div className="file-uploader">
            <div className={ cn("form-flavor_image", { "-content-flex-center": uploading }) }>
              <UploadButton
                className="link -delete"
                allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                onUploaded={ onUploaded }
                onInit={ onInitUploading }
              >
                <ImageInPopup
                  className="-pointer"
                  customZIndex={ 1062 }
                  src={ imageData }
                >
                  <img className="-pointer" src={ imageData } alt={ text } />
                </ImageInPopup>
              </UploadButton>
              <div className="form-flavor_image_delete">
                <button onClick={ handleDeleteImage } className="link -delete">
                  <svg className="icon-link_icon icon -i-delete">
                    <use xlinkHref="#svg_i-delete" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        }
        <div className={ cn("form-question_grid-item -grow", { '-readonly': disabled, 'has-error': hasError, '-with-left-margin': withImages }) }>
          <input
            className="form-field -block"
            type="text"
            value={ text }
            onChange={ handleChangeV }
            onKeyDown={ onKeyDown }
            placeholder={ placeholder }
            disabled={ disabled }
            ref={ assignRef }
          />
        </div>

        <div className="form-question_grid-item -buttons">
          {
            !disabled &&
            <button
              type="button"
              className="button -secondary -circle -delete-color form-question_grid-button -white-back"
              onClick={ handleDeleteAnswer }
            >
              <SvgIcon name="i-delete" />
            </button>
          }
        </div>
      </div>
    </li>
  );
};

export default Answer;
