import React from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../common/render_raw_html';

const Perk = ({
  perk, onPerkSelect, classNameWrapper,
  availablePerksCount, selectedPerks, readOnly, noBorder
}) => {
  const isSelected = (perkId) => {
    if (selectedPerks) {
      return (selectedPerks.indexOf(perkId) !== -1);
    }
    return false;
  };

  const clickPerk = (event, perk) => {
    if (!readOnly && availablePerksCount > 0) {
      if (isSelected(perk.id)) {
        onPerkSelect(perk.id, "deselect");
      } else if (availablePerksCount === 1) {
        onPerkSelect(perk.id, "change");
      } else {
        onPerkSelect(perk.id, "select");
      }
    }
  };

  // set disabled state for perks only in case when plan is chosen but it has not ability to add perks
  // this plan is A
  // in case when no plan is chosen there is not state disabled for perks but these perks are not clickable

  return (
    <div className={ classNameWrapper }>
      <div
        className={
          classnames(
            'perk-plate',
            availablePerksCount === 0 ? '-disabled' : '',
            availablePerksCount > 0 ? '-clickable' : '',
            isSelected(perk.id) ? '-selected' : '',
            readOnly ? '-readonly' : '',
            noBorder ? '-no-border' : ''
          )
        }

        onClick={ (event) => { clickPerk(event, perk); } }
      >
        <h5 className="perk-plate_title">{perk.title}</h5>

        <div className="perk-plate_list">
          {
            map(perk.list, (item, index) => (
              <div className="perk-plate_item" key={ `feature-${index}` } >
                {
                  item.title &&
                  <div className="perk-plate_feature-title-grid">
                    {
                      item.icon &&
                      <div className={ `perk-plate_feature-icon -${item.icon}` }>
                        <svg>
                          <use xlinkHref={ `#svg_${item.icon}` } />
                        </svg>
                      </div>
                    }
                    <div className="perk-plate_feature-title" { ...renderRawHtml(item.title) } />
                  </div>
                }
                {
                  (!!item.picture  || !!item.image) &&
                  <div className="perk-plate_feature-image-grid">
                    <div className={ `perk-plate_feature-image -${item.pictureKlass || item.image}` }>
                      { !!item.picture && <img src={ item.picture } alt="image" /> }
                      {
                        !!item.image &&
                        <svg>
                          <use xlinkHref={ `#svg_${item.image}` } />
                        </svg>
                      }
                    </div>
                    {
                      item.imageNote &&
                      <div
                        className={
                          classnames(
                            "perk-plate_feature-image-note",
                            item.imageNoteKlass
                          )
                        }
                      >
                        ({item.imageNote})
                      </div>
                    }
                  </div>
                }
              </div>
            ))
          }
        </div>

      </div>
    </div>
  );
};

export default Perk;
