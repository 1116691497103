import React from 'react';
import classnames from 'classnames';

const CloseAppXSign = ({ className, onClick }) => {
  const handleOnClose = (event) => {
    onClick(event);
  };
  return (
    <button
      className={ classnames("button-icon header_back close-app", className) }
      onClick={ handleOnClose }
    >
      <svg className="icon header_back-icon"><use xlinkHref="#svg_close" /></svg>
    </button>
  );
};

export default CloseAppXSign;
