import React, { useContext, useEffect, useState } from 'react';
import Switchers from "./switchers";
import ReactionMode from "./reaction-mode";
import IndividualMarkups from "./individual-markups";
import RegionsMapper from "./regions-mapper";
import RegionsMapperSettings from "./regions-mapper-settings";
import MarkupsComments from "./markups-comments";
import { MarkupData } from "./markups-logic";
import OpacitySlider from "./opacity-slider";

const Index = ({ current, imageField = 'image_url' }) => {
  const [ reactionMode, setReactionMode ] = useState('select');
  const [ markupOpacity, setMarkupOpacity ] = useState(0.2);

  const { focusConcept } = useContext(MarkupData);

  useEffect(() => {
    setReactionMode('select');
  }, [ focusConcept ]);

  return (
    <div className="concept-report_tab -markup">
      <Switchers conceptId={ current.id } />

      <h3 className="concept-focus-markups-title">
        Concept Focus - Individual Markups,
        { ' ' }
        N = { current.heatmap.positive.reaction_total }
      </h3>

      <div className="markups_grid">
        <div className="markups_grid-item">
          <ReactionMode
            reactionMode={ reactionMode }
            setReactionMode={ setReactionMode }
            conceptId={ current.id }
          />
          <div className="survey-reactions-interface_wrapper">
            <div className="survey-reactions-interface_image-wrapper">
              <RegionsMapper
                reactionMode={ reactionMode }
                setReactionMode={ setReactionMode }
              />
              <IndividualMarkups
                imageUrl={ current[imageField] }
                markupOpacity={ markupOpacity }
              />
            </div>
          </div>
        </div>

        <div className="markups_grid-item">
          <RegionsMapperSettings reactionMode={ reactionMode } conceptId={ current.id } />
          {
            reactionMode === 'select' &&
            <OpacitySlider
              opacity={ markupOpacity }
              setOpacity={ setMarkupOpacity }
              conceptId={ current.id }
              min={ 0 }
              max={ 0.7 }
              step={ 0.001 }
            />
          }
          <MarkupsComments />
        </div>
      </div>
    </div>
  );
};

export default Index;
