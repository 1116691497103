import React from 'react';

const BestPerks = ({ text, titleOnCenter = false }) => {
  const titleClassName = [
    'best-perks-text',
    titleOnCenter ? '-center' : false
  ].filter(Boolean).join(' ');
  return <div className="best-perks">
    <p className={titleClassName}>
      { text || 'Best unlimited, with the best perks'}
    </p>
    <div className="best-perks-icon"/>
  </div>
}

export default BestPerks;
