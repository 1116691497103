import React from 'react';
import InfoToolTip from './infoToolTip';

const SectionHeader = ({ title, infoText }) => {
  return (
    <div className="form_section-title -grid">
      <div className="form_section-title-item">
        <h3>{title}</h3>
      </div>
      <div className="form_section-title-item">
        <InfoToolTip text={ infoText } />
      </div>
    </div>
  );
};

export default SectionHeader;
