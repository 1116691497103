import React, { useEffect } from 'react';
import { map, filter, max } from 'lodash';
import cn from "classnames";

const ButtonGroup = ({ selectedFontSize, setFontSize }) => {
  useEffect(() => {
    const closest_value = max(filter(baseFontSizes, (num) => num <= selectedFontSize));
    setFontSize(closest_value);
  }, [ selectedFontSize ]);

  const types = [ "x.25", "x.5", "x1", "x2", "x4" ];
  const baseFontSizes = [ 500, 900, 1100, 1200, 1400 ];

  const getIndexOfSelectedFont = () => (
    baseFontSizes.indexOf(selectedFontSize)
  );

  return (
    <div className="button-plate_group">
      {
        map(types, (type, index) => (
          <button
            key={ type }
            className={
              cn(
                "button-plate",
                { "-checked": getIndexOfSelectedFont() === index },
                `-size-${type.replace('.', '0')}`
              )
            }
            onClick={ () => setFontSize(baseFontSizes[index]) }
          >
            { type }
          </button>
        ))
      }
    </div>
  );
};

export default ButtonGroup;
