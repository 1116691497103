import React, { useContext, useEffect, useRef, useState, Fragment } from "react";
import { isEmpty, map } from "lodash";
import SvgIcon from "../../../../../common/components/svg-icon";
import { MarkupData } from "./markups-logic";
import RegionsMapperEditor from "./regions-mapper-editor";

const RegionsMapper = ({ reactionMode, setReactionMode }) => {
  const [ showSelection, setShowSelection ] = useState(false);
  const [ selBox, setSelBox ] = useState({});
  const [ start, setStart ] = useState({});
  const [ showAddForm, setShowAddForm ] = useState(false);

  const {
    hasSelectedComments, selectedRegion, getScaledArea, areaClick, closeSquare, deselectComments, width, height,
    imageLeft, imageTop, focusConcept
  } = useContext(MarkupData);

  const $regions = useRef(null);

  const getCoords = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; //x position within the element.
    const y = event.clientY - rect.top;  //y position within the element.
    return { x, y };
  };

  const applySelectionCoords = (curStart, x, y) => {
    const s = {};

    if (start.x > x) {
      s.left = x;
      s.width = curStart.x - x;
    } else {
      s.left = curStart.x;
      s.width = x - curStart.x;
    }

    if (start.y > y) {
      s.top = y;
      s.height = curStart.y - y;
    } else {
      s.top = curStart.y;
      s.height = y - curStart.y;
    }
    const regionsEl = $regions.current;

    s.image_width = regionsEl.offsetWidth;
    s.image_height = regionsEl.offsetHeight;

    setSelBox(s);
  };

  const startSelection = (event) => {
    const cc = getCoords(event);

    if (reactionMode == 'select' || event.target.classList.contains('close-region')) {
      areaClick(cc);
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    setShowSelection(true);

    setStart({ x: cc.x, y: cc.y });
    applySelectionCoords({ x: cc.x, y: cc.y }, cc.x, cc.y);
  };

  const changeSelection = (event) => {
    if (showSelection) {
      event.preventDefault();
      event.stopPropagation();
      const cc = getCoords(event);
      applySelectionCoords(start, cc.x, cc.y);
    }
  };

  const finishSelection = (event) => {
    if (showSelection) {
      event.preventDefault();
      event.stopPropagation();
      setShowSelection(false);

      if (selBox.width > 3 && selBox.height > 3) {
        setShowAddForm(true);
      }
    }
  };

  const fsRef = useRef(finishSelection);
  useEffect(() => {
    fsRef.current = finishSelection;
  });

  useEffect(() => {
    const fs = (e) => fsRef.current(e);
    window.document.addEventListener('mouseup', fs);
    return () => {
      window.document.removeEventListener('mouseup', fs);
    };
  }, []);

  useEffect(() => {
    setShowSelection(false);
    setSelBox({});
    setStart({});
    setShowAddForm(false);
  }, [ focusConcept ]);

  const closeAddForm = () => {
    setShowAddForm(false);
  };

  const showClose = () => {
    return reactionMode == 'select' && hasSelectedComments() && !!closeSquare;
  };

  const onCloseRegion = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    deselectComments();
  };

  return (
    <Fragment>
      <div
        className="regions"
        ref={ $regions }
        onMouseDown={ startSelection }
        onMouseMove={ changeSelection }
        style={ { 'left': imageLeft, 'top': imageTop, width, height } }
      >

        {showSelection && <div
          className="selection"
          style={ { 'left': selBox.left, 'top': selBox.top, 'width': selBox.width, 'height': selBox.height } }
                          />}
        {reactionMode == 'select' && !isEmpty(selectedRegion.value) && <Fragment>
          {map(selectedRegion.value.data, (el, i) => {
            const scaled = getScaledArea(el, el.image_width);
            return (
              <div
                key={ i }
                className="region"
                style={ { 'left': scaled.left, 'top': scaled.top, 'width': scaled.width, 'height': scaled.height } }
              />
            );
          })}
        </Fragment>}
      </div>

      { showClose() && <div className="regions" style={ { left: 0, top: 0 } }>
        <span
          className="icon-link close-region"
          onClick={ onCloseRegion }
          style={ { 'left': closeSquare.x, 'top': closeSquare.y } }
        >
          <SvgIcon className="icon-link_icon" name="i-remove-round" />
        </span>
      </div>}

      {showAddForm && <RegionsMapperEditor
        region={ selBox }
        closeForm={ closeAddForm }
        setReactionMode={ setReactionMode }
                      />}
    </Fragment>
  );
};

export default RegionsMapper;
