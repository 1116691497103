import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { map, find, each } from 'lodash';
import SvgIcon from "../../../../../common/components/svg-icon";
import ConceptTags from '../concept-tags';

const ConceptCheckBox = ({ id, idField, selectedIds, setSelectedIds }) => {
  const idIsList = typeof id === 'object';
  const checkForIdList = () => {
    let allPresent = true;
    each(id, (item) => {
      if (!find(selectedIds, (el) => el === item)) {
        allPresent = false;
      }
    });
    return allPresent;
  };
  const [ checked, setChecked ] = useState(() => {
    if (idIsList) {
      return checkForIdList();
    }
    return selectedIds.findIndex(id) > -1;
  });

  useEffect(() => {
    if (idIsList) {
      setChecked(checkForIdList());
    }
  }, [ selectedIds ]);

  const onCheckedChange = (newState) => {
    let newIds = [ ...selectedIds ];
    if (newState) {
      if (idIsList) {
        newIds = [ ...id ];
      } else {
        newIds.push(id);
      }
    } else if (idIsList) {
      newIds = [];
    } else {
      newIds.remove(id);
    }
    setSelectedIds(newIds);
    setChecked(newState);
  };

  return (
    <>
      <input
        className="js-use"
        id={ idField }
        type="checkbox"
        checked={ checked }
        onChange={ (event) => {onCheckedChange(event.target.checked);} }
      />
      <label className="checkbox_label" htmlFor={ idField } />
    </>
  );
};

const ConceptsList = ({ concepts, setConcepts, selectedIds, setSelectedIds, setLocalSort }) => {
  const handleOnDragEnd = (result) => {
    const items = Array.from(concepts);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setConcepts(items);
    setLocalSort('');
  };

  const conceptIds = map(concepts, (item) => item.id);
  const conceptIsChecked = (conceptId) => (selectedIds.findIndex(conceptId) > -1);
  const idField = (conceptId, label) => (`id-${label || conceptId}`);

  return (
    <>
      <div className="concept-checker -header">
        <div className="concept-checker_item">
          <div className="checkbox">
            <ConceptCheckBox
              id={ conceptIds }
              selectedIds={ selectedIds }
              setSelectedIds={ setSelectedIds }
              idField={ idField(null, 'IdAll') }
            />
          </div>
        </div>
        <div className="concept-checker_item -grow">
          <label className="concept-checker_title" />
        </div>
      </div>
      <DragDropContext onDragEnd={ handleOnDragEnd }>
        <Droppable droppableId="concepts-area">
          {(provided) => {
            return (
              <ul className="concept-checker-list" { ...provided.droppableProps } ref={ provided.innerRef }>
                {
                  map(concepts, (concept, i) => (
                    <Draggable key={ `${concept.id}${conceptIsChecked(concept.id)}` } draggableId={ `x${concept.id}` } index={ i }>
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          provided.draggableProps.style.left = 70;
                        }
                        return (
                          <li className="concept-checker-list_item" { ...provided.draggableProps } ref={ provided.innerRef }>
                            <div className="concept-checker">
                              <div className="concept-checker_item">
                                <div className="checkbox">
                                  <ConceptCheckBox
                                    id={ concept.id }
                                    selectedIds={ selectedIds }
                                    setSelectedIds={ setSelectedIds }
                                    idField={ idField(concept.id) }
                                  />
                                </div>
                              </div>
                              {concept.image_present && <div className="concept-checker_item">
                                <label className="concept-preview">
                                  <div className="concept-preview_thumb -sm" style={ { backgroundImage: `url(${concept.image_url})` } } />
                                </label>
                              </div>}
                              <div className="concept-checker_item -grow">
                                <label
                                  className="concept-checker_title"
                                  htmlFor={ idField(concept.id) }
                                >
                                  { concept.title }
                                </label>
                                <ConceptTags concept={ concept } left />
                              </div>
                              <div className="concept-checker_item">
                                <div className="concept-checker_move" { ...provided.dragHandleProps }>
                                  <SvgIcon name="i-move" />
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </ul>
            );
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ConceptsList;
