import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';
import { sidebarTagManagerClick } from "../../../google-analytics/events";

const Tags = () => {
  const { controllerName, initialData, paths, gaTrackingId } = useContext(BaseContext);
  const isAdmin = initialData.is_admin;
  const tagsUrl = paths.all_tags;
  const tagsPage = controllerName === 'tags';
  return (
    <>
      {
        isAdmin &&
        <li className="sidebar_nav-list-item">
          <a
            className={ classnames("sidebar_nav-link -with-icon", { '-active': tagsPage }) }
            href={ tagsUrl }
            onClick={ () => sidebarTagManagerClick(gaTrackingId, { platform: "express", page_name: "admin" }) }
          >
            <svg className="sidebar_nav-link-icon icon -menu-tags">
              <use xlinkHref="#svg_menu-tags" />
            </svg>
            <span className="sidebar_nav-link-text">Tag Manager</span>
          </a>
        </li>
      }
    </>
  );
};

export default Tags;
