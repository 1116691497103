import React, { useEffect, useState } from 'react';
import { baseContext, fraudContext } from '../contexts';
import { initializeGoogleAnalytics } from "../../../common/google-analytics/googleAnalyticsTracking";
import { respondentStartSurvey } from "../../../common/google-analytics/events";

const WithAppTopContexts = ({ initProps, children }) => {
  const [ fraudProcessing, setFraudProcessing ] = useState(false);
  const {
    translations, respondent_id, project_id,
    anti_fraud, language_code, google_captcha_site_key,
    google_analytics_id
  } = initProps.data;
  const baseContextValue = { translations, fullLanguageCode: language_code };
  const fraudValue = {
    respondentId: respondent_id,
    projectId: project_id,
    antiFraud: anti_fraud,
    googleCaptchaSiteKey: google_captcha_site_key,
    gaTrackingId: google_analytics_id,
    fraudProcessing,
    setFraudProcessing
  };

  useEffect(() => {
    initializeGoogleAnalytics(google_analytics_id);
    respondentStartSurvey(google_analytics_id, { project_id, respondent_id });
  }, [ ]);

  return (
    <baseContext.Provider value={ baseContextValue }>
      <fraudContext.Provider value={ fraudValue }>
        { children }
      </fraudContext.Provider>
    </baseContext.Provider>
  );
};

export default WithAppTopContexts;
