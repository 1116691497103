import React from "react";
import cn from "classnames";
import SvgIcon from "../../../../common/components/svg-icon";

const WithData = ({ summary, handleEditClick, handleOnChange, handleUpdateSummary, disabled, className }) => {
  return (
    <div className="middle">
      <h3>AI Summary</h3>
      <div className={ cn("summary", className) }>
        {summary.summary}
      </div>
      <div className="buttons">
        <a className="link -green" onClick={ handleEditClick }>
          <SvgIcon name="pen" />
          Edit Summary
        </a>
        <a className="link" onClick={ handleUpdateSummary }>
          <SvgIcon name="refresh" />
          Update Summary
        </a>
        <div className="checkbox link">
          <input
            type="checkbox"
            id={ `cb-${summary.id}` }
            checked={ summary.add_to_report || false }
            onChange={ handleOnChange }
            disabled={ disabled }
          />
          <label className="checkbox_label" htmlFor={ `cb-${summary.id}` }>Add to Report</label>
        </div>

        <span className="text-grayed">
          ({summary.edited_at_by})
        </span>
      </div>
    </div>
  );
};

export default WithData;
