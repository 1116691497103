import React, { useContext, useState } from 'react';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';
import { findOrInitLoop } from '../../logic/loop';
import { fraudContext } from '../../contexts';
import { prepareParams, sendToFraudCheck } from '../../logic/fraud_check';

const OpenEnd = ({
  question, concept, note, placeholder,
  result, nextStep
}) => {
  const { respondentId, projectId, antiFraud, setFraudProcessing } = useContext(fraudContext);
  const [ text, updateText ] = useState("");

  const disabledOption = () => {
    const hash = {};
    if (!text) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswer = () => {
    findOrInitLoop(concept.confirmit_concept_id, result.loops, [ { [question.name]: text } ]);
    updateText('');
    const fraudParams = prepareParams({ antiFraud, projectId, respondentId }, [ text ]);
    sendToFraudCheck(fraudParams, nextStep, setFraudProcessing);
  };

  const updateAnswer = (event) => {
    updateText(event.target.value);
  };

  return (
    <>
      <div className="survey-layout_content">
        <div className="survey-layout_container -reasons-for-preference -mobile-centered">
          <div className="survey-options">
            <div
              className="survey-options_question-title"
              { ...renderRawHtml(question.text) }
            />
            {
              note &&
              <div className="note" { ...renderRawHtml(note) } />
            }
            <textarea
              className="js-text form-field survey-claim-random_textarea naming"
              placeholder={ placeholder }
              onChange={ (e) => { updateAnswer(e); } }
            />
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswer(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default OpenEnd;
