import React from 'react';
import ModalWindow from './modal_window';

const ProductModal = ({ children, onClose }) => {
  return (
    <ModalWindow className="modal-backdrop fade in" style={ "z-index: 1040" } >
      <div className="modal -base-modal-survey js-selectable-list-image-modal fade in -product-modal" style={ { zIndex: 1050, display: 'block' } }>
        <div className="modal-dialog">
          <div className="modal-content">
            <button type="button" className="close -move fa fa-window-close" onClick={ () => { onClose(); } } />
            <div className="modal-body">{ children }</div>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ProductModal;
