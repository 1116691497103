import React from 'react';
import { filter, map, find } from "lodash";
import cn from "classnames";

const SQAMultiOption = ({ options, question, screenerQuestions, setScreenerQuestions }) => {
  const mustSelectList = filter(
    question.condition_list,
    (item) => item.cond === 'is'
  );

  const multiConditionOptions = () => {
    const mustSelectListLength = mustSelectList.length;

    const listArr = [];

    if (mustSelectListLength > 0) {
      listArr.push({
        value: 0,
        text: options.multi_condition.all
      });

      for (let index = 0; index < mustSelectListLength - 1; index++) {
        listArr.push({
          value: index + 1,
          text: `${options.multi_condition.at_least} ${index + 1}`
        });
      }
    }

    return listArr;
  };

  const onChangeMultiCondition = (event) => {
    question.multi_condition = parseInt(event.target.value);
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const noneAnswer = find(question.condition_list, (el) => (el.index === "noneOfTheAbove"));
  const freezeSelect = noneAnswer && noneAnswer.cond === 'is' && question.none_answer;

  return (
    <>
      {
        question.kind === 'Multi' && mustSelectList.length > 1 &&
        <div className="form_section -multi-options">
          To qualify, respondents must select

          <span
            className={
              cn("form_inline-select",
                {
                  '-one': question.multi_condition > 0,
                  '-all': question.multi_condition === 0
                }
              )
            }
          >
            <select
              value={ question.multi_condition }
              onChange={ onChangeMultiCondition }
              disabled={ freezeSelect }
            >
              {
                map(multiConditionOptions(), (option) => (
                  <option key={ option.value } value={ option.value }>
                    {option.text}
                  </option>
                ))
              }
            </select>
          </span>
          of the answers with the ‘Must select’ logic.
        </div>
      }
    </>
  );
};

export default SQAMultiOption;
