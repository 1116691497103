import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ModalLoader = ({ children, backClassName, className }) => {
  const body = document.body || document.querySelector('body');
  const [ elementBack ] = useState(document.createElement('div'));
  const [ elementWrapper ] = useState(document.createElement('div'));
  useEffect(() => {
    elementBack.className = backClassName;
    elementWrapper.className = className;
    body.appendChild(elementBack);
    body.appendChild(elementWrapper);
    body.style.overflow = 'hidden';
    return (() => {
      body.removeChild(elementBack);
      body.removeChild(elementWrapper);
      body.style.overflow = null;
    });
  }, []);
  return createPortal(children, elementWrapper);
};

const Loader = () => {
  return (
    <ModalLoader
      backClassName="loader-back-wrapper -full-back"
      className="loader-back-wrapper"
    >
      <div className="loader" />
    </ModalLoader>
  );
};

export default Loader;
