import React from "react";
import SvgIcon from "../../../../common/components/svg-icon";

const BuzzbackProjectives = ({ namesText }) => (
  <>
    <div className="form_section-title -grid">
      <div className="form_section-title-item">
        <h3>buzzback Projectives</h3>
      </div>
      <div className="form_section-title-item">
        <div className="info-tooltip">
          <div className="info-tooltip_control">
            <SvgIcon name="i-info" />
          </div>
          <div className="info-tooltip_drop">
            <div className="info-tooltip_drop-inner">
              Respondents will view these for a subset of {namesText}.
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default BuzzbackProjectives;
