import React, { useContext, useEffect } from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { fraudContext } from "../contexts";
import { respondentFailedSurvey } from "../../../common/google-analytics/events";

const Fail = (props) => {
  const text = props.text;
  const { projectId, respondentId, gaTrackingId } = useContext(fraudContext);

  useEffect(() => {
    respondentFailedSurvey(gaTrackingId, { project_id: projectId, respondent_id: respondentId });
  }, []);

  return (
    <div className="survey-layout_content -start">
      <div className="survey-start -sm">
        <svg className="survey-start_icon -sorry">
          <use xlinkHref="#svg_survey-sorry" />
        </svg>
        <h3 className="survey-start_title" { ...renderRawHtml(text) } />
      </div>
    </div>
  );
};

export default Fail;
