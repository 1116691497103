import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { sendToFraudCheck, prepareParams } from "../../logic/fraud_check";
import { fraudContext } from "../../contexts";

const Markup = ({ updateHideCfHelp, hideCfHelp, nextStep, conceptIds, markupTool, result }) => {
  const { respondentId, projectId, antiFraud, setFraudProcessing } = useContext(fraudContext);

  useEffect(() => {
    window.postMessage = (params) => {
      if (params.finishSurvey) {
        checkCopyPaste(params.data.pasteDetected);
        checkForFraud(params.data);
      }
    };
  }, []);

  const checkCopyPaste = (pasteDetected) => {
    if (antiFraud) {
      result.pasteDetected ||= pasteDetected;
    }
  };

  const checkForFraud = (data) => {
    const answers = map(data.report_comments, (item) => item.comment);
    const params = prepareParams({ antiFraud, projectId, respondentId }, answers);
    sendToFraudCheck(params, nextStep, setFraudProcessing);
  };

  const onFrameLoad = (event) => {
    if (event.target.src) {
      window.frames[0].postMessage(
        {
          hideHintsOnStart: hideCfHelp,
          updateQuestion: markupTool,
          conceptIdsForSwipe: conceptIds
        },
        "*"
      );

      if (!!conceptIds && conceptIds[0]) {
        updateHideCfHelp(true);
      }
    }
  };

  return (
    <div className="tool">
      <iframe
        frameBorder="0"
        scrolling="no"
        style={ { overflow: 'hidden', position: 'absolute', left: 0, top: 0, width: "100%", height: "100%" } }
        src={ markupTool.url }
        onLoad={ (event) => { onFrameLoad(event); } }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ ...{ hideCfHelp: state.hideCfHelp } });

const mapDispatchToProps = (dispatch) => {
  return {
    updateHideCfHelp: (value) => { dispatch({ type: "UPDATE_HIDE_CF_HELP", value }); }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Markup);
