import React, { useRef, useState, Fragment } from 'react';
import { comparisonScorecardWordcloudPlayAnswer } from "../../../../common/google-analytics/events";
import { urls } from './const';

const AnswerPlayControl = ({ conceptId, answer, projectId, gaTrackingId }) => {
  const [ playing, setPlaying ] = useState(false);

  const audioRef = useRef(null);

  const onPlayStart = () => {
    comparisonScorecardWordcloudPlayAnswer(gaTrackingId, { project_id: projectId, item_id: conceptId });
    setPlaying(true);
    const audio = audioRef.current;
    audio.src = urls.play.
      replace(':concept_id', conceptId).
      replace(':answer_id',  answer.id).
      replace(':answer_type', encodeURIComponent(answer.type));

    audio.addEventListener('ended', () => { setPlaying(false); });
    audio.play();
  };

  const onPlayStop = () => {
    setPlaying(false);
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0.0;
    setPlaying(false);
  };

  return (
    <Fragment>
      <audio ref={ audioRef } />
      <div className="sentiment_playback">
        {!playing && <span
          className="playback-button"
          onClick={ onPlayStart }
          role="button"
          title="Play"
        >
          <span className="playback-button_text">Play</span>
        </span>}
        {playing && <span
          className="playback-button -stop"
          onClick={ onPlayStop }
          role="button"
          title="Stop"
        >
          <span className="playback-button_text">Stop</span>
        </span>}
      </div>
    </Fragment>
  );
};

export default AnswerPlayControl;
