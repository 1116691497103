import React from 'react';
import classnames from "classnames";

const FooterButton = ({ options, nextStep, className, customName }) => (
  <button
    className={ classnames('button survey-footer_button', className) }
    onClick={ nextStep }
    { ...options }
  >
    {
      !customName &&
      <svg className="svg-next_icon">
        <use xlinkHref="#svg_long-arrow" />
      </svg>
    }
    { !!customName && customName }
  </button>
);

export default FooterButton;
