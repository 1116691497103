import { sendMessage as sendBaseMessage } from '../../../../common/messaging';

const sendMessage = (projectId, kind, data) => {
  sendBaseMessage({
    to: "Angular",
    kind,
    projectId,
    data
  });
};

const sendEnableNextButton = (projectId) => {
  sendMessage(projectId, "enableNextButton");
};

const sendDisableNextButton = (projectId) => {
  sendMessage(projectId, "disableNextButton");
};

export { sendMessage, sendEnableNextButton, sendDisableNextButton };
