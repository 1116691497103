import React, { useState  } from 'react';
import { map } from 'lodash';
import NewTagLine from "./NewTagLine";
import Level1Line from "./Level1Line";
import Level2Line from "./Level2Line";

const TagWithChildren = ({ tagObj, productCategory }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const baseInfo = { category: productCategory.name, text1: tagObj.label, text2: '' };

  return (
    <div className="form_section-line">
      <Level1Line
        modalInfo={ { ...baseInfo, ...{ id: tagObj.value } } }
        tagObj={ tagObj }
        isOpen={ isOpen }
        setIsOpen={ setIsOpen }
      />

      {
        isOpen &&
        <>
          {
            map(tagObj.children, (childTag) => (
              <Level2Line
                key={ childTag.value }
                modalInfo={ { ...baseInfo, ...{ id: childTag.value, text2: childTag.label } } }
                tagObj={ childTag }
                className="-padding-left"
                tagType="express_category"
              />
            ))
          }

          <NewTagLine
            className="-padding-left"
            tagType="express_category"
            productCategoryId={ productCategory.product_category_id }
            parentId={ tagObj.value }
          />
        </>
      }
    </div>
  );
};

export default TagWithChildren;
