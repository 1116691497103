import React, { useContext } from "react";
import { map } from "lodash";
import classnames from "classnames";
import { showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { IndexData, selectKind } from '../../../index-context';

const TrBaseComparisonProducts = ({ checkedConcepts, sectionTitle, kindName }) => {
  const { init, data, averageMode, sort } = useContext(IndexData);

  const productsHashes = selectKind(data, kindName);

  return (
    <>
      <tr className="-highlighted">
        <td
          colSpan={
            checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1
          }
        >
          { sectionTitle }
        </td>
        {
          checkedConcepts.length > 3 &&
          <td colSpan={ checkedConcepts.length + 1 - 3 } />
        }
        <td className="table-comparison_average-cell -highlighted" />
      </tr>
      {
        map(productsHashes, (product, sortTitle) => (
          <tr key={ sortTitle }>
            <td
              className={
                classnames(
                  "table-comparison_name-cell",
                  { "table-comparison_bold-text": product.target }
                )
              }
            >
              <span className={ classnames({ 'sort-link -current': sortTitle === sort }) }>
                { product.name } { product.target && <span>({ data.titles.main })</span> }
              </span>
            </td>
            {
              map(checkedConcepts, (concept) => (
                <td key={ `concept_percent_${concept.id}` }>
                  <StatusCircle values={ product[concept.id] } box="top2" />
                </td>
              ))
            }
            <td className="-js-n table-comparison_average-cell">
              { showedAverageValue(product, averageMode, false, init.isInternal, data.view) }
            </td>
          </tr>
        ))
      }
    </>
  );
};

export default TrBaseComparisonProducts;
