import { cloneDeep, find, isArray, isEmpty, map, remove } from 'lodash';

const defaultLogicPrevOperator = "OR";
const logicPrevOperatorRegEx = (new RegExp(`^prevLogic:(AND|OR)$`));
const newLogicPrevOperator = (customOperator) => (
  `prevLogic:${customOperator || defaultLogicPrevOperator}`
);
const findBetweenLogicItem = (filtersArray) => (
  find(filtersArray || [], (item) => item.match(logicPrevOperatorRegEx)) ||
  newLogicPrevOperator()
);

const prepareDemoFilters = (data) => {
  if (!data) {
    return [];
  }
  if (!data.filter) {
    data.filter = {};
  }
  if (!data.filter.demographics) {
    data.filter.demographics = [];
  }
  const currentList = data.filter.demographics;
  if (isEmpty(currentList)) {
    data.filter.demographics = [ [] ];
  } else if (!isArray(currentList[0])) {
    data.filter.demographics = [ currentList ];
  }
  const filters = data.filter.demographics;

  return map(filters, (filtersArray, index) => {
    const resultingHash = cloneDeep({ filtersArray });
    if (index) {
      resultingHash.betweenLogicItem = findBetweenLogicItem(filtersArray);
      remove(
        resultingHash.filtersArray,
        (item) => item === resultingHash.betweenLogicItem
      );
    }
    return resultingHash;
  });
};

const filtersFromStructure = (data, demoFilters) => {
  const filter = cloneDeep(data.filter) || {};
  filter.demographics = map(demoFilters, (object, index) => (
    index ?
      [ ...[ object.betweenLogicItem ], ...object.filtersArray ] :
      object.filtersArray
  ));
  return filter;
};

const getFiltersUpdated = (data, demoFilters, loadData, newValue, index, noUpdate) => {
  if (!noUpdate) {
    demoFilters[index].filtersArray = newValue;
  }
  const filter = filtersFromStructure(data, demoFilters);
  loadData(filter);
  return filter;
};

const atLeastOptionRegEx = (qName) => (
  new RegExp(`^${qName}=at_least:`)
);

const ageBreaksOptionRegEx = (qName) => (
  new RegExp(`^${qName}=age_breaks:`)
);

const selectAgeBreaksOption = (name, leftBoarder, rightBoarder) => (
  `${name}=age_breaks:${leftBoarder}-${rightBoarder}`
);

const selectAtLeastOption = (name, number) => (
  `${name}=at_least:${number}`
);

const anyLogicOperatorRegEx = (qName) => (
  new RegExp(`^${qName}=logic:`)
);

const operatorNotRegEx = (qName) => (
  new RegExp(`^${qName}=logic:NOT`)
);
const operatorLogicRegEx = (qName) => (
  new RegExp(`^${qName}=logic:(AND|OR)`)
);

const generateId = () => `${Date.now()}_${Math.round(Math.random() * 10000)}`;

const logicNotOperatorRegEx = operatorNotRegEx("([^=]+)");
const logicOperatorRegEx = operatorLogicRegEx("([^=]+)");
const atLeastRegEx = /^([^=]+)=(at_least):(\d+)$/;
const ageBreaksRegEx = /^([^=]+)=age_breaks:([^-]*)-(.*)$/;
const defaultLogicOperator = "OR";

export {
  newLogicPrevOperator,
  findBetweenLogicItem,
  prepareDemoFilters,
  filtersFromStructure,
  getFiltersUpdated,
  atLeastOptionRegEx,
  ageBreaksOptionRegEx,
  selectAgeBreaksOption,
  selectAtLeastOption,
  anyLogicOperatorRegEx,
  operatorNotRegEx,
  operatorLogicRegEx,
  generateId,

  logicPrevOperatorRegEx,
  defaultLogicPrevOperator,
  logicNotOperatorRegEx,
  logicOperatorRegEx,
  atLeastRegEx,
  ageBreaksRegEx,
  defaultLogicOperator
};
