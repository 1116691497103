const classByPosition = (position) => {
  let resultingClass;
  switch (position) {
    case 'bottom-right':
      resultingClass = '-bottom-right';
      break;
    case 'bottom-left':
      resultingClass = '-bottom-left';
      break;
    case 'top-left':
      resultingClass = '-top-left';
      break;
    case 'bottom-center':
      resultingClass = '-bottom-center';
      break;
    case 'top-center':
      resultingClass = '-top-center';
      break;
    case 'top':
      resultingClass = '-top';
      break;
    case 'left':
      resultingClass = '-left';
      break;
    default:
      resultingClass = '';
  }
  return resultingClass;
};

export { classByPosition };
