import React from 'react';

const StartButton = ({ nextStep, disabled = false }) => {
  return (
    <button
      className="button survey-start_button"
      type="button"
      disabled={ disabled }
      onClick={ () => { nextStep(); } }
    >
      <svg className="svg-next_icon">
        <use xlinkHref="#svg_long-arrow" />
      </svg>
    </button>
  );
};

export default StartButton;
