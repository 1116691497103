import React from 'react';
import classnames from 'classnames';

const HintInPopup = ({ hintText, children, className, dropClassName }) => {
  const dropCn = dropClassName || "-bottom-center";
  return (
    <div className={ classnames("hover-hide-tool", className) }>
      <div className="hover-hide-text">{ children }</div>
      <div className={ classnames("info-tooltip_drop", dropCn) }>
        <div className="info-tooltip_drop-inner -tiny">
          { hintText }
        </div>
      </div>
    </div>
  );
};

export default HintInPopup;
