import React, { useEffect, useState, useRef } from 'react';
import { convertFormDataToText, sendMessageOnFormChange } from '../helper';

const OnUpdateForm = () => {
  const ref = useRef(null);
  const [ styles ] = useState(() => (
    { position: 'absolute', left: -1000, top: -1000, width: 0, height: 0 }
  ));
  const [ formElement, setFormElement ] = useState(null);
  const [ formDataText, setFormDataText ] = useState(null);

  const findFormFromCurrentElement = () => {
    let counter = 0;
    const maxCounter = 10;
    let form = ref.current;
    while (!!form && form.tagName?.toLowerCase() !== 'form' && counter <= maxCounter) {
      form = form.parentNode;
      counter += 1;
    }
    return form;
  };

  useEffect(() => {
    const form = findFormFromCurrentElement();
    setFormElement(form);
    const updateFormText = () => {
      setFormDataText(convertFormDataToText(form));
    };
    updateFormText();
    // Custom form on change
    const interval = setInterval(updateFormText, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (formElement && formDataText) {
      sendMessageOnFormChange(formElement.id);
    }
  }, [ formElement, formDataText ]);

  return (<div style={ styles } ref={ ref } />);
};

export default OnUpdateForm;
