const baseDefaultState = {
  loading: true,
  step: 0,
  qIndex: 0,
  cIndex: 0,
  conceptStep: 0,
  shelfStep: 0,
  hideCfHelp: false
};

const baseState = (state = baseDefaultState, action) => {
  switch (action.type) {
    case "UPDATE_LOADING":
      state.loading = action.value;
      return { ...state };
    case "NEXT_STEP":
      state.step += 1;
      return { ...state };
    case "UPDATE_STEP":
      state.step = action.value;
      return { ...state };
    case "UPDATE_QUESTION_INDEX":
      state.qIndex = action.value;
      return { ...state };
    case "UPDATE_CONCEPT_INDEX":
      state.cIndex = action.value;
      return { ...state };
    case "UPDATE_CONCEPT_STEP":
      state.conceptStep = action.value;
      return { ...state };
    case "UPDATE_SHELF_STEP":
      state.shelfStep = action.value;
      return { ...state };
    case "UPDATE_HIDE_CF_HELP":
      state.hideCfHelp = action.value;
      return { ...state };
    default:
      return state;
  }
};

export default baseState;
