import React, { useState } from 'react';

const ToggleManageQuota = ({ question, leaveLink, children, onUpdate }) => {
  const itemId = `${Date.now()}_${Math.round(Math.random() * 10000)}`;
  const id = `${question.uiTitle()}-${itemId}`;

  const [ manageVal, setManageVal ] = useState(question.manage_quota);

  const onManageUpdate = (event) => {
    const newValue = !question.manage_quota;
    setManageVal(newValue);
    question.manage_quota = newValue;
    onUpdate(event, question);
  };

  return (
    <>
      { !question.toggleManageQuota && question.manage_quota && children }
      {
        question.toggleManageQuota && question.availableToManageQuotas &&
        (
          <div className="toggle -manage -quota">
            <input
              className="hide"
              type="checkbox"
              checked={ manageVal }
              id={ id }
              onChange={ () => {} }
            />
            <label className="form-switch" htmlFor={ id } onClick={ onManageUpdate }>
              Toggle Manage Quota
            </label>
            {
              !manageVal && !leaveLink &&
              <span>Targeting Only</span>
            }
            {
              !manageVal && leaveLink &&
              <span className="edit-options-targeting">
                { children }<span>(Targeting Only)</span>
              </span>
            }
            { manageVal && children }
          </div>
        )
      }
    </>
  );
};

export default ToggleManageQuota;
