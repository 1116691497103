import React, { useContext } from 'react';
import { isEmpty, filter, map } from "lodash";
import classnames from "classnames";
import { IndexData, selectGroup } from '../../index-context';
import Th from '../../../common/components/concepts-table/th';
import TrOverviewCombinedTools from "../../../swipe/components/concepts-table/tr-overview-combined-tools";
import TrOverviewFivePointScales from "./tr-overview-five-point-scales";
import TrOverviewAttributeRatings from "./tr-overview-attribute-ratings";
import TrOverviewShelf from "./tr-overview-shelf";

const OverviewConceptsTable = () => {
  const { data, concepts } = useContext(IndexData);
  const checkedConcepts = filter(concepts, (el) => el.checked);
  const alphabet = data.alphabet;

  const combinedTools = selectGroup(data, 'combined_tool');
  const fivePointScales = selectGroup(data, 'five_point_scale');
  const attributeRatings = selectGroup(data, 'attribute_ratings');
  const nameAttributeRatings = selectGroup(data, 'name_attribute_agreement');
  const flavorAttributeRatings = selectGroup(data, 'flavor_attribute_agreement');
  const packageAttributeRatings = selectGroup(data, 'package_attribute_ratings');
  const packageMcAttributeRatings = selectGroup(data, 'package_mc_attribute_ratings');
  const adceptAttributeRatings1 = selectGroup(data, 'adcept_attribute_ratings1');
  const adceptAttributeRatings2 = selectGroup(data, 'adcept_attribute_ratings2');

  const leftRight = selectGroup(data, "Left/Right");

  const preference = selectGroup(data, 'preference');
  const shelfResults = selectGroup(data, "express_shelf_result");

  const cartExercise = { };
  if (data.cart_exercise) {
    cartExercise[data.titles.percent] = data.analytics[data.titles.percent];
  }

  const cartRanking = selectGroup(data, "Cart Ranking");

  return (
    <table
      className={
        classnames(
          "table table-comparison table-concept-overview",
          "-stick-header -stick-average -stick-first-column",
          `concepts-number-${checkedConcepts.length}`
        )
      }
    >
      <thead>
        <tr>
          <th className="table-comparison_name-cell" />
          <th className="-empty" />
          {
            map(checkedConcepts, (concept, index) => (
              <Th
                key={ `header${index}` }
                concept={ concept }
                alphabet={ alphabet }
              />
            ))
          }
        </tr>
      </thead>

      <tbody>
        {
          !isEmpty(combinedTools) &&
          <TrOverviewCombinedTools
            data={ data }
            checkedConcepts={ checkedConcepts }
            combinedTools={ combinedTools }
          />
        }
        {
          !isEmpty(fivePointScales) &&
          <TrOverviewFivePointScales
            data={ data }
            checkedConcepts={ checkedConcepts }
            fivePointScales={ fivePointScales }
          />
        }
        {
          !isEmpty(attributeRatings) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ attributeRatings }
          />
        }
        {
          !isEmpty(nameAttributeRatings) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ nameAttributeRatings }
          />
        }
        {
          !isEmpty(flavorAttributeRatings) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ flavorAttributeRatings }
          />
        }
        {
          !isEmpty(packageAttributeRatings) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ packageAttributeRatings }
          />
        }
        {
          !isEmpty(packageMcAttributeRatings) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ packageMcAttributeRatings }
          />
        }
        {
          !isEmpty(adceptAttributeRatings1) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ adceptAttributeRatings1 }
          />
        }
        {
          !isEmpty(adceptAttributeRatings2) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ adceptAttributeRatings2 }
          />
        }
        {
          !isEmpty(leftRight) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ leftRight }
          />
        }
        {
          !isEmpty(preference) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ preference }
          />
        }
        {
          !isEmpty(shelfResults) &&
          <TrOverviewShelf
            data={ data }
            checkedConcepts={ checkedConcepts }
          />
        }
        {
          !isEmpty(cartExercise) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ cartExercise }
          />
        }
        {
          !isEmpty(cartRanking) &&
          <TrOverviewAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ cartRanking }
          />
        }
      </tbody>
    </table>
  );
};

export default OverviewConceptsTable;
