import React, { Fragment, useContext } from 'react';
import { map, each } from 'lodash';
import renderRawHtml from "../../../../../common/render_raw_html";
import classnames from "classnames";

const PrepaidPlan = ({ planObject }) => {
  return(
    <div className="base-plan">
      <h2 className="-font-32 title -plan-name" { ...renderRawHtml(planObject.landingTitle) }>
      </h2>
      <div className="text-14 -concept-a text-description" { ...renderRawHtml(planObject.description) }>
      </div>
      <div className="dollar-price">
        <h2 className="value -big">${ planObject.price } <em>/mo</em></h2>
      </div>
      <div className="features">
        {
          map(planObject.features, (feature, index) => {
            return(
              <div key={ `prepaidPlan${planObject.id}feature${index}` } className={ classnames("feature-item", { '-big': feature.big }, { '-bigger': feature.bigger }) }>
                <span
                  className={ classnames(`text-14 -concept-a feature`, { '-big': feature.big }, { '-bigger': feature.bigger }) }
                  { ...renderRawHtml(feature.title) }
                >
                </span>
                {
                  feature.popupIcon &&
                  <svg className="info"><use xlinkHref="#svg_tooltip"/></svg>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

const SliderSection = () => {
  return(
    <div className="section-with-slider">
      <div className="left-part">
        <div className="boxes-with-slider">
          <div className="inner-box" data-length="1-3 months">
            <div className="bottom-section">
              { map(new Array(3), (_, index) => ( <div key={ `0${index}` } className="block-interval -three"></div>)) }
            </div>
            <div className="bottom-section -green" style={ { width: "40%" } }></div>
            <div className="slider-driver" style={ { left: "40%", transform: "translateX(-40%)" } }>
              <div className="arrow -left"></div>
              <div className="arrow -right"></div>
            </div>
          </div>
          <div className="inner-box" data-length="4-9 months">
            <h2>Save $5 <em>/mo</em></h2>
            <div className="bottom-section -darker">
              { map(new Array(6), (_, index) => (<div key={ `1${index}` } className="block-interval -six"></div>)) }
            </div>
          </div>
          <div className="inner-box" data-length="10+ months">
            <h2>Save $10 <em>/mo</em></h2>
            <div className="bottom-section -dark"></div>
          </div>
        </div>
        <div className="note">
          Starting at month 4, you can save on prepaid plans with loyalty discounts.
        </div>
      </div>
      <div className="right-part">
        <div className="right-box">
          <h4>Your monthly savings:</h4>
          <p>
            Your monthly savings will increase the longer you stay with Verizon.
          </p>
        </div>
        <div className="note -flex">
          <div className="checked-checkbox"></div>
          <span>
            Show $5/mo Auto Pay discount, starting at the 2nd month.
            <svg className="info"><use xlinkHref="#svg_tooltip"/></svg>
          </span>
        </div>
      </div>
    </div>
  )
}

const PrepaidPart = ({ allList, list, onClick }) => {
  const groupedPlans = [];
  each(list, (plan, index) => {
    if (!(index % 2)){ groupedPlans.push([]);}
    groupedPlans[groupedPlans.length-1].push(plan);
  });

  return (
    <Fragment>
      <h2 className="main-title">Prepaid data plans</h2>
      <div className="prepaid-landing">
        <div className="subtype-boxes">
          <div>Metered Plans</div>
          <div>Unlimited Plans</div>
        </div>
        <div className="plans-description-section">
          <div className="top-title-with-button">
            <h2 className="top-title">
              Save more on prepaid, now with 5G Nationwide.
            </h2>
            <button className="select-billing-plan get-started" onClick={ onClick }>Get started</button>
          </div>
          <div className="top-note">
            <strong>5G Nationwide available in 2,700+ cities.</strong> 5G Nationwide access requires a 5G Nationwide-capable device.
          </div>
          <SliderSection/>
        </div>
        <div className="plans-body">
          {
            map(groupedPlans, (group, index) =>
              (
                <div key={ `groupPrepaid${index}` } className="section-part">
                  {
                    map(group, (planObject)=> ( <PrepaidPlan key={`prepaidPlan${planObject.id}`} planObject={ planObject } />))
                  }
                </div>
              )
            )
          }
        </div>

      </div>
    </Fragment>
  )
};

export default PrepaidPart;