import { map } from 'lodash';

const planCombiName = (planNames) => {
  const parts = planNames[0].split(/\s+/);
  const firstPart = parts[0];
  const secondPart = map(
    planNames,
    (name) => (name.split(/\s+/)[1])
  ).join(' + ');
  return `${firstPart} ${secondPart}`;
};

export { planCombiName };
