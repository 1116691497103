import React from 'react';
import Modal from "../common/modal";
import PricingModalBaseBody from './pricingModalBody/pricingModalBaseBody';
import PricingModalCustomPerksBody from './pricingModalBody/pricingModalCustomPerksBody';
import PricingModalSpecialBody from './pricingModalBody/pricingModalSpecialBody';
import PricingModalBauBody from './pricingModalBody/pricingModalBauBody';
import PricingModalSpecialBauBody from './pricingModalBody/pricingModalSpecialBauBody';

const PricingModal = ({ close, pricing, plans, kind }) => {
  return (
    <Modal title={ 'Pricing guide' } handleClose={ close }>
      {
        kind === 'customPerks' &&
        <PricingModalCustomPerksBody
          pricing={ pricing }
          plans={ plans }
        />
      }
      {
        kind === 'special' &&
        <PricingModalSpecialBody
          pricing={ pricing }
          plans={ plans }
        />
      }
      {
        kind === 'bau' &&
        <PricingModalBauBody
          pricing={ pricing }
          plans={ plans }
        />
      }
      {
        kind === 'specialBAU' &&
        <PricingModalSpecialBauBody
          pricing={ pricing }
          plans={ plans }
        />
      }
      {
        (!kind || kind === 'base') &&
        <PricingModalBaseBody
          pricing={ pricing }
          plans={ plans }
        />
      }
    </Modal>
  );
};

export default PricingModal;


