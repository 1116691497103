const visibleComHash = (baseUrl) => {
  const url = [ baseUrl, "visible_com" ].join("/");
  return {
    aboutHero: `${url}/about-hero.jpg`,
    aboutStory: `${url}/about-story.jpg`,
    aboutSwitch: `${url}/about-switch.png`,
    aboutValue1: `${url}/about-value-1.png`,
    aboutValue2: `${url}/about-value-2.png`,
    aboutValue3: `${url}/about-value-3.png`,
    aboutValue4: `${url}/about-value-4.png`,
    aboutValue5: `${url}/about-value-5.png`,
    aboutValue6: `${url}/about-value-6.png`,
    landingHelp1: `${url}/landing-help-1.jpg`,
    landingHelp2: `${url}/landing-help-2.jpg`,
    landingHelp3: `${url}/landing-help-3.jpg`,
    landingHero: `${url}/landing-hero.jpg`,
    landingMap: `${url}/landing-map.png`
  };
};

export default visibleComHash;
