import React, {useState} from "react";
import classnames from "classnames";

const AgreementSection = ({ title, openedInit = true, children }) => {

  const [ openContent, setOpenContent ] = useState(openedInit);
  const toggleContent = () => {
    setOpenContent(!openContent);
  };

  return (
    <div className="agreement_section">
      <h2 className="agreement_section-title">
        <button
          className={
            classnames(
              "agreement_section-toggle",
              { "-closed": !openContent }
          )}
          onClick={ toggleContent }
        >
          <span>{ title }</span>
          <svg className="icon">
            <use xlinkHref="#svg_chevron" />
          </svg>
        </button>
      </h2>
      {!!openContent && (
        <div className="agreement_section-content">
          { children }
        </div>
      )}
    </div>
  );
};

export default AgreementSection;