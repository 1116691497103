import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const Credits = () => {
  const { controllerName, initialData, paths } = useContext(BaseContext);
  const isAdmin = initialData.is_admin;
  const creditsUrl = paths.credits;
  const creditsPage = controllerName === 'clients';
  return (
    <>
      {
        isAdmin &&
        <li className="sidebar_nav-list-item">
          <a
            className={ classnames("sidebar_nav-link -with-icon", { '-active': creditsPage }) }
            href={ creditsUrl }
          >
            <svg className="sidebar_nav-link-icon icon -menu-clients">
              <use xlinkHref="#svg_menu-clients" />
            </svg>
            <span className="sidebar_nav-link-text">Clients & Credits</span>
          </a>
        </li>
      }
    </>
  );
};

export default Credits;
