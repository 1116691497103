import React, { Component } from 'react';
import renderRawHtml from '../../../common/render_raw_html.js';

class Tooltip extends Component{

  constructor(props){
    super(props);
  }

  infoKlasses(forceMargin){
    const list = [ "info-tooltip" ];
    if (forceMargin) list.push("-force-margin");
    return list.join(" ");
  }

  dropKlasses(direction){
    const list = [ "info-tooltip_drop" ];
    if (direction == "right") list.push("-right");
    return list.join(" ");
  }

  render(){
    const text = this.props.text,
          forceMargin = this.props.forceMargin,
          direction = this.props.direction;

    return(
      <div className={ this.infoKlasses(forceMargin) }>
        <div className="info-tooltip_control">
          <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
        </div>
        <div className={ this.dropKlasses(direction) }>
          <div className="info-tooltip_drop-inner" { ...renderRawHtml(text) } ></div>
        </div>
      </div>
    )
  }
}

export default Tooltip;