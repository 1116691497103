import React from 'react';

const TagTypes = ({ tab, setTab }) => {
  const handleOnChange = (event) => {
    setTab(event.target.value);
  };

  return (
    <div className="form_section-tabs -max-50vw">
      <div className="radio-tabs -gray-bg -grow -block">
        <label id="label-express-category" className="radio-tabs_item -width-1-3">
          <input
            type="radio"
            checked={ tab === 'express_category' }
            value={ 'express_category' }
            onChange={ handleOnChange }
          />
          <div className="radio-tabs_label -width-1-1">Category Tags</div>
        </label>
        <label id="label-express-brand" className="radio-tabs_item -width-1-3">
          <input
            type="radio"
            checked={ tab === 'express_brand' }
            value={ 'express_brand' }
            onChange={ handleOnChange }
          />
          <div className="radio-tabs_label -width-1-1">Brand Tags</div>
        </label>
        <label id="label-express-custom" className="radio-tabs_item -width-1-3">
          <input
            type="radio"
            checked={ tab === 'express_custom' }
            value={ 'express_custom' }
            onChange={ handleOnChange }
          />
          <div className="radio-tabs_label -width-1-1">Custom Tags</div>
        </label>
      </div>
    </div>
  );
};

export default TagTypes;
