const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const body = document.body || document.querySelector('body');

const setKeyboardClass = () => {
  if (isIOS) {
    body.classList.add('with-keyboard');
  }
};

const removeKeyboardClass = () => {
  if (isIOS) {
    body.classList.remove('with-keyboard');
  }
};

export { setKeyboardClass, removeKeyboardClass };
