import React, { useState, useEffect, useRef, useContext } from 'react';
import cn from "classnames";
import { each } from "lodash";
import SvgIcon from "../../../../../common/components/svg-icon";
import Wysiwyg from "../../../../../common/components/wysiwyg";
import { QuestionContext, QuestionsContext } from '../contexts';
import {
  findConditionItem,
  findGridConditionItem,
  firstNotDeletedIndex
} from "../../../common/custom_questions/helper";
import OpenEnd from '../../../common/custom_questions/components/openEnd';
import QuestionSettings from "./QuestionSettings";
import QuestionAnswers from "./QuestionAnswers";

const Question = ({ options, question, questions, setQuestions, questionIndex, images }) => {
  const [ selectIsOpened, setSelectIsOpened ] = useState(false);
  const { allowReasons } = useContext(QuestionsContext);

  const hasErrorKind = question.errors && question.errors.kind;
  const hasErrorText = question.errors && question.errors.text;

  const manageFivePtToggleCondition = () => (
    (question.answers.length >= 5 && question.kind === 'Single') ||
    (question.kind === 'Grid' && question.scales.length === 5)
  );
  const fivePtScaleCheck = () => (question.as_point_scale);

  const [ manageFivePtToggle, setManageFivePtToggle ] = useState(manageFivePtToggleCondition);
  const [ fivePtScaleMode, setFivePtScaleMode ] = useState(fivePtScaleCheck);

  const kindName = (name) => {
    return options.kinds[name];
  };

  const onSelectClick = () => {
    if (fivePtScaleMode) {
      return;
    }
    setSelectIsOpened(!selectIsOpened);
  };

  const selectOption = (event, name) => {
    event.preventDefault();
    event.stopPropagation();

    const previousKind = question.kind;
    question.kind = name;
    if (name === 'Grid') {
      if (!question.scales.length) {
        question.scales = [ "" ];
      }
      question.condition_list = [];
      each(question.answers, (answer, index) => {
        findGridConditionItem(question, index);
      });
    }
    if (previousKind === "Grid") {
      question.condition_list = [];
      each(question.answers, (answer, index) => {
        findConditionItem(question, index);
      });
    }

    updateManageFivePtToggle();
    setQuestions([ ...questions ]);
    setSelectIsOpened(false);
  };

  const updateManageFivePtToggle = () => {
    if (manageFivePtToggleCondition()) {
      setManageFivePtToggle(true);
    } else {
      setManageFivePtToggle(false);
    }
  };

  const delQuestion = (event) => {
    event.preventDefault();
    question._destroy = true;
    setQuestions([ ...questions ]);
  };

  const onChangeText = (newHtml) => {
    question.text = newHtml;
    setQuestions([ ...questions ]);
  };

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSelectIsOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ ref ]);

  const contextValue = {
    manageFivePtToggle, setManageFivePtToggle, fivePtScaleMode,
    setFivePtScaleMode, updateManageFivePtToggle, images
  };

  return (
    <QuestionContext.Provider value={ contextValue }>
      <div
        className={
          cn({
            'form-question -screening': !question._destroy,
            'destroyed-question': question._destroy,
            '-first-actual': firstNotDeletedIndex(question, questionIndex, questions)
          })
        }
      >
        <div className="form_section">
          <div className="form_section-line">
            <div className="form_grid">
              <div
                className={
                  cn(
                    "form_grid-item -width-full -relative -multi-elements",
                    { 'has-error': hasErrorKind }
                  )
                }
              >
                <div
                  className={
                    cn(
                      "form-field -block -select",
                      {
                        '-not-selected': !question.kind || fivePtScaleMode,
                        '-opened': selectIsOpened,
                        '-disabled': fivePtScaleMode
                      }
                    )
                  }
                  onClick={ onSelectClick }
                >
                  {!question.kind && <span>Select Question Type</span>}
                  <span>{ kindName(question.kind) }</span>
                </div>

                {
                  selectIsOpened &&
                  <div
                    ref={ ref }
                    className="form_grid-item -width-2-3 -select-options"
                  >
                    <div className="form-question_type">
                      <div
                        className="form-question_type-col -first"
                        onClick={ ($event) => {selectOption($event, 'Single');} }
                      >
                        <div className="form-question_type-title">
                          { kindName('Single') }
                        </div>
                        <div className="form-question_type-text">
                          respondents may only select one answer
                        </div>
                      </div>

                      <div className="form-question_type-divider">
                        or
                      </div>

                      <div
                        className="form-question_type-col"
                        onClick={ ($event) => {selectOption($event, 'Multi');} }
                      >
                        <div className="form-question_type-title">
                          { kindName('Multi') }
                        </div>
                        <div className="form-question_type-text">
                          respondents may select more than one answer
                        </div>
                      </div>

                      <div className="form-question_type-divider">
                        or
                      </div>

                      <div
                        className="form-question_type-col"
                        onClick={ ($event) => {selectOption($event, 'Grid');} }
                      >
                        <div className="form-question_type-title">
                          { kindName('Grid') }
                        </div>
                        <div className="form-question_type-text">
                          respondents will answer per each grid row
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div className="form_grid-item -multi-elements">
                <button
                  type="button"
                  className="button -secondary -white-back"
                  onClick={ delQuestion }
                >
                  <SvgIcon name="i-delete" className="button_icon -delete" />
                  <span className="button_text">
                    Delete
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className={ cn("form_section-line", { 'has-error': hasErrorText }) }>
            <Wysiwyg
              customPicker
              toolbarClassName="wysiwyg-menu"
              editorClassName="wysiwyg-textarea"
              placeholder="Add"
              enableIndent
              value={ question.text }
              onChange={ onChangeText }
            />
          </div>
        </div>

        <QuestionSettings
          options={ options }
          question={ question }
          questions={ questions }
          setQuestions={ setQuestions }
        />

        <QuestionAnswers
          options={ options }
          question={ question }
          questions={ questions }
          setQuestions={ setQuestions }
          questionIndex={ questionIndex }
        />
        <OpenEnd
          allowReasons={ allowReasons }
          question={ question }
          questions={ questions }
          setQuestions={ setQuestions }
        />
      </div>
    </QuestionContext.Provider>
  );
};

export default Question;
