import React from "react";

const AboutAccount = () => {
  return (
    <div>
      <h3>Welcome to PayPal!</h3>
      <p>This user agreement is a contract between you and PayPal, Inc. governing your use of your PayPal account and the PayPal services. It applies to U.S. PayPal accounts only. If you are an individual, you must be a resident of the United States or one of its territories and at least 18 years old, or the age of majority in your state of residence to open a U.S. PayPal account and use the PayPal services. If you are a business, the business must be organized in, operating in, or a resident of, the United States or one of its territories to open a U.S. PayPal account and use the PayPal services.</p>
      <p>By opening and using a PayPal account, you agree to comply with all of the terms and conditions of this user agreement, including the <span className="pseudo-link">Fee pages</span>, and any upcoming changes described on the <span className="pseudo-link">Policy Updates</span> page at the time you accept this user agreement (which changes will apply to you on the indicated effective dates). These terms include an <span className="pseudo-link">agreement to resolve disputes by arbitration</span> on an individual basis. You also agree to comply with each of the other terms or agreements on the <span className="pseudo-link">Legal Agreements</span> page that apply to you and the following additional policies:</p>
      <ul>
        <li><span className="pseudo-link">Acceptable Use Policy</span></li>
        <li><span className="pseudo-link">Electronic Communications Delivery Policy (E-Sign Disclosure and Consent)</span></li>
      </ul>
      <p>Please read carefully all of the terms and conditions of this user agreement, the terms of the above policies, and each of the other terms and agreements that apply to you.</p>
      <p>We may revise this agreement and any of the other terms, agreements, or policies from time to time. The revised version will be effective at the time we post it, unless otherwise noted. If our changes reduce your rights or increase your responsibilities, we will provide notice of such changes.</p>
      <ul>
        <li>For personal accounts, notice will include posting information about the changes on our <span className="pseudo-link"><strong>Policy Updates</strong></span> page. If you accepted this user agreement prior to us posting notice of the changes on our <span className="pseudo-link">Policy Updates</span> page, then such notice will be provided at least 21 days before the applicable effective date.</li>
        <li>For business accounts, notice will include posting information about the changes on our <span className="pseudo-link"><strong>Policy Updates</strong></span> page or providing information about the changes by other written means (which may include email). If you accepted this user agreement prior to us providing notice of the changes on the Policy Updates page or by other written means, then such notice will be provided at least 5 days before the applicable effective date.</li>
      </ul>
      <p>By continuing to use our services after any changes to this user agreement or any of the other applicable terms, agreements, or policies, you agree to abide and be bound by those changes. If you do not agree with any changes to this user agreement, you may close your account.</p>

      {/*/////////*/}

      <h2>Opening a PayPal Account</h2>
      <p>
        We offer two types of PayPal accounts: PayPal personal accounts (or “personal accounts”) and PayPal business accounts (or “business accounts”), both covered by this user agreement.
      </p>
      <p>
        All PayPal accounts let you do things like:
      </p>
      <ul>
        <li><span className="pseudo-link">Send</span> and receive money.</li>
        <li><span className="pseudo-link">Buy</span> things online, using mobile devices or in stores.</li>
        <li>Make payments using your credit card, debit card, bank account, or other payment options, including funds held as balance in a business account (if we have verified the <span className="pseudo-link">required identifying information</span> you provide to us) or funds held in a PayPal Balance account linked to a personal account if you choose to open a PayPal Balance account. The terms “PayPal Balance account” or “Balance Account” in this user agreement are used to refer to a PayPal Balance account and shall include any account currently or previously known as a PayPal Cash or a PayPal Cash Plus account, as applicable. More information about Balance Accounts can be found in the <span className="pseudo-link">PayPal Balance Terms and Conditions</span>.</li>
        <li>Accept credit card, debit card, bank account, Balance Account, and other payment methods from others.</li>
      </ul>
      <p>You are responsible for maintaining adequate security and control of any and all IDs, passwords, personal identification numbers, or any other codes that you use to access your PayPal account and the PayPal services. You must keep your mailing address, email address and other contact information current in your PayPal account profile.</p>
      <h3>Personal accounts</h3>
      <p>If you primarily need to make purchases and send personal transactions to family and friends, a personal account is probably right for you. With a personal account you can do things like:</p>

      <ul>
        <li><span className="pseudo-link">Send</span> personal transactions to and request personal transactions from friends and family.</li>
        <li><span className="pseudo-link">Buy</span> goods and services.</li>
      </ul>

      <p>
        You can also use a personal account to receive money for the sale of goods and services and hold that money in a linked Balance Account or transfer it to a linked bank account or debit card if you do not open a Balance Account, but if you plan to use your personal account primarily to sell things, you should open a business account. You can also convert your personal account to a business account should circumstances change.
      </p>

      <h3> Business accounts</h3>
      <p>We recommend business accounts for people and organizations that primarily use PayPal to sell goods or services or to receive donations, even if your business is not incorporated. With a business account, you can do things like:</p>
      <ul>
        <li>Use a company or business name as the name on your business account.</li>
        <li>Allow employees access to some of the features of your business account.</li>
        <li>Sign up for <span className="pseudo-link">PayPal products</span> that meet your business needs.</li>
      </ul>
      <p>Business accounts may be subject to fees that differ from the fees applicable to personal accounts. <span>Business accounts are not eligible to receive “</span><span className="pseudo-link">personal transactions</span><span>”, although they may send personal transactions to friends and family with an eligible PayPal account.</span></p>
      <p><strong>By opening up a business account or converting a personal account to a business account, you certify to us that you are using it primarily for a business or commercial purpose. You also consent to PayPal obtaining your personal and/or business credit report from a credit reporting agency at account opening and whenever we reasonably believe there may be an increased level of risk associated with your business account.</strong></p>

      {/*/////////*/}

      <h2>Closing Your PayPal Account</h2>
      <p>You may close your PayPal account and terminate your relationship with us at any time without cost, but you will remain liable for all obligations related to your PayPal account even after the PayPal account is closed. When you close your PayPal account, we will cancel any scheduled or incomplete transactions. If you have a Balance Account linked to a personal account, you must <span className="pseudo-link">withdraw</span> or transfer any funds held in your Balance Account before closing your personal account, and closing a personal account will result in PayPal automatically closing any linked Balance Account. If you have a business account, you must <span className="pseudo-link">withdraw</span> or transfer any balance from your business account before closing it, and if you have not provided the <span className="pseudo-link">required identifying information</span> to us, or if we are unable to verify the <span className="pseudo-link">required identifying information</span> you provide, you must transfer any balance in your business account to a linked bank account or debit card. You cannot withdraw or transfer digital gift certificates/cards that are purchased through <span className="pseudo-link">PayPal Digital Gifts</span> and linked to your personal or business account as payment methods. However, even without your PayPal account, you can still use the codes you received by email when you purchased the gift certificates/cards to make purchases.</p>
      <p>In certain cases, you may not close your PayPal account, including:
      </p>
      <ul>
        <li>To evade an investigation.</li>
        <li>If you have a pending transaction or an open dispute or claim.</li>
        <li>If your PayPal account has a negative balance.</li>
        <li>If your PayPal account is subject to a hold, limitation or reserve.</li>
      </ul>

      {/*/////////*/}

      <h2>Link or Unlink a Payment Method</h2>
      <p>You can link or unlink certain payment methods such as a credit card, debit card, a U.S. bank account or PayPal Credit to your PayPal account. If you open a Balance Account it will be linked to your personal account as a payment method and you can unlink the Balance Account by withdrawing all funds and closing it. If you have a Balance Account and have also opened a PayPal Debit Mastercard® account (PayPal Debit Card), your PayPal Debit Card, which allows you to access the balance in your PayPal Balance Account, will be reflected as the Payment Method in PayPal checkout.</p>
      <p>Before linking a bank account, you should review and understand the consumer protection rights and remedies available for different payment sources under the Electronic Fund Transfer Act (EFTA) and Fair Credit Billing Act (FCBA). Please also refer to <span className="pseudo-link">Authorization to Charge Your Payment Method</span> for additional terms regarding our authority to charge your linked payment methods and your right to revoke our authority to charge such payment methods.</p>
      <p>Please keep your payment method information current (e.g., credit card number and expiration date). If this information changes, we may update it using information and third-party sources available to us without any action on your part. If you do not want us to update your payment method information, you may unlink that payment method by removing it from your PayPal account. If we update your payment method, we will keep any preference setting attached to such payment method. You may need to confirm your payment method, so we can verify that it is valid and belongs to you. To do this, you authorize us to make credits and debits to your payment method as follows:</p>
      <ul>
        <li>If your payment method is a card that we require you to confirm, we will verify it by charging $1.95 U.S. dollars to the card and promptly refunding this amount after we have confirmed it.</li>
        <li>If your payment method is a bank account that you manually link to your PayPal account by adding your bank routing and account numbers (instead of verifying using a real-time account verification service, if available to you), we will make two small deposits to your provided bank account of less than $1.00 U.S. dollar each that you will need to confirm with us, along with one corresponding single debit from your bank account totaling the amount of the two small deposits.</li>
      </ul>


      {/*/////////*/}


      <h2>Authorization to Charge Your Payment Method</h2>
      <h3>General authorization to charge your payment method</h3>
      <p>By linking a payment method to your PayPal account, as described in the section <span className="pseudo-link"><strong>Link or Unlink a Payment Method</strong></span>, you authorize PayPal to charge such linked payment method (a) whenever you choose such linked payment method to send money or purchase something using PayPal, (b) in connection with any errors, claims, or disputes, and (c) for amounts you owe to PayPal.</p>

      <h3>Authorization of specific transactions</h3>
      <p>Each time you send money using the Send Money feature in your PayPal account, you authorize PayPal to charge the selected payment method for the amount you are sending and any applicable fees.</p>
      <p>Each time you use PayPal to purchase something from a seller or make a donation, you authorize PayPal to charge your selected or backup payment method for the amount specified during the checkout process and any applicable fees. If the amount of your transaction changes after checkout, you authorize PayPal to charge the selected or backup payment method for the revised amount (for example, if the amount increases because you add another product or service (e.g., expedited delivery), the amount decreases, or the amount is split because a portion of your order is canceled or delayed).</p>
      <p>If you have entered into an automatic payment agreement with a seller, you authorize PayPal to charge your preferred or selected payment method each time you make a purchase from the seller, including via recurring payments or any other future transactions scheduled in advance. If your preferred or selected payment method cannot be charged, you authorize PayPal to charge the relevant backup payment method linked to your PayPal account.</p>

      <h3>Revoking your authorization</h3>
      <p>You can revoke our authority to charge a payment method by unlinking that payment method from your PayPal account as described in the section <span className="pseudo-link"><strong>Link or Unlink a Payment Method</strong></span>. Such revocation only applies to transactions that are initiated, by you or by a seller, after you unlink the payment method. It does not affect our authority to charge the payment method for a transaction you have already authorized, including our authority to re-present a charge to the unlinked payment method or charge the unlinked payment method as a backup payment method if the original transaction occurred before you unlinked that payment method. Unlinking a payment method also does not revoke our authority to charge or credit the payment method in connection with an error, dispute, or claim with respect to a transaction initiated before you unlinked that payment method. If there is a pending transaction with a seller where you have authorized the transaction, but the seller has not yet completed the transaction, you may not be able to unlink the payment method used until the transaction is completed.</p>

      {/*/////////*/}

      <h2>Receiving Funds, Holding a Balance, or Transferring Funds</h2>
      <p>Unless otherwise expressly stated, all references to “funds” in this user agreement mean money denominated in sovereign currency and not cryptocurrency or any other form of asset.</p>

      <h3>Personal accounts</h3>
      <p>Money sent to your personal account cannot be held as a balance in your personal account. When you receive money to your personal account, you can:</p>
      <ul>
        <li>transfer the money via a standard transfer to an eligible debit card or your bank account linked to your personal account for free;</li>
        <li>transfer the money via an Instant Transfer to an eligible debit card or bank account linked to your personal account, subject to the fees applicable to such transfers that can be found on the <span className="pseudo-link">Fees for Transferring Funds (for personal accounts)</span> table and that will be disclosed to you in advance each time you initiate such a withdrawal;</li>
        <li>
          <p>request PayPal to send the money to you by check (subject to a non-refundable fee of $1.50 U.S. dollars for PayPal personal accounts without a linked Balance Account, which will be deducted from the amount of the transfer). If you request a paper check from us, you must cash it within 180 days of the date of issuance, or we will return the funds to your linked Balance Account if you have one or hold the funds on your behalf and retain the non-refundable fee of $1.50 U.S. dollars, if applied. We will not send a check to a post office box, and we will only send a check to an address once we have confirmed that it belongs to you;</p>
        </li>
        <li>open a Balance Account to hold and use the money as a balance in your Balance Account for purchases or to send personal transactions to friends and family members. A Balance Account is separate account from your personal account. If you open a Balance Account, we will automatically link your Balance Account to your personal account. If you have a Balance Account and have also opened a PayPal Debit Card, your PayPal Debit Card, which allows you to access the balance in your PayPal Balance Account, will be reflected as the Payment Method in PayPal checkout. More information about Balance Accounts can be found in the <span className="pseudo-link">PayPal Balance Terms and Conditions</span>.</li>
      </ul>
      <p>You also cannot transfer money to your personal account to be held as a balance in your personal account. You will need to open a Balance Account to hold the money as a balance in your Balance Account, which can be used for purchases or to send personal transactions to friends and family members. More information about Balance Accounts can be found in the <span className="pseudo-link">PayPal Balance Terms and Conditions</span>.</p>
      <p>Except when PayPal acts as your agent and custodian to place funds in one or more banks insured by the Federal Deposit Insurance Corporation (FDIC) that we choose in our discretion (“<span className="pseudo-link">Program Banks</span>”), as provided below, any balance in your Balance Account and any funds sent to you which have not yet been transferred to a linked bank account or linked debit card if you do not have a Balance Account, represent unsecured claims against PayPal that are not eligible for FDIC pass-through insurance.</p>
      <p>If your Balance Account is not eligible for FDIC pass-through insurance, PayPal combines your PayPal funds with the PayPal funds not eligible for pass-through FDIC insurance of other PayPal users and invests those funds in liquid investments in accordance with state money transmitter laws. PayPal owns the interest or other earnings on these investments. However, the claim against PayPal represented by funds held in your Balance Account is not secured by these investments and you do not have any ownership interest (either legal or beneficial) in these investments. These pooled amounts are held apart from PayPal’s corporate funds, and PayPal will neither use these funds for its operating expenses or any other corporate purposes nor will it voluntarily make these funds available to its creditors in the event of bankruptcy. You will not receive any interest or other return on the funds held with PayPal.</p>
      <p>If you have opened a PayPal Debit Card Mastercard® account, enrolled in Direct Deposit, or bought or received cryptocurrency with your personal PayPal Balance account, we will place your U.S. dollar PayPal Balance funds in one or more <span className="pseudo-link"><strong>Program Banks</strong></span>, where they will, subject to certain conditions, be eligible for pass-through FDIC insurance, up to applicable limits. FDIC insurance protects against the failure of the <span className="pseudo-link"><strong>Program Banks</strong></span>, not the failure of PayPal. PayPal is not a bank, does not take deposits and is not FDIC insured. Any other balance funds and all cryptocurrencies are not held in FDIC-insured bank deposits. Cryptocurrencies may lose value. See <span className="pseudo-link"><strong>PayPal Balance Terms and Conditions</strong></span>. </p>

      <h3>PayPal Savings</h3>
      <p>You may also link PayPal Savings to your PayPal personal account. PayPal Savings is a deposit account provided by Synchrony Bank in which you can deposit funds and subsequently access those funds by transferring them back to your eligible Balance Account. A PayPal Balance Account is required to set up and utilize PayPal Savings.</p>
      <p>PayPal Savings does not act as a payment method to fund transactions with merchants for goods and services or to send or receive personal transactions from friends and family.</p>
      <p>All deposits to and withdrawals from PayPal Savings are made via transfer to your PayPal balance. When you link PayPal Savings to your PayPal account, you authorize PayPal and Synchrony Bank to transfer funds between your eligible Balance Account and PayPal Savings in accordance with your instructions. More information about sending and receiving funds from PayPal Savings can be found in the <span className="pseudo-link">PayPal Balance Terms and Conditions</span>.</p>

      <h3>Business accounts</h3>
      <p>Money sent to a business account can be held directly as balance in the business account. The balance functionality in your business account will differ depending on whether we have been able to verify the <span className="pseudo-link">required identifying information </span>that you provide to us.</p>
      <p>The required identifying information is:</p>
      <ul>
        <li>For sole proprietorships: name, physical address, date of birth and taxpayer identification number; or</li>
        <li>For other types of business legal entities: business name, business address, taxpayer identification number, and proof of business existence.</li>
      </ul>
      <p>If we have not verified the <span className="pseudo-link">required identifying information</span>, a balance in your business account can only be held in your business account and transferred to a linked bank account or debit card or you can request PayPal to send you the money by check (subject to a non-refundable fee of $1.50 U.S. dollars for PayPal business accounts, which will be deducted from the amount of the withdrawal). Your balance may also be used to fund payments or money owed to PayPal, such as payments on your PayPal Working Capital account or monies owed for <span className="pseudo-link">refunds or chargebacks</span>.</p>
      <p>If we have verified the <span className="pseudo-link">required identifying information</span>, a balance can be held in your business account, transferred to a linked bank account or debit card, used to make purchases or send money to others.</p>
      <p>If you have a business account and we have verified the <span className="pseudo-link">required identifying information</span> that you have provided to us, you may transfer money to the balance of your business account from any bank account linked to your business account by requesting an electronic transfer from your bank account. If we have not verified the <span className="pseudo-link">required identifying information</span> that you have provided to us, you cannot add funds to your balance in a business account.</p>
      <p>You can withdraw balance from your business account by:</p>
      <ul>
        <li>transferring it via a standard transfer to an eligible debit card or a bank account linked to your business account for free,</li>
        <li>transferring it via an Instant Transfer to an eligible debit card or bank account linked to your business account, subject to the fees applicable to such transfers that can be found on the <span className="pseudo-link">Fees for Transferring Balance (for business accounts)</span> table and that will be disclosed to you in advance each time you initiate such a withdrawal,</li>
        <li>
          <p>conducting a cash withdrawal transaction if you have a PayPal Debit Card via (i) a bank teller at a participating bank branch or other financial institution, or (ii) an ATM (automated teller machine) (fees may apply), or</p>
        </li>
        <li>
          <p>requesting a check from us (subject to a non-refundable fee of $1.50 U.S. dollars for PayPal business accounts, which will be deducted from the amount of the withdrawal). If you request a paper check from us, you must cash it within 180 days of the date of issuance, or we will return the funds to your balance in your business account and retain the non-refundable fee of $1.50 U.S. dollars. We will not send a check to a post office box, and we will only send a check to an address once we have confirmed that it belongs to you.</p>
        </li>
      </ul>
      <p>Any funds you hold in your business account represent unsecured claims against PayPal. PayPal is not a bank, does not take deposits and is not FDIC insured. You will not receive any interest or other return on the funds held with PayPal. PayPal combines your PayPal funds with the PayPal funds of other PayPal users and invests those funds in liquid investments in accordance with state money transmitter laws. PayPal owns the interest or other earnings on these investments. However, the claim against PayPal represented by your PayPal funds is not secured by these investments and you do not have any ownership interest (either legal or beneficial) in these investments. These pooled amounts are held apart from PayPal’s corporate funds, and PayPal will neither use these funds for its operating expenses or any other corporate purposes nor will it voluntarily make these funds available to its creditors in the event of bankruptcy.</p>

      <h3>Restrictions on transfers or withdrawals from PayPal accounts</h3>
      <p>To protect us and our users from loss, we may delay a withdrawal, in certain situations, including if we need to confirm that you have authorized the withdrawal or if other payments to your PayPal account have been subject to a reversal (for example, as a result of a <span className="pseudo-link">chargeback</span>, bank reversal, or <span className="pseudo-link">dispute by a buyer</span>). If we place a <span className="pseudo-link">limitation </span>on your PayPal account, a payment is subject to a hold, or your account or an associated account has a negative balance in any currency while a withdrawal from your PayPal account is pending, you will have to reinitiate the withdrawal once the limitation or hold has been lifted, or negative balance is fully paid off.</p>
      <p>We may set limits on your withdrawals. Completing two out of three of the following steps can help us verify your PayPal account, which may allow us to increase your withdrawal limit:</p>
      <ul>
        <li>Verifying your bank account.</li>
        <li>Linking and confirming your credit or debit card information.</li>
        <li>Providing your social security number.</li>
      </ul>

      {/*/////////*/}

      <h2>Managing Your Money in Multiple Currencies</h2>
      <h3>Holding currency other than U.S. dollars</h3>
      <p>Your balance in your business account or in your Balance Account, if you have one, may be held in currencies other than U.S. dollars.</p>
      <p>If you hold a balance in your business account or in your Balance Account:</p>
      <ul>
        <li>We may allow you to convert the funds to balance in another currency. If you convert funds in your account, PayPal’s transaction exchange rate (including our <span className="pseudo-link"><strong>currency conversion spread</strong></span>) will be used. We may, at our discretion, impose limits on the amount of money you can convert or the number of conversions you can perform.</li>
        <li>You may only withdraw the funds in U.S. dollars. In order to withdraw funds in your account held in another currency you will have to convert the currency to U.S. dollars, or it will be converted for you at the time of your withdrawal. PayPal’s currency conversion rate, including our <span className="pseudo-link"><strong>currency conversion spread</strong></span>, will be used.</li>
      </ul>
      <p>To receive money in a currency your account is not currently configured to accept, it may be necessary to create a balance in your business account or in your Balance Account, in that currency or convert the money into another currency. Certain currencies can only be received by converting the money into another currency that PayPal allows you to hold. If the money is converted, PayPal’s transaction exchange rate (including our <span className="pseudo-link">currency conversion spread</span>) will be used.</p>
      <p>You are responsible for all risks associated with maintaining multiple currencies in a Balance Account or business account. You may not manage or convert multiple currencies for speculative trading purposes, conversion arbitrage, conversion options, or any other activity that PayPal determines is primarily for the purpose of gaining or making money based on currency conversion rates. PayPal may hold, cancel, or reverse any transaction we determine to violate this policy.</p>

      <h3>How we convert currency</h3>
      <p>If PayPal converts currency, it will be completed at the transaction exchange rate we set for the relevant currency exchange. The transaction exchange rate is adjusted regularly and includes a <span className="pseudo-link">currency conversion spread</span> applied and retained by us on a base exchange rate to form the rate applicable to your conversion. The base exchange rate is based on rates within the wholesale currency markets on the conversion day or the prior <span className="pseudo-link">Business Day</span>; or, if required by law or regulation, set at the relevant government reference rate(s).</p>
      <p>If we cannot communicate with you at the time you authorize an in-person payment (for example, because you or the seller do not have connectivity at the time you authorized the transaction), and we perform a currency conversion for that payment, we will use the transaction exchange rate in effect at the time the payment transaction is processed by the seller.</p>
      <p>For some uses of your PayPal accounts, PayPal may determine currency conversion is necessary. The applicable currency conversion spread can be found on our Fees page under the heading <span className="pseudo-link">Currency conversion</span>.</p>
      <p><span className="pseudo-link">Holds or limitations </span>may delay currency conversion of amounts until such holds or limitations are released. This may result in the application of a currency conversion that is different from the transaction exchange rate that would have been applied had no account hold(s) or limitation(s) been in place. Currency conversions in these circumstances may be more favorable or less favorable to you.</p>

      <h3>Currency conversion choices</h3>
      <p>When your payment is funded by a debit or credit card and PayPal determines a currency conversion is necessary, you consent to and authorize PayPal to convert the currency in place of your debit or credit card issuer. You may have the right to have your card issuer perform the currency conversion, if applicable for that card issuer and network. Currency preference selections may be presented to you in various forms, including setting the currency of your card, a choice of which currency is used for the transaction, whether PayPal or your card issuer performs the conversion, or which conversion rate is used for the transaction, and may be made available individually for each card and for each automatic payment agreement. If your card issuer converts the currency, your card issuer will determine the currency conversion rate and what fees they may charge.</p>
      <p>PayPal will always perform the conversion for transactions where your Balance Account balance, business account balance or linked bank account is the payment method. For PayPal Debit Card transactions, the currency conversion will be performed by Mastercard, except for PayPal Debit Card transactions in PayPal checkout for which PayPal will perform the currency conversion. </p>

      {/*/////////*/}

      <h2>Taxes and Information Reporting</h2>
      <h3>General information</h3>
      <p>Our <span className="pseudo-link"><strong>consumer fees</strong></span> and <span className="pseudo-link"><strong>merchant fees</strong></span> are exclusive of any taxes, charges, or similar assessments of any nature, including, without limitation, value-added, sales, digital services, stamp, transfer, or withholding taxes, assessable by any jurisdiction or governmental authority (collectively, “Taxes”).</p>
      <p>Each of us shall be responsible to pay our own Taxes arising in connection with the performance of our respective obligations from your use of PayPal services.</p>

      <h3>Paying for PayPal’s services</h3>
      <p>If you are paying for a PayPal service, you agree to pay to PayPal the amount of any legally applicable Taxes imposed on any amount due from you to PayPal.</p>
      <p>You agree not to withhold any Taxes on amounts payable by you to PayPal unless you are legally required to do so. If you withhold any Taxes, the amount payable by you to PayPal shall not be decreased by the amount withheld. You agree that PayPal is entitled to receive the full amount that would have been received had there been no withholding of any Taxes. Upon request by PayPal, you will deliver to PayPal in a timely manner any written documentation evidencing your payment of Taxes to the relevant authorities.</p>

      <h3>Your additional responsibilities and acknowledgments</h3>
      <p>It is your responsibility to determine what, if any, taxes apply to the payments you make or receive, and it is solely your responsibility to assess, collect, report, and remit the correct taxes to the appropriate authority. PayPal is not responsible for determining whether any taxes apply to your transaction, or for calculating, collecting, reporting, or remitting taxes arising from any transaction.</p>
      <p>You acknowledge that PayPal may have a legal obligation to make reports, and will make such reports, to tax authorities regarding transactions that we process on your behalf. Additional information can be found in our <span className="pseudo-link"><strong>Help Center</strong></span>.</p>

      <h3>Information reporting</h3>
      <p>We may request that you provide PayPal with your tax identification number and/or other tax-related documentation or information. If you do not provide the requested information to PayPal or keep documentation up to date in your PayPal account, you may be subject to account <span className="pseudo-link">holds or limitations</span> and withholding Taxes at the applicable rates on gross payments received. PayPal will remit any such withholding Taxes to the appropriate tax authorities, and you acknowledge PayPal may be prohibited from refunding any amounts to you once these have been sent to the appropriate tax authorities.</p>
      <p>If a currency conversion is required on a payment of withholding Taxes, the amount due to the relevant tax authority will be calculated by PayPal in accordance with our <span className="pseudo-link">currency conversion</span> terms when we process the withholding Taxes. To minimize risks relating to exchange rate fluctuations, account holds and limitations, tax withholding, and remittance to the relevant tax authority, you should add or confirm your taxpayer information to PayPal.</p>

      {/*/////////*/}

      <h2>Account Statements</h2>
      <p>You have the right to receive an account statement showing your PayPal account activity. You may view your PayPal account statement by logging into your PayPal account. If you have a Balance Account linked to your personal account, you will receive two statements: one showing all transactions in your personal account and an additional statement showing only transactions involving your Balance Account.</p>

    </div>
  );

};

export default AboutAccount;