import React from 'react';

const PopupDetails = ({ text, children, imageClassName, imageSrc }) => (
  <>
    <div className="info-tooltip_drop-inner">
      <div className="info-tooltip_section">
        { text }
        { children }
      </div>
      {
        imageSrc &&
        <div className="info-tooltip_image">
          <img className={ imageClassName } src={ imageSrc } />
        </div>
      }
    </div>
  </>
);

export default PopupDetails;
