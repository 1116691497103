const allPlansInclude = [
  {
    id: 1,
    title: 'Unlimited talk, text, & data'
  },
  {
    id: 2,
    title: '4G LTE'
  },
  {
    id: 3,
    title: 'Mexico & Canada talk, text, & data'
  },
  {
    id: 4,
    title: 'international texting'
  },
  {
    id: 5,
    title: 'Call Filter Spam Blocker'
  }
];

const planFeatures = {
  Unlimited5G: {
    title: 'Unlimited 5G',
    description: 'Reliable 5G performance'
  },
  Unlimited5GUltra: {
    title: 'Unlimited 5G Ultra',
    description: 'Our fastest 5G network experience, up to 10X faster, that never slows you down'
  },
  Get400ToNewPhone: {
    title: 'Get up to $400 towards a new phone when you trade in'
  },
  Get800ToNewPhone: {
    title: 'Get up to $800 towards a new phone when you trade in'
  },
  PlusGet800ToNewPhone: {
    title: 'Plus, get up to $800 towards a new phone when you trade in'
  },
  PlusGet800ToNextPhone: {
    title: "Get up to $800 towards your next phone when you trade in"
  },
  plusPerks: (perksCount) => (
    {
      title: `+ ${perksCount} perk${perksCount > 1 ? 's' : ''} of your choice`,
      description: `Up to a $${perksCount > 1 ? '55' : '30'}/mo value`
    }
  ),
  choosePerks: (perksCount) => (
    `Choose ${perksCount === 1 ? 'a' : perksCount} perk${perksCount > 1 ? 's' : ''} below`
  ),
  FiveGNationwide: {
    title: "5G Nationwide",
    description: "Reliable 5G performance"
  },
  UnlimitedData: {
    title: "Unlimited data"
  },
  FiveGUltraWideband: {
    title: "5G Ultra Wideband",
    description: "Our fastest 5G network experience, up to 10X faster, that never slows you down"
  },
  FiftyGbPremiumNetworkAccess: {
    title: "50 GB premium network access, then unlimited data"
  },
  UnlimitedPremiumNetworkAccess: {
    title: "Unlimited premium network access"
  },
  TwentyFiveGbPremiumHotSpotData: {
    title: "25 GB premium hotspot data"
  },
  FiftyGbPremiumHotSpotData: {
    title: "50 GB premium hotspot data"
  }

};

const bauPerks = {
  allOnSixMonth: {
    description: "Try Disney+, Apple Arcade or Google Play Pass, and Apple Music for 6 mo. on us"
  },
  huluDisneyEspn: {
    image: 'disney-bundle'
  },
  appleArcadeOrGooglePlay: {
    image: 'arcade-gpp'
  },
  fiftyPercentOffHomeInternet: {
    description: "Up to 50% off Verizon Home Internet where available"
  },
  appleMusicOnSixMonths: {
    description: "Try Apple Music for 6 mo. on us"
  },
  FiftyPercentOffWatchTabletWifi: {
    description: "Up to 50% off watch, tablet, WiFi on the go, or in-car WiFi plan"
  },
  TravelPass: {
    description: "1 TravelPass day per month"
  },
  SixHundredGbVerizonCloud: {
    description: "600 GB Verizon Cloud"
  },
  AppleMusic: {
    image: 'apple-music'
  }
};

const perkTitles = [
  "Entertainment",
  "Entertainment Store",
  "Music",
  "Gaming",
  "Global Calling +",
  "International Roaming",
  "Work Anywhere",
  "Security",
  "Home Internet"
];

const perkFeatures = {
  DisneyBundle: {
    image: 'disney-bundle'
  },
  EntertainmentStore: {
    icon: 'play',
    title: '$10 credit/mo for our online store with 100+ apps like Netflix, Disney+, HBO Max, and more'
  },
  EntertainmentStore12: {
    icon: 'play',
    title: '$12 credit/mo for our online store with 100+ apps like Netflix, Disney+, HBO Max, and more'
  },
  AppleMusic: {
    image: 'apple-music',
    imageNote: 'Family',
    imageNoteKlass: '-apple-music-note'
  },
  GameXBox: {
    image: 'xbox-game',
    imageNote: 'Ultimate'
  },
  GameArcade: {
    image: 'arcade-gpp'
  },
  GlobalCalling: {
    icon: 'global',
    title: 'Unlimited calls to landlines in 60+ countries & mobile phones in 30+ countries'
  },
  Roaming: {
    icon: 'roaming',
    title: '3 TravelPasses/mo<br/>(can be banked month-to-month up to 15)'
  },
  AnyWhereMobile: {
    icon: 'mobile',
    title: '50 GB premium mobile hotspot'
  },
  AnyWhereWatch: {
    icon: 'watch',
    title: '50% off a connected device plan'
  },
  SecurityMCAfee: {
    image: 'mc-afee',
    imageNote: 'Safe Family plan'
  },
  SecurityRing: {
    image: 'ring',
    imageNote: 'Protect Plus plan'
  },
  SecurityVerizon: {
    icon: 'security',
    title: 'Verizon Digital Secure'
  },
  HomeInternet25: {
    icon: 'wifi-router',
    title: '$25 off monthly Verizon Home Internet price'
  }
};
const perksSectionTitle = 'Available perks';
const perksSectionText = 'Perks are included on us in Plan B and Plan C.';
const pricingLinesDescription = 'The more phone lines you have, the more you save per line/mo.';
const linesEmptyPlanTitle = 'Select Plan';

const bauPerkNote =  (price) => (`Up to a $${price}/mo value`);

export {
  allPlansInclude, planFeatures,
  perkTitles, perkFeatures,
  perksSectionTitle,
  perksSectionText,
  pricingLinesDescription,
  linesEmptyPlanTitle,
  bauPerks, bauPerkNote
};
