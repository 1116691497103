import React, { useRef, useEffect, useContext, useState } from 'react';
import { OptionsContext } from './contexts';

const ReadonlyImage = ({ src, onLoad, setWrapper }) => {
  const {
    imageUseEffectFunction, onSelectionAdd
  } = useContext(OptionsContext);
  const [ currentDimensions, setCurrentDimensions ] = useState({});
  const [ , setIntervalId ] = useState(null);
  const ref = useRef(null);

  const getIntervalId = () => {
    let intervalId;
    setIntervalId((oldValue) => {
      intervalId = oldValue;
      return oldValue;
    });
    return intervalId;
  };

  const onComplete = () => {
    // Note here: in Safari 16 when you open this component in modal.
    // Here you will have not attached image with width=0, height=0.
    // As workaround the inverval for checking is added
    let intervalId = getIntervalId();
    if (intervalId) {
      clearTimeout(intervalId);
    }
    intervalId = setInterval(() => {
      const { width, height } = ref?.current?.getBoundingClientRect() || {};
      if (!!width && !!height) {
        clearInterval(intervalId);
        setCurrentDimensions({ width, height });
        setWrapper(ref);
        onLoad({ width, height });
      }
    }, 100);
  };

  useEffect(() => {
    return () => {
      const intervalId = getIntervalId();
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (!imageUseEffectFunction) {
      return () => {};
    }
    return imageUseEffectFunction(ref.current, onSelectionAdd, currentDimensions);
  }, [ src, currentDimensions ]);

  return (
    <img
      width="100%"
      ref={ ref }
      src={ src }
      onLoad={ onComplete }
    />
  );
};

export default ReadonlyImage;
