import React, { useState } from 'react';
import QuantPortal from '../common/portal';
import Statistic from "../../statistic";

const PlanArticle = ({ article, planId, filledLines, clickedLineIndex, planList }) => {
  const { title, text, icon, iconPath, iconClassName, showText } = article;
  const [showTextState, setShowTextState] = useState(typeof showText !== 'undefined' ? showText: true);

  const toggleClickHandle = () => {
    const idx = planList.findIndex(planListItem => planListItem.id === planId);
    const lineIndex = typeof clickedLineIndex !== 'undefined' ? clickedLineIndex : filledLines;
    Statistic.updateExpandValuePlans(lineIndex, idx, title, !showTextState);
    setShowTextState(!showTextState);
  };

  return <div className="plan-details-value-wrapper">
    {
      icon && <div className="plan-details-icon">
        <svg className={ `icon -${ icon } ${iconClassName}` }>
            <use xlinkHref={ `#svg_${ icon }` }/>
        </svg>
      </div>
    }
    {
      iconPath && <img className={`icon ${iconClassName}`} src={iconPath} />
    }
    <h6 className={`plan-details-subtitle toggle-text_title`} onClick={toggleClickHandle}>
      <svg className={ `icon -dropdown toggle-text_dropdown-icon ${showTextState ? '-up': '-down'}` }>
        <use xlinkHref={ `#svg_arrow-down` }/>
      </svg>
      {
        title.split('\n').map((value, idx) => <span key={idx}>{value}</span>)
      }
    </h6>
    {
      showTextState ? text.split('\n').map((value, idx) => <p key={idx} className="plan-details-value" dangerouslySetInnerHTML={{__html: value}}/>) : ''
    }
  </div>;
}

const renderArticle = (planId, filledLines, clickedLineIndex, planList) => (article, idx) =>
  <PlanArticle
    key={idx}
    article={article}
    planId={ planId }
    filledLines={ filledLines }
    clickedLineIndex={ clickedLineIndex }
    planList={ planList }
  />;

const PlanDetailsDescription = ({ title, articles, index, planId, filledLines, clickedLineIndex, planList  }) =>
  <div className={`plan-details -col-${index + 1}`}>
    <h6 className="plan-details-title">{title}</h6>
    <div className="hr -secondary" />
    {articles.map(renderArticle(planId, filledLines, clickedLineIndex, planList))}
  </div>

const renderPlanDetailsDesciption = (planId, filledLines, clickedLineIndex, planList) => ({ title, articles }, idx) =>
  <PlanDetailsDescription
    key={`${planId}${idx}`}
    planId={ planId }
    filledLines={ filledLines }
    planList={ planList }
    clickedLineIndex={ clickedLineIndex }
    title={title}
    articles={articles}
    index={idx}
  />

const PlanDetailsModal = ({
                            open, handleClose, planId, onSelect, onCustomize,
                            planList, getPlanLong, getPlanShort,
                            filledLines, buttonSelectName, clickedLineIndex }) => {
  const [currentPlanId, setCurrentPlanId] = useState(planId);





  const changePlan = (planId) => {

    const idx = planList.findIndex(planListItem => planListItem.id === planId);
    const lineIndex = typeof clickedLineIndex !== 'undefined' ? clickedLineIndex : filledLines;
    setCurrentPlanId(planId);
    Statistic.updateValue(`lines.${lineIndex}.plans.${idx}.PlanDetailsClickedInPopup`);
  };

  const сustomizeHandle = (plan) => {
    const { name, price } = plan;

    const idx = planList.findIndex(planListItem => planListItem.id === currentPlanId);
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsSelected`);
    handleClose();
    onCustomize(plan, true);
  };

  const tabs = (
      <ul className="tabs-list">
        {planList && planList.map((planItem) => {
          return (
              <li className="tabs-list-item" key={planItem.id}>
                <div className={`tabs-list-link ${currentPlanId === planItem.id ? '-active':''}`} onClick={() => changePlan(planItem.id)}>{planItem.name}</div>
              </li>
          )
        })}
      </ul>
  );

  return <QuantPortal tabs={tabs} open={open} handleClose={handleClose}>
    <div className="plan-details-short">
      <h6 className="plan-details-short_title">{getPlanLong(currentPlanId).name}</h6>
      <div className="plan-details-short_subtitle">
        <div className="plan-details-short_price">${getPlanLong(currentPlanId).price}</div>
        <div className="plan-details-short_price-tip">
          /line per month
          <br />
          Plus taxes & fees.
        </div>
      </div>
      <div className="plan-details-short_button">
        {onSelect || onCustomize  ? (
          (getPlanShort(currentPlanId).perkCount > 0) ? (
            <button onClick={() => сustomizeHandle(getPlanShort(currentPlanId))} className="button -bordered -full-width" type="button">Customize this plan</button>
          ) : (
            <button onClick={() => onSelect(getPlanShort(currentPlanId), true)} className="button -bordered -full-width" type="button">
              {`${buttonSelectName ? buttonSelectName : 'Choose plan'}`}
            </button>
          )
        ):(
          (getPlanShort(currentPlanId).perkCount > 0) ? (
            <button disabled className="button -bordered -full-width" type="button">Customize this plan</button>
          ) : (
            <button disabled className="button -bordered -full-width" type="button">
              {`${buttonSelectName ? buttonSelectName : 'Choose plan'}`}
            </button>
          )
        )}
      </div>
    </div>

    <div className="plan-details-grid">
      {
        getPlanLong(currentPlanId).description.map(
          renderPlanDetailsDesciption(currentPlanId, filledLines, clickedLineIndex, planList)
        )
      }
    </div>
  </QuantPortal>
};

export default PlanDetailsModal;