import React, { useContext, useState } from "react";
import cn from "classnames";
import SvgIcon from "../../../../../../common/components/svg-icon";
import { IndexData } from "../../../index-context";
import { comparisonScorecardWordcloudGearShowHideWord } from "../../../../../common/google-analytics/events";
import KeywordsListEditor from "./keywords-list-editor";
import { KeywordsData } from "./index";

const KeywordsListLi = ({ keyword, toggleHide, conceptId }) => {
  const [ edit, setEdit ] = useState(false);
  const [ updating, setUpdating ] = useState(false);
  const { keywords, unmerge, setKeywords, showKeywordDetails } = useContext(KeywordsData);
  const { init } = useContext(IndexData);

  const onCheckedChange = (v) => {
    keyword.checked = v;
    setKeywords([ ...keywords ]);
  };

  const showEditor = () => {
    setEdit(true);
  };
  const cancelEdit = () => {
    setEdit(false);
  };

  const onHideClick = (keyword) => {
    comparisonScorecardWordcloudGearShowHideWord(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: conceptId });
    toggleHide(keyword);
  };

  return (
    <li className="keywords-list_item keyword">
      <div className="keyword_checker">
        <label className="checker">
          <input
            className="checker_checkbox"
            type="checkbox"
            checked={ keyword.checked || false }
            onChange={ (event) => { onCheckedChange(event.target.checked); } }
          />
          <span className="checker_label" />
        </label>
      </div>

      <div className="keyword_control">
        <span
          className={ cn("hide-button icon-link -no-text", { '-gray': updating }) }
          onClick={ () => { onHideClick(keyword); } }
          role="button"
          title="Hide"
        >
          <SvgIcon className="icon-link_icon" name="i-eye" />
          <span className="icon-link_text">Hide</span>
        </span>
      </div>

      <div className="keyword_content">
        {!keyword.is_root && <div className="keyword_static">
          <span className="keyword_text">{keyword.text}</span>
        </div>}

        {keyword.is_root && !edit && <div className="keyword_static -clickable" onClick={ () => { showKeywordDetails(keyword); } }>
          <SvgIcon className="keyword_icon" name="i-merged" />
          <span className="keyword_text">{keyword.text}</span>
        </div>}

        {edit && <KeywordsListEditor keyword={ keyword } cancelEdit={ cancelEdit } />}

      </div>

      {!edit && <div className="keyword_info">
        {keyword.is_root && <div className="keyword_merged-control">
          <div className="keyword_merged-buttons">
            <button className="icon-link" onClick={ showEditor }>
              <SvgIcon className="icon-link_icon" name="i-pencil" />
              <span className="icon-link_text">Edit</span>
            </button>
            <button className="icon-link -danger" onClick={ () => {unmerge(keyword) } }>
              <SvgIcon className="icon-link_icon" name="i-unmerge" />
              <span className="icon-link_text">Unmerge All</span>
            </button>
          </div>
        </div>}
        <span className="keyword_count" title="Count">{keyword.total}</span>
      </div>}
    </li>
  );
};

export default KeywordsListLi;
