import React, { useState } from 'react';
import { map, values } from 'lodash';
import classnames from "classnames";
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';
import { findOrInitLoop } from '../../logic/loop';
import ZoomImage from '../zoom_image';

// [BUZZ-2402] Survey - 10 columns overlaps in grid question
const minLeftColumnWidth = 66;
const maxLeftColumnWidth = 355;
const columnWidth = 80;
const fromScaleLengthBadView = 9;

const AttributeRatings = ({
  title, concept, questions, result, nextStep,
  children, forceImage, languageCode, productDescriptionComponent
}) => {
  const languageCodeWithValue = languageCode || 'en';
  const header = children;

  const [ answers, setAnswers ] = useState({});

  const disabledOption = () => {
    const hash = {};
    if (values(answers).length !== questions[0].answers.length) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswers = () => {
    findOrInitLoop(concept.confirmit_concept_id, result.loops, map(answers, (v, k) => `${k}_${v}`));
    setAnswers({});
    nextStep();
  };

  const selectAnswer = (event, row) => {
    setAnswers({ ...answers, ...{ [row.id]: event.target.value } });
  };

  const renderInnerScales = (row, scale, question) => {
    return (
      <div className="survey-options_rating-col -fix" key={ `${row.label}-${scale.id}` }>
        <div
          className={
            classnames(
              "survey-options_rating-col-header -show-mobile",
              { '-custom_columns': question.custom_columns }
            )
          }
        >
          <div className="survey-options_rating-legend" { ...renderRawHtml(scale.label) } />
          <div className="survey-options_rating-value">{ scale.id }</div>
        </div>
        <label className="radio">
          <input
            type="radio"
            name={ row.id }
            value={ scale.id }
            onChange={ (event) => { selectAnswer(event, row); } }
          />
          <div className="radio_label -no-text" />
        </label>
      </div>
    );
  };

  const renderRow = (question, row) => {
    return (
      <div className="survey-options_rating-grid-row" key={ row.id }>
        <div className="survey-options_rating-col -name">
          <div className="survey-options_rating-name" { ...renderRawHtml(row.label) } />
        </div>
        <div className="survey-options_inner-scales">
          { map(question.scales, (scale) => renderInnerScales(row, scale, question)) }
        </div>
      </div>
    );
  };

  const renderTopScale = (scale, question) => (
    <div
      className={
        classnames(
          "survey-options_rating-col -fix",
          { '-custom_columns': question.custom_columns }
        )
      }
      key={ scale.id }
    >
      <div
        className={
          classnames(
            "survey-options_rating-legend",
            { "-lower-font": languageCodeWithValue !== 'en' }
          )
        }
        { ...renderRawHtml(scale.label) }
      />
      <div className="survey-options_rating-value">{ scale.id }</div>
    </div>
  );

  const renderQuestion = (question) => {
    const scalesNumber = question.scales.length;
    const styles = {};
    if (scalesNumber >= fromScaleLengthBadView) {
      styles.width = '80vw';
      styles.minWidth = (minLeftColumnWidth + (scalesNumber * columnWidth));
      styles.maxWidth = (maxLeftColumnWidth + (scalesNumber * columnWidth));
    }

    return (
      <div className="survey-options_rating" key={ question.name } style={ styles }>
        <div className="survey-options_rating-header -hide-mobile">
          <div className="survey-options_rating-grid-row">
            <div className="survey-options_rating-col -name" />
            { map(question.scales, (scale) => renderTopScale(scale, question)) }
          </div>
        </div>
        <div className="survey-options_rating-grid">
          { map(question.answers, (row) => renderRow(question, row)) }
        </div>
      </div>
    );
  };

  return (
    <>
      {
        header ?
          header :
          <div className="survey-header">
            {
              !productDescriptionComponent &&
              <div className="survey-header_title" { ...renderRawHtml(title) } />
            }
            {
              !!productDescriptionComponent &&
              <div className="survey-header_title">
                <h3 className="survey-name-label -normal" { ...renderRawHtml(concept.name) } />
                <div className="survey-start_text -color-text" { ...renderRawHtml(title) } />
                { productDescriptionComponent }
              </div>
            }
          </div>
      }
      <div className="survey-layout_content">
        <div className="survey-layout_container">
          <div className="survey-options -flex">
            {
              !!concept.url && (!header || forceImage) &&
              <div className="survey-options_image-plate">
                <ZoomImage
                  className="survey-options_image"
                  src={ concept.url }
                >
                  <img src={ concept.url } />
                </ZoomImage>
              </div>
            }
            { map(questions, (question) => renderQuestion(question)) }
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswers(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default AttributeRatings;
