import React from 'react';
import {
  swipeNumbers, claimNumbers, conceptsTestNumbers, namingNumbers,
  packageNumbers, packageMcNumbers, adceptNumbers
} from '../express_numbers';
import ReadMoreSwipe from './readmore/swipe';
import ReadMoreConceptsTest from './readmore/concepts-test';
import ReadMoreClaim from './readmore/claim';
import ReadMoreNaming from './readmore/naming';
import ReadMoreFlavor from './readmore/flavor';
import ReadMorePackage from './readmore/package';
import ReadMoreAdcept from './readmore/adcept';
import ReadMoreBrandSnapshot from './readmore/brand-snapshot';

const listData = () => {
  const handleGetStarted = (onGetStarted, item, titleField) => {
    onGetStarted(item.express, item[titleField || 'title']);
  };
  const readMoreHash = (onGetStarted, item, titleField) => ({
    onGetStarted: () => {
      handleGetStarted(onGetStarted, item, titleField);
    }
  });

  const conceptData = {
    title: "Concept",
    landingTitle: "Concept",
    description: (
      <>
        Test up to {conceptsTestNumbers.itemsMax.express} concepts to screen and
        evaluate quickly. With Concept
        Focus and Thought Bubble for diagnostics
        and direction on how to improve.
      </>
    ),
    landingDescription: (
      <>
        Quickly evaluate concepts with key
        metrics and attributes, as well as buzzback
        Thought Bubble and Concept Focus
        for diagnostics.
      </>
    ),
    icon: "express-concepts_test",
    express: 'concepts_test',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreConceptsTest
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const adceptData = {
    title: "Advertising",
    landingTitle: "Advertising",
    description: (
      <>
        Test up to { adceptNumbers.itemsMax.express } ads to screen and evaluate quickly
        with Concept Focus and Thought Bubble included for diagnostics.
      </>
    ),
    landingDescription: (
      <>
        Test up to { adceptNumbers.itemsMax.express } ads to screen and evaluate quickly
        with Concept Focus and Thought Bubble included for diagnostics.
      </>
    ),
    icon: "express-adcept",
    express: "adcept",
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreAdcept
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const claimsData = {
    title: "Claims",
    landingTitle: "Claims",
    description: (
      <>
        Test up to {claimNumbers.claimsMax.express} claims with this simple
        MaxDiff methodology. Includes Concept Focus &
        Thought Bubble for diagnostics.
      </>
    ),
    landingDescription: (
      <>
        Test claims with this
        simple MaxDiff approach.
        Includes buzzback Concept Focus
        and Thought Bubble for diagnostics.
      </>
    ),
    icon: "express-claims",
    express: 'claim',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreClaim
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const packageData = {
    title: "Packaging",
    landingTitle: "Packaging",
    description: (
      <>
        Test up to { packageNumbers.itemsMax.express } packages to
        screen and evaluate quickly
        with Online Shelf and Concept
        Focus included for diagnostics.
      </>
    ),
    landingDescription: (
      <>
        Test up to { packageNumbers.itemsMax.express } packages to
        screen and evaluate quickly
        with Online Shelf and Concept
        Focus included for diagnostics.
      </>
    ),
    icon: "express-package",
    express: 'package',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMorePackage
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const namingData = {
    title: "Naming",
    landingTitle: "Naming",
    description: (
      <>
        Test up to { namingNumbers.itemsMax.express } names to
        screen and evaluate quickly
        with Thought Bubble included for
        diagnostics.
      </>
    ),
    landingDescription: (
      <>
        Determine which names are
        strongest with key metrics
        and attributes, plus buzzback
        Thought Bubble for diagnostics.
      </>
    ),
    icon: "express-naming",
    express: 'naming',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreNaming
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const flavorData = {
    title: "Flavors & Variants",
    landingTitle: "Flavors & Variants",
    description: (
      <>
        Wow the senses with flavors testing - also appropriate for fragrances.
        Utilize sequential monadic,  MaxDiff, and TURF methodologies to identify clear&nbsp;winners.
      </>
    ),
    landingDescription: (
      <>
        Wow the senses with flavors testing - also appropriate for fragrances.
        Utilize sequential monadic,  MaxDiff, and TURF methodologies to identify clear&nbsp;winners.
      </>
    ),
    icon: "express-flavor",
    express: 'flavor',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreFlavor
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const swipeData = {
    title: "Swipe",
    landingTitle: "Swipe",
    description: (
      <>
        Test & learn with our Swipe approach to screen
        up to { swipeNumbers.itemsMax.express } ideas. With Concept Focus for
        diagnostics and understanding.
      </>
    ),
    landingDescription: (
      <>
        Screen early-stage concepts/ideas with our Swipe
        mobile friendly approach and buzzback Concept Focus
        for diagnostics.
      </>
    ),
    icon: "express-swipe",
    express: 'swipe',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreSwipe
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const molsonCoors = {
    title: "Express Packaging - Molson Coors",
    landingTitle: "Express Packaging - Molson Coors",
    description: (
      <>
        Test up to { packageMcNumbers.itemsMax.express } packages to
        screen and evaluate quickly
        with Online Shelf and Concept
        Focus included for diagnostics.
      </>
    ),
    landingDescription: (
      <>
        Test up to { packageMcNumbers.itemsMax.express } packages to
        screen and evaluate quickly
        with Online Shelf and Concept
        Focus included for diagnostics.
      </>
    ),
    icon: "express-package",
    express: 'package_mc',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMorePackage
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  const brandSnapshot = {
    title: "Brand Snapshot",
    landingTitle: "Brand Snapshot",
    description: (
      <>
        Understand brand awareness, perceptions, sourcing, and usage among category users.
        Include up to 3 custom questions.
      </>
    ),
    landingDescription: (
      <>
        Understand brand awareness, perceptions, sourcing, and usage among category users.
        Include up to 3 custom questions.
      </>
    ),
    icon: "express-brand_snapshot",
    express: 'brand_snapshot',
    readMore: (onGetStarted, item, titleField) => (
      <ReadMoreBrandSnapshot
        { ...readMoreHash(onGetStarted, item, titleField) }
      />
    )
  };

  return [
    conceptData, adceptData, claimsData, packageData, namingData, flavorData,
    swipeData, molsonCoors, brandSnapshot
  ];
};

export default listData;
