import { findIndex } from 'lodash';

const phoneMask = '+1 ___-___-____';
const phoneMin = (phone) => (
  (phone || '').split('').slice(
    findIndex(phoneMask.split(''), (el) => el === '_')
  )
);


export { phoneMask, phoneMin };
