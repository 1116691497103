import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import ZoomImage from './zoom_image';
import StartButton from './start_button.js';

const ProductIntro = ({ nextStep, title, text, url }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start -naming -product-intro">
        {
          (!!title || !!text) &&
          <div className="survey-product-description">
            { !!title && <h3 { ...renderRawHtml(title) } /> }
            {
              !!text &&
              <div
                className="survey-start_description custom-title"
                { ...renderRawHtml(text) }
              />
            }
          </div>
        }
        {
          !!url &&
          <div className="naming-hero-image-wrapper">
            <ZoomImage
              withHeadWrapper
              className="survey-start-hero-img"
              src={ url }
            >
              <img src={ url } alt="Product Image" />
            </ZoomImage>
          </div>
        }
        <StartButton nextStep={ nextStep } />
      </div>
    </div>
  );
};

export default ProductIntro;
