import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const ReviewRespondents = () => {
  const { initialData, controllerName, paths } = useContext(BaseContext);
  const canReviewRespondents = initialData.can_review_respondents;
  const reviewRespondentsPath = paths.respondents;
  const active = controllerName === 'respondents';

  return (
    <>
      {
        canReviewRespondents &&
        <li className="sidebar_nav-list-item -sub">
          <a
            className={ classnames("sidebar_nav-link -sub", { '-active': active }) }
            href={ reviewRespondentsPath }
          >
            Review Respondents
          </a>
        </li>
      }
    </>
  );
};

export default ReviewRespondents;
