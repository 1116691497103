import React, { useContext } from 'react';
import { map } from 'lodash';
import { LineDataContext } from '../contexts';
import { AllPlansInclude } from '../allPlansInclude';
import Plan from './plan';

const Overview = ({
  onPlanSelect, currentLine,
  selectedPlanIdByLine
}) => {
  const {
    plans, getPlanPriceByLine
  } = useContext(LineDataContext);

  const handlePlanSelect = (event, planObject) => {
    onPlanSelect(event, planObject);
  };

  return (
    <div className="overview -bau">
      <div className="overview_section">
        <div className="overview_section-header">
          <h3 className="overview_section-title">
            Select your plan
          </h3>
          <div className="overview_section-description">
            <AllPlansInclude />
          </div>
        </div>

        <div className="overview_plans">
          {
            map(plans, (plan) => (
              <Plan
                key={ `overviewPlanItem${plan.id}` }
                plan={ plan }
                onPlanSelect={ handlePlanSelect }
                selectedPlanIdByLine={ selectedPlanIdByLine }
                planPrice={ getPlanPriceByLine(plan, currentLine) }
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Overview;
