import React from 'react';
import classnames from "classnames";

const TextField = ({ error, label, value, onChange, placeholder, className, classNameLabel, disabled }) => {
  return(
    <div className="form-group">
      {label && <div className={`form-group_label ${classNameLabel ? classNameLabel : '-upper'}`}>{ label }</div>}
      <div className="form-group_control">
        <input
          type="text"
          className={ classnames("form-control", className) }
          value={ value || "" }
          placeholder={ placeholder }
          onChange={ (event) => onChange(event.target.value) }
          disabled={ disabled }
        />
        { error && <p className="field-error">{ error }</p> }
      </div>
    </div>
  );
};

export default TextField;