import React, {createContext, useEffect, useState} from "react";
import {forEach, filter, merge, groupBy, concat, map, find} from "lodash";
import Http from "../../../../../common/http";

export const MarkupData = createContext()

export const WithMarkupData = ({tool, focusConcept, allComments, children}) => {

  const [showPositive, setShowPositive] = useState(true)
  const [showNegative, setShowNegative] = useState(true)
  const [anyHighlightedId, setAnyHighlightedId] = useState(null)
  const [markupType, setMarkupType] = useState('area_and_text')
  const [width, setWidth] = useState(null)
  const [imageLeft, setImageLeft] = useState(null)
  const [height, setHeight] = useState(null)
  const [imageTop, setImageTop] = useState(null)
  const [offsetX, setOffsetX] = useState(null)
  const [offsetY, setOffsetY] = useState(null)
  const [closeSquare, setCloseSquare] = useState( null )
  const [selectedComments, setSelectedComments] = useState([])
  const [groupedSelectedComments, setGroupedSelectedComments] = useState({})
  const [groupedComments, setGroupedComments] = useState({})
  const [markupComments, setMarkupComments] = useState([])

  const onShowPositiveChange = (event) => {
    setShowPositive(event.target.checked)
  }

  const onShowNegativeChange = (event) => {
    setShowNegative(event.target.checked)
  }

  const hasSelectedComments = () => {
    return selectedComments.length>0;
  }

  const getMarkupComments = () => {
    return filter(markupComments, (el) => { return !el.respondent_hidden && el.markup_visible });
  }

  const getScaledArea = (area, image_width) => {
    let scale = width / parseFloat(image_width);
    let scaledArea = {
      left: parseFloat(area.left) * scale,
      top: parseFloat(area.top) * scale,
      width: parseFloat(area.width) * scale,
      height: parseFloat(area.height) * scale
    };
    scaledArea.right = scaledArea.left + scaledArea.width;
    scaledArea.bottom = scaledArea.top + scaledArea.height;
    return scaledArea;
  }

  const mergeScaledRegion = (region) => {
    if (!region.image_width) region.image_width = 452;

    if (!!width && !region.scaled && region.image_width != width){
      region.scaled = true;
      merge(region, getScaledArea(region, region.image_width));
    }

    return region;
  }

  const intersectWithRegions = (area, image_width) => {
    const clipRegions = selectedRegion.value.data || []

    if(clipRegions.length==0){return -1}

    let scaledArea = getScaledArea(area, image_width),
        ax1 = scaledArea.left,
        ay1 = scaledArea.top,
        ax2 = scaledArea.left+scaledArea.width,
        ay2 = scaledArea.top+scaledArea.height;
    for(let i=0; i<clipRegions.length; i++){
      let region = clipRegions[i];

      mergeScaledRegion(region);

      let bx1 = region.left,
          by1 = region.top,
          bx2 = region.left + region.width,
          by2 = region.top + region.height;

      if( bx1 <= ax1 && ax2 <= bx2 && by1 <= ay1 && ay2 <= by2){
        return 1;
      }
      if( ax1 < bx2 && ax2 > bx1 && ay1 < by2 && ay2 > by1 ){
        return 0;
      }
    }
    return -1;
  }

  const filterAreas = (el) => {
    const clipMode = selectedRegion.value.clip_mode || 'none'

    if(clipMode == 'none' || width == null){
      return el.areas;
    }
    return filter( el.areas, (area) => {
      let i = intersectWithRegions(area, el.image_width);
      return clipMode == 'inside' && i == 1 || clipMode == 'outside' && i == -1 || clipMode == 'covered' && (i == 0 || i == 1);
    });
  }

  const isInside = (x, y, obj) => {
    return !(y <= obj.top || (obj.top + obj.height <= y) || x <= obj.left || (obj.left + obj.width) <= x)
  }

  const isRespondentHidden = (respondent_id) => {
    const gc = groupedComments,
          gsc = groupedSelectedComments,
          temp = gc[respondent_id]  && gc[respondent_id].any((el)=>{ return !!el.respondent_hidden }) ||
                 gsc[respondent_id] && gsc[respondent_id].any((el)=>{ return !!el.respondent_hidden });

    return !!temp;
  }

  const hintMessage = (respondent_id) => {
    if(isRespondentHidden(respondent_id)){
      return `Show all respondent's markups (${respondent_id}) on markup and heatmap view`;
    }else{
      return `Hide all respondent's markups (${respondent_id}) from markup and heatmap view`
    }
  }

  const respondentClasses = (respondent) => {
    return {
      '-positive': respondent.reaction == 'positive',
      '-negative': respondent.reaction == 'negative',
      '-confusing': respondent.reaction == 'confusing',
      '-selected': respondent.filterAreas.any( (el)=>{ return el.isHighlighted} ),
      '-commented': !!respondent.comment,
      '-with-reaction': respondent.areas.length>0
    }
  }

  const anyCommentVisible = () => {
    if(markupComments.length == 0){
      return false;
    }
    return markupComments.any((el)=>{ return el.filterAreas.length>0; })
  }

  const calcSelectedComments = (newMarkupComments) => {
    let temp = []

    forEach(newMarkupComments, (comment) => {
      let selected = false,
          resultId = null;

      if (!comment.respondent_hidden && comment.markup_visible ) {

        forEach(comment.filterAreas, (area) => {
          let scaledArea = getScaledArea(area, comment.image_width);

          if (!!scaledArea && isInside(offsetX, offsetY, scaledArea)) {
            selected = true;
            area.isSelected = true;
            if (resultId == null) {
              resultId = comment.id
            }
          }
          else {
            area.isSelected = false;
          }
        });

        if (selected) {
          temp.push(comment)
        }
      }
    });

    return temp
  }

  const calcMarkupComments = () => {
    const temp = filter(allComments, (el) => {
      if(
        (el.reaction == 'positive' && showPositive || el.reaction == 'negative' && showNegative)
        &&
        (markupType == 'area_and_text' || el.markup_type == markupType)
      ){
        return true;
      }
      return false;
    });

    forEach(temp, (el) => {
      el.filterAreas = filterAreas(el);
    })

    return temp
  }

  const calcGroupedSelectedComments = (newSelectedComments) => {
    const temp = groupBy(newSelectedComments, 'respondent_id')
    return temp
  }

  const allAreasSelected = (el) => {
    return el.filterAreas.all((area)=>{ return !!area.isSelected; });
  }

  const calcGroupedComments = (newMarkupComments)=>{
    const temp = groupBy( filter(newMarkupComments, (el) => (!allAreasSelected(el))), 'respondent_id')
    return temp
  }

  const cleanIsSelected = (newMarkupComments) => {
    forEach(newMarkupComments, (comment) => {
      forEach(comment.filterAreas, (area) => {
        area.isSelected = false;
      });
    });
  }

  const calcAll = () => {
    const mc = calcMarkupComments()

    if(offsetX == null || offsetY == null){
      setSelectedComments([])
      setGroupedSelectedComments({})
      cleanIsSelected(mc)
    } else {
      const sc = calcSelectedComments(mc)
      const gsc = calcGroupedSelectedComments(sc)

      setSelectedComments(sc)
      setGroupedSelectedComments(gsc)
    }

    const gmc = calcGroupedComments(mc)

    setMarkupComments(mc)
    setGroupedComments(gmc)
  }

  const deselectComments = () => {
    setOffsetX(null)
    setOffsetY(null)
  }

  const noRegionOption = {value: {}, label: 'No region'}
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState(noRegionOption)
  const regionOptions = concat(noRegionOption, map(regions, el=>({value: el, label: el.name})))

  const setSelectedRegionById = (regions, id) => {
    setRegions(regions)
    focusConcept.regions = regions
    const el = find(regions, r=>(r.id == id))
    if(!!el){
      setSelectedRegion({value: el, label: el.name})
    }else{
      setSelectedRegion(noRegionOption)
    }
  }

  const areaClick = (cc) => {
    setOffsetX(cc.x)
    setOffsetY(cc.y)
  }

  const toggleHighlight = (respondent) => {
    let newHighlight;

    forEach(allComments, (comment)=>{
      forEach(comment.areas, (area)=>{
        if (respondent && comment.id == respondent.id){
          newHighlight = !area.isHighlighted;
          area.isHighlighted = newHighlight;
        } else {
          area.isHighlighted = false;
        }
      })
    })

    if(respondent && newHighlight) {
      setAnyHighlightedId(respondent.id)
    }else{
      setAnyHighlightedId(null)
    }
  }

  const findHighlighted = (grouped, keys) => {
    for(let ki=0; ki<keys.length; ki++) {
      let comments = grouped[keys[ki]];
      for (let ci = 0; ci < comments.length; ci++) {
        let areas = comments[ci].filterAreas,
          hasHighlight = false;
        for (let ai = 0; ai < areas.length; ai++) {
          if (areas[ai].isHighlighted) {
            hasHighlight = true
          }
        }
        if(hasHighlight){
          return [ki,ci]
        }
      }
    }
    return null
  }

  const setIsHighlighted = (areas, v) => {
    for(let ai=0; ai<areas.length; ai++){ areas[ai].isHighlighted = v; }
  }

  const moveInsideGrouped = (moveUp, ki, ci, grouped, keys) => {
    let comments = grouped[keys[ki]],
        areas = comments[ci].filterAreas;

    if( moveUp && (ki>0 || ci>0) ){
      setIsHighlighted(areas, false);

      let newKi = (ci == 0 ? ki-1 : ki),
          newCi = (ci == 0 ? grouped[ keys[ki-1] ].length-1 : ci-1),
          newAreas = grouped[ keys[newKi] ][newCi].filterAreas;

      setIsHighlighted(newAreas, true);
    }

    if( !moveUp && (ki<keys.length-1 || ci<comments.length-1) ){
      setIsHighlighted(areas, false);

      let newKi = (ci == comments.length-1 ? ki+1 : ki),
          newCi = (ci == comments.length-1 ? 0 : ci+1),
          newAreas = grouped[ keys[newKi] ][newCi].filterAreas;

      setIsHighlighted(newAreas, true);
    }
  }

  const moveHighlight = (moveUp) => {
    let selKeys = Object.keys(groupedSelectedComments),
        selPos = findHighlighted(groupedSelectedComments, selKeys);
    if(selPos != null){
      moveInsideGrouped(moveUp, selPos[0], selPos[1], groupedSelectedComments, selKeys);
      setGroupedSelectedComments({...groupedSelectedComments})
    }else{
      let othKeys = Object.keys(groupedComments),
          othPos = findHighlighted(groupedComments, othKeys);
      if(othPos != null){
        moveInsideGrouped(moveUp, othPos[0], othPos[1], groupedComments, othKeys);
        setGroupedComments({...groupedComments})
      }
    }
  }

  const toggleMarkupVisible = (report_comment_id) => {
    let report = find(markupComments, (el) => el.id == report_comment_id),
        isSelArr = false;

    if(!report){
      report = find(selectedComments, (el) => el.id == report_comment_id)
      isSelArr = true
    }

    if (!report) return {};

    report.markup_visible = !report.markup_visible;

    if(isSelArr){
      setGroupedSelectedComments({...groupedSelectedComments})
    }else{
      setGroupedComments({...groupedComments})
    }

    const params = {
      toggle: {
        report_comment_id: report_comment_id,
        markup_visible: report.markup_visible
      }
    }

    Http.post(
      tool.toggle_url, params
    ).then(
      (http) => { if (http.response.status == 200) {} },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const toggleRespondent = (respondent_id) => {
    let hasChanges = false,
        newValue;

    forEach(markupComments, (el)=>{
      if (el.respondent_id == respondent_id) {
        if(newValue == undefined) { newValue = !el.respondent_hidden }
        el.respondent_hidden = newValue
        hasChanges = true
      }
    })
    if(hasChanges){
      setGroupedComments({...groupedComments})
    }

    hasChanges = false;
    forEach(selectedComments, (el)=>{
      if (el.respondent_id == respondent_id) {
        if(newValue == undefined) { newValue = !el.respondent_hidden }
        el.respondent_hidden = newValue
        hasChanges = true
      }
    })
    if(hasChanges){
      setGroupedSelectedComments({...groupedSelectedComments})
    }

    const params = {
      toggle: {
        all_respondents: [respondent_id],
        respondent_hidden: newValue
      }
    }

    Http.post(
      tool.toggle_url, params
    ).then(
      (http) => { if (http.response.status == 200) {} },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const toggleAllRespondents = (visible) => {
    forEach(markupComments, (el)=>{
      el.respondent_hidden = !visible
    })
    forEach(selectedComments, (el)=>{
      el.respondent_hidden = !visible
    })
    setGroupedComments({...groupedComments})
    setGroupedSelectedComments({...groupedSelectedComments})

    const params = {
      toggle: {
        all_respondents: concat( map(markupComments, el=>el.respondent_id), map(selectedComments, el=>el.respondent_id) ),
        respondent_hidden: !visible
      }
    };

    Http.post(
      tool.toggle_url, params
    ).then(
      (http) => { if (http.response.status == 200) {}},
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const checkAllRespondentsHidden = () => {
    let allHidden = true
    forEach(allComments, (el) => {
      if (!el.respondent_hidden) {
        allHidden = false
      }
    })
    return allHidden;
  }

  const data = {
    showPositive: showPositive,
    onShowPositiveChange: onShowPositiveChange,
    showNegative: showNegative,
    onShowNegativeChange: onShowNegativeChange,
    hasSelectedComments: hasSelectedComments,
    groupedSelectedComments: groupedSelectedComments,
    groupedComments: groupedComments,
    getMarkupComments: getMarkupComments,
    allComments: allComments,
    markupComments: markupComments,
    width: width,
    setWidth: setWidth,
    imageLeft: imageLeft,
    setImageLeft: setImageLeft,
    imageTop: imageTop,
    setImageTop: setImageTop,
    height: height,
    setHeight: setHeight,
    isRespondentHidden: isRespondentHidden,
    hintMessage: hintMessage,
    respondentClasses: respondentClasses,
    anyCommentVisible: anyCommentVisible,
    anyHighlightedId: anyHighlightedId,
    regionOptions: regionOptions,
    selectedRegion: selectedRegion,
    setSelectedRegionById: setSelectedRegionById,
    setSelectedRegion: setSelectedRegion,
    focusConcept: focusConcept,
    getScaledArea: getScaledArea,
    areaClick: areaClick,
    closeSquare: closeSquare,
    setCloseSquare: setCloseSquare,
    deselectComments: deselectComments,
    toggleHighlight: toggleHighlight,
    moveHighlight: moveHighlight,
    toggleMarkupVisible: toggleMarkupVisible,
    toggleRespondent: toggleRespondent,
    toggleAllRespondents: toggleAllRespondents,
    checkAllRespondentsHidden: checkAllRespondentsHidden,
  }

  useEffect(()=>{
    if(width != null){
      calcAll()
    }
  }, [width, allComments, selectedRegion, offsetX, offsetY, showPositive, showNegative])

  useEffect(()=>{
    setRegions(focusConcept.regions)
    setSelectedRegion(noRegionOption)
  }, [focusConcept])

  return (
    <MarkupData.Provider value={data}>
      {children}
    </MarkupData.Provider>
  );
};
