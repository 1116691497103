import React from 'react';

const ConditionSelect = ({ value, onChangeCondition, i, disabled = false }) => {
  return (
    <select
      className="form-field -block"
      value={ value }
      onChange={ (event) => {onChangeCondition(event, i);} }
      disabled={ disabled }
    >
      <option value="">Neutral</option>
      <option value="is">Must select</option>
      <option value="is_not">Must not select</option>
    </select>
  );
};

export default ConditionSelect;
