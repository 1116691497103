import React from 'react';
import { map } from 'lodash';
import renderRawHtml from './render_raw_html';
import { Header } from './header';

const Checkout = ({ currentLine, selectedLines, nextStep, selectedPlans, total, reselectPlan }) => {
  const isFinal = currentLine === selectedLines;

  const changePlan = (clickedLine) => {
    reselectPlan(clickedLine);
  };

  const hasSelectedPerk = (plan) => (!!plan.selectedPerk);

  return (
    <div className="content">
      <Header />
      <div>
        <div className="inner-content">
          <div className="cart">
            <div className="cart_header">
              <div className="cart_grid -center">
                <div className="cart_grid-item">
                  <h2 className="cart_title">Cart</h2>
                </div>
                <div className="cart_grid-item">
                  <div className="cart_total">${total}/mo</div>
                </div>
              </div>
            </div>
            <div className="cart_body">
              <ul className="cart_list">
                {
                  map(selectedPlans, (plan, index) => (
                    <li key={ `row${index}` } className="cart_item">
                      <div className="cart_line-number">Line {index + 1}</div>
                      <div className="cart_grid">
                        <div className="cart_grid-item">
                          <h3 className="cart_line-title" { ...renderRawHtml(plan.title) } />
                        </div>
                        <div className="cart_grid-item">
                          {plan && <div className="cart_price">${plan.price}/mo</div>}
                        </div>
                      </div>
                      <div className="cart_line-description">
                        { plan.description }
                        {
                          hasSelectedPerk(plan) &&
                          <>
                            <br />
                            { plan.description } selected.
                          </>
                        }
                      </div>
                      <div className="cart_line-edit">
                        <button
                          className="button-link -sm"
                          onClick={ () => { changePlan(index + 1); } }
                        >
                          Change plan
                        </button>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="cart_footer">
              <button
                className="button cart_footer_button"
                onClick={ () => {
                  nextStep(currentLine, isFinal);
                } }
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
