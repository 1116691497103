import React from 'react';
import Heatmap from "./heatmap";

const ConceptTabHeatmap = ({ current, comments, imageField = 'image_url' }) => {
  return (
    <div className="concept-report_tab heatmap">
      <h3 className="concept-focus-heatmap-title">
        Concept Focus - Heatmap, N = { current.heatmap.positive.reaction_total }
      </h3>
      <div className="heatmaps -grid">
        <div className="heatmaps_item">
          <h4 className="heatmaps_title">Positive</h4>
          <Heatmap imageUrl={ current[imageField] } comments={ comments }  reaction="positive" />
          <p>{ current.heatmap.positive.percent }% made at least one appealing markup</p>
        </div>

        <div className="heatmaps_item">
          <h4 className="heatmaps_title">Negative</h4>
          <Heatmap imageUrl={ current[imageField] } comments={ comments } reaction="negative" />
          <p>{ current.heatmap.negative.percent }% made at least one unappealing markup</p>
        </div>
      </div>
    </div>
  );
};

export default ConceptTabHeatmap;
