import React, { Fragment } from 'react';
import { map } from 'lodash';
import { abilityField, abilityNField } from './fields';

const OpenEndHiddenFields = ({ question, relation = 'custom_metrics' }) => (
  <>
    <input
      type="hidden"
      name={ `project[${relation}][][${abilityField}]` }
      value={ question[abilityField] || false }
    />
    <input
      type="hidden"
      name={ `project[${relation}][][${abilityNField}]` }
      value={ question[abilityNField] || 1 }
    />
    {
      map([ 'id', 'title', 'name', 'text', 'parent_id', 'kind', 'project_id' ], (reasonField) => (
        <Fragment key={ `${question.id}_${reasonField}` }>
          {
            !!question.reasons?.length &&
              <input
                type="hidden"
                name={ `project[${relation}][][reasons][][${reasonField}]` }
                value={ question.reasons[0][reasonField] || '' }
              />
          }
        </Fragment>
      ))
    }
  </>
);

export default OpenEndHiddenFields;
