import React, { useContext } from 'react';
import { BaseContext } from '../../contexts';

const Logout = () => {
  const { paths } = useContext(BaseContext);
  const logoutUrl = paths.logout;
  return (
    <a
      className="sidebar_nav-link -with-icon"
      rel="nofollow"
      data-method="delete"
      href={ logoutUrl }
    >
      <svg className="sidebar_nav-link-icon icon -menu-logout">
        <use xlinkHref="#svg_menu-logout" />
      </svg>
      <span className="sidebar_nav-link-text">Log Out</span>
    </a>
  );
};

export default Logout;
