import React from "react";
import { map } from "lodash";
import cn from "classnames";

const DisplayGroupSelectorInner = ({
  groups, onChange, currentGroup, className
}) => {
  const handleClick = (group) => {
    if (group === currentGroup) {
      return;
    }
    onChange(group);
  };

  return (
    <h4 className={ cn('years_title', className) }>
      {
        map(groups, (y) => (
          <span
            key={ `Group${y}` }
            className={ cn("year", { "-active": y === currentGroup }) }
            onClick={ () => {handleClick(y);} }
          >
            {y}
          </span>
        ))
      }
    </h4>
  );
};

const DisplayGroupSelector = (props) => {
  const { withSection, groups } = props;
  return (
    <>
      {
        groups && groups.length > 1 &&
        <>
          {
            withSection &&
            <div className="section_top">
              <DisplayGroupSelectorInner { ...props } />
            </div>
          }
          { !withSection && <DisplayGroupSelectorInner { ...props } /> }
        </>
      }
    </>
  );
};

export default DisplayGroupSelector;
