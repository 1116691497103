import React, { useContext } from 'react';
import { QuotaModeGlobalContext } from '../contexts';

const QuotaModeHiddenFields = ({ relation, takeGlobalValue, question }) => {
  const { quotaMode } = useContext(QuotaModeGlobalContext);
  const quotaValue = (takeGlobalValue ? quotaMode : question.quota_mode) || '';
  return (
    <input type="hidden" name={ `project[${relation}][][quota_mode]` } value={ quotaValue } />
  );
};

export default QuotaModeHiddenFields;
