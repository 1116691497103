import React, { useState, useEffect, useContext, useRef } from 'react';
import cn from "classnames";
import { IndexData } from '../index-context';

const DropdownButton = ({
  topClassName, headerButton,
  innerClassName, listClassName,
  buttonName, tourName,
  children
}) => {
  const [ , setIntention ] = useState(false);
  const tourElement = useRef(null);
  const { getTourUseEffectArgs, getForceOpenButtonState } = useContext(IndexData);
  const [ callback, condition ] = getTourUseEffectArgs(tourName, tourElement);
  const forcedOpenState = getForceOpenButtonState(tourName);
  const [ opened, setOpened ] = useState(false);

  useEffect(callback, [ ...condition, ...[ forcedOpenState ] ]);

  useEffect(() => {
    const body = document.body || document.querySelector('body');
    const handler = () => {
      let currentIntention = false;
      setIntention((oldIntention) => {
        currentIntention = oldIntention;
        return false;
      });
      if (!currentIntention) {
        setOpened(false);
      }
    };
    body.addEventListener('click', handler);
    return () => {
      body.removeEventListener('click', handler);
    };
  }, []);

  const openState = opened || forcedOpenState;

  const handleToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpened(!opened);
  };

  return (
    <div
      className={
        cn(
          'dropdown',
          { 'header_button': headerButton },
          topClassName,
          { 'open': openState }
        )
      }
      onClick={ () => { setIntention(true); } }
    >
      <button
        className={
          cn(
            'dropdown_toggle button -dropdown',
            `button-tour-${tourName}`,
            innerClassName
          )
        }
        onClick={ handleToggle }
      >
        { buttonName }
      </button>
      {
        openState &&
        <div
          ref={ tourElement }
          className={
            cn(
              "dropdown_container -corner-right",
              listClassName
            )
          }
        >
          <div className="dropdown_inset">
            <div className="dropdown_content">
              <ul className="reports-list">
                { children }
              </ul>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default DropdownButton;
