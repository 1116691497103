import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../contexts';
import { sidebarLogoClick } from "../../google-analytics/events";

const Header = ({ children }) => {
  const {
    titleAll, actionName, controllerName, initialData, paths, gaTrackingId
  } = useContext(BaseContext);
  const logoKlass = initialData.logo_klass;
  const svgLogo = initialData.svg_logo;
  const pngLogo = initialData.png_logo;
  const logoUrl = paths.root_logo;
  const allProjectsPage = controllerName === 'projects' && actionName === 'index';

  return (
    <>
      <div className="sidebar_item">
        <div className="sidebar_top -header">
          <a
            className="sidebar_logo"
            href={ logoUrl }
            onClick={ () => sidebarLogoClick(gaTrackingId) }
          >
            {
              pngLogo ? (
                <img src={ pngLogo } className={ classnames("img-logo", logoKlass) } alt="CMI" />
              ) : (
                <svg className={ classnames("icon -logo", logoKlass) }>
                  <use xlinkHref={ svgLogo } />
                </svg>
              )

            }
          </a>
        </div>
      </div>
      <div className="sidebar_item -grow">
        <nav className="sidebar_nav">
          <a
            className={ classnames("sidebar_nav-link -with-icon", { '-active': allProjectsPage }) }
            href={ logoUrl }
          >
            <svg className="sidebar_nav-link-icon icon -menu-projects">
              <use xlinkHref="#svg_menu-projects" />
            </svg>
            <span className="sidebar_nav-link-text">{ titleAll }</span>
          </a>
        </nav>
        { children }
      </div>
    </>
  );
};

export default Header;
