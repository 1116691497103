import React from 'react';
import classnames from 'classnames';
import InfoToolTip from '../../../../common/infoToolTip';

const EditableInput = ({
  title,
  children,
  value,
  onChange,
  error,
  disabled
}) => {
  const handleOnChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <div className="form_section-text input-with-subtitle">
      <div className="subtitle-wrapper">
        <span className="subtitle">{ title }</span>
        <InfoToolTip remote position={ 'top-right' } className="-force-margin">
          { children }
        </InfoToolTip>
      </div>
      <input
        className={ classnames("form-field -block", { "-error": error }) }
        type="text"
        onChange={ handleOnChange }
        value={ value }
        disabled={ disabled }
      />
    </div>
  );
};

export default EditableInput;
