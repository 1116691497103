import React, { useState } from 'react';
import { PageContext } from './contexts';

const Page = ({ children }) => {
  const [ clickedSelectButton, setClickedSelectButton ] = useState(null);
  const pageValue = {
    getClickedSelectButton: () => (clickedSelectButton),
    setClickedSelectButton
  };
  return (
    <PageContext.Provider value={ pageValue }>
      { children }
    </PageContext.Provider>
  );
};

export default Page;
