import React, { useContext } from 'react';
import { BaseContext } from '../../contexts';

const CreditsAmount = () => {
  const { initialData } = useContext(BaseContext);
  const creditsAmount = initialData.credits_amount;
  return (
    <>
      {
        !!creditsAmount &&
        <li className="sidebar_nav-list-item">
          <span className="sidebar_nav-link -with-icon">
            <svg className="sidebar_nav-link-icon icon -menu-clients -amount">
              <use xlinkHref="#svg_menu-clients" />
            </svg>
            <span className="sidebar_nav-link-text">Credits Available: { creditsAmount }</span>
          </span>
        </li>
      }
    </>
  );
};

export default CreditsAmount;
