import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const ModalWindow = ({ children, style, className }) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = className;
    if (typeof style === 'string') {
      element.setAttribute("style", style);
    }
    body.appendChild(element);
    return (() => {
      body.removeChild(element);
    });
  }, []);

  useEffect(() => {
    if (typeof style === 'string') {
      element.setAttribute("style", style);
    }
  }, [ style ]);

  return createPortal(children, element);
};

export default ModalWindow;
