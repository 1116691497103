import React, { useContext, useEffect } from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { fraudContext } from "../contexts";
import { respondentCompletedSurvey } from "../../../common/google-analytics/events";

const Success = ({ text }) => {
  const { projectId, respondentId, gaTrackingId } = useContext(fraudContext);

  useEffect(() => {
    respondentCompletedSurvey(gaTrackingId, { project_id: projectId, respondent_id: respondentId });
  }, []);

  return (
    <div className="survey-layout_content -start">
      <div className="survey-start -sm">
        <svg className="survey-start_icon -sorry">
          <use xlinkHref="#svg_survey-sorry" />
        </svg>
        <h3 className="survey-start_title -no-margin" { ...renderRawHtml(text) } />
      </div>
    </div>
  );
};

export default Success;
