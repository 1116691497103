import React, { useState } from "react";
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";

const SliderRadioButtons = ({ title, hint, hintPosition, initValue, options, onChange, errors, disabled, modifier }) => {
  const [ val, setVal ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setVal(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <div className="form_section-title -grid">
        <div className="form_section-title-item -text">
          <h3>{title}</h3>
        </div>
        {hint &&
          <div className="form_section-title-item">
            <div className="info-tooltip">
              <div className="info-tooltip_control">
                <SvgIcon name="i-info" />
              </div>
              <div className={ `info-tooltip_drop ${hintPosition ? hintPosition : ''}` }>
                <div className="info-tooltip_drop-inner">
                  {hint}
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div className="form-group">
        <div className={ cn("radio-tabs", modifier ? modifier : '', errors ? 'has-error' : '') }>

          {map(options, (option) => (
            <label className="radio-tabs_item" key={ `project-${option.value}` }>
              <input
                id={ `project-${option.value}` }
                name={ `project-${title.toLowerCase()}` }
                type="radio"
                checked={ val === option.value }
                onChange={ handleOnChange }
                value={ option.value }
                disabled={ !!disabled }
              />
              <div className="radio-tabs_label">{option.title}</div>
            </label>
          ))}

        </div>
      </div>
    </>
  );
};

export default SliderRadioButtons;
