import React, { useContext, useState } from 'react';
import Slider from "react-slick";
import renderRawHtml from "../../../common/render_raw_html";
import { DataContext } from '../contexts';
import { planList, noPlan } from '../plansData';
import { setHashData } from "../statistic";
import Modal from "./modal";


const OptionDetailsModal = ({ optionDetail, handleClose }) => {
  const { title, description } = optionDetail;
  const [ showHiddenText, setShowHiddenText ] = useState(false);
  const toShowHiddenText = () => {
    setShowHiddenText(!showHiddenText);
  };
  const handleCloseInner = () => {
    handleClose();
    setShowHiddenText(false);
  };

  return (<Modal open={ description } title={ title } handleClose={ handleCloseInner }>
    {description && description.textWithLink ? (
      <div className="plan-card_options-item-details">
        <div className="plan-card_options-item-details-part">
          {description.textBefore && description.textBefore.map((text, ind) => {
            return (
              <p key={ `text-before-p-${ind}` } >{text}</p>
            );
          })}
          <p>
            {description.textWithLink.textBefore}
            <span onClick={ () => toShowHiddenText() } className="plan-card_options-item-details-link">{description.textWithLink.linkName}</span>
            {description.textWithLink.textAfter}
          </p>
        </div>
        {description.hiddenText && showHiddenText && (
          <div className="plan-card_options-item-details-part -hidden">
            {description.hiddenText.map((text, ind) => {
              return (
                <p key={ `text-hidden-p-${ind}` } >{text}</p>
              );
            })}
          </div>
        )}
      </div>
    ) : (
      <div className="plan-card_options-item-details" { ...renderRawHtml(description) } />
    )}
  </Modal>);
};

const PlanItemOptionRender = ({ option, showModal, fromChoose }) => {
  return (
    <li className="plan-card_options-item">
      <div className="plan-card_options-item-icon">
        <svg className="icon -checkmark">
          <use xlinkHref="#svg_checkmark" />
        </svg>
      </div>
      {option.title ? (
        <>
          {fromChoose && option.description ? (
            <div
              className="plan-card_options-item-text -clickable"
              onClick={ () => showModal(option) }
              { ...renderRawHtml(option.title) }
            />
          ) : (
            <div
              className="plan-card_options-item-text"
              { ...renderRawHtml(option.title) }
            />
          )}
        </>
      ) : (
        <div className="plan-card_options-item-text" { ...renderRawHtml(option) } />
      )}
    </li>
  );
};


const PlanItemRender = ({
  plan, plansCount, onSelect, conceptName, onNoPlanSelect, fromChoose,
  showDontWantButtonInBox
}) => {
  const getStarted = fromChoose ? 'Choose this plan' : 'Get started';

  const [ optionDetail, setOptionDetail ] = useState({ title: null, description: null });

  const showModal = (option) => {
    if (option) {
      setOptionDetail({
        title: option.title,
        description: option.description
      });
    }
  };

  const handleClose = () => {
    setOptionDetail({ title: null, description: null });
  };

  return (
    <div
      className={ `plans_list-item ${plansCount === 1 ? '-single' : ''}` }
    >
      <div className={ `plan-card ${plansCount === 1 ? '-single' : ''} ${plan.bundle ? '-bundle' : ''} -concept-${conceptName.toLowerCase()}` }>
        <div className={ `plan-card_header` } >
          <h4 className="plan-card_title">{plan.name}</h4>
          {plan.popular && (
            <div className="plan-card_subtitle">Most popular</div>
          )}
        </div>
        <div className="plan-card_content">
          <div className="plan-card_price">
            {plansCount === 1 && (
              <div className="plan-card_price-text -top">As low as</div>
            )}
            <div className="plan-card_price-value">
              <div className="plan-card_price-value-num">
                {plan.bundle && "+"}
                ${plan.price}
              </div>
              <div className="plan-card_price-value-period">/mo</div>
            </div>
            <div className="plan-card_price-text -bottom">
              {plan.comment ? plan.comment : 'All-in, no hidden fees, no commitment'}

            </div>
          </div>
          <div className="plan-card_options">
            {plan.bundle && (
              <div className="plan-card_options-title">
                You add:
              </div>
            )}
            <ul className="plan-card_options-list">
              {plan.options.map((option, ind) => {
                return (
                  <PlanItemOptionRender option={ option } showModal={ showModal } fromChoose={ fromChoose } key={ `option-${ind}` } />
                );
              })}
            </ul>
            {plan.additionOptions && (
              <ul className="plan-card_options-list -addition">
                {plan.additionOptions.map((option, ind) => {
                  return (
                    <PlanItemOptionRender option={ option } showModal={ showModal } fromChoose={ fromChoose } key={ `option-${ind}` } />
                  );
                })}
              </ul>
            )}
            <OptionDetailsModal optionDetail={ optionDetail } handleClose={ handleClose } />
          </div>
        </div>
        <div className="plan-card_footer">
          <button
            onClick={ () => onSelect(plan) }
            className="button -bordered -full-width"
            type="button"
            disabled={ !fromChoose }
          >
            {plan.bundle ? 'Get the bundle' : (conceptName === 'B' ? 'Get the plan' : getStarted)}
          </button>
          {
            showDontWantButtonInBox &&
            <button
              onClick={ onNoPlanSelect }
              className="button -bordered -full-width"
              type="button"
            >
              { plan.bundle ? 'I do not want this bundle' : 'I do not want this plan' }
            </button>
          }
        </div>
      </div>
    </div>
  );
};

const PlanList = ({ onContinue, startTimePlans, fromChoose }) => {
  const { conceptName } = useContext(DataContext);
  const { startTimeApp } = useContext(DataContext);
  const planListFiltered = planList.filter((plan) => plan.concept === conceptName);

  const plansInfoText = [
    {
      concept: 'BASE',
      text: 'While Visible might seem too good to be true, there are a few ways we’re able to keep our costs low while providing you with great phone service. We cut out stores (and the salespeople that come with them), and we’ve leveraged our powered by Verizon network access to give you coverage across the country. But don’t worry, we still have a 24/7 Care team if you have an issue.'
    },
    {
      concept: 'A',
      text: 'While Visible might seem too good to be true, there are a few ways we’re able to keep our costs low while providing you with great phone service. We cut out stores (and the salespeople that come with them), and we’ve leveraged our powered by Verizon network access to give you coverage across the country. But don’t worry, we still have a 24/7 Care team if you have an issue.'
    },
    {
      concept: 'B',
      text: 'While Visible might seem too good to be true, there are a few ways we’re able to keep our costs low while providing you with great phone service. We cut out stores (and the salespeople that come with them), and we’ve leveraged our powered by Verizon network access to give you coverage across the country. But don’t worry, we still have a 24/7 Care team if you have an issue.'
    }
  ];


  const onSelect = (plan) => {
    if (fromChoose) {
      setHashData(`planSelected`, plan.id);
      setHashData(`plansTime`, Math.ceil(new Date(new Date() - startTimePlans).getTime() / 1000));
      setHashData(`landingTime`, Math.ceil(new Date(startTimePlans - startTimeApp).getTime() / 1000));
      onContinue();
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };

  return (
    <section className={ `plans ${planListFiltered.length === 1 ? '-single' : ''}` }>
      <div className="plans_info">
        <h3 className="plans_info-title">The Visible plan{ (planListFiltered.length > 1) ? 's' : '' }</h3>
        <div className="plans_info-text">
          {plansInfoText.filter((plan) => plan.concept === conceptName)[0].text}
        </div>
      </div>
      <div className="plans_list-wrapper">
        {planListFiltered.length > 1 ? (
          <Slider className="plans_list" { ...settings }>
            {planListFiltered.map((plan, index) => {
              return (
                <PlanItemRender
                  plan={ plan } key={ `${plan.id}-${index}` }
                  conceptName={ conceptName }
                  plansCount={ planListFiltered.length }
                  onSelect={ onSelect }
                  onNoPlanSelect={ () => {  onSelect(noPlan); } }
                  fromChoose={ fromChoose }
                  showDontWantButtonInBox={ false }
                />
              );
            })}
          </Slider>
        ) : (
          <div className="plans_list -no-slider">
            <PlanItemRender
              plan={ planListFiltered[0] }
              conceptName={ conceptName }
              plansCount={ 1 }
              onSelect={ onSelect }
              onNoPlanSelect={ () => {  onSelect(noPlan); } }
              fromChoose={ fromChoose }
              showDontWantButtonInBox={ fromChoose }
            />
          </div>
        )}
      </div>
      {fromChoose && planListFiltered.length > 1 && (
        <div className="plans_bottom">
          <button onClick={ () => onSelect(noPlan) } className="button -bordered -full-width" type="button">
            I do not want these plans
          </button>
        </div>
      )}
    </section>
  );
};

export default PlanList;
