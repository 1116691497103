import React from 'react';
import cn from "classnames";
import { isEmpty } from 'lodash';
import SvgIcon from "../../../../common/components/svg-icon";

const EditorCell3 = ({ editing, enableEditing, count, saving, handleDelete, handleSave, tempText }) => {
  const deleteClass = cn(
    "button -circle -secondary -white-back",
    { '-delete-color': count === 0, '-gray-color': count !== 0 }
  );

  return (
    <>
      {!editing &&
      <>
        <button
          type="button"
          className="button -circle -secondary -white-back"
          onClick={ enableEditing }
          disabled={ saving }
        >
          <SvgIcon name="i-edit" />
        </button>

        <button
          type="button"
          className={ deleteClass }
          onClick={ handleDelete }
          disabled={ !isEmpty(count) || saving }
        >
          <SvgIcon name="i-delete" />
        </button>
      </>}
      {editing &&
      <button
        type="button"
        className="button -primary -js-save"
        onClick={ handleSave }
        disabled={ !tempText || saving }
      >
        Save
      </button>
      }
    </>
  );
};

export default EditorCell3;
