import React, { useContext } from "react";
import { IndexData } from '../../../index-context';
import ShelfBaseComparisonProducts from './shelf-base-comparison-products';

const ShelfRankingCompetitors = ({ current }) => {
  const { data } = useContext(IndexData);
  return (
    <ShelfBaseComparisonProducts
      current={ current }
      sectionTitle={ data.titles.rank_competitors }
      kindName={ 'ranking_top3_competitors' }
    />
  );
};

export default ShelfRankingCompetitors;
