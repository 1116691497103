import React, { useState } from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import ProductModal from './product_modal.js';
import ProductIntro from './product_intro.js';

const ProductLink = ({ linkText, productUrl, productText }) => {
  const [ showModal, updateShowModal ] = useState(false);

  const clickLink = (event) => {
    event.preventDefault();
    updateShowModal(true);
  };

  return (
    <>
      <a href="#" className="link-show-product" { ...renderRawHtml(linkText) } onClick={ (event) => { clickLink(event); } } />
      {
        showModal &&
        <ProductModal onClose={ () => { updateShowModal(false); } }>
          <ProductIntro url={ productUrl } text={ productText } nextStep={ () => { updateShowModal(false); } } />
        </ProductModal>
      }
    </>
  );
};
export default ProductLink;
