import React from 'react';
import { upperFirst } from "lodash";
import pluralize from "pluralize";

const WarningMessage = ({ data, buzzbackUser, itemName }) => {
  const message = () => {
    const text = [];
    if (data.show_filter_message) {
      text.push(
        [
          `The "${data.average_mode_humanized} Average" can only be filtered by the questions identical for all participated projects.`,
          "Average calculation is made for the current project instead."
        ].join(' ')
      );
    } else {
      if (data.not_enough_data || (!buzzbackUser && data.average_mode === "platform" && data.not_enough_brands)) {
        text.push("There are not enough cases for a valid comparison. Try expanding the category.");
      }
      if (buzzbackUser && data.not_enough_brands) {
        text.push("There are less than 3 brands included in this category. Generally we recommend at least 3.");
      }
    }
    return text.join("\r\n");
  };
  const plItemName = upperFirst(pluralize(itemName || 'concept', data.concepts_count));
  const debugInfo = `Comparing results: Brands: ${data.brands_count}, ${plItemName}: ${data.concepts_count}, Projects: ${data.projects_count}`;
  const canShowDebugInfo = (
    buzzbackUser && !data.show_filter_message && data.average_mode !== "project" &&
    typeof data.brands_count !== 'undefined' &&
    typeof data.concepts_count !== 'undefined' &&
    typeof  data.projects_count !== 'undefined'
  );

  return (
    <>
      {
        canShowDebugInfo &&
        <div className="comparison-warning-wrapper">
          <div className="comparisons-debug-info">
            { debugInfo }
          </div>
        </div>
      }
      {
        (data.not_enough_data || data.not_enough_brands) &&
        <>
          <div className="comparison-warning-wrapper">
            <div className="comparisons-warning">
              { message() }
            </div>
          </div>
          <div className="comparisons-warning-arrow" />
        </>
      }
    </>
  );
};

export default WarningMessage;
