import React, { useState } from 'react';
import cn from "classnames";
import { map } from "lodash";
import { PerkDetails } from "../components/perk_details";
import renderRawHtml from '../../../common/render_raw_html';
import {
  perkImageUrl, getDefaultVariant, allVariantsPriceRange, allVariantsMaxSum, fixedView
} from './helper';
import Modal from "./modal";
import { increaseVariableForCurrentLine } from './statistics';

const PlanPerk = ({
  perk,
  className,
  showDetailsModal,
  showNote,
  showPrice,
  isPerkChecked,
  onPerkChange,
  price,
  save,
  variantsSelected,
  onVariantChange,
  hideTotalInDetails,
  fromDetails,
  popularPlan
}) => {
  const [ modalPerkDetails, setModalPerkDetails ] = useState(null);

  const closeModalPerkDetails = () => {
    setModalPerkDetails(null);
  };

  const openModalPerkDetails = () => {
    if (popularPlan) {
      increaseVariableForCurrentLine(`popular${popularPlan.statisticsName}Perk${perk.statisticsName}DetailsClick`);
    } else {
      increaseVariableForCurrentLine(`perks${perk.statisticsName}DetailsClick`);
    }
    setModalPerkDetails(perk);
  };

  const onPerkToggleClick = (event) => {
    event.stopPropagation();
  };

  const onVariantToggleClick = (event) => {
    event.stopPropagation();
  };

  const specialMode = (perk) => (!!perk.variants && isPerkChecked && isPerkChecked(perk.id));

  let perkPrice;
  let perkSave;
  if (showPrice) {
    const defaultVariant = getDefaultVariant(perk);
    perkPrice = perk.price || defaultVariant?.price;
    perkSave = perk.save || defaultVariant?.save;
  }

  return (
    <>
      <div
        className={
          cn(
            "plan-perk",
            className ? className : '',
            { '-clickable': showDetailsModal },
            perk.theme ? `-${perk.theme}` : '-light',
            perk.name ? `-${perk.name}` : '',
            specialMode(perk) ? '-special-mode' : ''
          )
        }
        onClick={ showDetailsModal ? openModalPerkDetails : null }
      >
        {
          perk.img && !specialMode(perk) &&
          <div className="plan-perk_bg">
            <img src={ perkImageUrl(perk.img) } className="plan-perk_img -default" alt={ perk.title } />
            <img src={ perk.imgHor ? perkImageUrl(perk.imgHor) : perkImageUrl(perk.img) } className="plan-perk_img -hor" alt={ perk.title } />
            {!!perk.imgVert &&
              <img src={ perkImageUrl(perk.imgVert) } className="plan-perk_img -vert" alt={ perk.title } />
            }
          </div>
        }
        <div className="plan-perk_grid">
          <div className="plan-perk_top">
            <div className="plan-perk_top-item">
              { showNote && <div className="plan-perk_note">Perk</div> }
              <h4 className={ `plan-perk_title ${onPerkChange ? "-with-toggle" : ""}` }>
                <span>{ perk.title }</span>
                {
                  showDetailsModal &&
                  <svg className="icon -right plan-perk_title-icon">
                    <use xlinkHref={ `#svg_right` } />
                  </svg>
                }
              </h4>
            </div>
            {
              onPerkChange &&
              <div className="plan-perk_top-item">
                <label className="toggle" onClick={ onPerkToggleClick }>
                  <input
                    type="checkbox"
                    name="perks"
                    checked={ isPerkChecked(perk.id) }
                    onChange={ () => onPerkChange(perk, fromDetails) }
                  />
                  <div className="toggle_icon" />
                </label>
              </div>
            }
          </div>
          {
            !specialMode(perk) &&
            <div className="plan-perk_bottom">
              <div className="plan-perk_bottom-item -icon">
                <svg className={ `plan-perk_icon icon -${perk.icon ? perk.icon : 'perk-logo'}` }>
                  <use xlinkHref={ `#svg_${perk.icon ? perk.icon : 'verizon-logo'}` } />
                </svg>
              </div>
              {
                showPrice &&
                <div className="plan-perk_bottom-item -price">
                  <div className="plan-perk_price"><strong>${perkPrice}</strong>/mo</div>
                  <div className="plan-perk_price-save">Save ${perkSave}/mo</div>
                </div>
              }
            </div>
          }
          {
            specialMode(perk) &&
            <div className="plan-perk_content">
              <div className="plan-perk_variants">
                {
                  map(perk.variants, (variant, idx) => (
                    <div className="plan-perk_variant" key={ idx }>
                      <label className="plan-perk_variant-label" onClick={ onVariantToggleClick }>
                        <div className="plan-perk_variant-radio">
                          <div className="radio">
                            <input
                              type="radio"
                              checked={ variantsSelected[perk.id] === variant.id }
                              onChange={ () => onVariantChange(perk, variant) }
                            />
                            <div className={ `radio_icon -simple` } />
                          </div>
                        </div>
                        <div className="plan-perk_variant-info">
                          <div className="plan-perk_variant-line -title">
                            ${variant.price}/mo {variant.title.toLowerCase()} subscription
                          </div>
                          <div className="plan-perk_variant-line -save">
                            Save {variant.save}/mo
                          </div>
                          {
                            !!variant.note &&
                            <div className="plan-perk_variant-line -note">
                              {variant.note}
                            </div>
                          }
                        </div>
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
      </div>
      {
        modalPerkDetails &&
        <Modal handleClose={ closeModalPerkDetails } classNameDialog="-md-height" >
          <PerkDetails
            perk={ perk }
            onPerkChange={ onPerkChange }
            isPerkChecked={ isPerkChecked }
            price={ price }
            save={ save }
            variantsSelected={ variantsSelected }
            onVariantChange={ onVariantChange }
            hideTotalInDetails={ hideTotalInDetails }
            popularPlan={ popularPlan }
          />
        </Modal>
      }
    </>);
};

export default PlanPerk;
