import { compact, uniq, includes, findIndex } from 'lodash';

const allPossibleSteps = [
  'splash', // Splash Native notification
  'welcome', // Welcome Screen
  'email', // Email screen
  'mobile_number', // Mobile Number screen
  'passkey', // Passkey screen
  'password_password', // Password screen - input password screen
  'password_save_password_to_device', // Password screen - save password to device Native Notification
  'personal_info', // Personal Info screen
  'faceid_notification', // Face ID Native notification screen
  'intro_setup', // Intro Screen
  'benefits', // Benefits Screen
  'id_check', // ID Check Screen
  'base_intent', // Base Intent Screen
  'base_add_card', // Intent - Add Card Screen
  'shopping_intent_and_notifications_shopping_intent', // Intent - Shopping Intent Screen
  'shopping_intent_and_notifications_contact_sync', // Intent - Contact Sync Screen
  'shopping_intent_and_notifications_access_contacts', // Intent - Contact Sync Native notification Screen
  'shopping_intent_and_notifications_notifications', // Intent - Get Updates Screen
  'shopping_intent_and_notifications_base_notifications', // Intent - Get Updates Native notification Screen
  'ppe_cashback_category', // Debit Category selection screen
  'ppe_autoreload_autoReload', // AutoReload screen - AutoReload form
  'ppe_autoreload_oneTime', // AutoReload screen - OneTime Transfer form
  'card_to_wallet_first_page', // Add Card To Wallet - First page Screen
  'card_to_wallet_native_apple_pay', // Add Card To Wallet - Native Adding Screens
  'card_to_wallet_card_pin', // Add Card To Wallet - Create Pin Screen
  'card_to_wallet_notifications_ask', // Add Card To Wallet - Get Updates Screen
  'card_to_wallet_notification_native', // Add Card To Wallet - Get Updates Native notification Screen
  'sign_in-sign_up' // Reboarding Sign in/Sign Up form
];

const homeScreenSteps = [ 'benefits', 'id_check' ];

const allPossibleSeenScreens = [
  ...allPossibleSteps,
  ...[
    'low_celebration', // App screen celebration - not all steps done.
    'full_celebration', // App screen celebration - all steps done.
    'app_interface' // Reboarding Home Screen
  ]
];

const incentiveScreens = [
  'ppe_cashback_category', 'benefits',
  'base_intent', 'shopping_intent_and_notifications_shopping_intent'
];

const findStepName = (topStep, innerStep) => compact([ topStep, innerStep ]).join('_');

const addSeenScreenNames = (existingData, topStep, innerStep) => {
  const seenScreens = existingData.seenScreens;
  const screenName = findStepName(topStep, innerStep);
  if (topStep === 'ppe_autoreload') {
    if (existingData.autoReloadSeen) {
      const autoReloadStepName = findStepName(screenName, 'autoReload');
      if (includes(allPossibleSeenScreens, autoReloadStepName)) {
        seenScreens.push(autoReloadStepName);
      }
    }
  } else if (includes(allPossibleSeenScreens, screenName)) {
    seenScreens.push(screenName);
  }

  if (existingData.oneTimeSeen) {
    const autoReloadStepName = findStepName('ppe_autoreload', 'autoReload');
    const oneTimeStepName = findStepName('ppe_autoreload', 'oneTime');
    if (
      includes(allPossibleSeenScreens, oneTimeStepName) &&
      !includes(seenScreens, oneTimeStepName)
    ) {
      const autoReloadStepIndex = findIndex(seenScreens, (item) => item === autoReloadStepName);
      if (autoReloadStepIndex >= 0 && seenScreens.length - 1 > autoReloadStepIndex) {
        seenScreens.splice(autoReloadStepIndex + 1, 0, oneTimeStepName);
      } else {
        seenScreens.push(oneTimeStepName);
      }
    }
    seenScreens.push(oneTimeStepName);
  }

  existingData.seenScreens = uniq(seenScreens);
};

export {
  allPossibleSteps, homeScreenSteps, allPossibleSeenScreens, incentiveScreens,
  findStepName, addSeenScreenNames
};
