import React, { useContext } from 'react';
import { map } from 'lodash';
import { IndexData } from "../../index-context";
import { averageCountWithFlag } from "../../helpers";

const TrNAvg = ({ checkedConcepts, data }) => {
  const { averageMode, init } = useContext(IndexData);
  const [ averageCountNum, hasValue ] = averageCountWithFlag(
    data.respondents_average_count, averageMode, init.isInternal, data.view
  );

  return (
    <tr>
      <td className="table-comparison_name-cell">(N)</td>
      {
        map(checkedConcepts, (concept, i) => (
          <td key={ i }>
            <span>({concept.respondents_count})</span>
          </td>
        ))
      }
      <td className="table-comparison_average-cell">
        { hasValue && '(' }
        { averageCountNum }
        { hasValue && ')' }
      </td>
    </tr>
  );
};

export default TrNAvg;
