import React from "react";
import AgreementSection from "./agreementSection";
import AboutAccount from "./userAgreement/aboutAccount";
import SendingMoney from "./userAgreement/sendingMoney";
import SellingAndAccepting from "./userAgreement/sellingAndAccepting";
import RestrictedActivities from "./userAgreement/restrictedActivities";
import Liability from "./userAgreement/liability";
import OtherTerms from "./userAgreement/otherTerms";

const UserAgreement = () => {
  return(
    <div className="agreement">
      <p>
        Last updated on August 14, 2024
      </p>

      <AgreementSection title="About Your Account">
        <AboutAccount />
      </AgreementSection>

      <AgreementSection title="Sending Money and Buying">
        <SendingMoney />
      </AgreementSection>

      <AgreementSection title="Selling and Accepting Payments">
        <SellingAndAccepting />
      </AgreementSection>

      <AgreementSection title="Restricted Activities, Holds, and Other Actions We May Take">
        <RestrictedActivities />
      </AgreementSection>

      <AgreementSection title="Liability for Unauthorized Transactions and Other Errors">
        <Liability />
      </AgreementSection>

      <AgreementSection title="Other Legal Terms">
        <OtherTerms />
      </AgreementSection>

     </div>
  );
}

export default UserAgreement;