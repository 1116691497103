import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const SurveyFlow = () => {
  const { initialData, controllerName, paths } = useContext(BaseContext);
  const canEditSurveyFlow = initialData.can_edit_survey_flow;
  const surveyFlowPath = paths.survey_flow;
  const active = controllerName === 'survey_flows';

  return (
    <>
      {
        canEditSurveyFlow &&
        <li className="sidebar_nav-list-item -sub">
          <a
            className={ classnames("sidebar_nav-link -sub", { '-active': active }) }
            href={ surveyFlowPath }
          >
            Edit Survey Flow
          </a>
        </li>
      }
    </>
  );
};

export default SurveyFlow;
