import React, { useState } from "react";
import { filter, map, uniq, orderBy, includes } from 'lodash';
import PortalModal from "../../common/portal";
import { displayGroups, getGroupName } from "../../helpers";
import DisplayGroupSelector from "../../common/display-group-selector";
import FileView from './fileView';

const FilesModal = ({
  withGroups, onClose, allProjectsList, allFilesList,
  title, activeProject, sortByCreatedAt, noItemsCopy
}) => {
  const currentGroups = withGroups && activeProject ?
    uniq(
      map(
        [ ...[ activeProject ], ...(allProjectsList || []), ...(allFilesList || []) ],
        (el) => (el.archived)
      )
    ) : [];

  const [ archived, setArchived ] = useState(() => (
    activeProject ? activeProject.archived : false
  ));

  const projectsList = withGroups ?
    filter(allProjectsList, (el) => (el.archived === archived)) :
    allProjectsList;
  const filesList = withGroups ?
    filter(allFilesList, (el) => (el.archived === archived)) :
    allFilesList;

  const onGroupChange = (newGroup) => {
    setArchived(newGroup === displayGroups[1]);
  };

  let fullList = [
    ...map(projectsList || [], (project) => (
      {
        key: `Project${project.id}`,
        id: project.id,
        title: project.ppt_report_filename,
        url: project.ppt_report_url,
        createdAt: project.created_at,
        kind: 'project',
        instance: project
      }
    )),
    ...map(filesList || [], (file) => (
      {
        key: `File${file.id}`,
        id: file.id,
        title: file.title,
        url: file.attachment_url,
        createdAt: file.created_at,
        archived: file.archived,
        kind: 'file',
        instance: file
      }
    ))
  ];

  if (sortByCreatedAt) {
    fullList = orderBy(fullList, 'createdAt', [ 'desc' ]);
  }

  const updateInstanceTitle = (listItem) => {
    const instanceField = listItem.kind === "project" ? 'ppt_report_filename' : 'title';
    listItem.instance[instanceField] = listItem.title;
  };

  return (
    <PortalModal
      className="-sm"
      title={ title }
      open
      handleClose={ onClose }
      hideFooter
      headerContent={
        <DisplayGroupSelector
          className="-modal"
          groups={ includes(currentGroups, true) ? displayGroups : [ false ] }
          currentGroup={ getGroupName(archived) }
          onChange={ onGroupChange }
        />
      }
    >
      <>
        <ul className="exports-list">
          {
            !fullList.length &&
            <li className="-no-items">
              <div className="completed">
                {
                  noItemsCopy ?
                    noItemsCopy :
                    'There are no reports at the moment.'
                }
              </div>
            </li>
          }
          {
            !!fullList.length &&
            map(fullList, (item, index) => (
              <li key={ `${item.key}${index}${item.title}` }>
                <FileView file={ item } onChange={ updateInstanceTitle } />
              </li>
            ))
          }
        </ul>
      </>
    </PortalModal>
  );
};

export default FilesModal;
