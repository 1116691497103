const sendMessage = (hash) => {
  (window.parent || window).postMessage(hash, "*");
};

const waitingForMessage = (callback) => {
  window.addEventListener("message", callback);
  return () => {
    window.removeEventListener("message", callback);
  };
};

export { sendMessage, waitingForMessage };
