import React, { useContext } from 'react';
import cn from "classnames";
import { map, upperFirst } from "lodash";
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import { sendMessage } from "../../../../../common/messaging";

const SwitchModal = ({ concepts, current, setCurrentWithUrl, singular, titleField = 'title' }) => {
  const { closeSwitchModal } = useContext(IndexData);

  const onSwitchClick = (e, concept) => {
    e.preventDefault();
    setCurrentWithUrl(concept);
    sendMessage({ to: "Angular", kind: "scorecardOpened", id: concept.id });
    closeSwitchModal();
  };

  return (
    <Modal modalSize="sm" customZIndex={ 1071 }>
      <div className="modal-header -auto-height">
        <span className="modal_close icon-link -no-text -gray" title="Close" role="button" onClick={ closeSwitchModal }>
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title">Switch {upperFirst(singular)}</h3>
      </div>

      <div className="modal-description">
        <p>Click to view a different {singular}.</p>
      </div>

      <div className="modal_body -scrollable">
        <ul className="concept-checker-list">
          {map(concepts, (concept, i) => (
            <li key={ i } className="concept-checker-list_item">
              <div className={ cn("concept-checker -handle", `id-${concept.id}`) }>
                {concept.image_present && <div className="concept-checker_item">
                  {current.id == concept.id && <div className="concept-preview_thumb -sm" style={ { backgroundImage: `url(${concept.image_url})` } } />}
                  {current.id != concept.id && <a className="concept-preview link -text" onClick={ (e) => {onSwitchClick(e, concept);} } href={ concept.report_path }>
                    <div className="concept-preview_thumb -sm" style={ { backgroundImage: `url(${concept.image_url})` } } />
                  </a>}
                </div>}
                <div className="concept-checker_item -grow">
                  {current.id == concept.id && <div className="concept-checker_title -text">{concept[titleField]}</div>}
                  {current.id != concept.id && <a className="concept-checker_title link -text" onClick={ (e) => {onSwitchClick(e, concept);} } href={ concept.report_path }>
                    {concept[titleField]}
                  </a>}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default SwitchModal;
