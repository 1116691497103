import React from 'react';
import { map, find } from 'lodash';
import priceDecorator from '../decorators/price.js';
import classnames from 'classnames';

const plansDescriptions = [
  {
    id: 1,
    channels: "125+",
    options: ["1 Set-Top Box"]
  },
  {
    id: 2,
    channels: "300+",
    options: ["DVR & 1 Set-Top Box", "$50 Verizon Gift Card"]
  },
  {
    id: 3,
    channels: "425+",
    options: ["MR DVR & 1 Set-Top Box", "$100 Verizon Gift Card"]
  }
];

// plans should be [ { id: 1, title: "Your Fios TV", price: 65 },
// { id: 2, title: "More Fios TV", price: 85 },
// { id: 3, title: "The Most Fios TV", price: 105 } ]

// selected is a plan item

// onChange arguments will be [event, plan]

//Usage

//const App = (props) => {
//  const { selected, changeSelected } = useState(null);
//
//  return (
//    <Landing
//      plans={ plans }
//      selected={ selected }
//      onChange={ (event, plan) => { changeSelected(plan) } }
//    />
//  )
//};

const Landing = (props) => {
  const { plans, selected, onChange } = props;

  const description = (plan) => find(plansDescriptions, (el) => el.id == plan.id).channels;
  const options = (plan) => find(plansDescriptions, (el) => el.id == plan.id).options;

  return (
    <>
      <div className="tv-plans-title">
        You can now watch your Fios TV plan on your own eligible connected TV devices using the Fios TV Home App.
      </div>
      <div className="tv-boxes">
        {
          map(plans, (plan, index) => {
            return (
              <div
                key={ `landing${index}` }
                className={ classnames('tv-box', { '-selected-item': !!selected && selected.id == plan.id }) }
                onClick={ () => { onChange(plan); } }
              >
                <div className="tv-box-content">
                  <div className="tv-box-top">
                    <h3>{ plan.title }</h3>
                    <div className="tv-box-description">{ description(plan) } channels</div>
                  </div>
                  <div className="tv-box-bottom">
                    <h3>{ priceDecorator(plan.price, 2) } more</h3>
                    <ul>
                      {
                        map(options(plan), (option, j) => {
                          return( <li key={ `option${index}${j}` }>{ option }</li> )
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className="tv-box-button">
                  <span className="-with-right-arrow">Details</span>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  );
};

export default Landing;