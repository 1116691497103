import React, { useState, useEffect, useRef } from 'react';
import { map, find } from 'lodash';
import classnames from 'classnames';

const AveragesDropdown = ({ currentValue, onUpdate, disabled, data, buzzbackUser }) => {
  const sourceEl = useRef(null);

  const [ showDropDown, updateShowDropDown ] = useState(false);

  const elements = [
    { text: "Project average", value: "project" },
    { text: "Client average", value: "client" }
  ];

  if (buzzbackUser) {
    elements.push({ text: "Category average", value: "platform" });
  }

  const currentText = (
    find(elements, (item) => item.value === currentValue) || {}
  ).text;

  useEffect(() => {
    const body = document.body || document.querySelector("body");
    let pointedToElement = false;

    const bodyListenerFunction = (event) => {
      if (!pointedToElement) {
        updateShowDropDown(false);
      }
      pointedToElement = false;
    };
    const elementListenerFunction = (event) => {
      event.preventDefault();
      pointedToElement = true;
    };

    const el = sourceEl.current;

    el.addEventListener("click", elementListenerFunction);
    body.addEventListener("click", bodyListenerFunction);

    return (() => {
      el.removeEventListener("click", elementListenerFunction);
      body.removeEventListener("click", bodyListenerFunction);
    });
  }, []);

  const openDropDown = (event) => {
    event.preventDefault();
    if (disabled) { return; }
    updateShowDropDown(true);
  };

  const onSelectElement = (event, item) => {
    if ((item.value === "client" && !data.client_cmp_enabled) || (item.value === "platform" && !data.category_cmp_enabled)) {
      return;
    }
    onUpdate(item.value);
    updateShowDropDown(false);
  };

  const getClassName = (item) => {
    if ((item.value === "client" && !data.client_cmp_enabled) || (item.value === "platform" && !data.category_cmp_enabled)) {
      return "disabled-result";
    }
    return "active-result";
  };

  return (
    <div
      className="chosen-container chosen-container-single chosen-container-single-nosearch chosen-container-active chosen-with-drop"
      style={ { width: "100%" } }
      ref={ sourceEl }
    >
      <a
        className={ classnames("chosen-single -default-cursor", { "-disabled": disabled }) }
        tabIndex="-1"
        href="#"
        onClick={ openDropDown }
      >
        <span>{ currentText || "" }</span>
      </a>
      {
        showDropDown &&
        <div className="chosen-drop">
          <ul className="chosen-results">
            {
              map(elements, (item, index) => (
                <li
                  key={ `averages${index}` }
                  className={
                    classnames(
                      getClassName(item),
                      { 'result-selected highlighted': currentValue === item.value }
                    )
                  }
                  onClick={ (event) => onSelectElement(event, item) }
                >
                  { item.text }
                </li>
              ))
            }
          </ul>
        </div>
      }
    </div>
  );
};

export default AveragesDropdown;
