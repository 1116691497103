import React, { useState } from "react";
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";

const Select = ({ id, title, hint, initValue, options, onChange, errors, addEmpty, disabled }) => {
  const [ val, setVal ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setVal(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="form_section">
      <div className="form_section-title -grid">
        <div className="form_section-title-item">
          <h3>{title}</h3>
        </div>
        {hint &&
          <div className="form_section-title-item">
            <div className="info-tooltip">
              <div className="info-tooltip_control">
                <SvgIcon name="i-info" />
              </div>
              <div className="info-tooltip_drop">
                <div className="info-tooltip_drop-inner">
                  {hint}
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div className={ cn("form-group", errors ? 'has-error' : '') }>
        <select
          id={ `-js-${id || ''}` }
          className="form-field -block"
          value={ val }
          onChange={ handleOnChange }
          disabled={ disabled }
        >
          {addEmpty && <option value="">---</option>}
          {map(options, (option) => (
            <option key={ option.value } value={ option.value }>{option.title}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
