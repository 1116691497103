import React, { useState, useEffect } from 'react';
import { find, cloneDeep } from 'lodash';
import classnames from 'classnames';
import { PanelServiceContext } from '../../../contexts';
import InfoToolTip from '../../../../common/infoToolTip';
import Tabs from '../tabs';
import CustomPanelSection from '../customPanelSection';
import FooterButtons from '../footerButtons';
import MainPanelSection from '../mainPanelSection';

const EditPanelServiceCommon = ({
  panelServiceData,
  production,
  completesCount = 0,
  numberOfTargetCompletes = 0,
  paused, stopped, onUpdate, globalDisabled,
  inputErrors,
  getUpdates, onGetUpdates
}) => {
  const expressUrl = panelServiceData.express_url;
  const showTestNSize = panelServiceData.show_test_n_size;
  const services = panelServiceData.options;
  const prodegeProjectId = panelServiceData.data.prodege_project_id;
  const prodegeProjectIdForRecreate = panelServiceData.data.prodege_project_id_for_recreate;
  const activeService = () => (find(services, (service) => service.name === serviceName));
  const isCustom = () => (activeService()?.custom);
  const inputsDisabled = () => ((production && !paused && !stopped) || globalDisabled);

  const [ errors, setErrors ] = useState(inputErrors);
  const [ serviceName, setServiceName ] = useState(panelServiceData.data.panel_service || 'prodege');
  const [ completeUrl, setCompleteUrl ] = useState(panelServiceData.data.panel_service_complete_url || '');
  const [ screenedUrl, setScreenedUrl ] = useState(panelServiceData.data.panel_service_screened_url || '');
  const [ quotaUrl, setQuotaUrl ] = useState(panelServiceData.data.panel_service_quota_url || '');
  const [ testNSize, setTestNSize ] = useState(panelServiceData.data.panel_service_test_n_size || 0);
  const [ prodegeCreateNew, setProdegeCreateNew ] = useState(false);

  const updateStatesToProjectOnes = () => {
    setServiceName(panelServiceData.data.panel_service);
    setCompleteUrl(panelServiceData.data.panel_service_complete_url);
    setScreenedUrl(panelServiceData.data.panel_service_screened_url);
    setQuotaUrl(panelServiceData.data.panel_service_quota_url);
    setTestNSize(panelServiceData.data.panel_service_test_n_size);
    setErrors(null);
  };

  useEffect(() => {
    updateStatesToProjectOnes();
  }, [ panelServiceData ]);

  useEffect(() => {
    setProdegeCreateNew(false);
  }, [ serviceName ]);

  useEffect(() => {
    setErrors(inputErrors);
  }, [ inputErrors ]);

  useEffect(() => {
    if (getUpdates && onGetUpdates) {
      const updatedHash = cloneDeep(panelServiceData);
      updatedHash.data = {
        ...updatedHash.data,
        ...{
          panel_service: serviceName,
          panel_service_complete_url: completeUrl,
          panel_service_screened_url: screenedUrl,
          panel_service_quota_url: quotaUrl,
          panel_service_test_n_size: testNSize
        }
      };
      onGetUpdates(updatedHash);
    }
  }, [ serviceName, completeUrl, screenedUrl, quotaUrl, testNSize ]);

  const anyChanges = () => {
    if (serviceName !== panelServiceData.data.panel_service) {
      return true;
    }
    if (isCustom()) {
      return (
        completeUrl !== panelServiceData.data.panel_service_complete_url ||
        screenedUrl !== panelServiceData.data.panel_service_screened_url ||
        quotaUrl !== panelServiceData.data.panel_service_quota_url ||
        testNSize !== panelServiceData.data.panel_service_test_n_size
      );
    }

    return true;
  };

  const noChanges = !anyChanges();
  useEffect(() => {
    setErrors(null);
  }, [ noChanges ]);

  const handleUpdate = (event) => {
    const params = {
      panel_service: serviceName,
      panel_service_complete_url: completeUrl,
      panel_service_screened_url: screenedUrl,
      panel_service_quota_url: quotaUrl,
      panel_service_test_n_size: testNSize,
      prodege_create_completely_new: prodegeCreateNew
    };
    onUpdate(event, params, { setProdegeCreateNew, isCustom: isCustom() });
  };

  const handleCancel = () => {
    updateStatesToProjectOnes();
  };

  const contextValue = {
    production, expressUrl, showTestNSize,
    services,
    anyChanges,
    inputsDisabled,
    getErrorByField: (field) => (((errors || {})[field] || [])[0]),
    getServiceName: () => (serviceName), setServiceName,
    getCompleteUrl: () => (completeUrl), setCompleteUrl,
    getScreenedUrl: () => (screenedUrl), setScreenedUrl,
    getQuotaUrl: () => (quotaUrl), setQuotaUrl,
    getTestNSize: () => (testNSize), setTestNSize,
    getProdegeProjectId: () => (prodegeProjectId),
    getProdegeProjectIdForRecreate: () => (prodegeProjectIdForRecreate),
    getProdegeCreateNew: () => (prodegeCreateNew), setProdegeCreateNew,
    completesCount, numberOfTargetCompletes
  };

  return (
    <PanelServiceContext.Provider value={ contextValue }>
      <div className="form_section">
        <div className="form_grid">
          <div
            className={
              classnames(
                "form_grid-item -width-full edit-info-item-external",
                { '-error': !!errors }
              )
            }
          >
            <h3 className="form_section-title">
              Panel Service
              {
                production &&
                <InfoToolTip remote position={ 'top-center' } className="-force-margin">
                  Functionality is enabled only when the project is paused or stopped.
                </InfoToolTip>
              }
            </h3>
            <div className="form_section-tabs -no-bottom-margin">
              <Tabs />
              {
                isCustom() ? (
                  <CustomPanelSection />
                ) : (
                  <MainPanelSection />
                )
              }
              {
                !!onUpdate &&
                <FooterButtons
                  onUpdate={ handleUpdate }
                  onCancel={ handleCancel }
                />
              }
            </div>
          </div>
        </div>
      </div>
    </PanelServiceContext.Provider>
  );
};

export default EditPanelServiceCommon;
