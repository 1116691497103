import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { remove, find } from 'lodash';
import { BaseColorPickerContext } from '../contexts';
import ColorSelector from './colorSelector';

const ColorChanger = ({
  initColor, initBgColor, onApply, onCancel
}) => {
  const { updateRanges } = useContext(BaseColorPickerContext);
  const [ color, setColor ] = useState(initColor || '#000000');
  const [ bgColor, setBgColor ] = useState(initBgColor || '#000000');
  const [ kind, setKind ] = useState('color'); // can be 'color' or 'bgcolor'
  const modalColor = kind === 'color' ? color : bgColor;
  const updateModalColor = kind === 'color' ? setColor : setBgColor;

  const handleModalApply = (event) => {
    onApply(event, kind, modalColor);
  };

  const handleModalCancel = (event) => {
    onCancel(event);
  };

  useEffect(() => {
    const newClassName = '-wysiwig-no-overflow';
    const body = document.body || document.querySelector("body");
    const classNames = body.className.split(/\s+/);
    if (!find(classNames, (el) => el === newClassName)) {
      classNames.push(newClassName);
    }
    body.className = classNames.join(' ');
    return () => {
      const classNames = body.className.split(/\s+/);
      remove(classNames,  (el) => el === newClassName);
      body.className = classNames.join(' ');
    };
  }, []);

  return (
    <div
      className="custom-color-picker-template -react-way"
      onClick={ updateRanges }
    >
      <div className="kind-selector">
        <h4
          className={ classnames({ '-active': kind === "color" }) }
          onClick={ () => { setKind('color'); } }
        >
          Text Color
        </h4>
        <h4
          className={ classnames({ '-active': kind === "bgcolor" }) }
          onClick={ () => { setKind('bgcolor'); } }
        >
          BG Color
        </h4>
      </div>
      <ColorSelector
        color={ modalColor }
        updateColor={ updateModalColor }
        onApply={ handleModalApply }
        onCancel={ handleModalCancel }
      />
    </div>
  );
};

export default ColorChanger;
