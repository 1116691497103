import React from 'react';
import { map } from 'lodash';
import classnames from "classnames";

const SelectField = ({ error, label, value, options, onChange, noNulls, placeholder, className, disabled }) => {
  return(
    <div className="form-group">
      <div className="form-group_label -upper">{ label }</div>
      <div className="form-group_control -for-select">
        <select
          className={ classnames("form-control", className) }
          value={ value }
          onChange={ (event) => { onChange(event.target.value) } }
          disabled={ disabled }
        >
          {
            !noNulls &&
            <option value="" >{ placeholder ? placeholder : `Select ${label}` }</option>
          }
          {
            map(options, (option, index) => {
              return (
                <option key={ `Option${option.name}${index}` } value={ option.name }>{ option.title }</option>
              )
            })
          }
        </select>
        { error && <p className="field-error">{ error }</p> }
      </div>
    </div>
  );
};

export default SelectField;