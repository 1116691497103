import React, { useState, useRef, useContext, useEffect } from "react";
import { find } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";
import Http from "../../../../common/http";
import { IndexData } from "../index-context";
import NoData from "./no-data";
import WithData from "./with-data";
import SummaryEditor from "./summary-editor";
import { checkTimeout, checkResult, createSummary, updateSummary } from "./http-helpers";
import Working from "./working";

const Index = ({
  metric, summaryClassName, placeholder, entityTitle, onGotSummary
}) => {
  const [ working, setWorking ] = useState(false);
  const [ disabled, setDisabled ] = useState(false);

  const [ attempt, setAttempt ] = useState(0);
  const attemptRef = useRef(attempt);
  attemptRef.current = attempt;

  const [ showModalForm, setShowModalForm ] = useState(false);

  const { aiSummaries, setAiSummaries, aiSummaryAvailable } = useContext(IndexData);

  const summary = find(aiSummaries, (el) => (
    el.source_id === metric.source_id && el.source_type === metric.source_type && el.tag === metric.tag
  ));

  useEffect(() => {
    if (summary && summary.jid) {
      setWorking(true);
      setAttempt(0);
      checkResult(
        [ { id: summary.id, jid: summary.jid } ],
        setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking,
        { onGotSummary }
      );
    }
  }, []);

  const runAI = () => {
    setWorking(true);
    setAttempt(0);

    createSummary(
      metric,
      (http) => {
        setTimeout(() => {
          checkResult(
            [ http.data ],
            setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking,
            { onGotSummary }
          );
        }, checkTimeout);
      },
      () => {
        setWorking(false);
      },
      {}
    );
  };

  const handleUpdateSummary = () => {
    setWorking(true);
    setAttempt(0);

    updateSummary(
      summary.id,
      (http) => {
        setTimeout(() => {
          checkResult(
            [ http.data ],
            setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking,
            { onGotSummary }
          );
        }, checkTimeout);
      },
      () => {
        setWorking(false);
      },
      {}
    );
  };

  const update = (params) => {
    setDisabled(true);
    Http.put(`/express/api/ai_summaries/${summary.id}`, params).then(
      (http) => {
        if (http.response.status === 200) {
          if (showModalForm) {
            setShowModalForm(false);
          }
          if ('summary' in params) {
            summary.summary = params.summary;
          }
          if ('add_to_report' in params) {
            summary.add_to_report = params.add_to_report;
          }
          summary.edited_at_by = http.data.record.edited_at_by;
          setAiSummaries([ ...aiSummaries ]);
        }
        setDisabled(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const handleEditClick = () => {
    setShowModalForm(true);
  };

  const cancelModal = () => {
    setShowModalForm(false);
  };

  useEffect(() => {
    cancelModal();
  }, [ metric ]);

  const saveModalText = (newSummary) => {
    update({ summary: newSummary });
  };

  const onChangeAddToReport = () => {
    update({ add_to_report: !summary.add_to_report });
  };

  const inProgress = working || summary && !!summary.jid;

  let defineTargetTitle;

  if (metric.source_type === 'Focus::MarkupOption') {
    const titlePart = 'Concept Focus';
    defineTargetTitle = (
      metric.reaction === 'positive' ? `${titlePart} (Positive)` : `${titlePart} (Negative)`
    );
  } else if (metric.tag === 'open-end-claim') {
    defineTargetTitle = 'Thought Bubble';
  } else {
    defineTargetTitle = metric.title || entityTitle;
  }

  return (
    <>
      {
        placeholder ? (
          <div className="summary-panel comparisons_plate -js-scorecard">
            <div className="middle">
              <h3>AI Summary</h3>
              <span>{placeholder}</span>
            </div>
          </div>
        ) : (
          <>
            {
              aiSummaryAvailable &&
              <div className="summary-panel comparisons_plate -js-scorecard">
                <SvgIcon name="scheme" />
                { inProgress && <Working stubText="Working ..." />}

                {!inProgress && !summary &&
                  <NoData
                    runAI={ runAI }
                    text={ `Get AI summary of "${defineTargetTitle}"` }
                  />
                }

                {!inProgress && summary &&
                  <WithData
                    summary={ summary }
                    handleOnChange={ onChangeAddToReport }
                    handleEditClick={ handleEditClick }
                    handleUpdateSummary={ handleUpdateSummary }
                    disabled={ disabled }
                    className={ summaryClassName }
                  />
                }

                {showModalForm &&
                  <SummaryEditor
                    initModalText={ summary.summary }
                    cancelModal={ cancelModal }
                    saveModalText={ saveModalText }
                    disabled={ disabled }
                  />
                }
              </div>
            }
          </>
        )
      }
    </>
  );
};

export default Index;
