import React, { useEffect, useState }  from 'react';
import { createPortal } from 'react-dom';

const ModalWindow = ({ children }) => {
  const body = document.body || document.querySelector("body");

  const [bodyOverflow] = useState(body.style.overflow);
  const [element] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';

    return () => {
      body.removeChild(element);
      body.style.overflow = bodyOverflow;
    };
  }, [body, bodyOverflow, element]);
  return createPortal(children, element);
};

const QuantPortal = ({ title, open, handleClose, children }) => {
  return open
    ? <ModalWindow>
        <div className="quant-portal">
          <div className="content">
            <div className="quant-portal-header">
              <h6 className="quant-portal-title">{title}</h6>
              <button className="quant-portal-cross" onClick={handleClose} type="button"/>
            </div>
            <div className="quant-portal-content">
              {children}
            </div>
            <div className="quant-portal-footer">
              <button onClick={handleClose} className="button" type="button">Back</button>
            </div>
          </div>
        </div>
      </ModalWindow>
    : null;
}

export default QuantPortal;
