import React, {useContext} from 'react';
import { map, each, find, fromPairs, keys } from 'lodash';
import EditQuota from '../../edit_quota';
import { condIndexHash } from '../helper';
import EditGridQuota from "../../edit-grid-quota";
import { PlatformContext } from "../contexts";

const EditScreenerQuota = (props) => {
  const { question, options } = props;
  const PlatformContextValue = useContext(PlatformContext);
  const platformName = PlatformContextValue.platformName || '';

  const createList = () => {
    const list = map(question.answers, (v, index) => {
      const foundCondition = find(
        question.condition_list,
        (el) => el.index === index
      ) || {};
      return {
        answer: v,
        selected: question.kind === 'Grid' ? genGridSelected(foundCondition.cond) : foundCondition.cond !== 'is_not',
        quota: foundCondition.quota || '',
        condIndex: foundCondition.index,
        cond: foundCondition.cond
      };
    });
    const otherCondition = find(question.condition_list, (el) => (
      el.index === condIndexHash.other.converted &&
      question.other_answer
    ));
    if (otherCondition) {
      list.push({
        answer: question.other_answer_text || options.other_specify,
        selected: otherCondition.cond !== 'is_not',
        quota: otherCondition.quota,
        condIndex: otherCondition.index,
        cond: otherCondition.cond
      });
    }
    return list;
  };

  const genGridSelected = (condition) => (
    fromPairs(map(keys(condition), (key) => ([ key, condition[key] !== 'is_not' ])))
  );

  const convertOnSave = (list) => {
    each(question.condition_list, (el) => {
      el.quota = '';
    });
    each(list, (item) => {
      const found = find(
        question.condition_list,
        (el) => el.index === item.condIndex
      );
      if (item.selected) {
        found.cond = item.cond === 'is_not' ? '' : item.cond;
        found.quota = item.quota;
      } else {
        found.cond = item.cond = 'is_not';
      }
    });
  };

  return (
    <>
      {
        question.kind !== 'Grid' &&
        <EditQuota
          { ...props }
          createList={ createList }
          convertOnSave={ convertOnSave }
          platformName={ platformName }
        />
      }
      {
        question.kind === 'Grid' &&
        <EditGridQuota
          { ...props }
          createList={ createList }
          convertOnSave={ convertOnSave }
          platformName={ platformName }
        />
      }
    </>
  );
};

export default EditScreenerQuota;
