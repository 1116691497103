import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { map } from 'lodash';
import ReadonlyImage from './components/readonly_image';
import SelectionLayer from './components/selection_layer';
import { OptionsContext } from './components/contexts';

const ImageWithMarkups = (
  {
    src, loading, updateLoading,
    onSelectionChange, onSelectionAdd,
    onDelete, initialAreas, oneArea,
    getProductName, viewOnly, withDelete,
    forceReinit, imageUseEffectFunction,
    rectHoverClassName, rectClassName, pointClassName
  }
) => {
  const areasInitialState = oneArea ? [ {} ] : [];
  const [ tempAreas, setTempAreas ] = useState(initialAreas || areasInitialState);
  const [ , setPreviousImage ] = useState(src);
  const [ originSize, setOriginSize ] = useState(null);
  const [ onLoadDimensionsChange, setOnLoadDimensionsChange ] = useState(null);
  const [ wrapperRef, setWrapperRef ] = useState(useRef());
  const setGlobalSize = (size) => {
    setOriginSize(size);
  };

  const onLoad = (size) => {
    setGlobalSize(size);
    setOnLoadDimensionsChange({ ...size });
    updateLoading && updateLoading(false);
  };

  useEffect(() => {
    if (viewOnly) {
      setTempAreas(initialAreas || areasInitialState);
    }
  }, [ initialAreas ]);

  useEffect(() => {
    if (forceReinit) {
      setTempAreas(initialAreas);
    }
  }, [ forceReinit ]);

  useLayoutEffect(() => {
    setPreviousImage((oldValue) => {
      if (src && oldValue !== src) {
        setTempAreas(initialAreas || areasInitialState);
      }
      return src;
    });
    updateLoading && updateLoading(true);
  }, [ src ]);

  const handleDelete = (index) => {
    onDelete && onDelete(index);
  };

  const optionsHash = {
    getProductName, viewOnly,
    onSelectionChange, onSelectionAdd,
    getOriginSize: () => (originSize),
    setOriginSize: setGlobalSize,
    scrollContainerElement: null,
    withDelete, handleDelete,
    imageUseEffectFunction,
    rectHoverClassName,
    rectClassName, pointClassName
  };

  return (
    <OptionsContext.Provider value={ optionsHash }>
      <div className="products-greed-item-uploaded">
        <ReadonlyImage
          src={ src }
          onLoad={ onLoad }
          setWrapper={ setWrapperRef }
        />
        {
          !loading && originSize &&
          <div className="products-greed-item-image-rect-wrapper">
            {
              map(tempAreas, (area, index) => (
                <SelectionLayer
                  key={ `area${index}` }
                  index={ index }
                  initialArea={ area }
                  wrapperRef={ wrapperRef }
                  onLoadDimensionsChange={ onLoadDimensionsChange }
                />
              ))
            }
          </div>
        }
      </div>
    </OptionsContext.Provider>

  );
};

export default ImageWithMarkups;
