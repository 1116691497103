import React, { useRef, useContext, useEffect } from "react";
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from '../../index-context';
import { comparisonScorecardCfCreateRegion } from "../../../../common/google-analytics/events";

const ReactionMode = ({ reactionMode, setReactionMode, conceptId }) => {
  const { init, getTourUseEffectArgs, getForceCFMarkupTab } = useContext(IndexData);
  const tourSelectReactionElement = useRef(null);
  const tourCreateReactionElement = useRef(null);
  const [ selectCallback, selectCondition ] = getTourUseEffectArgs(
    "projectModalSelectReaction", tourSelectReactionElement
  );
  const [ createCallback, createCondition ] = getTourUseEffectArgs(
    "projectModalCreateRegion", tourCreateReactionElement
  );
  useEffect(selectCallback, selectCondition);
  useEffect(createCallback, createCondition);

  const forceReactionMode = getForceCFMarkupTab();

  const reactionModeState = forceReactionMode || reactionMode;

  const onCreateRegion = (mode) => {
    comparisonScorecardCfCreateRegion(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: conceptId });
    setReactionMode(mode);
  };

  return (
    <div className="survey-reactions-interface_toggle">
      <div className="radio-tabs">
        <label className="radio-tabs_item" ref={ tourSelectReactionElement }>
          <span className="-help-top">
            <input
              id="mode1"
              name="mode"
              checked={ reactionModeState === 'select' }
              onChange={ () => {setReactionMode('select');} }
              type="radio"
            />
            <span className="radio-tabs_label -with-icon -help-on">
              <SvgIcon name="i-select-reaction" />
              <span>Select Reaction</span>
            </span>
            <span className="-help -margin-top">
              <span className="inner">
                Click on 1 point on the
                { ' ' }
                concept to see the corresponding markups.
              </span>
            </span>
          </span>
        </label>
        <label className="radio-tabs_item" ref={ tourCreateReactionElement }>
          <span className="-help-top">
            <input
              id="mode2"
              name="mode"
              checked={ reactionModeState === 'create' }
              onChange={ () => { onCreateRegion('create');} }
              type="radio"
            />
            <span className="radio-tabs_label -with-icon -help-on">
              <SvgIcon name="i-region" />
              <span>Create Region</span>
            </span>
            <span className="-help -margin-top">
              <span className="inner">
                Create a region to view only the markups in that area.
              </span>
            </span>
          </span>
        </label>
      </div>
    </div>
  );
};

export default ReactionMode;
