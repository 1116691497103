import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../contexts/appContexts';
import Footer from '../components/footer';
import Header from "../components/header";
import Layout from '../components/layout';

const ContactSync = ({ nextStep, skipStep, headerStep, numberOfHeaderSteps }) => {
  const { mergeData } = useContext(GlobalContext);

  useEffect(() => {
    mergeData({ contactSyncShown: true });
  }, []);

  const handleNext = () => {
    mergeData({ contactSyncSkip: false });
    nextStep();
  };

  const handleSkip = () => {
    mergeData({ contactSyncSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      <button
        className="button -full-width"
        onClick={ handleNext }
      >
        Sync Contacts
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  return (
    <Layout header={ header } footer={ footer }>

      <div className="base-intent">
        <div className="content_title">
          <h1>Find people easily by syncing your contacts</h1>
        </div>
        <div className="content_text">
          Pay or get paid with confidence.
        </div>
        <div className="svg-body">
          <svg className={ `contact-sync-svg` }>
            <use xlinkHref={ `#svg_contact-sync` } />
          </svg>
        </div>
      </div>
    </Layout>
  );
};

export default ContactSync;


