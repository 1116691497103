import { map, filter, each, includes, toPairs } from 'lodash';
import Http from '../../../../common/http.js';
import { respondentFailedSurvey } from "../../../common/google-analytics/events";
import { getSurveyData } from './respondent_data.js';

const checkQuotas = (project, answers, gaTrackingId, callback) => {
  Http.post(project.check_quota_url,
    {
      confirmit_respondent_id: project.respondent_id,
      survey_data: getSurveyData(project, answers)
    }
  ).then((response) => {
    if (callback) {
      callback(response.data);
    }
    if (response && response.data && response.data.redirect) {
      respondentFailedSurvey(gaTrackingId, { project_id: project.project_id, respondent_id: project.respondent_id });
      window.location = response.data.redirect;
    }
  },
  (reject) => {
    console.log('something went wrong', reject);
  });
};

const getAllMustNotSelectAnswers = (screening) => {
  if (screening.kind === 'Grid') {
    const eligibles = map(screening.answers, (item) => [ item.id, filter(toPairs(item.eligible), (el) => el[1] === 'is_not')?.[0] ]);
    const filtered = filter(eligibles, (el) => el[1]);
    return map(filtered, (item) => `${item[0]}_${item[1][0]}`);
  }

  return map(
    filter(screening.answers, (a) => a.eligible === 'is_not'),
    (mS) => mS.id
  );
};

const getAllMustSelectAnswers = (screening) => {
  if (screening.kind === 'Grid') {
    const eligibles = map(screening.answers, (item) => [ item.id, filter(toPairs(item.eligible), (el) => el[1] === 'is')?.[0] ]);
    const filtered = filter(eligibles, (el) => el[1]);
    return map(filtered, (item) => `${item[0]}_${item[1][0]}`);
  }

  return map(
    filter(screening.answers, (a) => a.eligible === 'is'),
    (mS) => mS.id
  );
};

const screeningIsEligible = (screening, answers) => {
  const allMustNotSelectAnswers = getAllMustNotSelectAnswers(screening);
  let selectedMustNot = true;
  const allMustSelectAnswers = getAllMustSelectAnswers(screening);
  let mustIncludedCount = 0;

  // if at least one answer selected, that must not be selected, we don't let them pass
  each(allMustNotSelectAnswers, (answer) => {
    if (includes(answers, answer)) {
      selectedMustNot = false;
    }
  });
  if (!selectedMustNot) {
    return false;
  }

  // Now count how many answers are selected from "must be selected" group

  each(allMustSelectAnswers, (mustSelect) => {
    if (includes(answers, mustSelect)) {
      mustIncludedCount += 1;
    }
  });

  if (screening.is_multi) {
    if (screening.select_condition === 0) {
      return allMustSelectAnswers.length === mustIncludedCount;
    }
    return allMustSelectAnswers.length ?
      mustIncludedCount >= screening.select_condition :
      true;
  }

  return allMustSelectAnswers.length ? mustIncludedCount > 0 : true;
};

const groupNotEligible = (group, func, answers) => {
  let result = false;
  each(group, (q) => {
    if (!func(q, answers)) {
      result = true;
    }
  });
  return result;
};

const demographicsNotEligible = (project, answers) => {
  return groupNotEligible(project.demographics, screeningIsEligible, answers);
};
const optionalDemographicsNotEligible = (project, answers) => {
  return groupNotEligible(project.optional_demographics, screeningIsEligible, answers);
};

const screeningsNotEligible = (project, answers, gaTrackingId, listName = 'screenings') => {
  const notEligible = groupNotEligible(project[listName], screeningIsEligible, answers);
  if (!notEligible) {
    checkQuotas(project, answers, gaTrackingId);
  }
  return notEligible;
};

const customMetricsByLocation = (scorings, location) => {
  return filter(scorings, (el) => {
    return el.survey_location === location;
  });
};

const defaultScorings = (scorings) => {
  return filter(scorings, (el) => {
    return !el.survey_location || el.survey_location === 'default';
  });
};

const defaultNotCustom = (scorings) => {
  return filter(scorings, (el) => {
    return (!el.survey_location || el.survey_location === 'default') && !el.custom;
  });
};

export {
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible,
  screeningIsEligible,
  customMetricsByLocation,
  defaultScorings,
  defaultNotCustom,
  checkQuotas
};
