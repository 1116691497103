import React, { useContext } from 'react';
import { map } from 'lodash';
import SvgIcon from "../../../../common/components/svg-icon";
import { LangData } from '../lang-data';
import renderRawHtml from '../../../../common/render_raw_html';

const ExpressLanguageSwitcher = ({ description, showLanguageSwitcher }) => {
  const { lang, setLang, langCode, langName } = useContext(LangData);

  const needOutput = () => {
    return !!langCode && langCode !== 'en' && showLanguageSwitcher;
  };

  if (!needOutput()) {
    return null;
  }

  const languages = [
    {
      name: langName,
      code: langCode
    },
    {
      name: "English",
      code: "en"
    }
  ];

  const selectLang = (event) => {
    setLang(event.target.value);
  };

  return (
    <div className="express-language-control">
      <h4>Switch language for open end answers:</h4>

      <div className="language-switcher radio-tabs -borderer -gray-bg">
        {
          map(languages, (language, index) => (
            <label key={ index } className="radio-tabs_item">
              <input
                type="radio"
                value={ language.code }
                checked={ language.code === lang }
                onChange={ selectLang }
                name="lang-code-radio"
              />
              <div className="radio-tabs_label">{language.name}</div>
            </label>
          ))}
      </div>

      <div className="info-tooltip_control -help-on -help-top">
        <span>
          <span>
            <SvgIcon name="i-info" />
          </span>
          <span className="-help -margin-top -right">
            <span className="inner" { ...renderRawHtml(description) } />
          </span>
        </span>
      </div>
    </div>
  );
};

export default ExpressLanguageSwitcher;
