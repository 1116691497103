import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import StartButton from './start_button.js';

const GroupIntro = ({ title, description, footer, nextStep }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        <div className="survey-start_description">
          <h3 className="survey-start_title -bolder" { ...renderRawHtml(title) } />
          <p { ...renderRawHtml(description) } />
          <p { ...renderRawHtml(footer) } />
        </div>
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default GroupIntro;
