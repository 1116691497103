import React, { useState, useRef, useContext } from 'react';
import classnames from 'classnames';
import { baseContext } from '../contexts';
import TouchModal from '../../../../common/components/popup_inner/touch_modal';
import renderRawHtml from '../../../../common/render_raw_html';

const ZoomImage = ({
  className, src, textAsSrc, children, withHeadWrapper, hideCopy,
  additionalContent, customNote, altText, popupClassName,
  wrapperClassName
}) => {
  const [ clicked, setClicked ] = useState(false);
  const imgEl = useRef(null);
  const { translations } = useContext(baseContext);
  const touchClassName = popupClassName || "-image-in-modal";

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setClicked(true);
  };

  const hint = customNote || translations.image_in_modal_hint;

  const mainContent = (
    additionalContent ?
      (
        <div className="with-additional-content">
          <div className="image-content">
            <div className="prev-force-pointer" />
            {
              src ? (
                <img
                  className={ classnames("-force-pointer", className) }
                  src={ src }
                  ref={ imgEl }
                  onClick={ handleClick }
                  alt={ altText }
                />
              ) : (
                <div
                  className={ classnames("image-from-text -force-pointer", className) }
                  ref={ imgEl }
                  onClick={ handleClick }
                >
                  { textAsSrc }
                </div>
              )
            }
            {
              !hideCopy &&
              <div
                className={ classnames("image-enlarge-text", className) }
                { ...renderRawHtml(hint) }
              />
            }
          </div>
          <div className="additional-content">{ additionalContent }</div>
        </div>
      )
      :
      (
        <>
          <div className="prev-force-pointer" />
          {
            src ? (
              <img
                className={ classnames("-force-pointer", className) }
                src={ src }
                ref={ imgEl }
                onClick={ () => { setClicked(true); } }
                alt={ altText }
              />
            ) : (
              <div
                className={ classnames("image-from-text -force-pointer", className) }
                ref={ imgEl }
                onClick={ () => { setClicked(true); } }
              >
                { textAsSrc }
              </div>
            )
          }
          {
            !hideCopy &&
            <div
              className={ classnames("image-enlarge-text", className) }
              { ...renderRawHtml(hint) }
            />
          }
        </>
      )
  );

  return (
    <>
      {
        withHeadWrapper ?
          <div className={ classnames("wrapper-white-back", wrapperClassName) }>{ mainContent }</div>
          :
          <>{ mainContent }</>
      }
      {
        clicked &&
        <TouchModal
          className={ touchClassName }
          sourceElement={ imgEl.current }
          onClose={ () => { setClicked(false); } }
        >
          {
            children ? children : (
              <>
                { !!src && <img src={ src } /> }
                {
                  !src && !!textAsSrc &&
                  <div className="image-from-text -modal-view">{ textAsSrc }</div>
                }
              </>
            )
          }
        </TouchModal>
      }
    </>
  );
};

export default ZoomImage;
