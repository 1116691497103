import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ResultsPortal = ({ resultsId, children }) => {
  const base = document.getElementById(resultsId);
  const [ element ] = useState(document.createElement('div'));
  useEffect(() => {
    element.className = "filters-results";
    base.appendChild(element);
    return (() => {
      base.removeChild(element);
    });
  }, []);
  return createPortal(children, element);
};

export default ResultsPortal;
