import React, { useContext, useEffect, useState, useRef } from 'react';
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import HeaderLinks from "../../../common/components/header-links";
import { IndexData } from '../../index-context';
import CustomizeViewModal from "../header/customize-view-modal";
import renderRawHtml from "../../../../../common/render_raw_html";
import OverviewModal from "../overview-modal/overview-modal";
import InternalViewWarningModal from "../internal-view-warning-modal";
import FontSelectionModal from "../font-selection-modal";
import TurfModal from "./turf-modal";

const Header = ({ reports, useCustomize = false, sortGroups }) => {
  const {
    init, data, averageMode, turfIsShown, openTurfModal, closeTurfModal,
    getTourUseEffectArgs, showOverviewModal, openOverviewModal, closeOverviewModal,
    fontSelectionModalData, exportData
  } = useContext(IndexData);

  const tourTurfElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs(
    "projectTURFButton",
    tourTurfElement
  );
  useEffect(callback, condition);

  const [ showCustomize, setShowCustomize ] = useState(false);
  const showTurf = turfIsShown();

  const onCustomizeClose = () => {
    setShowCustomize(false);
  };

  const { project } = init;

  useEffect(() => {
    if (!isEmpty(data) && window.location.hash === "#!?turf") {
      openTurfModal(true);
    }
  }, [ data ]);

  const location = useLocation();
  useEffect(() => {
    if (location.hash !== "#!?turf") {
      closeTurfModal(true);
    }
  }, [ location ]);

  return (
    <>
      <div className="content_top">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item -grow">
              <h1 className="header_title" { ...renderRawHtml(project.title) } />
            </div>
            <div className="header_grid-item">
              {
                init.project.is_turf &&
                <button
                  className="button -secondary header_button js-turf"
                  type="button"
                  onClick={ () => { openTurfModal(); } }
                  ref={ tourTurfElement }
                >
                  TURF Analysis
                </button>
              }
              {
                useCustomize &&
                <button
                  className="button -secondary header_button button"
                  type="button"
                  onClick={ () => { setShowCustomize(true); } }
                >
                  Customize View
                </button>
              }
              <HeaderLinks
                className="-right-sided -no-min-width"
                exportUrl={ init.urls.export }
                reports={ reports }
                i18n={ init.i18n }
                data={ data }
                tourName="projectComparisonExport"
              />
            </div>
          </div>
        </header>
      </div>
      {
        useCustomize && showCustomize &&
        <CustomizeViewModal
          onClose={ onCustomizeClose }
          plural="items"
          sortGroups={ sortGroups }
        />
      }
      {
        init.project.is_turf && showTurf &&
        <TurfModal onClose={ () => { closeTurfModal(); } } />
      }
      {
        showOverviewModal &&
        <OverviewModal
          onClose={ closeOverviewModal }
          plural={ 'items' }
        />
      }
      {
        exportData &&
        <InternalViewWarningModal />
      }
      { !!fontSelectionModalData && <FontSelectionModal /> }
    </>
  );
};

export default Header;
