import React, { useContext } from 'react';
import cn from "classnames";
import { IndexData } from "../../index-context";

const OverviewStatusBar = ({ values, box, statTesting }) => {
  const { averageMode } = useContext(IndexData);

  let hl = '';

  if (box && values.highlight[averageMode]) {
    hl = values.highlight[averageMode][box];
  } else if (box && values.highlight[box]) {
    hl = values.highlight[box];
  } else {
    hl = values.highlight;
  }

  const pc = box ? values.percentage[box] : values.percentage;
  const smallBar = values.percentage[`${box}_decimal`] < 30;

  return (
    <div className="barchart">
      <div className="barchart_container">
        {
          !values &&
          <div className="barchart_bar" style={ { 'height': 0 } } >
            <div className="barchart_bar-text -small-bar">0%</div>
          </div>
        }
        {
          !!values &&
          <div className={ cn("barchart_bar", { "-color-par": statTesting === "none", [`-color-${hl}`]: statTesting !== "none" }) } style={ { 'height': pc } } >
            <div className={ cn("barchart_bar-text", { "-small-bar": smallBar }) }>
              { pc }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default OverviewStatusBar;
