import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html';
import StartButton from './start_button';

const PreIntro = ({ nextStep, text }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        <div className="survey-start_description">
          <span { ...renderRawHtml(text) }  />
        </div>
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default PreIntro;
