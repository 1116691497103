import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

export const Header = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);
  return (

    <header className="header">

      <div className="header_top">
        <div className="inner-content -base -percent">
          <div className="header_grid">
            <div className="header_grid-item">
              <ul className="header_menu -secondary">
                <li className="header_menu-item">
                  <div className="header_menu-link -active">Personal</div>
                </li>
                <li className="header_menu-item">
                  <div className="header_menu-link">Business</div>
                </li>
              </ul>
            </div>
            <div className="header_grid-item">
              <ul className="header_menu -secondary">
                <li className="header_menu-item -bordered">
                  <div className="header_menu-link">Stores</div>
                </li>
                <li className="header_menu-item -bordered">
                  <div className="header_menu-link">Español</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="header_main">
        <div className="inner-content -base -percent">
          <div className="header_grid">
            <div className="header_grid-item">
              <div className="header_logo">
                <svg className="icon -logo"><use xlinkHref="#svg_verizon-logo" /></svg>
              </div>
            </div>
            <div className="header_grid-item -hide-mobile">
              <ul className="header_menu -primary">
                <li className="header_menu-item">
                  <div className="header_menu-link">Account</div>
                </li>
                <li className="header_menu-item">
                  <div className="header_menu-link">Shop</div>
                </li>
                <li className="header_menu-item">
                  <div className="header_menu-link">Why Verizon</div>
                </li>
                <li className="header_menu-item">
                  <div className="header_menu-link">Support</div>
                </li>
              </ul>
            </div>
            <div className="header_grid-item">
              <ul className="header_menu -primary">
                <li className="header_menu-item">
                  <div className="header_menu-link">Sign out</div>
                </li>
                <li className="header_menu-item">
                  <div className="header_menu-link">
                    <svg className="icon -search"><use xlinkHref="#svg_search" /></svg>
                  </div>
                </li>
                <li className="header_menu-item">
                  <div className="header_menu-link">
                    <svg className="icon -bag"><use xlinkHref="#svg_shopping-bag" /></svg>
                  </div>
                </li>
                <li className="header_menu-item -show-mobile">
                  <div className="header_menu-link">
                    <svg className="icon -sandwich"><use xlinkHref="#svg_sandwich" /></svg>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
