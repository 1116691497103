import React from 'react';
import { keys, find, map } from "lodash";

const ButtonToggle = ({ selectedFontSize, setFontSize }) => {
  const extendedFontSizes = {
    "5": 500,
    "6": 600,
    "7": 700,
    "8": 800,
    "9": 900,
    "10": 1000,
    "11": 1100,
    "12": 1200,
    "13": 1300,
    "14": 1400
  };

  const selectedFontKey = (
    find(
      keys(extendedFontSizes),
      (key) => extendedFontSizes[key] === selectedFontSize
    )
  );

  return (
    <div className="radio-tabs -extra_sm -centered -gray-bg">
      {
        map(keys(extendedFontSizes), (key) => (
          <label className="radio-tabs_item" key={ key }>
            <input
              id={ `id_${key}` }
              name={ `name_${key}` }
              checked={ selectedFontKey === key }
              onChange={ () => setFontSize(extendedFontSizes[key]) }
              type="radio"
            />
            <span className="radio-tabs_label">
              <span>{ key }</span>
            </span>
          </label>
        ))
      }
    </div>
  );
};

export default ButtonToggle;
