import React, { useContext } from 'react';
import { capitalize } from 'lodash';
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import ComparisonsLegend from "../comparisons-legend";
import TopFilter from "../../filters/app";
import OverviewConceptsTable from "./overview-concepts-table";
import OverviewModeSelector from "./overview-mode-selector";

const OverviewModal = ({ onClose }) => {
  const { data, loading, loadData } = useContext(IndexData);

  return (
    <Modal
      modalSize="lg"
      modal
    >
      { loading && <div className="page-loader -inner-loader" /> }
      <div className="modal-header -auto-height">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ onClose }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title" id="clientLabel">Database Comparison</h3>
      </div>

      <OverviewModeSelector />
      <br />

      <TopFilter
        statTesting
        statTestingItemTitle="ad"
        data={ data }
        loadData={ loadData }
      />

      {/*<hr />*/}
      <div className="comparisons_table table-wrap -concept-comparison -viewport -relative -overview">
        <OverviewConceptsTable />
      </div>

      <ComparisonsLegend
        statTesting={ data.stat_testing }
        averageMode={ data.average_mode }
        overviewModal
        view={ data.view }
      />
    </Modal>
  );
};

export default OverviewModal;
