import React from 'react';

const renderFeature = (item, idx) => {
  return (

    <li className="best-perks_features_item" key={`bestPerks${idx}`}>

      { typeof item === 'string' ? item : (
        <>
          <h6 className="best-perks_features_title">{item.title}</h6>
          <div className="best-perks_features_text">
            {typeof item.text === 'string' ? (
              item.text
            ) : (
              item.text.map((text, idx) => (
                <p key={`bestPerksText${idx}`}>{text}</p>
              ))
            )}
          </div>
        </>
      )}
    </li>
  )
}

const BestPerks = ({featuresList, modificator, title}) => {
  return <div className={`best-perks ${modificator ? modificator :''}`}>
    <p className="best-perks-text">
      {title}
    </p>
    <div className="best-perks_grid">
      <div className="best-perks_column -icons">
        <div className="best-perks-icon"/>
      </div>
      <div className="best-perks_column -features">
        <ul className="best-perks_features">
          {featuresList.map(renderFeature)}
        </ul>
      </div>
    </div>

  </div>
}

export default BestPerks;
