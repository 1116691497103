import React from 'react';
import classnames from 'classnames';
import { map } from 'lodash';
import getLineName from '../line_name';
import renderRawHtml from '../../../../common/render_raw_html';
import { Header } from '../header';

const Checkout = ({ currentLine, selectedLines, nextStep, selectedPlans, total }) => {
  const isFinal = currentLine === selectedLines;
  return (
    <div className="content">
      <Header />
      <div>
        <div className="inner-content">
          <h2 className="summary-title">
            {isFinal && <span>Let's review your selections.</span>}
            {!isFinal && <span>Let's choose a plan for your {getLineName(currentLine)} line.</span>}
          </h2>
          <div className="summary-table">
            <div className="summary-table-header">
              <div className="row">
                <div className="col">
                  <h4>Line</h4>
                </div>
                <div className="col -plan">
                  <h4>Plan</h4>
                </div>
                <div className="col">
                  <h4>Monthly Price</h4>
                </div>
              </div>
            </div>
            <div className="summary-table-body">
              {map(new Array(selectedLines), (item, index) => {
                const plan = selectedPlans[index];
                return (
                  <div key={ `row${index}` } className="row">
                    <div className="col">
                      <h2 className="-middle">Line {index + 1}</h2>
                    </div>
                    <div className={ classnames('col -plan', { '-blank': !plan }) }>
                      {plan && <h2 className="-middle" { ...renderRawHtml(plan.title) } />}
                    </div>
                    <div className={ classnames('col', { '-blank': !plan }) }>
                      {plan && <h2 className="-middle">${plan.price}.00</h2>}
                    </div>
                  </div>
                );
              })}
              {isFinal && (
                <div className="row -total">
                  <div className="col" />
                  <div className="col -plan" />
                  <div className="col">
                    <h2 className="-middle">Total: ${total}.00</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="start_buttons">
            <button
              className="summary-button button"
              onClick={ () => {
                nextStep(currentLine, isFinal);
              } }
            >
              {isFinal ? 'Finish' : 'Choose next plan'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
