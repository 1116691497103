import { sendMessage, waitingForMessage } from '../../../../../../common/messaging';
const closePopupMessage = 'closedDownloadPopup';
const downloadMessage = 'DownloadMessage';
const downloadIframeClickMessage = 'DownloadiFrameClick';

const sendClosePopupMessage = () => {
  sendMessage({ kind: closePopupMessage });
};

const onClosePopup = (callback) => {
  return waitingForMessage((event) => {
    if (event?.data.kind === closePopupMessage) {
      callback(event.data);
    }
  });
};

const onDownloadClick = (callback) => {
  return waitingForMessage((event) => {
    if (event?.data.kind === downloadMessage && typeof (event.data) === 'object') {
      callback(event.data);
    }
  });
};

const onDownloadIframeClick = (callback) => {
  return waitingForMessage((event) => {
    if (event?.data.kind === downloadIframeClickMessage && typeof (event.data) === 'object') {
      callback(event.data);
    }
  });
};

export {
  closePopupMessage, downloadIframeClickMessage, downloadMessage,
  sendClosePopupMessage, onClosePopup,
  onDownloadClick, onDownloadIframeClick
};
