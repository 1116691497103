import React, { useContext, useState } from 'react';
import CreatableSelect from "react-select/creatable";
import { map, orderBy } from "lodash";
import SvgIcon from "../../../../../../common/components/svg-icon";
import { KeywordsData } from "./index";

const KeywordDetails = ({ keyword, closeConfigure, closeKeywordDetails }) => {
  const { getChildrenOptions, updateKeywordText, unmerge, getChildren } = useContext(KeywordsData);
  const [ edit, setEdit ] = useState(false);
  const [ defaultOption, options ] = getChildrenOptions(keyword);
  const [ selected, setSelected ] = useState(defaultOption);

  const childKeywords = orderBy(getChildren(keyword), [ 'total', 'text' ], [ 'desc', 'asc' ]);

  const cancelEdit = () => {
    setEdit(false);
  };

  const handleChange = (newValue) => {
    setSelected(newValue);
  };

  const handleUnmerge = () => {
    unmerge(keyword, closeKeywordDetails);
  };

  const handleChildUnmerge = (k) => {
    if (childKeywords.length > 2) {
      unmerge(k);
    } else {
      handleUnmerge();
    }
  };

  return (
    <div className="modal_form form-horizontal">
      <div className="modal_header">
        <span
          aria-hidden="true"
          className="modal_close icon-link -no-text -gray"
          onClick={ closeConfigure }
          role="button"
          title="Close"
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        {!edit && <h3 className="modal_title">
          <button className="icon-link -keyword-popup-back" title="Back" onClick={ closeKeywordDetails }>
            <SvgIcon className="modal_title-icon -big" name="arrow-pointer-left" />
          </button>

          <SvgIcon className="modal_title-icon -big" name="i-merged" />
          {keyword.text}
        </h3>}

        {edit && <div className="modal_title-edit">
          <button className="icon-link -keyword-popup-back" title="Back" onClick={ closeKeywordDetails }>
            <SvgIcon className="modal_title-icon -big" name="arrow-pointer-left" />
          </button>
          <div className="modal_title-edit-inplace">
            <CreatableSelect
              defaultValue={ selected }
              options={ options }
              formatCreateLabel={ (v) => (`Use "${v}"`) }
              onChange={ handleChange }
            />
          </div>
          <div className="modal_title-edit-control">
            <button className="button" onClick={ () => {updateKeywordText(keyword, selected.value, cancelEdit);} }>Save</button>
            <button className="button -outline" onClick={ cancelEdit }>Cancel</button>
          </div>
        </div>}

        {!edit && <div className="modal_header-addon">
          <button className="icon-link" onClick={ () => { setEdit(true); } }>
            <SvgIcon className="icon-link_icon" name="i-pencil" />
            <span className="icon-link_text">Edit</span>
          </button>
          <button className="icon-link -danger" onClick={ handleUnmerge }>
            <SvgIcon className="icon-link_icon" name="i-unmerge" />
            <span className="icon-link_text">Unmerge All</span>
          </button>
        </div>}
      </div>

      <div className="modal_body">
        <div className="keywords-wrap">
          <ul className="keywords-list">
            {map(childKeywords, (k) => (
              <li key={ k.ids[0] } className="keywords-list_item keyword">
                <div className="keyword_content">
                  <div className="keyword_static -clickable">
                    <span className="keyword_text">{k.text}</span>
                  </div>
                </div>
                <div className="keyword_info">
                  <div className="keyword_merged-control">
                    <div className="keyword_merged-buttons">
                      <button className="icon-link -danger" onClick={ () => {handleChildUnmerge(k);} }>
                        <SvgIcon className="icon-link_icon" name="i-unmerge" />
                        <span className="icon-link_text">Unmerge</span>
                      </button>
                    </div>
                  </div>
                  <span className="keyword_count" title="Count">{k.total}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="modal_footer -no-border-top">
        <div className="-align-right">
          <button className="button -outline" onClick={ closeKeywordDetails }>Back</button>
          <button className="button -submit" onClick={ closeConfigure }>Close</button>
        </div>
      </div>
    </div>
  );
};

export default KeywordDetails;
