import Slider from "react-slick";
import React from "react";

const settingsSlider = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 10000,
      settings: 'unslick'
    },
    {
      breakpoint: 768,
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
      }
    }
  ]
};

const CommonSlider = ({ className, children, sliderRef, onSwipe, customSettings = {} }) => {
  const settings = { ...settingsSlider, ...customSettings };

  let startX = 0;

  const swipeAction = (event) => {
    const { type } = event;
    const { screenX } = event.changedTouches[0];
    const threshold = 15;

    if (type === 'touchstart') {
      startX = screenX;
    } else if (type === 'touchmove') {
      if (screenX > startX + threshold || screenX < startX - threshold) {
        // moved more than 20px left or right
        document.body.classList.add('prevent-scroll');
      }
    } else if (type === 'touchend') {
      document.body.classList.remove('prevent-scroll');
      startX = 0;
    }
  };

  return (
    <div
      onTouchEnd={ swipeAction }
      onTouchMove={ swipeAction }
      onTouchStart={ swipeAction }
    >
      <Slider className={ className } { ...settings } ref={ sliderRef } onSwipe={ onSwipe }>
        {children}
      </Slider>
    </div>
  );
};

export default CommonSlider;
