import React, { Fragment } from 'react';
import { clone, map } from 'lodash';
import classnames from 'classnames';
import { planCombiName } from './helper';

const PricingModalSpecialBody = ({ pricing, plans }) => {
  let repeatedTimes = 0;
  let tempArr = [];
  return (
    <div className="pricing-table">
      <div className="pricing-table_row -legend">
        <div className="pricing-table_cell -title">Line</div>
        {
          map(pricing[0], (pricingCellTitle, index) => (
            <div
              key={ `cell${index}` }
              className={
                classnames(
                  "pricing-table_cell -special",
                  { '-pale-border': index !== 1 && index < 4 },
                  { '-hard-border': index === 1 }
                )
              }
            >
              {index + 1}-line account{index === 4 ? '+' : ''}
            </div>
          ))
        }
      </div>
      {
        map(plans, (plan, planIndex) => {
          const nextPriceTheSame = (
            planIndex !== plans.length - 1 &&
            pricing[plan.id - 1][0] === pricing[plans[planIndex + 1].id - 1][0]
          );
          let localPlanNames;
          tempArr.push(plan.name);
          if (!nextPriceTheSame) {
            localPlanNames = clone(tempArr);
            tempArr = [];
          }
          return (
            <Fragment key={ `plan${planIndex}` }>
              {
                !nextPriceTheSame &&
                <div key={ `line${planIndex}` } className="pricing-table_row -data">
                  <div className="pricing-table_cell -title">
                    { planCombiName(localPlanNames) }
                  </div>
                  {
                    map(pricing[plan.id - 1], (pricingCell, innerIndex) => {
                      const repeated = innerIndex !== pricingCell.length - 1 &&
                        pricingCell === pricing[plan.id - 1][innerIndex + 1];
                      let localRepeated = 0;
                      if (repeated) {
                        repeatedTimes += 1;
                      } else {
                        localRepeated = repeatedTimes + 1;
                        repeatedTimes = 0;
                      }
                      return (
                        <Fragment key={ `cell${planIndex}${innerIndex}` }>
                          {
                            !repeated &&
                            <div
                              className={
                                classnames(
                                  "pricing-table_cell -special -repeated",
                                  `-repeated-${localRepeated}`
                                )
                              }
                            >
                              {
                                !repeated &&
                                <>
                                  ${pricingCell}/mo
                                  <br />
                                  each
                                </>
                              }
                            </div>
                          }
                        </Fragment>
                      );
                    })
                  }
                </div>
              }
            </Fragment>
          );
        })
      }
    </div>
  );
};

export default PricingModalSpecialBody;
