import Http from './http';

const copyToClipboard = ({ text, asyncUrl, asyncParams, asyncField, goodCallback, badCallback }) => {
  const afterCopy = (response) => {
    goodCallback && goodCallback(response);
  };
  const afterCopyBad = (response) => {
    badCallback && badCallback(response);
  };

  if (text) {
    navigator.clipboard.writeText(text).then(afterCopy, afterCopyBad);
    return;
  }

  const asyncCall = (serverCallBack, badResponse) => (
    Http.postForAsyncCopy(
      asyncUrl, asyncParams || {}, asyncField,
      serverCallBack, badResponse
    )
  );

  // https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
  if (typeof window.ClipboardItem && navigator?.clipboard?.write) {
  // if (false) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    //   by a direct user interaction. You can't use it async in a promise.
    //   But! You can wrap the promise in a ClipboardItem, and give that to
    //   the clipboard API.
    //   Found this on https://developer.apple.com/forums/thread/691873
    const textItem = new window.ClipboardItem({ "text/plain": asyncCall() });
    navigator.clipboard.write([ textItem ]).then(afterCopy, afterCopyBad);
  } else {
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.
    asyncCall(
      (text) => {
        navigator.clipboard.writeText(text).then(afterCopy, afterCopyBad);
      },
      (response) => {
        afterCopyBad(response);
      }
    );
  }
};

export { copyToClipboard };
