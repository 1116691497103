import React from "react";

const RadioPlate = ({ item, name, radioChange, iconPrefix }) => {
  const complexId = `${name}-${item.id}`;

  return (
    <div className="radio-plate">
      <input
        type="radio"
        name={ name }
        value={ item.id }
        id={ complexId }
        onChange={ () => {
          radioChange(item.id);
        } }
      />
      <label htmlFor={ complexId } className="radio-plate_label">
        <span className="radio-plate_icon">
          <svg className={ `radio-plate_svg -${item.id}` }>
            <use xlinkHref={ `#svg_${iconPrefix}-${item.id}` } />
          </svg>
        </span>
        <span className="radio-plate-text">{item.title}</span>
      </label>
    </div>
  );
};

export default RadioPlate;


