import React, { useState, useContext, useEffect } from 'react';
import { QuotaModeGlobalContext, QuotaModeLocalContext } from '../contexts';

const QuotaModeLocalState = ({ question, takeGlobalValue, children }) => {
  const { quotaMode, setQuotaMode } = useContext(QuotaModeGlobalContext);
  const [ quotaModeLocal, setQuotaModeLocal ] = useState(quotaMode);
  useEffect(() => {
    setQuotaModeLocal(quotaMode);
  }, [ quotaMode ]);
  const forceUpdateLocalValueToGlobal = () => {
    setQuotaModeLocal(quotaMode);
  };
  const forceChangeGlobalValueToLocal = () => {
    setQuotaMode(quotaModeLocal);
    if (!takeGlobalValue) {
      question.quota_mode = quotaModeLocal;
    }
  };
  const contextValue = {
    quotaModeLocal, setQuotaModeLocal,
    forceUpdateLocalValueToGlobal, forceChangeGlobalValueToLocal
  };

  return (
    <QuotaModeLocalContext.Provider value={ contextValue }>
      { children }
    </QuotaModeLocalContext.Provider>
  );
};

export default QuotaModeLocalState;
