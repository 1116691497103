import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import StartButton from './start_button.js';

const Qualified = ({
  nextStep, title, descriptionTop,
  descriptionBottom, descriptionFooter,
  description
}) => {
  let createdDescription = description;
  if (!createdDescription) {
    createdDescription = descriptionTop;
  }

  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        <h3 className="survey-start_title">
          <b { ...renderRawHtml(title) } />
        </h3>
        <div className="survey-start_description">
          <span { ...renderRawHtml(createdDescription) } />
          {
            descriptionBottom &&
            <>
              <br />
              <span { ...renderRawHtml(descriptionBottom) } />
            </>
          }
        </div>
        {
          descriptionFooter &&
          <div className="survey-start_title" { ...renderRawHtml(descriptionFooter) } />
        }
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default Qualified;
