import React, { useContext, useEffect } from 'react';
import NativeModal from "../components/nativeModal";
import { GlobalContext } from "../contexts/appContexts";

const FaceIdNotification = () => {
  const { nextStep, mergeData } = useContext(GlobalContext);
  useEffect(() => {
    mergeData({ faceIdShown: true });
  }, []);

  const handleNotAllow = () => {
    mergeData({ faceIdAllow: false });
    nextStep();
  };

  const handleOk = () => {
    mergeData({ faceIdAllow: true });
    nextStep();
  };

  const nativeModalButtons = (
    <>
      <button className="button-native" onClick={ () => { handleNotAllow(); } }>
        Don't Allow
      </button>
      <button className="button-native -bold" onClick={ () => { handleOk(); } }>
        OK
      </button>
    </>
  );

  return (
    <div className="native-notification-background">
      <NativeModal buttonsAsRows buttons={ nativeModalButtons }>
        <>
          <h2>
            Do you want to allow “PayPal” to use Face ID?
          </h2>
          <p>
            Use Face ID for faster login to PayPal.
          </p>
        </>
      </NativeModal>
    </div>
  );
};

export default FaceIdNotification;


