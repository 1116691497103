import React, { useState, useLayoutEffect, useEffect } from 'react';

const FrameWrapper = ({ src, loadCallback }) => {
  const ref = React.useRef();
  const [ height, setHeight ] = useState('0px');
  const [ show, setShow ] = useState(false);
  const onLoad = () => {
    loadCallback && loadCallback();
  };

  useLayoutEffect(() => {
    setShow(false);
    setHeight('0px');

    const callback = (ev) => {
      const { iframeUpdated, newHeight, currentSrc } = ev.data;
      if (iframeUpdated && !!newHeight && currentSrc === src) {
        setHeight(`${newHeight}px`);
      }
    };
    window.addEventListener("message", callback);
    return (() => {
      window.removeEventListener("message", callback);
    });
  }, [ src ]);

  useEffect(() => {
    setShow(true);
  }, [ show ]);

  return (
    <>
      {
        show &&
        <iframe
          className="v5g-iframe"
          ref={ ref }
          onLoad={ onLoad }
          src={ src }
          width="100%"
          height={ height }
          scrolling="no"
          style={ {
            width: '100%',
            overflow: 'hidden'
          } }
        />
      }
      {
        (!show || height === "0px") &&
        <div className="iframe-loader" />
      }
    </>
  );
};

export { FrameWrapper };
