import React from 'react';
import classnames from "classnames";
import { map, each, filter } from "lodash";

const Doughnut = (
  {
    data, options, hideChart,
    hideLegend, className,
    percentsInChart
  }
) => {
  if (!data) { return (<></>); }

  const width = options.width || 400,
    height = options.height || 400,
    viewBox = options.viewBox || "-22 -13 255 130",
    centerX = 50, centerY = 50, innerRadius = 18, outerRadius = 70, d = 1,
    colors = [ '#D52B1E', '#000000', '#D8D9D9', '#747776' ];
    // legendX = 135, legendY = -15, lineHeight = 20, rectSize = 7, rectRx = 2, textOffset = 6;

  let shiftAngle = -Math.PI / 2;

  const corrected = (cx, cy, ir, or, s, e, d, noCor) => {
    const xxyy = [
      [ cx + (ir * Math.cos(s)), cy + (ir * Math.sin(s)) ],
      [ cx + (or * Math.cos(s)), cy + (or * Math.sin(s)) ],
      [ cx + (or * Math.cos(e)), cy + (or * Math.sin(e)) ],
      [ cx + (ir * Math.cos(e)), cy + (ir * Math.sin(e)) ]
    ];

    if (!noCor && (e - s > 3 / 180 * Math.PI)) {
      xxyy[0][0] = xxyy[0][0] + (d * Math.cos(s + (Math.PI / 2)));
      xxyy[0][1] = xxyy[0][1] + (d * Math.sin(s + (Math.PI / 2)));

      xxyy[1][0] = xxyy[1][0] + (d * Math.cos(s + (Math.PI / 2)));
      xxyy[1][1] = xxyy[1][1] + (d * Math.sin(s + (Math.PI / 2)));

      xxyy[2][0] = xxyy[2][0] + (d * Math.cos(e - (Math.PI / 2)));
      xxyy[2][1] = xxyy[2][1] + (d * Math.sin(e - (Math.PI / 2)));

      xxyy[3][0] = xxyy[3][0] + (d * Math.cos(e - (Math.PI / 2)));
      xxyy[3][1] = xxyy[3][1] + (d * Math.sin(e - (Math.PI / 2)));
    }

    return xxyy;
  };

  const paths = map(data.answers, (answer) => {
    const percent = (answer.percent === 100 ? 99.99 : answer.percent),
      startAngle = shiftAngle,
      endAngle = shiftAngle + (2 * Math.PI * percent / 100);

    shiftAngle = endAngle;

    const p = corrected(centerX, centerY, innerRadius, outerRadius, startAngle, endAngle, d),
      angleDiff = endAngle - startAngle,
      largeArc = angleDiff % (Math.PI * 2) > Math.PI ? 1 : 0;


    return `M${p[0].join()
    } L${p[1].join()
    } A${[ outerRadius, outerRadius ].join()} 0 ${largeArc} 1 ${p[2].join()
    } L${p[3].join()
    } A${[ innerRadius, innerRadius ].join()} 0 ${largeArc} 0 ${p[0].join()
    } z`;
  });

  // Texts with percent definition

  let avgWidth, avgHeight = 16;
  const offsetX = 49, offsetY = 52;
  let currentAngle = -90;
  const angleToRad = (angle) => (angle * (Math.PI / 180));
  const percentToAngle = (percent) => (percent * (360 / 100));

  const percentTexts = map(data.answers, (item, index) => {
    const itemAngle = percentToAngle(item.percent);
    currentAngle += itemAngle;
    if (currentAngle >= 360) {currentAngle -= 360;}
    const midAngle = Math.round(currentAngle - (itemAngle / 2));

    const additionX = () => (-avgWidth / 2);
    const additionY = () => (avgHeight / 2);

    let radiusX = 43, radiusY = 43, black = (index > 1);
    if (item.percent < 10) {
      black = true;
      avgWidth = 17;
      avgHeight = 7;
      radiusX += 40;
      radiusY += 40;
      if ((midAngle > 130 && midAngle < 170) || (midAngle >= 190 && midAngle < 240)) {
        radiusX += 5;
        radiusY += 5;
      }
      if (midAngle >= 170 && midAngle < 190) {
        radiusX += 8;
        radiusY += 8;
      }
    }  else if (item.percent < 17) {
      radiusX += 15;
      radiusY += 15;
      avgWidth = 35;
    } else if (item.percent < 99) {
      avgWidth = 38;
    } else {
      avgWidth = 40;
    }

    return {
      x: Math.round(offsetX + (radiusX * Math.cos(angleToRad(midAngle))) + additionX()),
      y: Math.round(offsetY  + (radiusY * Math.sin(angleToRad(midAngle))) + additionY()),
      black,
      percent: item.percent
    };
  });

  const legend = [];
  each(data.answers, (answer, answerIndex) => {
    const words = answer.text.split(' ');
    words.unshift(`${Math.round(answer.percent)}%`);

    let index = 0, partLen = 0;
    each(words, (el, i) => {
      if (partLen + el.length > 20 || i === words.length - 1) {
        const untilIndex = i === words.length - 1 ? i + 1 : i;

        if (!index) {
          legend.push({
            texts: words.slice(index, untilIndex),
            color: colors[answerIndex % colors.length]
          });
        } else {
          legend.push({
            texts: words.slice(index, untilIndex)
          });
        }
        partLen = 0;
        index = i;
      }
      partLen = partLen + el.length;
    });
  });

  return (
    <div className={ classnames("doughnut", { [className]: className }) }>
      {!hideChart && (
        <svg width={ width } height={ height } viewBox={ viewBox } className="svg">
          <g>
            {
              map(paths, (path, key) => (
                <path key={ key } d={ path } fill={ colors[key % colors.length] } />
              ))
            }
            {
              percentsInChart &&
              map(filter(percentTexts, (item) => !!item.percent), (item, index) => (
                <text
                  key={ `texts${index}` }
                  x={ item.x } y={ item.y }
                  className={ classnames("inside-percent", { "-black": item.black }) }
                >
                  { item.percent }%
                </text>
              ))
            }
          </g>
        </svg>
      )}
      {
        !hideLegend &&
        <div className={ `doughnut_legend ${hideChart ? '-separate' : ''}` }>
          {
            map(legend, (word, key) => (
              <div className="doughnut_legend-item" key={ key }>
                {
                  word.color &&
                  <div className="doughnut_legend-icon" style={ { color: word.color } } />
                }
                <div className="doughnut_legend-text">
                  { word.color && !hideChart && <b>{ word.texts[0] } </b> }
                  { word.color && word.texts.slice(1).join(' ') }
                  { !word.color && word.texts.join(' ') }
                  { hideChart && <span>, %</span> }
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
};

export default Doughnut;
