import React, { useState } from "react";

const AddNewItem = ({ onAdd, placeholder, inputRef }) => {
  const [ text ] = useState('');

  const handleChange = (event) => {
    onAdd(event.target.value);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <li className="form-question_answer">
      <div className="form-question_grid">
        <div className="form-question_grid-item -first-answer-item" />

        <div className="form-question_grid-item -grow -js-new">
          <input
            className="form-field -block"
            type="text"
            value={ text }
            onChange={ handleChange }
            placeholder={ placeholder }
            onKeyDown={ onKeyDown }
            ref={ inputRef }
          />
        </div>
        <div className="form-question_grid-item -buttons" />
      </div>
    </li>
  );
};

export default AddNewItem;
