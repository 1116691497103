import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";
import { map, capitalize } from "lodash";

const values = (colors, stats, total) => {
  return map(
    colors,
    (v, key) => (
      {
        key,
        color: v,
        value: Math.round(100 * (stats[key] || 0) / total)
      }
    )
  );
};

const DonutChart = ({
  stats, customWidth, customHeight, customRingPart,
  positiveColor, neutralColor, negativeColor, emptyColor
}) => {
  const COLORS = {
    positive: positiveColor || '#00812e',
    neutral: neutralColor || '#ffbc3c',
    negative: negativeColor || '#ed1c24'
  };

  const ref = useRef(null);

  const width = customWidth || 300;
  const height = customHeight || 300;
  const ringPart = customRingPart || 0.333;
  const radius = Math.min(width, height) / 2;
  const keyFont = radius *  0.1;
  const percFont = radius * 0.2;
  const sameColor = false;
  const makeCaps = false;

  const draw = () => {
    const total = stats.negative + stats.neutral + stats.positive;

    const data = total > 0 ?
      values(COLORS, stats, total) :
      [ { key: 'n/a', color: emptyColor || '#919aa9', value: 1 } ];

    const svg = d3.select(ref.current);
    svg.select('svg').remove();

    const arc = d3.arc()
      .outerRadius(radius)
      .innerRadius(radius * (1 - ringPart));

    const pie = d3.pie()
      .value((d) => d.value)
      .sort(null);

    const chart = svg.append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`)
      .selectAll(".arc")
      .data(pie(data))
      .enter();

    chart.append("g")
      .attr("class", "arc")
      .append("path")
      .attr("d", arc)
      .style("fill", (d) => d.data.color);
    if (total > 0) {
      const text = chart.append("text")
        .attr("transform", (d, i) => `translate(0, ${2.1 * (i - 1.6) * percFont})`)
        .attr("text-anchor", "middle");

      text.append('tspan')
        .attr("x", 0)
        .attr("dy", `${percFont}px`)
        .style("fill", (d) => d.data.color)
        .style("font-size", () => `${keyFont}px`)
        .text((d) => makeCaps ? capitalize(d.data.key) : d.data.key);

      text.append('tspan')
        .attr("x", 0)
        .attr("dy", `${percFont}px`)
        .style("fill", (d) => sameColor ?  d.data.color : '#606772')
        .style("font-size", () => `${percFont}px`)
        .text((d) => d.data.value ? `${d.data.value}%` : '0%');
    }
  };

  useEffect(() => {
    if (ref.current) {
      draw();
    }
  }, [ stats, ref.current ]);

  return (<div ref={ ref } />);
};

export default DonutChart;
