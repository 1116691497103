import React from "react";

const SendingMoney = () => {
  return (
    <div>
      <h2>Sending Money to a Friend or Family Member</h2>
      <h3>Sending money</h3>
      <p>
        You can send money to a friend or family member as a personal transaction using the Send Money feature in your PayPal account. You can send money to a friend or family member even if they don’t have an eligible PayPal account at the time you send them money, using their email address or mobile number in any currency that PayPal supports, and you can choose which <span className="pseudo-link">payment method</span> you want to use. If the person to whom you are sending money does not have an eligible PayPal account, they can claim it by opening an eligible PayPal account. If they don’t claim it, it will be refunded to you.
      </p>
      <p>We may, at our discretion, impose limits on the amount of money you can send, including money you send for purchases.
      </p>
      <p>When you send money to a friend or family member who has an eligible PayPal account, one of two things may happen: they may accept or decline the money. If they decline to accept the money, the money (including any fees you were charged) will be <span className="pseudo-link">refunded</span> to
      </p>
      <ul>
        <li>The original payment method you used for the transaction, if you used a credit card, debit card, PayPal Credit, a Balance Account, or balance in a business account as the payment method, or</li>
        <li>The balance in your business account or the balance in your Balance Account linked to your personal account if you used a bank account as the payment method and we cannot refund it to your bank account.</li>
        <li>If you do not have a Balance Account linked to your personal account, the money will be waiting for you to claim by using one of the available transfer methods under <span className="pseudo-link">Receiving Funds, Holding a Balance or Transferring Funds</span> for personal accounts.</li>
      </ul>

      {/*////*/}

      <h2>
        Fees for Sending Money to Friends and Family
      </h2>
      <p>
        The fees applicable to sending personal transactions to an eligible PayPal account can be found on our <span className="pseudo-link"><strong>Consumer Fees page</strong></span> and will be disclosed to you in advance each time you initiate sending a personal transaction to a friend or family member. If you convert money in your Balance Account balance or in your business account balance from one currency to another before sending money, PayPal’s transaction exchange rate (including our <span className="pseudo-link">currency conversion spread</span>) will be used. If you use your credit card as the payment method when sending money, you may also be charged a cash-advance fee by your card issuer.
      </p>
      <p>If you send money to a friend’s or family member’s eligible PayPal account from a third party (non-PayPal) website or by using a third party’s product or service, then the third party will determine if the sender or recipient pays the fee. This will be disclosed to you by the third party before the payment is initiated.
      </p>
      <p>
        You can also use the Send Money feature in your PayPal account to pay another PayPal account for goods or services. You will not be charged any transaction fee for sending money to purchase goods or services as long as you send the money as a commercial transaction (often referred to as using the “Send Money for Goods and Services” feature in your PayPal account). In that case, the seller will pay a fee. You must not send money as a personal transaction (often referred to as using the “Send Money to a Friend or Family Member” feature in your PayPal account) when you are paying for goods or services. Note that the “Send Money to a Friend or Family Member” feature may not be available when sending money to a business account as such accounts may not be eligible to receive personal transactions.
      </p>

      {/*/////*/}

      <h2>
        Buying Something From, Donating to, or Returning Something to, a Seller Who Accepts PayPal
      </h2>

      <h3>How to purchase something or make a donation</h3>

      <p>
        You can purchase something from a seller who accepts PayPal or make a donation, in any currency that the recipient accepts and that PayPal supports using any <span className="pseudo-link">payment method</span> linked to your PayPal account. This includes, for example:
      </p>
      <ul>
        <li>Purchasing something at an online retailer’s website and selecting PayPal as your payment method at checkout.</li>
        <li>Sending money to a seller for goods or services.</li>
        <li>Using your PayPal account to purchase something at a seller’s physical store using an electronic terminal, subject to certain limitations.</li>
        <li>Using your PayPal account to purchase something at a seller’s physical store using your own mobile device and a QR code, subject to certain limitations.</li>
        <li>Making donations using PayPal.</li>
      </ul>
      <p>
        If the seller you are purchasing from sells goods or services and that seller does not already have a PayPal account, they can claim your payment by opening a PayPal account. If they don’t open a PayPal account within 30 days, your purchase will be refunded.
      </p>
      <p>
        In order to manage risk, PayPal may limit the payment methods available for a transaction when you purchase something or make a donation. In addition, payment methods may be limited for certain sellers or recipients, including PayPal payments made through certain third-party websites or applications.
      </p>
      <p>
        When you authorize a payment to a seller who accepts PayPal, some sellers may take up to 30 days to complete the transaction. In these instances, your payment may appear as a pending order in your PayPal account. In that case, your authorization of the payment will remain valid until the seller completes the transaction (but no longer than 30 days). If you used a debit or credit card as the payment method, your debit or credit card issuer also may show a pending authorization for a period of time until they release the hold or receive a completed transaction. If your payment requires a currency conversion by us, PayPal’s transaction exchange rate (including a <span className="pseudo-link">currency conversion spread</span>) will be used, and may be the rate at the time the payment is processed.
      </p>

      <h3>Fees</h3>
      <p>
        When you purchase something from a seller who accepts PayPal or make a donation, you don’t pay a fee to PayPal. If PayPal performs a currency conversion for your purchase or donation, PayPal’s transaction exchange rate (including our <span className="pseudo-link">currency conversion spread</span>) will be used. Your debit or credit card issuer may also charge you a separate fee for non-U.S. transactions.
      </p>
      <p>
        In addition you may be required to pay a fee to PayPal if you are making a purchase using PayPal <span className="pseudo-link">Business Payments</span> and the seller discloses to you that you, as the buyer, must pay the fee.
      </p>

      <h3>Payment review</h3>
      <p>
        When PayPal identifies a potentially high-risk transaction, we review the transaction more closely before allowing it to proceed. When this happens, PayPal will place a hold on the transaction and notify the seller to delay shipping of the item. As a buyer, this may delay your receipt of the item you purchased. If we clear the transaction, we will notify the seller and direct them to ship the item. If we don’t clear the transaction, we will cancel it and return the funds to you, unless we are legally required to take other action.
      </p>

      <h3>Automatic payments</h3>
      <p>
        Some sellers who accept PayPal allow you to store PayPal as the payment method for future purchases with that seller. When you store PayPal as a payment method with a specific seller, you agree with the seller that they can use PayPal to request payment for future transactions between you and the seller. The agreement between you and the seller may allow you to authorize and schedule a payment to the seller in advance on a one-time, sporadic, or recurring basis. Examples of automatic payments that can be arranged by you with a seller include those that PayPal calls a “billing agreement,” “subscription,” “recurring payment,” “reference transaction,” “preauthorized transfer”; or “preapproved payment.”
      </p>
      <p>
        If you have a personal account, you may cancel a recurring automatic payment 3 <span className="pseudo-link"><strong>Business Days</strong></span> or more before the date of the next scheduled payment from your account settings or by contacting us through the <span className="pseudo-link"><strong>PayPal Help Center</strong></span>, or by calling PayPal at (888) 221-1161. If you order us to stop one of these payments 3 <span className="pseudo-link"><strong>Business Days</strong></span> or more before the transfer is scheduled, and we do not do so, we will be liable for your losses or damages.
      </p>
      <p>
        Once your automatic payment authorization is canceled, all future automatic payments under your agreement with that seller will be stopped. If you cancel an automatic payment authorization, you may still owe the seller money for purchases that have been authorized but not completed, or you may still have additional obligations to the seller for any goods or services that you receive but have not paid for.
      </p>
      <p>
        If you have given advance payment authorization to a seller that permits a seller to take or receive payments from your PayPal account on a regularly recurring basis (for example, every month or otherwise on a routine billing cycle), and if such payments will vary in amount, you have the right to advance notice of the amount and date of the transfer from the seller at least 10 days before the transfer is made. If the seller provides the option, you may choose to receive this advance notice only when the amount of your automatic payment will fall outside a range established between you and the seller.
      </p>
      <p>
        If you have authorized an automatic payment and PayPal performs currency conversion for an automatic payment transaction, PayPal will use the transaction exchange rate (including PayPal’s <span className="pseudo-link"><strong>currency conversion spread</strong></span>) in effect at the time the automatic payment transaction is processed.
      </p>

      <h3>Refunds</h3>
      <p>
        When you purchase something from a seller online using PayPal and the transaction is ultimately refunded, the money will typically be refunded to the original payment method you used for the transaction if you used a debit card, credit card, PayPal Credit, or a balance in your Balance Account or business account.
      </p>
      <p>
        If you used a bank account linked to a personal account as the payment method for the transaction, we may give you the option to receive the money to your Balance Account; otherwise, we will attempt to refund the money to your bank account. If we are unable to do so, we will attempt to refund the money to your linked Balance Account if you have one. If you do not have a Balance Account, the money will be waiting for you to claim by using one of the available transfer methods under <span className="pseudo-link">Receiving Funds, Holding a Balance or Transferring Funds</span> for personal accounts.
      </p>
      <p>
        If you used a bank account linked to a business account as the payment method for the transaction, we will attempt to refund the money to your bank account. If we are unable to do so, we will refund the money to your balance in your business account.
      </p>
      <p>
        For purchases you make in a seller’s store location that you paid for using your personal account and the transaction is ultimately refunded, the money will be refunded to your Balance Account if you have one. If you do not have a Balance Account, the money will be waiting for you to claim by using one of the available transfer methods under <span className="pseudo-link">Receiving Funds, Holding a Balance or Transferring Funds</span> for personal accounts.
      </p>
      <p>
        For purchases you make in a seller’s store location that you paid for using your business account and the transaction is ultimately refunded, the money will be refunded to your balance in your business account.
      </p>
      <p>
        If you used Pay with Rewards to fund your transaction, your refund will be processed back as a dollar amount: depending on your card agreement with your issuer, your issuer may or may not credit your purchase back to your reward balance.
      </p>
      <p>
        If PayPal performed currency conversion for your transaction and a refund is issued:
      </p>
      <ul>
        <li>Within 1 day of the date of the original payment, PayPal’s transaction exchange rate (including a <span className="pseudo-link">currency conversion spread</span>) used at the time of the original payment will apply.</li>
        <li>Beyond 1 day of the date of the original payment, PayPal’s transaction exchange rate (including a <span className="pseudo-link">currency conversion spread</span>) on the date of the refund will apply.</li>
      </ul>

      <p>
        Money will be refunded in the currency you paid; or if we are unable to refund in the currency you paid, in your primary holding currency; or we are unable to refund in your primary holding currency, then in U.S. dollars.
      </p>

      {/*/////*/}

      <h2>Payment Method Used for My Transaction</h2>
      <h3>Selecting a preferred payment method</h3>
      <p>
        You can choose any of the payment methods in your PayPal account as your preferred payment method. You can select a preferred payment method in your account preferences on <span className="pseudo-link"><strong>www.paypal.com</strong></span> or in the PayPal app. There may be times when your preferred payment method cannot be used, for example, if you select a credit card that is expired.
      </p>
      <p>You can set separate preferred payment methods for online transactions, in-store transactions, and each of your automatic payment agreements, as described below.
      </p>
      <p>
        If you have chosen a preferred payment method, it will be shown as the primary method of payment.
      </p>
      <p>
        If you have not chosen a preferred payment method, then you must choose the payment method to be used for the specific transaction.
      </p>
      <p>
        The availability of certain payment methods may be limited based on that particular seller or the third-party website you are using to complete the transaction.
      </p>
      <p>
        If you have not selected a preferred payment method, or your preferred payment method is unavailable, we will show you available payment methods, including the payment method you used most often or most recently, at the time of transaction.
      </p>

      <h3>Backup payment method</h3>
      <p>
        Certain one-time online transactions may require that a backup payment method be used in the event that your selected or preferred payment method is unavailable. In those instances, the backup payment method may be displayed to you on your transaction review page before you complete the transaction. If PayPal determines currency conversion is necessary for a transaction that also requires a backup payment method, you may not be able to separately choose whether PayPal or your card issuer performs the currency conversion on your backup payment method.
      </p>

      <h3>Sending money using the Send Money feature</h3>
      <p>
        When you send money using the Send Money feature in your PayPal account to eligible PayPal accounts within the U.S. using balance in your Balance Account linked to your personal account, the balance in your business account, or your bank account, we waive all fees charged to you (as per our <span className="pseudo-link">Fee pages</span>), so we always show you these payment options first, even if you have a set preferred payment method for your online purchases. You always have the choice to select any payment method in your PayPal account during the Send Money experience. If you select a payment method with a fee, we will always show you the fee before you send money.
      </p>

      <h3>Automatic payments</h3>
      <p>
        Some sellers allow you to store PayPal as the way you pay when making purchases on their site, so you can check out faster. Often, this entails creating an agreement with the seller that permits them to request that we charge your PayPal account each time you make a purchase.
      </p>
      <p>
        When you first set up an automatic payment agreement or store PayPal as a payment method with a seller, you may have the option to set up a preferred payment method for such agreement. You can review, set, or change a preferred payment method for an automatic payment agreement in your account settings on paypal.com or in the PayPal app.
      </p>
      <p>
        Your preferred payment method for an automatic payment agreement will be used for transactions with that seller. If you have available balance in a linked Balance Account, that balance may be used before your preferred payment method, depending on your applicable automatic payment agreement with the seller.
      </p>
      <p>
        If you have not set a preferred payment method for an automatic payment agreement, or if your preferred payment method for an automatic payment agreement is unavailable (for example, due to fraud risk or because it is expired) you authorize PayPal to charge the backup payment method in the following order, if applicable: 1. Balance Account, PayPal Debit Card (which allows you to access your Balance Account) or balance in your business account (if we’ve been able to verify the required identifying information you provide to us); 2. Bank account (when PayPal pays the recipient before we receive the funds from your bank); 3. PayPal co-branded credit card; 4. Debit card; 5. Credit card; and 6. E-check (when PayPal waits to receive the funds from your bank before we pay the recipient). However, if a bank account is used as the payment method for a transaction and funding fails (for example, as a result of insufficient funds), PayPal may attempt another payment method as a backup notwithstanding the order specified above (typically a credit card, if applicable). To help minimize disruptions in your automatic payments, we may update your payment method information associated with such automatic payments; see <span className="pseudo-link">Link or Unlink a Payment Method</span>.
      </p>

      <h3>Bank account transfers</h3>
      <p>
        Each time you use your bank account as a payment method, you authorize PayPal to initiate a transfer from your bank account to the recipient. For these transactions, PayPal will make electronic transfers from your bank account, as set forth under <span className="pseudo-link">Authorization to Charge Your Payment Method</span>. You authorize PayPal to re-present this transfer from your bank account up to two (2) times if the initial transfer is rejected by your bank for any reason. Adding money to your PayPal balance (including your PayPal Balance account balance) while a re-presentment is pending does not stop the original transfer from being re-presented by us to your bank.
      </p>
      <p>
        If you use your PayPal account to pay for an item and you select your bank account as the payment method for the transaction, remember that your PayPal account is issued to you by PayPal and not your bank. The terms, benefits, and protections associated with your PayPal account may vary from those that apply to your bank account. If you use your PayPal account to make a purchase that exceeds the balance in your linked bank account, you could incur overdraft fees, even if you chose not to allow overdrafts with your bank. Similarly, the liability limits applicable to your PayPal account may differ from those applicable to your bank account.
      </p>

      <h3>E-check</h3>
      <p>
        E-check is a payment method where you use your bank account as your payment method, and the payment is not received by the recipient until the transaction is processed from the bank. This process usually takes 4-7 <span className="pseudo-link">Business Days</span>, but this amount of time will increase if the payment is sent from a bank account located outside the United States.
      </p>

      <h3>Pay with Rewards</h3>
      <p>
        PayPal may allow you to redeem rewards associated with your eligible card(s) when making a purchase with your PayPal account through PayPal’s Pay with Rewards program. PayPal may share with and receive information from your issuer about your issuer accounts to complete the redemption of your rewards when using PayPal’s Pay with Rewards program. The exchange of this information is necessary to complete the purchase when you select the Pay with Rewards redemption option.
      </p>
      <p>
        All Pay with Rewards redemptions are subject to the terms of your card agreement with your card issuer. Pay with Rewards is only available for eligible purchases with certain merchants, and PayPal or your issuer may limit, suspend, or terminate your ability to redeem rewards at any time in accordance with this user agreement and your card agreement. Your issuer determines the value of rewards redeemed through PayPal and available redemption methods (e.g., to pay for your purchase or for a statement credit).
      </p>
      <p>
        There may be a delay between checkout and processing of your payment. Only rewards available upon processing of your payment will be used. Because your reward balance and/or transaction balance may change, whenever you request to redeem rewards for a purchase you also authorize PayPal to charge up to the full payment amount to your associated card if that happens. For more information on redeeming rewards and how it works, please see <span className="pseudo-link">About Payment Methods</span>.
      </p>

      <h3>Debit card processing</h3>
      <p>
        PayPal will process your debit or prepaid card funded transactions through either the ATM debit network or the Visa, MasterCard, or Discover network except for PayPal Debit Card transactions you make in PayPal checkout, which will be processed directly by PayPal by debiting your Balance Account. If we process your debit or prepaid card through an ATM debit network, we may provide you with the opportunity to switch to a Visa, MasterCard, or Discover network by changing your preferences for that debit or prepaid card through your PayPal account profile.
      </p>

      {/*//////*/}

      <h2>
        Loyalty Cards
      </h2>
      <p>
        If you use the PayPal application on your mobile phone, you may store loyalty card numbers or bar codes, where applicable, for certain sellers in your PayPal account. When you store your loyalty card numbers or bar codes in your PayPal account, you will earn any rewards, subject to the seller’s loyalty card terms and conditions, at the time you make your payment to the merchant by presenting the stored loyalty card number or bar code to the seller at the point of sale. It is your responsibility to make sure you have entered your loyalty card information correctly and that you update it as necessary. It is also your responsibility to ensure that you are receiving the rewards to which you are entitled. PayPal is not responsible for managing your loyalty card account by offering this service and any questions about your loyalty card or associated rewards program should be directed to the seller who offers the card.
      </p>

      {/*/////*/}

      <h2>
        PayPal Rewards Program </h2>
      <p>
        The PayPal Rewards Program allows eligible PayPal users to earn rewards points for completing qualifying transactions or activities. See the <span className="pseudo-link">PayPal Rewards Program Agreement</span> for more information. Such agreement governs participation in the PayPal Rewards Program and is incorporated herein by reference.
      </p>

      {/*/////*/}

      <h2>
        PayPal’s Purchase Protection Program
      </h2>
      <p>
        When you purchase something from a seller who accepts PayPal, you may be eligible for a refund under PayPal’s Purchase Protection program. When applicable, PayPal’s Purchase Protection program may result in coverage for the full purchase price of the item plus the original shipping costs you paid, if any. PayPal determines, in its sole discretion, whether your claim is eligible for PayPal&#x27;s Purchase Protection program.
      </p>
      <p>
        The program terms and conditions are set out in <span className="pseudo-link">PayPal’s Purchase Protection program</span> page and form part of this user agreement.
      </p>

    </div>
  );
};

export default SendingMoney;