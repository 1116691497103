import React, { useContext, useState } from 'react';
import { LineDataContext } from '../contexts';

const PlanFeature = ({ title, description }) => {
  const [ open, setOpen ] = useState(false);
  const className = [
    "savings-feature_state",
    open ? "-open" : ""
  ].filter(Boolean).join(" ").trim();
  /* eslint-disable react/no-danger */
  return (
    <div className="savings-feature">
      <div className="savings-feature_header">
        <div className="savings-feature_icon">
          <svg>
            <use xlinkHref="#svg_checkmark-alt" />
          </svg>
        </div>
        <span
          onClick={ () => setOpen(!open) }
          className="savings-feature_title"
          dangerouslySetInnerHTML={ { __html: title } }
        />
        <div
          className={ className }
          onClick={ () => setOpen(!open) }
        >
          <svg>
            <use xlinkHref="#svg_Caret-Black" />
          </svg>
        </div>
      </div>
      {open &&
        <span
          className="savings-feature_description"
          dangerouslySetInnerHTML={ { __html: description } }
        />
      }
    </div>
  );
};

const renderFeature = ({ id, title, description }) => {
  return (
    <div key={ id } className="savings-all-features_cell">
      <PlanFeature title={ title } description={ description } />
    </div>
  );
};

const renderSubList = (subList, idx) => {
  return (
    <div key={ idx } className="savings-all-features_row">
      {subList.map(renderFeature)}
    </div>
  );
};

export const AllPlansInclude = () => {
  const { allPlansInclude } = useContext(LineDataContext);
  const plans = [ ...allPlansInclude ];
  const list = [];
  while (plans.length !== 0) {
    const slice = plans.splice(0, 3).filter(Boolean);
    list.push(slice);
  }
  return (<>
    <h4 className="savings-all-features_title">All plans include</h4>
    <div className="savings-all-features">{list.map(renderSubList)}</div>
  </>);
};
