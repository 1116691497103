import { includes } from 'lodash';

const nextButtonMessageKind = 'Express Admin Next Button Clicked Message';
const customSubmitMessageKind = 'Express Admin Custom Message to Submit Form';

const inOneOfAllowedMessages = (data, onlyCustom) => {
  const list = onlyCustom ?
    [ customSubmitMessageKind ] :
    [ nextButtonMessageKind, customSubmitMessageKind ];
  return includes(list, data?.kind);
};

const sendMessage = (data) => {
  window.postMessage(data, "*");
};

const sendMessageWithKind = (kind) => {
  sendMessage({ kind });
};

const sendNextButtonMessage = () => {
  sendMessageWithKind(nextButtonMessageKind);
};

const sendCustomSubmitButtonMessage = () => {
  sendMessageWithKind(customSubmitMessageKind);
};

const waitForNextButtonMessage = (callback, onlyCustom) => {
  (window.parent || window).addEventListener("message", (event) => {
    if (inOneOfAllowedMessages(event.data, onlyCustom)) {
      callback();
    }
  });
};

export {
  inOneOfAllowedMessages,
  sendNextButtonMessage,
  sendCustomSubmitButtonMessage,
  waitForNextButtonMessage
};
