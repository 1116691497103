import React, { useContext, useState } from 'react';
import { map } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import ImageInPopup from "../../../../../common/components/image_in_popup";
import useDraggableInPortal from './use-draggable-in-portal';

const CustomizeQuestionsModal = () => {
  const {
    loadData,
    customizedQuestionData, reportView, setReportView,
    setCustomizedQuestionData, init
  } = useContext(IndexData);

  const { sortingOrderDefault } = init;

  const renderDraggable = useDraggableInPortal();
  const hintTextStart = "Create title allows you to customize the how you refer to this question in the dashboard and reporting";
  const hintTextEnd = "For example, you could edit the question \"How well does this product fit with your brand\" to \"Brand fit\"";

  const initData = () => {
    const baseData = {};
    baseData[customizedQuestionData.id] = reportView?.customMetrics?.[customizedQuestionData.id] || {};
    return baseData;
  };

  const [ questionId ] = useState(customizedQuestionData.id);
  const [ answers, setAnswers ] = useState(customizedQuestionData.answers);
  const [ answersOrderChanged, setAnswersOrderChanged ] = useState(false);
  const [ columns, setColumns ] = useState(customizedQuestionData.columns);
  const [ columnsOrderChanged, setColumnsOrderChanged ] = useState(false);
  const [ customizeData ] = useState(initData());
  const [ customTitle, setCustomTitle ] = useState(customizeData[customizedQuestionData.id]?.custom_title);
  const [ formLoading ] = useState(false);

  const onChangeCustomTitle = (event) => {
    setCustomTitle(event.target.value);
  };

  const onSaveData = () => {
    const newData = { ...reportView };
    newData.customMetrics = reportView?.customMetrics || {};
    newData.customMetrics[questionId] = reportView?.customMetrics?.[questionId] || {};
    if (customTitle) {
      newData.customMetrics[questionId].custom_title = customTitle;
    }
    if (answers && answersOrderChanged) {
      const answersArray = map(answers, (answer) => (answer.index));
      correctBrandAsPoint(answersArray);
      newData.customMetrics[questionId].custom_answers_order = map(answersArray, (item) => (parseInt(item)));
    }
    if (columns && columnsOrderChanged) {
      newData.customMetrics[questionId].custom_columns_order = map(columns, (column) => (column.index));
    }
    setReportView({ ...reportView, ...newData });
    loadData(null, null, null, null, { reportView: newData });
    setCustomizedQuestionData(null);
  };

  const correctBrandAsPoint = (answersArray) => {
    if (customizedQuestionData.brand && customizedQuestionData.asPointScale) {
      answersArray.unshift(0);
      answersArray.push(...[ answersArray.length, answersArray.length + 1 ]);
    }
    answersArray.push(...(sortingOrderDefault || []));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(answers);

    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAnswers(items);
    setAnswersOrderChanged(true);
  };

  const handleOnColumnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(columns);

    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColumns(items);
    setColumnsOrderChanged(true);
  };

  return (
    <Modal modalSize="sm" modalContentClass="-overflow-visible">
      { formLoading && <div className="page-loader -inner-loader" /> }
      <div className="modal-header -auto-height">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ () => { setCustomizedQuestionData(null); } }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title">{ customizedQuestionData.title }</h3>
      </div>
      <div className="modal-description -flex">
        <h3 className="modal-title-sm -left -non-grow">Custom Question Title</h3>
        <div className="info-tooltip_control -help-on -help-top">
          <span>
            <span>
              <SvgIcon name="i-info" />
            </span>
            <span className="-help -margin-top">
              <span className="inner">
                { hintTextStart }
                <br />
                <br />
                { hintTextEnd }
              </span>
            </span>
          </span>
        </div>
      </div>

      <div className="modal-input">
        <input
          className="form-field -block"
          type="text"
          value={ customTitle || '' }
          onChange={ (event) => {
            onChangeCustomTitle(event);
          } }
          placeholder="Enter custom title"
        />
      </div>

      {
        !!columns.length &&
        <>
          <div className="modal-description -flex">
            <h3 className="modal-title-sm -left -non-grow">Columns</h3>
          </div>
          <div>
            <DragDropContext onDragEnd={ handleOnColumnDragEnd }>
              <Droppable droppableId="columns-area">
                {
                  (provided) => (
                    <ul
                      className="form-question_answers -columns"
                      { ...provided.droppableProps }
                      { ...provided.dragHandleProps }
                      ref={ provided.innerRef }
                    >
                      {
                        map(columns, (column, index) => (
                          <Draggable
                            key={ index }
                            draggableId={ `column-${index}` }
                            index={ index }
                          >
                            {renderDraggable((provided) => (
                              <div
                                className="form-draggable_item"
                                ref={ provided.innerRef }
                                { ...provided.draggableProps }
                                { ...provided.dragHandleProps }
                              >
                                <div className="form-question_grid -custom">
                                  <div
                                    className="form-question_grid-item -first-answer-item"
                                  >
                                    <div
                                      className="form-question_answer-move"
                                      { ...provided.dragHandleProps }
                                    >
                                      <SvgIcon name="i-move" />
                                    </div>
                                  </div>
                                  <div
                                    className="form-question_grid-item -grow"
                                  >
                                    <span className="form-question_answer-item -draggable">{ column.text }</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </ul>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
        </>
      }
      {
        !!answers.length &&
        <>
          <div className="modal-description -flex">
            <h3 className="modal-title-sm -left -non-grow">{ customizedQuestionData.grid ? 'Rows' : 'Answers' }</h3>
          </div>
          <div>
            <DragDropContext onDragEnd={ handleOnDragEnd }>
              <Droppable droppableId="answers-area">
                {
                  (provided) => (
                    <ul
                      className="form-question_answers -answers"
                      { ...provided.droppableProps }
                      { ...provided.dragHandleProps }
                      ref={ provided.innerRef }
                    >
                      {
                        map(answers, (answer, index) => (
                          <Draggable
                            key={ index }
                            draggableId={ `answer-${index}` }
                            index={ index }
                          >
                            {renderDraggable((provided) => (
                              <div
                                className="form-draggable_item"
                                ref={ provided.innerRef }
                                { ...provided.draggableProps }
                                { ...provided.dragHandleProps }
                              >
                                <div className="form-question_grid -custom">
                                  <div
                                    className="form-question_grid-item -first-answer-item"
                                  >
                                    <div
                                      className="form-question_answer-move"
                                      { ...provided.dragHandleProps }
                                    >
                                      <SvgIcon name="i-move" />
                                    </div>
                                  </div>
                                  <div>
                                    {
                                      answer.text &&
                                      <div
                                        className="form-question_grid-item"
                                      >
                                        <span className="form-question_answer-item -draggable">{ answer.text }</span>
                                      </div>
                                    }
                                    {
                                      answer.image &&
                                      <div className="file-uploader">
                                        <div className="form-flavor_image -with-margins">
                                          <ImageInPopup
                                            className="-pointer"
                                            customZIndex={ 1062 }
                                            src={ answer.image }
                                          >
                                            <img className="-pointer" src={ answer.image } alt={ "text" } />
                                          </ImageInPopup>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </ul>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
        </>
      }

      <div className="modal-footer">
        <button type="button" className="button modal_button" onClick={ onSaveData }>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default CustomizeQuestionsModal;
