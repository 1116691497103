import React from 'react';
import { getLinesCount } from './statistics';

const lineNameList = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth"
};

export const Subheader = ({ currentLine }) => {
  const linesCount = getLinesCount();
  const lineName = (
    <>
      {
        linesCount > 1 ?
          <span className="red-highlight">
            {  lineNameList[currentLine] } line
          </span>
          :
          <>
            { 'line' }
          </>
      }
    </>
  );
  return (
    <div className="subheader">
      <div className="inner-content -base">
        <h2 className="subheader-primary">
          Let’s choose a plan for&nbsp;your
          { ' ' }
          { lineName }.
        </h2>
        <h6 className="subheader-secondary">
          Only pay for what you need and get more of the entertainment you want.
          Choose your Unlimited plans to mix, match and save.
        </h6>
        <span className="subheader-additional">
          Plan prices reflect your $10/mo paper-free billing and Auto Pay discount.
          {/*<span className="subheader_info">*/}
          {/*  <svg><use xlinkHref="#svg_info" /></svg>*/}
          {/*</span>*/}
        </span>
      </div>
    </div>
  );
};
