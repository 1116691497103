import React, { useContext, useState, useRef, useEffect } from 'react';
import cn from "classnames";
import { find } from "lodash";
import SvgIcon from "../../../../../common/components/svg-icon";
import DonutChart from "../donut-chart";
import ExpressWordCloud from "../express-word-cloud";
import { customList, defineOrientation } from "../../orientation-service";
import { LangData } from "../../lang-data";
import useWindowSize from "../../../../../common/use-window-size";
import { IndexData } from '../../index-context';
import {
  comparisonScorecardWordcloudGearClick,
  comparisonScorecardWordcloudWordClick
} from "../../../../common/google-analytics/events";
import ModalKeywordConfig from "./modal-keyword-config/index";
import { ExchangeData } from "./exchange-data";
import { WordsData } from "./index";

const LeftPanel = ({ data, metric, filter, sentiment, tourName, hideConfigure = false }) => {
  const { loadWords } = useContext(WordsData);
  const { useTranslation } = useContext(LangData);
  const {
    init, getTourUseEffectArgs, wordCloudColors, wordCloudFont
  } = useContext(IndexData);

  const [ showConfigure, setShowConfigure ] = useState(false);
  const closeConfigure = () => {
    setShowConfigure(false);
    loadWords(false);
  };
  const openConfigure = () => {
    comparisonScorecardWordcloudGearClick(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: data.concept_id }
    );
    setShowConfigure(true);
  };

  const positiveColor = wordCloudColors.positive;
  const negativeColor = wordCloudColors.negative;
  const neutralColor = wordCloudColors.neutral;
  const defaultColor = wordCloudColors.default;

  const size = (orientation) => {
    return find(customList, (el) => (el.name === orientation));
  };

  const { setMessage } = useContext(ExchangeData);
  const addKeyword = (word) => {
    comparisonScorecardWordcloudWordClick(init.google_analytics_id, { project_id: init.project.id, item_id: data.concept_id });
    setMessage(word.text);
  };

  const selectedOrientation = defineOrientation();
  const orientationClass = {
    '-vertical': selectedOrientation === 'vertical',
    '-horizontal': selectedOrientation === 'horizontal'
  };

  useWindowSize();

  const tourChartElement = useRef(null);
  const [ chartCallback, chartCondition ] = getTourUseEffectArgs(
    `${tourName}Chart`, tourChartElement
  );
  useEffect(chartCallback, chartCondition);

  const tourGearElement = useRef(null);
  const [ gearCallback, gearCondition ] = getTourUseEffectArgs(
    `${tourName}Gear`, tourGearElement
  );
  useEffect(gearCallback, gearCondition);

  return (
    <div className="sentiments">
      <div className="sentiments_header">
        <div className="tile-header -no-border">
          <div className="tile-header_main">
            { sentiment && <h4>Sentiments</h4> }
          </div>
          {!hideConfigure &&
            <div className="tile-header_addon" ref={ tourGearElement }>
              <span
                className="icon-link -gray -no-text -config"
                onClick={ openConfigure }
                role="button"
                title="Configure"
              >
                <SvgIcon className="icon-link_icon" name="i-gear" />
                <span className="icon-link_text">Configure</span>
              </span>
            </div>}
          {
            showConfigure &&
            <ModalKeywordConfig
              metric={ metric }
              filters={ filter }
              closeConfigure={ closeConfigure }
            />
          }
        </div>
      </div>
      <div className="sentiments_content text-center">
        <div>
          {
            sentiment &&
            <div>
              <div
                className="survey-sentiments-chart neutral_question"
                ref={ tourChartElement }
              >
                <DonutChart
                  stats={ data.sentiment_stats }
                  positiveColor={ positiveColor }
                  neutralColor={ neutralColor }
                  negativeColor={ negativeColor }
                  emptyColor={ defaultColor }
                />
              </div>
            </div>
          }

          <div className="sentiments_content-sidebar">
            <div className={ cn("survey-cloud neutral_question", selectedOrientation) }>
              <div className={ cn("survey-cloud_existing", orientationClass) }>
                <ExpressWordCloud
                  onWordClick={ addKeyword }
                  words={ data.neutral_question.keywords }
                  noFill={ !sentiment }
                  fontFamily={ wordCloudFont }
                  positiveColor={ positiveColor }
                  neutralColor={ neutralColor }
                  negativeColor={ negativeColor }
                  emptyColor={ defaultColor }
                  size={ size(selectedOrientation) }
                  useTranslation={ useTranslation }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
