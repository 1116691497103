import { useReducer, useState } from 'react';

const MIN_RECT_SIDE_SIZE = 15;
const INITIAL_SIDE_SIZE = 150;

const noop = (event) => {
  if (event) {
    event.preventDefault();
  }
};

const pointActiveReducer = (_, action) => {
  switch (action) {
    case 'active':
      return true;
    case 'inactive':
    default:
      return false;
  }
};

const getClientCoordinate = (event) => {
  const { type } = event;
  const isTouch = type.includes('touch');
  const { clientX = NaN, clientY = NaN } = isTouch ? event?.changedTouches[0] : event;
  return { clientX, clientY };
};

const useActive = () => {
  const [ active, dispatchActive ] = useReducer(pointActiveReducer, false);
  const setActive = () => dispatchActive('active');
  const setInactive = () => dispatchActive('inactive');
  return {
    active,
    setActive,
    setInactive
  };
};

const notMoreThan = (max) => (num) => num >= max ? max : num;
const notLessThan = (min) => (num) => num >= min ? num : min;

const check = (rules, num) => rules.reduce((result, rule) => rule(result), num);

const usePosition = (initialPosition) => {
  const [ position, setPosition ] = useState(initialPosition);
  const getSize = (customPosition) => {
    const currentPosition = customPosition || position;
    return {
      width: (currentPosition.x2 - currentPosition.x1),
      height: (currentPosition.y2 - currentPosition.y1)
    };
  };

  const updatePosition = (partial, fixTopLeftOnInvalid = true) => {
    const tempPosition = {
      ...position,
      ...partial
    };
    const tempSize = getSize(tempPosition);
    const newPosition = { ...position };

    if (tempSize.width >= MIN_RECT_SIDE_SIZE) {
      newPosition.x1 = tempPosition.x1;
      newPosition.x2 = tempPosition.x2;
    } else {
      if (!fixTopLeftOnInvalid) {
        newPosition.x1 = tempPosition.x1;
      }
      newPosition.x2 = newPosition.x1 + MIN_RECT_SIDE_SIZE;
    }
    if (tempSize.height >= MIN_RECT_SIDE_SIZE) {
      newPosition.y1 = tempPosition.y1;
      newPosition.y2 = tempPosition.y2;
    } else {
      if (!fixTopLeftOnInvalid) {
        newPosition.y1 = tempPosition.y1;
      }
      newPosition.y2 = newPosition.y1 + MIN_RECT_SIDE_SIZE;
    }
    setPosition(newPosition);
  };

  return { position, setPosition, getSize, updatePosition };
};

export {
  getClientCoordinate, useActive, noop,
  notMoreThan, notLessThan, check,
  INITIAL_SIDE_SIZE, usePosition
};
