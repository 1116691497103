import { each, find, remove, map } from "lodash";
import 'element-closest-polyfill';

const getElement = (query) => {
  const list = document.querySelectorAll(query);
  if (!list.length) {
    return null;
  } else if (list.length === 1) {
    return list[0];
  }
  return find(list, (el) => {
    const box = el.getBoundingClientRect();
    return (box.width && box.height);
  });
};

const closestElement = (element, query) => element.closest(query);

const addElementClass = (element, klass) => {
  const classList = element.className.split(/\s+/);
  if (!find(classList, (kel) => kel === klass)) {
    classList.push(klass);
  }
  element.className = classList.join(" ");
};

const addClass = (query, klass) => {
  each(
    document.querySelectorAll(query),
    (item) => {
      const classList = item.className.split(/\s+/);
      if (!find(classList, (kel) => kel === klass)) {
        classList.push(klass);
      }
      item.className = classList.join(" ");
    }
  );
};

const removeClass = (query, klass) => {
  each(
    document.querySelectorAll(query),
    (item) => {
      const classList = item.className.split(/\s+/);
      remove(classList, (kel) => kel === klass);
      item.className = classList.join(" ");
    }
  );
};

const setCheckedValue = (query, value, callback) => {
  each(
    document.querySelectorAll(query),
    (item) => {
      item.checked = value;
      if (callback) {
        callback(item);
      }
    }
  );
};

const setStyleAttributes = (element, hash) => {
  element.style = map(hash, (v, k) => (
    `${k.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${v}`
  )).join('; ');
};

export {
  closestElement, getElement,
  addElementClass, addClass, removeClass,
  setCheckedValue, setStyleAttributes
};
