const urls = {
  // export: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/export',
  filter: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/filter',
  keywords: '/dashboard/api/open_ends/database_concepts/:concept_id/keywords',
  link: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/link',
  unlink: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/unlink',
  play: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/play?linked_id=:answer_id&linked_type=:answer_type',
  hide: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/hide',
  unhide: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/unhide',
  keywordsList: '/dashboard/api/keywords'
};

export { urls };
