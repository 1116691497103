import React, { useState } from 'react';
import classnames from "classnames";
import { generateId } from "../../helpers";

const CheckboxField = ({ id, error, label, checked, onChange, className, classNameLabel, disabled }) => {
  const [ identifier ] = useState(id || `survey_${generateId()}`);
  return(
    <div className="form-group -checkbox">
      {
        !!label &&
        <label className={`form-group_label -checkbox ${classNameLabel ? classNameLabel : '-upper'}`} htmlFor={ identifier }>{ label }</label>
      }
      <div className="verizon-checkbox table_control-over-line">
        <input
          name="selected"
          type="checkbox"
          id={ identifier }
          checked={ checked }
          onChange={ (event) => onChange(event.target.checked) }
          disabled={ disabled }
        />
        <label htmlFor={ identifier } className={ classnames("checkbox", className) }></label>
      </div>
      { error && <p className="field-error">{ error }</p> }
    </div>
  );
};

export default CheckboxField;