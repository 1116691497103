import React from 'react';

const Field = ({ title, type, value, onChange, hint, error, disabled }) => {
  return (
    <div className="form_group">
      <label className="form-label">{ title }</label>
      <input
        className="form-field -block"
        type={ type }
        value={ value }
        onChange={ (event) => { onChange(event.target.value); } }
        disabled={ disabled }
      />
      {
        hint &&
        <div className="form_hint">{ hint }</div>
      }
      {
        error &&
        <div className="form-error">{error}</div>
      }
    </div>
  );
};

export default Field;
