import React, { useEffect, useRef, useState, useContext } from 'react';
import classnames from 'classnames';
import { useActive, getClientCoordinate, noop } from '../helper';
import { OptionsContext } from './contexts';

const Point = ({
  higherIndex, kind, className,
  left, top, onPositionChange, onEndPositionChange,
  onMouseEnter, onMouseLeave
}) => {
  const ref = useRef(null);
  const { active, setActive, setInactive } = useActive();
  const [ initDiffs, setInitDiffs ] = useState({});
  const { rectHoverClassName, pointClassName } = useContext(OptionsContext);

  const updatePosition = (event) => {
    if (!active) {
      return;
    }
    onPositionChange(getClientCoordinate(event), initDiffs);
  };

  const activate = (event) => {
    if (active || !ref || !ref.current) {
      return;
    }
    const buttonInfo = ref.current.getBoundingClientRect();
    const currentCoords = getClientCoordinate(event);
    let diffX, diffY;
    if (kind === "tl" || kind === "bl") {
      diffX = buttonInfo.left - currentCoords.clientX;
    } else if (kind === "tr" || kind === "br") {
      diffX = buttonInfo.left + buttonInfo.width - currentCoords.clientX;
    }
    if (kind === "tl" || kind === "tr") {
      diffY = buttonInfo.top - currentCoords.clientY;
    } else if (kind === "bl" || kind === "br") {
      diffY = buttonInfo.top + buttonInfo.height - currentCoords.clientY;
    }
    setInitDiffs({ diffX, diffY });
    setActive();
  };

  const deactivate = (event) => {
    if (!active) {
      return;
    }
    setInitDiffs({});
    setInactive();
    onEndPositionChange(getClientCoordinate(event));
  };

  useEffect(() => {
    const currentEl = ref.current;
    currentEl.addEventListener('mousedown', activate);
    currentEl.addEventListener('touchstart', activate);
    document.body.addEventListener('mouseup', deactivate);
    document.body.addEventListener('touchend', deactivate);
    document.body.addEventListener('mousemove', updatePosition);
    document.body.addEventListener('touchmove', updatePosition);
    return () => {
      currentEl.removeEventListener('mousedown', activate);
      currentEl.removeEventListener('touchstart', activate);
      document.body.removeEventListener('mouseup', deactivate);
      document.body.removeEventListener('touchend', deactivate);
      document.body.removeEventListener('mousemove', updatePosition);
      document.body.removeEventListener('touchmove', updatePosition);
    };
  }, [ updatePosition, activate, deactivate ]);

  let style = { left, top, zIndex: (higherIndex ? 4 : 2) };
  if (rectHoverClassName && !higherIndex) {
    style = { ...style, ...{ display: 'none' } };
  }
  return (
    <button
      type="button"
      ref={ ref }
      style={ style }
      className={
        classnames(
          "products-greed-item-image-rect-point",
          pointClassName,
          { [rectHoverClassName]: rectHoverClassName && higherIndex },
          className,
          { '-active': active }
        )
      }
      onContextMenu={ noop }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    />
  );
};

export default Point;
