import React, { useContext } from "react";
import { map } from "lodash";
import cn from "classnames";
import { selectKind, IndexData, selectGroup } from '../../index-context';
import { biggerThan, showedAverageValue, findAvgModeValue } from "../../helpers";
import OverviewStatusBar from "./overview-status-bar";

const TrOverviewShelf = ({ data, checkedConcepts }) => {
  const shelfFound = selectKind(data, 'express_shelf_found');
  const easeToFind = selectGroup(data, 'five_point_scale', true);
  const { sort, averageMode, init } = useContext(IndexData);

  return (
    <>
      {
        map(shelfFound, (values, title) => {
          const avgValue = findAvgModeValue(values);
          return (
            <tr key={ title }>
              <td className="table-comparison_name-cell table-concept-overview_td-title">
                <span className={ cn({ 'sort-link -current': title === sort }) }>
                  {data.titles.found}
                </span>
              </td>
              <td className="table-concept-overview_avg-col">
                {
                  !!avgValue && ((init.isInternal && data.view !== "client") || averageMode !== 'platform') &&
                  <div className="table-concept-overview_avg-inner">
                    <span
                      className="table-concept-overview_avg-value"
                      style={ { 'bottom': avgValue } }
                    >
                      <span
                        className={
                          cn(
                            'table-concept-overview_avg-value-text',
                            { '-big': biggerThan(avgValue, 75) }
                          )
                        }
                      >
                        { showedAverageValue(values, averageMode, true, init.isInternal, data.view) }
                      </span>
                    </span>
                  </div>
                }
              </td>
              {
                map(checkedConcepts, (concept, i) => (
                  <td
                    className={
                      cn(
                        "table-concept-overview_td-chart",
                        {
                          "-first": !i,
                          "-last": i === checkedConcepts.length - 1
                        }
                      )
                    }
                    key={ i }
                  >
                    <OverviewStatusBar
                      values={ values[concept.id] }
                      box="top2"
                    />
                  </td>
                ))
              }
            </tr>
          );
        })
      }
      {
        map(easeToFind, (values, title) => {
          const avgValue = findAvgModeValue(values);
          return (
            <tr key={ title }>
              <td className="table-comparison_name-cell table-concept-overview_td-title">
                <span className={ cn({ 'sort-link -current': title === sort }) }>
                  Top 2 Box { title }
                </span>
              </td>
              <td className="table-concept-overview_avg-col">
                {
                  !!avgValue &&
                  <div className="table-concept-overview_avg-inner">
                    <span
                      className="table-concept-overview_avg-value"
                      style={ { 'bottom': avgValue } }
                    >
                      <span
                        className={
                          cn(
                            'table-concept-overview_avg-value-text',
                            { '-big': biggerThan(avgValue, 75) }
                          )
                        }
                      >
                        { showedAverageValue(values, averageMode, true, init.isInternal, data.view) }
                      </span>
                    </span>
                  </div>
                }
              </td>
              {
                map(checkedConcepts, (concept, i) => (
                  <td
                    className={
                      cn(
                        "table-concept-overview_td-chart",
                        {
                          "-first": !i,
                          "-last": i === checkedConcepts.length - 1
                        }
                      )
                    }
                    key={ i }
                  >
                    <OverviewStatusBar
                      values={ values[concept.id] }
                      box="top2"
                    />
                  </td>
                ))
              }
            </tr>
          );
        })
      }
    </>
  );
};

export default TrOverviewShelf;
