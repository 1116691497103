import { each, uniq, sumBy, filter, keys, map, min, max } from 'lodash';

const minAge = 13;

const ageReg = /^(\d+)-(\d+)$/, quotaReg = /^(\d+)$/;

const errorMessages = {
  rangeText: `Invalid range: should match the example: 18-35, so that right border should always be greater or equal to the left border.`,
  firstLeftBorder: `Invalid range: Left border of the first range could not be less than ${minAge}.`,
  rangeOverlap: "Invalid range: ranges should not overlap.",

  quotaNumber: "Invalid quota: only digits are allowed, value should not be set to 0% and total can not be greater than 100%.",
  quotaNumberMulti: "Invalid quota: only digits are allowed, value should not be set to 0% and be greater than 100%.",
  quotaSum: "Invalid quota: sum of all selected options should equal 100%."
};

const ageInvalidRange = (item) => {
  const res = (`${item.answer || ''}`).match(ageReg);
  item.highlightRange = !res || (res[1] * 1 > res[2] * 1);
  return item.highlightRange;
};

const ageInvalidLeftBorder = (firstItem) => {
  const res = (`${firstItem.answer || ''}`).match(ageReg);
  firstItem.highlightRange = !res || (res[1] * 1 < minAge);
  return firstItem.highlightRange;
};

const ageInvalidOverlap = (leftItem, rightItem) => {
  if (!rightItem) {
    return false;
  }
  const res1 = (`${leftItem.answer || ''}`).match(ageReg),
    res2 = (`${rightItem.answer || ''}`).match(ageReg);

  rightItem.highlightRange = (res1[2] * 1 >= res2[1] * 1);
  return rightItem.highlightRange;
};

const invalidAgesList = (q, list) => {
  const messages = [];

  each(list, (item) => {
    if (ageInvalidRange(item)) {
      messages.push(errorMessages.rangeText);
    }
  });
  if (messages.length) {
    return uniq(messages);
  }

  if (ageInvalidLeftBorder(list[0])) {
    messages.push(errorMessages.firstLeftBorder);
  }
  if (messages.length) {
    return uniq(messages);
  }

  each(list, (item, index) => {
    if (ageInvalidOverlap(item, list[index + 1])) {
      messages.push(errorMessages.rangeOverlap);
    }
  });

  return messages.length ? uniq(messages) : null;
};

const quotaInvalidNumber = (item, multiPunch = false) => {
  if (!item.selected) {
    item.highlightQuota = false;
    return item.highlightQuota;
  }

  if (!item.quota && multiPunch) {
    item.highlightQuota = false;
    return item.highlightQuota;
  }

  const res = (`${item.quota || ''}`).match(quotaReg);
  item.highlightQuota = !res || (res[1] * 1 === 0) || (res[1] * 1 > 100);
  return item.highlightQuota;
};

const quotaGridInvalidNumber = (item) => {
  item.highlightQuota = false;
  each(keys(item.selected), (key) => {
    if (item.selected[key]) {
      const res = (`${item.quota[key] || ''}`).match(quotaReg);
      if (!res || (res[1] * 1 === 0) || (res[1] * 1 > 100)) {
        item.highlightQuota = true;
      }
    }
  });

  return item.highlightQuota;
};

const totalQuotaSum = (list) => {
  const result = sumBy(filter(list, (el) => el.selected), (item) => {
    return item.quota * 1;
  });

  return isNaN(result) ? -1 : result;
};
const maxPerEachQuotaSum = (list) => max(map(list, (el) => (el.quota && parseInt(el.quota)) || 1));
const minPerEachQuotaSum = (list) => min(map(list, (el) => (el.quota && parseInt(el.quota)) || 1));

const totalGridQuotaSum = (list) => {
  const asArray = map(keys(list.selected), (key) => ([ key, list.selected[key] !== false ]));
  const result = sumBy(filter(asArray, (el) => el[1] === true), (item) => {
    return list.quota[item[0]] * 1;
  });

  return isNaN(result) ? -1 : result;
};

const invalidQuotas = (q, list) => {
  const messages = [];
  const isMulti = q.kind === "Multi";

  each(list, (item) => {
    if (quotaInvalidNumber(item, isMulti)) {
      const quotaNumberMessage = isMulti ? 'quotaNumberMulti' : 'quotaNumber';
      messages.push(errorMessages[quotaNumberMessage]);
    }
  });
  if (messages.length) {
    return uniq(messages);
  }

  if (
    (!isMulti && (totalQuotaSum(list) < 100 || totalQuotaSum(list) > 100)) ||
    (isMulti && (maxPerEachQuotaSum(list) > 100 || minPerEachQuotaSum(list) === 0))
  ) {
    messages.push(errorMessages.quotaSum);
  }
  return messages.length ? uniq(messages) : null;
};

const invalidGridQuotas = (q, list) => {
  const messages = [];

  each(list, (item) => {
    if (quotaGridInvalidNumber(item)) {
      messages.push(errorMessages.quotaNumber);
    }
  });
  if (messages.length) {
    return uniq(messages);
  }

  each(list, (item) => {
    if ((totalGridQuotaSum(item) < 100) || totalGridQuotaSum(item) > 100) {
      item.highlightQuota = true;
      messages.push(errorMessages.quotaSum);
    }
  });

  return messages.length ? uniq(messages) : null;
};

export { invalidAgesList, totalQuotaSum, invalidQuotas, invalidGridQuotas, totalGridQuotaSum };
