import React, { useState } from 'react';
import Card from './card';
import CardPlanTitle from './cardPlanTitle';
import PlanShortDescription from './planShortDescription';

const AccordionView = ({ isStatic, handleOpenChange, title, children }) => {
  const [open, setOpen] = useState(isStatic);
  const handleChange = () => {
    handleOpenChange();
    setOpen(!open);
  };
  return <div className="accordion">
    {!isStatic && <button onClick={handleChange} className="accordion-button">
      <span className={`accordion-button-text ${open ? '-open' : ''}`}>{title}</span>
    </button>}
    {open && children}
  </div>
};

export const EmptyCard = () => <div className="empty-card">
  <span className="empty-card-text">Not chosen yet</span>
</div>;

export const PlanCard = (props) => {
  const { 
    plan: {
      id, name, price, description
    },
    headerClassName,
    isAcardionStatic,
    accordionTitle,
    handleOpenChange,
    onShowDetails,
    setClickedLine,
    renderChildrenInAccordion,
    children
  } = props;

  const handleClickSeeDetails = () => {
    onShowDetails(id);
    setClickedLine();
  };

  return <Card>
    <CardPlanTitle name={name} price={price} className={headerClassName} />
    <div className="choose-plan-card-body">
      <AccordionView
        isStatic={isAcardionStatic}
        handleOpenChange={handleOpenChange}
        title={accordionTitle}>
        <div className="cards-item-description">
          <PlanShortDescription
            description={description} />
          <div className="details-link-wrapper">
            <button type="button" className="details-link" onClick={handleClickSeeDetails}>See plan details</button>
          </div>
        </div>
        {renderChildrenInAccordion && children}
      </AccordionView>
    </div>
    {!renderChildrenInAccordion && children}
  </Card>
};