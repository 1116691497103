import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import { map, isEmpty } from 'lodash';
import { TransformContext } from '../contexts';

const ProductsOnShelf = ({
  viewOnly,
  shelfPointer,
  shelfRef,
  shelfWrapperRef,
  shelfUrl,
  onProductClick,
  onOutsideClick,
  productPointer
}) => {
  const {
    setRectPositions, getRectPositions,
    getShelfPosition, getWrapperStyles, contentRef,
    getContentWrapperStyles
  } = useContext(TransformContext);
  const products = getRectPositions();
  const onLoad = () => {
    setRectPositions();
  };

  const shelfWidth = getShelfPosition().width;
  const shelfHeight = getShelfPosition().height;
  const shelfStyle = getShelfPosition().style;

  const getProductStyle = (product) => {
    const { top, left, width, height } = product;
    return {
      left,
      top,
      width,
      height
    };
  };

  const contentWrapperStyles = getContentWrapperStyles();
  const imageWrapperStyles = getWrapperStyles();

  useEffect(() => {
    if (imageWrapperStyles.transform) {
      const contentBox = contentRef.current.getBoundingClientRect();
      const imageBox = shelfWrapperRef.current.getBoundingClientRect();
      contentRef.current.scrollLeft = parseInt((imageBox.width - contentBox.width) / 2);
      contentRef.current.scrollTop = parseInt((imageBox.height - contentBox.height) / 2);
    }
  }, [ imageWrapperStyles ]);

  const handleProductClick = (product) => (event) => {
    onProductClick(event, product);
  };

  const loadingClasses = (shelfWidth && shelfWidth > 0) ?  '' : 'page-zero-loader';

  return (
    <div className={ classnames("zoom-area_wrapper", loadingClasses) } style={ contentWrapperStyles }>
      <div
        className={ classnames("zoom-area_clickable-area", { "-pointer": !viewOnly && shelfPointer }) }
        ref={ shelfWrapperRef }
        style={ imageWrapperStyles }
      >
        <img
          ref={ shelfRef }
          src={ shelfUrl }
          onLoad={ onLoad }
          draggable="false"
          className="zoom-area_image"
          alt="Shelf"
          width={ shelfWidth }
          height={ shelfHeight }
          style={ shelfStyle }
        />
        {
          !viewOnly &&
          <>
            {
              onOutsideClick &&
              <div className="zoom-area_outside-area" onClick={ onOutsideClick } />
            }
            {
              map(products, (product, index) => (
                <div
                  key={ `product${product.id}-${index}` }
                  draggable="false"
                  className={
                    classnames(
                      "zoom-area_product-area",
                      { '-pointer': productPointer }
                    )
                  }
                  style={ getProductStyle(product) }
                  onClick={ handleProductClick(product) }
                />
              ))
            }
          </>
        }
      </div>
    </div>
  );
};

export default ProductsOnShelf;
