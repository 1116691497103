import React from 'react';

export const Back = ({ onBack }) => {
  const handleBackClick = () => {
    onBack();
  };

  return (
    <div className="back" >
      <button className="back_button" onClick={ handleBackClick }>
        <svg className="icon -back back_icon">
          <use xlinkHref={ `#svg_back` } />
        </svg>
        <span className="back_text">Back</span>
      </button>
    </div>
  );
};
