import React, { useEffect, useState, useRef } from "react";
import renderRawHtml from '../../../../../../../common/render_raw_html';
import FooterButton from '../../../footer_button';
import useTransform from '../common/use_transform';
import { LocalizationContext, TransformContext } from '../common/contexts';
import ScaleControls from '../common/components/scale_controls';
import ProductsOnShelf from '../common/components/products_on_shelf';
import CantFindProduct from './components/cant_find';
import ChoseWrongPopup from './components/chose_wrong_popup';

const SHOW_HELP_TIMEOUT = 10000;

const ShelfFindTime = ({
  result, concept,
  shelfUrl, shelfWidth, shelfHeight, area, nextStep,
  viewOnlyTask, exerciseTask,
  cantFind, wantToGiveUp, wouldToContinue,
  cantFindProduct, fail, viewOnly
}) => {
  const [ choseWrong, setChoseWrong ] = useState(false);
  const [ startTime, setStartTime ] = useState(0);
  const minScale = 1;
  const [ scale, setScale ] = useState(minScale);
  const [ contentWrapperStyles, setContentWrapperStyles ] = useState({});
  const [ wrapperStyles, setWrapperStyles ] = useState({});
  const [ shelfPosition, setShelfPosition ] = useState({});
  const [ areaPositions, setAreaPositions ] = useState([]);
  const contentRef = useRef(null);
  const shelfWrapperRef = useRef(null);
  const shelfRef = useRef(null);
  const transform = useTransform({
    dataAreas: [ { ...area, ...{ imageWidth: shelfWidth, imageHeight: shelfHeight } } ],
    min: minScale, max: 2, step: 0.1,
    getScale: () => (scale), setScale,
    getShelfPosition: () => (shelfPosition), setShelfPosition,
    getAreaPositions: () => (areaPositions), setAreaPositions,
    getContentWrapperStyles: () => (contentWrapperStyles), setContentWrapperStyles,
    getWrapperStyles: () => (wrapperStyles), setWrapperStyles,
    contentRef, shelfWrapperRef, shelfRef
  });

  const handleNextForView = () => {
    nextStep();
  };

  const nextStepWithResult = (found) => {
    const timeSpent = Math.ceil(((new Date).getTime() - startTime) / 1000);
    if (!result.shelf_results) {
      result.shelf_results = [];
    }
    result.shelf_results.push({
      concept_id: concept.id,
      time_spent: timeSpent,
      found
    });
    nextStep({ found, timeSpent });
  };

  const onComplete = (success) => () => {
    if (viewOnly) {
      return;
    }
    success ? nextStepWithResult(true) : setChoseWrong(true);
  };

  const onBackToShelf = () => {
    setChoseWrong(false);
  };

  const onCantFind = () => {
    nextStepWithResult(false);
  };

  const task = viewOnly ? viewOnlyTask : exerciseTask;

  const localization = {
    task,
    cantFind,
    wantToGiveUp,
    wouldToContinue,
    cantFindProduct,
    fail
  };

  const [ cantFindVisible, setCantFindVisible ] = useState(false);
  useEffect(() => {
    if (viewOnly) {
      return () => {};
    }
    setStartTime((new Date).getTime());
    const tId = setTimeout(() => {
      setCantFindVisible(true);
    }, SHOW_HELP_TIMEOUT);

    return () => {
      clearTimeout(tId);
    };
  }, []);

  return (
    <>
      <LocalizationContext.Provider value={ localization }>
        <TransformContext.Provider value={ transform }>
          {
            choseWrong &&
            <ChoseWrongPopup onClick={ onBackToShelf } />
          }
          <>
            <div className="survey-header disable-zoom" style={ { touchAction: 'manipulation' } }>
              <div
                className="survey-header_title"
                { ...renderRawHtml(localization.task) }
              />
            </div>
            <div className="survey-layout_content -with-overflow" ref={ contentRef }>
              <ProductsOnShelf
                shelfPointer
                viewOnly={ viewOnly }
                shelfRef={ shelfRef }
                shelfWrapperRef={ shelfWrapperRef }
                shelfUrl={ shelfUrl }
                onProductClick={ onComplete(true) }
                onOutsideClick={ onComplete(false) }
              />
            </div>
            <div
              className="survey-footer -zoom-middle disable-zoom"
              style={ { touchAction: 'manipulation' } }
            >
              <ScaleControls withWrapper />
              {
                viewOnly ?
                  <FooterButton
                    className="-short"
                    nextStep={ handleNextForView }
                  />
                  :
                  <>
                    {
                      cantFindVisible &&
                      <CantFindProduct onCantFind={ onCantFind } />
                    }
                  </>
              }
            </div>
          </>
        </TransformContext.Provider>
      </LocalizationContext.Provider>
    </>
  );
};

export default ShelfFindTime;
