import React, { useState } from "react";
import { isEmpty, trim } from "lodash";
import Modal from "../../../../common/components/modal";

const SummaryEditor = ({ initModalText, cancelModal, saveModalText, disabled }) => {
  const [ modalText, setModalText ] = useState(initModalText || '');

  const onChangeModalText = (event) => {
    setModalText(event.target.value);
  };

  return (
    <Modal
      modalClassName="centered"
      dialogClassName="verizon-modifier"
      backDropModifier="verizon-modifier"
    >
      <h3 className="normal-popup-title">
        Edit Summary
      </h3>
      <textarea
        className="form-field concept-tags_notes"
        onChange={ onChangeModalText }
        value={ modalText }
        disabled={ disabled }
      />
      <div className="nearby-popup-buttons">
        <button
          className="button"
          type="button"
          onClick={ () => { saveModalText(modalText); } }
          disabled={ disabled || isEmpty(trim(modalText)) }
        >
          Save
        </button>
        <button
          className="button -secondary"
          type="button"
          onClick={ cancelModal }
          disabled={ disabled }
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default SummaryEditor;
