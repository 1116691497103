import React, { useState } from 'react';
import { filter, find } from "lodash";
import classnames from 'classnames';

const Checkbox = ({ htmlId, initVal, obj, selected, setSelected, onChange }) => {
  const [ ch, setCh ] = useState(initVal);

  const handleChange = (name, checked) => {
    setCh(checked);

    let result;
    if (checked) {
      result = [ ...selected, name ];
    } else {
      const newSelected = filter(selected, (el) => (el !== name));
      result = [ ...newSelected ];
    }
    setSelected(result);
    onChange(result);
  };

  return (
    <input
      type="checkbox"
      id={ htmlId }
      disabled={ obj.disabled }
      checked={ ch }
      onChange={ (e) => { handleChange(obj.name, e.target.checked); } }
    />
  );
};

//objects = [ {id: INT, name: STRING, disabled: BOOL}, {...}, ... ]
//selectedIds = [ INT, ... ]
const CheckboxMulti = ({ objects, selectedNames, onChange }) => {
  const [ selected, setSelected ] = useState(selectedNames);
  const isChecked = (id) => {
    return find(selected, (el) => (el == id)) > -1;
  };

  return (
    <div className="checkbox-multi">
      {objects.map((obj, index) => (
        <div
          key={ index }
          className={ classnames("checkbox-multi_item", { '-disabled': obj.disabled }) }
        >
          <Checkbox
            htmlId={ `id-s1-${index}` }
            initVal={ isChecked(obj.id) }
            obj={ obj }
            selected={ selected }
            setSelected={ setSelected }
            onChange={ onChange }
          />
          <label className="checkbox-multi_label" htmlFor={ `id-s1-${index}` }>{obj.name}</label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxMulti;
