import React, {
  createContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
  useCallback,
  Fragment,
  forwardRef
} from 'react';
import { sortBy, map } from 'lodash';

export const TabContext = createContext({
  addTab: () => {},
  setTab: () => {}
});

const TabStateProvider = ({ tabsRef, setTab, children }) => {
  const addTab = (tab) => {
    const index = tabsRef.current.findIndex(({ name }) => tab.name === name);
    index === -1
      ? tabsRef.current.push(tab)
      : tabsRef.current.splice(index, 1, tab);
  };

  return (<TabContext.Provider value={ { addTab, setTab } }>
    { children }
  </TabContext.Provider>);
};

export const Tabs = forwardRef(({
  initialTabName,
  sortFn = (item) => item.weight,
  hideTabButtons = false,
  children }, ref) => {
  const tabsRef = useRef([]);
  const [ currentContent, setCurrentContent ] = useState({ });
  const updateCurrentContent = useCallback((name) => {
    let selectedTab = tabsRef.current.find((el) => el.name === name);
    if (!selectedTab) {
      selectedTab = tabsRef.current.length > 0
        ? tabsRef.current[0].content
        : { content: null };
    }
    setCurrentContent({ name, content: selectedTab.content });
  }, [ tabsRef ]);

  const onTabClick = ({ name, onClick }) => (e) => {
    if (name === currentContent.name) {
      return;
    }
    if (onClick) {
      onClick(e);
    }
    updateCurrentContent(name);
  };

  useEffect(() => {
    const selectedName = (
      initialTabName ||
      (tabsRef.current.length > 0 ? sortBy(tabsRef.current, sortFn)[0].name : '')
    );
    updateCurrentContent(selectedName);
  }, [ updateCurrentContent, initialTabName ]);

  const tabButtons = map(
    sortBy(tabsRef.current, sortFn),
    ({ title, name, onClick, className, content, weight, ...rest }, key) => (
      <button
        key={ key }
        { ...rest }
        className={ `tab ${className || ''} ${currentContent.name === name ? '-active' : ''}` }
        onClick={ onTabClick({ name, onClick }) }
      >
        { title }
      </button>
    ));
  return (
    <div ref={ ref }>
      {!hideTabButtons && <div className="tabs">
        { tabButtons }
      </div>}
      <TabStateProvider tabsRef={ tabsRef } setTab={ updateCurrentContent }>
        { children }
        { currentContent.content }
      </TabStateProvider>
    </div>
  );
});

export const Tab = ({ children, ...rest }) => {
  const { addTab } = useContext(TabContext);
  useLayoutEffect(() => {
    addTab({ ...rest, content: <Fragment key={ rest.name }>{children}</Fragment> });
  }, [ addTab, children, rest ]);
  return null;
};
