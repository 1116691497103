import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.removeChild(element);
      body.style.overflow = null;
      //hack for safari to correct behavior of modal in iframe
      const topCoord = html.scrollTop;
      html.scrollTo({ top: 0 });
      html.scrollTo({ top: topCoord });
    };
  }, [ body, element, html ]);


  return createPortal(children, element);
};

const CompletedModal = ({ returnSurvey }) => {
  return (
    <Portal>
      <div className="completed-modal">
        <div className="completed-modal_backdrop" />
        <div className="completed-modal_dialog">
          <div className="completed-modal_content">
            <h2>
              The app experience is now complete
            </h2>
          </div>
          <div className="completed-modal_buttons">
            <button className="button -full-width" onClick={ returnSurvey }>
              Please click here to return to the survey
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default CompletedModal;
