import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import { fraudContext } from '../../../contexts';
import { onPasteDetected, prepareParams, sendToFraudCheck } from '../../../logic/fraud_check';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ZoomImage from '../../zoom_image';
import FooterButton from '../../footer_button';

const Reason = ({ question, answers, nextStep, result, mainResult }) => {
  const { respondentId, projectId, antiFraud, setFraudProcessing } = useContext(fraudContext);
  const [ text, updateText ] = useState("");

  const disabledOption = () => {
    const hash = {};
    if (!text) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswer = () => {
    if (result.answers) {
      result.answers.push({ [question.name]: text });
    } else {
      result[question.name] = text;
    }
    updateText('');
    const fraudParams = prepareParams(
      { antiFraud, projectId, respondentId },
      [ text ]
    );
    sendToFraudCheck(fraudParams, nextStep, setFraudProcessing);
  };

  const updateAnswer = (event) => {
    updateText(event.target.value);
  };

  let wrapperClassName = answers.length > 1 ? '-half-width' : '';
  if (answers.length >= 3) {
    wrapperClassName = '-third-width';
  }

  return (
    <>
      <div className="survey-layout_content">
        <div className="survey-layout_container -reason">
          <div className="survey-options">
            <div className="survey-options_image-plate -reason">
              {
                map(answers, (answer) => (
                  <ZoomImage
                    key={ answer.id }
                    withHeadWrapper
                    className="survey-options_image"
                    src={ answer.imageUrl }
                    textAsSrc={ answer.text }
                    wrapperClassName={ wrapperClassName }
                  />
                ))
              }
            </div>
            <div className="note survey-options_question -reason survey-options_question-reason-title -center">
              <div className="survey-options_question-title-inner" { ...renderRawHtml(question.text) } />
            </div>
            <textarea
              className="js-text form-field -reason"
              onChange={ (e) => { updateAnswer(e); } }
              onPaste={ () => { onPasteDetected(mainResult); } }
            />
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswer(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default Reason;
