import React from "react";
import renderRawHtml from '../../../../common/render_raw_html';

const FormControlReadOnly = ({ label, value }) => {
  return (
    <>
      <div className={ `form-control` }>
        {!!label && <label className={ `form-control_label-readonly` }>{ label }</label>}
        <div className="form-control_value-readonly" { ...renderRawHtml(value) } />
      </div>
    </>
  );
};

export default FormControlReadOnly;
