import React from "react";
import cn from "classnames";

const Radio = ({ label, name, value, bool, onChange, className }) => {
  return (
    <div className="radio">
      <label className={ cn("radio_label", className, { '-checked': bool }) }>
        <input
          type="radio"
          name={ name }
          value={ value }
          checked={ bool }
          onChange={ (event) => { onChange(event.target.value); } }
        />
        {label}
      </label>
    </div>
  );
};

export default Radio;
