import React, { useContext } from "react";
import { map } from "lodash";
import classnames from 'classnames';
import { n2, showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { IndexData } from '../../../index-context';

const TrCart = ({ checkedConcepts }) => {
  const { init, data, averageMode, sort } = useContext(IndexData);
  const { analytics, titles } = data;
  const includedTargetInCart = analytics[titles.percent];
  const numberTotalItemsInCart = analytics[titles.total];
  const numberAverageItemsInCart = analytics[titles.in_average];

  const addedPercText = showedAverageValue(
    includedTargetInCart,
    averageMode,
    false,
    init.isInternal,
    data.view
  );

  const avgCartTotalText = showedAverageValue(
    numberTotalItemsInCart,
    averageMode,
    false,
    init.isInternal,
    data.view
  );

  const avgProductTotalText = showedAverageValue(
    numberAverageItemsInCart,
    averageMode,
    false,
    init.isInternal,
    data.view
  );

  return (
    <>
      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': titles.percent === sort }) }>
            { titles.percent }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td key={ `cart_list_${concept.id}` } className="-js-n">
              <StatusCircle values={ includedTargetInCart[concept.id] } box="top2" />
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { addedPercText }
        </td>
      </tr>

      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': titles.total === sort }) }>
            { titles.total }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td
              key={ `cart_list_${concept.id}` }
              className="-js-n"
            >
              { n2(numberTotalItemsInCart[concept.id].value) }
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { avgCartTotalText }
        </td>
      </tr>

      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': titles.in_average === sort }) }>
            { titles.in_average }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td
              key={ `cart_list_${concept.id}` }
              className="-js-n"
            >
              { n2(numberAverageItemsInCart[concept.id].value) }
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { avgProductTotalText }
        </td>
      </tr>
    </>
  );
};

export default TrCart;
