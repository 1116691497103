const expressLandingHash = (baseUrl) => {
  const url = [ baseUrl, "landing" ].join("/");
  return {
    feature1Image12x: `${url}/feature-1-image-1-2x.png`,
    feature1Image22x: `${url}/feature-1-image-2-2x.png`,
    feature2Image12x: `${url}/feature-2-image-1-2x.png`,
    feature3Image12x: `${url}/feature-3-image-1-2x.png`,
    feature3Image22x: `${url}/feature-3-image-2-2x.png`,
    feature3Image32x: `${url}/feature-3-image-3-2x.png`,
    footerLogoAma: `${url}/footer-logo-ama.png`,
    footerLogoArf: `${url}/footer-logo-arf.png`,
    footerLogoEsomar: `${url}/footer-logo-esomar.png`,
    footerLogoInsights: `${url}/footer-logo-insights.png`,
    footerLogoWbenc: `${url}/footer-logo-wbenc.png`,
    heroScreen12x: `${url}/hero-screen-1-2x.png`,
    heroScreen22x: `${url}/hero-screen-2-2x.png`,
    heroTablet2x: `${url}/hero-tablet-2x.png`
  };
};

export default expressLandingHash;
