import React from 'react';
import remoteFiles from '../../../common/remote_files';

const Preview = () => {
  const { preview } = remoteFiles.verizon.tv;
  return (
    <img className="preview-image" src={ preview } width="100%" />
  );
};

export default Preview;

