import React, { Fragment } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';

const PricingModalSpecialBody = ({ pricing, plans }) => {
  let repeatedTimes = 0;
  return (
    <div className="pricing-table">
      <div className="pricing-table_row -legend">
        <div className="pricing-table_cell -title">Line</div>
        {
          map(pricing[0], (pricingCellTitle, index) => (
            <div
              key={ `cell${index}` }
              className={
                classnames(
                  "pricing-table_cell -special",
                  { '-pale-border': index !== 1 && index < 4 },
                  { '-hard-border': index === 1 }
                )
              }
            >
              {index + 1}-line account{index === 4 ? '+' : ''}
            </div>
          ))
        }
      </div>
      {
        map(plans, (plan, planIndex) => (
          <div key={ `line${planIndex}` } className="pricing-table_row -data">
            <div className="pricing-table_cell -title">{plan.name}</div>
            {
              map(pricing[plan.id - 1], (pricingCell, innerIndex) => {
                const repeated = innerIndex !== pricingCell.length - 1 && pricingCell === pricing[plan.id - 1][innerIndex + 1];
                let localRepeated = 0;
                if (repeated) {
                  repeatedTimes += 1;
                } else {
                  localRepeated = repeatedTimes + 1;
                  repeatedTimes = 0;
                }
                return (
                  <Fragment key={ `cell${planIndex}${innerIndex}` }>
                    {
                      !repeated &&
                      <div
                        className={
                          classnames(
                            "pricing-table_cell -special -repeated",
                            `-repeated-${localRepeated}`
                          )
                        }
                      >
                        {
                          !repeated &&
                          <>
                            ${pricingCell}/mo
                            <br />
                            each
                          </>
                        }
                      </div>
                    }
                  </Fragment>
                );
              })
            }
          </div>
        ))
      }
    </div>
  );
};

export default PricingModalSpecialBody;


