import React from 'react';

const QuestionLocation = ({ question, screenerQuestions, setScreenerQuestions }) => {
  const onChangeSurveyLocation = (newValue) => {
    question.survey_location = newValue;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  return (
    <>
      <h3 className="-mr">Location:</h3>
      <div className="form_section-text">
        <select
          className="form-field -block -js-survey-location"
          value={ question.survey_location }
          onChange={ (event) => {onChangeSurveyLocation(event.target.value);} }
        >
          <option value="at_the_start">At start</option>
          <option value="default">In middle</option>
          <option value="at_the_end">At end</option>
        </select>
      </div>
    </>
  );
};

export default QuestionLocation;
