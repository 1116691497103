import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { formatCurrency } from '../helpers/currencyHelper';
import Modal from './modal';
import InlineEdit from './inlineEdit';

const SeparateEdit = ({
  prefix = '$', value, setValue, children, onEdit, editTitle,
  minValue, minValueText, maxValue, maxValueText
}) => {
  const [ editing, setEditing ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ tempValue, setTempValue ] = useState(null);

  const handleUpdateTempValue = (value, error = null) => {
    setTempValue(value);
    setError(error);
  };

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onEdit && onEdit();
    setEditing({ show: false });
    handleUpdateTempValue(value);
  };

  useEffect(() => {
    if (editing && !editing?.show) {
      setEditing({ show: true });
    }
  }, [ editing ]);

  const formattedValue = formatCurrency(value, prefix);

  const handleEditSave = () => {
    setValue(tempValue);
    setEditing(null);
  };

  const handleSkip = () => {
    setEditing(null);
  };

  const footerNextDisabled = (!!error);
  const footerEdit = (
    <>
      <button
        className="button -full-width"
        onClick={ handleEditSave }
        disabled={ footerNextDisabled }
      >
        Save
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  return (
    <>
      {
        editing ? (
          <Modal
            removeHeader
            className={ classnames("edit-money-form", { 'show': editing?.show }) }
            footer={ footerEdit }
          >
            <div className="ppe-autoreload">
              <div className="content_title">
                <h2>{ editTitle }</h2>
              </div>
              <div className="ppe-autoreload_form">
                <div className="ppe-autoreload_form-plates">
                  <div className="one-time-value">
                    <InlineEdit
                      className="separate-edit-form"
                      value={ tempValue }
                      setValue={ handleUpdateTempValue }
                      minValue={ minValue }
                      minValueText={ minValueText }
                      maxValue={ maxValue }
                      maxValueText={ maxValueText }
                    >
                      <svg className="icon -one-time-edit">
                        <use xlinkHref="#svg_one-time-edit" />
                      </svg>
                    </InlineEdit>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          <button className={ classnames("button-link -md") } onClick={ handleOnClick }>
            {formattedValue}{children}
          </button>
        )
      }
    </>
  );
};

export default SeparateEdit;
