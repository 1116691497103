import React, { useContext, Fragment } from "react";
import { map } from "lodash";
import cn from "classnames";
import SvgIcon from "../../../../../common/components/svg-icon";
import { LangData } from "../../lang-data";
import { MarkupData } from "./markups-logic";
import { colors } from "./const";

const GroupedSelectedComments = ({ selRef }) => {
  const {
    groupedSelectedComments, isRespondentHidden, respondentClasses, toggleHighlight, toggleMarkupVisible,
    toggleRespondent
  } = useContext(MarkupData);
  const { commentByLanguage } = useContext(LangData);

  const onToggleHighlight = (respondent) => {
    toggleHighlight(respondent);
  };

  const onToggleMarkupVisible = (event, respondent) => {
    event.stopPropagation();
    toggleMarkupVisible(respondent.id);
  };

  const onToggleRespondent = (respondentId) => {
    toggleRespondent(respondentId);
  };

  return (
    <Fragment>
      {map(groupedSelectedComments, (r, k) => (
        <div className="gsc" key={ k }>
          <div className="summary-markup_comment -header">
            <div className="summary-markup_comment_author">
              Respondent {k}
            </div>
            <span
              className="summary-markup_comment_action icon-link -no-text"
              hint-in-popup="{{ mtMarkups.hintMessage(k) }}"
              onClick={ () => {onToggleRespondent(k);} }
              position="auto left"
              role="button"
            >
              { !isRespondentHidden(k) && <SvgIcon className="icon-link_icon" name="eye-open" /> }
              { isRespondentHidden(k) && <SvgIcon className="icon-link_icon" name="eye-closed" /> }
            </span>
          </div>

          {map(r, (respondent) => {
            const classes = respondentClasses(respondent);
            let newRef;
            if (classes['-selected']) {
              newRef = selRef;
            }

            return (
              <div
                key={ respondent.id }
                ref={ newRef }
                className={ cn("summary-markup_comment -pointer", classes) }
                id={ `resp-{{ respondent.id }}` }
                onClick={ () => {
                  onToggleHighlight(respondent);
                } }
                style={ { color: (respondent.hidden ? '' : colors[respondent.reaction]) } }
              >
                <div className="summary-markup_comment_comment">
                  {commentByLanguage(respondent) || '---'}
                </div>

                {respondent.markup_visible && <SvgIcon
                  className="icon-link_icon"
                  name="eye-open"
                  onClick={ (e) => {onToggleMarkupVisible(e, respondent);} }
                                              />}

                {!respondent.markup_visible && <SvgIcon
                  className="icon-link_icon"
                  name="eye-closed"
                  onClick={ (e) => {onToggleMarkupVisible(e, respondent);} }
                                               />}
              </div>
            );
          })}
        </div>
      ))}
    </Fragment>
  );
};

export default GroupedSelectedComments;
