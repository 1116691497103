import { each } from "lodash";

const classNameError = (errors, field) => (errors && !!errors[field] ? 'has-error' : '');

const errorsText = (errors, field) => (errors && !!errors[field] ? errors[field].join(', ') : '');

const convertDottedErrors = (dotErrors) => {
  const result = {};
  each(dotErrors, (v, k) => {
    const parts = k.split('.');
    let temp = result;
    each(parts, (part, index) => {
      if (index === parts.length - 1) {
        temp[part] = v;
      } else {
        if (temp[part] === undefined) {
          temp[part] = {};
        }
        temp = temp[part];
      }
    });
  });
  return result;
};

export { classNameError, errorsText, convertDottedErrors };
