import React, { useContext } from 'react';
import { filter } from 'lodash';
import Select from "react-select";
import { IndexData } from "../../index-context";
import { comparisonCompareAddClient } from "../../../../common/google-analytics/events";

const ClientsDropdown = ({ onUpdate, averageMode, selectedOptions, setSelectedOptions, clientList }) => {
  const { init } = useContext(IndexData);
  const exists = (data) => filter(data, (item) => {
    return item.value === 0;
  });

  const cleared = (options) => {
    return !exists(options).length;
  };

  const onAddClient = (action) => {
    if (action === "select-option") {
      comparisonCompareAddClient(init.google_analytics_id, { project_id: init.project.id });
    }
  };

  const handleSelect = (data, selected) => {
    if (!data.length) {
      setSelectedOptions([ { value: 0, label: "All Clients" } ]);
      onUpdate([ { value: 0, label: "All Clients" } ]);
    } else if (selected.option?.value === 0 || !!exists(data).length) {
      onAddClient(selected.action);
      setSelectedOptions([ selected.option ]);
      onUpdate([ selected.option ]);
    } else {
      onAddClient(selected.action);
      setSelectedOptions(data);
      onUpdate(data);
    }
  };

  const selectStyles = {
    control: (styles) => ({ ...styles, borderRadius: 20, fontSize: "13px" }),
    option: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93" }),
    menu: (provided) => ({ ...provided, fontSize: "13px", zIndex: 9999, color: "#787F93" }),
    multiValue: (styles, { data }) => (
      {
        ...styles, fontSize: "13px", borderRadius: 70, color: "#787F93",
        background: data.value === 0 ? "#FFF" : "#EFF4F5",
        paddingRight: data.value === 0 ? "5px" : 0
      }
    ),
    multiValueLabel: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93" }),
    valueContainer: (styles) => ({ ...styles, fontSize: "13px", paddingLeft: "5px" }),
    multiValueRemove: (styles, state) => {
      return state.data.value === 0 ? { ...styles, display: 'none' } : styles;
    }
    // indicatorSeparator: (styles) => ({ ...styles, display: 'none' })
  };

  return (
    <div className="react-select-wrapper -clients-dropdown">
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={ clientList() }
        placeholder="Client not applicable"
        value={ averageMode === "client" ? selectedOptions : [] }
        onChange={ handleSelect }
        isSearchable
        isMulti
        isClearable={ cleared(selectedOptions) }
        isDisabled={ averageMode !== "client" }
        styles={ selectStyles }
      />
    </div>
  );
};

export default ClientsDropdown;
