import React, { useContext, useState, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { findIndex } from 'lodash';
import FormControl from "../components/formControl";
import { GlobalContext } from '../contexts/appContexts';
import {
  validatePassword,
  generatePassword,
  validatePasswordLength,
  validatePasswordContents
} from '../helpers/passwordHelper';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import SavePasswordNotification from './savePasswordNotification';

const Password = ({ headerStep, numberOfHeaderSteps }) => {
  const {
    nextStep, prevStep, mergeData,
    innerStep, setInnerStep, baseInnerSteps, setBaseInnerSteps
  } = useContext(GlobalContext);
  const [ initialPassword, setInitialPassword ] = useState('');

  useLayoutEffect(() => {
    setBaseInnerSteps([ 'password', 'save_password_to_device' ]);
    setInnerStep('password');
    return () => {
      setBaseInnerSteps(null);
      setInnerStep(null);
    };
  }, []);

  const handleNext = (skipNumber = 1) => {
    const currentIndex = findIndex(baseInnerSteps, (el) => (el === innerStep));
    if (currentIndex < baseInnerSteps.length - skipNumber) {
      setInnerStep(baseInnerSteps[currentIndex + skipNumber]);
    } else {
      nextStep();
    }
  };

  const handleCreatePasswordNext = () => {
    handleNext();
    mergeData({ password: initialPassword });
  };

  const handleOnClick = () => {
    setInitialPassword(generatePassword());
  };

  const footerButtons = (
    <button
      disabled={ !validatePassword(initialPassword) }
      className="button -full-width"
      onClick={ handleCreatePasswordNext }
    >
      Next
    </button>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  const handleBack = () => {
    mergeData({ passwordBackClick: true });
    prevStep();
  };

  const header = (
    <Header
      onBack={ handleBack }
      currentStep={ headerStep }
      numberOfSteps={ numberOfHeaderSteps }
    />
  );

  return (
    <>
      {
        innerStep === 'password' &&
        <Layout header={ header } footer={ footer }>
          <div className="password">
            <div className="title password_title">
              <h1 className="title_h1">Create a password</h1>
            </div>
            <div className="password_form form">
              <FormControl
                disableChange
                label="Password"
                type="password"
                initialText={ initialPassword }
                onClick={ handleOnClick }
              />
              <div className="form_hint">
                <ul className="form_hint-list">
                  <li
                    className={
                      classnames(
                        "form_hint-list-item",
                        { "-checked": validatePasswordLength(initialPassword) }
                      )
                    }
                  >
                    {
                      validatePasswordLength(initialPassword) &&
                      <svg className="icon form_hint-checked-icon">
                        <use xlinkHref="#svg_checked-circle" />
                      </svg>
                    }
                    Use 8 to 20 characters
                  </li>
                  <li
                    className={
                      classnames(
                        "form_hint-list-item",
                        { "-checked": validatePasswordContents(initialPassword) }
                      )
                    }
                  >
                    {
                      validatePasswordContents(initialPassword) &&
                      <svg className="icon form_hint-checked-icon">
                        <use xlinkHref="#svg_checked-circle" />
                      </svg>
                    }
                    Use 2 of the following: letters, numbers, or symbols (like !@#$%^)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Layout>
      }
      {
        innerStep === 'save_password_to_device' &&
        <SavePasswordNotification nextStep={ () => { handleNext(); } } />
      }
    </>
  );
};

export default Password;


