import React, { useState, Fragment, useEffect } from 'react';
import { find, filter, map, some, includes } from 'lodash';
import { getAvailableRangesFromString } from '../validation';
import {
  atLeastOptionRegEx,
  ageBreaksOptionRegEx,
  selectAgeBreaksOption,
  selectAtLeastOption
} from '../helpers/filters_helper';
import Results from './filter_results/results';

const FiltersDropdown = ({ selected, options, onUpdate, resultsId, forceDropdownOpen }) => {
  let pointerInDropdown = false;
  const placeholder = "Filter data by any screener question";
  const optionsList = options || [];
  const [ showDropdown, updateShowDropdown ] = useState(false);
  const [ searchString, updateSearchString ] = useState(placeholder);
  const initSelectedList = () => (selected ? [ ...selected ] : []);
  const [ selectedList, updateSelectedList ] = useState(initSelectedList);
  useEffect(() => {
    updateSelectedList(initSelectedList());
  }, [ selected ]);

  const isSelected = (optionItem, answer) => {
    if (answer.precode === 'at_least') {
      return !!find(
        selectedList,
        (item) => item.match(atLeastOptionRegEx(optionItem.name))
      );
    } else if (answer.precode === 'age_breaks') {
      return !!find(
        selectedList,
        (item) => item.match(ageBreaksOptionRegEx(optionItem.name))
      );
    }

    if (optionItem.title === 'State' && anyRegionSelected(optionItem)) {
      return true;
    }

    if (optionItem.title === 'Region' && stateFilterExists()) {
      if (anyStateSelected(optionItem)) {
        return true;
      }
    }

    return !!find(selectedList, (item) => item === `${optionItem.name}=${answer.precode}`);
  };

  const stateFilterExists = () => (
    some(optionsList, (el) => el.title === 'State')
  );

  const answersPrecodesByTitle = (title) => (
    map(
      find(optionsList, (el) => el.title === title).answers,
      (item) => item['precode']
    )
  );

  const anyStateSelected = (optionItem) => (
    some(
      answersPrecodesByTitle('State'),
      (el) => includes(selectedList, `${optionItem.name}=${el}`)
    )
  );

  const anyRegionSelected = (optionItem) => (
    some(
      answersPrecodesByTitle('Region'),
      (el) => includes(selectedList, `${optionItem.name}=${el}`)
    )
  );

  const filteredOptionsAnswers = (option) => {
    if (!searchString || searchString === placeholder) {
      return option.answers;
    }
    return filter(
      option.answers,
      (item) => item.value.match(new RegExp(searchString, 'i'))
    );
  };

  const filteredOptions = (
    (!searchString || searchString === placeholder) ?
      optionsList :
      filter(optionsList, (item) => filteredOptionsAnswers(item).length)
  );

  const dropdownIsOpened = (
    (showDropdown && optionsList.length) ||
    forceDropdownOpen
  );

  const onInputChange = (event) => {
    updateSearchString(event.target.value);
  };
  const onInputFocus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    updateSearchString('');
    updateShowDropdown(true);
  };
  const onInputBlur = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!pointerInDropdown) {
      updateSearchString(placeholder);
      updateShowDropdown(false);
    }
  };

  const onDropDownEnter = (event) => {
    pointerInDropdown = true;
  };
  const onDropDownLeave = (event) => {
    pointerInDropdown = false;
  };

  const selectOption = (event, item, answer, chosen) => {
    event.preventDefault();
    event.stopPropagation();
    if (chosen) {
      updateShowDropdown(false);
      updateSearchString(placeholder);
      return;
    }
    updateShowDropdown(false);
    let newOption;
    if (answer.precode === "age_breaks") {
      const availableRanges = getAvailableRangesFromString(item.age_breaks);
      newOption = selectAgeBreaksOption(
        item.name,
        availableRanges[0][0],
        availableRanges[availableRanges.length - 1][1]
      );
    } else if (answer.precode === 'at_least') {
      newOption = selectAtLeastOption(item.name, 1);
    } else {
      newOption = `${item.name}=${answer.precode}`;
    }

    selectedList.push(newOption);
    onUpdate(selectedList);
    updateSearchString(placeholder);
  };

  return (
    <>
      <div className="chosen-container chosen-container-multi comparasion_filter localytics-chosen chosen-container-active chosen-with-drop">
        <ul className="chosen-choices">
          <li className="search-field -full">
            <input
              className="default"
              type="text"
              value={ searchString }
              onChange={ onInputChange }
              onFocus={ onInputFocus }
              onBlur={ onInputBlur }
            />
          </li>
        </ul>
        {
          dropdownIsOpened &&
          <div
            className="chosen-drop"
            onMouseOver={ onDropDownEnter }
            onMouseLeave={ onDropDownLeave }
          >
            <ul
              className="chosen-results"
              onClick={ (event) => {selectOption(event, null, null, true); } }
            >
              {
                map(filteredOptions, (item, i) => {
                  return (
                    <Fragment key={ `optionsIndex${i}` }>
                      <li className="group-result">{ item.title }</li>
                      {
                        map(filteredOptionsAnswers(item), (answer, j) => {
                          const chosen = isSelected(item, answer);
                          return (
                            <li
                              key={ `answersIndex${i}_${j}` }
                              className={ `${chosen ? "result-selected" : "active-result"} group-option` }
                              onClick={ (event) => {
                                selectOption(event, item, answer, chosen);
                              } }
                            >
                              { answer.value }
                            </li>
                          );
                        })
                      }
                    </Fragment>
                  );
                })
              }
            </ul>
          </div>
        }
      </div>
      {
        !!selectedList && !!selectedList.length &&
        <Results
          resultsId={ resultsId }
          selectedList={ selectedList }
          optionsList={ optionsList }
          onUpdate={ onUpdate }
        />
      }
    </>
  );
};

export default FiltersDropdown;
