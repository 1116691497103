import React, { useContext, useState } from 'react';
import { find } from 'lodash';
import FormControl from "../components/formControl";
import FormControlAddress from "../components/formControlAddress";
import Modal from "../components/modal";
import ESignConsent from "../modals/eSignConsent";
import UserAgreement from "../modals/userAgreement";
import Privacy from "../modals/privacy";
import { GlobalContext } from '../contexts/appContexts';
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";

const PersonalInfo = ({ headerStep, numberOfHeaderSteps }) => {
  const { fakeData, nextStep, prevStep, mergeData } = useContext(GlobalContext);
  const { usStates } = fakeData;
  const zipCodeMask = '_____';

  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ address, setAddress ] = useState(null);
  const [ manualAddress, setManualAddress ] = useState('');
  const [ manualCity, setManualCity ] = useState('');
  const [ manualState, setManualState ] = useState('');
  const [ manualVisibleState, setManualVisibleState ] = useState('');
  const [ manualZipCode, setManualZipCode ] = useState('');

  const [ openModalESign, setOpenModalESign ] = useState(false);
  const [ openModalUserAgreement, setOpenModalUserAgreement ] = useState(false);
  const [ openModalPrivacy, setOpenModalPrivacy ] = useState(false);
  const [ editAddressManually, setEditAddressManually ] = useState(false);

  const closeModalESign = () => {
    setOpenModalESign(false);
  };
  const closeModalUserAgreement = () => {
    setOpenModalUserAgreement(false);
  };
  const closeModalPrivacy = () => {
    setOpenModalPrivacy(false);
  };

  const toggleAddressManually = () => {
    if (editAddressManually) {
      setManualCity('');
      setManualState('');
      setManualVisibleState('');
      setManualZipCode('');
    }
    mergeData({ personalInfoManualAddressClick: true });
    setEditAddressManually(!editAddressManually);
  };

  const handleNext = () => {
    mergeData({
      firstName,
      lastName,
      address,
      billingAddress: address,
      manualAddress,
      manualCity,
      manualState,
      manualZipCode
    });
    nextStep();
  };


  const disabledNextButton = (
    !firstName || !lastName || !address ||
    (
      editAddressManually ?
        (
          !manualCity ||
          !manualState ||
          !manualZipCode ||
          manualZipCode.length !== zipCodeMask.length
        ) :
        false
    )
  );
  const footerButtons = (
    <button
      disabled={ disabledNextButton }
      className="button -full-width"
      onClick={ handleNext }
    >
      Agree and Create Account
    </button>
  );

  const handleOpenModalESign = () => {
    mergeData({ personalInfoOpenTerms: true });
    setOpenModalESign(true);
  };

  const handleOpenModalUserAgreement = () => {
    mergeData({ personalInfoOpenTerms: true });
    setOpenModalUserAgreement(true);
  };

  const handleOpenModalPrivacy = () => {
    mergeData({ personalInfoOpenTerms: true });
    setOpenModalPrivacy(true);
  };

  const footerHintAbove = (
    <>
      By tapping Agree and Create Account, you’re creating a PayPal account and confirm you’re
      { " " }
      at least 18 years old and agree to our
      { " " }
      <button className="button-link -underlined" onClick={ handleOpenModalESign }>
        E-Sign Consent
      </button>
      ,
      { " " }
      <button className="button-link -underlined" onClick={ handleOpenModalUserAgreement }>
        User Agreement
      </button>
      , and
      { " " }
      <button className="button-link -underlined" onClick={ handleOpenModalPrivacy }>
        Privacy Statement
      </button>.
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } footerHintAbove={ footerHintAbove } />;

  const handleFirstNameChange = () => {
    setFirstName(fakeData.firstName);
  };

  const handleLastNameChange = () => {
    setLastName(fakeData.lastName);
  };

  const handleAddressClick = () => {
    setAddress(fakeData.address);
  };

  const handleManualAddressChange = () => {
    setManualAddress('');
  };

  const handleChangeCityValue = () => {
    setManualCity(fakeData.manualCity);
  };

  const handleChangeStateValue = () => {
    setManualState(fakeData.manualState);
    setManualVisibleState(find(usStates, (el) => el.value === fakeData.manualState).title);
  };

  const handleChangeZipCode = () => {
    setManualZipCode(fakeData.manualZipCode);
  };

  const handleBack = () => {
    mergeData({ personalInfoBackClick: true });
    prevStep();
  };

  const header = (
    <Header
      onBack={ handleBack }
      currentStep={ headerStep }
      numberOfSteps={ numberOfHeaderSteps }
    />
  );

  return (
    <Layout header={ header } footer={ footer }>
      <div className="personal-info">
        <div className="title personal-info_title">
          <h1 className="title_h1">Enter personal info</h1>
        </div>
        <div className="personal-info_form form">
          <div className="form_group">
            <FormControl
              disableIosPrep
              disableChange
              initialText={ firstName }
              label="Legal first name"
              onClick={ handleFirstNameChange }
            />
            <FormControl
              disableIosPrep
              disableChange
              initialText={ lastName }
              label="Legal last name"
              onClick={ handleLastNameChange }
            />
            <FormControlAddress
              disableChange
              value={ address }
              setValue={ setAddress }
              label="Address"
              addressList={ fakeData.addressList }
              onClick={ handleAddressClick }
            />
            <FormControl
              disableIosPrep
              disabled
              disableChange
              initialText={ manualAddress }
              onClick={ handleManualAddressChange }
              label="Apt., ste., bldg."
            />
            {
              editAddressManually &&
              <>
                <FormControl
                  disableIosPrep
                  disableChange
                  label="City"
                  initialText={ manualCity }
                  onClick={ handleChangeCityValue }
                />
                <FormControl
                  disableIosPrep
                  disableChange
                  label="State"
                  initialText={ manualVisibleState }
                  onClick={ handleChangeStateValue }
                />
                {/*<FormControlDropdown*/}
                {/*  disableChange*/}
                {/*  label="State"*/}
                {/*  options={ usStates }*/}
                {/*  value={ manualState }*/}
                {/*  onClick={ handleChangeStateValue }*/}
                {/*/>*/}
                <FormControl
                  disableIosPrep
                  disableChange
                  label="ZIP code"
                  initialText={ manualZipCode }
                  onClick={ handleChangeZipCode }
                />
              </>
            }
          </div>
          <div className="form_hint">
            <button className="button-link -underlined" onClick={ toggleAddressManually }>
              { editAddressManually ? "Hide editing address manually" : "Edit address manually" }
            </button>
          </div>
        </div>
      </div>
      {openModalESign && <Modal handleClose={ closeModalESign } headerTitle="E-Sign Consent" classNameDialog="-full-height" classNameContent="-bg-gray" >
        <ESignConsent />
      </Modal>}
      {openModalUserAgreement && <Modal handleClose={ closeModalUserAgreement } headerTitle="User Agreement" classNameDialog="-full-height" classNameContent="-bg-gray" >
        <UserAgreement />
      </Modal>}
      {openModalPrivacy && <Modal handleClose={ closeModalPrivacy } headerTitle="Privacy Statement" classNameDialog="-full-height" classNameContent="-bg-gray" >
        <Privacy />
      </Modal>}
    </Layout>
  );
};

export default PersonalInfo;


