import React, { useState, useEffect } from 'react';
import { map, keys, find, each, uniq, shuffle } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../../common/render_raw_html';
import FooterButton from '../../footer_button';
import ZoomImage from '../../zoom_image';

const ShelfPreference = ({
  concept, title, products, result, nextStep, children,
  forceImage, bigger,
  noEyeCatchText,
  firstEyeCatchText, secondEyeCatchText, thirdEyeCatchText
}) => {
  const shuffleProducts = () => (shuffle(products));

  const [ randomProducts, setRandomProducts ] = useState(shuffleProducts);
  useEffect(() => {
    setRandomProducts(shuffleProducts());
  }, [ products ]);

  const header = children;
  const noImage = !!header && !forceImage;
  const numbers = {
    1: firstEyeCatchText,
    2: secondEyeCatchText,
    3: thirdEyeCatchText
  };
  const hashInit = () => {
    const hash = {};
    each(keys(numbers), (item) => {
      hash[item] = null;
    });
    return hash;
  };

  const [ selectedHash, updateSelectedHash ] = useState(hashInit);

  const disabledOption = () => {
    const res = uniq(
      map(
        keys(numbers),
        (item) => (!!selectedHash[item])
      )
    );
    return res.length === 1 && res[0] === true ? {} : { disabled: 'disabled' };
  };

  const processAnswer = () => {
    const selectedSortedProducts = map(keys(numbers), (item) => (selectedHash[item]));
    if (!result.shelf_preference_selections) {
      result.shelf_preference_selections = [];
    }
    result.shelf_preference_selections = map(
      selectedSortedProducts,
      (productId, index) => (
        { concept_id: concept.id, product_id: productId, position: (index + 1) }
      )
    );
    updateSelectedHash(hashInit());
    nextStep();
  };

  const selectAnswer = (event, productId) => {
    const value = event.target.value;
    const parsedValue = value ? parseInt(value, 10) : null;
    updateSelectedHash((oldSelected) => {
      const currentSelection = find(keys(oldSelected), (item) => (oldSelected[item] === productId));
      const newHash = {};

      if (currentSelection) {
        if (parsedValue) {
          newHash[currentSelection] = oldSelected[parsedValue];
          newHash[parsedValue] = productId;
        } else {
          newHash[currentSelection] = parsedValue;
        }
      } else if (parsedValue) {
        newHash[parsedValue] = productId;
      }
      return { ...oldSelected, ...newHash };
    });
  };

  const selectedForConcept = (productId) => {
    const selected = find(
      keys(selectedHash),
      (item) => (selectedHash[item] === productId)
    );
    return selected ? parseInt(selected, 10) : '';
  };

  const renderItem = (product) => {
    return (
      <div className="survey-options_plates-item" key={ `item${product.id}` }>
        <div className="radio-plate" >
          <div className="radio-plate_part -radio">
            <label className="radio">
              <select
                className="form-field"
                value={ selectedForConcept(product.id) }
                onChange={ (event) => { selectAnswer(event, product.id); } }
              >
                <option value="">{ noEyeCatchText }</option>
                {
                  map(numbers, (text, value) => (
                    <option
                      key={ `val${product.id}${value}` }
                      value={ value }
                    >
                      { text }
                    </option>
                  ))
                }
              </select>
            </label>
          </div>
          <div className="radio-plate_part -image">
            {
              noImage ?
                <span className="name-preference">
                  { product.name }
                </span>

                :

                <ZoomImage
                  className={
                    classnames(
                      "radio-plate_part-image",
                      { "-with-text": forceImage }
                    )
                  }
                  src={ product.url }
                  altText={ product.name }
                  additionalContent={
                    <>
                      {
                        forceImage &&
                        <div className="name-preference">
                          { product.name }
                        </div>
                      }
                    </>
                  }
                >
                  <img src={ product.url } alt={ product.name } />
                </ZoomImage>
            }
          </div>
          {
            forceImage &&
            <div className="radio-plate_part -text">
              { product.name }
            </div>
          }
        </div>
      </div>
    );
  };

  return (
    <>
      {
        header ?
          header :
          <div className="survey-header">
            <div
              className={ classnames(`survey-header_title`, { "-bigger": bigger }) }
              { ...renderRawHtml(title) }
            />
          </div>
      }
      <div className="survey-layout_content">
        <div className={ classnames("survey-layout_container -preference", { "-bigger": bigger }) } >
          <div className={ classnames("survey-options -preference", { "-flavor": forceImage }) }>
            <div className={ classnames('survey-options_plates', { "-naming": noImage, "-flavor": forceImage }) }>
              { map(randomProducts, (product) => renderItem(product)) }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswer(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default ShelfPreference;
