import React, { useContext, useEffect, useRef } from "react";
import { upperFirst } from "lodash";
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import render_raw_html from '../../../../../common/render_raw_html';
import ConceptTags from '../concept-tags';
import { comparisonScorecardSwitchConceptClick } from "../../../../common/google-analytics/events";

const Header = ({ singular, titleField = 'title' }) => {
  const {
    init, current, closeEntityModal,
    openSwitchModal, getTourUseEffectArgs
  } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs("projectModalTitle", tourElement);
  useEffect(callback, condition);

  const onOpenSwitchModal = () => {
    comparisonScorecardSwitchConceptClick(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: current.id });
    openSwitchModal();
  };

  return (
    <div className="modal-header concept-report_header">
      <span
        className="modal_close icon-link -no-text -gray"
        title="Close"
        role="button"
        onClick={ closeEntityModal }
      >
        <SvgIcon className="icon-link_icon" name="i-close" />
        <span className="icon-link_text">Close</span>
      </span>
      {
        current.image_present &&
        <div className="concept-report_header-item concept-preview">
          <div
            className="concept-preview_thumb -sm"
            style={ { backgroundImage: `url(${current.image_url})` } }
          />
        </div>
      }
      <div className="concept-report_header-item" ref={ tourElement }>
        <div
          className="concept-report_header-subtitle"
          { ...render_raw_html(init.project.title) }
        />
        <div className="concept-report_header-title-wrap">
          <h2 className="modal_title concept-report_header-title">
            { current[titleField] }
            <ConceptTags concept={ current } left />
          </h2>
          <div className="concept-report_header-count">
            <SvgIcon
              className="concept-report_header-count-icon"
              name="project-user"
            />
            <span>{current.respondents_count}</span>
          </div>
          <div
            className="concept-report_header-switch"
            onClick={ onOpenSwitchModal }
          >
            <SvgIcon className="-switcher-icon" name="i-switch" />
            <span>Switch {upperFirst(singular)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
