const verizonTvHash = (baseUrl) => {
  const url = [ baseUrl, "tv" ].join("/");
  return {
    bag: `${url}/bag.png`,
    faq: `${url}/faq.png`,
    fiosTv: `${url}/fios-tv.png`,
    footerInfo: `${url}/footer-info.png`,
    language: `${url}/language.png`,
    logo: `${url}/logo.png`,
    popupImageApp: `${url}/popup-image-app.png`,
    popupInfoIcon: `${url}/popup-info-icon.png`,
    preview: `${url}/preview.png`
  };
};

export default verizonTvHash;
