import React, { useContext } from 'react';
import cn from 'classnames';
import { LangData } from "../../lang-data";
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import AnswerPlayControl from "./answer-play-control";
import AnswerHideControl from "./answer-hide-control";
import AnswerUnpinControl from "./answer-unpin-control";

const SentimentPinned = ({ conceptId, metric, answer, isAdmin, afterFilterIds }) => {
  const { answerByLanguage } = useContext(LangData);
  const { init } = useContext(IndexData);
  const filteredOut = afterFilterIds.findIndex(answer.id) === -1;

  return (
    <li className={ cn("sentiments-list_item sentiment -pinned", { '-filter-warning': answer.filtered }) }>
      <AnswerPlayControl conceptId={ conceptId } answer={ answer } projectId={ init.project.id } />

      <div className="sentiment_content">
        <span className="answer-block -sm">
          <span className="answer-block_txt">{answerByLanguage(answer)}</span>
          <span className="answer-block_author">
            {answer.respondent.signature}
          </span>
        </span>
      </div>

      {filteredOut && <div className="sentiment_status">
        <span
          className="sentiment_status-warning -filter-warning"
          title="Pinned answer does not meet filter criteria"
        >
          <SvgIcon name="i-filter-warning" />
        </span>
      </div>}

      {isAdmin && <AnswerHideControl conceptId={ conceptId } metric={ metric } answer={ answer } projectId={ init.project.id } gaTrackingId={ init.google_analytics_id } />}

      <AnswerUnpinControl conceptId={ conceptId } metric={ metric } answer={ answer } />
    </li>
  );
};

export default SentimentPinned;
