import { sortBy } from 'lodash';

const n0 = (v) => (Math.round(v));

const n2 = (v) => (parseFloat(v).toFixed(2));

const idToColor = (id) => {
  const positiveId = Math.abs(id);

  const r = [
    '40',
    (96 + positiveId % 128).toString(16).padLeft(2, '0'),
    (192 + (positiveId >> 8) % 64).toString(16).padLeft(2, '0')
  ];

  if (positiveId % 5 === 0) {
    return `#${r[0]}${r[1]}${r[2]}`;
  } else if (positiveId % 5 === 1) {
    return `#${r[2]}${r[1]}${r[0]}`;
  } else if (positiveId % 5 === 2) {
    return `#${r[0]}${r[2]}${r[1]}`;
  } else if (positiveId % 5 === 3) {
    return `#${r[2]}${r[0]}${r[1]}`;
  } else if (positiveId % 5 === 4) {
    return `#${r[1]}${r[0]}${r[2]}`;
  }

  return `#${r[1]}${r[2]}${r[0]}`;
};

const biggerThan = (value, level) => {
  return parseInt(value) > level;
};

const findAvgModeValue = (values, averageMode) => (
  values[averageMode] && values[averageMode].avg?.top2 ?
    values[averageMode].avg.top2 :
    '0%'
);

const showedAverageValue = (values, averageMode, inMode = false, internalUser = false, view = "internal", box = "top2") => {
  if (
    (internalUser && view === "client" && averageMode === 'platform') ||
    (values.avg_hide_for_platform && averageMode === 'platform') ||
    (values.avg_hide_for_custom && averageMode !== 'project')
  ) {
    return '-';
  }
  if (inMode && values[averageMode]) {
    return findAvgModeValue(values, averageMode);
  }
  return values?.avg?.[box] ? values.avg[box] : '0%';
};

const averageCount = (value, averageMode, buzzbackUser, view, withHasValueFlag = false) => {
  if (averageMode === 'platform' &&
    (!buzzbackUser || (buzzbackUser && view === "client")
    )) {
    return withHasValueFlag ? [ '-', false ] : '-';
  }
  if (typeof value === 'undefined') {
    return withHasValueFlag ? [ '', false ] : '';
  }
  const result = Math.round(value);
  return withHasValueFlag ? [ result, true ] : result;
};

const averageCountWithFlag = (value, averageMode, buzzbackUser, view) => (
  averageCount(value, averageMode, buzzbackUser, view, true)
);

const hideCustom = (customMetric, averageMode) => {
  return customMetric !== undefined && customMetric && averageMode !== 'project';
};

const columnsOrderChanged = (reportView, id) => (
  reportView?.customMetrics?.[id] && !!reportView.customMetrics[id]?.custom_columns_order
);
const columnsOrder = (reportView, columns, id) => (
  sortBy(
    columns,
    (item) => (reportView?.customMetrics?.[id]?.custom_columns_order.indexOf(item)))
);

export {
  n0, n2, idToColor, biggerThan,
  showedAverageValue, findAvgModeValue,
  hideCustom, averageCount, averageCountWithFlag,
  columnsOrderChanged, columnsOrder
};
