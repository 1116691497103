import React, { useEffect, useRef, useState } from 'react';
import { find } from 'lodash';
import {
  projectSetupCustomizeQualifyMessageClick,
  projectSetupForceMarketplaceClick
} from "../../../common/google-analytics/events";

const GoogleAnalyticsOnInputs = ({ projectId, options }) => {
  const ref = useRef(null);
  const [ styles ] = useState(() => (
    { position: 'absolute', left: -1000, top: -1000, width: 0, height: 0 }
  ));

  const findFormFromCurrentElement = () => {
    let counter = 0;
    const maxCounter = 10;
    let form = ref.current;
    while (!!form && form.tagName?.toLowerCase() !== 'form' && counter <= maxCounter) {
      form = form.parentNode;
      counter += 1;
    }
    return form;
  };

  const findInput = (className) => {
    const element = findFormFromCurrentElement();
    return find(element, (item) => (item.tagName?.toLowerCase() === 'input' && item.id === className));
  };

  const [ qualInput, setQualInput ] = useState(findInput("project_custom_qualified")?.className);
  const [ prevqualInput, setPrevQualInput ] = useState(findInput("project_custom_qualified")?.className);
  const [ mpInput, setMpInput ] = useState(findInput("project_mp_option")?.className);
  const [ prevMpInput, setPrevMpInput ] = useState(findInput("project_mp_option")?.className);

  useEffect(() => {
    const qualifiedInput = findInput("project_custom_qualified");
    setPrevQualInput(qualifiedInput?.className);
    setQualInput(qualifiedInput?.className);
    const interval = setInterval(() => (setQualInput(qualifiedInput?.className)), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const mpInput = findInput("project_mp_option");
    setMpInput(mpInput?.className);
    setPrevMpInput(mpInput?.className);
    const mpInterval = setInterval(() => (setMpInput(mpInput?.className)), 100);
    return () => {
      clearInterval(mpInterval);
    };
  }, []);

  useEffect(() => {
    if (qualInput && prevqualInput && qualInput !== prevqualInput) {
      projectSetupCustomizeQualifyMessageClick(options.google_analytics_id, { project_id: projectId || "new" });
      setPrevQualInput(qualInput);
    }
  }, [ qualInput ]);

  useEffect(() => {
    if (mpInput && prevMpInput && mpInput !== prevMpInput) {
      projectSetupForceMarketplaceClick(options.google_analytics_id, { project_id: projectId || "new" });
      setPrevQualInput(qualInput);
    }
  }, [ mpInput ]);

  return (<div style={ styles } ref={ ref } />);
};

export default GoogleAnalyticsOnInputs;
