import React, { useContext, useState } from 'react';
import { RouteContext } from "../contexts";
import { scrollTop } from "../helpers";
import { setHashData } from "../statistic";

const Header = ({ clickableDroppedMenu }) => {
  const { setPath } = useContext(RouteContext);

  const [ showDroppedMenu, setShowDroppedMenu ] = useState(false);

  const goHome = () => {
    setShowDroppedMenu(false);
    setPath('/');
    scrollTop();
  };

  const goAbout = () => {
    setShowDroppedMenu(false);
    setTimeout(() => {
      setPath('/about');
      setHashData(`aboutUsClicked`, true);
      scrollTop();
    }, 60);
  };

  const sandwichClick = () => {
    if (clickableDroppedMenu) {
      setShowDroppedMenu(!showDroppedMenu);
    }
  };

  return (
    <header className="header">
      <div className="header_grid">
        <div className="header_item">
          <div className="header_link" onClick={ () => sandwichClick() }>
            <div className={ `header_sandwich ${showDroppedMenu ? '-opened' : ''}` }>
              <div className="header_sandwich-line -top" />
              <div className="header_sandwich-line -middle" />
              <div className="header_sandwich-line -bottom" />
            </div>
          </div>
        </div>
        <div className="header_item">
          <div className="header_link" onClick={ () => goHome() }>
            <svg className="icon -logo">
              <use xlinkHref="#svg_logo" />
            </svg>
          </div>
        </div>
        <div className="header_item">
          <div className="header_link">
            <svg className="icon -cart">
              <use xlinkHref="#svg_cart" />
            </svg>
          </div>
        </div>
      </div>
      {clickableDroppedMenu && (
        <div className={ `header_drop ${showDroppedMenu ? '-open' : ''}` }>
          <ul className="header_drop-menu">
            <li className="header_drop-menu-item">
              <div className="header_drop-menu-link" onClick={ () => goAbout() }>
                About Us
              </div>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
