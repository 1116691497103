import { each, filter, find } from 'lodash';
import { getSelectedKeys, getSelectedKeysAfterMapping } from "./questions_mapping";

//hashSums is { appliedSum:, restSum: };
const calculateQuotaValue = (hashSums, selectedItemsLength, isLast) => {
  let val;

  if (isLast) {
    val = 100 - hashSums.appliedSum;
  } else {
    const calc = (1 / selectedItemsLength) * 100;
    val = Math.floor(calc);
    hashSums.restSum += (calc - val);

    const additionalPercent = Math.floor(hashSums.restSum);
    if (additionalPercent > 0) {
      val += additionalPercent;
      hashSums.restSum -= additionalPercent;
    }
  }

  hashSums.appliedSum += val;

  return val;
};

const calculateQuotaBySelectedKeys = (selectedKeys) => {
  const quotas = {};
  const hashSums = { appliedSum: 0, restSum: 0 };
  for (let i = 0; i < selectedKeys.length; i++) {
    const k = selectedKeys[i], isLast = (i === selectedKeys.length - 1);
    quotas[k] = `${calculateQuotaValue(hashSums, selectedKeys.length, isLast)}`;
  }
  return quotas;
};

const calculateQuotasForData = (title, answers, condition, disallowedOptions = {}, conditionValues) => {
  const selectedKeys = getSelectedKeys(title, answers, condition, disallowedOptions, conditionValues);
  return calculateQuotaBySelectedKeys(selectedKeys);
};

const calculateQuotasWithMapping = (q, conditionValues) => {
  const selectedKeys = getSelectedKeysAfterMapping(
    q.quotaMappingList, q.title, q.answers, q.condition, q.quotaMappingAnswers, conditionValues
  );
  return calculateQuotaBySelectedKeys(selectedKeys);
};

const calculateQuotasForQuestion = (q, disallowedOptions = {}, conditionValues) => {
  if (q.quotaMappingList) {
    q.quotas = calculateQuotasWithMapping(q, conditionValues);
  } else {
    q.quotas = calculateQuotasForData(q.title, q.answers, q.condition, disallowedOptions, conditionValues);
  }
};

const calculateQuotasForList = (list) => {
  const selectedList = filter(list, (item) => item.selected);
  const hashSums = { appliedSum: 0, restSum: 0 };

  each(selectedList, (item, index) => {
    const isLast = selectedList.length - 1 === index;
    item.quota = calculateQuotaValue(hashSums, selectedList.length, isLast);
  });
};

const setQuotasAndSelectionForBreakingQuestion = (q, breaks, conditionValues) => {
  // Implemented only for Kids in Household, when there are Gender and Age question.
  const genderQ = breaks[0], ageQ = breaks[1];

  const mainList = [];

  each(genderQ.condition, (gVal, qKey) => {
    if (gVal === conditionValues.selected) {
      const genderAnswer = genderQ.answers[`${qKey}`],
        genderQuota = genderQ.quotas[`${qKey}`] * 1;

      each(ageQ.condition, (aVal, aKey) => {
        if (aVal === conditionValues.selected) {
          const ageAnswer = ageQ.answers[`${aKey}`],
            ageQuota = ageQ.quotas[`${aKey}`] * 1;

          mainList.push(
            {
              title: `${genderAnswer} ${ageAnswer}`,
              quota: Math.ceil(((genderQuota / 100) * (ageQuota / 100)) * 100)
            }
          );
        }
      });
    }
  });

  q.condition = {};
  q.quotas = {};

  each(q.answers, (mainAnswer, mainKey) => {
    const item = find(mainList, (item) => item.title === mainAnswer);

    if (item) {
      q.condition[`${mainKey}`] = conditionValues.selected;
      q.quotas[`${mainKey}`] = `${item.quota}`;
    } else {
      q.condition[`${mainKey}`] = conditionValues.notSelected;
    }
  });
};

export {
  calculateQuotaValue,
  calculateQuotasForQuestion,
  calculateQuotasForList,
  setQuotasAndSelectionForBreakingQuestion
};
