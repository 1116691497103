import React from "react";
import ShelfEvaluationShelf from "./shelf-evaluation-shelf";
import ShelfEvaluationCart from "./shelf-evaluation-cart";
import ShelfEvaluationRanking from "./shelf-evaluation-ranking";
import ShelfEvaluationShelfNsizes from './shelf-evaluation-shelf-nsizes';
import ShelfCartCompetitors from './shelf-cart-competiors';
import ShelfRankingCompetitors from './shelf-ranking-competitors';

const ShelfEvaluationTable = ({ data, current }) => {
  const shelfEnabled = data.shelf_enabled;
  const cartEnabled = data.cart_exercise;
  const rankingEnabled = data.cart_exercise && data.cart_ranking && data.ranking[current.id].has_ranking;

  return (
    <table className="table table-comparison -long-first-cell">
      <thead>
        <tr>
          <th className="table-comparison_name-cell" colSpan={ 3 }>
            <div className="table-comparison_title">
              Shelf Evaluation
            </div>
          </th>
          <th className="table-comparison_average-cell">
            Avg
          </th>
        </tr>
      </thead>
      <tbody>
        { (shelfEnabled || cartEnabled) && <ShelfEvaluationShelfNsizes current={ current } /> }
        { shelfEnabled && <ShelfEvaluationShelf current={ current } /> }
        { cartEnabled && <ShelfEvaluationCart current={ current } /> }
        { cartEnabled && rankingEnabled  && <ShelfEvaluationRanking current={ current } /> }

        { cartEnabled && <ShelfCartCompetitors current={ current } /> }
        { cartEnabled && rankingEnabled && <ShelfRankingCompetitors current={ current } /> }
      </tbody>
    </table>
  );
};

export default ShelfEvaluationTable;
