import React, { useState } from "react";
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";

const RadioButtons = ({ id, title, hint, initValue, options, onChange, errors }) => {
  const [ val, setVal ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setVal(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="form_section">
      <div className="form_section-title -grid">
        <div className="form_section-title-item">
          <h3>{title}</h3>
        </div>
        {hint &&
          <div className="form_section-title-item">
            <div className="info-tooltip">
              <div className="info-tooltip_control">
                <SvgIcon name="i-info" />
              </div>
              <div className="info-tooltip_drop">
                <div className="info-tooltip_drop-inner">
                  {hint}
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div className={ cn("form-group", errors ? 'has-error' : '') }>
        {map(options, (option) => (
          <div className="form-group_item" key={ `project-${option.value}` }>
            <label className={ `radio -js-${id}-${option.value}` }>
              <input
                id={ `project-${id}-${option.value}` }
                type="radio"
                checked={ val === option.value }
                onChange={ handleOnChange }
                value={ option.value }
              />
              <span className="radio_label">{option.title}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButtons;
