import React, { useState, useEffect, Fragment, useRef } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';

const Dropdown = ({
  index,
  selectedPlan,
  allPlans,
  chosenPlanIds,
  chosenPlanPackIds = [],
  updateChosenPlanIds,
  onUpdatePlans
}) => {
  const dropdownRef = useRef();
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const [ , setClickedToOpen ] = useState(false);

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (dropdownRef.current) {
        setClickedToOpen((oldClickedToOpen) => {
          if (!oldClickedToOpen && !dropdownRef.current.contains(event.target)) {
            setOpenDropdown(false);
          }
          return false;
        });
      }
    };
    document.addEventListener('click', handleBodyClick);
    return (() => {
      document.removeEventListener('click', handleBodyClick);
    });
  }, []);

  const handleDropDownClick = () => {
    setClickedToOpen(true);
    setOpenDropdown(!openDropdown);
  };

  const dropDownMenuClick = (newPlanId, packId) => {
    setOpenDropdown(false);
    chosenPlanIds[index] = newPlanId;
    chosenPlanPackIds[index] = packId;
    updateChosenPlanIds([ ...chosenPlanIds ], [ ...chosenPlanPackIds ]);
    onUpdatePlans(newPlanId);
  };

  return (
    <div ref={ dropdownRef } className={ classnames('dropdown', { '-open': openDropdown }) }>
      <div className="dropdown_selected" onClick={ handleDropDownClick }>
        {
          selectedPlan.choosePerkPack?.length > 0
            ? <>
              { !openDropdown
                && <div className="dropdown_selected-value -perk-pack">
                  { selectedPlan.name }
                </div>
              }
              <div className="dropdown_selected-value">
                { selectedPlan.choosePerkPack.find((pack) => pack.id === chosenPlanPackIds[index])?.name }
              </div>
            </>
            : <div className="dropdown_selected-value">{ selectedPlan.name }</div>
        }
        <div
          className={
            classnames(
              'dropdown_caret',
              { '-open': openDropdown }
            )
          }
        >
          <svg className="icon -dropdown">
            <use xlinkHref="#svg_caret" />
          </svg>
        </div>
      </div>
      <div className="dropdown_menu">
        {
          map(allPlans, (planItem, idx) => {
            const hasPerkPacks = planItem.choosePerkPack?.length > 0;
            return (
              <Fragment key={ `drd-item-${planItem.id}-${idx}` }>
                <div
                  className={
                    classnames(
                      'dropdown_menu-item',
                      {
                        '-perk-pack -no-border': hasPerkPacks,
                        '-disabled': selectedPlan.id === planItem.id && !hasPerkPacks
                      }
                    )
                  }
                  onClick={ hasPerkPacks ? () => {} : () => dropDownMenuClick(planItem.id) }
                >
                  { planItem.name }
                </div>
                { hasPerkPacks && map(planItem.choosePerkPack, (pack, packIndex, arr) => {
                  return (
                    <div
                      className={
                        classnames(
                          'dropdown_menu-item -perk-pack-item',
                          {
                            '-no-border': packIndex !== arr.length - 1,
                            '-disabled': !!chosenPlanPackIds[index]
                              && planItem.id === selectedPlan.id
                              && pack.id === chosenPlanPackIds[index]
                          }
                        )
                      }
                      key={ `drd-item-${planItem.id}-${pack.id}-${packIndex}` }
                      onClick={ () => dropDownMenuClick(planItem.id, pack.id) }
                    >
                      { pack.name }
                    </div>
                  );
                }) }
              </Fragment>
            );
          })
        }
      </div>
    </div>
  );
};

export default Dropdown;
