import React, { useContext, useState } from 'react';
import { map, sum, find } from 'lodash';
import {
  calculatePerksSum,
  calculatePerksSavings,
  sumAndFormatCheckFractional
} from './helper';
import { Header } from './header';

import {
  setCurrentLine,
  isLinesFilled,
  createLine,
  getSelectedPlans,
  getHashData,
  increaseVariableForCurrentLine
} from './statistics';
import { PlanDataProvider } from './contexts';

const NoPlan = ({ index, onSelectPlan }) => {
  return (
    <div className="order_line">
      <div className="order_line-top">
        <div className="order_line-grid">
          <div className="order_line-grid-item">
            <div className="order_line-number">Line {index + 1}</div>
          </div>
        </div>
      </div>
      <div className="order_line-content">
        <div className="order_line-button-wrapper">
          <button
            className="button order_line-button"
            onClick={ () => { onSelectPlan(index + 1); } }
          >
            Pick a plan
          </button>
        </div>
      </div>
    </div>
  );
};

const SelectedPlan = ({
  plan, planPrices, perks, index, onChangePlan, handleClearLine
}) => {
  return (
    <div className="order_line">
      <div className="order_line-top">
        <div className="order_line-grid">
          <div className="order_line-grid-item">
            <div className="order_line-number">Line {index + 1}</div>
          </div>
          <div className="order_line-grid-item">
            <button
              className="button-link -sm"
              onClick={ () => { handleClearLine(index + 1); } }
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="order_line-content">
        <div className="order_line-grid">
          <div className="order_line-grid-item">
            <div className="order_line-note">Plan</div>
            <div className="order_line-full-price">${ planPrices.totalPlanPrice }/mo*</div>
          </div>
          <div className="order_line-grid-item">
            <button
              className="button-link -sm"
              onClick={ () => { onChangePlan(index + 1); } }
            >
              Edit
            </button>
          </div>
        </div>
        <div className="order_line-grid">
          <div className="order_line-grid-item">
            <div className="order_line-title">{plan?.core?.title}</div>
            <div className="order_line-note">
              You’re saving $10 with Auto Pay.
              <br />
              Activate after completing this purchase.
            </div>
          </div>
          <div className="order_line-grid-item">
            <div className="order_line-price">${ planPrices.planPrice }/mo</div>
          </div>
        </div>
        {
          map(perks, (perk) => (
            <div className="order_line-grid" key={ perk.title }>
              <div className="order_line-grid-item">
                <div className="order_perk-title">
                  { perk.title }
                </div>
                <div className="order_line-note">
                  ${ sumAndFormatCheckFractional(perk.notePrice) }/mo value
                </div>
              </div>
              <div className="order_line-grid-item">
                <div className="order_perk-price">
                  ${ perk.orderPrice }/mo
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

const Order = ({ plans, perks, nextStep, onReselectPlan }) => {
  const isFinal = isLinesFilled();
  const selectedPlans = getSelectedPlans(plans);
  const [ counter, setCounter ] = useState(0);
  const rerender = () => setCounter(counter + 1);
  const { allPerksFlatten, plans_prebuild } = useContext(PlanDataProvider);

  const defineCurrentPlanType = (clickedLine) => {
    const lineData = getHashData(`lines.${clickedLine}`) || {};
    const { planCustomBuilt } = lineData;
    const currentPlan = selectedPlans[clickedLine - 1];
    const popular = find(plans_prebuild, (item) => item.id === currentPlan?.id);
    if (popular) {
      return planCustomBuilt ? 'CustomizedPopularPlan' : 'PopularPlan';
    }
    return 'BuiltOwnPlan';
  };

  const handleSelectPlan = (clickedLine) => {
    increaseVariableForCurrentLine(`orderPickPlanClick`, clickedLine);
    setCurrentLine(clickedLine);
    onReselectPlan(clickedLine, true);
  };

  const handleChangePlan = (clickedLine) => {
    increaseVariableForCurrentLine(`orderEdit${defineCurrentPlanType(clickedLine)}Click`, clickedLine);
    onReselectPlan(clickedLine);
  };

  const handleClearLine = (clickedLine) => {
    increaseVariableForCurrentLine(`orderRemove${defineCurrentPlanType(clickedLine)}Click`, clickedLine);
    createLine(clickedLine, {}, allPerksFlatten);
    rerender();
  };

  const itemsWithPrices = map(selectedPlans, (plan, index) => {
    const result = {};
    if (plan?.id) {
      const { planPerkIds = [], planPerkVariants = {} } = getHashData(`lines.${index + 1}`);
      const selectedPerks = map(planPerkIds, (id) => find(perks, (perk) => perk.id === id));
      result.plan = plan;
      result.planPrices = {
        totalPlanPrice: calculatePerksSum(plan.price, selectedPerks, planPerkVariants),
        planPrice: plan.price,
        totalSavings: calculatePerksSavings(selectedPerks, planPerkVariants)
      };
      result.perks = map(selectedPerks, (perk) => {
        const orderPrice = calculatePerksSum(0, [ perk ], planPerkVariants);
        const saveSum = calculatePerksSavings([ perk ], planPerkVariants);

        return {
          title: (perk.orderTitle || perk.title),
          saveSum,
          notePrice: (orderPrice + saveSum),
          orderPrice
        };
      });
    }
    return result;
  });

  const totalSum = sum(map(itemsWithPrices, (item) => item.planPrices?.totalPlanPrice || 0));
  const totalSavings = sum(map(itemsWithPrices, (item) => item.planPrices?.totalSavings || 0));

  return (
    <div className="wrapper">
      <Header />
      <div className="content">
        <div className="container">
          <div className="order">
            <h2 className="order_title content_title">
              Build your order.
            </h2>
            <div className="order_sections">
              <div className="order_section -lines">
                <div className="order_lines">
                  <ul className="order_lines-list">
                    {
                      map(itemsWithPrices, (item, index) => (
                        <li className="order_lines-list-item" key={ index }>
                          {
                            item.plan?.id ? (
                              <SelectedPlan
                                plan={ item.plan }
                                planPrices={ item.planPrices }
                                perks={ item.perks }
                                index={ index }
                                onChangePlan={ handleChangePlan }
                                handleClearLine={ handleClearLine }
                              />
                            ) : (
                              <NoPlan index={ index } onSelectPlan={ handleSelectPlan } />
                            )
                          }
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="order_section -summary">
                <div className="order_summary">
                  <div className="order_summary-grid">
                    <div className="order_summary-grid-item">
                      <div className="order_summary-text">
                        Plans total: ${totalSum}/mo
                      </div>
                    </div>
                    <div className="order_summary-grid-item">
                      <div className="order_summary-text -accent">
                        { !!totalSavings && <>${ sumAndFormatCheckFractional(totalSavings) }/mo savings</> }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_section -footnote">
                <div className="order_footnote">
                  *Pricing shown is based on Data Plan selection, plus any  Perks you selected. Monthly surcharges, taxes and government fees will be added to your bill.
                </div>
              </div>
            </div>
            <div className="order_footer">
              <button
                className="button order_footer-button"
                onClick={ () => {
                  nextStep();
                } }
                disabled={ !isFinal }
              >
                Proceed to checkout
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Order;

