import React, { useState } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from './render_raw_html';

const Characteristic = ({
  title,
  description,
  onCheck,
  hideTitle,
  iconName,
  highlighted,
  specialClass
}) => {
  const [ open, setOpen ] = useState(false);
  const updateVisibility = () => {
    setOpen(!open);
  };

  const rootClassName = classnames('characteristic', specialClass);

  const titleClassName = classnames(
    'characteristic_title',
    {
      '-highlighted': highlighted,
      '-has-icon': !hideTitle && iconName,
      '-pointer': description
    }
  );

  const descriptionClassName = classnames(
    'characteristic_description',
    { '-highlighted': highlighted }
  );

  const caretClassName = classnames(
    'characteristic_state',
    { '-open': open }
  );

  const iconClassName = classnames(
    'characteristic_icon',
    `-${iconName}`,
    { '-pointer': hideTitle }
  );

  const titleWithCaret = title +
    (
      description ?
        (`<span class="${caretClassName}">` +
        '<svg>' +
        `<use xlink:href="#svg_caret" />` +
        '</svg>' +
        '</span>') : ''
    );

  return (
    <div className={ rootClassName }>
      <div className="characteristic_header" onClick={ updateVisibility }>
        {
          iconName &&
          <div className={ iconClassName }>
            <svg>
              <use xlinkHref={ `#svg_${iconName}` } />
            </svg>
            {
              hideTitle &&
              <span className={ caretClassName }>
                <svg>
                  <use xlinkHref="#svg_caret" />
                </svg>
              </span>
            }
          </div>
        }
        {
          !hideTitle &&
          <div className={ titleClassName } { ...renderRawHtml(titleWithCaret) } />
        }
      </div>
      {
        description && open &&
        <div
          className={ descriptionClassName }
          { ...renderRawHtml(description) }
        />
      }
      {
        open && onCheck &&
        <div className="characteristic_check">
          Check availability
        </div>
      }
    </div>
  );
};

const ContentCharacteristic = ({
  title, highlighted, note, list, onCheck
}) =>  {
  const headerClassName = classnames(
    'plan-features_header',
    { '-highlighted': highlighted }
  );

  const noteClassName = classnames(
    'plan-features_note',
    { '-highlighted': highlighted }
  );

  return (
    <>
      { title && <div className={ headerClassName }>{ title }</div> }
      { note && <div className={ noteClassName } { ...renderRawHtml(note) } /> }
      {
        list && map(list, (item, index) => (
          <Characteristic
            key={ `${item.title}-${item.id}-${index}` }
            title={ item.title }
            description={ item.description }
            hideTitle={ item.hideTitle }
            iconName={ item.iconName }
            highlighted={ highlighted }
            onCheck={ onCheck }
            specialClass={ item.specialClass }
          />
        ))
      }
    </>
  );
};

const CharacteristicList = ({
  title,
  list,
  onCheck,
  note,
  overTitle,
  specialClassList,
  boxClassNames,
  highlighted
}) => {
  const listClassName = classnames(
    'plan-features_group',
    specialClassList,
    {
      '-highlighted': highlighted,
      '-other': !title
    }
  );
  const boxClassName = classnames('plan-features_box', boxClassNames);
  const overHeaderClassName = classnames('plan-features_header');

  return (
    <div className={ listClassName }>
      {
        overTitle &&
        <div className={ overHeaderClassName }>{ overTitle }</div>
      }
      {
        highlighted ?
          <div className={ boxClassName }>
            <ContentCharacteristic
              title={ title }
              note={ note }
              list={ list }
              highlighted={ highlighted }
            />
          </div>
          :
          <ContentCharacteristic
            title={ title }
            note={ note }
            list={ list }
            highlighted={ highlighted }
          />
      }
    </div>
  );
};

export default CharacteristicList;
