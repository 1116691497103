import React from 'react';

const StickyTip = ({ className }) => {
  return <div className={ `sticky-tip ${className || ''}` }>
    <div className="sticky-tip_container">
      <div className="sticky-tip_content">
        <p className="sticky-tip-text">Scroll down and read the information below. Choose “Get Started” when you are ready to continue.</p>
      </div>
    </div>
  </div>
}

export default StickyTip;
