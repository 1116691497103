import React, { useContext } from "react";
import { map } from "lodash";
import cn from "classnames";
import { GlobalContext } from '../contexts/appContexts';
import CloseAppXSign from './closeAppXSign';

const Header = ({ onBack, currentStep, numberOfSteps, className }) => {
  const { closeFullApp } = useContext(GlobalContext);
  const handleCloseFullApp = () => {
    closeFullApp && closeFullApp();
  };
  return (
    <header className={ cn("header", className) }>
      <div className="header_grid">
        <div className="header_grid-item">
          {
            onBack &&
            <button className="button-icon header_back" onClick={ onBack }>
              <svg className="icon header_back-icon">
                <use xlinkHref="#svg_back" />
              </svg>
            </button>
          }
        </div>
        <div className="header_grid-item">
          {
            !!numberOfSteps &&
            <ul className="header_progress">
              {
                map([ ...Array(numberOfSteps).keys() ], (item, index) => (
                  <li
                    key={ index }
                    className={
                      cn(
                        "header_progress-step",
                        { '-current': index + 1 === currentStep },
                        { '-completed': index + 1 < currentStep },
                        { '-big': !!closeFullApp && (!numberOfSteps || numberOfSteps < 2) }
                      )
                    }
                  />
                ))
              }
            </ul>
          }
        </div>
        {
          !!closeFullApp &&
          <div className="header_grid-item">
            <CloseAppXSign onClick={ handleCloseFullApp } />
          </div>
        }
      </div>
    </header>
  );
};

export default Header;

