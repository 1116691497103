import React from 'react';
import classnames from 'classnames';
import { map } from 'lodash';

const NegativeSwitcher = ({ selectOperator, item }) => {
  const handleSelect = (event, operator) => {
    if (!operator && item.logicNotOperator) {
      selectOperator(event, operator);
    } else if (!!operator && !item.logicNotOperator) {
      selectOperator(event, operator);
    }
  };
  return (
    <div className="logic-operator-switcher not-switcher">
      <span
        className={
          classnames(
            'logic-operator colored',
            { "-active": !item.logicNotOperator }
          )
        }
        onClick={ (event) => { handleSelect(event, null); } }
      >
        IS
      </span>
      <span
        className={
          classnames(
            'logic-operator colored -NOT',
            { "-active": item.logicNotOperator }
          )
        }
        onClick={ (event) => { handleSelect(event, "NOT"); } }
      >
        IS NOT
      </span>
    </div>
  );
};

const LogicSwitcher = ({ selectOperator, item, reverseColors }) => {
  const handleSelect = (event, operator) => {
    if (operator !== item.logicOperator) {
      selectOperator(event, operator);
    }
  };

  return (
    <div
      className={
        classnames(
          "logic-operator-switcher",
          { "-reverse-colors": reverseColors }
        )
      }
    >
      {
        map([ "AND", "OR" ], (operator, l) => (
          <span
            key={ `logicOperator${item.name}${l}` }
            className={
              classnames(
                'logic-operator colored',
                `-${operator.toLowerCase()}`,
                { "-active": operator === item.logicOperator }
              )
            }
            onClick={ (event) => { handleSelect(event, operator); } }
          >
            { operator }
          </span>
        ))
      }
    </div>
  );
};

const RemoveButton = ({ removeSection, reverseColors }) => {
  return (
    <div
      onClick={ (event) => { removeSection(event); } }
      className={
        classnames(
          "remove-section",
          { "-reverse-colors": reverseColors }
        )
      }
    >
      <div
        className="cross-to-remove"
      />
    </div>
  );
};

export { NegativeSwitcher, LogicSwitcher, RemoveButton };
