import React, { createContext, useState } from 'react';
import { map, partition, filter } from "lodash";
import SentimentPinned from './sentiment-pinned';
import Sentiment from './sentiment';

export const PinnedData = createContext({});

const SentimentList = ({ data, metric, isAdmin, afterFilterIds }) => {
  const [ pinnedIds, setPinnedIds ] = useState(map(data.neutral_answers, (answer) => answer.id));
  const [ pinnedAnswers, otherAnswers ] = partition(data.all_neutral_answers, (answer) => (pinnedIds.findIndex(answer.id) > -1));

  const otherAnswersFiltered = filter(otherAnswers, (answer) => (afterFilterIds.findIndex(answer.id) > -1));

  return (
    <PinnedData.Provider value={ { pinnedIds, setPinnedIds } }>
      <ul className="sentiments-list">
        {map(pinnedAnswers, (answer) => (
          <SentimentPinned
            key={ answer.id }
            conceptId={ data.concept_id }
            metric={ metric }
            answer={ answer }
            setPinnedIds={ setPinnedIds }
            isAdmin={ isAdmin }
            afterFilterIds={ afterFilterIds }
          />
        ))}

        {map(otherAnswersFiltered, (answer) => (
          <Sentiment
            key={ answer.id }
            conceptId={ data.concept_id }
            metric={ metric }
            answer={ answer }
            setPinnedIds={ setPinnedIds }
            isAdmin={ isAdmin }
          />
        ))}
      </ul>
    </PinnedData.Provider>
  );
};

export default SentimentList;
