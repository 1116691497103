import React from 'react';

const SellingAndAccepting = () => {
  return (
    <div>

      <div>
        <h2>Accepting Payments</h2>
        <h3>Receiving payments</h3>
        <p>
          If you use your PayPal account to receive payments for the sale of goods or services or to receive donations, you must:
        </p>
        <ul>
          <li>Pay any applicable fees for receiving the funds.</li>
          <li>Not ask your buyer to send you money as a personal transaction (often referred to as using the “Send Money to a Friend or Family Member” feature). If you do so, PayPal may remove your PayPal account’s ability to accept payments from friends or family members.</li>
        </ul>
        <p>
          By integrating into your online checkout/platform any functionality intended to enable a payer without a PayPal account to send a payment to your PayPal account, or by sending an <span className="pseudo-link">invoice</span> for a payment request, you agree to all further terms of use of that functionality which PayPal will make available to you on any page on the PayPal or Braintree website (including any page for developers and our <span className="pseudo-link">Legal Agreements</span> page) or online platform. Such further terms include the <span className="pseudo-link">PayPal Alternative Payment Methods Agreement</span>.
        </p>


        <h3>Commercial entity status</h3>
        <p>
          If you are a seller, and the card payments you receive using the PayPal services meet certain criteria, you are required under the applicable card network rules to agree to a Commercial Entity Agreement with each card network member bank that processes such card payments. Such criteria include you receiving card payments from:
        </p>
        <ul>
          <li>PayPal account users where such card payments reach specified card network-defined volume thresholds, and/or</li>
          <li>PayPal account users where such card payments fall within certain card network-defined business categories, and/or</li>
          <li>Buyers without a PayPal account.</li>
        </ul>
        <p>
          The applicable <span className="pseudo-link">Commercial Entity Agreement(s)</span> (as updated from time to time) apply to any such card payments processed by PayPal on your behalf through the relevant member bank(s) and are incorporated by reference into this user agreement.
        </p>

        <h3>Receiving donation payments</h3>
        <p>
          If you use your PayPal account to accept donations, including using the <span className="pseudo-link">PayPal Fundraisers</span>, the Donate Button, or PayPal Checkout for Donations, you must:
        </p>
        <ul>
          <li>Pay any applicable fees for receiving the funds.</li>
          <li>Not use PayPal Fundraisers, the Donate Button, PayPal Checkout for Donations, or any other donation services for the sale of goods and services. If you do so, PayPal may remove your PayPal account&#x27;s ability to use those services or otherwise receive donations.</li>
        </ul>

        <h3>No surcharges</h3>
        <p>
          You agree that you will not impose a surcharge or any other fee for accepting PayPal as a payment method. You may charge a handling fee in connection with the sale of goods or services as long as the handling fee does not operate as a surcharge and is not higher than the handling fee you charge for non-PayPal transactions.
        </p>

        <h3>Presentation of PayPal and Venmo</h3>
        <p>
          You must treat PayPal and/or Venmo payment methods or marks at least on par with any other payment methods or marks offered or displayed at your points of sale where PayPal or Venmo branded services are integrated, including your websites or mobile applications. This includes at least equal or better: logo placement, position within any point of sale, and treatment in terms of payment flow, terms, conditions, restrictions, and fees, in each case as compared to other marks and payment methods at your points of sale. Further, you must not present any payment method or mark upstream (or at an earlier point in the checkout experience) from the presentment of any of PayPal or Venmo services or marks.
        </p>
        <p>
          In representations to your customers or in public communications, you must not mischaracterize any PayPal or Venmo services or exhibit a preference for other payment methods over PayPal or Venmo services. Within all of your points of sale, you agree not to try to dissuade or inhibit your customers from using PayPal or Venmo services or encourage the customer to use an alternate payment method. If you enable your customers to pay you with PayPal or Venmo, whenever you display or exhibit the payment methods that you accept (either within any point of sale or in your marketing materials, advertising, and other customer communications) you agree to display the PayPal or Venmo services payment marks at least as prominently, and in at least as positive a manner, as you do for all other payment methods.
        </p>

        <h3>
          Your customer service information, refunds and returns policy, and privacy policy
        </h3>
        <p>
          You must publish customer service information with clear instructions on how your customers can contact you. Such instructions must include at least: (1) one active customer service email address or active customer service URL to file tickets for assistance and/or (2) one active customer service telephone number. Such information must be readily accessible for review by your customers.
        </p>
        <p>You must publish a refunds and returns policy.</p>
        <p>You must publish a privacy policy, where required by law.</p>


        <h3>Payment review</h3>
        <p>
          PayPal reviews certain potentially high-risk transactions. If PayPal determines, in its sole discretion, that a transaction is high-risk, we place a hold on the payment and provide notice to you to delay shipping of the item. PayPal will conduct a review and either complete or cancel the payment. If the payment is completed, PayPal will provide notice to you to ship the item. Otherwise, PayPal will cancel the payment and the funds will be returned to the buyer, unless we are legally required to take other action. All payments that complete this payment review will be eligible for PayPal’s Seller Protection program if they meet <span className="pseudo-link">PayPal’s Seller Protection program</span> requirements. We will notify you about payment reviews by email and/or through your PayPal account.
        </p>


        <h3>In-store payments and QR code transactions</h3>
        <p>
          If you accept PayPal payments at your physical store, you must communicate the total amount of the transaction to the customer before it takes place. You may charge your customer’s account only for transactions that they have authorized. You must also provide customers with a physical receipt if they request one. You agree that any transaction that you make shall have an accurate and true description of the goods and services being purchased.
        </p>
        <p>
          If you use a QR code to accept payments in your physical store, you must use a QR code intended for goods and services transactions. You must also not use QR codes intended for in-person transactions as a method of accepting payment for goods and services transactions occurring online. For any buyer claims related to QR code transactions, you may be required to provide us with alternative evidence of delivery or such additional documentation or information relating to the transaction.
        </p>


        <h3>Invoicing</h3>
        <p>
          If you use Invoicing, the <span className="pseudo-link">Invoicing Terms and Conditions</span> will apply.
        </p>

        <h3>Marketplace sellers</h3>
        <p>
          If you’re a seller on a marketplace or through a third-party application where PayPal is offered, you must comply with any rules that apply to the marketplace’s or the third-party application’s buyer protection program for sales you make through that forum. Any such protections may require you to take certain actions and may impact how claims are processed.
        </p>

        <h3>PayPal Giving Fund</h3>
        <p>
          If you are a charity and hold a PayPal account, you may be eligible to participate in PayPal Giving Fund programs. PayPal Giving Fund is a Donor Advised Fund (DAF) recognized by the Internal Revenue Service (IRS) under Section 501(c)(3) of the Code as a tax-exempt public charity (Federal Tax ID: 45-0931286). If you meet the eligibility requirements in the <span className="pseudo-link"><strong>Nonprofit Certification Policy</strong></span> and are either a “Public Charity” under Section 509(a) of the Code or a &quot;Private Operating Foundation&quot; under IRS regulations, you may be automatically enrolled with PayPal Giving Fund upon acceptance of this user agreement. You will receive an email from us confirming your enrollment. Your relationship with PayPal Giving Fund will be governed by the <span className="pseudo-link"><strong>Nonprofit User Agreement</strong></span>, including receiving email communications from PayPal Giving Fund about its current and future partners about its programs. You can opt out of email communications at any time in your <span className="pseudo-link"><strong>PayPal Giving Fund profile</strong></span>. If you don’t want to be enrolled or receive donations from PayPal Giving Fund, you can opt-out at any time by <span className="pseudo-link"><strong>contacting us</strong></span>. For more information, please see <span className="pseudo-link"><strong>PayPal Giving Fund’s Policies</strong></span>.
        </p>

        <h3>Card not present transactions</h3>
        <p>
          Whenever a buyer uses a debit or credit card as the payment method for a transaction using their account to buy something from you as a seller, the transaction will be processed as a “card not present” transaction, even if the buyer is at your store location.
        </p>

        <h3>Accepting preauthorized payments</h3>
        <p>
          As a seller, you can accept payments from a buyer’s account through preauthorized transfers either on a
          one-time, regular or sporadic basis. This type of transaction is sometimes called a “billing
          agreement,” &quot;subscription,&quot; &quot;recurring payment,” “reference transaction,” &quot;pre-approved
          payment&quot; or &quot;automatic payment&quot;.
        </p>
        <table className="agreement_table">
          <tbody>
          <tr>
            <td className="-title" colSpan="2">
              If you receive preauthorized payments from buyers:
            </td>
          </tr>
          <tr>
            <td className="-th">
              <p>You must:</p>
            </td>
            <td className="-th">
              <p>You must not:</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Get each buyer’s prior authorization for the amount, frequency, and duration of any such payment.</p>
            </td>
            <td colSpan="1" rowSpan="4">
              <p>Restart future payments without the buyer’s written authorization, if a buyer has stopped or canceled a
                preauthorized payment.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Provide a simple and easily accessible online cancelation procedure, if buyers sign up for preauthorized
                payments online.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Provide buyers the ability to stop any such payment up to 3 <span
                className="pseudo-link">Business Days</span> before the date scheduled for payment.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Notify the buyer at least 10 days in advance of the amount and date of each preauthorized payment if the
                preauthorized payment will vary from the preauthorized amount or (at the buyer’s option) any payment that
                is in an amount that falls outside of the pre-determined range.</p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      {/*//////*/}

      <div>

        <h2>Transaction Fees for Online and In-Store Payments</h2>
        <p>Your PayPal transaction fees depend on:</p>
        <ul>
          <li>the country of the buyer’s account or the currency of their card;</li>
          <li>whether you are registered as a charitable organization and have active 501(c)(3) status. Your 501(c)(3) status must be documented with PayPal to receive the rates applicable to charitable organizations, and those rates will apply only after you document your status with us.</li>
        </ul>
        <h3>Standard transaction fees</h3>
        <p>The fees you pay when selling goods or services or receiving donations, and the buyer pays using their PayPal account (or using another authorized wallet), can be found on the <span className="pseudo-link">Standard Transaction Fees table</span>, with the fees applicable to charitable organizations founds on the <span className="pseudo-link">Standard Transaction Fees for Charitable Organizations</span> table for the charitable fees. Please note that:</p>
        <ul>
          <li>We may revise the fees applicable to future transactions that you process using PayPal, subject to the applicable notice requirements set forth herein.</li>
          <li>If you accept payments using a PayPal product (like <span className="pseudo-link">PayPal Payments Pro</span>), the fees applicable to those products will apply to your transactions.</li>
        </ul>

        <h3>Micropayments</h3>
        <p>
          You may apply to receive <span className="pseudo-link">Micropayments pricing</span> for certain lower value transactions processed through your PayPal account.
        </p>
        <ul>
          <li>If your PayPal account has been approved for Dynamic Micropayments (also known as Dynamic Pricing), then PayPal will automatically apply either the <span className="pseudo-link">Micropayments rate</span> or the <span className="pseudo-link">applicable higher value transaction rate</span> to each transaction processed through your account based on whichever rate results in the lowest fees to you for that transaction.</li>
          <li>If your PayPal account has previously been approved for Static Micropayments, then all such transactions processed through your account will receive the <span className="pseudo-link">Micropayments rate</span>, regardless of the transaction amount. If you have multiple PayPal accounts, but only some of them have been approved for Static Micropayments, then you are responsible for routing your transactions through the appropriate account to receive the desired pricing. Once a transaction is processed, PayPal will have no responsibility to re-route the transaction through a different account or apply different pricing. PayPal may, at its sole discretion, unilaterally convert your account from a Static Micropayments setup to the Dynamic Micropayments setup. PayPal has no obligation to offer the Static Micropayments setup as an alternative to the Dynamic Micropayments setup.</li>
        </ul>
        <p>Not all payment types are eligible for Micropayments pricing.</p>

        <h3>PayPal Payouts</h3>
        <p>If you use PayPal Payouts (formerly Mass Pay), the <span className="pseudo-link">PayPal Payouts Terms and Conditions</span> will apply.</p>

        <h3>Your responsibility to notify PayPal of pricing or fee errors</h3>
        <p>
          Once you have access to any account statement(s) or other account activity information made available to you by PayPal with respect to your business account(s), you will have sixty (60) days to notify PayPal in writing of any errors or discrepancies with respect to the pricing or other fees applied by PayPal. If you do not notify PayPal within such timeframe, you accept such information as accurate, and PayPal shall have no obligation to make any corrections, unless otherwise required by applicable law. For the purposes of this provision, such pricing or fee errors or discrepancies are different than <span className="pseudo-link">Unauthorized Transactions</span> and other electronic transfer<strong> </strong><span className="pseudo-link">Errors</span> which are each subject to different notification timeframes as set forth in this user agreement.
        </p>

      </div>

      {/*//////*/}

      <div>
        <h2>Refunds, Reversals and Chargebacks</h2>
        <h3>General information</h3>
        <p>If you receive a payment for selling goods or services that is later refunded or invalidated for any reason, you are responsible for the full amount of the payment sent to you plus any fees (including any applicable <span className="pseudo-link">chargeback fee</span> or <span className="pseudo-link">Dispute fee</span>). Whenever a transaction is refunded or otherwise reversed, PayPal will refund or reverse the transaction from your PayPal account. If a currency conversion is necessary (for example if the balance in your Balance Account or your business account for a particular currency is insufficient to cover the amount of a refund or reversal, or if the buyer paid in a different currency), PayPal will perform a currency conversion in order to refund or reverse the transaction. PayPal’s transaction exchange rate (including our <span className="pseudo-link">currency conversion spread</span>) at the time the refund or reversal is processed will be used.</p>
        <p>
          If you refund a transaction, we’ll retain the fees you paid as set out on our <span className="pseudo-link">Fees page</span>.
        </p>
        <h3>Payments that are invalidated and reversed</h3>
        <p>Payments to you may be invalidated and reversed by PayPal if:</p>
        <ul>
          <li>You lose a PayPal Purchase Protection claim submitted to us by a buyer.</li>
          <li>Y<span>ou fail to respond or provide accurate and complete information in response to PayPal&#x27;s inquiries related to a buyer’s claims or chargebacks for a particular transaction within the timeframe communicated to you by PayPal.</span></li>
          <li>You lose a Venmo Protected Purchase Program claim.</li>
          <li>Your buyer pursues a chargeback related to a card-funded transaction and the transaction is not eligible for <span className="pseudo-link">PayPal’s Seller Protection</span> program. The card issuer, not PayPal, determines whether a buyer is successful when they pursue a chargeback related to a card-funded transaction.</li>
          <li>You do not fulfill the transaction as promised or you cannot provide <span className="pseudo-link">proof of shipment or proof of delivery</span> when required.</li>
          <li>Our investigation of a bank reversal made by a buyer or the buyer’s bank finds that the transaction was fraudulent.</li>
          <li>PayPal sent the payment to you in error.</li>
          <li>The payment was unauthorized.</li>
          <li>You received the payment for activities that violated this user agreement or any other agreement between you and PayPal.</li>
        </ul>
        <p>
          When you receive a payment, you are liable to PayPal for the full amount of the payment sent to you plus any fees if the payment is later invalidated or reversed for any reason. If the buyer paid in another currency, the full amount of that payment may be calculated in that currency, using the PayPal transaction exchange rate (including our <span className="pseudo-link">currency conversion spread</span>) that applies at the time the refund or reversal is processed. If the balance in your Balance Account or business account doesn’t cover the payment amount due plus the fees, we may use any of the payment methods linked to your PayPal account to cover the amount due. If the payment methods linked to your PayPal account don’t cover the amount due, this will result in a negative balance. A negative balance represents an amount that you owe to us, and, in this situation, you must immediately add funds to your balance to resolve it. If you don’t, PayPal may:
        </p>
        <ul>
          <li>engage in collection efforts to recover the amount due from you;</li>
          <li>take any or all action as outlined under <span className="pseudo-link">Amounts owed to PayPal</span>; or</li>
          <li>place a limitation or take other action on your PayPal account as outlined under <span className="pseudo-link">Restricted Activities and Holds</span>.</li>
        </ul>

        <h3> Dispute fees</h3>
        <p>
          PayPal will charge a <span className="pseudo-link"><strong>Dispute fee</strong></span> to sellers for facilitating the online dispute resolution process for transactions that are processed either through a buyer’s PayPal account or through a PayPal Guest Checkout. The <span className="pseudo-link"><strong>Dispute fee</strong></span> applies when the buyer pursues a claim directly with PayPal, a chargeback with their card issuer, or a reversal with their bank. The <span className="pseudo-link"><strong>Dispute fee</strong></span> will be charged at either the <span className="pseudo-link"><strong>Standard Dispute fee</strong></span> rate or the <span className="pseudo-link"><strong>High Volume Dispute fee</strong></span> rate. The <span className="pseudo-link"><strong>Dispute fee</strong></span> will be charged in the currency which you selected for the original transaction listing. If the transaction was in a currency not listed in the <span className="pseudo-link"><strong>Dispute fee</strong></span> table, then the fee charged will be in your primary holding currency. The <span className="pseudo-link"><strong>Dispute fee</strong></span> will be deducted from your PayPal account after the claim is decided.
        </p>
        <p>
          The <span className="pseudo-link"><strong>Dispute fee</strong></span> amount will be determined when the dispute case is created. The fee is based on the ratio of all Item Not Received and Significantly Not as Described claims you receive compared to the total amount of your sales for the previous three calendar months. Your total claims include all Item Not Received and Significantly Not as Described claims that are filed either directly with and escalated to PayPal or with the buyer’s card issuer or bank. Your total claims do not include claims for Unauthorized Transactions. For example, for the month of September, your dispute ratio will be calculated by considering your total claims to sales ratio over June, July, and August. The claims ratio for September will determine the dispute fee for all claims filed in October. Your total claims include all claims filed directly with and escalated to PayPal, except claims for <span className="pseudo-link"><strong>Unauthorized Transactions</strong></span>; and all chargebacks from the buyer’s card issuer or reversals from the buyer’s bank.
        </p>
        <p>
          If your dispute ratio is 1.5% or more and you had more than 100 sales transactions in the previous three full calendar months, you will be charged the <strong>High Volume Dispute fee</strong> for each dispute. Otherwise, you will be charged the <strong>Standard Dispute fee</strong> for each dispute.
        </p>
        <p>
          You will <strong><u>not</u></strong> be charged a <strong>Standard Dispute fee</strong> for disputes that are:
        </p>
        <ul>
          <li>Inquiries in PayPal’s<span className="pseudo-link"><strong> Resolution Center</strong></span> that are not escalated to a claim with PayPal.</li>
          <li>Resolved directly between you and the buyer and not escalated to a claim with PayPal.</li>
          <li>Filed by the buyer directly with PayPal as an <span className="pseudo-link"><strong>Unauthorized Transaction</strong></span>.</li>
          <li>Deemed by PayPal in its sole discretion to have met all the requirements under <span className="pseudo-link">PayPal’s Seller Protection program</span>.</li>
          <li>Claims with a transaction value that is less than twice the amount of a Standard Dispute fee.</li>
          <li>Decided in your favor by PayPal or your issuer.</li>
        </ul>
        <p>You will <strong><u>not</u></strong> be charged a <strong>High Volume Dispute fee</strong> for disputes that are:</p>
        <ul>
          <li>Inquiries in PayPal’s<span className="pseudo-link"><strong> Resolution Center</strong></span> and not escalated to a claim with PayPal.</li>
          <li>Resolved directly between you and the buyer and not escalated to a claim with PayPal.</li>
          <li>Filed by the buyer directly with PayPal as an <span className="pseudo-link"><strong>Unauthorized Transaction</strong></span>.</li>
        </ul>
        <p>Sellers charged <strong>High Volume Dispute fees</strong> may be required to provide a remediation plan which includes an explanation of the cause of the increased dispute rate, the actions taken to reduce disputes, and the timelines for those actions.</p>
        <p>Disputes listed above may be excluded from being charged a <strong>Standard Dispute fee</strong> or a <strong>High Volume Dispute fee</strong>, but the claim itself may still be included in the overall calculation of your dispute ratio.</p>
        <p>If you engage in a <span className="pseudo-link"><strong>Restricted Activity</strong></span>, PayPal may charge the <span className="pseudo-link"><strong>High Volume Dispute fees</strong></span> for all current and future disputes, irrespective of your dispute ratio or sales volumes, given PayPal’s increased involvement as a result of such Restricted Activity.</p>

        <h3> Chargeback fees</h3>
        <p>For transactions that are not processed either through a buyer’s PayPal account or through a PayPal Guest Checkout, and where the buyer pursues a chargeback for the transaction with their card issuer, then PayPal will charge you a <span className="pseudo-link"><strong>Chargeback fee</strong></span> for facilitating the chargeback process. This fee with apply regardless of whether the buyer is successful in pursuing the chargeback with the card issuer.</p>
        <p><span>The applicable chargeback fee will be deducted from your PayPal account. The chargeback fee is applied as specified in the </span><span className="pseudo-link">Chargeback Fee table</span><span> and will be charged in the currency which you selected for the original transaction listing. If the transaction was in a currency not listed in the </span><span className="pseudo-link">Chargeback fee</span><span> table, the fee charged will be in your primary holding currency.</span></p>
        <h3>Impact of various purchase protection processes on sellers</h3>
        <p>You should read and understand PayPal’s Purchase Protection program, Venmo’s Protected Purchase Program, and if you sell goods and services to buyers with PayPal accounts in countries other than your own, you also should be familiar with the purchase protection available to buyers in each of those countries. Buyers’ rights under these programs may impact you as a seller. You can find information about PayPal’s protection programs on the <span className="pseudo-link">Legal Agreements</span> page by selecting your buyer’s location at the top of the page and referring to the corresponding user agreement. You can find information on Venmo’s Protected Purchase Program in <span className="pseudo-link">Venmo’s user agreement</span>.</p>
        <p>If you lose a claim under <span className="pseudo-link">PayPal’s Purchase Protection</span> program in any country, or under Venmo’s Protected Purchase Program:</p>
        <ul>
          <li>You must forfeit the full purchase price of the item plus the original shipping cost. This applies when you are the primary seller or a secondary seller of goods or services. For example, event ticketing agents or online travel agents will forfeit the full purchase amount paid by the buyer. In some cases, you may not receive the item back.</li>
          <li>The Purchase Protection claim will only be considered fully resolved if:<ul>
            <li>the refund to a buyer is processed through PayPal or Venmo, as applicable, or</li>
            <li>you provide evidence acceptable to PayPal, in its sole discretion, that the buyer agreed to the alternative resolution provided.</li>
          </ul>
          </li>
          <li>You will not receive a refund of the PayPal fees that you paid in connection with the sale.</li>
          <li>If the claim was that the item received was Significantly Not as Described, you may not receive the item back, or you may be required to accept the item back and pay for the return shipping costs.</li>
          <li>If the claim was that the item received was Significantly Not as Described and related to an item you sold that is counterfeit, you will be required to provide a full refund to the buyer and you may not receive the item back.</li>
        </ul>
      </div>

      {/*/////*/}

      <div>
        <h2>PayPal’s Seller Protection Program</h2>
        <h3>What’s eligible</h3>
        <p>
          If you sell a good or service to a buyer, you may be eligible for PayPal’s Seller Protection program. When it applies, PayPal’s Seller Protection program may result in you retaining the full purchase amount. PayPal determines whether your claim is eligible for PayPal’s Seller Protection program.
        </p>
        <p>
          The program terms and conditions are set out in <span className="pseudo-link">PayPal’s Seller Protection program</span> page and form part of this user agreement.
        </p>
      </div>

      {/*/////*/}

      <div>
        <h2>PayPal Advertising Program</h2>
        <p>
          The PayPal Advertising Program enables qualifying merchants with eligible business accounts to promote PayPal services, including PayPal’s Credit Products, through PayPal-hosted banners and buttons on merchants’ websites or in other channels if and as approved by PayPal. PayPal may change the content provided through this program at any time for any reason, and PayPal may choose to discontinue the PayPal Advertising Program at any time.
        </p>

        <table className="agreement_table">
          <tbody>
          <tr><td colSpan="2" className="-title">If you participate in this program:</td></tr>
          <tr><td className="-th">You must:</td><td className="-th">You must not:</td></tr>
          <tr>
            <td>Comply with all integration and PayPal Advertising Program requirements provided by PayPal or each authorized third party, including those with respect to accurate presentation of all PayPal related information.</td>
            <td>Modify any PayPal content or its presentation in any way.</td>
          </tr>
          <tr>
            <td>Only use the code for PayPal content, or any promotional assets as provided by PayPal.</td>
            <td>Copy PayPal content from other websites or channels or duplicate PayPal promotional assets from other merchants.</td>
          </tr>
          <tr>
            <td>Use the PayPal content in its entirety with all the links and language provided by PayPal and without modification by you.</td>
            <td>Create, display or host your own PayPal content, unless authorized by PayPal in writing.</td>
          </tr>
          <tr>
            <td>Immediately remove all PayPal content from your sites or other channels and communications and/or cooperate with PayPal or your third-party provider to do so, if you stop using a qualifying PayPal payments solution.</td>
            <td>Post or present PayPal content on social media networks or platforms or anywhere else, unless authorized by PayPal in writing.</td>
          </tr>
          <tr>
            <td>Keep all PayPal content current and up to date, including by cooperating with PayPal to manually update the PayPal content as it appears on your sites, other channels, or in your communications and repair or reinstall the code to facilitate future updates.</td>
            <td>Display any non-current PayPal content.</td>
          </tr>
          <tr>
            <td>Comply with applicable advertising laws, regulations and standards issued by governmental authorities or advertising self-regulatory bodies including but not limited to the digital advertising standards issued by the Network Advertising Initiative.</td>
            <td />
          </tr>
          </tbody></table>

        <p>
          If you promote the use of PayPal’s Credit Products on your sites, other approved channels, and/or consumer communications through the PayPal Advertising Program, there are additional requirements that <span className="pseudo-link">apply</span>.
        </p>
        <p>
          If you receive consumer inquiries relating to a Credit Product including but not limited to PayPal Credit, PayPal Cashback Mastercard, or the PayPal World Cashback Mastercard, then you must direct the consumer to the Customer Support channel associated with the Credit Product. If you receive consumer inquiries relating to PayPal or another Credit Product other than those listed above, then you must direct the consumer to <span className="pseudo-link"><strong>PayPal Customer Support</strong></span>.
        </p>
        <p>
          You are responsible for any damages suffered by PayPal or any third parties resulting from your failure to follow the rules of the PayPal Advertising Program, and if you violate these rules, PayPal may modify or remove the PayPal content you are presenting or require you to remove or modify it immediately. If you fail to follow these rules, we may limit or close your PayPal account.
        </p>
      </div>









    </div>
  );
};

export default SellingAndAccepting;