import React, { useState, useEffect } from 'react';
import { map, each, isEmpty, keys, find, trim }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ZoomImage from "../../zoom_image";
import Grid from "../grid";

const Question = ({
  question, custom, questionNotice, underTextHtml,
  selectAnswer
}) => {
  const questionText = question.text;
  const single = question.kind === 'Single';
  const multi = question.kind === 'Multi';
  const grid = question.kind === 'Grid';
  const answers = question.detailed_answers || question.answers;

  const [ radioAnswer, updateRadioAnswer ] = useState(null);
  const [ checkboxAnswers, updateCheckboxAnswers ] = useState({});
  const [ otherAnswer, setOtherAnswer ] = useState('');
  const [ gridAnswers, updateGridAnswers ] = useState({});

  const isOther = (id) => ((id || '').match(/_-2/));
  const isNone = (id) => ((id || '').match(/_-1/));
  const haveOther = find(answers, (answer) => isOther(answer.id));
  const isRadioOtherSelected = () => (isOther(radioAnswer));
  const isCheckboxOtherSelected = () => (
    find(keys(checkboxAnswers), (item) => isOther(item))
  );
  const getImage = (id) => (
    question.answer_images?.find((item) => item?.id === id)?.image_url
  );

  const defineDisabledByKind = (kind) => {
    let newDisabled;
    let otherSelected;
    let enableWhenPresent;

    if (kind === 'checkbox') {
      otherSelected = isCheckboxOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = isEmpty(checkboxAnswers) || !enableWhenPresent;
    } else if (kind === 'grid') {
      newDisabled = isEmpty(gridAnswers) || keys(gridAnswers).length < answers.length;
    } else {
      otherSelected = isRadioOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = !radioAnswer || !enableWhenPresent;
    }
    return newDisabled;
  };

  const updateOtherText = (event) => {
    setOtherAnswer(event.target.value);
  };

  const updateStateAndSend = (isRadio) => {
    if (isRadio && !grid) {
      const newDisabled = defineDisabledByKind('radio');
      const otherText = isRadioOtherSelected() && !isEmpty(otherAnswer) ?
        otherAnswer :
        null;
      selectAnswer(
        newDisabled,
        isEmpty(radioAnswer) ? null : [ radioAnswer ],
        otherText
      );
    } else if (isRadio && grid) {
      const newDisabled = defineDisabledByKind('grid');
      const values = map(keys(gridAnswers), (key) => (`${key}_${gridAnswers[key]}`));
      selectAnswer(newDisabled, values, null);
    } else {
      const newDisabled = defineDisabledByKind('checkbox');
      const otherText = isCheckboxOtherSelected() && !isEmpty(otherAnswer) ?
        otherAnswer :
        null;
      const preparedAnswers = isEmpty(checkboxAnswers) ?
        null :
        map(checkboxAnswers, (value, key) => `${key}_${value}`);
      selectAnswer(newDisabled, preparedAnswers, otherText);
    }
  };

  useEffect(() => {
    updateStateAndSend(false);
  }, [ checkboxAnswers ]);

  useEffect(() => {
    updateStateAndSend(true);
  }, [ radioAnswer ]);

  useEffect(() => {
    updateStateAndSend(!multi);
  }, [ otherAnswer ]);

  useEffect(() => {
    updateStateAndSend(true);
  }, [ gridAnswers ]);

  const updateCheckbox = (value) => {
    updateCheckboxAnswers({ ...value });
  };
  const updateRadio = (value) => {
    updateRadioAnswer(value);
  };

  const radioChange = (value) => {
    updateRadio(value);
  };
  const checkboxChange = (value, answerId) => {
    if (value) {
      if (isNone(answerId)) {
        each(checkboxAnswers, (v, k) => {
          delete checkboxAnswers[k];
        });
      }
      checkboxAnswers[answerId] = value;
    } else {
      delete checkboxAnswers[answerId];
    }
    updateCheckbox(checkboxAnswers);
  };

  const disableCheckboxItem = (answerId) => {
    if (isNone(answerId)) {
      return false;
    }
    return !!find(checkboxAnswers, (v, k) => {
      return isNone(k);
    });
  };

  const singleOutput = () => (
    <ul
      className={
        classnames(
          "survey-options_list",
          { "-full-width": haveOther }
        )
      }
    >
      {
        map(answers, (answer) => (
          <li
            key={ answer.id }
            className={
              classnames({
                'option-top-border': isNone(answer.id)
              })
            }
          >
            {
              !getImage(answer.id) &&
              <label
                className={
                  classnames(
                    "radio",
                    { "-full-width": isOther(answer.id) }
                  )
                }
              >
                <input
                  type="radio"
                  name={ question.name }
                  value={ answer.id }
                  checked={ answer.id === radioAnswer }
                  onChange={ () => { radioChange(answer.id); } }
                />
                {
                  isOther(answer.id) &&
                  <div
                    className={
                      classnames(
                        "radio_label -centered",
                        { "-full-width": isOther(answer.id) }
                      )
                    }
                  >
                    <div className="other-text_wrapper">
                      <div className="other-text_title" { ...renderRawHtml(`${answer.label}:`) } />
                      <input
                        type="text"
                        className="js-other-text form-field -other-text"
                        // disabled={ !radioOptionIsChecked(answer.id) }
                        onFocus={ () => { radioChange(answer.id); } }
                        value={ otherAnswer }
                        onChange={ updateOtherText }
                      />
                    </div>
                  </div>
                }
                {
                  !isOther(answer.id) &&
                  <div
                    className="radio_label"
                    { ...renderRawHtml(answer.label) }
                  />
                }
              </label>
            }
            {
              getImage(answer.id) &&
              <div className="radio-plate -base" >
                <div className="radio-plate_part -no-padding -right-margin -clickable">
                  <label className="radio">
                    <input
                      type="radio"
                      name="concept"
                      value={ answer.id }
                      checked={ answer.id === radioAnswer }
                      onChange={ () => { radioChange(answer.id); } }
                    />
                    <div className="radio_label -no-text" />
                  </label>
                </div>
                <div className="radio-plate_part-image">
                  <div
                    { ...renderRawHtml(answer.label) }
                  />
                  <div className="survey-options_image-plate -fix-width">
                    <ZoomImage
                      className="radio-plate_part-image"
                      popupClassName="-custom-image-in-modal"
                      src={ getImage(answer.id) }
                    >
                      <img src={ getImage(answer.id) } alt="image" />
                    </ZoomImage>
                  </div>
                </div>
              </div>
            }
          </li>
        ))
      }
    </ul>
  );

  const multiOutput = () => (
    <ul
      className={
        classnames(
          "survey-options_list",
          { "-full-width": haveOther }
        )
      }
    >
      {
        map(answers, (answer) => (
          <li
            key={ answer.id }
            className={
              classnames({
                'option-top-border': isNone(answer.id)
              })
            }
          >
            <div className="option-list-inner">
              {
                !getImage(answer.id) &&
                <label
                  className={
                    classnames(
                      "checkbox",
                      { "-full-width": isOther(answer.id) }
                    )
                  }
                >
                  <input
                    type="checkbox"
                    disabled={ disableCheckboxItem(answer.id) }
                    checked={ !!checkboxAnswers[answer.id] }
                    onChange={ (event) => { checkboxChange(event.target.checked, answer.id); } }
                  />
                  {
                    isOther(answer.id) &&
                    <div
                      className={
                        classnames(
                          "checkbox_label -centered",
                          { "-full-width": isOther(answer.id) }
                        )
                      }
                    >
                      <div className="other-text_wrapper">
                        <div className="other-text_title" { ...renderRawHtml(`${answer.label}:`) } />
                        <input
                          type="text"
                          className="js-other-text form-field -other-text"
                          onFocus={ () => { checkboxChange(true, answer.id); }  }
                          value={ otherAnswer }
                          disabled={ disableCheckboxItem(answer.id) }
                          onChange={ updateOtherText }
                        />
                      </div>
                    </div>
                  }
                  {
                    !isOther(answer.id) &&
                    <div
                      className="checkbox_label"
                      { ...renderRawHtml(answer.label) }
                    />
                  }
                </label>
              }
              {
                getImage(answer.id) &&
                <div className="radio-plate -base" >
                  <div className="radio-plate_part -no-padding -clickable">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        disabled={ disableCheckboxItem(answer.id) }
                        checked={ !!checkboxAnswers[answer.id] }
                        onChange={ (event) => { checkboxChange(event.target.checked, answer.id); } }
                      />
                      <div className="checkbox_label -no-text" />
                    </label>
                  </div>
                  <div className="radio-plate_part-image">
                    <div
                      { ...renderRawHtml(answer.label) }
                    />
                    <div className="survey-options_image-plate -fix-width">
                      <ZoomImage
                        className="radio-plate_part-image"
                        popupClassName="-custom-image-in-modal"
                        src={ getImage(answer.id) }
                      >
                        <img src={ getImage(answer.id) } alt="image" />
                      </ZoomImage>
                    </div>
                  </div>
                </div>
              }
            </div>
          </li>
        ))
      }
    </ul>
  );

  return (
    <>
      <div className="survey-options_question -no-top-margin">
        {
          custom &&
          <div
            className={
              classnames(
                "survey-options_question-title -less-margin",
                { "custom-title -screening -left-aligned": custom }
              )
            }
            { ...renderRawHtml(questionText) }
          />
        }
        {
          !custom &&
          <>
            <div
              className={
                classnames(
                  "survey-options_question-title -less-margin",
                  { '-no-margin': questionNotice }
                )
              }
              { ...renderRawHtml(questionText) }
            />
            { underTextHtml }
            {
              questionNotice &&
              <div className="survey-options_question-title -sub" { ...renderRawHtml(questionNotice) } />
            }
          </>
        }
      </div>
      <div className="survey-layout_container -custom-metric">
        <div className={ classnames("survey-options", { "-custom-metric": !grid, "-grid": grid }) }>
          { single && singleOutput() }
          { multi && multiOutput() }
          {
            grid &&
            <Grid
              alwaysMobileView
              question={ question }
              gridAnswers={ gridAnswers }
              updateGridAnswers={ updateGridAnswers }
              updateStateAndSend={ updateStateAndSend }
            />
          }
        </div>
      </div>
    </>
  );
};

export default Question;
