import React, { useEffect, useRef, useContext } from 'react';
import classnames from 'classnames';
import { useActive, getClientCoordinate, noop } from '../helper';
import { OptionsContext } from './contexts';

const Rect = ({
  width, height, left, top, areaOptions,
  onPositionChange,
  onStartPositionChange, onEndPositionChange,
  higherIndex,
  onMouseEnter, onMouseLeave
}) => {
  const ref = useRef(null);
  const { active, setActive, setInactive } = useActive();
  const styles = {
    width,
    height,
    top,
    left,
    zIndex: (higherIndex ? 3 : 1)
  };
  const updatePosition = (event) => {
    if (!active) {
      return;
    }
    onPositionChange(getClientCoordinate(event));
  };

  const {
    getProductName, viewOnly, rectHoverClassName,
    rectClassName
  }  = useContext(OptionsContext);

  const activate = (event) => {
    if (active) {
      return;
    }
    setActive();
    onStartPositionChange(getClientCoordinate(event));
  };

  const deactivate = (event) => {
    if (!active) {
      return;
    }
    setInactive();
    onEndPositionChange(getClientCoordinate(event));
  };

  useEffect(() => {
    const currentEl = ref.current;
    if (!viewOnly) {
      currentEl.addEventListener('mousedown', activate);
      currentEl.addEventListener('touchstart', activate);
      document.body.addEventListener('mouseup', deactivate);
      document.body.addEventListener('touchend', deactivate);
      document.body.addEventListener('mousemove', updatePosition);
      document.body.addEventListener('touchmove', updatePosition);
    }
    return () => {
      if (!viewOnly) {
        currentEl.removeEventListener('mousedown', activate);
        currentEl.removeEventListener('touchstart', activate);
        document.body.removeEventListener('mouseup', deactivate);
        document.body.removeEventListener('touchend', deactivate);
        document.body.removeEventListener('mousemove', updatePosition);
        document.body.removeEventListener('touchmove', updatePosition);
      }
    };
  }, [ updatePosition, activate, deactivate ]);

  return (
    <div
      ref={ ref }
      style={ styles }
      className={
        classnames(
          "products-greed-item-image-rect",
          rectClassName,
          { [rectHoverClassName]: rectHoverClassName && higherIndex },
          { "-active": active },
          { "-view-only": viewOnly }
        )
      }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
      onContextMenu={ noop }
    >
      {
        getProductName &&
        <div className="products-greed-item-image-rect-name">
          { getProductName(areaOptions) }
        </div>
      }
    </div>
  );
};

export default Rect;
