import React, { useEffect, useState, useRef } from "react";
import classnames from 'classnames';
import { find, map, round, sum } from 'lodash';
import renderRawHtml from '../../../../../../../common/render_raw_html';
import FooterButton from '../../../footer_button';
import useTransform from '../common/use_transform';
import { LocalizationContext, TransformContext } from '../common/contexts';
import ScaleControls from '../common/components/scale_controls';
import ProductsOnShelf from '../common/components/products_on_shelf';
import CartManagement from './components/cart_management';
import { CartContext } from './contexts';

// products should be [{ id, name, price, target, width, height, top, left, imageWidth, imageHeight }]
const ShelfCart = ({
  result, concept, shelfUrl, products, productsUniq, nextStep,
  viewOnly, withPrices, budgetLimit,
  // copy
  viewOnlyTask, noBudgetTask, withBudgetTask,
  buttonEmptyCart, cartEmptyContent, cartContentTitle,
  cartOneItemName, cartManyItemsName, currency,
  maxBudgetTitle, cartTotalTitle, customEnlarge, productDelete,
  cartComplete
}) => {
  const [ startTime, setStartTime ] = useState(0);
  const minScale = 1;
  const [ scale, setScale ] = useState(minScale);
  const [ contentWrapperStyles, setContentWrapperStyles ] = useState({});
  const [ wrapperStyles, setWrapperStyles ] = useState({});
  const [ shelfPosition, setShelfPosition ] = useState({});
  const [ areaPositions, setAreaPositions ] = useState([]);
  const [ cartItems, setCartItems ] = useState([]);
  const [ showCartPopup, setShowCartPopup ] = useState(false);
  const contentRef = useRef(null);
  const shelfWrapperRef = useRef(null);
  const shelfRef = useRef(null);
  const transform = useTransform({
    dataAreas: products,
    min: minScale, max: 2, step: 0.1,
    getScale: () => (scale), setScale,
    getShelfPosition: () => (shelfPosition), setShelfPosition,
    getAreaPositions: () => (areaPositions), setAreaPositions,
    getContentWrapperStyles: () => (contentWrapperStyles), setContentWrapperStyles,
    getWrapperStyles: () => (wrapperStyles), setWrapperStyles,
    contentRef, shelfWrapperRef, shelfRef
  });

  let task = "";
  if (viewOnly) {
    task = viewOnlyTask;
  } else {
    task = withPrices && budgetLimit ? withBudgetTask : noBudgetTask;
  }

  const localization = {
    buttonEmptyCart,
    cartEmptyContent,
    cartContentTitle,
    cartOneItemName,
    cartManyItemsName,
    currency,
    maxBudgetTitle,
    cartTotalTitle,
    customEnlarge,
    productDelete,
    cartComplete,
    task
  };

  const itemsNum = () => (sum(map(cartItems, (cartItem) => cartItem.amount)));


  const itemsSum = () => {
    if (withPrices) {
      return sum(
        map(
          cartItems,
          (cartItem) => round(cartItem.amount * cartItem.price, 2)
        )
      );
    }
    return 0;
  };

  const notFitBudget = () => (
    budgetLimit ? (itemsSum() > budgetLimit) : false
  );

  const handleNext = () => {
    if (!viewOnly) {
      if (!result.express_cart_exercise_results) {
        result.express_cart_exercise_results = [];
      }
      result.express_cart_exercise_results.push({
        concept_id: concept.id,
        time_spent: Math.ceil(((new Date).getTime() - startTime) / 1000),
        express_cart_exercise_interacted_attributes: map(productsUniq, (product) => {
          const cartItem = find(cartItems, (cartItem) => product.id === cartItem.id);
          return {
            product_id: product.id,
            amount: cartItem?.amount || 0,
            position: cartItem ? (cartItems.indexOf(cartItem) + 1) : 0,
            selected: !!cartItem,
            price_item: cartItem?.price
          };
        })
      });
    }
    nextStep();
  };

  const handleProductClick = (event, product) => {
    event.preventDefault();
    event.stopPropagation();
    const existing = find(cartItems, (item) => item.id === product.id);
    const newItems = [];
    if (existing) {
      existing.amount += 1;
    } else {
      newItems.push({
        id: product.id,
        url: product.url,
        name: product.name,
        price: product.price,
        amount: 1
      });
    }
    setCartItems([ ...cartItems, ...newItems ]);
  };


  useEffect(() => {
    setStartTime((new Date).getTime());
  }, []);

  const nextDisabled = () => {
    if (viewOnly) {
      return false;
    }
    return (!cartItems.length || notFitBudget());
  };

  const cartOptions = {
    getSelectedProducts: () => (cartItems),
    updateSelectedProducts: setCartItems,
    getShowPopup: () => (showCartPopup),
    setShowPopup: setShowCartPopup,
    itemsNum,
    itemsSum,
    notFitBudget,
    withPrices,
    budgetLimit,
    nextDisabled,
    handleNext
  };

  return (
    <>
      <CartContext.Provider value={ cartOptions }>
        <LocalizationContext.Provider value={ localization }>
          <TransformContext.Provider value={ transform }>
            <div className="survey-header disable-zoom" style={ { touchAction: 'manipulation' } }>
              <div
                className="survey-header_title"
                { ...renderRawHtml(localization.task) }
              />
            </div>
            <div className="survey-layout_content -with-overflow" ref={ contentRef }>
              <ProductsOnShelf
                productPointer
                viewOnly={ viewOnly }
                shelfRef={ shelfRef }
                shelfWrapperRef={ shelfWrapperRef }
                shelfUrl={ shelfUrl }
                onProductClick={ handleProductClick }
              />
            </div>
            <div
              className={
                classnames(
                  "survey-footer -zoom-middle disable-zoom",
                  { "-items-space-between": !viewOnly }
                )
              }
              style={ { touchAction: "manipulation" } }
            >
              <ScaleControls
                withWrapper={ viewOnly }
                className={ classnames({ "-width-140": !viewOnly }) }
              />
              {
                !viewOnly && <CartManagement />
              }
              <FooterButton
                className={
                  classnames(
                    "-short",
                    { "-with-dynamic-width-140-to-60": !viewOnly },
                    { "-invisible": !viewOnly }
                  )
                }
                options={ { disabled: !viewOnly } }
                nextStep={ handleNext }
              />
            </div>
          </TransformContext.Provider>
        </LocalizationContext.Provider>
      </CartContext.Provider>
    </>
  );
};

export default ShelfCart;
