import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import Notification from "../components/notification";
import RadioPlate from "../components/radioPlate";
import { GlobalContext } from '../contexts/appContexts';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";

const PPECashbackCategory = ({ headerStep, numberOfHeaderSteps }) => {
  const { fakeData, nextStep, skipStep, mergeData } = useContext(GlobalContext);
  const [ selectedCategory, setSelectedCategory ] = useState(null);

  const categoryUpdate = (value) => {
    setSelectedCategory(value);
  };

  const onNext = () => {
    mergeData({
      cashbackCategory: selectedCategory,
      cashbackCategoryChosen: true,
      cashbackCategorySkip: false
    });
    nextStep();
  };

  const onSkip = () => {
    mergeData({
      cashbackCategory: null,
      cashbackCategoryChosen: false,
      cashbackCategorySkip: true
    });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      <button className="button -full-width" onClick={ () => { onNext(); } }>
        Next
      </button>
      <button className="button -link -full-width" onClick={ () => { onSkip(); } }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  return (
    <Layout header={ header } footer={ footer }>
      <Notification status="success" delay={ 3000 } icon="checked-circle">
        Your Balance Account and PayPal Debit Card are ready!
      </Notification>
      <div className="ppe-cashback-category">
        <div className="content_title">
          <h1>Choose your 5% cash back category</h1>
        </div>
        <div className="content_text">
          Earn 5% back on up to $1,000 spent monthly with PayPal Debit. Set it each month to keep the cash back coming.
        </div>

        <ul className="select-category">
          {
            map(fakeData.cashBackCategoriesListShort, (item, index) => (
              <li key={ index } className="select-category_item">
                <RadioPlate
                  name="cashback-cat"
                  item={ item }
                  radioChange={ categoryUpdate }
                  iconPrefix="cat"
                />
              </li>
            ))
          }
        </ul>
      </div>
    </Layout>
  );
};

export default PPECashbackCategory;


