import React, {useContext} from 'react';
import SQAGridColumns from "../../../common/custom_questions/components/SQAGridColumns";
import SQAHeader from "../../../common/custom_questions/components/SQAHeader";
import SQAGridRows from "../../../common/custom_questions/components/SQAGridRows";
import { QuestionContext } from "../contexts";
import QAList from "./QAList";
import QAHeader from "./QAHeader";

const QuestionAnswers = ({ options, question, questions, setQuestions, questionIndex }) => {
  const { setManageFivePtToggle, setFivePtScaleMode } = useContext(QuestionContext);
  return (
    <div className="form_section">
      <QAHeader
        options={ options }
        question={ question }
        questions={ questions }
        setQuestions={ setQuestions }
      />

      {
        question.kind === "Grid" &&
        <>
          <SQAGridColumns
            options={ options }
            question={ question }
            screenerQuestions={ questions }
            setScreenerQuestions={ setQuestions }
            questionIndex={ questionIndex }
            setManageFivePtToggleProp={ setManageFivePtToggle }
            setFivePtScaleModeProp={ setFivePtScaleMode }
          />
          <SQAHeader
            title="Rows"
            options={ options }
            question={ question }
            screenerQuestions={ questions }
            setScreenerQuestions={ setQuestions }
          />
          <SQAGridRows
            options={ options }
            question={ question }
            screenerQuestions={ questions }
            setScreenerQuestions={ setQuestions }
            questionIndex={ questionIndex }
          />
        </>
      }

      {
        question.kind !== "Grid" &&
        <QAList
          options={ options }
          question={ question }
          questions={ questions }
          setQuestions={ setQuestions }
          questionIndex={ questionIndex }
        />
      }
    </div>
  );
};

export default QuestionAnswers;
