import React, {useContext, useState} from "react";
import CreatableSelect from "react-select/creatable";
import {KeywordsData} from "./index";

const KeywordsListEditor = ({keyword, cancelEdit}) => {
  const {getChildrenOptions, updateKeywordText} = useContext(KeywordsData)
  const [defaultOption, options] = getChildrenOptions(keyword)
  const [selected, setSelected] = useState(defaultOption)

  const handleChange = (newValue)=>{
    setSelected(newValue)
  }

  return (
    <div className="keyword_edit">
      <div className="keyword_edit-inplace">
        <CreatableSelect defaultValue={selected}
                 options={options}
                 formatCreateLabel={(v)=>(`Use "${v}"`)}
                 onChange={handleChange}/>
      </div>
      <div className="keyword_edit-control">
        <button className="button" onClick={()=>{ updateKeywordText(keyword, selected.value, cancelEdit) }}>Save</button>
        <button className="button -outline" onClick={cancelEdit}>Cancel</button>
      </div>
    </div>
  )
}

export default KeywordsListEditor