import React, { useState } from 'react';
import { map, keys, sumBy, filter, includes, find } from 'lodash';
import renderRawHtml from './render_raw_html';
import { Header } from './header';
import {
  setCurrentLine,
  isLinesFilled,
  createLine,
  getLinesCount,
  removeHashLine,
  setLinesCount,
  getSelectedPlans,
  getTotal,
  getHashData,
  setLinePrice,
  getSelectedPlanIdByLine,
  getCurrentLineHash,
  increaseVariable
} from './statistics';

const NoPlan = ({ index, selectPlan, handleRemove }) => {
  return (
    <li className="order_line">
      <div className="order_line-number">Line {index + 1}</div>
      <div className="order_line-grid -empty">
        <div className="order_line-grid-item">
          <div className="order_line-placeholder">
            No plan selected
          </div>
        </div>
        <div className="order_line-grid-item">
          <button
            className="button -bordered -sm"
            onClick={ () => { selectPlan(index + 1); } }
          >
            Select plan
          </button>
        </div>
      </div>
      {
        !!index &&
        <div className="order_line-footer -border">
          <button
            className="button-link -sm"
            onClick={ () => { handleRemove(index + 1); } }
          >
            Remove line
          </button>
        </div>
      }
    </li>
  );
};

const SelectedPlan = ({
  plan, perks, index, changePlan, handleRemove, includedPerksText
}) => {
  const { planPerkIds = [], planAdditionalPerkIds = [] } = getHashData(`lines.${index + 1}`);
  const selectedPerks = map(planPerkIds, (id) => find(perks, (perk) => perk.id === id));
  const additionalPerks = map(planAdditionalPerkIds, (id) => find(perks, (perk) => perk.id === id));
  return (
    <li className="order_line">
      <div className="order_line-number">Line {index + 1}</div>
      <div className="order_line-grid">
        <div className="order_line-grid-item">
          <h3 className="order_line-title" { ...renderRawHtml(plan.title) } />
        </div>
        <div className="order_line-grid-item">
          <div className="order_line-price"><b>${plan.price}</b>/mo</div>
        </div>
      </div>
      <div className="order_line-perks">
        {
          map(selectedPerks, (perk) => (
            <div key={ perk.title }>
              { perk.orderTitle ? perk.orderTitle : perk.title }
              { includedPerksText ? ` ${includedPerksText}` : '' }
            </div>)
          )
        }
        {
          map(additionalPerks, (perk) => (
            <div key={ perk.title }>{ perk.orderTitle ? perk.orderTitle : perk.title }</div>)
          )
        }
      </div>
      <div className="order_line-footer">
        <button
          className="button-link -sm"
          onClick={ () => { changePlan(index + 1); } }
        >
          Change plan
        </button>
      </div>
      {
        !!index &&
        <div className="order_line-footer -border -with-change-plan">
          <button
            className="button-link -sm"
            onClick={ () => { handleRemove(index + 1); } }
          >
            Remove line
          </button>
        </div>
      }
    </li>
  );
};

const Order = ({
  nextStep, perks, plans, reselectPlan, perkPrice = 0,
  additionalPerkPrice = 0, usePerksPriceValue = false,
  customTitle, includedPerksText
}) => {
  const isFinal = isLinesFilled();
  const selectedPlans = getSelectedPlans();
  const total = getTotal();
  const [ counter, setCounter ] = useState(0);
  const rerender = () => setCounter(counter + 1);

  const selectPlan = (clickedLine) => {
    setCurrentLine(clickedLine);
    reselectPlan(clickedLine);
  };

  const changePlan = (clickedLine) => {
    increaseVariable("planChangeClickedCount", clickedLine);
    increaseVariable("changePlanClickedCount");
    reselectPlan(clickedLine, true);
  };

  const updatePlanPrices = () => {
    const lines = getHashData('lines');
    keys(lines).forEach((line) => {
      const planId = getSelectedPlanIdByLine(line);
      const selectedPlan = find(plans, (el) => el.id === planId);
      const { planPerkIds = [], planAdditionalPerkIds = [] } = getHashData(`lines.${line}`);
      if (selectedPlan) {
        const planPrice = selectedPlan.price;
        if (usePerksPriceValue) {
          const perksArray = filter(perks, (item) => includes(planPerkIds, item.id));
          setLinePrice(planPrice + sumBy(perksArray, (perk) => perk.priceValue || 0), line);
        } else {
          setLinePrice(
            planPrice +
            (planPerkIds.length * perkPrice) +
            (planAdditionalPerkIds.length * additionalPerkPrice),
            line
          );
        }
      }
    });
  };

  const changeLinesStatisticsIncrease = () => {
    increaseVariable("changeLinesClickedCount");
  };

  const handleAddLine = () => {
    const count = getLinesCount();
    const { planId, planTitle } = getCurrentLineHash(1);
    let plan;
    if (planId === 5) {
      plan = { id: planId, title: planTitle };
    }
    const lineId = count + 1;
    createLine(lineId, plan);
    setLinesCount(lineId);
    updatePlanPrices();
    changeLinesStatisticsIncrease();
    rerender();
  };

  const handleRemove = (index) => {
    removeHashLine(index);
    updatePlanPrices();
    changeLinesStatisticsIncrease();
    rerender();
  };

  const handleClearLines = () => {
    const lines = getHashData('lines');
    keys(lines).forEach((line) => {
      createLine(line);
    });
    increaseVariable("clearCartClickedCount");
    rerender();
  };

  return (
    <div className="content -flex-vert">
      <Header />
      <div className="order">
        <div className="inner-content -percent-desktop">
          <h2 className="order_title">
            { !!customTitle && customTitle }
            { !customTitle && <>Now, select a plan for each line.</> }
          </h2>
          <div className="order_grid">
            <div className="order_grid-item -lines">
              <div className="order_lines">
                <ul className="order_lines-list">
                  {
                    map(selectedPlans, (plan, index) => (
                      plan?.id
                        ? <SelectedPlan
                          key={ index }
                          perks={ perks }
                          plan={ plan }
                          index={ index }
                          changePlan={ changePlan }
                          handleRemove={ handleRemove }
                          includedPerksText={ includedPerksText }
                        />
                        : <NoPlan
                          key={ index }
                          index={ index }
                          selectPlan={ selectPlan }
                          handleRemove={ handleRemove }
                        />
                    ))
                  }
                </ul>
                <div className="order_lines-button">
                  <button
                    className="button -bordered"
                    disabled={ getLinesCount() === 5 }
                    onClick={ handleAddLine }
                  >
                    Add a line
                  </button>
                </div>
              </div>
            </div>
            <div className="order_grid-item -summary">
              <div className="order_summary">
                <h3 className="order_summary-title">Order summary</h3>
                <div className="order_summary-grid">
                  <div className="order_summary-grid-item">
                    <div className="order_summary-text">Due monthly</div>
                  </div>
                  <div className="order_summary-grid-item">
                    <div className="order_summary-text">
                      {total > 0 ? `$${total}.00` : '—'}
                    </div>
                  </div>
                </div>
                <div className="order_summary-footer">
                  <button
                    className="button order_summary-button"
                    onClick={ () => {
                      nextStep();
                    } }
                    disabled={ !isFinal }
                  >
                    Proceed to checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="order_footer">
        <div className="inner-content -percent-desktop">
          <div className="order_footer-col">
            <div className="order_footer-text">Need to start over?</div>
            <div className="order_footer-button">
              <button
                className="button-link"
                onClick={ handleClearLines }
              >
                Clear cart
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Order;
