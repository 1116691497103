import React, { useState, useEffect, useRef, useContext } from 'react';
import cn from "classnames";
import { each } from "lodash";
import SvgIcon from '../../../../../common/components/svg-icon';
import Wysiwyg from '../../../../../common/components/wysiwyg';
import ToggleManageQuota from '../../toggle_manage_quota';
import {
  calculateGridQuota,
  calculateQuotaForCustomScreening,
  findConditionItem,
  findGridConditionItem,
  firstNotDeletedIndex,
  reorderQuestions
} from '../helper';
import { QuestionContext, QuestionsContext } from '../contexts';
import QuotaModeLocalState from '../../quotaMode/components/quotaModeLocalState';
import QuotaModeGlobalState from '../../quotaMode/components/quotaModeGlobalState';
import EditScreenerQuota from './EditScreenerQuota';
import ScreeningQuestionAnswers from "./ScreeningQuestionAnswers";
import OpenEnd from './openEnd';

const ScreeningQuestion = ({
  options, question, screenerQuestions,
  setScreenerQuestions, questionIndex,
  needQuota, needLogicEdit, needOther, needNone,
  needFivePtScaleToggle, needDedicatedPage,
  gridData, needRows, images, withImages, deleteButtonModifier, needLocation
}) => {
  const manageFivePtToggleCondition = () => (
    (question.answers.length >= 5 && question.kind === 'Single') ||
    (question.kind === 'Grid' && question.scales.length === 5)
  );
  const fivePtScaleCheck = () => (question.as_point_scale);

  const { allowReasons } = useContext(QuestionsContext);

  const [ selectIsOpened, setSelectIsOpened ] = useState(false);
  const [ manageFivePtToggle, setManageFivePtToggle ] = useState(manageFivePtToggleCondition);
  const [ fivePtScaleMode, setFivePtScaleMode ] = useState(fivePtScaleCheck);
  question.toggleManageQuota = needQuota;
  question.availableToManageQuotas = needQuota;
  question.uiTitle = () => ('Screener Question');

  const hasErrorKind = question.errors && question.errors.kind;
  const hasErrorText = question.errors && question.errors.text;

  const kindName = (name) => {
    return options.kinds[name];
  };

  const onSelectClick = () => {
    if (fivePtScaleMode) {
      return;
    }
    setSelectIsOpened(!selectIsOpened);
  };

  const selectOption = (event, name) => {
    event.preventDefault();
    event.stopPropagation();

    const previousKind = question.kind;
    question.kind = name;
    if (name === 'Grid') {
      if (!question.scales.length) {
        question.scales = [ "" ];
      }
      question.condition_list = [];
      each(question.answers, (answer, index) => {
        findGridConditionItem(question, index);
      });
      if (question.manage_quota) {
        calculateGridQuota(question);
      }
    }
    if (previousKind === "Grid") {
      question.condition_list = [];
      each(question.answers, (answer, index) => {
        findConditionItem(question, index);
      });
    }
    if (name !== "Grid" && question.manage_quota) {
      calculateQuotaForCustomScreening(question);
    }

    updateManageFivePtToggle();
    setScreenerQuestions([ ...screenerQuestions ]);
    setSelectIsOpened(false);
  };

  const updateManageFivePtToggle = () => {
    if (manageFivePtToggleCondition()) {
      setManageFivePtToggle(true);
    } else {
      setManageFivePtToggle(false);
    }
  };

  const delQuestion = (event) => {
    event.preventDefault();
    question._destroy = true;
    reorderQuestions(screenerQuestions);
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeText = (newHtml) => {
    question.text = newHtml;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSelectIsOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ ref ]);

  const handleQuestionChange = (event, q) => {
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onManageQuotaUpdate = (event, q) => {
    if (q.manage_quota) {
      if (q.kind === "Grid") {
        calculateGridQuota(q);
      } else {
        calculateQuotaForCustomScreening(q);
      }
    }
    handleQuestionChange(event, q);
  };

  const contextValue = {
    needLogicEdit, needNone, needOther, manageFivePtToggle,
    setManageFivePtToggle, fivePtScaleMode, setFivePtScaleMode,
    needFivePtScaleToggle, updateManageFivePtToggle, needDedicatedPage,
    gridData, needRows, images, withImages
  };

  return (
    <QuotaModeGlobalState question={ question } options={ options }>
      <QuotaModeLocalState question={ question }>
        <QuestionContext.Provider value={ contextValue }>
          <div
            className={
              cn({
                'form-question -screening': !question._destroy,
                'destroyed-question': question._destroy,
                '-first-actual': firstNotDeletedIndex(question, questionIndex, screenerQuestions)
              })
            }
          >
            <div className="form_section">
              <div className="form_section-line">
                <ToggleManageQuota
                  question={ question }
                  onUpdate={ onManageQuotaUpdate }
                >
                  <EditScreenerQuota
                    question={ question }
                    linkTitle={ 'Edit Quotas' }
                    title={ 'Quotas' }
                    onUpdate={ handleQuestionChange }
                    options={ options }
                    showCross
                  />
                </ToggleManageQuota>
                <div className="form_grid -no-top-margin">
                  <div
                    className={
                      cn(
                        "form_grid-item -width-full -relative -multi-elements",
                        { 'has-error': hasErrorKind }
                      )
                    }
                  >
                    <div
                      className={
                        cn(
                          "form-field -block -select",
                          {
                            '-not-selected': !question.kind || fivePtScaleMode,
                            '-opened': selectIsOpened,
                            '-disabled': fivePtScaleMode
                          }
                        )
                      }
                      onClick={ onSelectClick }
                    >
                      {!question.kind && <span>Select Question Type</span>}
                      <span>{ kindName(question.kind) }</span>
                    </div>

                    {
                      selectIsOpened &&
                      <div
                        ref={ ref }
                        className="form_grid-item -width-2-3 -select-options"
                      >
                        <div className="form-question_type">
                          <div
                            className="form-question_type-col -first"
                            onClick={ (event) => {selectOption(event, 'Single');} }
                          >
                            <div className="form-question_type-title">
                              { kindName('Single') }
                            </div>
                            <div className="form-question_type-text">
                              respondents may only select one answer
                            </div>
                          </div>

                          <div className="form-question_type-divider">
                            or
                          </div>

                          <div
                            className="form-question_type-col"
                            onClick={ (event) => {selectOption(event, 'Multi');} }
                          >
                            <div className="form-question_type-title">
                              { kindName('Multi') }
                            </div>
                            <div className="form-question_type-text">
                              respondents may select more than one answer
                            </div>
                          </div>

                          <div className="form-question_type-divider">
                            or
                          </div>

                          <div
                            className="form-question_type-col"
                            onClick={ (event) => {selectOption(event, 'Grid');} }
                          >
                            <div className="form-question_type-title">
                              { kindName('Grid') }
                            </div>
                            <div className="form-question_type-text">
                              respondents will answer per each grid row
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  <div className="form_grid-item -multi-elements">
                    <button
                      className={ `button -secondary -white-back ${deleteButtonModifier ? deleteButtonModifier : ''}` }
                      onClick={ delQuestion }
                    >
                      <SvgIcon name="i-delete" className="button_icon -delete" />
                      <span className="button_text">
                        Delete
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className={ cn("form_section-line", { 'has-error': hasErrorText }) }>
                <Wysiwyg
                  customPicker
                  toolbarClassName="wysiwyg-menu"
                  editorClassName="wysiwyg-textarea"
                  placeholder="Add"
                  enableIndent
                  value={ question.text }
                  onChange={ onChangeText }
                />
              </div>
            </div>
            <ScreeningQuestionAnswers
              options={ options }
              question={ question }
              screenerQuestions={ screenerQuestions }
              setScreenerQuestions={ setScreenerQuestions }
              questionIndex={ questionIndex }
              needLocation={ needLocation }
            />
            <OpenEnd
              allowReasons={ allowReasons }
              question={ question }
              questions={ screenerQuestions }
              setQuestions={ setScreenerQuestions }
            />
          </div>
        </QuestionContext.Provider>
      </QuotaModeLocalState>
    </QuotaModeGlobalState>
  );
};

export default ScreeningQuestion;
