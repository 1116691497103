import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import CurrencyInput from 'react-currency-input-field';
import { formatCurrency } from '../helpers/currencyHelper';

const InlineEdit = ({
  prefix = '$', value, setValue, className, children, onEdit,
  minValue, minValueText, maxValue, maxValueText
}) => {
  const [ editing, setEditing ] = useState(false);
  const [ error, setError ] = useState(null);
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onEdit && onEdit();
    setEditing(true);
  };

  const inputRef = useRef(null);

  const handleOnBlur = () => {
    setEditing(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.onblur = () => {
        handleOnBlur();
      };
    }
  }, [ editing ]);

  const handleChangeValue = (value) => {
    const newValue = parseInt(value || 0);
    let newError = null;
    if (minValue && minValueText) {
      if (newValue < minValue) {
        newError = minValueText;
      }
    }
    if (maxValue && maxValueText) {
      if (newValue > maxValue) {
        newError = maxValueText;
      }
    }
    setError(newError);
    setValue(newValue, newError);
  };

  const formattedValue = formatCurrency(value, prefix);

  return (
    <>
      {
        editing ? (
          <span className={ classnames("button-link -md -grid", className) }>
            <CurrencyInput
              groupSeparator=","
              decimalSeparator="."
              className="inline-edit-form"
              prefix={ prefix }
              value={ value }
              allowNegativeValue={ false }
              allowDecimals={ false }
              onValueChange={ handleChangeValue }
              maxLength={ 6 }
              ref={ inputRef }
            />
          </span>
        ) : (
          <button className={ classnames("button-link -md") } onClick={ handleOnClick }>
            { formattedValue }{ children }
          </button>
        )
      }
      {
        error &&
        <div className="inline-edit-error">
          <svg className="icon -warning">
            <use xlinkHref="#svg_i-warning" />
          </svg>
          { error }
        </div>
      }
    </>
  );
};

export default InlineEdit;
