import React, { createContext, useState } from "react";

export const ExchangeData = createContext();

export const WithExchangeData = ({ children }) => {
  const [ message, setMessage ] = useState(null);

  const data = {
    message,
    setMessage
  };

  return (
    <ExchangeData.Provider value={ data }>
      {children}
    </ExchangeData.Provider>
  );
};

