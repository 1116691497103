import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

const formatTimeAgo = (strDt) => {
  const d = new Date(strDt);
  return timeAgo.format(d);
};

const formatDateTime = (strDateTime) => {
  const dateObj = new Date(strDateTime);
  const [ day, month, year ] = [ dateObj.getUTCDate(), dateObj.getUTCMonth(), dateObj.getUTCFullYear() ];
  const updatedMonth = month + 1;
  const [ hours, minutes ] = [ dateObj.getUTCHours(), dateObj.getUTCMinutes() ];
  const offsetMinutes = dateObj.getTimezoneOffset();
  const offsetHours = offsetMinutes ? offsetMinutes / 60 : 0;
  return `${updatedMonth}/${day}/${year} ${hours}:${minutes} UTC${offsetHours}`;
};

const generateId = () => `${Date.now()}_${Math.round(Math.random() * 10000)}`;

export { timeAgo, formatTimeAgo, formatDateTime, generateId };
