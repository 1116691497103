import React, { useContext } from 'react';
import { withPrefix } from '../helpers';
import NSize from '../activeReport/nSize';
import { baseContext } from '../contexts';

const OverviewTitle = ({ overview, children, forceHideNsize }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const nSize = overview.data?.base_n;
  const nSizeLow = overview.data?.base_n_low;

  return (
    <div className="active-report section">
      <div className="section_top -grid">
        <div className="section_top-item">
          <h4 className="section_title">
            {withPrefix(verizonType, mediaKind, 'Customer Experience')}
          </h4>
          {
            overview.overview_date && (
              <div className="section_subtitle">
                ({overview.overview_date})
                <NSize
                  comaAtStart
                  verizonType={ verizonType }
                  value={ nSize }
                  low={ nSizeLow }
                  forceHideNsize={ forceHideNsize }
                />

              </div>
            )
          }
        </div>
        <div className="section_top-item">
          <div className="active-report_logo-bb" />
        </div>
      </div>
      { children }
    </div>
  );
};

export default OverviewTitle;
