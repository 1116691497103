const expressPackagemcHash = (baseUrl) => {
  const url = [ baseUrl, "molson_coors_survey" ].join("/");
  return {
    image1: `${url}/Vizzy+Lemonade+Variety+Pack.jpg`,
    image2: `${url}/Vizzy+Variety+Pack+No1.jpg`,
    image3: `${url}/Vizzy+Variety+Pack+No2.jpg`
  };
};

export default expressPackagemcHash;
