import React, { useState, useEffect } from 'react';
import { map } from "lodash";
import { prepareText } from "./helper";

export const Accordion = ({ list, className, perkId }) => {
  const [ currentIdx, setCurrentIdx ] = useState(-1);
  useEffect(() => {
    setCurrentIdx(-1);
  }, [ perkId ]);

  const btnOnClick = (idx) => {
    setCurrentIdx(currentIdx !== idx ? idx : -1);
  };
  const isOpen = (idx) => {
    return idx === currentIdx;
  };
  return (
    <ul className={ `accordion ${className ? className : ''}` }>
      {
        map(list, (item, idx) => (
          <li className="accordion_item" key={ `accordion-${idx}` }>
            <div className="accordion_summary">
              <div className="accordion_title">{ item.title }</div>
              <div className="accordion_button" onClick={ () => btnOnClick(idx) }>
                <div className={ `accordion_button-icon ${isOpen(idx) ? "-open" : ""}` }>
                  <svg className="icon -caret">
                    <use xlinkHref="#svg_caret" />
                  </svg>
                </div>
              </div>
            </div>
            {isOpen(idx) && (
              <div className="accordion_details">
                {prepareText({ texts: item.description, type: item.type })}
              </div>
            )}
          </li>
        ))
      }
    </ul>
  );
};
