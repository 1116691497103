import React, { useContext } from 'react';
import { filter, isEmpty, map } from 'lodash';
import classnames from 'classnames';
import { IndexData } from '../index-context';

const ConceptTags = ({ concept, left }) => {
  const { data } = useContext(IndexData);
  const tags = map(
    filter(concept.tags || [], (item) => item.type === "express_custom"),
    (el) => el.tag
  );

  const canShowTags = data.show_custom_tags_in_researcher && !isEmpty(tags);

  return (
    <>
      {
        canShowTags &&
        <div className={ classnames("table-comparison_concept-tags", { "-left": left }) }>
          {
            map(tags, (tag, tagIndex) => (
              <div key={ `${tag}${tagIndex}` } className="comparison-tag-item">{tag}</div>
            ))
          }

        </div>
      }
    </>
  );
};

export default ConceptTags;
