import React, { useState, useEffect, useRef } from 'react';
import { each } from 'lodash';

const InputNumber = ({
  value, onUpdate, maxLength, className, checkNumberRules, ariaLabel, max,
  onBlur, autoFocus
}) => {
  const [ currentData, setCurrentData ] = useState(
    () => ({ value, cursorPosition: { start: -1, end: -1 } })
  );
  const inputRef = useRef(null);

  const handleChange = (event) => {
    let currentValue = event.target.value;
    currentValue = currentValue.replace(/[^0-9]/gi, '');
    const newStart = event.target.selectionStart;
    const newEnd = event.target.selectionStart;
    const newData = { value: currentValue };
    const wrongSymbol = (currentData.value === newData.value);
    newData.cursorPosition = {
      start: wrongSymbol ? newStart - 1 : -1,
      end: wrongSymbol ? newEnd - 1 : -1
    };

    if (checkNumberRules) {
      const resultingChars = [];
      let zeroCountAtStart = 0;
      let zerosEnded = false;
      each(newData.value, (char) => {
        if (char !== '0') {
          zerosEnded = true;
        } else if (!zerosEnded) {
          zeroCountAtStart += 1;
        }
        if (zerosEnded) {
          resultingChars.push(char);
        }
      });
      if (!resultingChars.length && zeroCountAtStart > 0) {
        newData.value = '0';
        newData.cursorPosition.start = newStart - (zeroCountAtStart - 1);
        newData.cursorPosition.end = newEnd - (zeroCountAtStart - 1);
      } else {
        newData.value = resultingChars.join('');
        if (zeroCountAtStart) {
          newData.cursorPosition.start = newStart - zeroCountAtStart;
          newData.cursorPosition.end = newEnd - zeroCountAtStart;
        }
      }
      if (max && parseInt(newData.value) > max) {
        newData.value = max.toString();
      }
    }
    setCurrentData(newData);
    onUpdate(newData.value);
  };

  useEffect(() => {
    const currentValue = currentData.value;
    const { start, end } = currentData.cursorPosition;
    if (inputRef.current && currentValue && start > -1 && end > -1) {
      inputRef.current.setSelectionRange(start, end);
    }
  }, [ currentData ]);

  useEffect(() => {
    setCurrentData({ ...currentData, ...{ value } });
  }, [ value ]);

  const handleOnBlur = () => {
    onBlur && onBlur();
  };

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [ inputRef.current ]);

  return (
    <input
      ref={ inputRef }
      className={ className }
      type="text"
      maxLength={ maxLength }
      onChange={ handleChange }
      value={ currentData.value || '' }
      aria-label={ ariaLabel }
      onBlur={ handleOnBlur }
    />
  );
};

export default InputNumber;
