import React from 'react';

const CardPlanTitle = ({ name, price }) => {
  return <>
    <h6 className="choose-plan-card-title">{name}</h6>
    <div className="choose-plan-card-subtitle">
      <span className="choose-plan-card-price">${price}</span>
      <span className="choose-plan-card-price-tip">/line per month plus taxes & fees.</span>
    </div>
  </>
};

export default CardPlanTitle;