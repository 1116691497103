import React, { useContext } from 'react';
import { BaseContext } from '../../contexts';
import { runExport } from '../../requests';
import { sidebarExpressReportClick } from "../../../google-analytics/events";

const ExpressReport = () => {
  const { initialData, paths, gaTrackingId } = useContext(BaseContext);
  const isAdmin = initialData.is_admin;
  const expressReportUrl = paths.express_report;

  const openReport = (event) => {
    event.preventDefault();
    event.stopPropagation();
    runExport(event, expressReportUrl, { name: 'projects_info', report_format: 'xlsx' });
    sidebarExpressReportClick(gaTrackingId);
  };

  return (
    <>
      {
        isAdmin &&
        <li className="sidebar_nav-list-item">
          <a
            className="sidebar_nav-link -with-icon"
            href={ expressReportUrl }
            onClick={ openReport }
          >
            <svg className="sidebar_nav-link-icon icon -menu-downloads">
              <use xlinkHref="#svg_menu-downloads" />
            </svg>
            <span className="sidebar_nav-link-text">Express Report</span>
          </a>
        </li>
      }
    </>
  );
};

export default ExpressReport;
