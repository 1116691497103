import React, { useContext, useLayoutEffect } from 'react';
import { findIndex } from 'lodash';
import { GlobalContext } from '../contexts/appContexts';
import { cardLast4 } from '../helpers/cardHelper';
import FirstPage from './cardToWallet/firstPage';
import NativeApplePay from './cardToWallet/nativeApplePay';
import CardPin from './cardToWallet/cardPin';
import Notifications from './notifications';
import BaseNotifications from './baseNotifications';

const CardToWallet = ({ skipNotifications, headerStep, numberOfHeaderSteps }) => {
  const {
    nextStep, mergeData, currentData, fakeData,
    baseInnerSteps, setBaseInnerSteps, innerStep, setInnerStep
  } = useContext(GlobalContext);

  useLayoutEffect(() => {
    const allSteps = [
      'first_page', 'native_apple_pay', 'card_pin', 'notifications_ask', 'notification_native'
    ];
    setBaseInnerSteps(allSteps);
    setInnerStep(allSteps[0]);
    return () => {
      setBaseInnerSteps(null);
      setInnerStep(null);
    };
  }, []);

  const handleNext = () => {
    nextStep();
  };

  const handleCommonNext = (skipNumber = 1) => {
    const currentIndex = findIndex(baseInnerSteps, (stepsItem) => (stepsItem === innerStep));
    if (currentIndex < baseInnerSteps.length - skipNumber) {
      setInnerStep(baseInnerSteps[currentIndex + skipNumber]);
    } else {
      handleNext();
    }
  };

  const handleFirstPageNext = () => {
    mergeData({ cardToWalletStart: true });
    handleCommonNext();
  };

  const handleFirstPageSkip = () => {
    mergeData({ cardToWalletSkip: true });
    handleCommonNext(2);
  };

  const handleNativeApplePayNext = () => {
    mergeData({ cardIssued: true, cartToWalletNativeSkip: false });
    handleCommonNext();
  };

  const handleNativeApplePaySkip = () => {
    mergeData({ cardIssued: false, cartToWalletNativeSkip: true });
    setInnerStep(baseInnerSteps[0]);
  };

  const handleUseDefaultCardNext = () => {
    mergeData({ cardToWalletUseDefault: true });
  };
  const handleUseDefaultCardSkip = () => {
    mergeData({ cardToWalletUseDefault: false });
  };

  const handleSetPinNext = () => {
    mergeData({ pinSet: true, pinCreationSkip: false });
    handleCommonNext(skipNotifications ? 3 : 1);
  };

  const handleSetPinSkip = () => {
    mergeData({ pinSet: false, pinCreationSkip: true });
    handleCommonNext(skipNotifications ? 3 : 1);
  };

  const handleNotificationAskNext = () => {
    handleCommonNext();
  };

  const handleNotificationAskSkip = () => {
    handleCommonNext(2);
  };

  const handleNativeNotificationAskNext = () => {
    handleCommonNext();
  };

  const handleNativeNotificationAskSkip = () => {
    handleCommonNext();
  };

  return (
    <>
      {
        innerStep === 'first_page' &&
        <FirstPage
          headerStep={ headerStep }
          numberOfHeaderSteps={ numberOfHeaderSteps }
          nextStep={ handleFirstPageNext }
          skipStep={ handleFirstPageSkip }
        />
      }
      {
        innerStep === 'native_apple_pay' &&
        <NativeApplePay
          devicesList={ fakeData.devicesList }
          firstName={ currentData.firstName }
          lastName={ currentData.lastName }
          cardNumber={ cardLast4(currentData.card?.cardNumber) }
          nextStep={ handleNativeApplePayNext }
          skipStep={ handleNativeApplePaySkip }
          onUseDefaultNext={ handleUseDefaultCardNext }
          onUseDefaultSkip={ handleUseDefaultCardSkip }
        />
      }
      {
        innerStep === 'card_pin' &&
        <CardPin
          headerStep={ headerStep }
          numberOfHeaderSteps={ numberOfHeaderSteps }
          nextStep={ handleSetPinNext }
          skipStep={ handleSetPinSkip }
        />
      }
      {
        innerStep === 'notifications_ask' &&
        <Notifications
          headerStep={ headerStep }
          numberOfHeaderSteps={ numberOfHeaderSteps }
          nextStep={ handleNotificationAskNext }
          skipStep={ handleNotificationAskSkip }
        />
      }
      {
        innerStep === 'notification_native' &&
        <BaseNotifications
          nextStep={ handleNativeNotificationAskNext }
          skipStep={ handleNativeNotificationAskSkip }
        />
      }
    </>
  );
};

export default CardToWallet;


