import React, {useContext, useEffect, useRef, useState} from "react";
import {forEach} from "lodash";
import {MarkupData} from "./markups-logic";

const greenColor = '#4ec09a',
      redColor = '#ff7970',
      orangeColor = '#d0b050',
      borderColor = '#000000',
      defaultBorderOpacity = 0.9,
      highlightOpacity = 0.6,
      frameColor = '#29A5FF';

const IndividualMarkups = ({imageUrl, markupOpacity}) => {
  const [imageLoaded, setImageLoaded] = useState( false )
  const [commentsReceived, setCommentsReceived] = useState( false )
  const $img = useRef(null);
  const $canvas = useRef(null);

  const {
    anyHighlightedId, markupComments, getMarkupComments, width, setWidth, setHeight, setCloseSquare, groupedComments,
    groupedSelectedComments, setImageLeft, setImageTop
  } = useContext(MarkupData)

  const getScaledArea = (baseWidth, area, image_width) => {
    let scale = baseWidth / parseFloat(image_width);
    let scaledArea = {
      left: parseFloat(area.left) * scale,
      top: parseFloat(area.top) * scale,
      width: parseFloat(area.width) * scale,
      height: parseFloat(area.height) * scale
    };
    scaledArea.right = scaledArea.left + scaledArea.width;
    scaledArea.bottom = scaledArea.top + scaledArea.height;
    return scaledArea;
  };

  const drawMarkup = (ctx, comment, alpha, frameRect) => {
    forEach(comment.filterAreas, (area) => {
      if(area.isHighlighted){
        ctx.globalAlpha = highlightOpacity;
      }else{
        if (!!anyHighlightedId) ctx.globalAlpha = 0.00000000000001;
        else ctx.globalAlpha = alpha;
      }

      if(comment.reaction=='positive') ctx.fillStyle = greenColor;
      if(comment.reaction=='negative') ctx.fillStyle = redColor;
      if(comment.reaction=='confusing') ctx.fillStyle = orangeColor;

      var scaledArea = getScaledArea(width, area, comment.image_width);

      ctx.fillRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);

      if (area.isSelected){
        ctx.globalAlpha = defaultBorderOpacity;
        ctx.strokeStyle = borderColor;
        ctx.strokeRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);

        var newFrame = {};

        if(frameRect.left==null || frameRect.left>scaledArea.left){
          newFrame.left = scaledArea.left;
        }else{
          newFrame.left = frameRect.left;
        }

        if(frameRect.top==null || frameRect.top>scaledArea.top){
          newFrame.top = scaledArea.top;
        }else{
          newFrame.top = frameRect.top;
        }

        if(frameRect.width==null || (frameRect.left+frameRect.width)<(scaledArea.left + scaledArea.width) ){
          if(frameRect.width==null){
            newFrame.width = scaledArea.width;
          }else{
            newFrame.width = (scaledArea.left+scaledArea.width) - newFrame.left;
          }
        }else{
          newFrame.width = (frameRect.left+frameRect.width) - newFrame.left;
        }

        if(frameRect.height==null || (frameRect.top+frameRect.height)<(scaledArea.top + scaledArea.height) ){
          if(frameRect.height==null){
            newFrame.height = scaledArea.height;
          }else{
            newFrame.height = (scaledArea.top+scaledArea.height) - newFrame.top;
          }
        }else{
          newFrame.height = (frameRect.top+frameRect.height) - newFrame.top;
        }

        frameRect.left = newFrame.left;
        frameRect.top = newFrame.top;
        frameRect.width = newFrame.width;
        frameRect.height = newFrame.height;

      }
      ctx.globalAlpha = 1;
    })
  };


  const render = () => {
    const canvas = $canvas.current,
          context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    let list = getMarkupComments(),
        frameRect = {left: null, top: null, width: null, height: null};

    context.setLineDash([]);

    forEach(list, (el) => {
      drawMarkup(context, el, markupOpacity, frameRect);
    })

    if(!!frameRect.left){
      context.strokeStyle = frameColor;
      context.setLineDash([5,2]);
      context.strokeRect(frameRect.left-2, frameRect.top-2, frameRect.width+4, frameRect.height+4);
      setCloseSquare({x: frameRect.left + frameRect.width + 4, y: frameRect.top - 3})
    }else{
      setCloseSquare(null)
    }

  };

  const onImageLoad = (event) => {
    const w = $img.current.offsetWidth;
    const h = $img.current.offsetHeight;

    setImageLeft($img.current.offsetParent.offsetLeft)
    setImageTop($img.current.offsetParent.offsetTop)

    setWidth(w)
    setHeight(h)

    setImageLoaded(true)

    setWidth(w)
    $canvas.current.width = w
    $canvas.current.height = h

    if(commentsReceived){
      render()
    }
  }

  useEffect(() => {
    if(markupComments.length > 0 || commentsReceived) {
      setCommentsReceived(true)
      if(imageLoaded){
        render()
      }
    }
  }, [markupComments, markupOpacity, anyHighlightedId, groupedComments, groupedSelectedComments])

  return (
    <div className="survey-reactions-interface_image-canvas-wrapper" >
      <div className="survey-reactions-interface_image-canvas-inner">
        <img ref={$img}
             className="survey-reactions-interface_image"
             src={ imageUrl }
             onLoad={onImageLoad} />
        <canvas ref={$canvas}
                className="survey-reactions-interface_canvas" />
      </div>
    </div>
  )
}

export default IndividualMarkups;