import React, { createContext, useEffect, useState } from "react";

export const LangData = createContext();

export const WithLangData = ({ langCode, langName, children }) => {
  const [ lang, setLang ] = useState(langCode);

  const data = {
    lang,
    setLang,
    langCode,
    langName,
    useTranslation: lang !== langCode,
    answerByLanguage: (item) => {
      return lang === langCode ? item.answer : (item.translated_text || item.answer);
    },
    commentByLanguage: (item) => {
      return lang === langCode ? item.comment : (item.translated_text || item.comment);
    }
  };

  useEffect(() => {
    setLang(langCode);
  }, [ langCode ]);

  return (
    <LangData.Provider value={ data }>
      {children}
    </LangData.Provider>
  );
};

