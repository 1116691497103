import React, { useEffect } from 'react';
import { includes, map, keys, range } from 'lodash';
import classnames from 'classnames';
import Toggle from '../../../toggle';
import Wysiwyg from '../../../../../../common/components/wysiwyg';
import InlineDropdown from '../../../inlineDropdown';
import InfoToolTip from '../../../infoToolTip';
import { abilityField, abilityNField } from './fields';

const OpenEnd = ({ allowReasons, question, questions, setQuestions }) => {
  const displayAbility = (
    allowReasons &&
      includes([ 'Single', 'Multi' ], question.kind) &&
      !question.as_point_scale
  );
  const nFieldAbility = question.kind !== 'Single';
  const askReason = question[abilityField];
  const displayN = parseInt(question[abilityNField]);
  const numberOfAnswers = keys(question.answers).length;
  const handleToggleAskReason = (value) => {
    question[abilityField] = value;
    if (value && !question.reasons?.length) {
      if (!question.reasons) {
        question.reasons = [];
      }
      question.reasons.push({ text: '' });
    }
    setQuestions([ ...questions ]);
  };

  const hasErrorText = question.errors && question.errors['reasons.text'];

  const handleChangeText = (newHtml) => {
    question.reasons[0].text = newHtml;
    setQuestions([ ...questions ]);
  };

  const handleChangeN = (value) => {
    question[abilityNField] = value;
    setQuestions([ ...questions ]);
  };

  useEffect(() => {
    if (!displayN) {
      handleChangeN(numberOfAnswers);
    }
  }, []);

  useEffect(() => {
    if (!displayAbility && askReason) {
      handleToggleAskReason(false);
    }
    if (numberOfAnswers < displayN) {
      handleChangeN(numberOfAnswers);
    }
  }, [ questions ]);

  return (
    <>
      {
        displayAbility &&
        <div className="reason-open-end">
          <Toggle
            onChange={ handleToggleAskReason }
            checked={ askReason }
            hint={ <>Respondent will be asked about the reasons of their selection</> }
          >
            Include Reasons Question
          </Toggle>
          <div className={ classnames("reason-open-end-details", { '-expand': askReason }) }>
            <div className="reason-open-end-details-inner">
              <div className={ classnames("form_section-line", { 'has-error': hasErrorText }) }>
                <Wysiwyg
                  customPicker
                  toolbarClassName="wysiwyg-menu"
                  editorClassName="wysiwyg-textarea"
                  placeholder="Add"
                  enableIndent
                  value={ question.reasons?.length ? question.reasons[0].text : '' }
                  onChange={ handleChangeText }
                />
              </div>
              {
                nFieldAbility &&
                <div className="form_section -multi-options">
                  Display at most
                  {' '}
                  <InlineDropdown
                    value={ displayN }
                    onChange={ handleChangeN }
                    list={
                      map(
                        range(1, numberOfAnswers + 1), // end is not included
                        (num) => (
                          { title: `${num} selected item${num > 1 ? 's' : ''}`, value: num }
                        )
                      )
                    }
                  />
                  <InfoToolTip
                    remote
                    text={
                      <>
                        Maximum number of selected options that will be displayed
                        {' '}
                        along with the "Reasons Question".
                      </>
                    }
                    position="top-right"
                  />
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default OpenEnd;
