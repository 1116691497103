import React from "react";
import HeaderLinks from "../header-links";
import ExpressOpenEndCard from "../express-open-end-card";

const CustomPrTitleTag = ({ question, token, name, n }) => {
  let text = question;
  if (!!token && !!name) {
    text = text.replace(token, name);
  }

  return (
    <div className="reasons-pref-header header-with-controls_custom-header">
      <h3> Reasons for Preference </h3>
      <p>
        {text}
      </p>
      <p>N={n}</p>
    </div>
  );
};

const CustomTbTitleTag = ({ question, token, name, n }) => {
  let text = question;
  if (!!token && !!name) {
    text = text.replace(token, name);
  }
  return (
    <div className="thought-bubble-naming-header header-with-controls_custom-header">
      <h3> Thought Bubble </h3>
      <p>
        {text}
      </p>
      <p>N={n}</p>
    </div>
  );
};

const ReasonsForPreference = ({
  preferenceReasons, tbEnabled, current, reports, init, data,
  loadCurrent, loadComments, isAdmin,
  replaceToken = '%currentName%'
}) => {
  const demographics = data.filter.demographics;

  const bubblesText = () => {
    if (tbEnabled && preferenceReasons) {
      return "Thought Bubble & Reasons for Preference (Word Clouds)";
    }

    if (tbEnabled) {
      return "Thought Bubble (Word Cloud)";
    }

    if (preferenceReasons) {
      return "Reasons for Preference (Word Cloud)";
    }

    return "";
  };

  const prCard = current.preference_card;
  const tbCard = current.open_end_card;

  return (
    <>
      {
        (!!tbEnabled || !!preferenceReasons) &&
        <div className="comparisons_plate -margin">
          <div className="comparisons_plate-grid">
            <div className="comparisons_plate-item">
              <h3 className="comparisons_plate-title">
                {bubblesText()}
              </h3>
            </div>
            <div className="comparisons_plate-item">
              <HeaderLinks
                className="-no-min-width"
                exportUrl={ init.urls.export }
                reports={ reports }
                i18n={ init.i18n }
                data={ data }
                conceptId={ current.id }
                tourName={ "projectTbExport" }
              />
            </div>
          </div>
        </div>
      }
      {
        !!tbEnabled && !!current.open_end_card &&
        <div className="concept-report_tab wordcloud js-tb">
          <ExpressOpenEndCard
            metric={ tbCard.metric }
            cardData={ tbCard.data }
            mode="editing"
            filter={ demographics }
            customTitle={ (
              <CustomTbTitleTag
                question={ data.questions.thought_bubble_title }
                token={ replaceToken }
                name={ current.title }
                n={ tbCard.data.respondents_number }
              />) }
            pinCount={ 1 }
            answerHelp={ data.translations.wordcloud_hint }
            pinTitle={ data.translations.wordcloud_pin }
            unpinTitle={ data.translations.wordcloud_unpin }
            isAdmin={ isAdmin }
            loadWords={ loadCurrent }
            loadComments={ loadComments }
            tourName={ 'projectModalTB' }
          />
        </div>
      }
      {
        !!preferenceReasons && !!current.preference_card &&
        <div className="concept-report_tab wordcloud js-pc">
          <ExpressOpenEndCard
            metric={ prCard.metric }
            cardData={ prCard.data }
            mode="editing"
            filter={ demographics }
            customTitle={
              <CustomPrTitleTag
                question={ data.questions.reasons_for_preference_title }
                token={ replaceToken }
                name={ current.title }
                n={ prCard.data.respondents_number }
              />
            }
            pinCount={ 1 }
            answerHelp={ data.translations.wordcloud_hint }
            pinTitle={ data.translations.wordcloud_pin }
            unpinTitle={ data.translations.wordcloud_unpin }
            isAdmin={ isAdmin }
            loadWords={ loadCurrent }
            loadComments={ loadComments }
            tourName={ 'projectModalReasons' }
          />
        </div>
      }

    </>
  );
};

export default ReasonsForPreference;
