import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { condIndexHash } from '../../helper';
import { QuestionContext } from '../../contexts';
import ConditionSelect from './conditionSelect';

const AdditionalAnswerLine = ({
  question, questionIndex, options, findConditionItem,
  onChangeCondition, screenerQuestions, setScreenerQuestions,
  fieldBoolName, fieldTextName, fieldOptionsName, fieldConditionsKey,
  onSwitchAdditionalAnswer
}) => {
  const [ focusState, setFocusState ] = useState(false);
  const { needLogicEdit, fivePtScaleMode } = useContext(QuestionContext);

  const onChangeNoneAnswer = (event) => {
    question[fieldBoolName] = event.target.checked;
    if (onSwitchAdditionalAnswer) {
      onSwitchAdditionalAnswer();
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeNoneAnswerText = (event) => {
    question[fieldTextName] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <li className="form-question_answer">
      <div className="form-question_grid">
        <div className="form-question_grid-item -first-answer-item">
          <div className={ cn("form-question_answer-move", { '-disabled': fivePtScaleMode }) }>
            <div className="checkbox">
              <input
                type="checkbox"
                id={ `checkbox-id-${fieldBoolName}-${questionIndex}` }
                checked={ !!question[fieldBoolName] }
                onChange={ onChangeNoneAnswer }
                disabled={ fivePtScaleMode }
              />
              <label
                className={
                  cn(
                    `checkbox_label -for-additional-answer -for-${fieldBoolName}`,
                    { '-disabled': fivePtScaleMode }
                  )
                }
                htmlFor={ `checkbox-id-${fieldBoolName}-${questionIndex}` }
              />
            </div>
          </div>
        </div>

        <div
          className={
            cn(
              "form-question_grid-item -grow -with-custom-placeholder",
              { '-disabled': !question[fieldBoolName] }
            )
          }
        >
          <input
            className="form-field -block"
            type="text"
            value={ question[fieldTextName] || '' }
            onChange={ onChangeNoneAnswerText }
            disabled={ !question[fieldBoolName] }
            placeholder={ options[fieldOptionsName] }
            onFocus={ () => { setFocusState(true); } }
            onBlur={ () => { setFocusState(false); } }
            onKeyDown={ handleKeyDown }
          />
          {
            (!!question[fieldTextName] || focusState) &&
            <div className="custom-placeholder-label">
              Substitute for "{ options[fieldOptionsName] }"
            </div>
          }
        </div>

        {
          needLogicEdit &&
          <div
            className={
              cn(
                "form-question_grid-item -select",
                { '-disabled': !question[fieldBoolName] }
              )
            }
          >
            <ConditionSelect
              value={ findConditionItem(condIndexHash[fieldConditionsKey].converted) }
              onChangeCondition={ onChangeCondition }
              i={ condIndexHash[fieldConditionsKey].converted }
              disabled={ !question[fieldBoolName] }
            />
          </div>
        }

        <div className="form-question_grid-item -buttons" />
      </div>
    </li>
  );
};

export default AdditionalAnswerLine;
