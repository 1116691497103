import Http from '../../../../common/http';

const prepareParams = (antiFraudParams, answers) => {
  const params = {
    anti_fraud: antiFraudParams.antiFraud,
    project_id: antiFraudParams.projectId,
    answer: {},
    check_methods: antiFraudParams.checkMethods
  };
  params.answer[antiFraudParams.respondentId] = answers;
  return params;
};

const sendToFraudCheck = (payload, nextStep, setFraudProcessing) => {
  const url = `/survey/fraud_check/`;
  setFraudProcessing(true);

  Http.post(url, payload).then(
    (response) => {
      nextStep();
      setFraudProcessing(false);
    },
    (reject) => {
      console.log('something went wrong', reject);
      nextStep();
      setFraudProcessing(false);
    }
  );
};

const checkFraudOption = (projectId, antiFraud, onShowCaptcha, onHideCaptcha) => {
  const url = `/survey/can_off_fraud_check?project_id=${projectId}`;

  if (antiFraud) {
    onShowCaptcha && onShowCaptcha();
  } else {
    Http.get(url).then(
      (response) => {
        if (response.response.ok) {
          onHideCaptcha && onHideCaptcha();
        } else {
          onShowCaptcha && onShowCaptcha();
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
        onHideCaptcha && onHideCaptcha();
      }
    );
  }
};

const savePasteInfo = (projectId, respondentId) => {
  const url = `/survey/save_paste_info`;
  const payload = { project_id: projectId, respondent_id: respondentId };
  Http.post(url, payload).then(
    (response) => {
      if (!response.response.ok) {
        console.log('something went wrong', response);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const onPasteDetected = (result) => {
  result.pasteDetected = true;
};

export { sendToFraudCheck, prepareParams, checkFraudOption, onPasteDetected, savePasteInfo };
