import React from 'react';
import Slider from '../../../common/slider';

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const VerizonSlider = ({ children, customSettings }) => {
  const sliderSettings = { ...defaultSettings, ...customSettings };
  return (
    <Slider customSettings={ sliderSettings }>
      { children }
    </Slider>
  );
};

export default VerizonSlider;
