import { includes } from 'lodash';
import Base from '../base';
import FlavorSequential from './flavor_sequential';
import FlavorMaxDiff from './flavor_maxdiff';

class Flavor extends Base {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  maxDiffMode() {
    return includes(this.projectData.maxdiff_modes, this.projectData.concepts_mode);
  }

  findService() {
    if (this.maxDiffMode()) {
      return new FlavorMaxDiff(this.projectData, this.priceData.claim, this.size);
    }
    return new FlavorSequential(this.projectData, this.priceData.sequential, this.size);
  }
}

export default Flavor;
