import React, { useContext, useLayoutEffect } from 'react';
import { includes, findIndex, find } from 'lodash';
import { GlobalContext } from '../contexts/appContexts';
import ShoppingIntent from './shoppingIntent';
import ContactSync from './contactSync';
import Notifications from './notifications';
import BaseNotifications from './baseNotifications';
import AccessContacts from './accessContacts';

const ShoppingIntentAndNotifications = ({ headerStep, numberOfHeaderSteps }) => {
  const {
    nextStep, skipStep, currentData,
    baseInnerSteps, setBaseInnerSteps,
    innerStep, setInnerStep
  } = useContext(GlobalContext);

  const furtherStep = () => {
    const currentIndex = findIndex(baseInnerSteps, (stepsItem) => (stepsItem.name === innerStep));
    if (currentIndex < baseInnerSteps.length - 1) {
      setInnerStep(baseInnerSteps[currentIndex + 1].name);
    } else {
      nextStep();
    }
  };

  const alterStep = () => {
    const currentStepData = find(baseInnerSteps, (stepsItem) => (stepsItem.name === innerStep));
    if (currentStepData?.skipStep) {
      const newStep = currentStepData?.skipStep();
      if (newStep) {
        setInnerStep(newStep);
      } else {
        skipStep();
      }
    } else {
      furtherStep();
    }
  };

  const hasSendMoney = () => includes(currentData.intentCategories || [], 'send');
  const hasShop = () => includes(currentData.intentCategories || [], 'shop');
  const baseIntentOrCardSkip = () => (
    currentData.intentCategoriesSkip ||
    currentData.intentAddCardSkip
  );

  useLayoutEffect(() => {
    let firstStep = null;
    if (hasSendMoney() && !hasShop() && !baseIntentOrCardSkip()) {
      setBaseInnerSteps([
        {
          name: 'contact_sync',
          skipStep: () => ('notifications')
        },
        { name: 'access_contacts' },
        {
          name: 'notifications',
          skipStep: () => null
        },
        { name: 'base_notifications' }
      ]);
      firstStep = 'contact_sync';
    } else if (hasShop() && !hasSendMoney() && !baseIntentOrCardSkip()) {
      setBaseInnerSteps([
        { name: 'shopping_intent' },
        {
          name: 'notifications',
          skipStep: () => null
        },
        { name: 'base_notifications' }
      ]);
      firstStep = 'shopping_intent';
    } else {
      setBaseInnerSteps([
        { name: 'shopping_intent' },
        {
          name: 'notifications',
          skipStep: () => ('contact_sync')
        },
        { name: 'base_notifications' },
        {
          name: 'contact_sync',
          skipStep: () => null
        },
        { name: 'access_contacts' }
      ]);
      firstStep = 'shopping_intent';
    }
    setInnerStep(firstStep);
    return () => {
      setBaseInnerSteps(null);
      setInnerStep(null);
    };
  }, []);

  return (
    <>
      {
        innerStep === 'shopping_intent' &&
        <ShoppingIntent
          headerStep={ headerStep }
          numberOfHeaderSteps={ numberOfHeaderSteps }
          nextStep={ furtherStep }
          skipStep={ alterStep }
        />
      }
      {
        innerStep === 'contact_sync' &&
        <ContactSync
          headerStep={ headerStep }
          numberOfHeaderSteps={ numberOfHeaderSteps }
          nextStep={ furtherStep }
          skipStep={ alterStep }
        />
      }
      {
        innerStep === 'access_contacts' &&
        <AccessContacts nextStep={ furtherStep } />
      }
      {
        innerStep === 'notifications' &&
        <Notifications
          headerStep={ headerStep }
          numberOfHeaderSteps={ numberOfHeaderSteps }
          nextStep={ furtherStep }
          skipStep={ alterStep }
        />
      }
      {
        innerStep === 'base_notifications' &&
        <BaseNotifications nextStep={ furtherStep } />
      }
    </>
  );
};

export default ShoppingIntentAndNotifications;
