import React, { Fragment, useEffect, useRef, createRef } from "react";
import { map, times, toPairs, filter } from "lodash";
import Answer from "./Answer";
import AddNewItem from "./AddNewItem";

const EditableGridItems = ({
  gridItems, onChange, onDelete, placeholder, showAddNewItem, onImageDelete,
  newItemPlaceholder, onAdd, sections, withImages, onImageDataChange
}) => {
  const refsLen = gridItems.length;
  const refs = useRef([]);
  const prevRefsLen = useRef(-1);
  const newItemRef = createRef();

  useEffect(() => {
    refs.current = times(refsLen, (i) => (refs.current[i] || createRef()));

    if (prevRefsLen.current !== -1 && prevRefsLen.current < refsLen) {
      refs.current[refsLen - 1].focus();
    }
    prevRefsLen.current = refsLen;
  }, [ refsLen ]);

  const outerSections = filter(toPairs(sections), (el) => (parseInt(el[0]) >= gridItems.length));

  return (
    <>
      {
        map(gridItems, (item, index) => (
          <Fragment key={ index }>
            {sections && sections[index] &&
              <li className="form-question_answer -section-header">
                {sections[index]}
              </li>}
            {item.editable && !item._destroy &&
              <Answer
                text={ item.question }
                placeholder={ placeholder }
                onChange={ (v) => { onChange(v, index); } }
                onImageDataChange={ (v) => { onImageDataChange(v, index); } }
                deleteAnswer={ () => {onDelete(index);} }
                hasError={ item?.errors?.question }
                refs={ refs }
                index={ index }
                newItemRef={ newItemRef }
                disabled={ !item.enabled }
                withImages={ withImages }
                imageData={ item.imageSrc }
                onImageDelete={ () => { onImageDelete(index); } }
              />}
          </Fragment>
        ))
      }
      {map(outerSections, (el) => (
        <li key={ el[0] } className="form-question_answer -section-header">
          {el[1]}
        </li>
      ))}
      {showAddNewItem &&
        <AddNewItem
          placeholder={ newItemPlaceholder }
          onAdd={ onAdd }
          inputRef={ newItemRef }
        />}
    </>
  );
};

export default EditableGridItems;
