import React, { Fragment, useContext } from "react";
import { map } from "lodash";
import { averageCount, n0, showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { selectGroup, selectKind, IndexData } from '../../../index-context';

const ShelfEvaluationShelf = ({ current }) => {
  const { init, data, averageMode } = useContext(IndexData);
  const shelf = selectKind(data, 'express_shelf_found');
  const time = selectKind(data, 'express_shelf_time');
  const easeToFind = selectGroup(data, 'five_point_scale', true);

  return (
    <>
      {
        map(shelf, (values, title) => (
          <tr key={ title }>
            <td className="table-comparison_name-cell" colSpan={ 2 }>
              {data.titles.found}
            </td>
            <td className="-js-found">
              <StatusCircle values={ values[current.id] } box={ "top2" } noLetters />
            </td>
            <td className="table-comparison_average-cell">
              {showedAverageValue(values, averageMode, false, init.isInternal, data.view)}
            </td>
          </tr>
        ))
      }
      {
        map(time, (values, title) => (
          <tr key={ title }>
            <td className="table-comparison_name-cell" colSpan={ 2 }>
              {data.titles.average_time}
            </td>
            <td>
              {n0(values[current.id].value)}
            </td>
            <td className="table-comparison_average-cell">
              {averageCount(n0(values.avg_value), averageMode, init.isInternal, data.view)}
            </td>
          </tr>
        ))
      }
      {
        map(easeToFind, (values, title) => (
          <Fragment key={ title }>
            <tr>
              <td className="table-comparison_name-cell" rowSpan={ 2 } >
                { title } (N)
              </td>
              <td>
                <div className="table-comparison_box-cell">T2B</div>
              </td>
              <td>
                <StatusCircle
                  values={ values[current.id] }
                  box="top2"
                  noLetters
                />
                  &nbsp;
                <span className="with-number -in-scorecard">
                  ({current.shelf_counters.found_count})
                </span>
              </td>
              <td className="table-comparison_average-cell -js-avg">
                {showedAverageValue(values, averageMode, false, init.isInternal, data.view)}
              </td>
            </tr>
            <tr>
              <td>
                <div className="table-comparison_box-cell">TB</div>
              </td>
              <td>
                <StatusCircle
                  values={ values[current.id] }
                  box="top"
                  noLetters
                />
                  &nbsp;
                <span className="with-number -in-scorecard">
                  ({current.shelf_counters.found_count})
                </span>
              </td>
              <td className="table-comparison_average-cell -js-avg">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view, "top") }
              </td>
            </tr>
          </Fragment>
        ))
      }
    </>
  );
};

export default ShelfEvaluationShelf;
