import React, { useEffect } from 'react';
import Http from "../../../common/http";
import { sendDisableNextButton, sendEnableNextButton } from "./services/angular_messaging";

const PageLoader = ({ options }) => {
  const checkStatusTimeout = 5000;

  const checkStatus = () => {
    Http.get(options.check_status_url)
      .then(
        (response) => {
          const { status } = response.data;

          if (status === 'in_progress') {
            setTimeout(
              () => { checkStatus(); },
              checkStatusTimeout
            );
          } else {
            window.location = options.next_step_url;
          }
        },
        (reject) => {
          sendEnableNextButton("Any");
          console.log('something went wrong', reject);
        }
      );
  };

  useEffect(() => {
    sendDisableNextButton("Any");
    checkStatus();
  }, []);

  return (
    <>
      <div className="page-loader -inner-loader" />

      <div className="form_section">
        <div className="form-upload -plate -in-progress">
          <h3>{options.processing_message}</h3>
        </div>
      </div>
    </>
  );
};

export default PageLoader;
