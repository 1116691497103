import React, { useEffect }  from 'react';
import { createPortal } from 'react-dom'

const root = document.querySelector('.portal-content_inner#quant-portal-id');

const Portal = ({ children }) => {
  useEffect(() => {
    const rootContent = document.querySelectorAll('body');
    rootContent.forEach(content => content.style.overflow = 'hidden');
    return () => {
      rootContent.forEach(content => content.style.overflow = '');
    }
  })
  return createPortal(children, root);
}

const QuantPortal = ({ title, tabs, open, handleClose, className, children }) => {
  return open
    ? <Portal>
        <div className={`quant-portal ${className ? className : ''}`}>
          <div className="quant-portal-backdrop" onClick={handleClose}></div>
          <div className="quant-portal-inner">
            <div className="content">
              <div className="quant-portal-header">
                {title && <h6 className="quant-portal-title">{title}</h6>}
                {tabs && <div className="quant-portal-tabs">{tabs}</div>}
                <button className="quant-portal-cross" onClick={handleClose} type="button"/>
              </div>
              <div className="quant-portal-content">
                {children}
              </div>
            </div>
          </div>
        </div>
      </Portal>
    : null;
}

export default QuantPortal;
