import React from "react";
import Popup from "reactjs-popup";
import cn from "classnames";

const ThTextPopup = ({ concept, openScorecardModal, alphabet }) => {
  const trigger = (
    <div
      className={
        cn(
          "table-comparison_concept -concept-card -naming",
          `js-${concept.id}`,
          { '-with-letter-under': !!alphabet }
        )
      }
      onClick={ openScorecardModal }
    >
      <div className="concept-preview table-comparison_concept-preview -centered">
        <div className="concept-preview_naming">
          <div className="concept-preview_naming-inner">
            {concept.title}
          </div>
        </div>
      </div>
      <div className="table-comparison_concept-name -max-comparison-width link -decorated">
        {concept.title}
      </div>
    </div>
  );

  return (
    <Popup
      trigger={ trigger }
      position="top center"
      on={ [ 'hover', 'focus' ] }
      className="th-text-popup"
      mouseEnterDelay={ 1 }
      mouseLeaveDelay={ 1 }
      offsetY={ -93 }
    >

      <div className="concept-preview_full in">
        <div className="inner -naming">
          <div className="concept-preview_full-naming-text">
            {concept.title}
          </div>
          <div className="button-place">
            <button className="image-preview" onClick={ openScorecardModal }>
              View Report
            </button>
          </div>
        </div>
      </div>

    </Popup>
  );
};

export default ThTextPopup;
