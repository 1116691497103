import React, { forwardRef } from "react";
import { map } from 'lodash';
import classnames from 'classnames';

const FormControlDropdown = (
  { value = '', onChange, options, label, disableChange, onClick },
  forwardedRef
) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
  };
  const handleClick = (event) => {
    if (disableChange) {
      event.preventDefault();
      event.stopPropagation();
      onClick && onClick();
    }
  };
  return (
    <>
      <div className="form-control for-dropdown">
        <select
          name="test"
          className={ classnames("form-control_field dropdown", { '-with-label': !!label }) }
          value={ value }
          onChange={ handleChange }
          onClick={ handleClick }
          autoComplete="off"
          ref={ forwardedRef }
        >
          <option name="" />
          {
            map(options, (option) => (
              <option key={ option.value } value={ option.value }>{ option.title }</option>
            ))
          }
        </select>
        {
          !!label &&
          <label
            className={
              classnames(
                "form-control_label",
                { '-with-value': !!value }
              )
            }
          >
            { label }
          </label>
        }
      </div>
    </>
  );
};

export default forwardRef(FormControlDropdown);
