import React from 'react';
import cn from "classnames";

const SvgIcon = ({ className, name, onClick, role }) => {
  return (
    <svg className={ cn('icon', `-${name}`, className) } onClick={ onClick } role={ role }>
      <use xlinkHref={ `#svg_${name}` } />
    </svg>
  );
};

export default SvgIcon;
