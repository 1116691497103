import React, { useState, useContext } from 'react';
import { range, map } from 'lodash';
import classnames from 'classnames';
import InfoToolTip from '../../infoToolTip';
import { HighLevelContext } from '../contexts';

const ConceptsCountChanger = () => {
  const { conceptsCountData }  = useContext(HighLevelContext);
  const { display, max, min, item_name, value, setValue } = conceptsCountData;
  const [ optionsArray ] = useState(() => (
    [ ...range(min, max), ...[ max ] ]
  ));

  const handleOnChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(event.target.value);
  };

  return (
    <>
      {
        display &&
        <div className="form_section-title -grid">
          <div className="form_section-title-item -grid">
            <span>Custom Questions will be displayed for </span>
            <div
              className={
                classnames(
                  "form_inline-select -inline-n-concepts",
                  { '-more-letters': value > 1 }
                )
              }
            >
              <select
                className="custom-metric-concepts-number-select"
                value={ value }
                onChange={ handleOnChange }
              >
                {
                  map(optionsArray, (el) => (
                    <option
                      key={ `conceptsCountOption${el}` }
                      value={ el }
                    >
                      {el} {item_name}{ el > 1 ? 's' : '' }
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
          <InfoToolTip remote position="top-right">
            Concepts for Custom Questions will be selected among less answered.
            {' '}
            If less than {max} concepts is selected,
            {' '}
            custom questions will be displayed after the main KPIs.
          </InfoToolTip>
        </div>
      }
    </>
  );
};

export default ConceptsCountChanger;
