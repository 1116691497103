import React from "react";
import TrCart from './tr-cart';
import TrRanking from './tr-ranking';
import TrShelf from "./tr-shelf";
import TrShelfNsizes from './tr-shelf-nsizes';
import TrCartCompetitors from './tr-cart-competitors';
import TrRankingCompetitors from './tr-ranking-competitors';

const Index = ({ data, checkedConcepts }) => {
  const shelfEnabled = data.shelf_enabled;
  const cartEnabled = data.cart_exercise;
  const rankingEnabled = data.cart_exercise && data.cart_ranking;

  return (
    <>
      <tr className="-highlighted">
        <td
          colSpan={
            checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1
          }
        >
          Shelf Evaluation
        </td>
        {
          checkedConcepts.length > 3 &&
          <td colSpan={ checkedConcepts.length + 1 - 3 } />
        }
        <td className="table-comparison_average-cell -highlighted" />
      </tr>

      { (shelfEnabled || cartEnabled) && <TrShelfNsizes checkedConcepts={ checkedConcepts } /> }

      { shelfEnabled && <TrShelf checkedConcepts={ checkedConcepts } /> }

      { cartEnabled && <TrCart checkedConcepts={ checkedConcepts } /> }

      { cartEnabled && rankingEnabled && <TrRanking checkedConcepts={ checkedConcepts } /> }

      { cartEnabled && <TrCartCompetitors checkedConcepts={ checkedConcepts } /> }
      { cartEnabled && rankingEnabled && <TrRankingCompetitors checkedConcepts={ checkedConcepts } /> }
    </>
  );
};

export default Index;
