import React, { useState, useEffect, Fragment, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, className }) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    element.setAttribute("style", "top: 10px; right: 10px;");
    body.appendChild(element);
    return () => {
      body.removeChild(element);
    };
  }, []);

  useLayoutEffect(() => {
    element.className = className;
  }, [ className ]);

  return createPortal(children, element);
};

// messageObject = { message: "", success: true }
const Notification = ({ messageObject, onDeactivate, interval }) => {
  const [ currentTID, updateCurrentTID ] = useState(null);
  const { message, success } = messageObject || {};

  const removeInterval = () => {
    if (currentTID) {
      clearInterval(currentTID);
    }
  };

  useEffect(() => {
    return removeInterval;
  }, []);
  useEffect(() => {
    if (messageObject && !!interval) {
      removeInterval();
      let variable = interval;
      const TID = setInterval(() => {
        if (variable) {
          variable -= 1;
        }
        if (!variable) {
          removeInterval();
          onDeactivate();
        }
      }, 1000);
      updateCurrentTID(TID);
    } else { removeInterval(); }
  }, [ messageObject ]);

  return (
    <>
      {
        !!messageObject && !!message &&
        <Portal className={ `ui-notification  clickable ${success ? "success" : "error"}` } >
          <div onClick={ onDeactivate } className="message" >{ message }</div>
        </Portal>
      }
    </>
  );
};

export default Notification;
