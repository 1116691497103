import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import Modal from '../../../../common/components/modal';
import SvgIcon from '../../../../common/components/svg-icon';
import { DataContext } from "../withData";

const ImagesModal = () => {
  const { hideModal, modalLoading, modalInfo, conceptImages, clearImageTag } = useContext(DataContext);
  const [ saving, setSaving ] = useState(false);

  const handleDelete = (image) => {
    setSaving(true);

    clearImageTag(
      image.concept_id,
      modalInfo.id,
      () => {
        setSaving(false);
        if (conceptImages.length === 1) {
          hideModal();
        }
      }
    );
  };

  return (
    <Modal
      modalSize="md"
      modalClassName="centered"
      backClassName="-transparent"
      showCross
      onClose={ hideModal }
    >
      <div className="modal_header">
        <div className="modal_title">
          { modalInfo.category }
          {
            (modalInfo.text1 || modalInfo.text2) &&
            <span className="modal_subtitle">
              { modalInfo.text1 }{ modalInfo.text2 ? ` / ${modalInfo.text2}` : '' }
            </span>
          }
        </div>
      </div>
      <div className="modal_body">
        <div className="content -two-third">
          { modalLoading && <div className="modal_loader loader -loading-blink">Loading...</div> }
          {
            !modalLoading &&
            <>
              {
                map(conceptImages, (info) => (
                  <div className="express-image" key={ info.id }>
                    <div className="thumb" style={ { backgroundImage: `url(${info.url})` } } />
                    <div className="header-text">
                      <div className="title"><b>{info.name}</b> {info.project_title}</div>
                      <a
                        href={ `/express/projects/${info.project_id}/concept_tags` }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Open Concepts Tags
                      </a>
                    </div>
                    {
                      modalInfo.type !== 'category' &&
                      <div className="buttons">
                        <div className="info-tooltip">
                          <button
                            className="button -circle -secondary -delete-color info-tooltip_control -auto-height"
                            type="button"
                            disabled={ saving }
                            onClick={ () => {
                              handleDelete(info);
                            } }
                          >
                            <SvgIcon name="i-delete" />
                          </button>
                          <div className="info-tooltip_drop -left">
                            <div className="info-tooltip_drop-inner -tiny">
                              Remove from tag
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                ))
              }
            </>
          }
        </div>
      </div>
    </Modal>
  );
};

export default ImagesModal;
