import React from 'react';
import { imageUrl } from './helper';
import renderRawHtml from './render_raw_html';

const PlanPerk = ({ perk, openDetailsModal, children, onChangeAddon }) => {
  const onLearnMoreClick = () => {
    const { title, description } = perk;
    openDetailsModal({ title, description });
  };
  return (<div className="plan-perk">
    <div className="plan-perk_grid -no-space-between">
      {perk.icon && (
        <div className="plan-perk_grid-item">
          <img src={ imageUrl(perk.icon) } className="plan-perk_img" />
        </div>
      )}
      <div className="plan-perk_grid-item">
        <h4 className="plan-perk_title">{ perk.title }</h4>
        {perk.price && (
          perk.noPerk ? (
            <div className="plan-perk_price">Save ${perk.price}/mo on your plan</div>
          ) : (
            <>
              { !!children && children}
              { !children && <div className="plan-perk_price">${perk.price} monthly value</div> }
            </>
          )
        )}
        <div className="plan-perk_descr">
          <span { ...renderRawHtml(perk.shortDescription) } /><span className="plan-perk_space"> </span>
          {
            perk.description && (
              <button className="button-link -inherit" onClick={ onLearnMoreClick }>Learn more</button>
            )
          }
          {
            !!onChangeAddon &&
            <div className="build-own-plan_perk-link -button">
              <button className="button -bordered -sm" onClick={ onChangeAddon }>
                Change add-on
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  </div>);
};

export default PlanPerk;
