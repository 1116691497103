import React from 'react';
import remoteFiles from '../../../../../common/remote_files';
import { conceptsTestNumbers, defaultMaxPerRespondent } from '../../express_numbers';

const ReadMoreConceptsTest = ({ onGetStarted }) => {
  const {
    conceptFocus, preference, questionType1,
    questionType2, thoughtBubble
  } = remoteFiles.express.steps.conceptsTest;
  const {
    step12x, step32x, screenerQuestion
  } = remoteFiles.express.steps.base;
  return (
    <>
      <div className="modal_body">
        <div className="express-steps">
          <h3 className="express-steps_title">Concept</h3>
          <h2 className="express-steps_subtitle">Here’s how it works</h2>
          <div className="express-steps_list">
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Select your audience</p>
                  <p className="-descr">Choose demographics filters.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ step12x } />
                </div>
              </div>
            </div>
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Create up to 3 screener questions</p>
                  <p className="-descr">Adding screener questions may impact cost and timing.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ screenerQuestion } />
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-bottom-line">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Upload your concepts</p>
                  <p className="-descr">
                    You can evaluate just one concept or as many as
                    {' '}
                    {conceptsTestNumbers.itemsMax.express}.
                  </p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ step32x } />
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex -no-bottom-line">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">Choose Key Question Types</h3>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Questions for up to { defaultMaxPerRespondent } concepts per respondent</p>
                    <p className="-descr">
                      Respondents will view several concepts, randomly selected, and react to key
                      { ' ' }
                      metrics and attribute ratings for each.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ questionType1 } />
                  </div>
                  <div className="express-steps_item-img -narrow">
                    <img src={ questionType2 } />
                  </div>
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex -no-bottom-line">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">buzzback Projectives</h3>
                <div className="express-steps_item-description">
                  For a subset of concepts randomly selected, respondents will react to buzzback
                  { ' ' }
                  projective techniques.
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Thought Bubble</p>
                    <p className="-descr">
                      With Thought Bubble, respondents describe the concept in three words.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ thoughtBubble } />
                  </div>
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Concept Focus</p>
                    <p className="-descr">
                      With Concept Focus, respondents mark up what they like and dislike and
                      { ' ' }
                      describe why.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img">
                    <img src={ conceptFocus } />
                  </div>
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">Preference</h3>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Preference question</p>
                    <p className="-descr">
                      Respondents will select their preference. Only available if
                      {' '}
                      {conceptsTestNumbers.itemsMin.express}+ concepts are
                      { ' ' }
                      included.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ preference } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal_footer -bg-gray -center">
        <div className="modal_footer-button">
          <button className="button modal_button" onClick={ onGetStarted }>
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default ReadMoreConceptsTest;
