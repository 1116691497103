import React, { Fragment, useLayoutEffect, useState } from 'react';
import { Pagination } from "react-bootstrap";
import { each, keys } from 'lodash';

const BBPagination = ({ totalRecords, perPage, current, pageClicked, className }) => {
  const [ pageArray, setPageArray ] = useState([]);
  const total = Math.ceil(totalRecords / perPage);

  useLayoutEffect(() => {
    const resultingArray = [];

    each([ ...keys(Array(total)) ], (el) => {
      const pageEl = parseInt(el) + 1;
      if (
        pageEl === 1 ||
        pageEl === total ||
        Math.abs(current - pageEl) <= 2
      ) {
        resultingArray.push(pageEl);
      } else {
        resultingArray.push(null);
      }
    });
    setPageArray(resultingArray);
  }, [ current, totalRecords ]);

  return (
    <Pagination className={ className }>
      <Pagination.Item
        onClick={ () => { current > 1 ? pageClicked(current - 1) : null; } }
      >
        Previous
      </Pagination.Item>
      {
        pageArray.map((el, index) => {
          return (
            <Fragment key={ `Pagination${index}` }>
              {
                el ?
                  <Pagination.Item
                    active={ current === el }
                    onClick={ () => { pageClicked(el); } }
                  >
                    { el }
                  </Pagination.Item>
                  :
                  <Pagination.Item>...</Pagination.Item>
              }
            </Fragment>
          );
        })
      }
      <Pagination.Item
        onClick={ () => { current < total ? pageClicked(current + 1) : null; } }
      >
        Next
      </Pagination.Item>
    </Pagination>
  );
};

export default BBPagination;
