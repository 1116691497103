import React, { useState, useEffect, Fragment } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import remoteFiles from '../../../common/remote_files';

// usage <HowToConnectPopup opened={ true } onClose={ (event) => {} } />

const ModalWindow = ({ children, showBoth }) => {
  const body = document.body || document.querySelector("body");

  const [ bodyOverflow ] = useState(body.style.overflow);
  const [ element ] = useState(document.createElement('div'));
  const [ backElement ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = classnames("modal fade in -fios-tv", { "-only-set-top-box": !showBoth });
    body.appendChild(element);

    backElement.className = "modal-backdrop fade in";
    body.appendChild(backElement);

    body.style.overflow = 'hidden';

    return () => {
      body.removeChild(element);
      body.removeChild(backElement);
      body.style.overflow = bodyOverflow;
    };
  }, []);
  return createPortal(children, element);
};

const HowToConnectPopup = ({ opened, onClose, showBoth }) => {
  const { fiosTv, popupImageApp } = remoteFiles.verizon.tv;
  return (
    <>
      {
        opened &&
        <ModalWindow showBoth={ showBoth }>
          <div className="popup-content">
            <div className="cross" onClick={ onClose } />
            <div className="inner-content">
              <div className="scrollable-content">
                <h3>Fios TV One.</h3>
                <ul>
                  <li>Control TV with your voice.</li>
                  <li>Stunning 4k Ultra HD picture quality.</li>
                  <li>Wireless mini boxes with no cable required.</li>
                  <li>Netflix and YouTube channels built in.</li>
                  <li>Connects to your Apple TV or Fire TV.</li>
                </ul>
                <img className="-first" src={ fiosTv } alt="alt" />
                {
                  showBoth &&
                  <Fragment>
                    <h3>Fios TV Home.</h3>
                    <ul>
                      <li>Apple TV 4th Gen.</li>
                      <li>Apple TV 4K 5th Gen.</li>
                      <li>Fire TV Stick 4K 3rd Gen.</li>
                      <li>Fire TV Cube 1st & 2nd Gen.</li>
                    </ul>
                    <img className="-second" src={ popupImageApp } alt="alt" />
                  </Fragment>
                }
              </div>
              <div className="buttons">
                <button className="button" onClick={ onClose }>Continue</button>
              </div>
            </div>
          </div>
        </ModalWindow>
      }
    </>
  );
};

export default HowToConnectPopup;
