import React from 'react';

export const Header = () => {
  return (
    <div className="header-black">
      <div className="header-black-outer" />
      <div className="header-black-inner" />
      <div className="header-black-outer" />
    </div>
  );
};
