import React from 'react';

const EditButton = ({ onEdit }) => {
  return (
    <button className="section_top-button-edit" onClick={ onEdit }>
      <svg className="icon section_top-button-edit-icon">
        <use xlinkHref={ `#svg_edit` } />
      </svg>
    </button>
  );
};

export default EditButton;
