import React, { useContext } from 'react';
import { DataContext } from "../withData";
import Counters from './Counters';

const EditorCell2 = ({ modalInfo, editing, saving, count, cancelEditing }) => {
  const { showModal } = useContext(DataContext);

  const openImages = (kinds, type) => {
    showModal({ ...modalInfo, kinds, type });
  };

  return (
    <div className="form_section-text">
      {
        !editing &&
        <Counters countsHash={ count } onClick={ openImages } />
      }
      {
        editing &&
        <button
          type="button"
          className="button -darkest-grey"
          onClick={ cancelEditing }
          disabled={ saving }
        >
          Cancel
        </button>
      }
    </div>
  );
};

export default EditorCell2;
