import React, { useState } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';
import ZoomImage from '../zoom_image';

const Preference = ({
  title, concepts, result, nextStep, children,
  forceImage, bigger
}) => {
  const header = children;
  const noImage = !!header && !forceImage;

  const [ conceptId, updateConceptId ] = useState(null);

  const disabledOption = () => {
    const hash = {};
    if (!conceptId) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswer = () => {
    result.preference_selections = [ { concept_id: conceptId } ];
    result.preference_views = map(concepts, (concept) => {
      return { concept_id: concept.id };
    });
    updateConceptId(null);
    nextStep();
  };

  const selectAnswer = (event, conceptId) => {
    updateConceptId(conceptId ? conceptId : event.target.value);
  };

  const renderItem = (concept) => {
    return (
      <div className="survey-options_plates-item" key={ concept.id }>
        <div className="radio-plate" >
          <div
            className="radio-plate_part -radio -clickable"
            onClick={ (event) => { selectAnswer(event, concept.id); } }
          >
            <label className="radio">
              <input
                type="radio"
                name="concept"
                value={ concept.id }
                checked={ concept.id === conceptId }
                onChange={ (event) => { selectAnswer(event); } }
              />
              <div className="radio_label -no-text" />
            </label>
          </div>
          <div
            className="radio-plate_part -image -clickable"
            onClick={ (event) => { selectAnswer(event, concept.id); } }
          >
            {
              noImage ?
                <span className="name-preference">
                  { concept.name }
                </span>
                :
                <ZoomImage
                  className={
                    classnames(
                      "radio-plate_part-image",
                      { "-with-text": forceImage }
                    )
                  }
                  src={ concept.url }
                  additionalContent={
                    <>
                      {
                        forceImage &&
                        <div className="name-preference">
                          { concept.name }
                        </div>
                      }
                    </>
                  }
                >
                  <img src={ concept.url } alt={ concept.name } />
                </ZoomImage>
            }
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {
        header ?
          header :
          <div className="survey-header">
            <div
              className={ classnames("survey-header_title", { "-bigger": bigger }) }
              { ...renderRawHtml(title) }
            />
          </div>
      }
      <div className="survey-layout_content">
        <div className={ classnames("survey-layout_container -preference", { "-bigger": !noImage }) } >
          <div className="survey-options -preference">
            <div className={ classnames('survey-options_plates', { "-naming": noImage }) }>
              { map(concepts, (concept) => renderItem(concept)) }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswer(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default Preference;
