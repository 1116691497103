import React, { useState} from "react";
import classnames from 'classnames';
import { map } from 'lodash';

const Lines = ({ nextStep }) => {
  const [ line, updateLine ] = useState(null);
  const selectLine = (line) => {
    updateLine(line);
  };

  return(
    <div className="content">
      <div className="header"></div>
      <div>
        <div className="inner-content">
          <h2 className="line-question">
            How many lines do you need?
          </h2>
          <div className="text-20 line-note">
            You’ll need a line per device.
          </div>
          <div className="start_counts -billing">
            {
              map([1,2,3,4,5], (currentLine) => {
                return(
                  <h2
                    key={ `line${currentLine}` }
                    className={ classnames('start_counts-item', {'-selected': line == currentLine}) }
                    onClick={ () => { selectLine(currentLine) } }
                  >
                    { currentLine }
                  </h2>
                )
              })
            }
          </div>
          <div className="start_buttons">
            <button className="-billing button" disabled={ !line } onClick={ (event) => { nextStep(event, line) } }>Continue</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Lines;