import { find } from 'lodash';

const planById = (plans, id) => (
  find(plans, (plan) => plan.id === id) || null
);

const getPerksCountInPlan = (plans, planId) => (
  planId && planById(plans, planId) ?
    planById(plans, planId).perksCount : null
);

const perkNameById = (perks, id) => {
  const perk = find(perks, (perk) => perk.id === id);
  return perk ? perk.title : null;
};

const perkById = (perks, id) => {
  return find(perks, (perk) => perk.id === id) || null;
};

export { planById, getPerksCountInPlan, perkNameById, perkById };
