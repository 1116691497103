import { each, filter, includes } from 'lodash';
import Base from './base';

class ConceptBase extends Base {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  monadic() {
    return includes(this.projectData.monadic_modes, this.projectData.concepts_mode);
  }

  conceptsNumber() {
    return this.notDestroyedItems(this.projectData.express_images).length || 1;
  }

  viewedConceptsCount() {
    const selectedConceptNum = this.projectData.concepts_num;
    if (this.monadic()) {
      return 1;
    }
    if (this.turf()) {
      return Math.min(this.conceptsNumber(), this.projectData.max_five_point_turf);
    }
    if (
      !selectedConceptNum ||
      selectedConceptNum > this.conceptsNumber() ||
      selectedConceptNum > this.projectData.max_five_point_sequential ||
      selectedConceptNum < 2
    ) {
      return Math.min(this.conceptsNumber(), this.projectData.max_five_point_sequential);
    }
    return selectedConceptNum;
  }

  customMetricsNumber() {
    return this.notDestroyedItems(this.projectData.custom_metrics).length;
  }

  sizeCalcPart() {
    if (this.monadic()) {
      return this.conceptsNumber();
    }
    return (this.conceptsNumber() / this.viewedConceptsCount());
  }

  attributesPrice() {
    let paidAttributesCount = 0;
    each(this.projectData.express_metrics, (item) => {
      if ((item.enabled === true || item.enabled === 'true') && !item._destroy) {
        const selectedGridItemsCount = filter(
          item.grid_items || [],
          (el) => !el._destroy && (el.enabled === true || el.enabled === 'true')
        ).length;
        if (selectedGridItemsCount > this.priceData.free_attributes_count) {
          paidAttributesCount += selectedGridItemsCount - this.priceData.free_attributes_count;
        }
      }
    });

    return paidAttributesCount * this.priceData.price_for_extra_attribute;
  }

  customMetricsPrice() {
    return this.customMetricsNumber() * this.priceData.custom_metric_price_each;
  }

  baseConceptsPrice() {
    return (this.attributesPrice() + this.customMetricsPrice());
  }

  totalPrice() {
    return (this.basePrice() + this.baseConceptsPrice());
  }
}

export default ConceptBase;
