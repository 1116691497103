import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import { map } from 'lodash';
import Modal from '../../../../common/components/modal';
import renderRawHtml from '../../../../common/render_raw_html';
import SvgIcon from "../../../../common/components/svg-icon";
import listData from './list_data';

const TypesList = ({
  onCreate, title, onShowInfo, onCloseInfo, className,
  forceShowInTypeAfterClickCreate, projectsAllowance
}) => {
  const [ showReadMore, setShowReadMore ] = useState(null);
  const [ list ] = useState(() => (listData()));
  const titleField = 'title';

  const handleShowInfo = (express) => {
    if (onShowInfo) {
      onShowInfo();
    }
    setShowReadMore(express);
  };

  const handleClose = () => {
    if (onCloseInfo) {
      onCloseInfo();
    }
    setShowReadMore(false);
  };

  const onGetStarted = (express, popupTitle) => {
    if (!forceShowInTypeAfterClickCreate) {
      setShowReadMore(false);
      if (onCloseInfo) {
        onCloseInfo();
      }
    }
    onCreate(express, popupTitle);
  };

  return (
    <div className={ classnames("express-types", className) }>
      <div className="express-types_title">{ title }</div>
      <div className="express-types_list -even">
        {
          map(list, (item, index) => (
            <Fragment key={ `modalItem${index}${item.express}` }>
              {
                (!projectsAllowance || projectsAllowance[item.express]) &&
                <div className="express-types_list-item">
                  <div className="express-types_plate express-types_list-item-inner">
                    <div className="express-types_plate-icon">
                      <SvgIcon name={ item.icon } />
                    </div>
                    <div className="express-plate-middle">
                      <div
                        className="express-types_plate-title"
                        { ...renderRawHtml(item.title) }
                      />
                      <div className="express-types_plate-descr">
                        { item.description }
                      </div>
                      <div className="express-types_plate-buttons">
                        <div className="express-types_plate-buttons-item">
                          <button
                            className="button"
                            onClick={ () => { onGetStarted(item.express, item[titleField]); } }
                          >
                            Create Project
                          </button>
                        </div>
                        <div className="express-types_plate-buttons-item">
                          <div
                            className="link express-types_plate-link"
                            onClick={ () => { handleShowInfo(item.express); } }
                          >
                            Read more
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Fragment>
          ))
        }

        {list.length % 2 === 1 && <div className="express-types_list-item" />}

        {
          showReadMore &&
          <Modal
            modal
            modalSize="md"
            showCross
            onClose={ handleClose }
          >
            {
              map(list, (item, index) => (
                <Fragment key={ `ModalReadMore${index}` }>
                  {
                    showReadMore === item.express &&
                    item.readMore(onGetStarted, item, titleField)
                  }
                </Fragment>
              ))
            }
          </Modal>
        }
      </div>
    </div>
  );
};

export default TypesList;
