import React, { useState } from "react";
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";

const SelectInText = ({ initValue, onChange, options, namesText, nameText }) => {
  const [ value, setValue ] = useState(initValue);

  const handleOnChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const reduceWidth = (initValue <= 1) && (options[(options.length - 1)].value >= 1);

  return (
    <>
      <div className="form_section-text -margin-bottom">
        These projective exercises provide additional insight into what&apos;s working (or not) about the {nameText}.
      </div>

      <div className="form_section-text -margin-bottom -no-top-margin">
        Each respondent will answer these questions for
        <span className={ cn("form_inline-select", { '-reduce-width': reduceWidth }) }>
          <select
            value={ value }
            onChange={ handleOnChange }
          >
            {map(options, (option) => (
              <option key={ option.value } value={ option.value }>{option.name}</option>
            ))}
          </select>
        </span>
        in one session
        <div className="info-tooltip">
          <div className="info-tooltip_control">
            <SvgIcon name="i-info" />
          </div>
          <div className="info-tooltip_drop -bottom-center">
            <div className="info-tooltip_drop-inner">
              Number of {namesText} respondent will view for this set of questions.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectInText;
