import React from 'react';
import ExpressWordCloud from "./express-word-cloud";

const ScreenshotWordcloud = ({ init, keywords, sentiment = false, width = 800, height = 800 }) => {
  return (
    <ExpressWordCloud
      words={ keywords }
      noFill={ !sentiment }
      fontFamily={ init.font }
      positiveColor={ init.positive }
      neutralColor={ init.neutral }
      negativeColor={ init.negative }
      emptyColor={ init.default }
      size={ { name: 'horizontal', width, height } }
      useTranslation={ false }
    />
  );
};

export default ScreenshotWordcloud;
