import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const ConceptTags = () => {
  const { initialData, controllerName, paths } = useContext(BaseContext);
  const canManageConceptTags = initialData.can_manage_concept_tags;
  const conceptTagsPath = paths.concept_tags;
  const active = controllerName === 'concept_tags';

  return (
    <>
      {
        canManageConceptTags &&
        <li className="sidebar_nav-list-item -sub">
          <a
            className={ classnames("sidebar_nav-link -sub", { '-active': active }) }
            href={ conceptTagsPath }
          >
            Concept Tags
          </a>
        </li>
      }
    </>
  );
};

export default ConceptTags;
