import React from 'react';
import Slider from 'react-slick';

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const ExpressSlider = ({ children, customSettings }) => {
  const settings = { ...defaultSettings, ...customSettings };
  return (<Slider { ...settings }>{ children }</Slider>);
};

export default ExpressSlider;
