import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';
import { sidebarRecentClick } from "../../../google-analytics/events";

const ProjectTitle = ({ active, href, express, title, projectId }) => {
  const { initialData, gaTrackingId } = useContext(BaseContext);
  const projectType = initialData.project_types[express] || {};

  const onRecentClick = () => {
    if (active) {
      return;
    }
    sidebarRecentClick(gaTrackingId, { recent_id: projectId });
  };

  return (
    <a
      className={ classnames("sidebar_nav-link -with-icon", { '-active': active }) }
      href={ href }
      onClick={ onRecentClick }
    >
      {
        projectType.icon &&
        <svg className={ classnames("sidebar_nav-link-icon icon", projectType.klass) }>
          <use xlinkHref={ projectType.icon } />
        </svg>
      }
      <span className="sidebar_nav-link-text">
        { title }
      </span>
    </a>
  );
};

export default ProjectTitle;
