import React, { Fragment, useState, useRef } from 'react';
import TouchModal from './popup_inner/touch_modal.js';
import classnames from 'classnames';

const ImageInModal = ({className, src, children}) => {

  const [clicked, setClicked] = useState(false);
  const imgEl = useRef(null);

  return (
    <Fragment>
      <img
        className={ classnames("-force-pointer", className) }
        src={ src }
        ref={ imgEl }
        onClick={ () => { setClicked(true) } }
      />
      {
        clicked &&
        <TouchModal
          className={"-image-in-modal"}
          sourceElement={ imgEl.current }
          onClose={ () => { setClicked(false); } }
        >
          { children }
        </TouchModal>
      }
    </Fragment>
  )
}

export default ImageInModal;