import React, { useContext, useState } from 'react';
import FormControl from "../components/formControl";
import { GlobalContext } from '../contexts/appContexts';
import Footer from "../components/footer";
import Layout from "../components/layout";
import { validEmail } from '../helpers/emailHelper';
import Header from '../components/header';
import { phoneMask } from '../helpers/phoneHelper';

const SignInSignUp = () => {
  const { fakeData, nextStep, mergeData } = useContext(GlobalContext);
  const [ formEmail, setFormEmail ] = useState(true);
  const [ initialEmail, setInitialEmail ] = useState('');
  const [ initialPhone, setInitialPhone ] = useState('');
  const handleNext = () => {
    if (formEmail) {
      mergeData({ email: initialEmail });
    } else {
      mergeData({ phone: initialPhone });
    }
    nextStep();
  };

  const handleSignUpClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    mergeData({ signUpClick: true });
  };

  const handleForgotEmailClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    mergeData({ forgotEmailClick: true });
  };

  const disabledEmailNextButton = (
    (formEmail && (!initialEmail || !validEmail(initialEmail))) ||
    (!formEmail && (!initialPhone || initialPhone.length !== phoneMask.length))
  );
  const footerButtons = (
    <>
      <button
        className="button -full-width"
        disabled={ disabledEmailNextButton }
        onClick={ handleNext }
      >
        Next
      </button>
      <button className="button -full-width -white -black-border" onClick={ handleSignUpClick }>
        Sign Up
      </button>
      {
        formEmail &&
        <button className="button -link -full-width forgot-email" onClick={ handleForgotEmailClick }>
          Forgot email?
        </button>
      }
    </>
  );
  const footer = (<Footer footerButtons={ footerButtons } />);

  const handleOnEmailClick = () => {
    setInitialEmail(fakeData.email);
  };

  const handleOnPhoneClick = () => {
    setInitialPhone(fakeData.phone);
  };

  const handleSwitchInput = () => {
    setInitialPhone('');
    setInitialEmail('');
    setFormEmail(!formEmail);
  };

  const header = (<Header />);

  return (
    <Layout header={ header } footer={ footer } contentClassName="sign_in-sign_up-content">
      <div className="sign_in-sign_up">
        <div className="dark_logo">
          <svg className="dark_logo-icon">
            <use xlinkHref="#svg_logo-dark" />
          </svg>
        </div>
        <div className="title dark_title">
          <h1 className="title_h1">Log in to PayPal</h1>
        </div>
        <div className="email_form form">
          {
            formEmail ? (
              <FormControl
                disableChange
                initialText={ initialEmail }
                label="Email"
                onClick={ handleOnEmailClick }
              />
            ) : (
              <FormControl
                disableChange
                initialText={ initialPhone }
                label="Mobile number"
                type="tel"
                onClick={ handleOnPhoneClick }
              />
            )
          }
        </div>
        <button className="button -link -full-width switch-input" onClick={ handleSwitchInput }>
          {
            formEmail ? (
              <>Use phone number instead</>
            ) : (
              <>Use email instead</>
            )
          }
        </button>
      </div>
    </Layout>
  );
};

export default SignInSignUp;


