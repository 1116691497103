import React, { useContext } from 'react';
import classnames from 'classnames';
import { LocalizationContext } from '../../../common/contexts';
import SvgIcon from '../../../../../../../../../common/components/svg-icon';
import { CartContext } from '../../contexts';
import { priceView } from '../../helper';
import CartPopup from './cart_popup';

const CartManagement = () => {
  const {
    getSelectedProducts, getShowPopup, setShowPopup,
    withPrices, itemsNum, itemsSum, notFitBudget
  } = useContext(CartContext);
  const showPopup = getShowPopup();
  const products = getSelectedProducts();
  const {
    buttonEmptyCart, cartOneItemName, cartManyItemsName,
    currency
  } = useContext(LocalizationContext);
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowPopup(!showPopup);
  };

  const outOfBudget = notFitBudget();
  const totalPrice = itemsSum();
  const totalItemsCount = itemsNum();

  const selectedItemsDesktopText = () => {
    const itemName = totalItemsCount === 1 ?
      cartOneItemName : cartManyItemsName;
    const buttonName = [ `${totalItemsCount} ${itemName}` ];
    if (withPrices) {
      buttonName.push(`${priceView(currency, totalPrice)}`);
    }
    return buttonName.join(' · ');
  };

  const selectedItemsMobileText = () => (
    withPrices ? `${priceView(currency, totalPrice)}` : totalItemsCount
  );

  const mobileTextButtonKlasses = () => {
    const text = `${selectedItemsMobileText()}`;
    return {
      "-less1": text.length === 8,
      "-less2": text.length === 9,
      "-minimum": text.length > 9
    };
  };

  return (
    <div className="cart-button-wrapper">
      <button
        className={
          classnames(
            "button survey-footer_button cart-management-button",
            { "-error": outOfBudget }
          )
        }
        onClick={ handleToggle }
      >
        <SvgIcon name="shelf-cart" className="cart-icon" />
        <span className="desktop-text">
          <>
            { !products.length && buttonEmptyCart }
            { !!products.length && selectedItemsDesktopText() }
          </>
        </span>
        <span className={ classnames("mobile-text", mobileTextButtonKlasses()) }>
          { !!products.length && selectedItemsMobileText() }
        </span>
      </button>
      { showPopup && <CartPopup onClose={ handleClose } /> }
    </div>

  );
};

export default CartManagement;
