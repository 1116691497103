import React, { useState, useContext } from 'react';
import { baseContext } from '../../contexts';
import { updateFileName } from '../api';

const FileRead = ({ file, onEdit }) => {
  const { permissions } = useContext(baseContext);
  const { admin } = permissions;

  return (
    <>
      <div className="completed">
        <a href={ file.url }>
          { file.title }
          {
            admin &&
              <svg className="icon section_top-button-edit-icon -mini -for-file" onClick={ onEdit }>
                <use xlinkHref={ `#svg_edit` } />
              </svg>
          }
        </a>
      </div>
      <div className="icon-col">
        <a href={ file.url }  title="Download">
          Download
        </a>
      </div>
    </>
  );
};

const FileEdit = ({ file, afterUpdate, onCancel }) => {
  const { mediaKind, verizonType, combine5gAndLte } = useContext(baseContext);
  const [ extension ] = useState(() => {
    const arr = file.title.split('.');
    return arr.length > 1 ? `.${arr[arr.length - 1]}` : "";
  });
  const [ fileTitle, setFileTitle ] = useState(() => (
    file.title.replace(extension, '')
  ));

  const handleUpdate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFileName = `${fileTitle}${extension}`;
    updateFileName(mediaKind, verizonType, file.kind, file.id, newFileName, combine5gAndLte,
      () => {
        file.title = newFileName;
        afterUpdate(event, true);
      },
      () => {
        afterUpdate(event);
      });
  };

  const handleChange = (event) => {
    setFileTitle(event.target.value);
  };

  return (
    <>
      <div className="completed">
        <div className="form-group_control">
          <input
            type="text"
            className="form-control"
            value={ fileTitle }
            onChange={ handleChange }
          />
        </div>
      </div>
      <div className="icon-col">
        <a href="#" title="Update" onClick={ handleUpdate }>Update</a>
        <a href="#" title="Update" onClick={ onCancel }>Cancel</a>
      </div>
    </>
  );
};

const FileView = ({ file, onChange }) => {
  const [ edit, setEdit ] = useState(null);

  const handleEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setEdit(true);
  };

  const handleUpdate = (event, updated) => {
    if (updated) {
      onChange(file);
    }
    setEdit(false);
  };

  const handleCancelEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setEdit(false);
  };

  return (
    <>
      { !edit && <FileRead file={ file } onEdit={ handleEdit } /> }
      {
        edit &&
        <FileEdit file={ file } afterUpdate={ handleUpdate } onCancel={ handleCancelEdit } />
      }
    </>
  );
};

export default FileView;
