import React from "react";
import cn from "classnames";
import Checkbox from "./Checkbox";
import InfoToolTip from './infoToolTip';

const Single = ({ model, setModel, text, infoText, img }) => {
  const handleOnChangeEnabled = (newBool) => {
    setModel({ ...model, enabled: newBool });
  };

  return (
    <div className={ cn("form_metrics-item", model.default_metric_name) }>
      <Checkbox label={ text || model.default_metric_title } bool={ model.enabled } onChange={ handleOnChangeEnabled } />
      <InfoToolTip text={ infoText || model.default_metric_title } img={ img } />
    </div>
  );
};

export default Single;
