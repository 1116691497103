import React, { useState, Fragment, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { map, remove } from 'lodash';
import { IndexData } from "../index-context";
import { comparisonFiltersAddComplexFilter } from "../../../common/google-analytics/events";
import FiltersRow from './components/filters_row';
import {
  generateId, logicPrevOperatorRegEx,
  newLogicPrevOperator,
  findBetweenLogicItem,
  getFiltersUpdated,
  prepareDemoFilters
} from './helpers/filters_helper';
import { BetweenLogic, AddComplexFilter } from './components/filter_results/between_logic';

const App = ({
  classNameTop,
  classNameInner,
  children,
  combinations,
  scorecard,
  data,
  loadData,
  turfData,
  loadTurfData,
  forceDropDown,
  combinationsRef
}) => {
  const [ generatedId ] = useState(generateId);
  const [ demoFilters, setDemoFilters ] = useState(() => (prepareDemoFilters(data)));
  const { init } = useContext(IndexData);
  useEffect(() => {
    setDemoFilters(prepareDemoFilters(data));
  }, [ data ]);

  const findBetweenLogic = (filtersArray) => {
    const res = findBetweenLogicItem(filtersArray).match(logicPrevOperatorRegEx);
    return res[1];
  };
  const newDemoFilter = () => ({
    betweenLogicItem: newLogicPrevOperator(),
    filtersArray: []
  });

  const idWithIndex = (index) => (`${generatedId}With${index}`);
  const dynamicClassNameTop = (index) => {
    if (children && !index) {
      return  'concept-report_filter';
    } else if (index) {
      return '-more-bottom-padding';
    }

    return null;
  };
  const dynamicClassNameInner = children ? '-filters' : null;
  const comparisonIdentifierClass = (index) => (children || index ? null : '-custom-filters-body');

  const onFiltersUpdate = (newValue, index, noUpdate) => {
    getFiltersUpdated(data, demoFilters, loadData, newValue, index, noUpdate);
  };

  const handleUpdateBetweenLogic = (event, operator, filtersObject) => {
    filtersObject.betweenLogicItem = newLogicPrevOperator(operator);
    onFiltersUpdate(null, null, true);
  };

  const handleRemoveBetweenLogic = (event, filtersObject) => {
    remove(demoFilters, (item) => item === filtersObject);
    onFiltersUpdate(null, null, true);
  };

  const handleAddNewFilter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    demoFilters.push(newDemoFilter());
    comparisonFiltersAddComplexFilter(init.google_analytics_id, { project_id: data.project_id });
    onFiltersUpdate(null, null, true);
  };


  const renderBaseItem = (filtersArray, index) => (
    <FiltersRow
      combinations={ combinations }
      data={ data }
      turfData={ turfData }
      loadTurfData={ loadTurfData }
      forceDropDown={ forceDropDown }
      combinationsRef={ combinationsRef }
      demoFilters={ filtersArray }
      demoFiltersIndex={ index }
      filterResultsId={ idWithIndex(index) }
      onFiltersUpdate={ onFiltersUpdate }
    />
  );

  const renderAdditionalItem = (filtersArray, index) => (
    <FiltersRow
      scorecard={ scorecard }
      data={ data }
      loadData={ loadData }
      forceDropDown={ forceDropDown }
      demoFilters={ filtersArray }
      demoFiltersIndex={ index }
      filterResultsId={ idWithIndex(index) }
      onFiltersUpdate={ onFiltersUpdate }
    />
  );

  const renderWithWrapper = (filtersArray, index) => (
    <>
      {
        !index &&
        <>
          {
            !!children &&
            <div
              className={
                classnames(
                  "comparisons_plate-grid",
                  dynamicClassNameInner,
                  classNameInner
                )
              }
            >
              { renderBaseItem(filtersArray, index) }
            </div>
          }
          { !children && <>{ renderBaseItem(filtersArray, index) }</> }
        </>
      }
      { !!index && <>{ renderAdditionalItem(filtersArray, index) }</> }
    </>
  );

  return (
    <>
      {
        map(demoFilters, (filtersObject, index) => (
          <Fragment key={ `complexFilter${index}` }>
            {
              !!index &&
              <>
                <BetweenLogic
                  logicOperator={ findBetweenLogic([ filtersObject.betweenLogicItem ]) }
                  onUpdate={
                    (event, operator) => {
                      handleUpdateBetweenLogic(event, operator, filtersObject);
                    }
                  }
                  onRemove={ (event) => { handleRemoveBetweenLogic(event, filtersObject); } }
                />
              </>
            }
            <div
              className={
                classnames(
                  "comparisons_plate",
                  dynamicClassNameTop(index),
                  classNameTop
                )
              }
              id={ idWithIndex(index) }
            >
              <div
                className={
                  classnames(
                    "comparisons_plate-grid",
                    comparisonIdentifierClass(index)
                  )
                }
              >
                { renderWithWrapper(filtersObject.filtersArray, index) }
                {
                  !index && !!children &&
                  <div className="comparisons_plate-item">{children}</div>
                }
              </div>
            </div>
            {
              index === demoFilters.length - 1 && data.filter &&
              <AddComplexFilter onAdd={ handleAddNewFilter } />
            }
          </Fragment>

        ))
      }
    </>
  );
};

export default App;
