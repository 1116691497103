import React, { useContext, useState } from 'react';
import { map, cloneDeep } from "lodash";
import CheckboxPlate from "../components/chekboxPlate";
import { GlobalContext } from '../contexts/appContexts';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";

const BaseIntent = ({ headerStep, numberOfHeaderSteps }) => {
  const { fakeData, nextStep, skipStep, mergeData } = useContext(GlobalContext);
  const [ selectedCategories, setSelectedCategories ] = useState([]);

  const categoriesUpdate = (val) => {
    setSelectedCategories(val);
  };

  const handleNext = () => {
    mergeData({ intentCategories: cloneDeep(selectedCategories), intentCategoriesSkip: false });
    nextStep();
  };

  const handleSkip = () => {
    mergeData({ intentCategories: null, intentCategoriesSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const disabledNextButton = (!selectedCategories.length);
  const footerButtons = (
    <>
      <button
        disabled={ disabledNextButton }
        className="button -full-width"
        onClick={ handleNext }
      >
        Next
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  return (
    <Layout header={ header } footer={ footer }>

      <div className="base-intent">
        <div className="content_title">
          <h1>Great. What can we help you do?</h1>
        </div>
        <div className="content_text">
          Pick everything that interests you, so we can guide you.
        </div>

        <ul className="select-category">
          {
            map(fakeData.interestsList, (item, index) => (
              <li key={ index } className="select-category_item">
                <CheckboxPlate
                  name="cashback-cat"
                  item={ item }
                  selectedAnswers={ selectedCategories }
                  iconPrefix="cat"
                  checkboxChange={ categoriesUpdate }
                />
              </li>
            ))
          }
        </ul>
      </div>
    </Layout>
  );
};

export default BaseIntent;


