import React from 'react';

const ClaimsExplanation = ({n, singular}) => {
  return (
    <div className="claims-explanation">
      <p><b>See below for more information on the MaxDiff exercise.</b></p>
      <p>Positive Score &ndash; The percentage of times the {singular} was selected as <b>the best</b> option.</p>
      <p>Negative Score &ndash; The percentage of times the {singular} was selected as <b>the worst</b> option.</p>
      <p>Net score &ndash; Positive Score - Negative Score.</p>
      <p>N = { n || 0 }.</p>
    </div>
  );
};

export default ClaimsExplanation;
