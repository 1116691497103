import {
  customMetricsByLocation, defaultScorings,
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible
} from '../eligible.js';
import BaseForward from '../base_forward';
import {respondentQualified} from "../../../../common/google-analytics/events";

class Forward extends BaseForward {
  constructor(options) {
    super(options);
    this.updateConceptStep = options.updateConceptStep;
    this.updateConceptIndex = options.updateConceptIndex;
  }

  forward(step, data, result, projectId, antiFraud, gaTrackingId) {
    let newStep = step + 1, needForward = false;

    switch (newStep) {
      case 1: // title
        newStep = newStep + 1;
        break;
      case 2: //demographics
        if (!data.demographics.length) {
          needForward = true;
        }
        break;
      case 3: //optional demographics
        if (demographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.optional_demographics.length) {
          needForward = true;
        }
        break;
      case 4: //screening
        if (optionalDemographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.screenings.length) {
          needForward = true;
        }
        break;
      case 5: // Qualified
        if (screeningsNotEligible(data, result.answers, gaTrackingId)) {
          newStep = this.screenedActionNum;
          needForward = true;
        }
        break;
      case 6:
        respondentQualified(gaTrackingId, { project_id: projectId, respondent_id: data.respondent_id });
        if (customMetricsByLocation(data.survey_metrics, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 7:
        if (customMetricsByLocation(data.scorings, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 8: // Product Description
        if (!data.show_task) {
          needForward = true;
        }
        break;
      case 9: // Concepts initializing
        break;
      case 10:
        if (customMetricsByLocation(data.survey_metrics, 'default').length === 0) {
          needForward = true;
        }
        break;
      case 11:
        if (customMetricsByLocation(data.scorings, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
      case 12: // Preference
        if (!data.preference_enabled) {
          needForward = true;
        }
        break;
      case 13: // Reasons For Preference
        if (!data.preference_enabled || !data.preference_reasons) {
          needForward = true;
        }
        break;
      case 14:
        if (customMetricsByLocation(data.survey_metrics, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
    }

    [ newStep, needForward ] = this.checkLastSteps(
      this, newStep, needForward, 15, data, result, projectId, antiFraud
    );
    if (!newStep) {
      return;
    }

    if (needForward) {
      this.forward(newStep, data, result, projectId, antiFraud);
    } else {
      this.updateStep(newStep);
    }
  }

  nextConceptStep(conceptStep, conceptIndex, concept, data, goForward) {
    let nextStep = conceptStep + 1, needForward = false;

    switch (nextStep) {
      case 1: // Thought Bubble
        if (!concept.show_thought_bubble) {
          needForward = true;
        }
        break;
      case 2: // Five-point scales
        if (!defaultScorings(data.scorings).length) {
          needForward = true;
        }
        break;
      case 3: // Attribute Ratings
        if (!data.attribute_ratings.length) {
          needForward = true;
        }
        break;

      case 4: //Next concept
        if (conceptIndex === data.concepts.length - 1) {
          goForward();
          return;
        }

        this.updateConceptIndex(conceptIndex + 1);

        nextStep = 0;
        break;
    }

    if (needForward) {
      this.nextConceptStep(nextStep, conceptIndex, concept, data, goForward);
    } else {
      this.updateConceptStep(nextStep);
    }
  }
}
export default Forward;
