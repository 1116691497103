import React, { useContext } from 'react';
import { HexColorPicker } from "react-colorful"; // https://github.com/omgovich/react-colorful
import { BaseColorPickerContext } from '../contexts';

const ColorSelector = ({
  color, updateColor, onApply, onCancel
}) => {
  const { updateRanges } = useContext(BaseColorPickerContext);
  const handleOnBlur = () => {
    updateRanges();
  };
  const handleOnTextMouseClick = (event) => {
    event.stopPropagation();
  };
  const handleChangeText = (event) => {
    updateColor(event.target.value, true);
  };

  return (
    <div className="color-picker-mini">
      <div
        onMouseDown={ updateRanges }
        onMouseUp={ updateRanges }
      >
        <HexColorPicker
          color={ color }
          onChange={ updateColor }
        />
      </div>
      <input
        onClick={ handleOnTextMouseClick }
        onBlur={ handleOnBlur }
        className="manual-color-set"
        type="text"
        maxLength="7"
        value={ color }
        onChange={ handleChangeText }
      />
      <div className="color-buttons -react">
        <button
          type="button"
          className="button"
          onClick={ onApply }
        >
          Apply
        </button>
        <button
          type="button"
          className="button -secondary"
          onClick={ onCancel }
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ColorSelector;
