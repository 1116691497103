import React, { useContext, useState } from 'react';
import FormControl from "../components/formControl";
import { GlobalContext } from '../contexts/appContexts';
import Footer from "../components/footer";
import Layout from "../components/layout";
import { validEmail } from '../helpers/emailHelper';
import Header from '../components/header';

const Email = () => {
  const { fakeData, nextStep, currentData, mergeData } = useContext(GlobalContext);
  const [ initialEmail, setInitialEmail ] = useState(currentData.email || '');
  const onNext = () => {
    mergeData({ email: initialEmail });
    nextStep();
  };
  const disabledEmailNextButton = (!initialEmail || !validEmail(initialEmail));
  const footerButtons = (
    <button className="button -full-width" disabled={ disabledEmailNextButton } onClick={ onNext }>
      Next
    </button>
  );
  const footer = (<Footer footerButtons={ footerButtons } />);

  const handleOnClick = () => {
    setInitialEmail(fakeData.email);
  };

  const header = (<Header />);

  return (
    <Layout header={ header } footer={ footer }>
      <div className="email">
        <div className="email_logo">
          <svg className="email_logo-icon">
            <use xlinkHref="#svg_logo" />
          </svg>
        </div>
        <div className="title email_title">
          <h1 className="title_h1">Let’s get started.</h1>
          <div className="title_hint">
            <span>United States</span>
            {/*<button className="button-link -margin-left">Change</button>*/}
          </div>
        </div>
        <div className="email_form form">
          <FormControl
            disableChange
            initialText={ initialEmail }
            label="Enter your email"
            onClick={ handleOnClick }
          />
        </div>
      </div>
    </Layout>
  );
};

export default Email;


