import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { find, map, compact, filter, debounce } from 'lodash';

const customStyles = {
  container: () => ({
    padding: 0,
    minHeight: '38px',
    background: '#fff',
    border: '1px solid #e5e5e5',
    borderRadius: '18px',
    transition: 'border-color .3s,color .3s,background .3s,background-color .3s',
    position: 'relative',
    boxSizing: 'border-box'
  }),
  control: (provided) => {
    return { ...provided, backgroundColor: 'none', borderWidth: 0, boxShadow: 'none' };
  },
  input: (provided) => {
    return { ...provided, backgroundColor: 'none', borderWidth: 0 };
  },
  placeholder: (provided) => {
    return { ...provided, fontSize: '14px', fontWeight: 'normal' };
  },
  option: (provided) => {
    return { ...provided, fontSize: '14px', fontWeight: 'normal' };
  },
  multiValue: (provided) => {
    return { ...provided, backgroundColor: 'none', fontWeight: 'normal', fontSize: '14px', border: '1px solid #e5e5e5', borderRadius: '15px', margin: '2px' };
  },
  multiValueRemove: (provided) => {
    return { ...provided, ':hover': { color: '#c5c6c6' }, color: '#c5c6c6' };
  },
  clearIndicator: (provided) => {
    return { ...provided, color: '#e5e5e5' };
  }
};

const TagsInput = ({ initTags, onTagsChange, loadKeywords, autoTag, setAutoTag, placeholder }) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ value, setValue ] = useState([]);
  const [ defaultOptions, setDefaultOptions ] = useState([]);

  const debouncedSearch = debounce(({ inputValue, resolve }) => {
    loadKeywords(inputValue, resolve);
  }, 300);

  const promiseOptions = (inputValue) => (
    new Promise((resolve) => {
      debouncedSearch({ inputValue, resolve });
    })
  );

  const onChange = (values) => {
    const newValues = filter(values, (el) => (el.value));

    const tags = map(newValues, (v) => v.value);

    if (!!autoTag && !find(tags, (el) => (el === autoTag))) {
      setAutoTag(null);
    }

    onTagsChange(tags);
    setValue(newValues);
  };

  const onFocus = () => {
    if (!loaded) {
      promiseOptions("").then((defaultOptions) => {
        setLoaded(true);
        setDefaultOptions(defaultOptions);
      });
    }
  };

  useEffect(() => {
    const exTags = compact(initTags);
    setValue(map(exTags, (t) => ({ value: t, label: (t.text || t) })));
  }, [ initTags ]);

  useEffect(() => {
    if (autoTag !== undefined && autoTag !== null) {
      const exEl = find(value, (el) => (el.value === autoTag));
      if (!exEl) {
        const newValues = [ ...value, { value: autoTag, label: autoTag } ];
        setValue(newValues);
        const tags = map(newValues, (v) => v.value);
        onTagsChange(tags);
      }
    }
  }, [ autoTag ]);

  return (
    <AsyncSelect
      isMulti
      defaultOptions={ defaultOptions }
      onFocus={ onFocus }
      loadOptions={ promiseOptions }
      onChange={ onChange }
      value={ value }

      placeholder={ placeholder || "Filter By Keywords" }
      noOptionsMessage={ () => ("Type query") }
      styles={ customStyles }
    />
  );
};

export default TagsInput;
