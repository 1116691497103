import React from 'react';
import classnames from 'classnames';
import { BaseContext } from './contexts';
import Header from './components/header';
import Recents from './components/recents';
import BottomMenu from './components/bottomMenu';

const Sidebar = ({ initial_data, paths, projects_count }) => {
  const contextValue = {
    titleAll: initial_data.title_all,
    menu: initial_data.menu,
    platform: initial_data.platform,
    verizon: initial_data.verizon,
    customerCase: initial_data.customer_case,
    controllerName: initial_data.controller_name,
    actionName: initial_data.action_name,
    currentProject: initial_data.project,
    initialData: initial_data,
    paths,
    projectsCount: projects_count,
    gaTrackingId: initial_data.google_analytics_id
  };
  const sidebarKlass = contextValue.initialData.sidebar_klass;
  return (
    <BaseContext.Provider value={ contextValue }>
      <aside className={ classnames("sidebar", sidebarKlass) }>
        <Header>
          <Recents />
        </Header>
        <BottomMenu />
      </aside>
    </BaseContext.Provider>
  );
};

export default Sidebar;
