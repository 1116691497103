import React, { useState } from 'react';
import classnames from 'classnames';
import Modal from '../../../../common/components/modal';
import TypesList from './types-list';

const TypesModal = ({ onCreate, onClose, projectsAllowance }) => {
  const [ hideBaseModal, setHideBaseModal ] = useState(false);

  const handleShowInfo = () => {
    setHideBaseModal(true);
  };

  const handleCloseInfo = () => {
    setHideBaseModal(false);
  };

  return (
    <Modal
      dialogClassName={ classnames("modal_dialog -create-new-project", { "-force-hide": hideBaseModal }) }
      showCross
      onClose={ onClose }
    >
      <TypesList
        title="Select one of these options:"
        onCreate={ onCreate }
        className="-modal"
        onShowInfo={ handleShowInfo }
        onCloseInfo={ handleCloseInfo }
        projectsAllowance={ projectsAllowance }
        forceShowInTypeAfterClickCreate
      />
    </Modal>
  );
};

export default TypesModal;
