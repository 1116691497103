import React, { useContext, Fragment } from 'react';
import cn from "classnames";
import { map } from 'lodash';
import { getMetricKind, IndexData } from "../../index-context";
import StatusCircle from "../../components/status-circle";
import { showedAverageValue } from '../../helpers';

const TrPreference = ({ data, checkedConcepts, preference }) => {
  const { sort, averageMode, init } = useContext(IndexData);

  return (
    <>
      <tr className="-highlighted">
        <td
          colSpan={ checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1 }
        >
          { getMetricKind(data, 'preference') }
        </td>
        {
          checkedConcepts.length > 3 &&
          <td colSpan={ checkedConcepts.length + 1 - 3 } />
        }
        <td className="table-comparison_average-cell -highlighted" />
      </tr>

      {
        map(preference, (values, title) => (
          <Fragment key={ title }>
            <tr>

              <td className="table-comparison_name-cell">
                <span className={ cn({ 'sort-link -current': title === sort }) }>
                  {title}
                </span>
              </td>
              {
                map(checkedConcepts, (concept, i) => (
                  <td key={ i }>
                    <StatusCircle values={ values[concept.id] } box="top2" />
                  </td>
                ))
              }

              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
              </td>
            </tr>
          </Fragment>
        ))
      }
    </>
  );
};

export default TrPreference;
