import React from 'react';
import { createRoot } from 'react-dom/client';

window.reactRender = (componentPath, props = {}, idExisting = null) => {
  const App = require(`../${componentPath}`).default;
  const body = document.body || document.querySelector("body");
  let newDiv;

  if (idExisting) {
    newDiv = document.getElementById(idExisting);
  } else {
    newDiv = document.createElement("div");
    body.appendChild(newDiv);
  }
  const root = createRoot(newDiv);
  root.render(<App { ...props } />);
};
