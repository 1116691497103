import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { slice } from 'lodash';

const Phone = ({ title, value, onChange, hint, error }) => {
  return (
    <div className="form_group">
      <label className="form-label">{ title }</label>
      <PhoneInput
        placeholder=""
        countryCodeEditable={false}
        country={ 'us' }
        preferredCountries={['us', 'ca']}
        value={value}
        onChange={ (value, country) => { onChange(value, slice(value, country.dialCode.length).join()) } }
      />
      {
        hint &&
        <div className="form_hint">{ hint }</div>
      }
      {
        error &&
        <div className="form-error">{error}</div>
      }
    </div>
  )
};

export default Phone;