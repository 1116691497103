import React, { useContext, useState, useEffect, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { map, fill, each } from 'lodash';
import Perk from '../common/perk';
import Plan from '../common/plan';
import PlanWithPerks from '../common/planWithPerks';
import { LineDataContext } from './contexts';
import { AllPlansInclude } from './allPlansInclude';
import { getPerksCountInPlan, planById } from './utils';

const Overview = ({
  onPlanSelect, onPerkSelectOuter, currentLine,
  selectedPlanIdByLine, selectedPerksIdByLine,
  customHeader, showPerksInPlan, reselectPerksInPlan,
  keepPerksWhenPlanReselect
}) => {
  const {
    plans, perks, getPlanPriceByLine,
    perksSectionTitle, perksSectionText,
    perksSectionTitleDynamic, perksSectionTextDynamic
  } = useContext(LineDataContext);

  const selectedPlan = planById(plans, selectedPlanIdByLine);

  const planPerkCount = getPerksCountInPlan(plans, selectedPlanIdByLine);

  const perksFill = (currentPerks) => {
    const preFilledArray = planPerkCount ? fill(Array(planPerkCount), null) : [];
    if (currentPerks && currentPerks.length) {
      each(currentPerks, (item, index) => {
        preFilledArray[index] = item;
      });
    }
    return preFilledArray;
  };

  const [ selectedPerks, setSelectedPerks ] = useState([]);

  const perksListRef = useRef();

  smoothscroll.polyfill(); // For animated scrolls in safari and others

  const handlePlanSelect = (event, planObject) => {
    onPlanSelect(event, planObject, selectedPerks);
    if (!keepPerksWhenPlanReselect) {
      setSelectedPerks(perksFill());
    }
    if (planObject.perksCount > 0) {
      document.getElementById("content").scrollTo(
        { top: perksListRef.current.offsetTop, left: 0, behavior: 'smooth' }
      );
    }
  };

  const handlePlanWithPerksSelect = (event, planObject, perksList) => {
    onPlanSelect(event, planObject);
    setSelectedPerks(perksList);
  };

  const onPerkSelect = (perkId, mode) => {
    if (mode === "change") {
      setSelectedPerks([ perkId ]);
    } else if (mode === "select") {
      const indexOfNull = selectedPerks.indexOf(null);
      if (indexOfNull !== -1) {
        setSelectedPerks((oldList) => {
          oldList[indexOfNull] = perkId;
          return [ ...oldList ];
        });
      }
    } else if (mode === "deselect") {
      setSelectedPerks((oldList) => {
        const index = oldList.indexOf(perkId);
        if (index !== -1) {
          oldList[index] = null;
        }
        return [ ...oldList ];
      });
    }
  };

  useEffect(() => {
    onPerkSelectOuter(selectedPerks, selectedPlan);
  }, [ selectedPerks ]);

  useEffect(() => {
    if (!showPerksInPlan) {
      setSelectedPerks(perksFill(selectedPerksIdByLine));
    }
  }, [ currentLine, selectedPlanIdByLine ]);

  return (
    <div className="overview">

      <div className="overview_section">
        {
          customHeader || (
            <div className="overview_section-header">
              <h3 className="overview_section-title">
                Select your plan
              </h3>
              <div className="overview_section-description">
                <AllPlansInclude />
              </div>
            </div>
          )
        }

        <div className="overview_plans">
          {showPerksInPlan ? (
            map(plans, (plan) => (
              <PlanWithPerks
                key={ `overviewPlanItem${plan.id}${currentLine}` }
                plan={ plan }
                onPlanSelect={ handlePlanWithPerksSelect }
                classNameWrapper="overview_plans-item"
                selectedPlanIdByLine={ selectedPlanIdByLine }
                planPrice={ getPlanPriceByLine(plan, currentLine) }
                reselectPerksInPlan={ reselectPerksInPlan }
              />
            ))

          ) : (
            map(plans, (plan) => (
              <Plan
                key={ `overviewPlanItem${plan.id}` }
                plan={ plan }
                onPlanSelect={ handlePlanSelect }
                classNameWrapper="overview_plans-item"
                selectedPlanIdByLine={ selectedPlanIdByLine }
                planPrice={ getPlanPriceByLine(plan, currentLine) }
              />
            ))
          )}

        </div>
      </div>

      <div className="overview_section" ref={ perksListRef }>
        <div className="overview_section-header">
          <h3 className="overview_section-title">
            {
              perksSectionTitleDynamic ?
                perksSectionTitleDynamic(selectedPlan, selectedPerks) :
                perksSectionTitle
            }
          </h3>
          <div className="overview_section-description">
            {
              perksSectionTextDynamic ?
                perksSectionTextDynamic(selectedPlan) :
                perksSectionText
            }
          </div>
        </div>

        <div className={ `overview_perks` } >
          {
            map(perks, (perk) => (
              <Perk
                key={ `perkItem${perk.id}` }
                perk={ perk }
                onPerkSelect={ onPerkSelect }
                classNameWrapper="overview_perks-item"
                availablePerksCount={ planPerkCount }
                selectedPerks={ selectedPerks }
                readOnly={ showPerksInPlan }
                noBorder={ showPerksInPlan }
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Overview;
