import React, { useState } from 'react';
import classnames from 'classnames';
import { generateId } from '../../../common/dateUtils';
import InfoToolTip from './infoToolTip';

const Toggle = (
  { className, children, checked, onChange, hint, hintPosition = 'top-right' }
) => {
  const [ id ] = useState(() => (generateId()));
  const tagId = `custom_text_switcher${id}`;
  const handleChange = (event) => {
    onChange(!checked, event);
  };
  return (
    <div className={ classnames("toggle-component-wrapper", className) }>
      <div className="toggle">
        <input
          type="checkbox"
          id={ tagId }
          checked={ checked || false }
          onChange={ handleChange }
          className="hide"
        />
        <label className="form-switch" htmlFor={ tagId } />
        <label className="form-switch-text -pointer" htmlFor={ tagId }>{ children }</label>
      </div>
      {
        !!hint &&
        <InfoToolTip
          remote
          text={ hint }
          position={ hintPosition }
        />
      }
    </div>
  );
};

export default Toggle;
