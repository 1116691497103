import { screenedActionNum, brandScreenedActionNum, lastStepsLogic } from './last_steps';
import { screeningIsEligible } from './eligible';

class BaseForward {
  constructor({ updateQuestionIndex, updateStep, runSave }) {
    this.updateQuestionIndex = updateQuestionIndex;
    this.updateStep = updateStep;
    this.runSave = runSave;
    // No need to have -1 as lastSteps will be processed in the function's body after the first switch
    this.screenedActionNum = screenedActionNum;
    this.brandScreenedActionNum = brandScreenedActionNum;
  }
  nextQuestion(index, allQuestions, result, goForward) {
    const last = index === allQuestions.length - 1;
    if (last || !screeningIsEligible(allQuestions[index], result.answers)) {
      this.updateQuestionIndex(0);
      goForward();
    } else {
      this.updateQuestionIndex(index + 1);
    }
  }

  checkLastSteps(instance, currentStep, needForward, recaptchaStep, data, result, projectId, antiFraud) {
    return lastStepsLogic(instance, currentStep, needForward, recaptchaStep, data, result, projectId, antiFraud);
  }

  // private

  nextItem(step, size, setStep, goForward) {
    const last = step === size - 1;
    if (last) {
      setStep(0);
      goForward();
    } else {setStep(step + 1);}
  }
}

export default BaseForward;
