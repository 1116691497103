import React from 'react';
import classnames from 'classnames';

const Button = ({ onClick, className, title }) => {
  const buttonTitle = title ? title : (
    <svg className="icon -arrow-next button_icon">
      <use xlinkHref="#svg_arrow-next" />
    </svg>
  );
  return (
    <button
      className={ classnames("button -sm form_button", className) }
      onClick={ onClick }
    >
      { buttonTitle }
    </button>
  );
};

export default Button;
