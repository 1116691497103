import React from 'react';

const CardPlanTitle = ({ name, price, planPerksType, className, tipInTwoLines, children }) => {
  return <div className={`choose-plan-card-header ${className ? className : ''}`}>
    <div className="choose-plan-card-title-wrapper">
      <h6 className="choose-plan-card-title">{name}</h6>
      {planPerksType && (
        <div className="choose-plan-card-perks-type">{planPerksType}</div>
      )}
    </div>
    <div className="choose-plan-card-subtitle">
      <span className="choose-plan-card-price">${price}</span>
      <span className="choose-plan-card-price-tip">
        {tipInTwoLines ? (
          "/line per month\nPlus taxes & fees."
        ) : (
          "/line per month plus taxes & fees."
        )}
      </span>
    </div>
    {children}
    <div className="hr -secondary -no-margin"/>
  </div>
};

export default CardPlanTitle;