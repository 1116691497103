import React from 'react';
import classnames from 'classnames';
import { RemoveButton } from './section_logic';

const AtLeast = ({
  index, title, minNumber, maxNumber, selectedNumber,
  onChange, removeSection
}) => {
  const  itemsToChoose = [];

  for (let j = minNumber; j <= maxNumber; j++) {
    itemsToChoose.push(
      <li
        key={ `selectedAnswersIndex${index}_${j}` }
        className={ classnames('search-choice -at-least-item', { "-active": selectedNumber == j }) }
        onClick={ (event) => { onChange(event, j); } }
      >
        <span>{ j }+</span>
      </li>
    );
  }

  return (
    <div className="chosen-container chosen-container-multi comparasion_filter localytics-chosen chosen-container-active chosen-with-drop -low-index filter-item">
      <ul className="chosen-choices -no-dropdown">
        <li className="search-choice -title">
          <span>{ title }</span>
          <span>(selected options)</span>
        </li>
        { itemsToChoose }
      </ul>
      <div className="control-panel">
        <RemoveButton removeSection={ removeSection } />
      </div>
    </div>
  );
};

export default AtLeast;
