import React, { useContext, useEffect } from 'react';
import NativeModal from "../components/nativeModal";
import { GlobalContext } from "../contexts/appContexts";

const AccessContacts = ({ nextStep }) => {
  const { mergeData } = useContext(GlobalContext);

  useEffect(() => {
    mergeData({ accessContactsShown: true });
  }, []);

  const handleNotAllow = () => {
    mergeData({ accessContactsAllow: false });
    nextStep();
  };

  const handleOk = () => {
    mergeData({ accessContactsAllow: true });
    nextStep();
  };

  const nativeModalButtons = (
    <>
      <button className="button-native" onClick={ () => { handleNotAllow(); } }>
        Don't Allow
      </button>
      <button className="button-native -bold" onClick={ () => { handleOk(); } }>
        OK
      </button>
    </>
  );

  return (
    <div className="native-notification-background">
      <NativeModal buttonsAsRows buttons={ nativeModalButtons }>
        <>
          <h2>
            PayPal would Like to access your Contacts
          </h2>
          <p>
            We will securely upload your contacts and keep them updated in PayPal.
            { ' ' }
            We’ll never share this info.
          </p>
        </>
      </NativeModal>
    </div>
  );
};

export default AccessContacts;


