import React, { useContext, useEffect, useRef } from 'react';
import downloadStarts from "../download-messaging";
import Http from "../../../../common/http";
import { IndexData } from '../index-context';
import { MarkupData } from "./concept-tab-markup/markups-logic";
import DropdownButton from './dropdown-button';

const ConceptTabs = ({
  selectedId, selectedConceptId, selectedTool, demographics,
  loadData, loadComments, download, tab, setTab, mcRef
}) => {
  const { showPositive, showNegative, selectedRegion } = useContext(MarkupData);
  const tourElement = useRef(null);
  const { getTourUseEffectArgs } = useContext(IndexData);

  const [ callbackHeatmap, conditionHeatmap ] = getTourUseEffectArgs(
    "projectModalRadioTab", tourElement
  );
  useEffect(callbackHeatmap, conditionHeatmap);

  const selectedFocusConceptId = selectedTool.concepts[selectedConceptId].id;

  const onSettingsChange = (selectedTab) => {
    if (mcRef) {
      mcRef.current.scroll({ top: 0 });
    }

    setTab(selectedTab);
    loadData(
      null,
      {
        concept_id: selectedId || selectedConceptId,
        tool_id: selectedTool.id,
        tab: selectedTab
      },
      null,
      null,
      {},
      () => {
        loadComments(selectedTab);
      }
    );
  };

  const openStdReport = (event, reportName) => {
    event.preventDefault();
    event.stopPropagation();

    const params = {
      report: reportName,
      concept_ids: [ selectedFocusConceptId ],
      existing_settings: {
        filter: demographics,
        selected_reactions: { positive: showPositive, negative: showNegative },
        markup_type: 'area_and_text',
        selected_region_id: selectedRegion.value.id
      }
    };

    Http.post(selectedTool.heatmap_report_url, params).then(
      (http) => {
        if (http.response.status === 200) {
          downloadStarts(event, download);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  return (
    <div className="comparisons_plate">
      <div className="comparisons_plate-grid">
        <div className="comparisons_plate-item">
          <div className="radio-tabs" ref={ tourElement }>
            <label className="radio-tabs_item">
              <input
                type="radio"
                checked={ tab === 'heatmap' }
                onChange={ () => {onSettingsChange('heatmap');} }
              />
              <div className="radio-tabs_label -focus-tabs">
                Heatmap
              </div>
            </label>
            <label className="radio-tabs_item">
              <input
                type="radio"
                checked={ tab === 'wordcloud' }
                onChange={ () => {onSettingsChange('wordcloud');} }
              />
              <div className="radio-tabs_label -focus-tabs">
                Word Cloud
              </div>
            </label>
            <label className="radio-tabs_item">
              <input
                type="radio"
                checked={ tab === 'markups' }
                onChange={ () => {onSettingsChange('markups');} }
              />
              <div className="radio-tabs_label -focus-tabs">
                Individual Markups
              </div>
            </label>
          </div>
        </div>

        <div className="comparisons_plate-item -with-export-button">
          <DropdownButton
            innerClassName="comparisons_plate-button"
            buttonName="Export"
            tourName="projectCFExport"
          >
            <>
              <li>
                <span>Heatmaps and Word Clouds Report</span>
                <span>
                  <a
                    className="-export -ppt"
                    onClick={ ($event) => {openStdReport($event, 'pptxHeatmap');} }
                  >
                    PPT
                  </a>
                </span>
              </li>
              <li>
                <span>Individual Markups Report</span>
                <span>
                  <a
                    className="-export -ppt"
                    onClick={ ($event) => {openStdReport($event, 'pptxMarkup');} }
                  >
                    PPT
                  </a>
                  <a
                    className="-export -xlsx"
                    onClick={ ($event) => { openStdReport($event, 'xlsxMarkup'); } }
                  >
                    XLSX
                  </a>
                </span>
              </li>
              {
                tab === 'markups' &&
                <li className="-notice">
                  Any filters/regions applied below will also
                  { ' ' }
                  be applied to the exports.
                </li>
              }
            </>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default ConceptTabs;
