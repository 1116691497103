import React, { useState } from 'react';
import cn from "classnames";
import SvgIcon from "../../../common/components/svg-icon";

const FieldHeaderWithText = ({ id, title, hint, placeholder, errors, initValue, onChange }) => {
  const [ v, setV ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setV(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full">
          {
            !!title &&
            <div className="form_section-title -grid">
              <div className="form_section-title-item">
                <h3>{title}</h3>
              </div>
              {hint &&
                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <SvgIcon name="i-info" />
                    </div>

                    <div className="info-tooltip_drop">
                      <div className="info-tooltip_drop-inner">
                        {hint}
                      </div>
                    </div>
                  </div>
                </div>}
            </div>
          }
          <div className={ cn("form-group", errors ? 'has-error' : '') }>
            <input
              id={ id }
              placeholder={ placeholder }
              className="form-field -block"
              maxLength="250"
              size="250"
              type="text"
              value={ v }
              onChange={ handleOnChange }
            />
            <div className="form-error">{errors}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldHeaderWithText;
