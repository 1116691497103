import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ModalWindow = (props) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));
  const [ backElement ] = useState(document.createElement('div'));

  useEffect(() => {
    body.className = "modal-open";
    element.className = "modal";
    element.setAttribute("style", "z-index: 1050; display: block;");
    body.appendChild(element);
    backElement.className = "modal-backdrop in";
    backElement.setAttribute("style", "z-index: 1040");
    body.appendChild(backElement);
    return () => {
      body.className = "";
      body.removeChild(element);
      body.removeChild(backElement);
    };
  }, []);
  return createPortal(props.children, element);
};

const Popup = ({ children, onClose, className }) => {
  return (
    <ModalWindow>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className={ className || "popup-plan-details" }>
            {
              <div className="popup-close" onClick={ onClose }  />
            }
            { children }
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default Popup;
