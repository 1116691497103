import React from 'react';
import renderRawHtml from "./render_raw_html";


const Tooltip = ({ className, children }) => {
  return (
    <span className={ `tooltip ${className ? className : ''} ${children ? '-hover' : ''}` }>
      <svg className={ `icon -info` }>
        <use xlinkHref={ `#svg_info` } />
      </svg>
      {children && (
        <span className="tooltip_content">
          <span
            className="tooltip_content-inner"
            { ...renderRawHtml(children) }
          />
        </span>
      )}
    </span>
  );
};

export default Tooltip;
