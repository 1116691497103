import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

const Portal = ({ children }) => {
  const body = document.querySelector('body');
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    element.id = 'portal-element';
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.style.overflow = '';
      body.removeChild(element);
    };
  }, []);
  return createPortal(children, element);
};

const PortalModal = ({
  title,
  open, innerHideMode, showInner,
  headerContent, className, saveClassName,
  children,
  buttonName, labelDelete, hideFooter,
  disabledSave, showDelete, disabledDelete,
  saveCallback, handleClose, deleteItem,
  error
}) => {
  const handleSave = (event) => {
    saveCallback(event);
  };

  return open
    ? <Portal>
      <div className={ classnames("portal", className) }>
        <div className="portal-backdrop" onClick={ handleClose } />
        <div className={ classnames("portal-inner", { "-fade-out": innerHideMode }, { "-fade-in": showInner }) }>
          <div className="portal-container">
            <div className="portal-header">
              <div className="portal-header_grid">
                { title && <h6 className="portal-title">{ title }</h6>}
                <button className="portal-close" onClick={ handleClose } type="button">
                  <svg className="icon portal-close_icon">
                    <use xlinkHref={ `#svg_close` } />
                  </svg>
                </button>
              </div>
            </div>
            {
              error &&
                <div className="portal-error">
                  <h3>{ error }</h3>
                </div>
            }
            {
              headerContent &&
                <div className="portal-header-content">{ headerContent }</div>
            }
            <div className="portal-content">{ children }</div>
            {
              !hideFooter &&
                <div className={ classnames("portal-footer", { "-no-delete-button": !showDelete }) }>
                  {
                    showDelete &&
                    <div className="portal-footer_buttons -left">
                      <button className="button -sm -upper -gray" onClick={ deleteItem } type="button" disabled={ disabledDelete }>
                        { labelDelete || "Delete" }
                      </button>
                    </div>
                  }
                  <div className="portal-footer_buttons">
                    <button className="button -sm -upper -gray" onClick={ handleClose }>
                      Cancel
                    </button>
                    <button
                      className={ classnames("button -sm -upper", saveClassName) }
                      onClick={ handleSave }
                      disabled={ disabledSave }
                    >
                      { buttonName || "Save" }
                    </button>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </Portal>
    : null;
};

export default PortalModal;
