import React, { Fragment, useContext } from 'react';
import { map } from 'lodash';
import renderRawHtml from "../../../../../common/render_raw_html";
import classnames from "classnames";

const UnlimitedPlan = ({ planObject }) => {
  return(
    <div className="base-plan -no-line">
      <h2 className="-font-24 title ng-binding" { ...renderRawHtml(planObject.title) }>
      </h2>
      <div className="text-14 -concept-a text-description" { ...renderRawHtml(planObject.description) }>
      </div>
      <div className="features">
        {
          map([...planObject.features, ...planObject.perks], (feature, index) => {
            return(
              <Fragment key={ `unlimitedPlan${planObject.id}feature${index}` }>
                {
                  feature.before &&
                  <div
                    className={ classnames(`text-14 -concept-a feature -bold`) }
                    { ...renderRawHtml(feature.before) }
                  >
                  </div>
                }
                <div
                  className={ classnames(`text-14 -concept-a feature`, { '-big': feature.big }, { '-bigger': feature.bigger }) }
                  { ...renderRawHtml(feature.title) }
                >
                </div>
              </Fragment>

            )
          })
        }
      </div>
      <div className="dollar-price">
        <h2 className="value -big ng-binding">${ planObject.price }</h2>
        <div className="text-10 text ng-scope" ng-if="planIndex != state.splitBasePlans.length - 1">
          Per line per month.
          <br/>
          Plus taxes & fees.
        </div>
      </div>
    </div>
  )
};

const UnlimitedPart = ({ allList, list, onClick }) => {
  return (
    <Fragment>
      <h2 className="main-title">Unlimited data plans</h2>
      <div className="base-plans-header">
        <h2 className="-big base-plans-title">
          Everyone in the family gets the plan they need.
        </h2>
        <div className="text-16 -concept-a base-plans-description">
          Mix and match Unlimited plans on the network more people rely on. Plus get the best entertainment, on us.
        </div>
        <button className="select-billing-plan get-started" onClick={ onClick }>Get started</button>
      </div>
      <div className="base-plans-body">
        <div className="base-plans-all-description">
          <h4 className="base-plans-all-header">All Unlimited Plans Include</h4>
          <ul className="text-14 -concept-a">
            {
              map(allList, (item, index) => {
                return (
                  <li key={`allIncludeUnlimited${index}`} {...renderRawHtml(item)}>
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="base-plans">
          {
            map(list, (planObject) => {
              return (
                <UnlimitedPlan key={`unlimitedPlan${planObject.id}`} planObject={planObject} />
              );
            })
          }
        </div>
      </div>
    </Fragment>
  )
};

export default UnlimitedPart;