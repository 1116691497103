import React, { useContext } from "react";
import cn from "classnames";
import { IndexData } from "../../index-context";
import { comparisonScorecardCfChangeReactions } from "../../../../common/google-analytics/events";
import { MarkupData } from "./markups-logic";
import { colors } from "./const";

const Switchers = ({ conceptId }) => {
  const {
    showPositive, onShowPositiveChange,
    showNegative, onShowNegativeChange
  } = useContext(MarkupData);
  const { init } = useContext(IndexData);

  const handleClick = () => {
    comparisonScorecardCfChangeReactions(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: conceptId }
    );
  };

  return (
    <div className="markups_plate">
      <div className="markups_plate-grid">
        <div className="markups_plate-item">
          <label
            className={
              cn("form-switch-group -success", { color: colors.positive })
            }
          >
            <input
              type="checkbox"
              className="hide survey-reactions-control_checkbox -like"
              checked={ showPositive }
              onClick={ handleClick }
              onChange={ onShowPositiveChange }
            />
            <div
              className={
                cn(
                  "form-switch -success",
                  {
                    'background-color': showPositive,
                    color: colors.positive
                  }
                )
              }
            />
            Positive
          </label>

          <label
            className={
              cn("form-switch-group -danger", { color: colors.negative }) }
          >
            <input
              className="hide survey-reactions-control_checkbox -dislike"
              id="survey-reaction-negative"
              checked={ showNegative }
              onClick={ handleClick }
              onChange={ onShowNegativeChange }
              type="checkbox"
            />
            <span
              className={
                cn(
                  "form-switch -danger",
                  {
                    'background-color': showNegative,
                    color: colors.negative
                  }
                )
              }
            />
            Negative
          </label>
        </div>
      </div>
    </div>
  );
};

export default Switchers;
