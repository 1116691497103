import ConceptBase from '../concept_base';

class BrandSnapshot extends ConceptBase {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  totalPrice() {
    return this.priceData.base_rate + this.size;
  }

  sizeCalcPart() {
    return 1;
  }
}

export default BrandSnapshot;
