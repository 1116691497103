import React, { Fragment, useContext } from "react";
import { map } from "lodash";
import cn from "classnames";
import { selectGroup, selectKind, IndexData } from '../../../index-context';
import { averageCount, n0, showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';

const TrShelf = ({ checkedConcepts }) => {
  const { init, data, averageMode } = useContext(IndexData);

  const shelf = selectKind(data, 'express_shelf_found');
  const time = selectKind(data, 'express_shelf_time');
  const easeToFind = selectGroup(data, 'five_point_scale', true);

  return (
    <>
      {
        map(shelf, (values, title) => (
          <tr key={ title }>
            <td className="table-comparison_name-cell">
              <span>{data.titles.found}</span>
            </td>
            {
              map(checkedConcepts, (concept) => (
                <td key={ `${title}${concept.id}` }>
                  <StatusCircle values={ values[concept.id] } box="top2" />
                </td>
              ))
            }
            <td className="table-comparison_average-cell">
              { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
            </td>
          </tr>
        ))
      }

      {
        map(time, (values, title) => (
          <tr key={ title }>
            <td className="table-comparison_name-cell">
              <span>{data.titles.average_time}</span>
            </td>
            {
              map(checkedConcepts, (concept) => (
                <td key={ `${title}${concept.id}` }>
                  {n0(values[concept.id]?.value || 0)}
                </td>
              ))
            }
            <td className="table-comparison_average-cell">
              { averageCount(n0(values.avg_value), averageMode, init.isInternal, data.view) }
            </td>
          </tr>
        ))
      }

      {
        map(easeToFind, (values, title) => (
          <Fragment key={ title }>
            <tr className="-js-found">
              <td rowSpan="2" className="table-comparison_name-cell">
                <span>{ title } (N)</span>
              </td>
              {
                map(checkedConcepts, (concept, index) => (
                  <td key={ `easeToFindT2B${concept.id}${index}` }>
                    {
                      !index &&
                      <div className="table-comparison_box-cell">
                        T2B
                      </div>
                    }
                    <StatusCircle values={ values[concept.id] } box="top2" />
                    <span
                      className={
                        cn(
                          "with-number",
                          { '-each': data.stat_testing === 'each' }
                        )
                      }
                    >
                      ({concept.shelf_counters.found_count})
                    </span>
                  </td>
                ))
              }
              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
              </td>
            </tr>
            <tr>
              {
                map(checkedConcepts, (concept, index) => (
                  <td key={ `easeToFindTB${concept.id}${index}` }>
                    {
                      !index &&
                      <div className="table-comparison_box-cell">
                        TB
                      </div>
                    }
                    <StatusCircle values={ values[concept.id] } box="top" />
                    <span
                      className={
                        cn(
                          "with-number",
                          { '-each': data.stat_testing === 'each' }
                        )
                      }
                    >
                      ({concept.shelf_counters.found_count})
                    </span>
                  </td>
                ))
              }
              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view, "top") }
              </td>
            </tr>
          </Fragment>
        ))
      }
    </>
  );
};

export default TrShelf;
