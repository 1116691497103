import React, { useContext, Fragment } from "react";
import { map } from "lodash";
import cn from "classnames";
import SvgIcon from "../../../../../common/components/svg-icon";
import { LangData } from "../../lang-data";
import { MarkupData } from "./markups-logic";
import { colors } from "./const";

const GroupedComments = ({ selRef }) => {
  const {
    groupedComments, isRespondentHidden, hintMessage, respondentClasses, toggleHighlight, toggleMarkupVisible,
    toggleRespondent
  } = useContext(MarkupData);
  const { commentByLanguage } = useContext(LangData);

  const onToggleHighlight = (respondent) => {
    toggleHighlight(respondent);
  };

  const onToggleMarkupVisible = (event, respondent) => {
    event.stopPropagation();
    toggleMarkupVisible(respondent.id);
  };

  const onToggleRespondent = (respondentId) => {
    toggleRespondent(respondentId);
  };

  return (
    <Fragment>
      {map(groupedComments, (r, k) => (
        <div className="gc" key={ k }>
          <div className="summary-markup_comment -header">
            <div className="summary-markup_comment_author">
              Respondent {k}
              <br />
              <span className="demos">{r[0].demos.join(', ')}</span>
            </div>

            <span
              className="summary-markup_comment_action icon-link -no-text"
              title={ hintMessage(k) }
              onClick={ () => {onToggleRespondent(k);} }
              position="auto left"
              role="button"
            >
              {!isRespondentHidden(k) && <SvgIcon className="icon-link_icon" name="eye-open" /> }
              {isRespondentHidden(k) && <SvgIcon className="icon-link_icon" name="eye-closed" />}
            </span>
          </div>

          {map(r, (respondent) => {
            const rc = respondentClasses(respondent);
            let newRef;
            if (rc['-selected']) {
              newRef = selRef;
            }

            return (
              <div
                key={ respondent.id }
                ref={ newRef }
                className={ cn("summary-markup_comment -pointer", rc) }
                id={ `resp-${respondent.id}` }
                onClick={ () => {onToggleHighlight(respondent);} }
                style={ { color: (respondent.hidden ? '' : colors[respondent.reaction]) } }
              >
                <div className="summary-markup_comment_comment">
                  {commentByLanguage(respondent) || '---'}
                </div>

                {respondent.markup_visible && <SvgIcon
                  className="icon-link_icon"
                  name="eye-open"
                  onClick={ (e) => {onToggleMarkupVisible(e, respondent);} }
                                              />}

                {!respondent.markup_visible && <SvgIcon
                  className="icon-link_icon"
                  name="eye-closed"
                  onClick={ (e) => {onToggleMarkupVisible(e, respondent);} }
                                               />}
              </div>
            );
          })}
        </div>
      ))}
    </Fragment>
  );
};

export default GroupedComments;
