import React from 'react';
import remoteFiles from '../../../common/remote_files';

const Footer = ({ children }) => {
  const { footerInfo } = remoteFiles.verizon.tv;
  return (
    <footer className="verison-tv-footer">
      <img className="verison-tv-footer-info" src={ footerInfo } />
      <div>{children}</div>
    </footer>
  );
};

export default Footer;
