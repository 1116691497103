import React, { useContext, useRef, useEffect } from "react";
import { IndexData } from "../index-context";
import { comparisonReportTourClick } from "../../../common/google-analytics/events";

const ComparisonsIntro = ({ plural }) => {
  const { init, data, getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs('projectHeader', tourElement);
  useEffect(callback, condition);

  const onTourClick = () => {
    comparisonReportTourClick(init.google_analytics_id, { project_id: init.project.id });
  };

  return (
    <div className="comparisons_intro-grid" ref={ tourElement }>
      <div className="comparisons_intro-grid-item -fix">
        <div className="comparisons_intro-plate">
          N = { data.total_respondents_count }
        </div>
      </div>
      <div className="comparisons_intro-grid-item">
        <div className="comparisons_intro-text">
          Creating reports is easy. Compare {plural} side-by-side or look at each one
          <br />
          individually. You can also see how the {plural} compare to the overall average.
          <br />
          Click to view <a className="link" onClick={ onTourClick } href={ init.urls.tour }>examples of reporting pages</a> you can create.
        </div>
      </div>
    </div>
  );
};

export default ComparisonsIntro;
