import React, { useContext, useLayoutEffect, useState } from 'react';
import classnames from 'classnames';
import { LineDataContext } from './contexts';

const PlanFeature = ({ title, description, closeCarrotsHash }) => {
  const [ open, setOpen ] = useState(false);
  useLayoutEffect(() => {
    if (closeCarrotsHash) {
      setOpen(false);
    }
  }, [ closeCarrotsHash ]);

  const className = [
    "all-features_item-state",
    open ? "-open" : ""
  ].filter(Boolean).join(" ").trim();
  /* eslint-disable react/no-danger */
  return (
    <div className="all-features_item">
      <div className="all-features_item-header">
        <div className="all-features_item-icon">
          <svg>
            <use xlinkHref="#svg_checkmark-alt" />
          </svg>
        </div>
        <div className="all-features_item-title-with-carret">
          <span
            onClick={ () => setOpen(!open) }
            className="all-features_item-title"
            dangerouslySetInnerHTML={ { __html: title } }
          />
          <div
            className={ className }
            onClick={ () => setOpen(!open) }
          >
            <svg>
              <use xlinkHref="#svg_caret" />
            </svg>
          </div>
        </div>

      </div>
      {open &&
        <span
          className="all-features_item-description"
          dangerouslySetInnerHTML={ { __html: description } }
        />
      }
    </div>
  );
};

const renderFeature = (closeCarrotsHash) => function planFeature({ id, title, description }) {
  return (
    <div key={ id } className="all-features_cell">
      <PlanFeature
        title={ title }
        description={ description }
        closeCarrotsHash={ closeCarrotsHash }
      />
    </div>
  );
};

export const AllPlansInclude = ({ filteredIds, closeCarrotsHash, className }) => {
  const { allPlansInclude } = useContext(LineDataContext);
  const plans = [ ...allPlansInclude ];
  let plansFiltered = plans;
  if (filteredIds) {
    plansFiltered = plans.filter((plan) => filteredIds.includes(plan.id));
  }

  return (<div className={ classnames('all-features_wrapper', className) }>
    <h4 className="all-features_title">All plans include</h4>
    <div className="all-features">{plansFiltered.map(renderFeature(closeCarrotsHash))}</div>
  </div>);
};
