import React from 'react';

const ButtonMore = ({ onClickMore }) => {
  return (
    <button className="button-more" onClick={ onClickMore }>
      Learn more
      <svg className="icon -more">
        <use xlinkHref={ `#svg_caret` } />
      </svg>
    </button>
  );
};

export default ButtonMore;

