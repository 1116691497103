import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { GlobalContext } from '../contexts/appContexts';
import CloseAppXSign from './closeAppXSign';

const Portal = ({ children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.removeChild(element);
      body.style.overflow = null;
      // todo tanya: check it and maybe remove
      //hack for safari to correct behavior of modal in iframe
      const topCoord = html.scrollTop;
      html.scrollTo({ top: 0 });
      html.scrollTo({ top: topCoord });
    };
  }, [ body, element, html ]);


  return createPortal(children, element);
};

const NativeModal = ({ children, buttons }) => {
  const { closeFullApp } = useContext(GlobalContext);
  return (
    <Portal>
      <div className="native-modal">
        <div className="native-modal_backdrop" />
        <div className="native-modal_dialog">
          {
            !!closeFullApp &&
            <div className="x-button-wrapper">
              <CloseAppXSign onClick={ closeFullApp } />
            </div>
          }
          <div className="native-modal_content">
            { children }
          </div>
          {
            !!buttons &&
            <div className="native-modal_buttons">{ buttons }</div>
          }
        </div>
      </div>
    </Portal>
  );
};

export default NativeModal;
