import React, { Fragment } from 'react';
import remoteFiles from '../../../../../common/remote_files';

const ReadMoreBrandSnapshot = ({ onGetStarted }) => {
  const { step12x, screenerQuestion } = remoteFiles.express.steps.base;
  const {
    brandDetailsHigh, brandScreeningHigh,
    categoryEvaluationHigh, brandEvaluationHigh,
    thoughtBubbleHigh, customQuestionsHigh
  } = remoteFiles.express.steps.brandSnapshot;

  return (
    <Fragment>
      <div className="modal_body">
        <div className="express-steps">
          <h3 className="express-steps_title">Brand Snapshot</h3>
          <h2 className="express-steps_subtitle">Here’s how it works</h2>
          <div className="express-steps_list">
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Select your audience</p>
                  <p className="-descr">Choose demographics filters.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ step12x } />
                </div>
              </div>
            </div>
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Create up to 3 screener questions</p>
                  <p className="-descr">Adding screener questions may impact cost and timing.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ screenerQuestion } />
                </div>
              </div>
            </div>

            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Brand Details</p>
                  <p className="-descr">Do you offer a product or service? Provide a brand name and category.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ brandDetailsHigh } />
                </div>
              </div>
            </div>

            <div className="express-steps_item -no-bottom-line">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Brand Screening</p>
                  <p className="-descr">
                    Pick a Brand Users within a needed user timeframe and filter out Brand Rejectors
                  </p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ brandScreeningHigh } />
                </div>
              </div>
            </div>

            <div className="express-steps_item -no-flex -no-bottom-line">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">Choose Key Question Types</h3>
              </div>

              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Category Evaluation</p>
                    <p className="-descr">
                      Respondents will answer about the category in general - how often they purchase,
                      attribute importance, and where they typically purchase.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img">
                    <img src={ categoryEvaluationHigh } />
                  </div>
                </div>
              </div>

              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Brand Evaluation</p>
                    <p className="-descr">
                      Those aware of the Target Brand will react to key metrics, with Brand Users
                      also answering questions based on their personal experience with the Target Brand.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -border">
                    <img src={ brandEvaluationHigh } />
                  </div>
                </div>
              </div>

              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Diagnostics</p>
                    <p className="-descr">
                      With Thought Bubble, respondents describe the Target Brand in three words.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -border">
                    <img src={ thoughtBubbleHigh } />
                  </div>
                </div>
              </div>

              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Custom Questions</p>
                    <p className="-descr">
                      Create up to 3 custom questions.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img">
                    <img src={ customQuestionsHigh } />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer -bg-gray -center">
        <div className="modal_footer-button">
          <button className="button modal_button" onClick={ onGetStarted }>
            Get Started
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ReadMoreBrandSnapshot;
