import React, { useContext, useState } from 'react';
import cn from "classnames";
import SvgIcon from '../../../../common/components/svg-icon';
import { DataContext } from "../withData";

const NewTagLine = ({ className, tagType, productCategoryId, parentId }) => {
  const { addTag } = useContext(DataContext);

  const [ newText, setNewText ] = useState("");
  const [ saving, setSaving ] = useState(false);

  const handleOnAdd = () => {
    setSaving(true);
    addTag(
      tagType,
      productCategoryId,
      parentId,
      newText,
      () => {
        setSaving(false);
        setNewText("");
      }
    );
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleOnAdd();
    }
  };

  return (
    <div className={ cn("form_grid -js-add", className) }>
      <div className="form_grid-item -grow">
        <input
          className="form-field -block"
          type="text"
          value={ newText }
          onChange={ (event) => { setNewText(event.target.value); } }
          onKeyDown={ handleKeyPress }
          placeholder={ 'Add tag' }
        />
      </div>
      <div className="form_grid-item">
        <button
          type="button"
          className="button -circle"
          disabled={ !newText || saving }
          onClick={ handleOnAdd }
        >
          <SvgIcon name="i-add" />
        </button>
      </div>
    </div>
  );
};

export default NewTagLine;
