import React, { useContext } from 'react';
import { map } from "lodash";
import { getMetricKind, IndexData } from "../../index-context";
import StatusCircle from "../../components/status-circle";
import { showedAverageValue } from '../../helpers';

const TablePreference = ({ data, current, preference }) => {
  const { averageMode, init } = useContext(IndexData);
  return (
    <table className="table table-comparison -long-first-cell">
      <thead>
        <tr>
          <th className="table-comparison_name-cell" colSpan="2">
            <div className="table-comparison_title">
              { getMetricKind(data, 'preference') }
            </div>
          </th>
          <th className="table-comparison_average-cell">
            Avg
          </th>
        </tr>
      </thead>
      <tbody>
        {
          map(preference, (values, title) => (
            <tr key={ title }>
              <td className="table-comparison_name-cell">{title}</td>
              <td>
                <StatusCircle values={ values[current.id] } noLetters box={ "top2" } />
              </td>
              <td className="table-comparison_average-cell">
                {showedAverageValue(values, averageMode, false, init.isInternal, data.view)}
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default TablePreference;
