import React, {useContext, useDebounce, useEffect, useLayoutEffect, useRef, useState} from "react";
import Slider from 'rc-slider';
import { IndexData } from "../../index-context";
import { comparisonScorecardCfChangeOpacity } from "../../../../common/google-analytics/events";

const OpacitySlider = ({ opacity, setOpacity, conceptId, min, max, step }) => {
  const { init } = useContext(IndexData);
  const onChange  = (v) => {
    setOpacity(v);
  };

  const firstUpdate = useRef(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      comparisonScorecardCfChangeOpacity(
        init.google_analytics_id,
        { project_id: init.project.id, item_id: conceptId }
      );
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [ opacity, conceptId ]);

  return (
    <div className="markups_opacity">
      <h3>Opacity: {opacity}</h3>
      <Slider
        min={ min }
        max={ max }
        step={ step }
        value={ opacity }
        visible
        onChange={ onChange }
      />
    </div>
  );
};

export default OpacitySlider;
