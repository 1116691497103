import React, { useContext }  from 'react';
import classnames from 'classnames';
import { noop } from '../helper';
import { OptionsContext } from './contexts';

const DeleteButton = ({
  higherIndex, left, top, className, onClick,
  onMouseEnter, onMouseLeave
}) => {
  const { rectHoverClassName } = useContext(OptionsContext);
  let style = { left, top, zIndex: (higherIndex ? 4 : 2) };
  if (rectHoverClassName && !higherIndex) {
    style = { ...style, ...{ display: 'none' } };
  }
  return (
    <button
      type="button"
      style={ style }
      className={
        classnames(
          "products-greed-item-image-rect-delete-button",
          className
        )
      }
      onContextMenu={ noop }
      onClick={ onClick }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    />
  );
};

export default DeleteButton;
