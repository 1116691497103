const baseDefaultState = {
  loading: true,
  step: 0,
  qIndex: 0,
  gIndex: 0, //group
  rcIndex: 0 //random claim
};

const baseState = (state = baseDefaultState, action) => {
  switch (action.type) {
    case "UPDATE_LOADING":
      state.loading = action.value;
      return { ...state };
    case "UPDATE_STEP":
      state.step = action.value;
      return { ...state };
    case "UPDATE_QUESTION_INDEX":
      state.qIndex = action.value;
      return { ...state };
    case "UPDATE_GROUP_INDEX":
      state.gIndex = action.value;
      return { ...state };
    case "UPDATE_RANDOM_CLAIM_INDEX":
      state.rcIndex = action.value;
      return { ...state };
    default:
      return state;
  }
};

export default baseState;
