import React, { useState, useRef, useEffect } from 'react';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { each, map, find } from 'lodash';
import ThumbnailGenerator from '@uppy/thumbnail-generator';

const UploadButton = ({
  className,
  filenameOnBottom,
  allowedTypes,
  multiple,
  children,
  onUploaded,
  onInit,
  needPreview,
  handleInProgressAfterSave,
  disabled,
  initialList, forceInitialList,
  customProcessingLabel,
  removeLabel,
  onRemove,
  withId
}) => {
  const [ fileState, setFileState ] = useState({});
  const [ filenamesList, setFilenamesList ] = useState(initialList || []);
  const [ inProgress, setInProgress ] = useState(false);

  const uploadedFilesData = (results) => (
    map(results, (file) => (
      {
        previewSrc: file.preview,
        metaData: JSON.stringify({
          id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
          storage: 'cache',
          metadata: { size: file.size, filename: file.name, mime_type: file.type }
        }),
        filename: file.meta.name,
        awsKey: file.meta.key
      }
    ))
  );
  const createUppyInstance = () => {
    const service = new Uppy()
      .use(AwsS3, { companionUrl: '/' })
      .on('file-added', (file) => {
        uppy.upload();
      })
      .on('upload-success', (result, response) => {
        setFileState((value) => {
          return {
            file: result,
            selected: value.selected - 1,
            uploaded: [ ...(value.uploaded || []), ...[ result ] ],
            previewed: [ ...(value.previewed || []), ...[ result ] ]
          };
        });
      })
      .on('thumbnail:generated', (result) => {
        setFileState((currentValue) => {
          currentValue.previewed = [ ...(currentValue.previewed || []), ...[ result ] ];
          return currentValue;
        });
      })
      .on('error', (result, response) => {
        console.log('Upload errors');
        setInProgress(false);
      })
      .on('upload-error', (file, error, response) => {
        console.log('Upload errors');
        setInProgress(false);
      });
    if (needPreview) {
      service.use(ThumbnailGenerator, { thumbnailWidth: 800 });
    }
    return service;
  };

  const [ uppy ] = useState(createUppyInstance);

  const fileFieldRef = useRef(null);

  const onClick = () => {
    fileFieldRef.current.click();
  };

  const resetFiles = () => {
    uppy.getState().files = {};
    uppy.getState().totalProgress = null;
  };

  const onFileSelect = (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) {
      return;
    }
    setInProgress(true);
    setFileState({ selected: files.length, uploaded: [], previewed: [] });
    onInit && onInit();
    resetFiles();
    each(files, (file) => {
      uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file
      });
    });
    fileFieldRef.current.value = '';
  };

  const finalFilenamesList = forceInitialList ? initialList : filenamesList;

  useEffect(() => {
    const { file, selected, uploaded, previewed } = (fileState || {});
    if (file) {
      // sometimes we don't have a preview on this stage
      const fileWithPreview = find(previewed, (item) => item.id === file.id);
      if (fileWithPreview) {
        file.preview = fileWithPreview.preview;
      }
      setFilenamesList(map(uploaded, (item) => item.meta.name));
      if (!selected) {
        if (!handleInProgressAfterSave) {
          setInProgress(false);
        }
        onUploaded && onUploaded(uploadedFilesData(uploaded), setInProgress);
      }
    }
  }, [ fileState ]);

  const fileInputHash = {};
  if (withId) {
    fileInputHash.id = withId;
  }

  return (
    <>
      <input
        type="file"
        ref={ fileFieldRef }
        style={ {
          width: 0.1,
          height: 0.1,
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1
        } }
        accept={ (allowedTypes || []).join(', ') }
        multiple={ multiple }
        onChange={ onFileSelect }
        { ...fileInputHash }
      />
      {
        !inProgress &&
        <button className={ className } onClick={ onClick } disabled={ disabled }>
          {children}
        </button>
      }
      {
        inProgress &&
        <button className={ className } disabled="disabled">
          { customProcessingLabel || "Processing..." }
        </button>
      }
      {
        filenameOnBottom && !!finalFilenamesList && !!finalFilenamesList.length && (
          <ul className="uploaded-list">
            {
              map(finalFilenamesList, (item, index) => (
                <li key={ `${item}${index}` }>
                  { item }
                  {
                    onRemove &&
                    <>
                      { " (" }
                      <a
                        className="file-remove"
                        href="#"
                        onClick={ (event) => { onRemove(event, item); } }
                      >
                        { removeLabel || "Delete file" }
                      </a>
                      { ")" }
                    </>
                  }

                </li>
              ))}
          </ul>
        )
      }
    </>
  );
};

export default UploadButton;
