import React, { useContext, useState } from 'react';
import { map, max } from "lodash";
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import ButtonGroup from "./button-group";
import ButtonToggle from "./button-toggle";

const FontSelectionModal = () => {
  const {
    loading, init, data,
    onFontSelectionModalClose, fontSelectionModalData
  } = useContext(IndexData);

  const [ localLoading, setLocalLoading ] = useState(false);

  const [ additionalSettings, setAdditionalSettings ] = useState(false);
  const tooltipTexts = {
    500: "The smallest font, best for projects with 15-30 concepts and for significant-sized attributes",
    900: "Smaller font, best for projects with 5-15 concepts and for middle-sized attributes",
    1100: "Standard density, best for projects with 3-5 concepts and for middle-sized/small-sized attributes",
    1200: "Bigger font, best for projects with 1-3 concepts and up to 5 attributes",
    1400: "The biggest font, best for projects with 1-3 concepts and up to 3 attributes"
  };

  const determineDefaultFontSize = () => {
    const { analytics, concepts } = data;
    const names = [
      ...map(analytics, (value, titleKey) => value.real_attribute_title || titleKey),
      ...map(concepts, (el) => el.title)
    ];
    const maxLength = max(map(names, (el) => el.length));

    switch (true) {
      case (maxLength < 31):
        return 1100;
      case (maxLength < 45):
        return 900;
      default:
        return 500;
    }
  };

  const [ selectedFontSize, setFontSize ] = useState(determineDefaultFontSize());

  const switchAdditionalSettings = () => {
    setAdditionalSettings(!additionalSettings);
  };

  const additionalSettingsMode = (
    additionalSettings ? "Standard settings" : "Additional settings"
  );

  const tooltipText = (
    additionalSettings ?
      "Equal to original MS Power Point font sizes" :
      tooltipTexts[selectedFontSize]
  );

  const exportReport = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { exportFunction, params } = fontSelectionModalData;
    const postParams = { ...params, ...{ font_size: selectedFontSize } };
    setLocalLoading(true);
    exportFunction(event, postParams, () => {
      setLocalLoading(false);
      onFontSelectionModalClose();
    });
  };

  const processing = (loading || localLoading);

  return (
    <Modal modalSize="md2" modalClassName="centered">
      { processing && <div className="page-loader -inner-loader" /> }
      <div className="modal-title">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ onFontSelectionModalClose }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title -center" id="clientLabel">
          Font Size Adjustment
        </h3>
      </div>

      <div className="font-selection">
        <div className="font-selection_controls">
          {
            !additionalSettings &&
            <ButtonGroup
              selectedFontSize={ selectedFontSize }
              setFontSize={ setFontSize }
            />
          }
          {
            additionalSettings &&
            <ButtonToggle
              selectedFontSize={ selectedFontSize }
              setFontSize={ setFontSize }
            />
          }
        </div>
        {
          !!tooltipText &&
          <div className="font-selection_description">
            { tooltipText }
          </div>
        }
        <div className="font-selection_footer">
          <div className="font-selection_footer-item">
            <button
              className="button -next"
              type="button"
              onClick={ exportReport }
            >
              <svg className="icon -arrow-next-button">
                <use xlinkHref="#svg_long-arrow" />
              </svg>
            </button>
          </div>
          {
            init.isInternal &&
            <div className="font-selection_footer-item">
              <span
                className="link -bold"
                onClick={ switchAdditionalSettings }
              >{ additionalSettingsMode }</span>
            </div>
          }
        </div>
      </div>

    </Modal>
  );
};

export default FontSelectionModal;
