import React, { useContext, useState } from 'react';
import { map, cloneDeep } from "lodash";
import CheckboxPlate from '../components/chekboxPlate';
import { GlobalContext } from '../contexts/appContexts';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Header from "../components/header";
import Notification from "../components/notification";

const ShoppingIntent = ({ nextStep, skipStep, headerStep, numberOfHeaderSteps }) => {
  const { fakeData, mergeData } = useContext(GlobalContext);
  const [ selectedCategories, setSelectedCategories ] = useState([]);
  const [ error, setError ] = useState(false);

  const categoriesUpdate = (val) => {
    setSelectedCategories(val);
    if (selectedCategories.length >= 1) {
      setError(false);
    }
  };

  const handleNext = () => {
    if (selectedCategories.length < 1) {
      setError(true);
      mergeData({ cashbackCategoriesValidationShown: true });
    } else {
      mergeData({ cashbackCategories: cloneDeep(selectedCategories), cashbackCategoriesSkip: false });
      nextStep();
    }
  };

  const handleSkip = () => {
    mergeData({ cashbackCategories: null, cashbackCategoriesSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerHintAbove = (
    !!error && (
      <div className="footer_hint-grid">
        <svg className={ `footer_hint-icon -error` }>
          <use xlinkHref={ `#svg_error` } />
        </svg>
        Choose a category to continue.
      </div>
    )
  );

  const footerButtons = (
    <>
      <button
        className="button -full-width"
        onClick={ handleNext }
      >
        Personalize My Offers
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } footerHintAbove={ footerHintAbove } />;

  return (
    <Layout header={ header } footer={ footer }>
      {
        !!error &&
        <Notification status="error" icon="error" showClose>
          Choose a category to continue.
        </Notification>
      }
      <div className="base-intent">
        <div className="content_title">
          <h1>Get cash back offers you’ll love</h1>
        </div>
        <div className="content_text">
          Choose as many categories as you like.
        </div>

        <ul className="select-category">
          {
            map(fakeData.cashBackCategoriesList, (item, index) => (
              <li key={ index } className="select-category_item">
                <CheckboxPlate
                  name="cashback-cat-full"
                  item={ item }
                  selectedAnswers={ selectedCategories }
                  iconPrefix="cat"
                  checkboxChange={ categoriesUpdate }
                  modifier={ error ? "-error" : "" }
                />
              </li>
            ))
          }
        </ul>
      </div>
    </Layout>
  );
};

export default ShoppingIntent;


