import React from 'react';
import ImageInPopup from '../../../../../common/components/image_in_popup';
import remoteFiles from '../../../../../common/remote_files';

const ReadMoreSwipe = ({ onGetStarted }) => {
  const {
    fivePointScale, swipeAction, markupPositive
  } = remoteFiles.express.steps.fullSize.swipe;
  const {
    keyMetrics, screenerQuestion, step12x, step32x,
    step52x, step62x, step72x
  } = remoteFiles.express.steps.base;
  return (
    <>
      <div className="modal_body">
        <div className="express-steps">
          <h3 className="express-steps_title">Swipe</h3>
        </div>

        <h2 className="express-steps_subtitle">Here’s how it works</h2>

        <div className="express-steps_list">
          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">Select your audience</div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ step12x } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">
                <p>Create up to 3 screener questions</p>
                <p className="-descr">Adding screener questions may impact cost and timing.</p>
              </div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ screenerQuestion } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">Upload your concepts</div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ step32x } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">Choose key question types</div>
              <div className="express-variants">
                <div className="express-variants_list">
                  <div className="express-variants_list-item">
                    <div className="express-variants_item">
                      <ImageInPopup
                        hoverClassName="-express-tooltip-preview"
                        className="express-variants_item-preview"
                        src={ fivePointScale }
                      >
                        <div className="popup-title -no-description">
                          Sample 5-point scale question.
                        </div>
                        <div className="popup-image">
                          <img className="express-variants_item-preview-img" src={ fivePointScale } />
                        </div>
                      </ImageInPopup>
                      <div className="express-variants_item-text">
                        Sample 5-point scale question.
                      </div>
                    </div>
                  </div>
                  <div className="express-variants_list-item">
                    <div className="express-variants_item">
                      <ImageInPopup
                        hoverClassName="-express-tooltip-preview"
                        className="express-variants_item-preview"
                        src={ swipeAction }
                      >
                        <div className="popup-title -no-description">Sample Swipe question.</div>
                        <div className="popup-image">
                          <img className="express-variants_item-preview-img" src={ swipeAction } />
                        </div>
                      </ImageInPopup>
                      <div className="express-variants_item-text">Sample Swipe question.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ keyMetrics } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">
                <p>Concept Focus</p>
                <p className="-descr">
                  All respondents will participate in Concept Focus to mark up what they like and
                  { ' ' }
                  dislike for a select number of concepts.
                </p>
              </div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ markupPositive } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">Select sample size</div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ step52x } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">Enter your credit card</div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ step62x } />
              </div>
            </div>
          </div>

          <div className="express-steps_item">
            <div className="express-steps_col">
              <div className="express-steps_item-text">And Launch!</div>
            </div>
            <div className="express-steps_col">
              <div className="express-steps_item-img">
                <img src={ step72x } />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer -bg-gray -center">
        <div className="modal_footer-button">
          <button className="button modal_button" onClick={ onGetStarted }>
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default ReadMoreSwipe;
