import { each, sumBy, values, map } from 'lodash';

const resultHash = {
  linesCount: 0,
  path: "",
  startedFrom: "",
  lines: {},
  get totalPrice() { return sumBy(values(this.lines), (item) => item.planPrice || 0);  }
};

const setHashData = (path, value) => {
  const pathItems = path.split('.');
  let tempItem = resultHash;
  each(pathItems, (pathEl, index) => {
    if (index !== pathItems.length - 1) {
      if (!tempItem[pathEl]) {
        tempItem[pathEl] = {};
      }
      tempItem = tempItem[pathEl];
    } else { tempItem[pathEl] = value; }
  });
};

const getHashData = (path) => {
  const pathItems = path.split('.');
  let tempItem = resultHash, result = null;
  each(pathItems, (pathEl, index) => {
    if (!tempItem || !tempItem[pathEl]) {
      result = null;
      return false;
    } else if (index === pathItems.length - 1) {
      result = tempItem[pathEl];
      return false;
    }
    tempItem = tempItem[pathEl];
  });
  return result;
};

const updateHashByKey = (hashKey) => {
  const prevValue = getHashData(hashKey) || 0;
  setHashData(hashKey, prevValue + 1);
};

let currentLine = 1;
const setCurrentLine = (val) => {
  currentLine = val;
};
const getCurrentLine = () => currentLine;

const getSetValue = () => getHashData("setValue");

const setSetValue = (value) => {
  setHashData('setValue', value);
  setCurrentLine(1);
};

const setLinesCount = (value) => {
  setHashData('linesCount', value);
  setCurrentLine(1);
};
const getLinesCount = () => getHashData('linesCount');

// const setPlan = (plan, line, source, path, init, compareClickedCount, plansCount) => {
const setPlan = (plan, line, source, path, init, plansCount, perkPackName) => {
  setHashData(`lines.${line}.planTitle`, plan.name);
  setHashData(`lines.${line}.planPrice`, plan.price);
  setHashData(`lines.${line}.planId`, plan.id);
  setHashData(`lines.${line}.planDescription`, plan.description);
  setHashData(`lines.${line}.selectedPerk`, perkPackName);
  setHashData(`lines.${line}.selectedSource`, source || "overview");
  setHashData(`lines.${line}.taxPhrase`, plan.taxPhrase);
  // setHashData(`lines.${line}.compareClicked`, compareClickedCount);

  if (init) {
    setHashData(`lines.${line}.planReselectedCount`, 0);
  } else {
    updateHashByKey(`lines.${line}.planReselectedCount`);
  }

  // add zero values
  if (!getHashData(`lines.${line}.compareClicked`)) {
    setHashData(`lines.${line}.compareClicked`, 0);
  }

  if (plansCount) {
    for (let i = 1; i <= plansCount; i++) {
      if (!getHashData(`lines.${line}.plan${i}CompareClicked`)) {
        setHashData(`lines.${line}.plan${i}CompareClicked`, 0);
      }
      if (!getHashData(`lines.${line}.plan${i}CompareDropdownSelected`)) {
        setHashData(`lines.${line}.plan${i}CompareDropdownSelected`, 0);
      }
    }
  }

  setHashData("path", path);
};

const getSelectedPlans = () => {
  return map(values(resultHash.lines), (item) =>
    ({
      id: item.planId,
      title: item.planTitle,
      price: item.planPrice,
      description: item.planDescription,
      compareClicked: item.compareClicked,
      selectedPerk: item.selectedPerk
    })
  );
};

const getTotal = () => resultHash.totalPrice;
const sendData = () => {
  const finalData = {
    ...resultHash,
    lines: values(resultHash.lines),
    set: getSetValue()
  };

  (window.parent || window).postMessage({
    kind: 'finishPlans',
    result: finalData
  }, "*");
};

export {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  setHashData, getHashData,
  updateHashByKey,
  setPlan,
  getSelectedPlans, getTotal,
  sendData,
  setSetValue
};
