import React from 'react';
import classnames from "classnames";

const PasswordField = ({ error, label, value, onChange, placeholder, className, classNameLabel, disabled, addToLabel }) => {

  return(
    <div className="form-group">
      <div className="form-grid -bottom">
        {label && (
          <div className="form-grid_item">
            <div className={`form-group_label ${classNameLabel ? classNameLabel : '-upper'}`}>{ label }</div>
          </div>
        )}
        {addToLabel && (
          <div className="form-grid_item">{addToLabel}</div>
        )}
      </div>

      <div className="form-group_control -password">
        <input
          type="password"
          className={ classnames("form-control", className) }
          value={ value || "" }
          placeholder={ placeholder }
          onChange={ (event) => onChange(event.target.value) }
          disabled={ disabled }
        />
        { error && <p className="field-error">{ error }</p> }
      </div>
    </div>
  );
};

export default PasswordField;