import { map } from 'lodash';

const getAvailableRangesFromString = (ageBreaks) => (
  map(ageBreaks, (ageItem) => {
    const matched = ageItem.match(/^(\d+)-(\d+)$/);
    return [ matched[1], matched[2] ];
  })
);

const ageRangeResult = (leftResult, rightResult) => (
  { left: leftResult, right: rightResult }
);
const messageNotNumber = (isLeft) => (
  `${isLeft ? 'Left border' : 'Right border'} is not an integer value`
);
const messageLeftIsBigger = () => (
  'Left border is higher than the right one'
);
const messageOutOfBorders = (availableRanges) => (
  `Range is out of borders, selected for the exercise. Available ranges are: ${map(availableRanges, (range) => `${range[0]}-${range[1]}`).join(', ')}`
);
const checkIfNumber = (isLeft, value) => (
  value.match(/^\d+$/) ? null : messageNotNumber(isLeft)
);

const checkRanges = (ageBreaks, leftNum, rightNum) => {
  const availableRanges = getAvailableRangesFromString(ageBreaks);
  const outOfBorders = leftNum < availableRanges[0][0] ||
    rightNum > availableRanges[availableRanges.length - 1][1];

  return outOfBorders ? messageOutOfBorders(availableRanges) : null;
};

const ageRangeValidation = (ageBreaks, leftValue, rightValue) => {
  let leftResult = null;
  let rightResult = null;
  // Check if number
  leftResult = checkIfNumber(true, leftValue);
  rightResult = checkIfNumber(false, rightValue);
  if (leftResult || rightResult) {
    return ageRangeResult(leftResult, rightResult);
  }
  // Now we know both are integers
  const leftNum = parseInt(leftValue);
  const rightNum = parseInt(rightValue);

  // Check if left is less or equal to right
  if (leftNum > rightNum) {
    leftResult = rightResult = messageLeftIsBigger();
    return ageRangeResult(leftResult, rightResult);
  }

  leftResult = rightResult = checkRanges(ageBreaks, leftNum, rightNum);

  return ageRangeResult(leftResult, rightResult);
};

export { ageRangeValidation, getAvailableRangesFromString };
