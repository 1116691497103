import Base from '../base';

class Claim extends Base {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  totalPrice() {
    return this.basePrice();
  }

  sizeCalcPart() {
    return 1;
  }
}

export default Claim;
