import React from 'react';
import classnames from "classnames";

const TextArea = ({ error, label, value, onChange, placeholder, className, disabled }) => {
  return(
    <div className="form-group">
      {label && <div className="form-group_label -upper">{ label }</div>}
      <div className="form-group_control">
        <textarea
          className={ classnames("form-control", className) }
          value={ value || "" }
          placeholder={ placeholder }
          onChange={ (event) => onChange(event.target.value) }
          disabled={ disabled }
        />
        { error && <p className="field-error">{ error }</p> }
      </div>
    </div>
  );
};

export default TextArea;