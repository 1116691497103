import React, { useContext, useCallback, Fragment } from 'react';
import { filter, orderBy, map, debounce, each } from "lodash";
import SvgIcon from "../../../../../../common/components/svg-icon";
import Http from "../../../../../../common/http";
import KeywordsListLi from "./keywords-list-li";
import KeywordsListLiHidden from "./keywords-list-li-hidden";
import MergeName from "./merge-name";
import { KeywordsData } from "./index";

const KeywordsList = ({ metric, closeConfigure, loading }) => {
  const { isKeywordsSelected, hiddenKeywordCount, keywords, setKeywords, load, search, setSearch } = useContext(KeywordsData);

  const defferedLoad = useCallback(debounce((s) => {
    load(s);
  }, 500), []);

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    defferedLoad(event.target.value);
  };

  const isRootElement = (v) => {
    return !v.hidden && !v.pids.length;
  };

  const isHiddenRootElement = (v) => {
    return v.hidden && !v.pids.length;
  };

  const merge = (mergeName) => {
    const checkedKeywords = filter(keywords, (k) => (k.checked));

    const params = {
      source_id: metric.source_id,
      source_type: metric.source_type,
      keyword: mergeName,
      ids: checkedKeywords.map((k) => k.ids),
      q: search
    };

    Http.post(
      `/dashboard/api/keywords/merge.json`, params
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setKeywords(http.data);
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const toggleHide = (keyword) => {
    const params = {
      hidden: !keyword.hidden,
      ids: keyword.ids
    };

    Http.post(
      `/dashboard/api/keywords/hide.json`, params
    ).then(
      (http) => {
        if (http.response.status === 204) {
          keyword.hidden = !keyword.hidden;
          setKeywords([ ...keywords ]);
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const deselectAll = () => {
    each(keywords, (k) => {
      k.checked = false;
    });
    setKeywords([ ...keywords ]);
  };

  const rootKeywords = orderBy(filter(keywords, (el) => isRootElement(el)), [ 'total', 'text' ], [ 'desc', 'asc' ]);
  const hiddenKeywords = orderBy(filter(keywords, (el) => isHiddenRootElement(el)), [ 'total', 'text' ], [ 'desc', 'asc' ]);

  return (
    <div className="modal_form form-horizontal">

      <div className="modal_header">
        <span
          aria-hidden="true"
          className="modal_close icon-link -no-text -gray"
          onClick={ closeConfigure }
          role="button"
          title="Close"
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>
        {!isKeywordsSelected() && <h3 className="modal_title">
          Described As Word Cloud
          <span className="modal_subtitle">(number of mentions per word)</span>
        </h3>}
        {isKeywordsSelected() && <h3 className="modal_title">
          Merge selected to
          <span className="modal_subtitle" />
        </h3>}
      </div>

      <div className="modal_body">

        {!isKeywordsSelected() && <div className="keywords-control -no-border">
          <div className="keywords-control_main">
            <div className="form-field-with-icon">
              <input
                className="form-field -rounded form-field-with-icon_input"
                type="text"
                placeholder="Filter a word"
                value={ search }
                onChange={ onSearchChange }
              />
              <SvgIcon className="form-field-with-icon_icon" name="i-search" />
            </div>
          </div>
          <div className="keywords-control_addon">
            <div className="keywords-control_addon-child">
              {hiddenKeywordCount() !== 0 && <span className="words-counter">
                <SvgIcon className="words-counter_icon" name="i-eye" />
                <span className="words-counter_text">{hiddenKeywordCount()} words are hidden</span>
              </span>}
            </div>
          </div>
        </div>}

        {isKeywordsSelected() && <MergeName keywords={ rootKeywords } merge={ merge } deselectAll={ deselectAll } conceptId={ metric.concept_id } />}

        <div className="keywords-wrap">
          {!loading &&
            <Fragment>
              <ul className="keywords-list">
                {rootKeywords.length > 0 &&
                  <Fragment>
                    {map(rootKeywords, (keyword) => (
                      <KeywordsListLi key={ keyword.ids[0] } keyword={ keyword } toggleHide={ toggleHide } conceptId={ metric.concept_id } />
                    ))}
                  </Fragment>
                }
                {rootKeywords.length === 0 && <li>No visible words found</li>}
              </ul>

              {hiddenKeywords.length > 0 &&
                <Fragment>
                  <h4 className="tile-header -no-border">Hidden Words</h4>
                  <ul className="keywords-list">
                    {map(hiddenKeywords, (keyword) => (
                      <KeywordsListLiHidden key={ keyword.ids[0] } keyword={ keyword } toggleHide={ toggleHide } conceptId={ metric.concept_id } />
                    ))}
                  </ul>
                </Fragment>}
            </Fragment>}
          {loading && <div className="modal_loader loader -loading-blink">Loading...</div>}
        </div>
      </div>
      <div className="modal_footer">
        <div className="-align-right">
          <button className="button -submit" onClick={ closeConfigure }>Close</button>
        </div>
      </div>
    </div>
  );
};

export default KeywordsList;
