import React from 'react';
import Statistic from "../../statistic";

const TextDescription = ({ text }) => <li className="plan-short-description-value">
  {text.split('\n').map((value, idx) => <p key={idx}>{value}</p>)}
</li>;

const ComplexDescription = ({ text, icon, iconPath, iconClassName }) => <>
  <li className="plan-short-description-icon">
    {
      icon && <svg className={ `icon -${ icon } ${iconClassName ? iconClassName :''}` }>
        <use xlinkHref={ `#svg_${ icon }` }/>
      </svg>
    }
    {
      iconPath && <img className={`icon ${iconClassName}`} src={iconPath} />
    }
  </li>
  <TextDescription text={text} />
</>;

const renderDescriptionListItem = (element, idx) =>
  typeof element === 'string'
    ? <TextDescription key={idx} text={element} />
    : <ComplexDescription
        key={idx}
        text={element.text}
        icon={element.icon}
        iconPath={element.iconPath}
        iconClassName={element.iconClassName} />;

const renderDescriptionSubtypes = (element, idx) => {
  return (
    <div className="plan-short-description-section" key={idx}>
      <div className="plan-short-description-subtitle">{element.subtype}</div>
      <ul className="plan-short-description-list">
        {element.subitems.map(renderDescriptionListItem)}
      </ul>
    </div>
  );
}

const renderDescriptionSubtypesWithSwitch = (switchValue, handleSwitch) => (element, idx) => {
  return (
    <div className="plan-short-description-section -switch" key={idx}>
      {idx !== 0 && (
        <div className="plan-short-description-section-or-wrapper">
          <div className="plan-short-description-section-or">or</div>
        </div>
      )}
      <div className={`plan-short-description-subtitle -switch ${element.subtype === switchValue ? '-selected':''}`}
           onClick={() => handleSwitch(element.subtype)}
      >
        {element.subtype}
      </div>
      <ul className="plan-short-description-list">
        {element.subitems.map(renderDescriptionListItem)}
      </ul>
    </div>
  );
}


const renderDescription = (info, idx) => {
  const [title, list] = info;
  return <div key={idx} className="choose-plan-card">
    <h6 className="plan-short-description-title">{title}</h6>
    <ul className="plan-short-description-list">
      {list.map(renderDescriptionListItem)}
    </ul>
  </div>
};

const PlanShortDescriptionSectionWithSwitch = ({items, typePerks, setTypePerks}) => {
  return (
    <div className="plan-short-description-sections">
      {items.map(renderDescriptionSubtypesWithSwitch(typePerks, setTypePerks))}
    </div>
  );
};

const PlanShortDescriptionSectionInCheckout = ({items, planPerksTypeCheckout}) => {
  return (
    <div className="plan-short-description-sections">
      {items.filter((item) => item.subtype === planPerksTypeCheckout ).map(renderDescriptionSubtypes)}
    </div>
  );
};


const renderDescriptionWithTypes = (typePerks, setTypePerks, planPerksTypeCheckout) => (info, idx) => {
  const { title, titleCheckout, type, items, itemsBySubtypes, hasSwitchType } = info;
  return <div key={idx} className={`choose-plan-card -${type} `}>
    <h6 className="plan-short-description-title">{planPerksTypeCheckout && titleCheckout ? titleCheckout : title}</h6>
    {
      hasSwitchType ?
        // for case with clickable several type of perks (entertainment/productivity)
        planPerksTypeCheckout ?
          // for output in checkout
          <PlanShortDescriptionSectionInCheckout
            items={items}
            planPerksTypeCheckout={planPerksTypeCheckout}
          />
          // for output in choose plan
          : <PlanShortDescriptionSectionWithSwitch
            items={items} typePerks={typePerks} setTypePerks={setTypePerks}
          />
        // for case without clickable types (with subtypes and without)
        : itemsBySubtypes ?
            <div className="plan-short-description-sections">{items.map(renderDescriptionSubtypes)}</div>
            : <ul className="plan-short-description-list">{items.map(renderDescriptionListItem)}</ul>
    }
  </div>
};

const PlanShortDescriptionFlex = ({ description, withTypes, typePerks, setTypePerks, planPerksTypeCheckout }) => {
  return(
    withTypes ?
      description.map(renderDescriptionWithTypes(typePerks, setTypePerks, planPerksTypeCheckout))
      : description.map(renderDescription)
  )
};

export default PlanShortDescriptionFlex;
