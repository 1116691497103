import React from "react";

const NoData = ({ runAI, text }) => {
  return (
    <>
      <div className="middle">
        <h3>AI Summary</h3>
        <span>{text}</span>
      </div>
      <button
        type="button"
        className="button run-button"
        onClick={ runAI }
      >
        Run
      </button>
    </>
  );
};

export default NoData;
