import React, { Fragment } from 'react';
import pluralize from 'pluralize';
import { isEmpty, map, keys } from 'lodash';

const Counters = ({ countsHash, onClick }) => {
  const zeroItems = "0 items";
  const emptyCounts = isEmpty(countsHash);

  const keysArr = keys(countsHash);
  const lastKey = emptyCounts ? null : keysArr[keysArr.length - 1];

  const handleClick = (value) => {
    onClick(value.kinds, value.type);
  };

  return (
    <>
      { emptyCounts && <>{ zeroItems }</> }
      {
        !emptyCounts &&
        <>
          {
            map(countsHash, (value, itemName) => {
              return (
                <Fragment key={ itemName }>
                  <span className="link" onClick={ () => handleClick(value) }>
                    { value.count } {pluralize(itemName, value.count)}
                  </span>
                  { itemName !== lastKey && <>, </> }
                </Fragment>
              );
            })
          }
        </>
      }
    </>
  );
};

export default Counters;
