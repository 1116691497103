import React, { useState, useEffect, useRef } from 'react';
import { map, find } from 'lodash';
import classnames from 'classnames';

const StatTestingDropdown = ({ itemTitle, scorecard, currentValue, onUpdate, averageMode }) => {
  const sourceEl = useRef(null);

  const [ showDropDown, updateShowDropDown ] = useState(false);

  const elements = [
    { text: "No stat testing", value: "none" },
    { text: "Stat testing vs average", value: "average" }
  ];

  if (!scorecard) {
    elements.push({ text: `Stat testing vs each ${itemTitle}`, value: "each" });
  }

  if (scorecard && currentValue === "each") {
    currentValue = "none";
  }

  const currentText = (
    find(elements, (item) => item.value === currentValue) || {}
  ).text;

  useEffect(() => {
    const body = document.body || document.querySelector("body");
    let pointedToElement = false;

    const bodyListenerFunction = (event) => {
      if (!pointedToElement) {
        updateShowDropDown(false);
      }
      pointedToElement = false;
    };
    const elementListenerFunction = (event) => {
      event.preventDefault();
      pointedToElement = true;
    };

    const el = sourceEl.current;

    el.addEventListener("click", elementListenerFunction);
    body.addEventListener("click", bodyListenerFunction);

    return (() => {
      el.removeEventListener("click", elementListenerFunction);
      body.removeEventListener("click", bodyListenerFunction);
    });
  }, []);

  const openDropDown = (event) => {
    event.preventDefault();
    updateShowDropDown(true);
  };

  const onSelectElement = (event, item) => {
    onUpdate(item.value);
    updateShowDropDown(false);
  };

  return (
    <div
      className="chosen-container chosen-container-single chosen-container-single-nosearch chosen-container-active chosen-with-drop"
      style={ { width: "100%" } }
      ref={ sourceEl }
    >
      <a
        className="chosen-single -default-cursor"
        tabIndex="-1"
        href="#"
        onClick={ openDropDown }
      >
        <span>{ currentText || "" }</span>
      </a>
      {
        showDropDown &&
        <div className="chosen-drop">
          <ul className="chosen-results">
            {
              map(elements, (item, index) => {
                return (
                  <li
                    key={ `statTesting${index}` }
                    className={
                      classnames(
                        'active-result',
                        { 'result-selected highlighted': currentValue === item.value }
                      )
                    }
                    onClick={ (event) => onSelectElement(event, item) }
                  >
                    { item.text }
                  </li>
                );
              })
            }
          </ul>
        </div>
      }
    </div>
  );
};

export default StatTestingDropdown;
