import React from "react";
import { includes } from "lodash";
import classnames from 'classnames';

const CheckboxPlate = ({ item, name, selectedAnswers, checkboxChange, iconPrefix, modifier }) => {
  const onChangeCheckbox = (value) => {
    if (includes(selectedAnswers, item.id)) {
      const index = selectedAnswers.findIndex((el) => el === value);
      selectedAnswers.splice(index, 1);
    } else {
      selectedAnswers.push(value);
    }
    checkboxChange([ ...selectedAnswers ]);
  };

  const complexId = `${name}-${item.id}`;

  return (
    <div className="checkbox-plate">
      <input
        type="checkbox"
        name={ name }
        value={ item.id }
        id={ complexId }
        onChange={ () => {
          onChangeCheckbox(item.id);
        } }
      />
      <label
        htmlFor={ complexId }
        className={
          classnames(
            "checkbox-plate_label",
            modifier
          )
        }
      >
        <span className="checkbox-plate_checked">
          <svg className={ `checkbox-plate_checked-svg` }>
            <use xlinkHref={ `#svg_checked-circle-invert` } />
          </svg>
        </span>
        <span className="checkbox-plate_icon">
          <svg className={ `checkbox-plate_svg -${item.icon || item.id}` }>
            <use xlinkHref={ `#svg_${iconPrefix}-${item.icon || item.id}` } />
          </svg>
        </span>
        <span className="checkbox-plate-text">{item.title}</span>
      </label>
    </div>
  );
};

export default CheckboxPlate;


