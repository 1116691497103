import React from "react";

const SSNInfo = () => {
  return (
    <div className="ssn-hint">
      <div className="ssn-hint_tips">
        <div className="tip-item">
          <h2>Why do we need your date of birth and SSN?</h2>
          <span>
            To help the government fight the funding of terrorism and money laundering activities,
            { ' ' }
            federal law requires all financial institutions to obtain, verify, and record
            { ' ' }
            information that identifies each person who opens an account.
          </span>
        </div>
        <div className="tip-item">
          <h2>How do we secure your SSN?</h2>
          <ul>
            <li>We don’t share your SSN without your consent.</li>
            <li>Your SSN is encrypted and is secure.</li>
            <li>We only use your SSN to verify your identity. This doesn’t impact your credit score.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SSNInfo;
