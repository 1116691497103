import React, { useContext, useEffect } from 'react';
import NativeModal from "../components/nativeModal";
import { GlobalContext } from "../contexts/appContexts";

const BaseNotifications = ({ nextStep }) => {
  const { mergeData } = useContext(GlobalContext);

  useEffect(() => {
    mergeData({ baseNotificationsShown: true });
  }, []);

  const handleNotAllow = () => {
    mergeData({ baseNotificationsAllow: false });
    nextStep();
  };

  const handleOk = () => {
    mergeData({ baseNotificationsAllow: true });
    nextStep();
  };

  const nativeModalButtons = (
    <>
      <button className="button-native" onClick={ () => { handleNotAllow(); } }>
        Don't Allow
      </button>
      <button className="button-native -bold" onClick={ () => { handleOk(); } }>
        OK
      </button>
    </>
  );

  return (
    <div className="native-notification-background">
      <NativeModal buttonsAsRows buttons={ nativeModalButtons }>
        <>
          <h2>
            PayPal would like to send you notifications
          </h2>
          <p>
            Notifications may include alerts sounds, and icon badges.
            { ' ' }
            These can be configured in Settings
          </p>
        </>
      </NativeModal>
    </div>
  );
};

export default BaseNotifications;


