import { map, clone } from 'lodash';

const matchImages = (content, withIndex) => {
  const expression = new RegExp("(<img [^>]+/?>)", withIndex ? "" : "g");
  return content.match(expression);
};

const imageSrc = (item, images) => {
  const checkForImage = item.match(/%imageIndex(\d+)/);
  if (!checkForImage) {
    return {};
  }
  const imageIndex = parseInt(checkForImage[1], 10);
  return images[imageIndex] || {};
};

const splitTextAndImages = (content) => {
  let newContent = clone(content);
  const images = map(
    matchImages(newContent, false),
    (el, index) => {
      const matchedImages = matchImages(newContent, true);
      const item = matchedImages[0];
      const strIndex = matchedImages.index;
      newContent = [
        `${newContent.substring(0, strIndex)}`,
        `%split%imageIndex${index}%split`,
        `${newContent.substring(strIndex + item.length)}`
      ].join('');
      const elem = document.createElement('textarea');
      elem.innerHTML = item.match(/src\s*=\s*['"]([^'"]+)['"]/)[1];
      let alt = '';
      const altMatch = item.match(/alt\s*=\s*['"]([^'"]+)['"]/);
      if (altMatch) {
        alt = altMatch[1];
      }
      return { src: elem.value, alt };
    }
  );

  return { content: newContent.split('%split'), images };
};

export { splitTextAndImages, imageSrc };
