import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';
import { sidebarProfileClick } from "../../../google-analytics/events";

const Profile = () => {
  const { controllerName, initialData, paths, gaTrackingId } = useContext(BaseContext);
  const isDemoUser = initialData.is_demo_user;
  const editProfileUrl = paths.edit_profile;
  const profilePage = controllerName === 'profiles';
  return (
    <>
      {
        !isDemoUser &&
        <li className="sidebar_nav-list-item">
          <a
            className={ classnames("sidebar_nav-link -with-icon", { '-active': profilePage }) }
            href={ editProfileUrl }
            onClick={ () => sidebarProfileClick(gaTrackingId) }
          >
            <svg className="sidebar_nav-link-icon icon -menu-profile">
              <use xlinkHref="#svg_menu-profile" />
            </svg>
            <span className="sidebar_nav-link-text">Profile</span>
          </a>
        </li>
      }
    </>
  );
};

export default Profile;
