import React, {  useState } from 'react';
import { map, find } from 'lodash';
import { generateId } from '../../../common/dateUtils';

const InlineDropdown = ({ value, onChange, list }) => {
  const [ labelId ] = useState(() => (generateId()));
  const handleChange = (event) => {
    onChange(event.target.value, event);
  };
  const label = find(list, (item) => item.value === value) || {};

  return (
    <>
      <label className="base-inline-dropdown-label" htmlFor={ labelId }>
        { label.title }
        <select
          className="base-inline-dropdown-select"
          id={ labelId }
          value={ value }
          onChange={ handleChange }
        >
          {
            map(list, (item) => (
              <option key={ item.value } value={ item.value }>{ item.title }</option>
            ))
          }
        </select>
      </label>
    </>
  );
};

export default InlineDropdown;
