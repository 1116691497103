import React from 'react';
import Question from "./Question";

const QuestionsList = ({ options, questions, setQuestions, images }) => (
  <div className="form-group -max-screener-and-custom-metrics-width">
    {
      questions.map((question, index) => (
        <Question
          key={ `Question${index}-${question.name}-${question.tmpIndex}` }
          options={ options }
          question={ question }
          questions={ questions }
          setQuestions={ setQuestions }
          questionIndex={ index }
          images={ question.answer_images || {} }
        />
      ))
    }
  </div>
);

export default QuestionsList;
