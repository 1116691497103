import React, { useState, useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { each, filter, find, fromPairs, map, toPairs } from "lodash";
import cn from "classnames";
import SvgIcon from '../../../../../common/components/svg-icon';
import {
  calculateQuotaForCustomScreening,
  reIndexConditionList
} from '../helper';
import { QuestionContext } from '../contexts';
import UploadButton from "../../../../../common/components/upload_button";
import ImageInPopup from "../../../../../common/components/image_in_popup";
import AdditionalAnswerLine from './lines/AdditionalAnswerLine';
import ConditionSelect from './lines/conditionSelect';

const SQAList = ({
  options, question, questionIndex, screenerQuestions, setScreenerQuestions
}) => {
  const {
    needLogicEdit, needNone, needOther,
    setManageFivePtToggle, setFivePtScaleMode,
    images, fivePtScaleMode, withImages
  } = useContext(QuestionContext);

  const [ , setFocusLastOnEnter ] = useState(false);
  const [ uploading, setUploading ] = useState(false);
  const getImageData = (key) => (question.answer_images?.[key] !== undefined ? images?.[key] : null);
  const answersToGridItems = () => (
    map(
      toPairs(question.answers),
      ([ key, question ]) => (
        {
          id: key,
          question,
          editable: true,
          imageData: getImageData(key)?.meta_data,
          imageSrc: getImageData(key)?.url
        }
      )
    )
  );
  const [ gridItems, setGridItems ] = useState(answersToGridItems);
  const refs = useRef([]);

  const handleOnDragEnd = (result) => {
    const from = result?.source?.index,
      to = result?.destination?.index;

    if (to === undefined) {
      return;
    }

    each(question.condition_list, (el) => {
      const elIndex = parseInt(el.index);
      if (elIndex === from) {
        el.index = to;
      }
      if (from < to &&  from < elIndex  && elIndex <= to) {
        el.index = elIndex - 1;
      }
      if (from > to &&  to <= elIndex  && elIndex < from) {
        el.index = elIndex + 1;
      }
    });

    const items = Array.from(question.answers);
    const grids = Array.from(gridItems);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    const [ reorderedGridItem ] = grids.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    grids.splice(result.destination.index, 0, reorderedGridItem);
    each(grids, (item, index) => (item.id = index));
    question.answers = items;
    setGridItems([ ...grids ]);
    handleChangeAnswersImages(question, gridItemsToAnswersImages(grids));

    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeAnswer = (event, index) => {
    question.answers[index] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeCondition = (event, index) => {
    const found = find(question.condition_list, (el) => el.index === index);
    if (found) {
      const newValue = event.target.value;
      const previousValue = found.cond;
      found.cond = newValue;

      if (index === 'noneOfTheAbove' && newValue === 'is') {
        question.multi_condition = 1;
      }
      if ((newValue === 'is_not' || previousValue === 'is_not') && question.kind !== 'Multi') {
        calculateQuotaForCustomScreening(question);
      }
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const findConditionItem = (index) => {
    let found = find(question.condition_list, (el) => {
      return el.index === index;
    });

    if (!found) {
      found = { index, cond: '' };
      question.condition_list.push(found);
    }

    return found.cond;
  };

  const delAnswer = (event, answer, index) => {
    event.preventDefault();

    if (question.answers.length <= 1) {
      return;
    }

    question.answers.splice(index, 1);

    gridItems.splice(index, 1);
    each(gridItems, (el, i) => {
      el.id = i.toString();
    });
    setGridItems([ ...gridItems ]);

    const foundConditionItem = find(question.condition_list, (c) => {
      return c.index === index;
    });

    const conditionItemStatus = foundConditionItem?.cond;
    const conditionIdx = question.condition_list.indexOf(foundConditionItem);
    if (conditionIdx > -1) {
      question.condition_list.splice(conditionIdx, 1);
      reIndexConditionList(question, index);
    }

    if (conditionItemStatus !== 'is_not' && question.kind !== 'Multi') {
      calculateQuotaForCustomScreening(question);
    }

    const mustSelectList = filter(question.condition_list, (item) => item.cond === 'is');
    const mustSelectListLength = mustSelectList.length;

    if (question.multi_condition >= mustSelectListLength && mustSelectListLength > 1) {
      question.multi_condition = mustSelectListLength - 1;
    }

    updateFivePtToggleStates();
    handleChangeAnswersImages(question, gridItemsToAnswersImages());
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const pushAnswer = () => {
    question.answers.push('');
    updateFivePtToggleStates();
    findConditionItem(question.answers.length - 1);
    if (question.kind !== 'Multi') {
      calculateQuotaForCustomScreening(question);
    }
  };

  const addAnswer = (event) => {
    event.preventDefault();
    pushAnswer();
    addGridItem();
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const extractData = (event) => {
    const text = event.clipboardData.getData('text/plain');
    const lines = text.replace(/\r/g, '').replace(/\n$/, '').split("\n");
    if (lines.length === 1 && lines[0].indexOf("\t") === -1) {
      return null;
    }
    event.preventDefault();
    return lines;
  };

  const handlePaste = (event, fromIndex) => {
    const lines = extractData(event);
    const answersOldLength = question.answers.length;
    if (lines != null) {
      each(lines, (line, li) => {
        const correctedLi = li + fromIndex;
        question.answers[correctedLi] = line;
        findConditionItem(correctedLi);
      });
    }
    if (question.answers.length > answersOldLength) {
      calculateQuotaForCustomScreening(question);
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const answerChangeRef = (item, index) => {
    if (item) {
      refs.current[index] = item;
    }

    if (!item || question.answers.length - 1 !== index) {
      return;
    }
    setFocusLastOnEnter(
      (focusLastRequest) => {
        if (focusLastRequest) {
          item.focus();
        }
        return false;
      }
    );
  };

  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (question.answers.length - 1 === index) {
        pushAnswer();
        addGridItem();
        setScreenerQuestions([ ...screenerQuestions ]);
        setFocusLastOnEnter(true);
      } else {
        refs.current[index + 1].focus();
      }
    }
  };

  const onSwitchOtherAnswer = () => {
    if (question.kind !== 'Multi') {
      calculateQuotaForCustomScreening(question);
    }
  };

  const updateFivePtToggleStates = () => {
    const toggleEnableCondition = question.answers.length >= 5 && question.kind === 'Single';
    setManageFivePtToggle(toggleEnableCondition);
    if (question.answers.length < 5) {
      setFivePtScaleMode(false);
      question.as_point_scale = false;
      setScreenerQuestions([ ...screenerQuestions ]);
    }
  };

  const addGridItem = () => {
    const key = question.answers.length - 1;
    gridItems.push({
      id: key,
      question,
      editable: true,
      imageData: getImageData(key)?.meta_data,
      imageSrc: getImageData(key)?.url
    });
    setGridItems([ ...gridItems ]);
  };

  const gridItemsToAnswersImages = (inputData) => {
    const data = inputData || gridItems;
    return fromPairs(
      map(
        filter(data, (el) => el.imageData),
        (el) => ([ el.id, { meta_data: el.imageData, url: el.imageSrc } ])
      )
    );
  };

  const handleOnChangeEditableGridItemImage = (newData, index) => {
    gridItems[index].imageData = newData.metaData;
    gridItems[index].imageSrc = newData.url;
    setGridItems([ ...gridItems ]);
    handleChangeAnswersImages(question, gridItemsToAnswersImages());
  };

  const handleChangeAnswersImages = (q, imageData) => {
    q.answer_images = imageData;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onInitUploading = () => {
    setUploading(true);
  };

  const onUploaded = (files, index) => {
    const [ file ] = files;
    const { cacheSrc: url, metaData } = file;
    handleOnChangeEditableGridItemImage({ url, metaData }, index);
    setUploading(false);
  };

  const onImageDelete = (index) => {
    gridItems[index].imageData = null;
    gridItems[index].imageSrc = null;
    setGridItems([ ...gridItems ]);
    handleChangeAnswersImages(question, gridItemsToAnswersImages());
  };

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <Droppable droppableId="answers-area">
        {
          (provided) => (
            <ul
              className="form-question_answers"
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              {
                map(question.answers, (answer, index) => (
                  <Draggable
                    key={ index }
                    draggableId={ `x${questionIndex}-${index}` }
                    index={ index }
                    isDragDisabled={ question.answers_order !== 'specific' }
                  >
                    {
                      (provided) => (
                        <li
                          id={ `x${questionIndex}-${index}` }
                          { ...provided.draggableProps }
                          ref={ provided.innerRef }
                          className="form-question_answer"
                        >
                          <div className="form-question_grid">
                            <div
                              className={
                                cn(
                                  "form-question_grid-item -first-answer-item",
                                  { '-transparent': question.answers_order !== 'specific' }
                                )
                              }
                            >
                              <div
                                className="form-question_answer-move"
                                { ...provided.dragHandleProps }
                              >
                                <SvgIcon name="i-move" />
                              </div>
                            </div>
                            {
                              withImages && !gridItems[index]?.imageData && !fivePtScaleMode &&
                              <div className="file-uploader">
                                <div className="form-flavor_image -no-image -with-margins -grid">
                                  <UploadButton
                                    className="link -no-hover"
                                    allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                                    onUploaded={ (newData) => { onUploaded(newData, index); } }
                                    onInit={ onInitUploading }
                                  >
                                    <svg className="icon -i-no-image-current-color">
                                      <use xlinkHref="#svg_i-no-image-current-color" />
                                    </svg>
                                  </UploadButton>
                                </div>
                              </div>
                            }
                            {
                              withImages && gridItems[index]?.imageData && !fivePtScaleMode &&
                              <div className="file-uploader">
                                <div className={ cn("form-flavor_image -with-margins", { "-content-flex-center": uploading }) }>
                                  <UploadButton
                                    className="link -delete"
                                    allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                                    onUploaded={ (newData) => { onUploaded(newData, index); } }
                                    onInit={ onInitUploading }
                                  >
                                    <ImageInPopup
                                      className="-pointer"
                                      customZIndex={ 1062 }
                                      src={ gridItems[index].imageSrc }
                                    >
                                      <img className="-pointer" src={ gridItems[index].imageSrc } alt={ "text" } />
                                    </ImageInPopup>
                                  </UploadButton>
                                  <div className="form-flavor_image_delete">
                                    <button onClick={ () => { onImageDelete(index); } } className="link -delete">
                                      <svg className="icon-link_icon icon -i-delete">
                                        <use xlinkHref="#svg_i-delete" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            }
                            <div
                              className={
                                cn(
                                  "form-question_grid-item -grow",
                                  { 'has-error': question?.errors?.answers }
                                )
                              }
                            >
                              <input
                                ref={ (item) => answerChangeRef(item, index) }
                                className="form-field -block"
                                type="text"
                                value={ question.answers[index] }
                                onChange={ (event) => {
                                  onChangeAnswer(event, index);
                                } }
                                onPaste={ (event) => handlePaste(event, index) }
                                onKeyPress={ (event) => handleKeyPress(event, index) }
                                placeholder="Add Answer Text"
                              />
                            </div>
                            {
                              needLogicEdit &&
                              <div className="form-question_grid-item -select">
                                <ConditionSelect
                                  value={ findConditionItem(index) }
                                  onChangeCondition={ onChangeCondition }
                                  i={ index }
                                />
                              </div>
                            }

                            <div className="form-question_grid-item -buttons">
                              <button
                                className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                onClick={ (event) => {delAnswer(event, answer, index);} }
                                disabled={ question.answers.length === 1 }
                              >
                                <SvgIcon name="i-delete" />
                              </button>

                              {
                                question.answers.length - 1 === index &&
                                <button
                                  className="button -circle form-question_grid-button"
                                  onClick={ (event) => {addAnswer(event);} }
                                >
                                  <SvgIcon name="i-add" />
                                </button>
                              }
                            </div>
                          </div>
                        </li>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}

              {
                needOther &&
                <AdditionalAnswerLine
                  fieldBoolName={ 'other_answer' }
                  fieldTextName={ 'other_answer_text' }
                  fieldOptionsName={ 'other_specify' }
                  fieldConditionsKey={ 'other' }
                  question={ question }
                  questionIndex={ questionIndex }
                  options={ options }
                  findConditionItem={ findConditionItem }
                  onChangeCondition={ onChangeCondition }
                  screenerQuestions={ screenerQuestions }
                  setScreenerQuestions={ setScreenerQuestions }
                  onSwitchAdditionalAnswer={ onSwitchOtherAnswer }
                />
              }
              {
                needNone &&
                <AdditionalAnswerLine
                  fieldBoolName={ 'none_answer' }
                  fieldTextName={ 'none_answer_text' }
                  fieldOptionsName={ 'none_of_the_above' }
                  fieldConditionsKey={ 'noneOfTheAbove' }
                  question={ question }
                  questionIndex={ questionIndex }
                  options={ options }
                  findConditionItem={ findConditionItem }
                  onChangeCondition={ onChangeCondition }
                  screenerQuestions={ screenerQuestions }
                  setScreenerQuestions={ setScreenerQuestions }
                />
              }
            </ul>
          )
        }
      </Droppable>
    </DragDropContext>
  );
};


export default SQAList;
