import React from 'react';

const TextDescription = ({ text }) => <li className="plan-short-description-value">
  {text.split('\n').map((value, idx) => <p key={idx}>{value}</p>)}
</li>;

const ComplexDescription = ({ text, icon, iconPath, iconClassName }) => <>
  <li className="plan-short-description-icon">
    {
      icon && <svg className={ `icon -${ icon } ${iconClassName}` }>
        <use xlinkHref={ `#svg_${ icon }` }/>
      </svg>
    }
    {
      iconPath && <img className={`icon ${iconClassName}`} src={iconPath} />
    }
  </li>
  <TextDescription text={text} />
</>;

const renderDescriptionListItem = (element, idx) =>
  typeof element === 'string'
    ? <TextDescription key={idx} text={element} />
    : <ComplexDescription
        key={idx}
        text={element.text}
        icon={element.icon}
        iconPath={element.iconPath}
        iconClassName={element.iconClassName} />;


const renderDescription = (info, idx) => {
  const [title, list] = info;
  return <div key={idx} className="choose-plan-card">
    <h6 className="plan-short-description-title">{title}</h6>
    <ul className="plan-short-description-list">
      {list.map(renderDescriptionListItem)}
    </ul>
  </div>
}

const PlanShortDescription = ({ description }) => description.map(renderDescription);

export default PlanShortDescription;
