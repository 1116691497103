import React, { useContext, useState } from 'react';
import FormControl from '../../components/formControl';
import Layout from "../../components/layout";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { GlobalContext } from '../../contexts/appContexts';

const CardPin = ({ nextStep, skipStep, headerStep, numberOfHeaderSteps }) => {
  const { fakeData } = useContext(GlobalContext);

  const [ pin, setPin ] = useState('');
  const [ confirmedPin, setConfirmedPin ] = useState('');
  const handleNext = () => {
    nextStep();
  };

  const handleSkip = () => {
    skipStep();
  };

  const disabledNextButton = (!pin || !confirmedPin);
  const footerButtons = (
    <>
      <button
        disabled={ disabledNextButton }
        className="button -full-width"
        onClick={ handleNext }
      >
        Create PIN
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  const handlePinClick = () => {
    setPin(fakeData.cardPin);
  };

  const handleConfirmedPinClick = () => {
    setConfirmedPin(fakeData.cardPin);
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  return (
    <Layout header={ header } footer={ footer }>
      <div className="personal-info">
        <div className="content_title">
          <h1>Create your PayPal Debit Card PIN</h1>
        </div>
        <div className="content_text">
          So you’re ready if a store requires a PIN.
        </div>
        <div className="personal-info_form form">
          <div className="form_group">
            <FormControl
              disableChange
              initialText={ pin }
              type="password"
              label="Create a 4-digit PIN"
              onClick={ handlePinClick }
            />
            <FormControl
              disableChange
              initialText={ confirmedPin }
              type="password"
              label="Confirm your PIN"
              onClick={ handleConfirmedPinClick }
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CardPin;


