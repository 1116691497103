import { saveUserDataBase } from '../respondent_data.js';

const saveUserData = (url, status, result, data)  => {
  const moreParams = {
    claim_answers_attributes: result.claim_answers,
    claim_views_attributes: result.claim_views,
    thought_bubble_answers_attributes: result.thought_bubbles
  };
  const moreSurveyData = {
    claims_set: data.claims_set
  };
  saveUserDataBase(
    url, status, result, data, moreParams, moreSurveyData
  );
};

export default saveUserData;
