import React, { useContext } from "react";
import { IndexData } from '../../../index-context';
import TrBaseComparisonProducts from './tr-base-comparison-products';

const TrCartCompetitors = ({ checkedConcepts }) => {
  const { data } = useContext(IndexData);
  return (
    <TrBaseComparisonProducts
      checkedConcepts={ checkedConcepts }
      sectionTitle={ data.titles.cart_competitors }
      kindName={ 'shelf_cart_competitors' }
    />
  );
};

export default TrCartCompetitors;
