import React, {useEffect} from "react";
import classnames from 'classnames';
import GreyStripe from './greyStripe';
import { removeKeyboardClass } from '../helpers/iphoneKeyboardHelper';

const Layout = ({ header, footer, children, greyStripeData, contentClassName }) => {
  useEffect(() => {
    removeKeyboardClass();
  }, []);
  return (
    <>
      <div className="layout">
        { !!header && <div className="layout_header">{ header }</div> }
        <div className="layout_content">
          <main className={ classnames("content", contentClassName) }>
            { children }
          </main>
        </div>
        { !!footer && <div className="layout_footer">{ footer }</div> }
      </div>
      { !!greyStripeData && <GreyStripe { ...greyStripeData } /> }
    </>
  );
};

export default Layout;
