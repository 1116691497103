import { saveUserDataBase } from '../respondent_data.js';

const saveUserData = (url, status, result, data) => {
  const moreParams = {
    thought_bubble_answers_attributes: result.thought_bubbles,
    preference_views_attributes: result.preference_views,
    preference_selections_attributes: result.preference_selections,
    preference_answers_attributes: result.preference_answers
  };
  saveUserDataBase(url, status, result, data, moreParams);
};

export default saveUserData;
