import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const ProjectSetup = () => {
  const {
    initialData, controllerName, currentProject, paths
  } = useContext(BaseContext);
  const canEditExpressProject = initialData.can_edit_express_project;
  const production = currentProject.production;
  const editProjectPath = paths.edit_project;
  const active = controllerName === 'projects';

  return (
    <>
      {
        canEditExpressProject &&
        <li className="sidebar_nav-list-item -sub">
          <a
            className={ classnames("sidebar_nav-link -sub", { '-active': active }) }
            href={ editProjectPath }
          >
            {
              production ? (
                <>Edit Project (Limited)</>
              ) : (
                <>Project Setup</>
              )
            }
          </a>
        </li>
      }
    </>
  );
};

export default ProjectSetup;
