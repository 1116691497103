import Http from '../../../common/http';
import { sendMessage } from '../../../common/messaging';
import { downloadMessage } from './components/bottomMenu/downloadIndicator/helper';

const getUserRecents = (path, customParams, goodCallback, badCallback) => {
  const url = customParams ? `${path}?${customParams}` : path;
  Http.get(url, {}).then(
    (response) => {
      if (response.response.ok) {
        goodCallback && goodCallback(response);
      } else {
        badCallback && badCallback(response);
      }
    },
    (response) => {
      badCallback && badCallback(response);
    }
  );
};

const runExportVisually = (event, customText) => {
  const message = {
    kind: downloadMessage,
    x: event.clientX,
    y: event.clientY,
    offsetX: event.offsetX,
    offsetY: event.offsetY,
    iframe: window.parent !== window
  };
  if (customText) {
    message.customText = customText;
  }
  sendMessage(message);
};

const runExport = (event, url, params) => {
  Http.get(url, params).then(
    (response) => {
      if (response.response.ok) {
        runExportVisually(event);
      }
    }
  );
};

export { getUserRecents, runExportVisually, runExport };
