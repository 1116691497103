import React, { Fragment } from 'react';
import { map, clone } from 'lodash';
import { planCombiName } from './helper';

const PricingModalBauBody = ({ pricing, plans }) => {
  let tempArr = [];
  return (
    <div className="pricing-table">
      <div className="pricing-table_row -legend">
        <div className="pricing-table_cell -title">Lines</div>
        {
          map(pricing[0], (pricingCellTitle, index) => (
            <div key={ `cell${index}` } className="pricing-table_cell">
              {index + 1}-line account{index === 4 ? '+' : ''}
            </div>
          ))
        }
      </div>
      {
        map(plans, (plan, planIndex) => {
          const nextPriceTheSame = (
            planIndex !== plans.length - 1 &&
            pricing[plan.id - 1][0] === pricing[plans[planIndex + 1].id - 1][0]
          );
          let localPlanNames;
          tempArr.push(plan.name);
          if (!nextPriceTheSame) {
            localPlanNames = clone(tempArr);
            tempArr = [];
          }
          return (
            <Fragment key={ `plan${planIndex}` }>
              {
                !nextPriceTheSame &&
                <div key={ `plan${planIndex}` } className="pricing-table_row -data">
                  <div className="pricing-table_cell -title">
                    { planCombiName(localPlanNames) }
                  </div>
                  {
                    map(pricing[0], (item, index) => (
                      <div key={ `cell${index}` } className="pricing-table_cell">
                        ${pricing[plan.id - 1][index]}/mo
                      </div>
                    ))
                  }
                </div>
              }
            </Fragment>
          );
        })
      }
    </div>
  );
};

export default PricingModalBauBody;


