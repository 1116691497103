import React from 'react';

const Header = () => {
  return (
    <div className="content_top -sticky -gray">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item -grow">
            <h1 className="header_title">
              Tag Manager
            </h1>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
