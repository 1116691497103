import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { map, includes } from 'lodash';
import { BaseContext } from '../../contexts';
import { waitingForMessage } from '../../../../../common/messaging';
import {sidebarEntityClick, sidebarRecentClick} from "../../../google-analytics/events";

const Report = () => {
  const [ currentId, setCurrentId ] = useState(null);
  const { initialData, controllerName, paths, gaTrackingId } = useContext(BaseContext);
  const openedDropdown = initialData.opened_dropdown;
  const showConceptsInReport = initialData.show_concepts_in_report;
  const concepts = initialData.concepts;
  const showClaimsInReport = initialData.show_claims_in_report;
  const claims = initialData.claims;
  let items = [];
  if (showConceptsInReport) {
    items = concepts;
  } else if (showClaimsInReport) {
    items = claims;
  }
  const comparisonPath = paths.comparison;
  const active = controllerName === 'concept_comparisons';

  const itemIsActive = (item) => {
    return item.active && currentId === item.id;
  };

  useEffect(() => {
    const retFunc = waitingForMessage((event) => {
      if (
        event.data.to === "Angular" &&
        includes([ "scorecardOpened", "scorecardClosed" ], event.data.kind)) {
        setCurrentId(event.data.id);
      }
    });
    return retFunc;
  }, []);

  return (
    <li className="sidebar_nav-list-item -sub">
      <a
        className={
          classnames("sidebar_nav-link -sub", { '-active': active, '-opened': openedDropdown  })
        }
        href={ comparisonPath }
      >
        Report
      </a>
      {
        openedDropdown && items.length &&
        <ul className="sidebar_nav-list -sub">
          {
            map(items, (item) => (
              <li className="sidebar_nav-list-item -sub" key={ `concept-${item.id}` }>
                <a
                  className={
                    classnames(
                      "sidebar_nav-link -sub -normal", { '-active': itemIsActive(item) }
                    )
                  }
                  href={ `${item.scorecard_path}&salt=${Date.now()}` }
                  onClick={ () => (sidebarEntityClick(gaTrackingId, { item_id: item.id, project_id: initialData.project.id })) }
                >
                  { item.name }
                </a>
              </li>
            ))
          }
        </ul>
      }
    </li>
  );
};

export default Report;
