import React, { useState, useEffect, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { ageRangeValidation } from '../../validation';
import ErrorMessage from './error_message';
import { NegativeSwitcher, RemoveButton } from './section_logic';

const debounceTimeout = 600;

const AgeBreaks = ({
  ageRanges, leftBorder, rightBorder, onChange,
  item, selectNotOperator, removeSection
}) => {
  const [ left, setLeft ] = useState(leftBorder);
  const [ leftError, setLeftError ] = useState(null);
  const [ right, setRight ] = useState(rightBorder);
  useEffect(() => {
    setLeft(leftBorder);
    setRight(rightBorder);
    validateRange(leftBorder, rightBorder);
  }, [ leftBorder, rightBorder ]);
  const [ rightError, setRightError ] = useState(null);
  const [ debounceHash ] = useState({ timeoutId: null });

  const validateRange = (customLeft, customRight) => {
    const validationResult = ageRangeValidation(
      ageRanges, customLeft || left, customRight || right
    );
    setLeftError(validationResult.left);
    setRightError(validationResult.right);
  };

  const debouncedUpdate = (newLeft, newRight) => {
    clearTimeout(debounceHash.timeoutId);
    debounceHash.timeoutId = setTimeout(() => {
      onChange(newLeft, newRight);
    }, debounceTimeout);
  };

  const handleLeftEdit = (event) => {
    const val = event.target.value;
    setLeft(val);
    validateRange(val, right);
    debouncedUpdate(val, right);
  };

  const handleRightEdit = (event) => {
    const val = event.target.value;
    setRight(val);
    validateRange(left, val);
    debouncedUpdate(left, val);
  };

  useLayoutEffect(() => {
    validateRange();
  }, []);

  return (
    <div className="chosen-container chosen-container-multi comparasion_filter localytics-chosen chosen-container-active chosen-with-drop -low-index filter-item">
      <ul className="chosen-choices -no-dropdown">
        <li className="search-choice -title">
          <span>Age</span>
          <NegativeSwitcher
            selectOperator={ (event, operator) => { selectNotOperator(event, operator); } }
            item={ item }
          />
          <span>between</span>
        </li>
        <li className="search-choice -input">
          <input
            type="text"
            className={ classnames({ "-invalid": !!leftError }) }
            maxLength={ 2 }
            value={ left }
            onChange={ handleLeftEdit }
          />
          { !!leftError && <ErrorMessage message={ leftError } /> }
        </li>
        <li className="search-choice -between">
          <span>and </span>
        </li>
        <li className="search-choice -input">
          <input
            type="text"
            className={ classnames({ "-invalid": !!rightError }) }
            maxLength={ 2 }
            value={ right }
            onChange={ handleRightEdit }
          />
          { !!rightError && <ErrorMessage message={ rightError } /> }
        </li>
      </ul>
      <div className="control-panel">
        <RemoveButton removeSection={ removeSection } />
      </div>
    </div>
  );
};

export default AgeBreaks;
