import React from 'react';
import { map } from "lodash";

const QuestionSettings = ({ options, question, questions, setQuestions }) => {
  const onChangeTargetGroup = (newVal) => {
    question.target_group = newVal;
    setQuestions([ ...questions ]);
  };

  const onChangeSurveyLocation = (newVal) => {
    question.survey_location = newVal;
    setQuestions([ ...questions ]);
  };

  return (
    <div className="form_section">
      <div className="form_section-custom-metric-settings -p35-p60">
        <div className="form_section-title-item">
          <h3>Ask this question to:</h3>
          <div className="form_section-text">
            <select
              className="form-field -block -js-target-group"
              value={ question.target_group }
              onChange={ (event) => {onChangeTargetGroup(event.target.value);} }
            >
              {
                map(options.target_group, (v, k) => (
                  <option key={ k } value={ k }>{v}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="form_section-title-item">
          <h3>Location:</h3>
          <div className="form_section-text">
            <select
              className="form-field -block -js-survey-location"
              value={ question.survey_location }
              onChange={ (event) => {onChangeSurveyLocation(event.target.value);} }
            >
              <option value="default">{ options.target_group_descriptions[question.target_group] }</option>
              <option value="at_the_end">At the end of the survey</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionSettings;
