import React, { useState, useEffect } from 'react';
import { keys } from 'lodash';
import { QuotaModeGlobalContext } from '../contexts';

const QuotaModeGlobalState = ({ question, questions, options, children }) => {
  const quotaModes = options?.quota_modes || {};
  const defaultValue = (keys(quotaModes.list || {}))[0];
  const [ quotaMode, setQuotaMode ] = useState(
    () => ((question || questions[0]).quota_mode || defaultValue)
  );
  useEffect(() => {
    if (question && !question.quota_mode) {
      question.quota_mode = defaultValue;
    }
  }, []);
  const contextValue = { quotaModes, quotaMode, setQuotaMode, demographics: !!questions };

  return (
    <QuotaModeGlobalContext.Provider value={ contextValue }>
      { children }
    </QuotaModeGlobalContext.Provider>
  );
};

export default QuotaModeGlobalState;
