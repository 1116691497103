import React, { useEffect, useState } from "react";
import { isEmpty, values } from "lodash";
import Notification from "../common/notification";
import { inOneOfAllowedMessages } from '../common/navigation';

const firstError = (errors) => {
  const forFirst = values(errors)[0];
  return forFirst[0];
};

const WithSubmit = ({ formId, errors, children, hasChangesFunc, options }) => {
  const [ notificationObject, setNotificationObject ] = useState(null);

  const submitForm = getSubmitFormFunc(formId, hasChangesFunc, options);
  useEffect(() => {
    window.addEventListener("message", submitForm);
    return () => {
      window.removeEventListener('message', submitForm);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(errors)) {
      setNotificationObject({ message: firstError(errors), success: false });
    }
  }, []);

  return (
    <>
      {children}
      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
    </>
  );
};

const getSubmitFormFunc = (id, hasChangesFunc, options) => {
  return (ev) => {
    if (
      !ev.data ||
      typeof (ev.data) !== "object" ||
      !inOneOfAllowedMessages(ev.data)
    ) {
      return;
    }

    if (hasChangesFunc && options && !options.generate && options.is_valid) {
      if (hasChangesFunc().current) {
        if (confirm("You changed some settings. If you had any test data, it will be removed.\n\n Do you want to save the new settings?")) {
          window.document.getElementById(id).submit();
        }
      } else {
        window.location = options.next_step_url;
      }
    } else {
      window.document.getElementById(id).submit();
    }
  };
};

export default WithSubmit;
