import React, { useContext } from "react";
import { IndexData } from '../../../index-context';
import ShelfBaseComparisonProducts from './shelf-base-comparison-products';

const ShelfCartCompetitors = ({ current }) => {
  const { data } = useContext(IndexData);
  return (
    <ShelfBaseComparisonProducts
      current={ current }
      sectionTitle={ data.titles.cart_competitors }
      kindName={ 'shelf_cart_competitors' }
    />
  );
};

export default ShelfCartCompetitors;
