import React from "react";

const NativeTerms = () => {
  return (
    <div className="agreement">
      <div className="agreement_content">
        <p>
          Terms & Conditions
          <br />
          The following terms and conditions (the "Digital Wallet Terms") apply when you choose to add any eligible PayPal-branded payment card ("PayPal Card") to a digital wallet provided through an entity other than PayPal ("Wallet"). In the Digital Wallet Terms, "you" and "your" refer to the holder of the PayPal Card. "We," ''us, "our," and "PayPal" refer to PayPal, Inc. When you add a PayPal Card to the Wallet, you agree to the Digital Wallet Terms:
        </p>
        <p>
          1. Adding Your PayPal Card. Add an eligible PayPal Card to the Wallet by following the instructions supplied by the Wallet provider. Only those PayPal Cards we indicate are eligible may be added to a Wallet. PayPal Cards may only be added to Wallets we support. We may not support all Wallets. We reserve the right to limit your ability to add the same PayPal Card to multiple Wallets. A PayPal Card with an underlying PayPal account that is not in good standing is ineligible for Wallet enrollment. When you add a PayPal Card to a Wallet, the Wallet allows you to use the PayPal Card to conduct transactions for which the Wallet is accepted. The Wallet may not be accepted everywhere your PayPal Card is accepted.
        </p>
        <p>
          2. PayPal Terms Do Not Change. The terms of the agreements which govern the PayPal account and the PayPal Card do not change when you add your PayPal Card to a Wallet. A Wallet simply provides an additional way for you to make purchases with the PayPal Card. Any applicable fees and charges that may apply to use of your PayPal Card will also apply when you use your PayPal Card through use of a Wallet. PayPal does not charge you any additional fees for adding your PayPal Card to the Wallet or for using your PayPal Card in the Wallet. The Wallet provider and other third parties, such as wireless companies or data service providers, may charge you fees.
        </p>
        <p>
          3. PayPal Is Not Responsible for the Wallet. PayPal is not the provider of the Wallet, and we are not responsible for providing the Wallet service to you. We are only responsible for supplying information securely to the Wallet provider to allow use of the PayPal Card in the Wallet. We are not responsible for any failure of the Wallet or the inability to use the Wallet for any transaction. We are not responsible for the performance or non-performance of the Wallet provider or any other third parties regarding any agreement you enter into with the Wallet provider or associated third-party relationships that may impact your use of the Wallet.
        </p>
        <p>
          4. Contacting You Electronically and by Email. You consent to continue to receive electronic communications and disclosures from us in connection with your PayPal Card and the Wallet. You agree that we can contact you by email at any email address you provide to us in connection with any PayPal account and may include contact from companies working on our behalf to service your accounts. You agree to update your contact information with us when it changes.
        </p>
        <p>
          5. Removing the PayPal Card from the Wallet. You should contact the Wallet provider on how to remove a PayPal Card from the Wallet. We can block a PayPal Card in a wallet from being used for purchases at any time, either at your request, or in our sole discretion.
        </p>
        <p>
          6. Governing Law and Disputes. The Digital Wallet Terms are governed by federal law and, to the extent that state law applies, the laws of the state that apply to the agreement under which your PayPal Card is covered. Disputes arising out of or relating to the Digital Wallet Terms will be subject to any dispute resolution procedures in the terms of the applicable account agreements, including the card issuer's agreement with you.
        </p>
        <p>
          7. Ending or Changing Digital Wallet Terms; Assignments. We can terminate the Digital Wallet Terms at any time. We can also change, add or delete any items in the Digital Wallet Terms at any time. We will provide notice if required by law. We can also assign the Digital Wallet Terms. You cannot change the Digital Wallet Terms, but you can end your acceptance of the Digital Wallet Terms at any time by removing all your PayPal Cards from the Wallet. You may not assign the Digital Wallet Terms.
        </p>
        <p>
          8. Privacy. Your privacy and the security of your information are important to us. The PayPal Privacy Policy, as applicable, applies to your use of your PayPal Card in a Wallet. You agree that we may share your information with the Wallet provider, a payment network, and others, in order to provide the services you have requested, to make information available to you about your PayPal Card transactions, and to improve our ability to offer these services. This information helps us add your PayPal Card to a Wallet and for it to remain in the Wallet. We do not control the privacy and security of your information that may be held by the Wallet provider and will be governed by the privacy policy given to you by the Wallet provider.
        </p>
        <p>
          9. Notices. We can provide notices to you concerning the Digital Wallet Terms and your use of a PayPal Card in the Wallet by posting the material on our website, through electronic notice given to any electronic mailbox we maintain for you or to any other email address or telephone number you provide to us, or by contacting you at the current address we have on file for you. Contact us using the telephone numbers provided in Section 10 below.
        </p>
        <p>
          10. Questions. If you have any questions, disputes, or complaints about a Wallet, contact the Wallet provider using the information given to you by the provider. If your card is the PayPal Business Debit Mastercard®, contact us at: 1-866-888-6080; if your card is the PayPal Cash Mastercard, contact us at: 1-844-896-4937.
        </p>
        <p>
          The PayPal Business Debit Mastercard and the PayPal Debit card are issued by The Bancorp Bank, N.A., pursuant to a license by Mastercard International Incorporated. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Cards may be used everywhere Mastercard is accepted. The Bancorp Bank, N.A is issuer of the Card only and not responsible for the associated accounts or other products, services, or offers from PayPal.
        </p>
      </div>
    </div>
  );
};

export default NativeTerms;
