import React, { useContext, useState, Fragment } from "react";
import Select from 'react-select';
import { isEmpty } from "lodash";
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import { IndexData } from "../../index-context";
import { comparisonScorecardCfChangeRegionSettings } from "../../../../common/google-analytics/events";
import RegionsMapperEditor from "./regions-mapper-editor";
import { MarkupData } from "./markups-logic";

const customStyles = {
  container: () => ({
    width: '100%',
    padding: 0,
    minHeight: '38px',
    background: '#fff',
    border: '1px solid #e5e5e5',
    borderRadius: '18px',
    transition: 'border-color .3s,color .3s,background .3s,background-color .3s',
    position: 'relative',
    boxSizing: 'border-box'
  }),
  control: (provided) => {
    return { ...provided, backgroundColor: 'none', borderWidth: 0, boxShadow: 'none' };
  },
  input: (provided) => {
    return { ...provided, backgroundColor: 'none', borderWidth: 0 };
  }
};

const RegionsMapperSettings = ({ reactionMode, conceptId }) => {
  const [ showEditForm, setShowEditForm ] = useState(false);

  const { selectedRegion, setSelectedRegion, regionOptions } = useContext(MarkupData);
  const { init } = useContext(IndexData);

  const onChange = (values) => {
    setSelectedRegion(values);
  };

  const disableSelect = reactionMode == 'create' || regionOptions.length == 1;
  const disableAll = isEmpty(selectedRegion.value) || disableSelect;

  const openEditForm = () => {
    setShowEditForm(true);
  };

  const closeEditForm = () => {
    setShowEditForm(false);
  };

  const isClipMode = (v) => {
    return selectedRegion.value.clip_mode == v;
  };

  const changeClipMode = (v) => {
    comparisonScorecardCfChangeRegionSettings(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: conceptId }
    );
    const r = selectedRegion.value;

    r.clip_mode = v;
    setSelectedRegion({ value: r, label: r.name });

    Http.put(
      r.update_path,
      { region: { clip_mode: v } }
    ).then(
      (http) => {
        if (http.response.status === 200) {}
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  return (
    <Fragment>
      <div className="page-row">

        <div className="page-row_col">
          <div className="form-label">Region:</div>
          <div className="summary-markup_form">

            <Select
              value={ selectedRegion }
              onChange={ onChange }
              options={ regionOptions }
              isSearchable={ false }
              isDisabled={ disableSelect }
              styles={ customStyles }
            />

            {!disableAll && <button onClick={ openEditForm } className="button -edit">Edit</button>}
          </div>
        </div>

      </div>

      <div className="page-row">
        <div className="page-row_col -none">
          <div className="form-label" />
          <div className="summary-markup_regions">
            <input
              className="hide"
              type="radio"
              id="region1"
              name="region_type"
              disabled={ disableAll }
              checked={ isClipMode('inside') }
              onChange={ () => {changeClipMode('inside');} }
            />

            <label className="inside" htmlFor="region1"><SvgIcon name="region1" /></label>

            <input
              className="hide"
              type="radio"
              id="region2"
              name="region_type"
              disabled={ disableAll }
              checked={ isClipMode('covered') }
              onChange={ () => {changeClipMode('covered');} }
            />

            <label className="covered" htmlFor="region2"><SvgIcon name="region2" /></label>

            <input
              className="hide"
              type="radio"
              id="region3"
              name="region_type"
              disabled={ disableAll }
              checked={ isClipMode('outside') }
              onChange={ () => {changeClipMode('outside');} }
            />

            <label className="outside" htmlFor="region3"><SvgIcon name="region3" /></label>
          </div>
        </div>
      </div>

      {
        showEditForm &&
        <RegionsMapperEditor
          region={ selectedRegion.value }
          closeForm={ closeEditForm }
        />
      }
    </Fragment>
  );
};

export default RegionsMapperSettings;
