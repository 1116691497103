import React from 'react';
import SliderRemote from 'react-slick';

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Slider = ({ children, customSettings }) => {
  const settings = { ...defaultSettings, ...customSettings };
  return (
    <SliderRemote { ...settings }>
      { children }
    </SliderRemote>
  );
};

export default Slider;
