import React, { useContext } from 'react';
import { includes, map } from 'lodash';
import cn from "classnames";
import { IndexData } from "../index-context";
import {
  comparisonConceptQuotaDetailsExportClick,
  comparisonDisqualifiedExportClick,
  comparisonVerbatimsExportClick
} from "../../../common/google-analytics/events";
import { classByReportKind, reportKindsArray, reportKindTitle, kindByIndex } from '../headerLinksHelper';
import DropdownButton from './dropdown-button';

const HeaderLinks = ({
  reports, className, conceptId, claimId, tourName, generateExportParams
}) => {
  const { init, exportReport } = useContext(IndexData);

  const onExportRun = (event, conceptId, claimId, item, kind, additionalParams) => {
    if (includes(item.title, "Verbatims")) {
      comparisonVerbatimsExportClick(init.google_analytics_id, { project_id: init.project.id });
    }
    if (includes(item.title, "Disqualified")) {
      comparisonDisqualifiedExportClick(init.google_analytics_id, { project_id: init.project.id });
    }
    if (includes(item.title, "Quota")) {
      comparisonConceptQuotaDetailsExportClick(init.google_analytics_id, { project_id: init.project.id });
    }

    exportReport(event, conceptId, claimId, item, kind, additionalParams);
  };

  return (
    <DropdownButton
      tourName={ tourName }
      headerButton
      topClassName={ className }
      listClassName="-to-right-bottom"
      buttonName="Export"
    >
      <>
        {
          map(reports, (item, index) => (
            <li key={ index }>
              <span>{ item.title }</span>
              <span>
                {
                  map(reportKindsArray(item), (kind, kindIndex) => (
                    <a
                      key={ `${index}${kindIndex}` }
                      className={ cn("-export", classByReportKind(item, kindIndex)) }
                      onClick={
                        (event) => {
                          onExportRun(
                            event, conceptId, claimId, item, kindByIndex(item, kindIndex),
                            generateExportParams ?
                              generateExportParams(
                                item, kindIndex, item.title, reportKindTitle(item, kindIndex)
                              ) : {}
                          );
                        }
                      }
                    >
                      { reportKindTitle(item, kindIndex) }
                    </a>
                  ))
                }
              </span>
            </li>
          ))
        }
      </>
    </DropdownButton>
  );
};

export default HeaderLinks;
