import React from 'react';
import { map, sum } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html';

const PricingModalCustomPerksBody = ({ pricing, plans }) => {
  return (
    <>
      <div className="pricing-table">
        <div className="pricing-table_row -legend">
          <div className="pricing-table_cell -title">Lines</div>
          {
            map(pricing[0], (pricingCellTitle, index) => (
              <div key={ `cell${index}` } className="pricing-table_cell">
                {index + 1}-line account{index === 4 ? '+' : ''}
              </div>
            ))
          }
        </div>
        {
          map(plans, (plan, planIndex) => (
            <div key={ `plan${planIndex}` } className="pricing-table_row -data">
              <div className="pricing-table_cell -title">
                { plan.name }
              </div>
              {
                map(pricing[0], (item, index) => (
                  <div key={ `cell${index}` } className="pricing-table_cell">
                    ${pricing[plan.id - 1][index]}/mo
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
      <div className="pricing-table_perks-note">
        Add up to two perks for $10 each.
      </div>
    </>
  );
};

export default PricingModalCustomPerksBody;


