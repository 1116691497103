import React from 'react';

const PlatePinned = ({ onContinue }) => {
  return (
    <div className="plate-pinned -bottom">
      <div className="plate-pinned_main">
        <button onClick={ onContinue } className="button -red -bordered -full-width" type="button">I'm done browsing</button>
      </div>
      <div className="plate-pinned_placeholder" />
    </div>
  );
};

export default PlatePinned;
