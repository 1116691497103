import React, { useContext, useState } from 'react';
import { GlobalContext } from '../contexts/appContexts';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import AddCardForm from './addCardForm';

const BaseAddCard = ({ headerStep, numberOfHeaderSteps }) => {
  const { fakeData, nextStep, skipStep, currentData, mergeData } = useContext(GlobalContext);
  const [ cardNumber, setCardNumber ] = useState('');
  const [ cardExpiration, setCardExpiration ] = useState('');
  const [ cardCvv, setCardCvv ] = useState('');

  const handleNext = () => {
    mergeData({ card: { cardNumber, cardExpiration, cardCvv }, intentAddCardSkip: false });
    nextStep();
  };
  const handleSkip = () => {
    mergeData({ card: null, intentAddCardSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const disabledAddCard = (!cardNumber || !cardCvv || !cardExpiration);
  const footerButtons = (
    <>
      <button
        disabled={ disabledAddCard }
        className="button -full-width"
        onClick={ handleNext }
      >
        Add Card
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  const handleScanClick = () => {
    mergeData({ intentCardScanClick: true });
  };

  return (
    <Layout header={ header } footer={ footer }>

      <div className="base-intent">
        <div className="content_title">
          <h1>It’s always good to start with a way to pay</h1>
        </div>
        <div className="content_text">
          Set it up now. Send money or check out fast later.
        </div>
        <AddCardForm
          fakeData={ fakeData }
          billingAddress={ currentData.billingAddress }
          cardNumber={ cardNumber }
          setCardNumber={ setCardNumber }
          cardExpiration={ cardExpiration }
          setCardExpiration={ setCardExpiration }
          cardCvv={ cardCvv }
          setCardCvv={ setCardCvv }
          onScan={ handleScanClick }
        />
      </div>
    </Layout>
  );
};

export default BaseAddCard;


