import React from 'react';
import remoteFiles from '../../../../../common/remote_files';
import { flavorNumbers } from '../../express_numbers';

const ReadMoreFlavor = ({ onGetStarted }) => {
  const {
    conceptFocus, flavor, mode, preference, thoughtBubble
  } = remoteFiles.express.steps.flavor;
  const {
    step12x, screenerQuestion
  } = remoteFiles.express.steps.base;
  return (
    <>
      <div className="modal_body">
        <div className="express-steps">
          <h3 className="express-steps_title">Flavors & Variants</h3>
          <h2 className="express-steps_subtitle">Here’s how it works</h2>
          <div className="express-steps_list">
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Select your audience</p>
                  <p className="-descr">Choose demographic filters.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ step12x } />
                </div>
              </div>
            </div>
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Create up to 3 screener questions</p>
                  <p className="-descr">Adding screener questions may impact cost and timing.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ screenerQuestion } />
                </div>
              </div>
            </div>
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Select exercise mode</p>
                  <p className="-descr">
                    Choose from Sequential Monadic (TURF), Sequential Monadic, MaxDiff. Listed modes
                    { ' ' }
                    depend on the number of items uploaded.
                  </p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ mode } />
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-bottom-line">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Add items</p>
                  <p className="-descr">
                    You can evaluate up to {flavorNumbers.itemsMax.express} items
                    {' '}
                    (flavors, fragrances, benefits, features, variants).
                  </p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ flavor } />
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex -no-bottom-line">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">Methodologies</h3>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_item-text">
                  <p>Sequential Monadic</p>
                  <p className="-descr">
                    Respondents will view several items, randomly selected, and react to key metrics
                    { ' ' }
                    and attribute agreement. They will then select their preferred item from the
                    { ' ' }
                    ones evaluated. Ideal for
                    {' '}
                    {flavorNumbers.itemsMin.express}-{flavorNumbers.itemsMax.express} items.
                  </p>
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_item-text">
                  <p>Sequential Monadic / MaxDiff (TURF)</p>
                  <p className="-descr">
                    TURF analysis is used to determine the best combinations of a given number of
                    { ' ' }
                    items by maximizing unduplicated reach. If included, respondents will evaluate
                    { ' ' }
                    all items (max { flavorNumbers.itemsTurfMax.express } for Sequential Monadic;
                    {' '}
                    max { flavorNumbers.claimsMax.express } for MaxDiff) and TURF analysis is an option for reporting.
                    <br />
                    Ideal for:
                    <br />
                    - {flavorNumbers.itemsMin.express}-{flavorNumbers.itemsTurfMax.express} items for Sequential Monadic.
                    <br />
                    - { flavorNumbers.claimsMin.express }-{flavorNumbers.claimsMax.express} items for MaxDiff.
                  </p>
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_item-text">
                  <p>MaxDiff</p>
                  <p className="-descr">
                    MaxDiff is an advanced analytics technique designed to obtain
                    { ' ' }
                    preference/importance scores (utilities) for multiple options of a single item
                    { ' ' }
                    (such as items, product features, claims, etc.). Respondents view a subset of
                    { ' ' }
                    options, and are then asked to indicate which one is "best" and which is
                    { ' ' }
                    "worst". MaxDiff uses respondent's choices to estimate scores for each of the
                    { ' ' }
                    items/options. Ideal for
                    {' '}
                    { flavorNumbers.claimsMin.express }-{flavorNumbers.claimsMax.express} items.
                  </p>
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">buzzback Projectives</h3>
                <div className="express-steps_item-description">
                  For the items randomly selected,
                  <br />
                  respondents will react to buzzback projective techniques.
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Thought Bubble</p>
                    <p className="-descr">
                      With Thought Bubble, respondents describe the item in three words.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ thoughtBubble } />
                  </div>
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex">
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Preference</p>
                    <p className="-descr">
                      Respondents will select their preference and indicate why. Only available if
                      { ' ' }
                      the Sequential Monadic or Sequential Monadic (TURF) modes are selected.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ preference } />
                  </div>
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex -no-bottom-line">
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Concept Focus</p>
                    <p className="-descr">
                      With Concept Focus respondents will mark up what they like and dislike for 3
                      { ' ' }
                      items, randomly selected. Only available if MaxDiff mode is selected.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ conceptFocus } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer -bg-gray -center">
        <div className="modal_footer-button">
          <button className="button modal_button" onClick={ onGetStarted }>
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default ReadMoreFlavor;
