import React from 'react';

const Liability = () => {
  return (
    <div>
      <div>
        <h2>Protection from Unauthorized Transactions</h2>
        <p>To protect yourself from unauthorized activity in your PayPal account, you should regularly log into your PayPal account and review your PayPal account statement. PayPal will notify you of each transaction by sending an email to your primary email address on file. You should review these transaction notifications to ensure that each transaction was authorized and accurately completed.</p>
        <p>PayPal will protect you from unauthorized activity in your PayPal account. When this protection applies, PayPal will cover you for the full amount of the unauthorized activity as long as you cooperate with us and follow the procedures described below.</p>

        <h3>What is an Unauthorized Transaction</h3>
        <p>An “Unauthorized Transaction” occurs when money is sent from your PayPal account that you did not authorize and that did not benefit you. For example, if someone steals or fraudulently obtains your password, uses the password to access your PayPal account, and sends a payment from your PayPal account, an Unauthorized Transaction has occurred.</p>
        <h3>What is not considered an Unauthorized Transaction</h3>
        <p>The following are NOT considered Unauthorized Transactions:</p>
        <ul>
          <li>If you grant authority to someone to use your PayPal account (by giving them your login information) and they exceed the authority you gave them. You are responsible for transactions made in this situation unless you have previously notified PayPal that you no longer authorize transfers by that individual.</li>
          <li>Invalidation and reversal of a payment as a result of the actions described under <span className="pseudo-link">Refunds, Reversals and Chargebacks</span>.</li>
        </ul>
        <h3>Reporting an Unauthorized Transaction</h3>
        <p>If you believe your PayPal login information has been lost or stolen, please <span className="pseudo-link">contact PayPal customer service</span> immediately or call: (888) 221-1161 or write: PayPal, Attn: Error Resolution Department, P.O. Box 45950, Omaha, NE 68145-0950.</p>
        <p>Tell us AT ONCE if you believe that an electronic fund transfer has been made without your permission using your login information or by other means. You could lose all the money in your PayPal account. </p>
        <p>Also, if your PayPal account statement shows transfers that you did not make, including those made with your PayPal login information or by other means, tell us at once. If you do not tell us within 60 days after we provided the statement to you, you may not get back any money you lost after the 60 days if we can prove that we could have stopped someone from taking the money if you had told us in time. If a good reason (such as a long trip or a hospital stay) kept you from telling us, we may extend the time periods.</p>
      </div>
      {/*/////*/}
      <div>
        <h2>Error Resolution</h2>
        <h3>What is an Error</h3>
        <p>An “Error” means the following:</p>
        <ul>
          <li>When money is either incorrectly taken from your PayPal account or incorrectly placed into your PayPal account, or when a transaction is incorrectly recorded in your PayPal account.</li>
          <li>You send a payment and the incorrect amount is debited from your PayPal account.</li>
          <li>An incorrect amount is credited to your PayPal account.</li>
          <li>A transaction is missing from or not properly identified in your PayPal account statement.</li>
          <li>We make a computational or mathematical error related to your PayPal account.</li>
          <li>You request receipt or periodic statement documents that PayPal is required to provide to you.</li>
          <li>You request information concerning preauthorized (recurring) transfers to your PayPal account that PayPal is required to provide to you.</li>
          <li>You request additional information or clarification concerning a transfer to or from your PayPal account, including a request you make to determine whether an error has occurred.</li>
          <li>You inquire about the status of a pending transfer to or from your PayPal account.</li>
          <li>You request documentation or other information, unless the request is for a duplicate copy for tax or other record-keeping purposes.</li>
        </ul>
        <h3>What is not considered an Error</h3>
        <p>The following are NOT considered Errors:</p>
        <ul>
          <li>If you give someone access to your PayPal account (by giving them your login information) and they use your PayPal account without your knowledge or permission. You are responsible for transactions made in this situation.</li>
          <li>Invalidation and reversal of a payment as a result of the actions described under <span className="pseudo-link">Refunds, Reversals and Chargebacks</span>.</li>
          <li>Routine inquiries about the balance in your Balance Account or business account, or any money waiting to be claimed through your personal account if you do not have a Balance Account linked to your personal account.</li>
          <li>Requests for duplicate documentation or other information for tax or other recordkeeping purposes.</li>
        </ul>
        <h3>In case of Errors or questions about your electronic transfers</h3>
        <p>Telephone us at (888) 221-1161, contact us through the PayPal <span className="pseudo-link">Resolution Center</span>; or write us at PayPal, Attn: Error Resolution Department, P.O. Box 45950, Omaha, NE 68145-0950.</p>
        <p>Notify us as soon as you can, if you think your statement or receipt is wrong or if you need more information about a transfer listed on the statement or receipt. We must hear from you no later than 60 days after we sent the FIRST statement on which the problem or error appeared.</p>
        <ul>
          <li>Tell us your name and account number (if any).</li>
          <li>Describe the error or the transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information.</li>
          <li>Tell us the dollar amount of the suspected error.</li>
        </ul>
        <p>If you tell us orally, we may require that you send us your complaint or question in writing within 10 <span className="pseudo-link">Business Days</span>.</p>
        <p>We will determine whether an error occurred within 10 <span className="pseudo-link">Business Days</span> after we hear from you and will correct any error promptly. If we need more time, however, we may take up to 45 days to investigate your complaint or question. If we decide to do this, we will credit your PayPal account within 10 <span className="pseudo-link">Business Days</span> for the amount you think is in error, so that you will have the use of the money during the time it takes us to complete our investigation. If we ask you to put your complaint or question in writing and we do not receive it within 10 <span className="pseudo-link">Business Days</span>, we may not credit your PayPal account.</p>
        <p>For errors involving new PayPal accounts, point-of-sale transactions, or foreign-initiated transactions, we may take up to 90 days to investigate your complaint or question. For new PayPal accounts, we may take up to 20 <span className="pseudo-link">Business Days</span> to credit your PayPal account for the amount you think is in error.</p>
        <p>We will tell you the results within 3 <span className="pseudo-link">Business Days</span> after completing our investigation. If we decide that there was no error, we will send you a written explanation. You may ask for copies of the documents that we used in our investigation.</p>
      </div>
      {/*/////*/}
      <div>
        <h2>Processing Errors</h2>
        <p>We will rectify any processing error that we discover. If the error results in:</p>
        <ul>
          <li>You receiving less than the correct amount to which you were entitled, then we will credit your PayPal account for the difference between what you should have received and what you actually received.</li>
          <li>You receiving more than the correct amount to which you were entitled, then we will debit your PayPal account for the difference between what you actually received and what you should have received.</li>
          <li>Our not completing a transaction on time or in the correct amount, then we will be responsible to you for your losses or damages directly caused by this failure, unless:<ul>
            <li>through no fault of ours, you did not have enough available funds to complete the transaction;</li>
            <li>our system was not working properly and you knew about the breakdown when you started the transaction; or</li>
            <li>the error was due to extraordinary circumstances outside our control (such as fire, flood or loss of Internet connection), despite our reasonable precautions.</li>
          </ul>
          </li>
        </ul>
        <p>Processing errors are not:</p>
        <ul>
          <li>Delays that result from PayPal applying <span className="pseudo-link">holds, limitations or reserves</span>.</li>
          <li>Delays based on a <span className="pseudo-link">payment review</span>.</li>
          <li>Delays described under <span className="pseudo-link">How to buy something</span> related to the time it may take for a purchase transaction to be completed in some situations.</li>
          <li>Your errors in making a transaction (for example, mistyping an amount of money that you are sending).</li>
        </ul>
      </div>
      {/*/////*/}
      <div>
        <h2>Remittance Transfer Errors</h2>
        <h3>What is a Remittance Transfer</h3>
        <p>
          You may be protected for certain errors when you send money outside of the U.S.using a PayPal account. A “Remittance Transfer” is a transaction that meets the following criteria:
        </p>
        <ul>
          <li>The payment is sent from a personal account that is used primarily for personal, family or household purposes,</li>
          <li>The payment is made for personal, family or household purposes,</li>
          <li>The payment is made using the Send Money feature through your PayPal account,</li>
          <li>The payment amount equals or exceeds $15 U.S. dollars (excluding fees charged to you) or the foreign currency equivalent calculated using exchange rates in effect at the time of the transaction, and</li>
          <li>The recipient <span>received the payment in a PayPal account outside of the U.S.</span></li>
        </ul>
        <p>Payments that you make at a merchant website or location by selecting PayPal as a payment option are not “Remittance Transfers.”</p>
        <h3>What qualifies as a Remittance Transfer Error</h3>
        <p>A <strong>“Remittance Transfer Error”</strong> occurs if:</p>
        <ul>
          <li>You paid an incorrect amount to send the Remittance Transfer. For example, you were charged more than the total shown on the remittance transfer receipt you received (unless the total shown on the receipt was an estimate, and the difference results from application of the actual exchange rate, fees, and Taxes).</li>
          <li>PayPal made a computational or bookkeeping error, such as a miscalculation of the amount the recipient received.</li>
          <li>The amount stated in the remittance transfer receipt was not made available to the recipient, unless:<ul>
            <li>we stated in the remittance transfer receipt that we used estimates and the difference in the amount received and the amount stated in the remittance transfer receipt results from application of the actual exchange rate, fees, and Taxes, or</li>
            <li>the problem was caused by extraordinary circumstances outside our control.</li>
          </ul>
          </li>
          <li>Funds were available to the recipient later than the date of availability that was disclosed to you on the remittance transfer receipt or were not delivered, unless:<ul>
            <li>extraordinary circumstances outside our control caused the delay, and we could not reasonably anticipate those circumstances,</li>
            <li>delays resulted from fraud screenings, or were caused by requirements of the Office of Foreign Assets Controls or similar requirements, or</li>
            <li>you (or someone assisting you) made the transfer with fraudulent intent.</li>
          </ul>
          </li>
          <li>You request documentation or information concerning the Remittance Transfer, such as information required to determine if a Remittance Transfer Error occurred.</li>
        </ul>
        <h3>What is not considered a Remittance Transfer Error</h3>
        <p>The following are NOT considered Remittance Transfer Errors:</p>
        <ul>
          <li>An inquiry about the status of a Remittance Transfer, except where the funds from the transfer were not made available to a designated recipient by the disclosed date of availability,</li>
          <li>A request for information for tax or other recordkeeping purposes,</li>
          <li>A change requested by the designated recipient, or</li>
          <li>A change in the amount or type of currency received by the designated recipient from the amount or type of currency stated in the disclosure provided you, if we relied on information provided by you in making the disclosure.</li>
        </ul>
        <h3>What to do if you think there has been a Remittance Transfer Error or problem</h3>
        <p>If you think there has been a Remittance Transfer Error or problem with your Remittance Transfer:</p>
        <ul>
          <li>Call us at: (888) 221-1161.</li>
          <li>Write us at: PayPal, Attn: Error Resolution Department, P.O. Box 45950, Omaha, NE 68145-0950.</li>
          <li>Log in to your PayPal account at paypal.com and go to the <span className="pseudo-link">Resolution Center</span> and dispute a transaction.</li>
        </ul>
        <p>You must contact us within 180 days of the date we promised to you that funds would be made available to the recipient. When you do, please tell us:</p>
        <ul>
          <li>Your name, address and telephone number,</li>
          <li>The error or problem with the transfer, and why you believe it is an error or problem,</li>
          <li>The name of the person receiving the funds, and if you know it, his or her telephone number or address,</li>
          <li>The dollar amount of the transfer, and</li>
          <li>The confirmation code or number of the transaction.</li>
        </ul>
        <p>We will determine whether an error occurred within 90 days after you contact us and we will correct any error promptly. We will tell you the results within 3 <span className="pseudo-link">Business Days</span> after completing our investigation. If we decide that there was no error, we will send you a written explanation. You may ask for copies of any documents we used in our investigation.</p>
      </div>
    </div>
  );
};

export default Liability;

