import { find } from 'lodash';
import ConceptBase from '../concept_base';

class Swipe extends ConceptBase {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  swipeMetricPresent() {
    return !!find(
      this.projectData.express_metrics,
      (el) =>
        el.kind === this.projectData.combined_type_metric &&
        (el.enabled === 'true' || el.enabled === true) &&
        !el._destroy
    );
  }

  sizeCalcPart() {
    if (this.swipeMetricPresent()) {
      return (
        this.conceptsNumber() <= this.priceData.swipe_limit ?
          1 :
          (this.conceptsNumber() / this.priceData.swipe_limit)
      );
    }

    return (this.conceptsNumber() / this.priceData.five_pt_scale_limit);
  }
}

export default Swipe;
