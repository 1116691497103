import {
  each, sumBy, values, map, sum, keys
} from 'lodash';

const resultHash = {
  linesCount: 0,
  lines: {},
  get totalPrice() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.planPrice || 0) +
        sum(
          map(
            item.planSelectedPerks || [],
            (perkId, index) => (
              item.planPerksPrice && item.planPerksPrice.length ?
                item.planPerksPrice[index] :
                0
            )
          )
        )
      )
    );
  }
};

const setHashData = (path, value) => {
  const pathItems = path.split('.');
  let tempItem = resultHash;
  each(pathItems, (pathEl, index) => {
    if (index !== pathItems.length - 1) {
      if (!tempItem[pathEl]) {
        tempItem[pathEl] = {};
      }
      tempItem = tempItem[pathEl];
    } else { tempItem[pathEl] = value; }
  });
};

const getHashData = (path) => {
  const pathItems = path.split('.');
  let tempItem = resultHash, result = null;
  each(pathItems, (pathEl, index) => {
    if (!tempItem || !tempItem[pathEl]) {
      result = null;
      return false;
    } else if (index === pathItems.length - 1) {
      result = tempItem[pathEl];
      return false;
    }
    tempItem = tempItem[pathEl];
  });
  return result;
};

const updateHashByKey = (hashKey) => {
  const prevValue = getHashData(hashKey) || 0;
  setHashData(hashKey, prevValue + 1);
};

let currentLine = 1;
const setCurrentLine = (val) => {
  currentLine = val;
};
const getCurrentLine = () => currentLine;

const getSetValue = () => getHashData("setValue");

const setSetValue = (value) => {
  setHashData('setValue', value);
  setCurrentLine(1);
};

const setLinesCount = (value) => {
  setHashData('linesCount', value);
  // setCurrentLine(1);

  for (let i = 1; i <= value; i++) {
    if (!getHashData(`lines.${i}.planTitle`)) {
      setHashData(`lines.${i}.planId`, null);
      setHashData(`lines.${i}.planTitle`, '');
      setHashData(`lines.${i}.planPrice`, 0);
      setHashData(`lines.${i}.planSelectedPerks`, null);
    }
  }

  // const lengthLines = Object.keys(resultHash.lines).length;
  while (value < keys(resultHash.lines).length) {
    delete resultHash.lines[keys(resultHash.lines).length];
  }
};
const getLinesCount = () => getHashData('linesCount');

const setPlan = (plan, line, price) => {
  setHashData(`lines.${line}.planId`, plan.id);
  setHashData(`lines.${line}.planTitle`, plan.name);
  setHashData(`lines.${line}.planPrice`, price);
  setHashData(`lines.${line}.planSelectedPerks`, []);
};

const updateLinePrices = (priceForLine) => {
  each(values(resultHash.lines), (item, index) => {
    item.planPrice = priceForLine(item.planId, index);
  });
};

const getSelectedPlans = () => {
  return map(values(resultHash.lines), (item) =>
    ({
      id: item.planId,
      title: item.planTitle,
      price: item.planPrice,
      planSelectedPerks: item.planSelectedPerks,
      planPerksPrice: item.planPerksPrice
    })
  );
};

const getSelectedPlanIdByLine = (line) => {
  return resultHash.lines[line].planId;
};

const getSelectedPerksIdByLine = (line) => {
  return resultHash.lines[line].planSelectedPerks;
};

const getTotal = () => resultHash.totalPrice;
const sendData = () => {
  const finalData = {
    ...resultHash,
    lines: values(resultHash.lines),
    set: getSetValue()
  };

  (window.parent || window).postMessage({
    kind: 'finishPlans',
    result: finalData
  }, "*");
};

const removeHashLine = (lineNumber) => {
  delete resultHash.lines[lineNumber];
  const newLinesHash = {};
  each(values(resultHash.lines), (line, index) => {
    newLinesHash[index + 1] = line;
  });
  resultHash.lines = newLinesHash;
  setHashData('linesCount', (getLinesCount() - 1));
};

const getCurrentLineHash = () => (resultHash.lines[currentLine]);

export {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  setHashData, getHashData,
  updateHashByKey,
  setPlan,
  getSelectedPlans, getTotal,
  sendData,
  setSetValue,
  getSelectedPlanIdByLine,
  getSelectedPerksIdByLine,
  updateLinePrices,
  removeHashLine,
  getCurrentLineHash
};
