import React from 'react';
import { map } from "lodash";
import ButtonMore from './buttonMore';

const AllPlansIncludeWithModal = ({ plans, openDetailsModal }) => {
  const onClick = () => {
    openDetailsModal({
      title: 'All plans include',
      descriptions: map(plans, ({ title, description }) => ({ title, description }))
    });
  };
  return (<div className="plate -gray -all-plans">
    <div className="all-plans">
      <h4 className="plate_title">All plans include</h4>
      <ul className="all-plans_list">
        {
          map(plans, (plan, idx) => (
            <li className="all-plans_item" key={ `all-plans-${idx}` }>
              <svg className="icon -checkmark all-plans_item-icon">
                <use xlinkHref={ `#svg_checkmark` } />
              </svg>
              <span>{ plan.title }</span>
            </li>
          ))
        }
      </ul>
      <div className="all-plans_more">
        <ButtonMore onClickMore={ onClick } />
      </div>
    </div>
  </div>);
};

export default AllPlansIncludeWithModal;

