import React, { useState, useEffect, useLayoutEffect } from 'react';
import StatTestingDropdown from './stat_testing_dropdown';
import CombinationsDropdown from './combinations_dropdown';
import FiltersDropdown from './filters_dropdown';

const FiltersRow = ({
  combinations,
  data,
  turfData,
  loadTurfData,
  forceDropDown,
  combinationsRef,
  filterResultsId,
  demoFilters,
  demoFiltersIndex,
  onFiltersUpdate
}) => {
  const projectId = data.project_id;
  const defaultCombinationSelected = 2;
  const dataCombinationSelectedField = "combinations_number";
  const turfDataCombinationSelectedField = "value";

  const [ combinationsValue, updateCombinationsValue ] = useState(defaultCombinationSelected);
  const [ filtersValue, updateFiltersValue ] = useState([]);
  const [ filterOptions, updateFilterOptions ] = useState([]);
  const [ forceDropdownOpen, updateForceDropdownOpen ] = useState(forceDropDown);

  useEffect(() => {
    updateForceDropdownOpen(forceDropDown);
  }, [ forceDropDown ]);

  useLayoutEffect(() => {
    if (data.filter) {
      updateFiltersValue(demoFilters);
    }
  }, [ demoFilters ]);

  useEffect(() => {
    if (data) {
      updateFilterOptions(data.filter_options);
    }

    let combinationSelected;

    if (turfData && turfData[turfDataCombinationSelectedField]) {
      combinationSelected = turfData[turfDataCombinationSelectedField];
    } else if (data && data[dataCombinationSelectedField]) {
      combinationSelected = data[dataCombinationSelectedField];
    } else {
      combinationSelected = defaultCombinationSelected;
    }
    updateCombinationsValue(combinationSelected);
  }, [ data, turfData ]);

  const handleFiltersUpdate = (newValue) => {
    const filter = onFiltersUpdate(newValue, demoFiltersIndex);
    if (loadTurfData) {
      loadTurfData(projectId, filter, null, null, combinationsValue);
    }
  };

  const onCombinationsUpdate = (newValue) => {
    data[dataCombinationSelectedField] = newValue;
    loadTurfData(projectId, null, null, null, newValue);
  };

  return (
    <>
      <div className="comparisons_plate-item -grow -with-inner-dropdown">
        <FiltersDropdown
          resultsId={ filterResultsId }
          selected={ filtersValue }
          options={ filterOptions }
          forceDropdownOpen={ forceDropdownOpen }
          onUpdate={ handleFiltersUpdate }
        />
      </div>
      {
        !!combinations &&
        <div
          className="comparisons_plate-item -width-1-3"
          ref={ combinationsRef }
        >
          <CombinationsDropdown
            currentValue={ combinationsValue }
            onUpdate={ onCombinationsUpdate }
            combinations={ combinations }
          />
        </div>
      }
    </>
  );
};

export default FiltersRow;
