import React, { useEffect, useState } from 'react';
import { each, includes } from 'lodash';
import { createPortal } from 'react-dom';

const convertStyles = (styles) => {
  const textStylesArr = [];
  each(
    styles,
    (value, key) => {
      textStylesArr.push(`${key}: ${value}`);
    }
  );
  return textStylesArr.join(';');
};


const RemotePopup = ({ className, children, hoverElement, position, show }) => {
  const [ element ] = useState(document.createElement('div'));
  useEffect(() => {
    const body = document.body || document.querySelector('body');
    element.className = className;
    body.appendChild(element);
    return () => {
      body.removeChild(element);
    };
  }, []);

  const baseStyles = {
    position: 'absolute',
    left: '-1000px',
    top: '-1000px',
    'z-index': 2000,
    width: '300px'
  };
  const visibleStyles = { visibility: 'hidden', opacity: 0 };
  const positionStyles = {};

  if (show) {
    visibleStyles.visibility = 'visible';
    visibleStyles.opacity = 1;
    if (hoverElement.current) {
      const positionNumbers = {};
      const scrollTop = window.scrollY || 0;
      const scrollLeft = window.scrollX || 0;
      const hoverBox = hoverElement.current.getBoundingClientRect();
      const elementBox = element.getBoundingClientRect();

      const positions = position.split('-'); // left-center-right are available
      let definedPosition = positions[0] === 'left' ? 'left' : 'center';
      if (positions.length > 1 && includes([ 'left', 'right', 'center' ], positions[1])) {
        definedPosition = positions[1];
      }
      positionNumbers.top = (
        positions[0] === 'top' ?
          (hoverBox.top - elementBox.height - 2)
          :
          (hoverBox.top + hoverBox.height + 2)
      );
      if (definedPosition === 'right') {
        positionNumbers.left = hoverBox.left;
      } else if (definedPosition === 'left') {
        positionNumbers.left = (hoverBox.left - elementBox.width);
      } else if (definedPosition === 'center') {
        positionNumbers.left = ((hoverBox.left + (hoverBox.width / 2)) - (elementBox.width / 2));
      }
      if (positionNumbers.left) {
        positionStyles.left = `${scrollLeft + positionNumbers.left}px`;
      }
      if (positionNumbers.top) {
        positionStyles.top = `${scrollTop + positionNumbers.top}px`;
      }
    }
  }

  element.setAttribute(
    'style',
    convertStyles({ ...baseStyles, ...visibleStyles, ...positionStyles })
  );

  return createPortal(children, element);
};

export default RemotePopup;
