import React, { Fragment, useContext, useState } from 'react';
import { map } from 'lodash';
import renderRawHtml from "../../../../common/render_raw_html";
import { LineDataContext } from '../contexts';
import Popup from '../popup';

const noBreaks = (text) => {
  return text.replace(/<br\/>/, ' ').replace(/\s+/, ' ');
}

const SharedPlan = ({ planObject, onPlanSelect }) => {
  return (
    <div className="shared-plan ng-scope">
      {
        planObject.best &&
        <h3 className="shared-plan-note">Best value per GB</h3>
      }
      <h2 className="-font-20 shared-plan-title" { ...renderRawHtml(planObject.title) }>
      </h2>
      <div className="box-pricing">
        <div className="price"><h2 className="big-value">${ planObject.price }</h2>
          <div className="text-10 value-hint">plan cost per month<sup>1</sup><br/>Plus taxes & fees.</div>
        </div>
      </div>
      <div className="text-14 note">Includes account and line access fees.</div>
      <button className="select-billing-plan" onClick={ (event) => { onPlanSelect(event, planObject) } }>Select a plan</button>
      <h3 className="feature-title">Included</h3>
      <div className="features">
        {
          map(planObject.features, (feature, index) => {
            return (
              <div key={ `sharedPlanFeature${planObject.id}${index}` } className="text-16 feature "  {...renderRawHtml(noBreaks(feature.title))}>
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

const PopupData = ({ plans, onPlanSelect }) => {
  return (
    <Fragment>
      <h2 className="-font-36 popup-title">Shared plan details</h2>
      <div className="popup-body">
        <div className="shared-plans">
          {
            map(plans, (planObject) => {
              return (
                <div key={`popupPlanTitle${planObject.id}`} className="shared-plan">
                  <h2 className="-font-20 shared-plan-title"  { ...renderRawHtml(planObject.title) }>
                  </h2>
                  <div className="box-pricing">
                    <div className="price">
                      <h2 className="big-value">${ planObject.price }</h2>
                      <div className="text-10 value-hint">
                        plan cost per month<sup>1</sup>
                        <br/>
                        Plus taxes & fees.
                      </div>
                    </div>
                  </div>
                  <div className="text-12 note">
                    Includes account and line access fees.
                  </div>
                  <button
                    className="select-billing-plan"
                    onClick={  (event) => { onPlanSelect(event, planObject, "Popup") } }
                  >
                    Select this plan
                  </button>
                </div>
              )
            })
          }
        </div>
        <div className="features-section">
          {
            map(plans, (planObject) => {
              return(
                <div key={`popupPlanFeatureGroup${planObject.id}`} className="features">
                  {
                    map(planObject.features, (feature, index) => {
                      return (
                        <div key={`popupPlanFeature${planObject.id}${index}`} className="feature">
                          <h4 { ...renderRawHtml(noBreaks(feature.title)) }>
                          </h4>
                          {
                            feature.description &&
                            <div className="text-14 feature-description">
                              <span { ...renderRawHtml(feature.description) }>
                              </span>
                              {
                                feature.popupIcon &&
                                <svg className="info"><use xlinkHref="#svg_tooltip"/></svg>
                              }
                            </div>
                          }
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </Fragment>
  )
}

const PrepaidPage = ({ currentLine, onPlanSelect, onChangePage, showChangePage }) => {
  const { shared } = useContext(LineDataContext);
  const [showPopup, updateShowPopup] = useState(false);
  const clickPlanDetails = (event) => {
    event.preventDefault();
    updateShowPopup(true);
  };

  return(
    <div className="content -split-concept">
      <div className="header"></div>
      <div className="prepaid-page">
        <div className="inner-content -split">
          <div className="top-description">
            <div className="ad-left">
              <h2>Let’s choose a plan for all your lines.</h2>
              <span className="text-20">Enroll in paper-free billing and Auto Pay after account creation to save an additional $10/mo per phone line on these plans.
                <svg className="info"><use xlinkHref="#svg_tooltip"/></svg>
              </span>
            </div>
          </div>
          <div className="shared-plans">
              {
                map(shared.sharedPlans, (planObject) => {
                  return(
                    <SharedPlan
                      key={ `sharedPlanItem${planObject.id}` }
                      planObject={ planObject }
                      onPlanSelect={ onPlanSelect }
                    />
                  )
                })
              }
          </div>
          <div className="text-14 bottom-description">
            Account access for 5GB and 10GB plans are $30/mo or $40/mo,
            respectively. Each phone line is $25/mo with Auto Pay and paper-free billing discount, or $35/mo without.
            Line access fees apply to each connected device but they are ineligible for the discount.
          </div>
          <div className="buttons">
            {
              showChangePage &&
              <button className="select-billing-plan change-path" onClick={ onChangePage }>See our other plans<span>&gt;</span></button>
            }
          </div>
          <div className="text-12 bottom-note">
            <sup>1</sup>
            Auto Pay and paper-free billing are required to get the discount.
            You can get Auto Pay by signing up online or on the phone with your bank account or debit card.
            Your discount will be applied each month as long as Auto Pay and paper-free billing stay active.
          </div>
        </div>
      </div>
      {
        showPopup &&
        <Popup onClose={ () => { updateShowPopup(false) } } className="split-popup">
          <PopupData plans={ shared.sharedPlans } onPlanSelect={onPlanSelect}/>
        </Popup>
      }
    </div>
  );
};

export default PrepaidPage;