import { each, map, fromPairs } from 'lodash';

const titleToKey = (title) => title.replace(/[^A-Za-z0-9]/gi, '').toLowerCase();

const planFeaturesMapping = fromPairs(map([
  '5gnationwide', // 5G Nationwide
  '4glte', // 4G LTE
  'callfilterspamblocker', // Call Filter spam blocker
  'unlimitedtalktextdata', // Unlimited talk, text, & data
  'mexicocanadatalktextdata', // Mexico & Canada talk, text, & data
  'internationaltexting', // International texting
  'verizonup', // Verizon Up
  'disney6monthsonus', // Disney+ 6 months on us
  'applearcadeorgoogleplaypass6monthsonus', // Apple Arcade or Google Play Pass 6 months on us
  'discovery6monthsonus', // discovery+ 6 months on us
  'applemusic6monthsonus', // Apple Music 6 months on us
  '5gultrawideband', // 5G Ultra Wideband
  'premiumnetworkaccess', // Premium Network Access
  'unlimitedmobilehotspot25gbhighspeeddata', // Unlimited mobile hotspot (25 GB high-speed data)
  'callfilterplusspamblocker', // Call Filter Plus spam blocker
  '5verizondollarspermonth', // $5 Verizon Dollars per month
  'disneyhuluespnincluded', // Disney+, Hulu, ESPN+ included
  'applearcadeorgoogleplaypassincluded', // Apple Arcade or Google Play Pass included
  'unlimitedmobilehotspot50gbhighspeeddata', // Unlimited mobile hotspot (50 GB high-speed data)
  '50offaplanforawatchtablethumorhotspot', // 50% off a plan for a watch, tablet, Hum, or hotspot
  '2tbofverizoncloudstorage', // 2 TB of Verizon Cloud storage
  '1travelpassdaypermonth', // 1 TravelPass day per month
  'unlimitedpremiumnetworkaccess', // Unlimited Premium Network Access
  '10verizondollarspermonth', // $10 Verizon Dollars per month
  'discovery12monthsonus', // discovery+ 12 months on us
  'applemusic12monthsonus', // Apple Music 12 months on us
  '600gbofverizoncloudstorage', // 600 GB of Verizon Cloud storage,
  'callfilter', // Call Filter
  'verizonuprewards' // Verizon Up rewards
], (item, index) => [item, index + 1]));

const perkFeaturesMapping = fromPairs(map([
  '25gbadditionalmobilehotspotdata', // 25 GB additional mobile hotspot data
  '50offaplanforawatchtablethumorhotspot', // 50% off a plan for a watch, tablet, Hum, or hotspot
  'applemusicincluded', // Apple Music included
  'smartfamilypremiumparentalcontrols', // Smart Family Premium (parental controls)
  'digitalsecureincluded', // Digital Secure included
  '1travelpassdaypermonth', // 1 TravelPass day per month
  'globalchoiceinternationalcalling', // Global Choice international calling
  '2tbofverizoncloudstorage' // 2 TB of Verizon Cloud storage
], (item, index) => [item, index + 1]));

const generatePlanLineFeatureKey = (hashKey, opened) => `PlanDetailsFeature${planFeaturesMapping[hashKey]}${opened ? 'Open' : 'Close'}`;

const initExpandStatisticsPlans = (lineObject) => {
  each(planFeaturesMapping, (index, value) => {
    lineObject[generatePlanLineFeatureKey(value, true)] = 0;
    lineObject[generatePlanLineFeatureKey(value, false)] = 0;
  });
};

const updateExpandStatisticsPlans = (lineIndex, planIndex, title, actionOpen, callback) => {
  callback(`lines.${lineIndex}.plans.${planIndex}.${generatePlanLineFeatureKey(titleToKey(title), actionOpen)}`);
};

const generatePerkLineFeatureKey = (hashKey, opened, landing) =>
  `${landing ? 'Landing' : ''}DetailsFeature${perkFeaturesMapping[hashKey]}${opened ? 'Open' : 'Close'}`;

const initExpandStatisticsPerks = (object, landing) => {
  each(perkFeaturesMapping, (index, value) => {
    object[generatePerkLineFeatureKey(value, true, landing)] = 0;
    object[generatePerkLineFeatureKey(value, false, landing)] = 0;
  });
};

const updateExpandStatisticsPerks = (isLanding, lineIndex, perkIndex, title, actionOpen, callback) => {
  if (isLanding){
    callback(`perks.${perkIndex}.${generatePerkLineFeatureKey(titleToKey(title), actionOpen, true)}`);
  }
  else{
    callback(`lines.${lineIndex}.perks.${perkIndex}.${generatePerkLineFeatureKey(titleToKey(title), actionOpen, false)}`);
  }
};

export {
  initExpandStatisticsPlans, updateExpandStatisticsPlans,
  initExpandStatisticsPerks, updateExpandStatisticsPerks
};