export const cashbackMoney = 10;

export const baseFakeData = {
  cashBackBonus: `$${cashbackMoney}`,
  completeStepsByData: "9/30/24",
  completeStepsByTime: "10:00 AM",
  addressList: [
    { primary: "123 Relington Street", secondary: "Los Angeles, CA 91213" },
    { primary: "2119 Fleming Way", secondary: "Denver, NC 28037" },
    { primary: "4069 Hide A Way Road", secondary: "San Jose, CA 95136" },
    { primary: "161 Seth Street", secondary: "Everett, PA 15537" },
    { primary: "4202 Crim Lane", secondary: "Lanse, MI 49946" },
    { primary: "127 Washington Street", secondary: "Bowstring, MN 56631" }
  ],
  introSetupList: [
    {
      id: "pay",
      title: "Pay in stores with PayPal Debit Card",
      text: "Sign up for free and add it to Apple Pay or your digital wallet and you’re good to go."
    },
    {
      id: "earn",
      title: "Earn 5% cash back everywhere",
      text: "Online and in stores. In a category you get to choose every month with PayPal Debit card."
    },
    {
      id: "rewards",
      title: `Plus a $${cashbackMoney} cash back bonus`,
      rewards: true
    }
  ],
  cashBackCategoriesListShort: [
    { id: "restaurant", title: "Restaurant", innerIndex: 1 },
    { id: "clothing", title: "Clothing", innerIndex: 2 },
    { id: "health", title: "Health & Beauty", innerIndex: 3 },
    { id: "grocery", title: "Grocery", innerIndex: 4 },
    { id: "gas", title: "Gas", innerIndex: 5 }
  ],
  interestsList: [
    { id: "earn", title: "Earn cash back", innerIndex: 1 },
    { id: "send", title: "Send money", innerIndex: 2 },
    { id: "receive", title: "Receive money", innerIndex: 3 },
    { id: "shop", title: "Shop smarter", innerIndex: 4 },
    { id: "offers", title: "Find great offers", innerIndex: 5 }
  ],
  cashBackCategoriesList: [
    { id: "w-clothing", title: "Women’s Clothing", icon: "clothing", innerIndex: 1 },
    { id: "m-clothing", title: "Men’s Clothing", icon: "clothing", innerIndex: 2 },
    { id: "w-shoes", title: "Women’s Shoes", icon: "shoes", innerIndex: 3 },
    { id: "m-shoes", title: "Men’s Shoes", icon: "shoes", innerIndex: 4 },
    { id: "grocery", title: "Groceries", innerIndex: 5 },
    { id: "health", title: "Health & Beauty", innerIndex: 6 },
    { id: "food", title: "Dining", innerIndex: 7 },
    { id: "technology", title: "Tech", innerIndex: 8 },
    { id: "pet", title: "Pet", innerIndex: 9 },
    { id: "sports", title: "Sports & Outdoors", innerIndex: 10 },
    { id: "travel", title: "Travel", innerIndex: 11 },
    { id: "jewelry", title: "Jewelry & Watches", innerIndex: 12 },
    { id: "auto", title: "Auto", innerIndex: 13 },
    { id: "home", title: "Home", innerIndex: 14 },
    { id: "office", title: "Office", innerIndex: 15 },
    { id: "kids", title: "Kids", innerIndex: 16 }
  ],
  benefitsList: [
    { id: 1, title: "Send/receive money and pay online" },
    { id: 2, title: "Tap to pay in stores" },
    { id: 3, title: "Earn 5% cash back on a monthly category" },
    { id: 4, title: "Instantly spend money people send you" },
    { id: 5, title: "Use your PayPal balance to pay and get paid" },
    { id: 6, title: "Add money to PayPal balance in seconds, for free" },
    { id: 7, title: "Mastercard ID Theft Protection" }
  ],
  plansBenefits: [
    { id: 1, title: "Current", benefitsList: [ 1 ] },
    { id: 2, title: "Available Benefits", benefitsList: [ 1, 2, 3, 4, 5, 6, 7 ] }
  ],
  devicesList: [ 'Phone', 'Apple Watch' ],
  usStates: [
    { title: 'Alabama', value: 'AL' },
    { title: 'Alaska', value: 'AK' },
    { title: 'Arizona', value: 'AZ' },
    { title: 'Arkansas', value: 'AR' },
    { title: 'California', value: 'CA' },
    { title: 'Colorado', value: 'CO' },
    { title: 'Connecticut', value: 'CT' },
    { title: 'Delaware', value: 'DE' },
    { title: 'District of Columbia', value: 'DC' },
    { title: 'Florida', value: 'FL' },
    { title: 'Georgia', value: 'GA' },
    { title: 'Hawaii', value: 'HI' },
    { title: 'Idaho', value: 'ID' },
    { title: 'Illinois', value: 'IL' },
    { title: 'Indiana', value: 'IN' },
    { title: 'Iowa', value: 'IA' },
    { title: 'Kansas', value: 'KS' },
    { title: 'Kentucky', value: 'KY' },
    { title: 'Louisiana', value: 'LA' },
    { title: 'Maine', value: 'ME' },
    { title: 'Montana', value: 'MT' },
    { title: 'Nebraska', value: 'NE' },
    { title: 'Nevada', value: 'NV' },
    { title: 'New Hampshire', value: 'NH' },
    { title: 'New Jersey', value: 'NJ' },
    { title: 'New Mexico', value: 'NM' },
    { title: 'New York', value: 'NY' },
    { title: 'North Carolina', value: 'NC' },
    { title: 'North Dakota', value: 'ND' },
    { title: 'Ohio', value: 'OH' },
    { title: 'Oklahoma', value: 'OK' },
    { title: 'Oregon', value: 'OR' },
    { title: 'Maryland', value: 'MD' },
    { title: 'Massachusetts', value: 'MA' },
    { title: 'Michigan', value: 'MI' },
    { title: 'Minnesota', value: 'MN' },
    { title: 'Mississippi', value: 'MS' },
    { title: 'Missouri', value: 'MO' },
    { title: 'Pennsylvania', value: 'PA' },
    { title: 'Rhode Island', value: 'RI' },
    { title: 'South Carolina', value: 'SC' },
    { title: 'South Dakota', value: 'SD' },
    { title: 'Tennessee', value: 'TN' },
    { title: 'Texas', value: 'TX' },
    { title: 'Utah', value: 'UT' },
    { title: 'Vermont', value: 'VT' },
    { title: 'Virginia', value: 'VA' },
    { title: 'Washington', value: 'WA' },
    { title: 'West Virginia', value: 'WV' },
    { title: 'Wisconsin', value: 'WI' },
    { title: 'Wyoming', value: 'WY' }
  ]
};

