import React from 'react';
import cn from "classnames";
import { isEmpty } from "lodash";

const itemLetters = (value, box) => {
  if (!!value.letters && !isEmpty(value.letters)) {
    return box ? value.letters[box] : value.letters;
  }
  return null;
};

const StatusCircle = ({ values, box, noLetters, hideCustom = false }) => {
  if (!values) {
    return (<div className={ cn('status-circle') } />);
  }

  const hl = box ? values.highlight[box] : values.highlight;
  const il = box ? itemLetters(values, box) : itemLetters(values);
  const pc = box ? values.percentage[box] : values.percentage;

  return (
    <div
      className={
        cn(
          'status-circle',
          `-color-${hideCustom ? 'none' : hl}`,
          { '-with-letters': !noLetters && il }
        )
      }
    >
      <span className="status-circle_text">
        { pc }
        { !noLetters && il && <span>{ il }</span> }
      </span>
    </div>
  );
};

export default StatusCircle;
