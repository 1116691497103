import React, {useContext, useState} from "react";
import SvgIcon from "../../../../../common/components/svg-icon";
import Modal from "../../../../../common/components/modal";
import cn from "classnames"
import Http from "../../../../../common/http";
import {MarkupData} from "./markups-logic";

const RegionsMapperEditor = ({region, closeForm, setReactionMode}) => {
  const {focusConcept, setSelectedRegionById} = useContext(MarkupData)

  const [name, setName] = useState(region.name||'')

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const regionAdd = ()=>{
    Http.post(
      focusConcept.create_region_path,
      {
        region: {
          concept_id: focusConcept.id,
          name: name,
          clip_mode: 'covered',
          data: [region]
        }
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setSelectedRegionById(http.data.regions, http.data.new_region_id)
          closeForm()
          setReactionMode('select')
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  }

  const regionUpdate = () => {
    Http.put(
      region.update_path,
      { region: { name: name } },
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setSelectedRegionById(http.data.regions, http.data.region_id)
          closeForm()
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  }

  const regionDelete = () => {
    if(!!region.id){
      Http.del(
        region.destroy_path
      ).then(
        (http) => {
          if (http.response.status === 200) {
            setSelectedRegionById(http.data.regions, null)
            closeForm()
          }
        },
        (reject) => {console.log('something went wrong', reject);}
      );
    }else{
      closeForm()
    }
  }

  return (
    <Modal modalSize="xs" customZIndex={2000}>
      <div className="modal_header">
        {!!region.id && <span className="modal_close icon-link -no-text -gray"
                              title="Close"
                              role="button"
                              onClick={closeForm}>
          <SvgIcon className="icon-link_icon" name="i-close"/>
          <span className="icon-link_text">Close</span>
        </span>}
        <h3 className="modal_title" id="clientLabel">Add New Region</h3>
      </div>
      <div className="modal_body">
        <div className="form-group -lg">
          <label className="form-label">Region's Name</label>
          <input className="form-field -full-width-standalone"
                 type="text"
                 value={name}
                 onChange={handleNameChange}/>
        </div>
      </div>
      <div className="modal_footer -new-region-standalone">
        <button className="button -square -danger -outline -pull-left" onClick={regionDelete}>
          <SvgIcon className="button_icon" name="i-trash-big"/>
        </button>

        <div className={cn("button-group -sm -pull-right", {'-no-cancel': !region.id})}>

          {!!region.id && <button className="button -submit -outline" onClick={closeForm}>
            Cancel
          </button>}
          {!region.id && <button className="button -submit" disabled={!name} onClick={regionAdd}>
            Add
          </button>}
          {!!region.id && <button className="button -submit" disabled={!name} onClick={regionUpdate}>
            Save
          </button>}
        </div>
      </div>
    </Modal>
  )
}

export default RegionsMapperEditor;