import React, { useContext } from "react";
import { map } from "lodash";
import { IndexData } from '../../../index-context';
import { averageCountWithFlag, n0 } from '../../../helpers';

const TrShelfNsizes = ({ checkedConcepts }) => {
  const { init, data, averageMode } = useContext(IndexData);
  const [ averageCountNum, hasValueNum ] = averageCountWithFlag(
    n0(data.shelf_avg_num), averageMode, init.isInternal, data.view
  );

  return (
    <>
      <tr className="shelf-comparison-nsizes">
        <td className="table-comparison_name-cell"><span>{ data.titles.n }</span></td>
        {
          map(checkedConcepts, (concept) => (
            <td key={ `shelf_list_${concept.id}` } className="-js-n">
              ({concept.shelf_counters.total_count})
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { hasValueNum && '(' }
          { averageCountNum }
          { hasValueNum && ')' }
        </td>
      </tr>
    </>
  );
};

export default TrShelfNsizes;
