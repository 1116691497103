import { filter, includes, find, invert } from 'lodash';

class Base {
  constructor(projectData, priceData, size) {
    this.size = size || priceData.recommended;
    this.projectData = projectData;
    this.priceData = priceData;
  }

  safeFloat(number) {
    return parseFloat(number.toFixed(10));
  }

  selectedCountry() {
    const countryObject = find(
      this.projectData.demographic_questions,
      (item) => (item.title === this.projectData.country_title)
    ) || {};
    const answerCondition = find(
      invert(countryObject.condition || []),
      (k, v) => v === 'is'
    );
    return answerCondition ? countryObject.answers[answerCondition] : this.projectData.country_default;
  }

  notDestroyedItems(list) {
    return filter(list, (item) => !item._destroy);
  }

  turf() {
    return includes(this.projectData.turf_modes, this.projectData.concepts_mode);
  }

  conceptSize(total) {
    if (!total) {
      return 0;
    }
    return Math.ceil(total / this.sizeCalcPart());
  }

  totalSize() {
    return this.size * this.sizeCalcPart();
  }

  sizeCalcPart() {
    console.log('Not implemented');
    return 0;
  }

  samplePrice() {
    return Math.ceil(
      this.safeFloat(
        (
          this.totalSize() *
          (this.priceData.cost_per_interview + this.priceData.cost_per_interview_margin) *
          (
            this.priceData.country_margin_mapping[this.selectedCountry()] ||
              this.priceData.country_margin_mapping['-1']
          )
        )
      )
    );
  }

  screenerQuestionsPrice() {
    const screenerCount = this.notDestroyedItems(this.projectData.screener_questions).length;
    if (!screenerCount) {
      return 0;
    }

    const tempPrice = this.priceData.screener_questions_price_base;
    if (screenerCount <= this.priceData.screener_questions_base_limit) {
      return tempPrice;
    }

    const additionalPrice = (
      (screenerCount - this.priceData.screener_questions_base_limit) *
      this.priceData.screening_questions_price_add
    );

    return tempPrice + additionalPrice;
  }

  turfPrice() {
    return (this.turf() ? this.priceData.price_for_turf : 0);
  }

  basePrice() {
    return (
      this.priceData.base_rate +
      this.samplePrice() +
      this.screenerQuestionsPrice() +
      this.turfPrice()
    );
  }

  totalPrice() {
    console.log('Not Implemented');
    return 0;
  }
}

export default Base;
