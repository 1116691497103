import React from "react";

const BonusTermsApply = () => {
  return (
    <div className="bonus-agreement">
      <div className="agreement_content">
        <p>
          The PayPal Rewards Program (the <span className="bolder bigger">“Rewards Program”</span>)
          { ' ' }
          is offered by PayPal, Inc. (<span className="bolder bigger">“PayPal”</span>)
          { ' ' }
          via the PayPal mobile app, PayPal.com, and the PayPal Honey mobile browser extension
          { ' ' }
          (collectively, the <span className="bolder bigger">“PayPal Service”</span>), and via
          { ' ' }
          the PayPal Honey mobile app,
          { ' ' }
          website (<span className="pseudo-link">www.joinhoney.com</span>), browser extension,
          { ' ' }
          and other surfaces operated by PayPal, Inc. under the PayPal Honey brand
          { ' ' }
          (<span className="bolder bigger">“Honey”</span>)
          { ' ' }
          (collectively, the <span className="bolder bigger">“Honey Service”</span>).
          { ' ' }
          This program agreement (<span className="bolder bigger">“Agreement”</span>) is a
          { ' ' }
          contract between you and PayPal and governs your access to and use of the Rewards
          { ' ' }
          Program. This Agreement is in addition to and supplements the other terms and
          { ' ' }
          conditions that govern your use of the PayPal Service and/or the Honey Service,
          { ' ' }
          as applicable.
        </p>
        <div className="bolder">
          PLEASE NOTE:
          <ul>
            <li>
              To receive cash back, you must redeem Points and have a PayPal account in good standing.
            </li>
            <li>
              Points can expire. To learn how to maintain your points, please refer to Section 5 (Expiration & Loss of Points; Program Termination) below.
            </li>
            <li>
              For Points earned through the Honey Service, your Honey account must be linked to your PayPal account to redeem for cash back.
            </li>
            <li>
              In addition to cash back, other Point redemption options are available, including the option to redeem Points towards eligible purchases when you use your PayPal account to check out online.
            </li>
            <li>
              You are not required to redeem Points for cash back. See Section 4 (Redeeming Points) for more information on Points redemption.
            </li>
            <li>
              Points are not money and have no value prior to redemption. Points can only be earned on eligible transactions.
            </li>
          </ul>
        </div>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="1.">
            Acceptance and Eligibility
          </li>
        </ol>
        <ul className="high-level">
          <li className="custom-style" data-list-style="a.">
            To participate in the Rewards Program via the PayPal Service, you must have a
            { ' ' }
            valid PayPal account in good standing. To participate in the Rewards Program via
            { ' ' }
            the Honey Service, you must have a valid Honey account in good standing.
          </li>
          <li className="custom-style" data-list-style="b.">
            You must be an individual, be a resident of the United States or one of its
            { ' ' }
            territories, and be at least 18 years old, or the age of majority in your state or
            { ' ' }
            jurisdiction of residence (if over 18), to participate in the Rewards Program.
          </li>
          <li className="custom-style" data-list-style="c.">
            The Rewards Program is not available in all countries or locations.
            { ' ' }
            You may not participate in the Rewards Program using multiple PayPal or Honey
            { ' ' }
            accounts to avoid or circumvent any prohibition of this Agreement or earn more
            { ' ' }
            Points than would otherwise be permitted under this Agreement. If we identify
            { ' ' }
            fraudulent activity in connection with your participation in the Rewards Program,
            { ' ' }
            or any other violation of this Agreement, PayPal reserves the right to suspend or
            { ' ' }
            terminate your ability to participate.
          </li>
        </ul>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="2.">
            Description of the Rewards Program
          </li>
        </ol>
        <ul className="high-level">
          <li className="custom-style" data-list-style="a.">
            The Rewards Program allows participants to earn Points on eligible transactions as
            { ' ' }
            described in more detail below. PayPal offers a variety of ways to participate
            { ' ' }
            in the Rewards Program across both the PayPal Service and Honey Service.
            { ' ' }
            Not all aspects of the Rewards Program will be available through all channels and
            { ' ' }
            not all offers will be the same across all channels.
          </li>
          <li className="custom-style" data-list-style="b.">
            PayPal does not charge fees to you for participation in the Rewards Program.
            { ' ' }
            You acknowledge that (i) the Rewards Program is a promotional program as to which no
            { ' ' }
            consideration has been paid to you, (ii) unless and until redeemed in accordance with
            { ' ' }
            this Agreement, no right, title, or interest in the Points has been conveyed, and (iii)
            { ' ' }
            Points have no cash value, unless and until redeemed in accordance with this Agreement.
          </li>
        </ul>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="3.">
            Earning Points
          </li>
        </ol>
        <ul className="high-level inner-padding">
          <li className="custom-style" data-list-style="a.">
            We may make our rewards points (
            <span className="bolder bigger">“PayPal Rewards Points”</span>
            { ' ' }
            or
            { ' ' }
            <span className="bolder bigger">“Points”</span>) available to you when you complete
            { ' ' }
            certain actions through the PayPal Service or the Honey Service. The most common
            { ' ' }
            way for you to earn Points is by making an eligible purchase from one of
            { ' ' }
            the participating third-party merchants.
          </li>
          <li className="custom-style" data-list-style="b.">
            Points are not available for all merchants or items. When Points are available for
            { ' ' }
            eligible purchases made from a particular merchant, we may indicate so on the PayPal
            { ' ' }
            Service and the Honey Service. If a merchant is participating in the Rewards Program,
            { ' ' }
            you can earn Points based on the eligible items that you purchase from that merchant.
          </li>
          <li className="custom-style" data-list-style="c.">
            To earn Points, you must follow the links to the applicable merchant within the
            { ' ' }
            PayPal Service or Honey Service, complete the eligible purchase, meet all other
            { ' ' }
            eligibility requirements, and comply with all related terms. To redeem a Rewards
            { ' ' }
            Program offer presented in a PayPal channel, you may first be required to add or save
            { ' ' }
            the offer to your account by clicking a button labeled “+”, “Save Offer”,
            { ' ' }
            “Add Offer”, “Activate Offer”, “Activate Rewards,” “Shop Now,” or other similar buttons
            { ' ' }
            made available where the offer is displayed. Eligible transactions must be completed
            { ' ' }
            to be recognized by PayPal.
          </li>
          <li className="custom-style" data-list-style="d.">
            Reward Program offers are subject to this Agreement and may be subject to additional
            { ' ' }
            terms, as specified at the time of the offer, including terms requiring you to pay
            { ' ' }
            with your PayPal account and/or PayPal Debit Card to earn Points. The number of Points
            { ' ' }
            that can be earned in any scenario will be displayed to you when you are
            { ' ' }
            presented with an offer.
          </li>
          <li className="custom-style" data-list-style="e.">
            We may display to you the amount or range of Points you may earn in connection with
            { ' ' }
            an offer in the following ways: (1) a fixed percentage reward rate (for example:
            { ' ' }
            3% in Points based on the subtotal of eligible items), (2) a percentage reward rate
            { ' ' }
            range (for example: 1-4% in Points based on the subtotal of eligible items), (3)
            { ' ' }
            a fixed number of Points (for example: 500 Points), or (4) a range of Points
            { ' ' }
            (for example: 300-500 Points). We may also display to you the cash back percentage
            { ' ' }
            or dollar amount, which represents the percentage of cash back or dollar amount you
            { ' ' }
            could receive in connection with completing an eligible transaction, if you elect to
            { ' ' }
            redeem the Points for cash back to your PayPal account. For an eligible transaction,
            { ' ' }
            the number of Points to be provided may be calculated based on the subtotal of
            { ' ' }
            eligible items purchased from a participating merchant, excluding tax or any additional
            { ' ' }
            fees such as shipping, or (where indicated) it may be provided as a flat sum regardless
            { ' ' }
            of the value of your purchase.
          </li>
          <li className="custom-style" data-list-style="f.">
            You are not required to redeem the Points earned for cash back, and you may elect
            { ' ' }
            to redeem such Points for any other redemption options offered as part of
            { ' ' }
            the Rewards Program.
          </li>
          <li className="custom-style" data-list-style="g.">
            In addition to earning Points on eligible purchases or items,
            { ' ' }
            you may also receive offers to earn Points by conducting other specified activities
            { ' ' }
            (e.g., completing a certain number of PayPal checkout transactions or using certain
            { ' ' }
            PayPal products), subject to the terms of any such offer.
          </li>
          <li className="custom-style" data-list-style="h.">
            Certain items, product categories, and purchases are ineligible for Points.
            { ' ' }
            Exclusions vary by merchant. We may not be able to determine if the item(s) you have
            { ' ' }
            purchased are eligible for Points or the number of Points that you will earn for that
            { ' ' }
            purchase until after you make your purchase.
          </li>
          <li className="custom-style" data-list-style="i.">
            Certain ad blockers may prevent you from earning PayPal Rewards Points. If we are
            { ' ' }
            unable to track your eligible transaction or other qualifying activity, or if the
            { ' ' }
            relevant third-party merchant does not fulfill its obligation, then you may not
            { ' ' }
            receive the Points associated with that transaction or activity.
          </li>
          <li className="custom-style" data-list-style="j.">
            Points will typically appear as pending in your Rewards Activity within 1 to 14 days
            { ' ' }
            of completing your qualifying purchase or incentivized activity, although this may vary
            { ' ' }
            based on the transaction and merchant. Points may remain pending for up to 90 days
            { ' ' }
            while we verify that you have met the requirements to receive the Points. In some cases,
            { ' ' }
            Points may remain pending for longer periods (for example, with travel-related
            { ' ' }
            purchases, Points will not be confirmed or available for redemption until after travel
            { ' ' }
            has been completed). Points that are pending cannot be redeemed. You will be able to
            { ' ' }
            see in your Rewards dashboard when Points are no longer pending and become
            { ' ' }
            available to redeem.
          </li>
          <li className="custom-style" data-list-style="k.">
            You will not earn Points if you fail to complete all steps required for an offer or
            { ' ' }
            incentivized activity. You will not earn Points for any items that you return or for
            { ' ' }
            which you receive a full refund. If all or part of your purchase is ineligible for
            { ' ' }
            Points, or if you return all or part of your purchase or receive a refund on it, the
            { ' ' }
            number of Points you receive on your purchase, regardless of "pending" status, will be
            { ' ' }
            reduced accordingly based on the new subtotal of eligible items. For purposes of
            { ' ' }
            clarity and without limiting the foregoing, PayPal may remove Points previously made
            { ' ' }
            available to you and/or cancel any awards for which those Points were redeemed if you
            { ' ' }
            return all or part of your underlying purchase or receive a refund on it (either
            { ' ' }
            through the merchant or via chargeback), or if the Points were issued as a result of
            { ' ' }
            fraudulent activity, or activity otherwise in violation of this Agreement. If you make
            { ' ' }
            a purchase that does not include eligible items (or does not otherwise qualify for any
            { ' ' }
            incentive), you will not earn any Points on that transaction.
          </li>
          <li className="custom-style" data-list-style="l.">
            Points cannot be purchased, are not your property, are not money, can expire as
            { ' ' }
            described in this Agreement, and have no value prior to redemption. Upon redemption,
            { ' ' }
            additional terms may apply. Points are not transferrable (except where PayPal
            { ' ' }
            expressly makes transfer or account linking functionality available within the Rewards
            { ' ' }
            Program, subject to any additional terms or limitations set out by PayPal). Without
            { ' ' }
            limiting the foregoing, Points are not descendible and may not be transferred by
            { ' ' }
            operation of law (including via inheritance, bankruptcy, or in the event of divorce).
          </li>
          <li className="custom-style" data-list-style="m.">
            For support in connection with the Rewards Program, please contact us at
            { ' ' }
            <span className="pseudo-link">paypalrewardssupport@paypal.com</span>. If you contact
            { ' ' }
            us regarding any issues receiving or redeeming Points, you may be required to provide
            { ' ' }
            us with related documentation (e.g., a receipt showing an eligible transaction).
          </li>
        </ul>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="4.">
            Redeeming Points
          </li>
        </ol>
        <p>
          Provided your PayPal account is in good standing, you can redeem Points as described
          { ' ' }
          below. Available redemption methods (and, where applicable, the number of Points required
          { ' ' }
          for each redemption method) will be as set forth on the PayPal Service or Honey Service,
          { ' ' }
          as applicable. There may be a minimum redemption threshold that will be disclosed to you
          { ' ' }
          at the time of redemption. Redemption types and values may vary, may be subject to
          { ' ' }
          availability or limitations, and may be subject to additional redemption-specific terms.
          { ' ' }
          PayPal reserves the right to change the number of Points required, Point redemption-rate,
          { ' ' }
          or availability of any redemption option. All redemptions are final. Unless otherwise
          { ' ' }
          expressly indicated below, the current Point redemption rate is one cent per Point
          { ' ' }
          redeemed (e.g., 100 Points can be redeemed for $1 cash back).
        </p>
        <p>
          Benefits you receive through the redemption of Points may qualify as reportable
          { ' ' }
          by PayPal and taxable income to you under applicable law. You may be asked to provide
          { ' ' }
          certain identifying information (for example, your Social Security Number or Taxpayer
          { ' ' }
          Identification Number) for tax purposes. You are solely responsible for any tax
          { ' ' }
          liability incurred in connection with your participation in the Rewards Program.
        </p>
        <ul className="high-level">
          <li className="custom-style" data-list-style="a.">
            Redemption for Cash Back
          </li>
        </ul>
        <ul className="inner-level inner-padding">
          <li className="custom-style" data-list-style="i.">
            Points may be redeemed for cash back. This redemption option requires you to have a
            { ' ' }
            valid personal PayPal account that is active and in good standing and has your bank
            { ' ' }
            account or debit card linked to it.
          </li>
          <li className="custom-style" data-list-style="ii.">
            After you have elected to redeem Points for cash back, it can take up to 24 hours for
            { ' ' }
            the funds to be sent to your PayPal account. After that, to cash out your funds, you
            { ' ' }
            can transfer them to the linked debit card or bank account. Or, if you have a
            { ' ' }
            PayPal Balance account, you can hold it as balance for future use. This redemption
            { ' ' }
            option is subject to additional terms, as specified in the
            { ' ' }
            <span className="pseudo-link">PayPal Balance Terms and Conditions</span>.
          </li>
          <li className="custom-style" data-list-style="iii.">
            If you redeem your Points for cash back and your PayPal account has a negative balance,
            { ' ' }
            the cash received into your account from the Points redemption will be used to offset
            { ' ' }
            the negative balance.
          </li>
        </ul>
        <ul className="high-level">
          <li className="custom-style" data-list-style="b.">
            Redemption for Cash Back into PayPal Savings
          </li>
        </ul>
        <ul className="inner-level inner-padding">
          <li className="custom-style" data-list-style="i.">
            If you have a valid PayPal Balance account and PayPal Savings account, and both are
            { ' ' }
            active and in good standing, you can choose to redeem Points by converting them to
            { ' ' }
            cash back (subject to the terms outlined in the Redemption for Cash Back section above)
            { ' ' }
            to be deposited into your PayPal Savings account.
          </li>
          <li className="custom-style" data-list-style="ii.">
            When you redeem Points for cash back into your PayPal Savings account, all transfers go
            { ' ' }
            through your PayPal Balance account first. If your balance is below $0, we’ll use your
            { ' ' }
            transfer to cover that amount first and the rest will go into your PayPal Savings
            { ' ' }
            account. If it’s after 5 PM ET, you’ll start earning interest on your transfer
            { ' ' }
            the next day.
          </li>
          <li className="custom-style" data-list-style="iii.">
            Redemption of Points for cash back into your PayPal Savings account is only available
            { ' ' }
            to customers that have an existing PayPal Savings account. If you would like to use
            { ' ' }
            this redemption option, you must first apply for and be approved for a PayPal
            { ' ' }
            Savings account.
          </li>
          <li className="custom-style" data-list-style="iv.">
            Any cash deposits you make that are redemptions of Points for cash back into your PayPal
            { ' ' }
            Savings account are subject to the terms of your
            { ' ' }
            <span className="pseudo-link">Synchrony Bank Deposit Account Agreement</span>.
          </li>
          <li className="custom-style" data-list-style="v.">
            PayPal is a financial technology company, not a bank. PayPal Savings is provided by
            { ' ' }
            Synchrony Bank, Member FDIC. Money in PayPal Savings is held at Synchrony Bank.
          </li>
        </ul>
        <ul className="high-level">
          <li className="custom-style" data-list-style="c.">
            Redemption at Checkout
          </li>
        </ul>
        <ul className="inner-level inner-padding">
          <li className="custom-style" data-list-style="i.">
            You may be offered the option to redeem Points during the checkout process when paying
            { ' ' }
            for a purchase using your PayPal account. Where available, you may redeem Points
            { ' ' }
            towards the total price of an eligible purchase. Unless otherwise specified by PayPal
            { ' ' }
            during the checkout process, Points can be redeemed in any denomination of whole points.
            { ' ' }
            Upon redemption, the points will be converted to cash to fund the purchase. Redemption
            { ' ' }
            of Points at checkout can only be used for eligible purchases from U.S. merchants in
            { ' ' }
            U.S. Dollars up to the total purchase price.
          </li>
          <li className="custom-style" data-list-style="ii.">
            When redeeming Points to fund the entire purchase price, you will be required to provide
            { ' ' }
            a back-up funding source to complete the transaction. Your back-up funding source may
            { ' ' }
            be charged if there is a payment dispute, if your final charge with the merchant
            { ' ' }
            exceeds the value of Points redeemed (e.g., due to a variable cost, such as shipping
            { ' ' }
            fees or gratuity), or in certain other circumstances.
          </li>
          <li className="custom-style" data-list-style="iii.">
            Redemption at checkout is not available at all merchants where PayPal is available or
            { ' ' }
            on all transactions paid for using PayPal.
          </li>
          <li className="custom-style" data-list-style="iv.">
            Not all payment sources may be combined with at-checkout redemption of Points to fund
            { ' ' }
            an eligible purchase. At the time of checkout, PayPal will display the available
            { ' ' }
            funding sources that are eligible for the given transaction.
          </li>
          <li className="custom-style" data-list-style="v.">
            If you receive a full refund for (or there is a reversal on) a transaction in which
            { ' ' }
            you redeemed Points at checkout, your Points balance will be credited in an amount
            { ' ' }
            equal to the Points you originally redeemed on such purchase. If you receive a partial
            { ' ' }
            refund for any transaction you paid for using both at checkout redemption of Points
            { ' ' }
            and another payment method, the other method of payment will be credited first in
            { ' ' }
            applying the refunded amount to you, up to the total amount of the original transaction
            { ' ' }
            paid for using that payment method, after which any remainder will be credited to your
            { ' ' }
            Points balance.
          </li>
        </ul>
        <ul className="high-level">
          <li className="custom-style" data-list-style="d.">
            Redemption for Gift Cards
          </li>
        </ul>
        <ul className="inner-level">
          <li className="custom-style" data-list-style=" ">
            On the Honey Service, points can be redeemed for third-party merchant gift cards. The
            { ' ' }
            selection of merchant gift cards may vary based on availability. There may be a minimum
            { ' ' }
            Point redemption threshold for gift cards that will be disclosed to you at the time of
            { ' ' }
            redemption. Use of a third-party merchant gift card at a selected merchant will be
            { ' ' }
            subject to such merchant's and/or the gift card issuer's own terms of service.
          </li>
        </ul>
        <ul className="high-level">
          <li className="custom-style" data-list-style="e.">
            Redemption for Donation to PayPal Giving Fund
          </li>
        </ul>
        <ul className="inner-level">
          <li className="custom-style" data-list-style="i.">
            You can redeem Points for a donation to PayPal Giving Fund, a registered 501(c)(3)
            { ' ' }
            charity. After you have elected to redeem Points for a donation to PayPal Giving Fund,
            { ' ' }
            it can take up to 24 hours for the Points to be deducted from your Points balance. The
            { ' ' }
            current redemption value for this redemption option is one cent for each 0.8 Point
            { ' ' }
            (e.g., 80 Points can be redeemed for a $1 donation to PayPal Giving Fund). This
            { ' ' }
            redemption option, and the resulting donation, is subject to PayPal Giving Fund’s
            { ' ' }
            privacy statement and <span className="pseudo-link">donor terms of service</span>.
          </li>
          <li className="custom-style" data-list-style="ii.">
            A donation made with redeemed Points is not tax-deductible. You are responsible for
            { ' ' }
            compliance with all applicable tax requirements. While PayPal Giving Fund will make
            { ' ' }
            every effort to grant donated funds to your recommended charity, PayPal Giving Fund
            { ' ' }
            retains exclusive legal control over all donations. If a charity fails to meet the
            { ' ' }
            terms of PayPal Giving Fund’s
            { ' ' }
            <span className="pseudo-link">Nonprofit Certification Policy</span> and/or fails to meet
            { ' ' }
            PayPal Giving Fund's due diligence standards for receipt of a donation, PayPal Giving
            { ' ' }
            Fund reserves the right to reassign funds to another charitable organization. PayPal
            { ' ' }
            Giving Fund may also reassign donations in the event it is unable to distribute funds
            { ' ' }
            successfully (e.g., the charity fails to cash the check(s) sent by PayPal Giving Fund).
          </li>
        </ul>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="5.">
            Expiration & Loss of Points; Program Termination
          </li>
        </ol>
        <ul className="high-level">
          <li className="custom-style" data-list-style="a.">
            Points are subject to expiration but can be maintained as described below. To maintain
            { ' ' }
            Points earned with your PayPal or Honey account and prevent them from expiring, your
            { ' ' }
            PayPal and/or Honey account (as applicable) must remain open and in good standing
            { ' ' }
            (i.e., not in violation of applicable terms or subject to any limitation or suspension)
            { ' ' }
            and be active. If you fail to maintain Points earned as described below,
            { ' ' }
            they will expire.
          </li>
          <li className="custom-style" data-list-style="b.">
            Your PayPal account will be considered active, and you can prevent the expiration of
            { ' ' }
            Points earned with your PayPal account, by:
          </li>
        </ul>
        <ul className="inner-level">
          <li className="custom-style" data-list-style="i.">
            Earning at least 10 Points using your PayPal account within each consecutive 365-day
            { ' ' }
            period (for example, from April 10th of this year to April 10th of next year); or
          </li>
          <li className="custom-style" data-list-style="ii.">
            Completing at least 3 eligible PayPal account transactions within the consecutive
            { ' ' }
            365-day period. The following are considered eligible PayPal account transactions:
          </li>
        </ul>
        <ul className="more-inner-level inner-padding">
          <li className="custom-style" data-list-style="A.">
            Completing a PayPal Checkout transaction using your PayPal account;
          </li>
          <li className="custom-style" data-list-style="B.">
            Sending or receiving money using the Send Money feature;
          </li>
          <li className="custom-style" data-list-style="C.">
            Adding funds to your PayPal Balance account or transferring funds from your PayPal
            { ' ' }
            Balance account to a linked bank account or debit card;
          </li>
          <li className="custom-style" data-list-style="D.">
            Adding funds to a PayPal Savings account; or
          </li>
          <li className="custom-style" data-list-style="E.">
            Making a purchase using your PayPal Debit Card.
          </li>
        </ul>
        <p>
          If you fund your PayPal Checkout transaction or Send Money transaction with your PayPal
          { ' ' }
          Balance, it will be counted as a single eligible PayPal account transaction.
        </p>
        <ul className="high-level">
          <li className="custom-style" data-list-style="c.">
            Your Honey account will be considered active, and you can prevent the expiration of
            { ' ' }
            Points earned with your Honey account, by earning at least 10 Points using your Honey
            { ' ' }
            account within each consecutive 365-day period. If you fail to do so, Points earned
            { ' ' }
            with your Honey account will expire.
          </li>
          <li className="custom-style" data-list-style="d.">
            You have the option to link your Honey account and your PayPal account. If you link
            { ' ' }
            your accounts, and if you have met the requirements for maintaining Points
            { ' ' }
            earned with one of your linked accounts for a given period, any Points earned with
            { ' ' }
            your other linked account will be maintained for the relevant period as well, even
            { ' ' }
            if you have not separately met the requirements for maintaining Points in that account.
            { ' ' }
            Both linked accounts, however, must remain open and in good standing to prevent
            { ' ' }
            expiration of Points. See Section 7 (Account Linking) below for more information on
            { ' ' }
            linking your accounts.
          </li>
          <li className="custom-style" data-list-style="e.">
            If your PayPal account is closed or no longer in good standing, PayPal has the right
            { ' ' }
            (to the maximum extent permitted by applicable law) to reset your Points earned with
            { ' ' }
            that account to zero (0), in which case you will no longer have any Points to redeem.
            { ' ' }
            If your PayPal account is closed, any unredeemed Points earned via the PayPal Service
            { ' ' }
            will no longer be redeemable. If your Honey account remains open, any unredeemed points
            { ' ' }
            earned via the Honey Service will continue to be eligible for redemption through the
            { ' ' }
            Honey Service. If your Honey account and your PayPal account are linked, and you close
            { ' ' }
            your Honey account (but not your PayPal account), all Points earned on the Honey Service
            { ' ' }
            and the PayPal Service will continue to be eligible for redemption through the
            { ' ' }
            PayPal Service.
          </li>
          <li className="custom-style" data-list-style="f.">
            PayPal reserves the right to suspend or terminate your participation in the PayPal
            { ' ' }
            Rewards Program or your PayPal account entirely if PayPal suspects that you have abused
            { ' ' }
            any PayPal Rewards Program benefits or privileges, or have violated
            { ' ' }
            (or acted inconsistently with) this Agreement or applicable law. Abuse or violations
            { ' ' }
            that may result in suspension or termination include (but are not limited to): (i)
            { ' ' }
            using automated or fraudulent means (including artificially manufacturing
            { ' ' }
            transactions/spend) to earn Points or otherwise participate in this program;
            { ' ' }
            (ii) failure to abide by the requirements of the Agreement; (iii) the attempted or
            { ' ' }
            actual purchase, sale, or transfer of any Points; and (iv) making a misrepresentation
            { ' ' }
            to PayPal in connection with your participation in the Rewards Program. You understand
            { ' ' }
            and agree that termination of your participation in the Rewards Program (and/or your
            { ' ' }
            PayPal account) will result in the immediate forfeiture of all Points and unused
            { ' ' }
            Rewards Program awards associated with that account.
          </li>
          <li className="custom-style" data-list-style="g.">
            PayPal reserves the right to modify or terminate the Reward Program. In the event
            { ' ' }
            PayPal elects to terminate the Rewards Program, we will provide a notice of
            { ' ' }
            termination on the PayPal Service and/or the Honey Service, or by email to the email
            { ' ' }
            address then associated with your account at least 21 days (or such longer period as
            { ' ' }
            may be required by law) prior to termination during which time you may continue to
            { ' ' }
            redeem accumulated Points. The transfer of participants to a successor loyalty program,
            { ' ' }
            where such participants do not forfeit all unexpired, unredeemed Points in their account
            { ' ' }
            as a result, will not be considered a termination of the Rewards Program for purposes of
            { ' ' }
            this provision. In addition, this provision will not apply if PayPal elects, in its sole
            { ' ' }
            discretion, upon termination of the Rewards Program, to automatically credit to each
            { ' ' }
            program participant’s PayPal account a pro rata credit amount equal to the cash back
            { ' ' }
            redemption value of the participant’s unredeemed Points.
          </li>
        </ul>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="6.">
            Rewards Offers from Third Party Merchants
          </li>
        </ol>
        <p>
          As part of the PayPal Rewards Program, Rewards offers on third-party merchant goods or
          { ' ' }
          services may be displayed in various PayPal and/or Honey channels (including,
          { ' ' }
          without limitation, PayPal or Honey mobile apps, websites, browser extensions, or
          { ' ' }
          communications). Any pricing shown is determined by the merchant and is subject to
          { ' ' }
          change at any time. The price you ultimately pay may differ from the price shown in a
          { ' ' }
          PayPal or Honey channel due to changes in merchant pricing and/or the addition of other
          { ' ' }
          costs (for example, taxes and shipping fees). Items displayed in PayPal or Honey channels
          { ' ' }
          may no longer be available at the time you attempt to make a purchase. You agree that
          { ' ' }
          PayPal is in no way responsible or liable to you to the extent pricing displayed in PayPal
          { ' ' }
          or Honey channels differs from the actual price displayed on a merchant site, or to the
          { ' ' }
          extent displayed items are no longer available for purchase.
        </p>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="7.">
            Account Linking
          </li>
        </ol>
        <p>
          You can link your PayPal account and your Honey account to earn Points across both the
          { ' ' }
          PayPal Service and the Honey Service. To link accounts, you will need to visit a Honey
          { ' ' }
          Service site and choose the log-in with PayPal option. Please note your Honey and PayPal
          { ' ' }
          accounts must have a matching, verified email address in order to be linked. Once linked,
          { ' ' }
          Points from both accounts will be combined into one cumulative point balance, although
          { ' ' }
          redemption options may differ depending on whether you choose to redeem your Points
          { ' ' }
          via the PayPal Service or the Honey Service. See Section 4 (Redeeming Points) above
          { ' ' }
          for more information on redemption options. If you create your Honey account using your
          { ' ' }
          PayPal account login, your Points balances will be combined automatically. Once linked,
          { ' ' }
          the accounts cannot be unlinked.
        </p>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="8.">
            Amendments to this Agreement
          </li>
        </ol>
        <p>
          PayPal may amend this Agreement. Any changes to the Agreement will be posted on PayPal’s
          { ' ' }
          website, and the posted Agreement will indicate the date the Agreement was last revised.
          { ' ' }
          The revised Agreement will take effect when posted or as otherwise expressly indicated.
          { ' ' }
          If you do not agree to the changes, you must stop participating in the Rewards Program
          { ' ' }
          or using any benefits of the Rewards Program. By continuing to use the Rewards Program
          { ' ' }
          after any changes to this Agreement take effect or taking such other action as specified
          { ' ' }
          by PayPal, you agree to be bound by those changes.
        </p>
        <ol className="high-level">
          <li className="custom-style bigger" data-list-style="9.">
            Assignment
          </li>
        </ol>
        <p>
          PayPal may assign, without recourse, any or all of its rights or obligations under
          { ' ' }
          this Agreement, including all or a portion of this Agreement and/or all or a portion of
          { ' ' }
          any liabilities related to Points. If PayPal assigns any or all of the liabilities
          { ' ' }
          related to Points, the assignee shall be the sole obligor with respect to such Points,
          { ' ' }
          and PayPal shall have no further liability with respect to such Points.
        </p>
      </div>
    </div>
  );
};

export default BonusTermsApply;
