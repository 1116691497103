import React, { useContext } from 'react';
import classnames from "classnames";
import { IndexData } from "../../index-context";
import { comparisonCompareToDatabaseClick } from "../../../../common/google-analytics/events";

const TabButtons = ({ statTestingValue, onStatTestingUpdate, quintileDisabled }) => {
  const { init } = useContext(IndexData);
  const onChange = (mode) => {
    comparisonCompareToDatabaseClick(init.google_analytics_id, { project_id: init.project.id });
    onStatTestingUpdate(mode);
  };

  return (
    <div className="tab-panel__header">
      <span
        className={ classnames("tab", { "tab--selected": statTestingValue !== "quintiles" }) }
        onClick={ () => { onStatTestingUpdate("average");} }
      >
        <span className="tab-label">Stat testing</span>
      </span>
      <span
        className={ classnames("tab", { "tab--selected": statTestingValue === "quintiles" }, { "-disabled": quintileDisabled }) }
        onClick={ () => { onChange("quintiles");} }
      >
        <span className={ classnames("tab-label") }>Compare to database</span>
      </span>
    </div>
  );
};

export default TabButtons;
