import React from 'react';

const Header = ({ title, description = null }) => {
  return (
    <>
      <div className="content_top -sticky -gray">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item -grow">
              <h1 className="header_title">
                {title}
              </h1>
            </div>
          </div>
        </header>
      </div>
      {description &&
        <div className="content_description">
          {description}
        </div>}
    </>
  );
};

export default Header;
