import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import ZoomImage from './zoom_image';
import StartButton from './start_button.js';

const Intro = ({ name, text, nextStep, imageUrl  }) => (
  <div className="survey-layout_content -start">
    <div className="survey-start -naming">
      {
        imageUrl &&
        <ZoomImage
          className="survey-start-hero-img -no-margin"
          src={ imageUrl }
        >
          <img src={ imageUrl } alt={ name } />
        </ZoomImage>
      }
      <h3 className="survey-name-label -big" { ...renderRawHtml(name) } />
      <div className="survey-start_description" { ...renderRawHtml(text) } />
      <StartButton nextStep={ () => { nextStep(); } } />
    </div>
  </div>
);

export default Intro;
