import React, { useContext } from "react";
import { showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { IndexData } from '../../../index-context';

const ShelfEvaluationRanking = ({ current }) => {
  const { init, data, averageMode } = useContext(IndexData);

  const info = data.ranking[current.id];

  const hashTop3 = data.analytics[data.titles.rank_top3];
  const hash1 = data.analytics[data.titles.rank1];
  const hash2 = data.analytics[data.titles.rank2];
  const hash3 = data.analytics[data.titles.rank3];

  const pTop3Text = showedAverageValue(hashTop3, averageMode, false, init.isInternal, data.view);
  const p1Text = showedAverageValue(hash1, averageMode, false, init.isInternal, data.view);
  const p2Text = showedAverageValue(hash2, averageMode, false, init.isInternal, data.view);
  const p3Text = showedAverageValue(hash3, averageMode, false, init.isInternal, data.view);

  return (
    <>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { data.titles.rank_top3 }
        </td>
        <td className="-js-n">
          {info.has_ranking && <StatusCircle values={ hashTop3[current.id] } box="top2" noLetters />}
          {!info.has_ranking && <span>-</span>}
        </td>
        <td className="table-comparison_average-cell">
          { pTop3Text }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { data.titles.rank1 }
        </td>
        <td className="-js-n">
          {info.has_ranking && <StatusCircle values={ hash1[current.id] } box="top2" noLetters />}
          {!info.has_ranking && <span>-</span>}
        </td>
        <td className="table-comparison_average-cell">
          { p1Text }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { data.titles.rank2 }
        </td>
        <td className="-js-n">
          {info.has_ranking && <StatusCircle values={ hash2[current.id] } box="top2" noLetters />}
          {!info.has_ranking && <span>-</span>}
        </td>
        <td className="table-comparison_average-cell">
          { p2Text }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { data.titles.rank3 }
        </td>
        <td className="-js-n">
          {info.has_ranking && <StatusCircle values={ hash3[current.id] } box="top2" noLetters />}
          {!info.has_ranking && <span>-</span>}
        </td>
        <td className="table-comparison_average-cell">
          { p3Text }
        </td>
      </tr>
    </>
  );
};

export default ShelfEvaluationRanking;
