import React, { useContext, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { each, filter, findIndex, fromPairs, map, toPairs } from "lodash";
import cn from "classnames";
import SvgIcon from "../../../../../common/components/svg-icon";
import { QuestionContext } from "../contexts";
import Image from "../../../common/custom_questions/components/Image";
import AdditionalAnswerLine from './lines/AdditionalAnswerLine';

const QAList = ({ options, question, questionIndex, questions, setQuestions }) => {
  const {
    setManageFivePtToggle, setFivePtScaleMode, images, fivePtScaleMode
  } = useContext(QuestionContext);

  const [ , setFocusLastOnEnter ] = useState(false);
  const getImageData = (key) => (question.answer_images?.[key] !== undefined ? images?.[key] : null);
  const answersToGridItems = () => (
    map(
      toPairs(question.answers),
      ([ key, question ]) => (
        {
          id: key,
          question,
          editable: true,
          imageData: getImageData(key)?.meta_data,
          imageSrc: getImageData(key)?.url
        }
      )
    )
  );
  const [ gridItems, setGridItems ] = useState(answersToGridItems);
  const refs = useRef([]);

  const addGridItem = () => {
    const key = question.answers.length - 1;
    gridItems.push({
      id: key,
      question,
      editable: true,
      imageData: getImageData(key)?.meta_data,
      imageSrc: getImageData(key)?.url
    });
    setGridItems([ ...gridItems ]);
  };

  const handleChangeAnswersImages = (q, imageData) => {
    q.answer_images = imageData;
    setQuestions([ ...questions ]);
  };

  const gridItemsToAnswersImages = (inputData) => {
    const data = inputData || gridItems;
    return fromPairs(
      map(
        filter(data, (el) => el.imageData),
        (el) => ([ el.id, { meta_data: el.imageData, url: el.imageSrc } ])
      )
    );
  };

  const handleOnDragEnd = (result) => {
    const from = result.source.index,
      to = result.destination.index;

    each(question.condition_list, (el) => {
      const elIndex = parseInt(el.index);
      if (elIndex === from) {
        el.index = to;
      }
      if (from < to &&  from < elIndex  && elIndex <= to) {
        el.index = elIndex - 1;
      }
      if (from > to &&  to <= elIndex  && elIndex < from) {
        el.index = elIndex + 1;
      }
    });

    const items = Array.from(question.answers);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    question.answers = items;

    const grids = Array.from(gridItems);
    const [ reorderedGridItem ] = grids.splice(result.source.index, 1);
    grids.splice(result.destination.index, 0, reorderedGridItem);
    each(grids, (item, index) => (item.id = index));
    setGridItems([ ...grids ]);
    handleChangeAnswersImages(question, gridItemsToAnswersImages(grids));

    setQuestions([ ...questions ]);
  };

  const onChangeAnswer = (event, index) => {
    question.answers[index] = event.target.value;
    setQuestions([ ...questions ]);
  };

  const delAnswer = (event, answer, index) => {
    event.preventDefault();

    if (question.answers.length <= 1) {
      return;
    }

    question.answers.splice(index, 1);

    gridItems.splice(index, 1);
    each(gridItems, (el, i) => {
      el.id = i.toString();
    });
    setGridItems([ ...gridItems ]);

    const conditionIdx = findIndex(question.condition_list, (c) => {
      return c.index === index;
    });
    if (conditionIdx > -1) {
      question.condition_list.splice(conditionIdx, 1);
    }

    const mustSelectList = filter(question.condition_list, (item) => item.cond === 'is');
    const mustSelectListLength = mustSelectList.length;

    if (question.multi_condition >= mustSelectListLength && mustSelectListLength > 1) {
      question.multi_condition = mustSelectListLength - 1;
    }

    updateFivePtToggleStates();
    handleChangeAnswersImages(question, gridItemsToAnswersImages());
    setQuestions([ ...questions ]);
  };

  const addAnswer = (event) => {
    event.preventDefault();
    question.answers.push('');
    addGridItem();
    updateFivePtToggleStates();
    setQuestions([ ...questions ]);
  };

  const extractData = (event) => {
    const text = event.clipboardData.getData('text/plain');
    const lines = text.replace(/\r/g, '').replace(/\n$/, '').split("\n");
    if (lines.length === 1 && lines[0].indexOf("\t") === -1) {
      return null;
    }
    event.preventDefault();
    return lines;
  };

  const handlePaste = (event, fromIndex) => {
    const lines = extractData(event);
    if (lines != null) {
      each(lines, (line, li) => {
        const correctedLi = li + fromIndex;
        question.answers[correctedLi] = line;
      });
    }
    setQuestions([ ...questions ]);
  };

  const answerChangeRef = (item, index) => {
    if (item) {
      refs.current[index] = item;
    }

    if (!item || question.answers.length - 1 !== index) {
      return;
    }
    setFocusLastOnEnter(
      (focusLastRequest) => {
        if (focusLastRequest) {
          item.focus();
        }
        return false;
      }
    );
  };

  const handleKeyPress = (event, index) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (question.answers.length - 1 === index) {
        question.answers.push('');
        addGridItem();
        updateFivePtToggleStates();
        setQuestions([ ...questions ]);
        setFocusLastOnEnter(true);
      } else {
        refs.current[index + 1].focus();
      }
    }
  };

  const updateFivePtToggleStates = () => {
    const toggleEnableCondition = question.answers.length >= 5 && question.kind === 'Single';
    setManageFivePtToggle(toggleEnableCondition);
    if (question.answers.length < 5) {
      setFivePtScaleMode(false);
      question.as_point_scale = false;
      setQuestions([ ...questions ]);
    }
  };

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <Droppable droppableId="answers-area">
        {
          (provided) => (
            <ul
              className="form-question_answers"
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              {
                map(question.answers, (answer, index) => (
                  <Draggable
                    key={ index }
                    draggableId={ `x${questionIndex}-${index}` }
                    index={ index }
                    isDragDisabled={ question.answers_order !== 'specific' }
                  >
                    {
                      (provided) => (
                        <li
                          id={ `x${questionIndex}-${index}` }
                          { ...provided.draggableProps }
                          ref={ provided.innerRef }
                          className="form-question_answer"
                        >
                          <div className="form-question_grid">
                            <div
                              className={
                                cn(
                                  "form-question_grid-item -first-answer-item",
                                  { '-transparent': question.answers_order !== 'specific' }
                                )
                              }
                            >
                              <div
                                className="form-question_answer-move"
                                { ...provided.dragHandleProps }
                              >
                                <SvgIcon name="i-move" />
                              </div>
                            </div>

                            {
                              <Image
                                question={ question }
                                index={ index }
                                gridItems={ gridItems }
                                setGridItems={ setGridItems }
                                screenerQuestions={ questions }
                                setScreenerQuestions={ setQuestions }
                                fivePtScaleMode={ fivePtScaleMode }
                              />
                            }

                            <div
                              className={
                                cn(
                                  "form-question_grid-item -grow",
                                  { 'has-error': question?.errors?.answers }
                                )
                              }
                            >
                              <input
                                ref={ (item) => answerChangeRef(item, index) }
                                className="form-field -block"
                                type="text"
                                value={ question.answers[index] }
                                onChange={ (event) => {
                                  onChangeAnswer(event, index);
                                } }
                                onPaste={ (event) => handlePaste(event, index) }
                                onKeyDown={ (event) => handleKeyPress(event, index) }
                                placeholder="Add Answer Text"
                              />
                            </div>

                            <div className="form-question_grid-item -buttons">
                              <button
                                type="button"
                                className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                onClick={ ($event) => {
                                  delAnswer($event, answer, index);
                                } }
                                disabled={ question.answers.length === 1 }
                              >
                                <SvgIcon name="i-delete" />
                              </button>

                              {
                                question.answers.length - 1 === index &&
                                <button
                                  type="button"
                                  className="button -circle form-question_grid-button"
                                  onClick={ ($event) => {
                                    addAnswer($event);
                                  } }
                                >
                                  <SvgIcon name="i-add" />
                                </button>
                              }
                            </div>
                          </div>
                        </li>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}

              <AdditionalAnswerLine
                fieldBoolName={ 'other_answer' }
                fieldTextName={ 'other_answer_text' }
                fieldOptionsName={ 'other_specify' }
                question={ question }
                questionIndex={ questionIndex }
                options={ options }
                screenerQuestions={ questions }
                setScreenerQuestions={ setQuestions }
              />
              <AdditionalAnswerLine
                fieldBoolName={ 'none_answer' }
                fieldTextName={ 'none_answer_text' }
                fieldOptionsName={ 'none_of_the_above' }
                question={ question }
                questionIndex={ questionIndex }
                options={ options }
                screenerQuestions={ questions }
                setScreenerQuestions={ setQuestions }
              />
            </ul>
          )
        }
      </Droppable>
    </DragDropContext>
  );
};


export default QAList;
