import React, { useState } from "react";
import cn from "classnames";
import InfoToolTip from "../../common/infoToolTip";

const CheckBox = ({ initValue, onChange, className, inputId, text, infoText, img }) => {
  const [ value, setValue ] = useState(initValue);

  const handleOnChange = (event) => {
    setValue(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <div className={ cn("form_metrics-item", className) }>
      <div className="checkbox">
        <input
          type="checkbox"
          id={ inputId }
          checked={ value }
          onChange={ handleOnChange }
        />
        <label className="checkbox_label" htmlFor={ inputId }>{text}</label>
      </div>

      <InfoToolTip text={ infoText } img={ img } imgClassName="-bigger" position="bottom-right" />
    </div>
  );
};

export default CheckBox;
