import React, { useContext, useRef, useEffect } from 'react';
import { map } from "lodash";
import classnames from 'classnames';
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from '../../index-context';
import TopFilter from "../../../common/filters/app";
import HeaderLinks from "../../../common/components/header-links";
import renderRawHtml from '../../../../../common/render_raw_html';
import CombinationRow from "./combination-row";

const TurfModal = ({ onClose }) => {
  const {
    init, data, loadData, loading,
    getTourUseEffectArgs, tourData,
    turfData, loadTurfData, turfLoadingError
  } = useContext(IndexData);

  useEffect(() => {
    loadTurfData(init.project.id, null, null, null, null);
  }, []);

  const combinationsSelectElement = useRef(null);
  const tableElement = useRef(null);

  const [ combinationsCallback, combinationsCondition ] = getTourUseEffectArgs(
    'projectTURFModalCombinationsDropdown',
    combinationsSelectElement
  );
  useEffect(combinationsCallback, combinationsCondition);

  const [ tableCallback, tableCondition ] = getTourUseEffectArgs(
    'projectTURFModalTable',
    tableElement
  );
  useEffect(tableCallback, tableCondition);

  const isMaxDiffTurf = init.project.is_maxdif_turf;

  const reports = [
    {
      title: 'TURF Analysis',
      name: isMaxDiffTurf ? 'maxdif_turf_analysis' : 'turf_analysis',
      kinds: [ 'pptx', 'xlsx' ]
    }
  ];

  const respondentsNumber = (
    isMaxDiffTurf ?
      data.respondents_count :
      data.total_respondents_count
  );

  const needMoreRespondents = respondentsNumber < 150;

  return (
    <Modal
      modal
      modalSize="lg"
      modalClassName={
        classnames("-scorecard", { 'tour-mode': !!tourData })
      }
      className="concept-report h100"
      id="TURFAnalysisModal"
    >
      <div className="modal-header concept-report_header">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ onClose }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <div className="concept-report_header-item concept-preview">
          {
            !!init.project.image_url &&
            <div
              className="concept-preview_thumb -sm"
              style={ { backgroundImage: `url(${init.project.image_url})` } }
            />
          }
          {
            !init.project.image_url &&
            <div className="concept-preview_thumb -sm" />
          }
        </div>

        <div className="concept-report_header-item">
          <div
            className="concept-report_header-subtitle"
            { ...renderRawHtml(init.project.title) }
          />
          <div className="concept-report_header-title-wrap">
            <h2 className="modal_title concept-report_header-title">
              TURF Analysis
            </h2>

            <div className="concept-report_header-count">
              <SvgIcon
                className="concept-report_header-count-icon"
                name="project-user"
              />
              <span>{ respondentsNumber }</span>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-filters-header">
        <TopFilter
          combinations={ init.project.combinations }
          data={ data }
          turfData={ turfData }
          loadData={ loadData }
          loadTurfData={ loadTurfData }
          combinationsRef={ combinationsSelectElement }
        >
          <HeaderLinks
            className="-no-min-width"
            exportUrl={ init.urls.export }
            reports={ reports }
            i18n={ init.i18n }
            data={ data }
            tourName={ 'projectTURFModalExport' }
          />
        </TopFilter>
      </div>

      <div className="modal_body -scrollable concept-report_body">
        <div className="modal-data">
          {loading && <div className="page-loader -inner-loader" />}

          <div className="turf-message">
            <div className="comparisons_grid">
              <p>
                TURF analysis is used to maximize unduplicated
                { ' ' }
                reach and provides the best combinations of
                { ' ' }
                a given number of items.
              </p>
            </div>
            {
              turfLoadingError &&
              <div className="comparisons_grid warning">
                The calculations for this number of combinations took
                { ' ' }
                significant amount of time,
                { ' ' }
                so the system is not able to show them right now,
                { ' ' }
                please download an excel report containing all data for
                { ' ' }
                all combinations instead.
              </div>
            }
            {
              !turfLoadingError && needMoreRespondents &&
              <div className="comparisons_grid warning">
                Caution: buzzback recommends at least N=150 for TURF analysis.
              </div>
            }
          </div>

          {
            !turfLoadingError &&
            <div className="comparisons_grid -turf">
              <table
                className="table table-comparison -long-first-cell"
                ref={ tableElement }
              >
                <thead>
                  <tr>
                    <th className="table-comparison_name-cell">
                      <div className="table-comparison_title">
                        Combinations
                      </div>
                    </th>
                    <th className="table-comparison_name-cell -text-left">
                      Reach
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !!turfData.data &&
                    map(turfData.data, (r, i) => (
                      <CombinationRow key={ i } r={ r } />)
                    )
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    </Modal>
  );
};

export default TurfModal;
