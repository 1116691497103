import React, { useContext, useState, useRef, useEffect } from 'react';
import Confetti from 'react-confetti';
import Modal from "../components/modal";
import { GlobalContext } from '../contexts/appContexts';
import HomePage from "./homePage";

const Celebration = ({ title, text }) => {
  const { nextStep, currentData } = useContext(GlobalContext);
  const [ celebrationDimensions, setCelebrationDimensions ] = useState(null);
  const [ openModal ] = useState(true);

  const homeRef = useRef(null);

  const closeModal = () => {
    nextStep();
  };

  const balance = () => {
    if (currentData.autoReload) {
      return currentData.addMoney;
    } else if (currentData.oneTimeSend) {
      return currentData.oneTimeAmount;
    }
    return 300;
  };

  const updateCelebrationDimensions = () => {
    const body = document.body || document.querySelector('body');
    const dimensions = body.getBoundingClientRect();
    setCelebrationDimensions({ width: dimensions.width, height: dimensions.height });
  };

  useEffect(() => {
    if (homeRef.current) {
      updateCelebrationDimensions();
    }
    window.onresize = () => {
      updateCelebrationDimensions();
    };
  }, [ homeRef.current ]);

  return (
    <div onClick={ closeModal }>
      {
        celebrationDimensions &&
        <div className="confetti">
          <Confetti
            width={ celebrationDimensions.width }
            height={ celebrationDimensions.height }
            numberOfPieces={ 400 }
            gravity={ 0.15 }
          />
        </div>
      }
      <HomePage balance={ balance() } ref={ homeRef } />
      {
        openModal &&
        <Modal handleClose={ closeModal }>
          <div className="celebration">
            <div className="title modal_content-title">
              <h1 className="title_h1">{ title }</h1>
            </div>
            <div className="modal_content-text">{ text }</div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default Celebration;
