import React from "react";

const Privacy = () => {
  return(
    <div className="agreement">
      <p>Last updated on March 28, 2024</p>

      <section id="1">
        <div>
          <h2>Overview</h2>
          <div><section><p>This Privacy Statement applies to PayPal and Xoom accounts and aims to provide you with information regarding our use of your Personal Information when you visit our website, apply for, or use our services, like Braintree and Zettle (collectively, the “<u>Services</u>”). We encourage you to read this Privacy Statement and to use it to help you make informed decisions.</p>
          <p>Certain capitalized terms that are not otherwise defined in the Statement are explained in the<span> </span><a href="#11"><strong>Definitions</strong></a><span> </span>section below.</p>
          <p>We revise this Privacy Statement from time to time to reflect changes to our business, Services, or applicable laws. If the revised version requires notice in accordance with applicable law, we will provide you with 30 days prior notice by posting notice of the change on the &quot;<span className="pseudo-link"><strong>Policy Updates</strong></span>&quot; or &quot;<span className="pseudo-link">Privacy Statement</span>&quot; page of our website, otherwise the revised Privacy Statement will be effective as of the published effective date. If you are a new User or are receiving this Privacy Statement for the first time and there is an upcoming change described on the <span className="pseudo-link"><strong>Policy Updates</strong></span> or Privacy Statement page at the time you receive this Privacy Statement, such upcoming change will apply to you on the indicated effective date.<br/></p></section></div>
        </div>
      </section>

      <section id="2">
        <div>
          <h2>Non-Account Holders and Fastlane (formerly known as Connect) Profile Users</h2>
          <div>
            <section>
              <p>Our Services may be accessed by individuals without a PayPal or Xoom account. We will collect Personal Information from you even if you are a non-account holder when you use our Services, such as when you use our Pay Without a PayPal account, use Unbranded Payment Services (e.g., Braintree and Zettle), use a Fastlane profile, or when a non-accountholder receives a payment through the Visa+ service from a PayPal account holder (“Recipient”). If you Pay Without a PayPal account, we may link your transaction information with your PayPal account, if you have one at the time you use the Service without logging in, or if you create a PayPal account later.</p>
              <p>With
                a Fastlane (formerly known as
                &quot;Connect”) profile, individuals can store their payment methods and Personal
                Information with PayPal so that they can complete the checkout process faster
                at participating Partners and Merchants without having to manually input
                payment method details and Personal Information each time they checkout (&quot;Fastlane&quot;).</p><p>When you create a Fastlane profile, you are agreeing to let PayPal store your Personal Information, including your name, email, phone number, address information, as well as billing information and payment method details, and pre-populate that information in the checkout flows of participating Partners and Merchants so that you can complete purchases faster by not having to manually input the information. We will also collect transaction data related to your purchases when you use Fastlane.<br/></p>
              <p>The Visa+ Service enables eligible customers with a PayPal account to send and receive money to and from Venmo and third-party Visa+-enabled digital wallets (“Visa+”).</p>
              <p>When you shop on Partner and Merchant sites, the partner or merchant may disclose Personal Information with PayPal that we will use in accordance with this Privacy Statement and the relevant PayPal user agreement.</p>
              <p><br/></p>
            </section>
          </div>
        </div>
      </section>

      <section id="3">
        <div>
          <h2>Categories of Personal Information We Collect</h2>
          <div>
            <section>
              <p><strong>Categories of Personal Information collected</strong><span> </span><strong><u>from you</u></strong><strong>, including from your interactions with us and use of the Services:</strong></p>
              <ul>
                <li><em>Personal Identifiers</em>: Such as name, Business Name, Address, Phone Number, Email, IP address, Device Information, Information collected from cookies or other tracking technologies, other information necessary to establish an account or profile</li>
              </ul>
              <ul>
                <li><em>Records and Financial Information</em>: Such as, bank account and routing numbers, credit and debit card information, amount you send or request, other financial information</li>
              </ul>
              <ul>
                <li><em>Commercial Information</em>: Such as online shopping cart information, shopping activity on merchant sites, purchase history, and order tracking and product information</li>
              </ul>
              <ul>
                <li><em>Geolocation</em>: We may collect IP-based geolocation data and Global Positioning System (GPS) with your consent during your user experience or based on your mobile application settings.</li>
              </ul>
              <ul>
                <li><em>Internet or network activity</em>: interactions with our Services, Information about response time for web pages, download errors, date and time when you used the service, Location Information, such as your IP address, statistics regarding how pages are loaded or viewed, the websites you visited before coming to the Sites and other usage and browsing information collected through Cookies (“<strong>Technical Usage Data</strong>”)</li>
              </ul>
              <ul>
                <li><em>Biometric</em>: When you consent in the user experience, we collect voice identification, photo identification, or face scans to verify your identity and authenticate you for certain actions related to your account, including, for example, verify your identity and authenticate you to meet regulatory requirements or before you access accounts and Services, recover passwords, update profile info, manage payments and payment methods, lift account limitations, and initiate cryptocurrency transfers.</li>
              </ul>
              <ul>
                <li><em>Audio, electronic, visual, or similar information</em>: Call recordings when you talk to customer service</li>
              </ul>
              <ul>
                <li><em>Professional or employment information:</em><span> </span>including business information, job title, contact emails, phone numbers and taxpayer ID numbers</li>
              </ul>
              <ul>
                <li><em>Imported Contact Information:</em><span> </span>including name, address, phone number, images, email address or usernames associated with the contacts</li>
              </ul>
              <ul>
                <li><em>PayPal Account Profile Information:</em><span> </span>username, profile picture, gender, or personal description which you add that may include sensitive Personal Information. You can set your profile to “Private” at any time</li>
              </ul>
              <ul>
                <li><em>Information you provide when you contact us:</em><span> </span>Your response to surveys, recorded conversations, chat conversations with us, email correspondence with us, account or profile status, repayment history, voice identification, Information about others if you choose to share it with us</li>
              </ul>
              <ul>
                <li><em>Inferred data:</em><span> </span>We may infer information about you such as your preferences and shopping behavior, based on your transactions and interactions with our Services</li>
              </ul>
              <ul>
                <li><em>Characteristics of Protected Classifications:</em><span> </span>including age or date of birth, national origin, disability, citizenship, military status</li>
              </ul>
              <ul>
                <li><em>Sensitive Personal Information:</em><span> </span>Social Security number, government-issued identification, bank account and routing numbers, credit and debit card information, voice identification and Photo IDs or Precise Geolocation</li>
              </ul>
              <ul>
                <li><em>Information from your device:</em><span> </span>including, language settings, browser ID, cookie preferences, time zone, operating system, platform, screen resolution and similar information about your device settings, data collected from cookies or other tracking technologies</li>
              </ul>
              <p><strong>We may also obtain the above categories of Personal Information from the following<span> </span><u>categories of sources</u>:</strong></p>
              <ul>
                <li><a href="#11">PayPal Companies</a>, including Venmo and PayPal Honey</li>
              </ul>
              <ul>
                <li>Third parties: including Service Providers, Partners and Merchants, Payment Partners, such as card networks and payment processors, Credit Reporting Agencies, Government Entities, Data Brokers, and Financial Institutions</li>
              </ul>
              <ul>
                <li>Linked Accounts: Non-financial or financial accounts you agree to link to PayPal, such as social network accounts, mail accounts or for open banking. You may change your mind about use of this feature and unlink your linked accounts at any time. If you choose to link these accounts or share such information with us, we will periodically collect and process it until you unlink the account</li>
              </ul>
              <ul>
                <li>Third Party Applications: Applications that you choose to use for example, the Apple App Store, Google Play Store, or social networking sites</li>
              </ul>
            </section>
          </div>
        </div>
      </section>

      <section id="4">
        <div>
          <h2>How is Personal Information used?</h2>
          <div>
            <section>
              <p>We may process your Personal Information for a variety of reasons, including to provide our Services, for security and fraud prevention and to comply with law. We may also use personal data to participate in certain features, that while not necessary for use of the Services, may be of interest to you, such as syncing your contact list to your account, personalize content and offers, targeted advertising, or connecting to a third-party platform. Some of these features may require your consent. </p>
              <p>We may collect Personal Information to:</p>
              <ul>
                <li><em>Provide our Services</em>: such as to help you send, receive or request money, initiate a payment, add monetary value to an account, pay a bill, administer your purchases, show you your Fastlane profile, account and payment information, send and receive Visa+ transactions, to assess your creditworthiness in connection with our Services, confirm your identity and your contact information, to authenticate your access to your account or Fastlane profile and to confirm your account or profile and financial information is accurate and up to date. For Visa+, we will receive the recipient&#x27;s name and payment information from Visa and third-party Visa+ participating digital wallets for the purpose of processing and matching Visa+ payment transactions between participating digital wallets and PayPal. If you are using Fastlane, we will also use your Personal Information and payment method details to determine whether the payment you are making with a participating Partner or Merchant is authorized by you and likely to be successfully authorized by the payment method you choose to use when you make a purchase using details from your Fastlane profile.</li>
                <li><em>Provide receipts in connection with Zettle services</em>: For buyers using our Zettle services, you may choose to provide us your contact details so that we can send you digital receipts.</li>
              </ul>
              <ul>
                <li><em>Manage and improve our Services:</em><span> </span>for example, to develop new products and features, for customer analysis, to administer our Services, and for internal operations, for example troubleshooting, data analysis, testing, research, and statistical purposes.</li>
              </ul>
              <ul>
                <li><em>Manage fraud and risk</em>: We conduct risk analysis, fraud prevention and risk management to protect our customers and business, including fraud that involves our Partners and Merchants and strategic ventures.</li>
              </ul>
              <ul>
                <li><em>Associate information about you:</em><span> </span>if you use our Services Without a PayPal account (e.g., Pay without a PayPal account), we may associate such transactions with your PayPal account if you have one or later establish an account. <span>If you use one of the following Services: Fastlane, Venmo, and PayPal Honey; we may associate information about your transactions and experiences using these Services to personalize content and offers and improve your use of the PayPal services.</span></li>
              </ul>
              <ul>
                <li><em>Market our Services</em>: We may use Personal Information to market our Services including where we partner with others to offer similar services to market about our Partners and Merchants. We use Personal Information, <span>including when we Associate information about you,</span> to better understand and cater to your interests.</li>
              </ul>
              <ul>
                <li><em>Communicate with you</em>: We may contact you when you need us, such as answering a question you sent to our customer service team.</li>
              </ul>
              <ul>
                <li><em>Comply with Laws:<span> </span></em>to comply with applicable laws and rules and enforce our agreements with you and other people who use our Services.</li>
              </ul>
              <ul>
                <li><em>Process information about your contacts:</em><span> </span>to make it easy for you to find and connect them, improve payment accuracy and suggest connections with people you may know.<span> </span><strong><u>By providing us with information about your contacts you certify that you have permission to provide that information to PayPal for the purposes described in this Privacy Statement.</u></strong></li>
              </ul>
              <ul>
                <li><em>Create an account connection between your account and a third-party account or platform</em>: such as with a social media account or a financial institution in connection with your participation in Open Banking.</li>
              </ul>
              <ul>
                <li><em>Send you locally relevant options</em>: If you agree to let us track your location, we can enhance your security of our Services and customize our Services by using the right language and personalizing content such as providing location-based options, functionality or offers, ads and search results. <span>Even if you don’t allow us to track your location, we may still use your address to send you location-based options recommended by businesses that are near to your address.</span></li>
              </ul>
              <ul>
                <li><em>Remember your preferences</em>: We may remember your preferences for the next time you use the Services, such as whether you choose to receive digital receipts via email or text when you checkout.</li>
              </ul>
              <ul>
                <li><em>Personalize your experience</em>: When you use Services, as well as other third-party sites and services, we might use tracking technologies like cookies. See our<span> </span><span className="pseudo-link">Cookie Statement</span><span> </span>for more details.</li>
              </ul>
            </section>
          </div>
        </div>
      </section>

      <section id="5">
        <div>
          <h2>Do We Disclose Personal Information, and why?</h2>
          <div>
            <section>
              <p>We do not sell Personal Information to third parties for money or share your Personal Information for cross context behavioral advertising, including any Sensitive Personal Information.</p>
              <p>However, we will disclose your Personal Information with third parties to help us provide Services, protect our customers from risk and fraud, market our products, and comply with legal obligations.</p>
              <p><strong>In addition, we may disclose Personal Information with:</strong></p>
              <ul>
                <li><a href="#11"><strong>PayPal Companies</strong></a>, including Venmo to provide you with the Services, personalize your use of the Services, to manage our business; or with PayPal Honey to manage our Rewards program.</li>
              </ul>
              <ul>
                <li><strong>Authorities</strong>, when accompanied by a subpoena or other legal documentation that requires PayPal or PayPal Companies to respond. Such authorities include courts, governments, law enforcement, and regulators. We may also be required to provide other third parties information about your use of our Services, for example to comply with card association rules, to investigate or enforce violations of our user agreement or to prevent physical harm or illegal activity.</li>
              </ul>
              <ul>
                <li><strong>Other financial institutions</strong>, to jointly offer a product, such as PayPal Credit, PayPal Savings, PayPal Cashback Mastercard and PayPal Extra Mastercard.</li>
              </ul>
              <ul>
                <li><strong>Card networks and payment processors,<span> </span></strong>to facilitate payment processing or to add cards to your electronic wallet. For payment transactions with Fastlane, your Personal Information will be shared with the provider of the payment services for the participating Partner and Merchant to enable the processing of the payment transaction. The payment provider for the Partner and Merchant may be PayPal or a third party payment provider.</li>
                <li><strong>Fraud prevention and identity verification agencies</strong>, for example to assist us in detecting activities suggestive of fraud.</li>
                <li><strong>Credit reporting and debt collection agencies,<span> </span></strong>for example to collect unpaid overdue debts through a third party such as a debt collection agency.</li>
              </ul>
              <ul>
                <li><strong>Service providers</strong><span> </span>that operate at our direction and on our behalf to perform services we outsource to them, such as processing payments, marketing, research, compliance, audits, corporate governance, communications, IT development, maintenance, hosting and support and customer service operations.</li>
                <li><strong>Other PayPal Account Holders to complete a transaction</strong>. Some Personal Information is disclosed to other PayPal account holders as required to complete a payment transaction. This includes your username, profile photo, first and last name, email, and phone number.</li>
                <li><strong>Other Users in accordance with your account settings</strong>. In accordance with your<span> </span><span className="pseudo-link">account settings</span><span> </span>or PayPal.Me preferences, your Personal Information can be seen by other account holders and non-account holders (“Users”) to help facilitate a transaction. For example, as part of the send money feature, a User may search for your account to initiate a payment. Based on your account settings, the information shown to other Users may include your username, profile photo, first and last name, email, phone number, city and state, month and year of PayPal account creation, and any additional information you include in the details or “about you” section of your account.</li>
                <li><strong>Other Users if you have a business profile</strong>.  If you have a business profile, we will display a payment link and disclose certain information about you to other Users, including your name or business name, profile picture or logo, and the city associated with your PayPal account, as well as total time selling with us, total number of followers, and total number of unique Users that have paid you in the past year.</li>
                <p>If you have a business profile, you can choose to display other information to other Users, such as your street address, phone number, email and website, in accordance with your business profile settings.</p>
                <li><strong>Linked accounts</strong>, for example any social media accounts you asked us to link or when you link an account with another bank or<span> </span><strong>financial institutions,<span> </span></strong>card account, or aggregator<strong><span> </span>in connection with your participation in Open Banking</strong>, so we can check if you have sufficient funds or confirm your ownership of the account.</li>
              </ul>
              <ul>
                <li><strong>Partners and Merchants, their service providers and others involved in a transaction</strong>, for example when you use the Services to initiate online purchases, save your payment information with Fastlane, pay other Users using the Services, pay Recipients using Visa+, or return goods we may disclose information about you and your account or Fastlane profile with the other parties (or their service providers) involved in processing your transactions. We may also disclose Personal Information to Partners and Merchants to enable their use of our Services to facilitate your transactions.  For example, when you visit a participating merchant site or app, the merchant can check whether you are a user of PayPal services and present a recommended payment method to you to simplify your checkout process.</li><li>Please note
                that Personal Information disclosed to Partners and Merchants (or their
                service providers) involved in a transaction is subject to the Partners&#x27; and Merchants&#x27; own privacy policies and
                procedures. We may also disclose Personal Information to Partners and Merchants
                to enable their use of our Services with those Partners and Merchants.</li>
              </ul>
              <ul>
                <li><strong>Other third parties</strong>, for example we disclose Personal Information to advertising platforms at your direction, or security service providers to help prevent unauthorized access to our Services.<span> </span><strong>Please be aware that these parties’ privacy notice applies to the Personal Information that you share directly with them</strong>. For example, we use Google’s reCAPTCHA to prevent misuse of our Services, when you access our mobile application. We may also use Google Address Autofill to ensure accuracy of your address. Google’s<span> </span><span className="pseudo-link">Privacy Policy</span><span> </span>and<span> </span><span className="pseudo-link">Terms of Use</span><span> </span>apply to the Personal Information you share with them.</li>
              </ul>
              <ul>
                <li><strong>Buyers or in connection with business transfer</strong><em>,<span> </span></em>for example if we are involved in a merger, a purchase or sale of all or part of our business or assets, including receivables and debts, we may disclose, under appropriate data protection terms, your Personal Information to an interested or actual buyer of those business or assets. If PayPal or a significant portion of PayPal’s assets are acquired by a third party, Personal Information may also be disclosed.</li>
              </ul>
              <p>We may disclose your sensitive personal information as appropriate to carry out legitimate business activities allowed by law.</p>
              <p> </p>
            </section>
          </div>
        </div>
      </section>

      <section id="6">
        <div>
          <h2>How long does PayPal store your Personal Information?</h2>
          <div>
            <section>
              <p>We retain Personal Information for as long as needed or permitted in context of the purpose for which it was collected and consistent with applicable law.</p>
              <p>The criteria used to determine our retention period is as follows:</p>
              <ul>
                <li>
                  Personal Information used for the ongoing relationship between you and PayPal is stored for the duration of the relationship plus a period of 10 years, unless we need to keep it longer, such as:
                  <ul>
                    <li>a legal obligation or compliance with laws to which we are subject is retained consistent with the applicable law, such as under applicable bankruptcy laws and AML obligations</li>
                    <li>litigation, investigations, audit and compliance practices, or to protect against legal claims.</li>
                  </ul>
                </li>
              </ul>
              <p>We retain biometric data for as long as needed or permitted given the purpose for which it was collected and no more than 3 years after your account closes, unless otherwise required by applicable law.</p>
            </section>
          </div>
        </div>
      </section>

      <section id="7">
        <div>
          <h2>How Do We Use Cookies and Tracking Technologies?</h2>
          <div>
            <section>
              <p>When you interact with our Services, open email we send you, or visit a third-party website for which we provide Services, we and our partners use cookies and other tracking technologies such as pixel tags, web beacons, and widgets (collectively, “Cookies”) to recognize you as a user, customize your online experiences and online content, including to serve you interest-based advertising, perform analytics; mitigate risk and prevent potential fraud, and promote trust and safety across our Services.</p>
              <p>We use Cookies to collect your device information, internet activity information, and inferences as described above.</p>
              <p>You can disable or decline some cookies for our Services. But, since some parts of our service rely on cookies to work, those services could become difficult or impossible to use.</p>
              <p>Some web browsers have an optional setting called “Do Not Track” (DNT) that lets you opt-out of being tracked by advertisers and some third parties. Because many of our services won’t function without tracking data, we do not respond to DNT settings.</p>
              <p>If you want to know more about how we use cookies, please review our<span> </span><span className="pseudo-link">Statement on Cookies and Tracking Technologies</span><span> </span>to learn more about our use of Cookies. To learn how to opt-out of this kind of tracking technology, visit<span> </span><a href="https://optout.aboutads.info/?c=2&amp;lang=EN">About Ads</a>.</p>
            </section>
          </div>
        </div>
      </section>

      <section id="8">
        <div>
          <h2>Your Data Protection Rights</h2>
          <div>
            <section>
              <p><strong>Your rights to access, correction, deletion, and restriction to use or share your Personal Information.</strong></p>
              <p>Under applicable data protection law, you have certain rights to how your Personal Information is collected, stored, used and shared.</p>
              <p>We recognize the importance of your ability to control the use of your Personal Information and provide several ways for you to exercise your rights to access (right to know), correction, deletion (erasure), and to restrict certain information (right to opt out of sharing and right to limit use and disclosure of sensitive personal information).</p>
              <p>We will not deny you services, charge you different prices, or provide you with a different level of service solely for exercising your privacy rights. If you are a California resident, learn more about how we have handled your<span> </span><span className="pseudo-link">Privacy Rights</span>.</p>
              <p><strong>How do you exercise your rights?</strong></p>
              <p>If you, or an authorized agent, want to exercise any of your rights relating to your Personal Information,<span> </span><a href="#12"><strong>contact us</strong></a><span> </span>or submit your request from your account settings. If you have a PayPal account, you can exercise your privacy rights by accessing “Data and Privacy” from account settings in the PayPal app.</p>
              <p>If you, or an authorized agent, want to exercise any of your rights relating to your Personal Information in your Fastlane profile,<span> </span><a href="#12"><strong>contact us</strong></a><span> </span>or submit your request from your Fastlane profile management portal.</p>
              <p>Even if you do not have a PayPal account (for example, where you use Pay without a PayPal account), you can submit a request for access, correction, or deletion of your Personal Information by contacting us at the number provided in our<span> </span><a href="#12">Contact Information</a><span> </span>section.</p>
              <p>If you or an authorized agent submit a request, we’ll first need to verify who you are before we can respond to your request. We may ask you to provide us with information necessary to reasonably verify your identity before responding to your request. We will compare the information you submit against our internal business records to verify your identity. If we can’t verify your identity, we will not be able to fulfill your request. If we deny your request in whole or in part, you may have the right to appeal the decision. In such circumstances, we will provide you with information regarding the appeals process.</p>
              <p><strong>Your</strong><strong><span> </span>right to request a copy of the Personal Information</strong>. If you want to make a request to know about the data we’ve collected about you in the past 12 months, you have choices:</p>
              <ul>
                <li>Log in to your PayPal or Xoom account or Fastlane profile management portal and submit a request</li>
                <li><a href="#12">Call or contact us</a><span> </span>and request that we provide you with the data we’ve collected.</li>
              </ul>
              <p><strong>Your right</strong><strong><span> </span>to correct your Personal Information:</strong></p>
              <ul>
                <li>Log in to your PayPal or Xoom account or Fastlane profile management portal and correct information you previously added. For example, you can edit your addresses in your settings</li>
                <li><a href="#12">Call or contact us</a><span> </span>and request that we correct specific information</li>
              </ul>
              <p><strong>Your right</strong><strong><span> </span>to delete your Personal Information:</strong></p>
              <ul>
                <li>Log in to your PayPal or Xoom account or Fastlane profile management portal and delete information you previously added. For example, you may delete your non-primary addresses in your settings</li>
                <li><a href="#12">Call us or contact us</a><span> </span>and request that we delete specific information</li>
                <li>Close your PayPal or Xoom account or Fastlane profile</li>
              </ul>
              <p>If you close your PayPal or Xoom account or Fastlane profile or request that we delete Personal Information, we still need to keep some Personal Information as explained in<span> </span><a href="#6">How long does PayPal store your Personal Information</a><span> </span>section so we can:</p>
              <ul>
                <li>Complete a transaction, provide goods or services you requested, or comply with our promises to you in the user agreement or other contract you have with us</li>
                <li>Detect and prevent malicious, fraudulent, or illegal activity</li>
                <li>Protect your (or another person’s) legal rights, including the right to free speech</li>
                <li>Manage our internal business processes that are reasonably related to your expectations when using our Services</li>
                <li>Comply with laws and other legal or governmental processes</li>
              </ul>
              <p>California also offers a right to opt out of “Selling” and “Sharing” Personal Information. Global Privacy Control setting is a browser setting that notifies website owners of users&#x27; privacy preferences regarding selling or sharing their personal information. PayPal does not respond to these settings because we do not sell or share data. Some Personal Information collected, processed, or disclosed by a financial institution are subject to federal laws, such as the Gramm-Leach-Bliley Act. Consumers may read our<span> </span><a href="#14"><strong>Consumer Privacy Notice</strong></a><span> </span>for more information about their rights under US federal law.</p>
              <p><strong>Understanding your choices</strong></p>
              <p>You can control how Personal Information is collected or disclosed, as well as how we communicate with you. Here are some of the ways you can customize your choices.</p>
              <p><strong>Choose how we collect Personal Information</strong></p>
              <p>You may choose to limit the Personal Information you provide when our apps or Services request it. To help make choices that are right for you, it’s important to understand that Personal Information helps us provide a richer, more personalized experience for you. Also, some Personal Information is required for our Services to function at all.</p>
              <p>For example, sharing your contacts helps make it easier for you to find the people you want to send money to. If you choose not to share your contacts with us, you can still use our mobile apps, but some actions may not be as fast or easy as it would be if shared your contacts. Another example is creating an account or Fastlane profile with us. If you choose not to provide information that is required for an account or Fastlane profile to function, like your name and email address, we will not be able to create an account or Fastlane profile for you.</p>
              <p><strong>Choose how linked accounts collect and use Personal Information</strong></p>
              <p>If you link your account to a third-party service, you may be able to manage how your Personal Information is collected, used, and shared by them. Read the third parties’ privacy policies to see the choices they offer you.</p>
              <p>You can control which third-party services you link to your account and what Personal Information they can collect about you. For example, to manage the permissions, go to the Security settings in your PayPal account.</p>
              <p><strong>Choose what we disclose with other Users</strong></p>
              <p>Some Personal Information may be seen by other Users. You may be able to adjust or turn off this setting in the privacy section in your account settings.</p>
              <p><strong>Choose how we communicate with you</strong></p>
              <p>Your choices about how we communicate with you differ depending on the purpose of the message and how it is delivered. Some messages are considered optional, and some are necessary for you to manage your accounts or Fastlane profile with us. We use email, text messages, push notifications on your mobile device, and even phone calls or paper mail depending on the situation and your preferences.</p>
              <p>You can click the unsubscribe link in a PayPal or Fastlane marketing email, opt out of a text message by replying “STOP,” or turn off notifications on your device. You can also change your account’s notification settings or the notification preferences on your device.</p>
              <p>You won’t be able to opt out of messages that are considered necessary for the Services, such as digital receipts and emails that alert you to changes in your account or Fastlane profile&#x27;s status. You may be able to decide how we send those messages, such as by email, phone, text message, or a notification on your mobile device.</p>
              <p><br/></p>
            </section>
          </div>
        </div>
      </section>

      <section id="9">
        <div>
          <h2>How Do We Protect Your Personal Information?</h2>
          <div>
            <section>
              <p>We maintain technical, physical, and administrative security measures designed to provide reasonable protection for your Personal Information against loss, misuse, unauthorized access, disclosure, and alteration. The security measures include firewalls, data encryption, physical access controls to our data centers, and information access authorization controls. While we are dedicated to securing our systems and Services, you are responsible for securing and maintaining the privacy of your password(s) and account/Fastlane profile registration information and verifying that the Personal Information we maintain about you is accurate and current. We are not responsible for protecting any Personal Information that we share with a third-party based on a linked account connection that you have authorized.</p>
              <p><br/></p>
            </section>
          </div>
        </div>
      </section>

      <section id="10">
        <div>
          <h2>Can Children Use Our Services?</h2>
          <div>
            <section>
              <p>We do not knowingly collect information, including Personal Information, from children under the age of 13 or other individuals who are not legally able to use our Services. If we obtain actual knowledge that we have collected Personal Information from someone not allowed to use our Services, we will promptly delete it, unless we are legally obligated to retain such data.</p>
              <p>Please<span> </span><a href="#12">contact us</a><span> </span>if you believe that we have mistakenly or unintentionally collected information from someone not allowed to use our Services.</p>
              <p>We do not sell to third parties for money or share Personal Information of anyone under 16 years of age for cross context behavioral advertising.</p>
            </section>
          </div></div>
      </section>

      <section id="11">
        <div>
          <h2>Definitions</h2>
          <div>
            <section>
              <ul>
                <li>Device Information means data that can be automatically collected from any device used to access the Services. Such information may include, but is not limited to, your device type; your device’s network connections; your device’s name; your device IP address; information about your device’s web browser and internet connection you use to access the Services; Geolocation Information; information about apps downloaded to your device.</li>
              </ul>
              <ul>
                <li>Geolocation Information means information that identifies, with precise specificity, your location by using, for instance, longitude and latitude coordinates obtained through your GPS, or your device settings.</li>
              </ul>
              <ul>
                <li>Location Information means information that identifies, with reasonable specificity, your approximate location by using, for instance, longitude and latitude coordinates obtained through GPS or Wi-Fi or cell site triangulation.</li>
              </ul>
              <ul>
                <li>Partners and Merchants means our partners and the merchants, partners or businesses that our Users transact with for the purpose of obtaining goods or services.</li>
              </ul>
              <ul>
                <li>Pay Without a PayPal account means our Services may be accessed by individuals without using a PayPal account, a Fastlane profile, or the Unbranded Payment Services.</li>
              </ul>
              <ul>
                <li>PayPal means PayPal, Inc. which offers PayPal, Braintree, Xoom, Zettle and Fastlane profile services.</li>
              </ul>
              <ul>
                <li>PayPal Companies means companies or separate brands, affiliates or subsidiaries of PayPal, and who process Personal Information in accordance with their terms of service and privacy statements. Examples include Venmo, Honey Science LLC, Chargehound LLC,  Hyperwallet, Simility, Swift Financial LLC, and Bill Me Later, Inc.</li>
              </ul>
              <ul>
                <li>Personal Information in this Privacy Statement means information about you, including your identity, finances and online behavior.</li>
              </ul>
              <ul>
                <li>Sell under California law is defined as the disclosure of personal information to third parties in exchange for monetary or other valuable consideration.</li>
              </ul>
              <ul>
                <li>Services means any PayPal, Unbranded Payment Services, Pay Without a PayPal account, Fastlane, Xoom, Zettle, bill pay, Rewards, sending or receiving money, credit products and services, content, features, technologies, or functions, and all related websites, applications and services offered to you by PayPal. Your use of the Services includes use of our Sites.</li>
              </ul>
              <ul>
                <li>Sharing under California law is defined as the targeting of advertising to a consumer based on that consumer’s personal information obtained from the consumer’s activity across websites.</li>
              </ul>
              <ul>
                <li>Sites means the websites, mobile apps, official social media platforms, or other online properties through which PayPal offers the Services and which has posted or linked to this Privacy Statement.</li>
              </ul>
              <ul>
                <li>Unbranded Payment Services means when you are interacting with and making payments to Partners and Merchants using our card processing services that do not carry the PayPal brand or when you use our Braintree or Zettle services.</li>
              </ul>
              <p> </p>
            </section>
          </div>
        </div>
      </section>

      <section id="12">
        <div>
          <h2>Our Contact Information</h2>
          <div>
            <section>
              <p>If you have questions about this Privacy Statement or your Personal Information, contact us so we can help.</p>
              <p>To talk about your PayPal account or Unbranded Payment Services:</p>
              <ul>
                <li>Call PayPal Customer Service at 1-888-221-1161</li>
                <li>or<span> </span><span className="pseudo-link"><strong>visit our Customer Service web portal</strong></span></li>
              </ul>
              <p>To talk about your Fastlane profile:</p>
              <ul>
                <li>Call PayPal Customer Service at 1-844-705-3555</li>
                <li>Or visit your<span> </span><span className="pseudo-link">Fastlane profile management portal</span></li>
              </ul>
              <div><span>To talk about your Xoom account:</span></div>
              <ul>
                <li>Call Xoom Customer Service at 1-877-815-1531</li>
                <li>or<span> </span><span className="pseudo-link"><strong>visit our Help Center web portal</strong></span></li>
          </ul>
            </section>
          </div>
        </div>
      </section>

      <section id="13">
        <div>
          <h2>California Privacy Notice of Collection</h2>
          <div>
            <section>
              <p>Under the laws of California and certain other US states (i.e., Virginia), we are required to provide you with the following additional information about: (1) the purpose for which we use each category of “personal information” we collect; and (2) the categories of third parties to which we (a) disclose such personal information for a business purpose, (b) “share” personal information for “cross-context behavioral advertising,” and/or (c) “sell” such personal information.</p>
              <p>Under California law, “sharing” is defined as the targeting of advertising to a consumer based on that consumer’s personal information obtained from the consumer’s activity across websites, and “selling” is defined as the disclosure of personal information to third parties in exchange for monetary or other valuable consideration.<span> </span><strong>We do not sell or share your Personal Information, including any Sensitive Personal Information.</strong><span> </span>We also do not sell or share and have no actual knowledge that we have sold or shared any Personal Information of anyone under 16 years of age.</p>
              <p>For more information about each category, purpose of use, and the third parties to which we disclose information, please see the “Categories of Personal Information We Collect”, “How is Personal Information used,” and “Do We Disclose Personal Information” sections.</p>

              <table className="agreement_table -bordered">
                <tbody>
                <tr>
                  <td><p><strong>Categories of Personal Information</strong></p></td>
                  <td><p><strong>Purpose of Collection</strong></p></td>
                  <td><p><strong>Categories of 3</strong><strong><sup>rd</sup></strong><span> </span><strong>Parties to which PayPal discloses this Personal Information for Business Purpose</strong></p></td></tr>
                <tr>
                  <td><p>Identifiers</p></td>
                  <td><ul><li>Provide Services</li><li>Communicate with you</li><li>Link an account</li><li>Manage and improve our Services</li><li>Manage fraud and risk</li><li>Market our Services</li><li>Comply with laws</li><li>Process information about your contacts</li><li>Communicate with you</li><li>Provide receipts in connection with Zettle services</li><li>Remember your preferences</li><li>Associate information about you</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Authorities</li><li>Other financial institutions</li><li>Card Networks and Payment Processors</li><li>Fraud prevention and identity verification agencies</li><li>Credit Reporting Agencies</li><li>Service providers</li><li>Other Users in accordance with account settings</li><li>Linked Accounts</li><li>Partners and Merchants</li><li>Other third parties</li><li>Buyers in connection with a business transfer</li></ul></td></tr>
                <tr>
                  <td><p>Records and Financial Information</p></td>
                  <td><ul><li>Provide Services</li><li>Communicate with you</li><li>Link an account</li><li>Keep your account and Fastlane profile and financial information up to date</li><li>Manage and improve our Services</li><li>Manage fraud and risk</li><li>Market our Services Personalize your experience</li><li>Provide personalized Services</li><li>Comply with Laws</li><li>Process information about your contacts</li><li>Communicate with you</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Authorities</li><li>Other financial institutions</li><li>Card Networks and Payment Processors</li><li>Fraud prevention and identity verification agencies</li><li>Credit Reporting Agencies</li><li>Service Providers, other</li><li>Other Users in accordance with account settings</li><li>Linked Accounts</li><li>Partners and Merchants</li><li>Other third parties for business purposes</li><li>Buyers in connection with a business transfer</li></ul></td></tr>
                <tr>
                  <td><p>Commercial information</p></td>
                  <td><ul><li>Provide Services</li><li>Communicate with you</li><li>Manage and improve our Services</li><li>Manage fraud and risk</li><li>Market our Services</li><li>Comply with Laws</li><li>Remember your preferences</li><li>Associate information about you</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Authorities</li><li>Other financial institutions</li><li>Partners and Merchants</li><li>Other third parties</li><li>Buyers or in connection with business transfer</li><li>Other Users if you have a business profile</li></ul></td></tr>
                <tr>
                  <td><p>Geolocation Data</p></td>
                  <td><ul><li>Send you location-based options</li><li>Manage fraud and risk</li></ul></td>
                  <td><ul><li>Authorities</li><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Other financial institutions</li><li>Partners and Merchants</li></ul></td></tr>
                <tr>
                  <td><p>Internet or network activity</p></td>
                  <td><ul><li>Operate and provide Services,</li><li>Communicate with you</li><li>Manage and improve Services</li><li>Market our Services</li><li>Send you locally relevant options</li><li>Comply with law</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Other financial institutions</li><li>Partners and Merchants</li><li>Authorities</li></ul></td></tr>
                <tr>
                  <td><p>Biometric</p></td>
                  <td><p> </p><p>Authenticate you for certain actions related to your account, including to:</p><ul><li>meet regulatory requirements or before you access accounts and services</li><li>recover passwords</li><li>update profile info</li><li>manage payments and payment methods</li><li>lift account limitations</li><li>initiate third-party crypto currency transfers</li></ul></td>
                  <td><ul><li>Service Providers</li></ul></td></tr>
                <tr>
                  <td><p>Audio, electronic, visual, or similar information</p></td>
                  <td><ul><li>Provide Services</li><li>Authenticate your access to an account or Fastlane profile</li><li>Manage and improve Services</li><li>Manage fraud and risk</li></ul></td>
                  <td><ul><li>Service Providers</li><li>PayPal and PayPal Companies</li><li>Merchants and Partners</li><li>Authorities</li></ul></td></tr>
                <tr>
                  <td><p>Professional or employment information</p></td>
                  <td><ul><li>Provide Services</li><li>Manage fraud and risk</li></ul></td>
                  <td><ul><li>Service Providers</li><li>PayPal and PayPal Companies</li><li>Merchants and Partners</li><li>Authorities</li><li>Other financial institutions</li></ul></td></tr>
                <tr>
                  <td><p>Information about your imported contacts</p></td>
                  <td><ul><li>Provide Services</li></ul></td>
                  <td><ul><li>Service Providers</li></ul></td></tr>
                <tr>
                  <td><p>Information in your PayPal or Xoom account or Fastlane profile</p></td>
                  <td><ul><li>Provide Services</li></ul></td>
                  <td><ul><li>Service Providers</li><li>PayPal and PayPal Companies</li><li>Merchants and Partners</li><li>Authorities</li><li>Other financial institutions</li></ul></td></tr>
                <tr>
                  <td><p>Information you provide when you contact us</p></td>
                  <td><ul><li>Provide Services</li><li>Authenticate your access to an account or Fastlane profile</li><li>Manage and improve Services</li><li>Manage fraud and risk</li></ul></td>
                  <td><ul><li>Service Providers</li><li>PayPal and PayPal Companies</li><li>Merchants and Partners</li><li>Authorities</li></ul></td></tr>
                <tr>
                  <td><p>Inferred data</p></td>
                  <td><ul><li>Provide Services</li><li>Manage fraud and risk</li><li>Market our Services</li><li>Display content based on your interest</li><li>Better respond to your requests or inquiries or for similar customer service issues</li><li>Verify your identity</li><li>Conduct risk assessment</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Other financial institutions</li><li>Merchants and Partners</li><li>Authorities</li></ul></td></tr>
                <tr>
                  <td><p>Sensitive Personal Information</p></td>
                  <td><ul><li>Provide Services</li><li>Manage fraud and risk</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Other financial institutions</li><li>Merchants and Partners</li><li>Authorities</li><li>Buyers or in connection with business transfer</li></ul></td></tr>
                <tr>
                  <td><p>Characteristics of Protected Classifications</p></td>
                  <td><ul><li>Provide our Services</li><li>Manage risk</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Authorities</li></ul></td></tr>
                <tr>
                  <td><p>Information from your device</p></td>
                  <td><ul><li>Provide Services</li><li>Manage and improve our Services</li><li>Manage fraud and risk</li><li>Communicate with you</li><li>Link an account</li><li>Market our Services</li><li>Personalize your experience</li><li>Comply with laws</li></ul></td>
                  <td><ul><li>PayPal and PayPal Companies</li><li>Service Providers</li><li>Other financial institutions</li><li>Merchants and Partners</li><li>Authorities</li></ul></td></tr></tbody>
              </table>
              <p><br/></p>
            </section></div></div>
      </section>


      <section id="14">
        <div>
          <h2>US Consumer Privacy Notice</h2>
          <div>
            <section>
          <p>The following Consumer Privacy Notice applies to you if you are an individual who resides in the United States and uses PayPal Services for your own personal, family, or household purposes.</p>
          <p>This Consumer Privacy Notice does not apply to Unbranded Payment Services.</p>
          <p>Rev. January 2023</p>
          <table className="agreement_table -bordered -auto">
            <tbody>
            <tr>
              <th>
                <p><strong>FACTS</strong></p>
              </th>
              <th>
                <p><strong>WHAT DOES PAYPAL DO WITH YOUR PERSONAL INFORMATION?</strong></p>
              </th>
            </tr>
            <tr><td>
            <p><strong>Why?</strong></p>
          </td><td>
            <p>Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.</p>
          </td></tr><tr><td>
            <p><strong>What?</strong></p>
          </td><td>
            <p>The types of personal information we collect and share depend on the product or service you have with us. This information can include:</p>
            <ul>
              <li>Social Security number and account balances</li>
              <li>Payment history or transaction history</li>
              <li>Credit history or credit scores</li>
            </ul>
            <p>When you are<span> </span><em>no longer</em><span> </span>our customer, we continue to share your information as described in this notice.</p>
          </td></tr><tr><td>
            <p><strong>How?</strong></p>
          </td><td>
            <p>All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons PayPal chooses to share; and whether you can limit this sharing.</p>
          </td></tr></tbody></table>
          <p> </p>
          <table className="agreement_table -bordered"><tbody><tr><th>
            <p><strong>Reasons we can share your personal information</strong></p>
          </th><th>
            <p><strong>Does PayPal share?</strong></p>
          </th><th>
            <p><strong>Can you limit this sharing?</strong></p>
          </th></tr><tr><td>
            <p><strong>For our everyday business purposes –</strong><br/>such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus</p>
          </td><td>
            <p>Yes</p>
          </td><td>
            <p>No</p>
          </td></tr><tr><td>
            <p><strong>For our marketing purposes –</strong><br/>to offer our products and services to you</p>
          </td><td>
            <p>Yes</p>
          </td><td>
            <p>No</p>
          </td></tr><tr><td>
            <p><strong>For joint marketing with other financial companies</strong></p>
          </td><td>
            <p>Yes</p>
          </td><td>
            <p>No</p>
          </td></tr><tr><td>
            <p><strong>For our affiliates’ everyday business purposes –</strong><br/>information about your transactions and experiences</p>
          </td><td>
            <p>Yes</p>
          </td><td>
            <p>No</p>
          </td></tr><tr><td>
            <p><strong>For our affiliates’ everyday business purposes –</strong><br/>information about your creditworthiness</p>
          </td><td>
            <p>No</p>
          </td><td>
            <p>We don’t share</p>
          </td></tr><tr><td>
            <p><strong>For our affiliates to market to you</strong></p>
          </td><td>
            <p>No</p>
          </td><td>
            <p>We don’t share</p>
          </td></tr><tr><td>
            <p><strong>For nonaffiliates to market to you</strong></p>
          </td><td>
            <p>No</p>
          </td><td>
            <p>We don’t share</p>
          </td></tr></tbody></table>
          <p> </p>
          <table className="agreement_table -bordered -auto"><tbody><tr><td>
            <p><strong>Questions?</strong></p>
          </td><td>
            <p>Call our customer service at 1-888-221-1161or visit us at<span> </span><span className="pseudo-link"><strong>/smarthelp/contact-us/privacy</strong></span>.</p>
          </td></tr></tbody></table>
          <p> </p>
          <table className="agreement_table -bordered -auto"><tbody><tr><td colSpan="2">
            <p><strong>Who we are</strong></p>
          </td></tr><tr><td colSpan="1">
            <p><strong>Who is providing this Privacy<span> </span><u>Statement</u>?</strong></p>
          </td><td>
            <p>This privacy notice is provided by PayPal, Inc. and is applicable to your personal U.S. PayPal and Xoom accounts, and Fastlane profile.</p>
          </td></tr><tr><td colSpan="2">
            <p><strong>What we do</strong></p>
          </td></tr><tr><td colSpan="1">
            <p><strong>How does PayPal protect my personal information?</strong></p>
          </td><td>
            <p>To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.</p>
          </td></tr><tr><td colSpan="1">
            <p><strong>How does PayPal collect my personal information?</strong></p>
          </td><td>
            <p>We collect your personal information, for example, when you</p>
            <ul>
              <li>open an account or provide account information</li>
              <li>use your credit or debit card or give us your contact information</li>
              <li>use your PayPal and/or Xoom account to send or receive funds</li>
            </ul>
            <p>We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.</p>
          </td></tr><tr><td colSpan="1">
            <p><strong>Why can’t I limit all sharing?</strong></p>
          </td><td>
            <p>Federal law gives you the right to limit only</p>
            <ul>
              <li>sharing for affiliates’ everyday business purposes — information about your creditworthiness</li>
              <li>affiliates from using your information to market to you</li>
              <li>sharing for nonaffiliates to market to you</li>
            </ul>
            <p>State laws and individual companies may give you additional rights to limit sharing. See below for more information on your rights under state law.</p>
          </td></tr><tr><td colSpan="2">
            <p><strong>Definitions</strong></p>
          </td></tr><tr><td colSpan="1">
            <p><strong>Affiliates</strong></p>
          </td><td>
            <p>Companies related by common ownership or control. They can be financial and nonfinancial companies.</p>
            <ul>
              <li>Our affiliates include Bill Me Later, Inc.</li>
            </ul>
          </td></tr><tr><td colSpan="1">
            <p><strong>Nonaffiliates</strong></p>
          </td><td>
            <p>Companies not related by common ownership or control. They can be financial and nonfinancial companies.</p>
            <ul>
              <li>Nonaffiliates with which we share personal information include service providers that perform services or functions on our behalf.</li>
            </ul>
          </td></tr><tr><td colSpan="1">
            <p><strong>Joint Marketing</strong></p>
          </td><td>
            <p>A formal agreement between nonaffiliated financial companies that together market financial products or services to you.</p>
            <ul>
              <li>Our joint marketing partners include financial companies and banks.</li>
            </ul>
          </td></tr><tr><td colSpan="2">
            <p><strong>Other important information</strong></p>
          </td></tr><tr><td colSpan="2">
            <p>We may transfer personal information to other countries, for example, for customer service or to process transactions.</p>
            <p><u>California:</u><span> </span>The California Consumer Privacy Act of 2018 (CCPA) permits consumers who are California residents to ask businesses covered under the CCPA about personal information it has collected about the consumer, submit an access or deletion request, and opt-out of the sale of personal information, if applicable. These provisions do not apply to personal information collected, processed, shared, or disclosed by financial institutions pursuant to federal law such as the Gramm-Leach-Bliley Act. Contact us if you have questions about our privacy statement, this consumer notice, or your personal information. If your PayPal or Xoom account or Fastlane profile has a California mailing address, we will not share personal information we collect about you except to the extent permitted under California law.</p>
            <p>To talk about your PayPal account, call PayPal Customer Service at 1-888-221-1161 or visit our<span> </span><span className="pseudo-link"><strong>Customer Service web portal</strong></span>. To talk about your Xoom account, call Xoom Customer Service at 1-877-815-1531 or visit our<span> </span><span className="pseudo-link"><strong>Help Center web portal</strong></span>. To talk about your Fastlane profile, call PayPal Customer Service at 1-844-705-3555 or visit your<strong><span> </span></strong><span className="pseudo-link">Fastlane profile management portal</span>.</p>
            <p><u>Vermont</u>: If your PayPal or Xoom account or Fastlane profile has a Vermont mailing address, We will not disclose information about your creditworthiness to our affiliates and will not disclose your personal information, financial information, credit report, or health information to nonaffiliated third parties to market to you, other than as permitted by Vermont law, unless you authorize us to make those disclosures. Additional information concerning our privacy policies can be found at<span> </span><span className="pseudo-link"><strong>Customer Service web portal</strong></span><span> </span>or call 1-888-221-1161</p>
            <p><u>Nevada</u>: If your PayPal or Xoom account or Fastlane profile has a Nevada mailing address, we are providing this notice pursuant to Nevada law. If you prefer not to receive marketing calls from PayPal, you may be placed on our internal Do Not Call List by calling 1-888-221-1161 or going to<span> </span><span className="pseudo-link"><strong>/smarthelp/home</strong></span>. For more information, you can contact PayPal at 12312 Port Grace Blvd, La Vista, NE 68128 or you can contact the Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington St., Suite 3900, Las Vegas, NV 89101; telephone number: 1-702-486-3132; email: Aginfo@ag.nv.gov</p>
          </td></tr>
          </tbody>
          </table>
        </section>
          </div>
        </div>
      </section>




    </div>
  );
}

export default Privacy;