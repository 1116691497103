import React from 'react';
import classnames from 'classnames';

const CheckMark = ({ className }) => (
  <div className={ classnames("checkmark-icon", className) }>
    <svg className={ `card-checkmark-svg` }>
      <use xlinkHref={ `#svg_card-checkmark` } />
    </svg>
  </div>
);

export default CheckMark;
