import {
  each, sumBy, values, map, keys, includes,
  find, filter
} from 'lodash';

const resultHash = {
  linesCount: 0,
  changePlanClickedCount: 0,
  changeLinesClickedCount: 0,
  clearCartClickedCount: 0,
  lines: {},
  get totalPrice() {
    return sumBy(
      values(this.lines),
      (item) => (item.planPrice || 0)
    );
  }
};

const setHashData = (path, value) => {
  const pathItems = path.split('.');
  let tempItem = resultHash;
  each(pathItems, (pathEl, index) => {
    if (index !== pathItems.length - 1) {
      if (!tempItem[pathEl]) {
        tempItem[pathEl] = {};
      }
      tempItem = tempItem[pathEl];
    } else { tempItem[pathEl] = value; }
  });
  // console.log('Set Hash Data', resultHash);
};

const getHashData = (path) => {
  const pathItems = path.split('.');
  let tempItem = resultHash, result = null;
  each(pathItems, (pathEl, index) => {
    if (!tempItem || !tempItem[pathEl]) {
      result = null;
      return false;
    } else if (index === pathItems.length - 1) {
      result = tempItem[pathEl];
      return false;
    }
    tempItem = tempItem[pathEl];
    return true;
  });
  return result;
};

const updateHashByKey = (hashKey) => {
  const prevValue = getHashData(hashKey) || 0;
  setHashData(hashKey, prevValue + 1);
};

let currentLine = 1;
const setCurrentLine = (val) => {
  currentLine = val;
};
const getCurrentLine = () => currentLine;

const setSetValue = (value) => {
  setHashData('setValue', value);
  setCurrentLine(1);
};

const setHashDataForTemplatePlan = (line, templatePlan, corePlan, perks) => {
  const templatePlanId = templatePlan?.id;
  let corePlanChanged = false;
  const removedPerkIds = [];
  const addedPerkIds = [];

  if (templatePlanId) {
    corePlanChanged = templatePlan.core.id !== corePlan.core.id;
    const templatePerkIds = map(templatePlan.perks, (el) => el.id);
    const selectedPerkIds = map(perks, (el) => el.id);
    each(
      templatePerkIds,
      (perkId) => {
        if (!includes(selectedPerkIds, perkId)) {
          removedPerkIds.push(perkId);
        }
      }
    );
    each(
      selectedPerkIds,
      (perkId) => {
        if (!includes(templatePerkIds, perkId)) {
          addedPerkIds.push(perkId);
        }
      }
    );
  }
  setHashData(`lines.${line}.planBuildYourOwnTemplatePlanId`, templatePlanId);
  setHashData(`lines.${line}.planBuildYourOwnCorePlanChanged`, corePlanChanged);
  setHashData(`lines.${line}.planBuildYourOwnRemovedPerkIds`, removedPerkIds);
  setHashData(`lines.${line}.planBuildYourOwnAddedPerkIds`, addedPerkIds);
};

const createLine = (id, plan = {}) => {
  setHashData(`lines.${id}.planId`, plan.id || null);
  setHashData(`lines.${id}.planTitle`, plan.title || '');
  setHashData(`lines.${id}.planPrice`, plan.price || 0);
  setHashData(`lines.${id}.planPerkIds`, map(plan.perks || []), ({ id }) => id);
  setHashData(`lines.${id}.planAdditionalPerkIds`, map(plan.perksAdditional || []), ({ id }) => id);
  setHashData(`lines.${id}.planSelectTime`, null);
  setHashData(`lines.${id}.planCustomBuilt`, false);
  setHashData(`lines.${id}.planUnlimitedSectionClickCount`, 0);
  setHashData(`lines.${id}.planWelcomeSectionClickCount`, 0);
  setHashData(`lines.${id}.planWelcomePopupContinueClickCount`, 0);
  setHashData(`lines.${id}.planWelcomePopupCancelClickCount`, 0);
  setHashData(`lines.${id}.planBaseFromWelcomePopupContinueClickCount`, 0);
  setHashData(`lines.${id}.planBaseFromWelcomePopupCancelClickCount`, 0);
  setHashData(`lines.${id}.planChangeClickedCount`, 0);
  setHashData(`lines.${id}.planBuildOwnLinkClickedCount`, 0);
  setHashData(`lines.${id}.planCustomizePlanEntertainmentClickedCount`, 0);
  setHashData(`lines.${id}.planCustomizePlanGamingClickedCount`, 0);
  setHashData(`lines.${id}.planCustomizePlanShoppingClickedCount`, 0);
  setHashData(`lines.${id}.planOurBestPlansTabClickedCount`, 0);
  setHashData(`lines.${id}.planBuildYourOwnTabClickedCount`, 0);
  setHashDataForTemplatePlan(id);
  setHashData(`lines.${id}.planClickExploreNonWelcomePlansCount`, 0);
  setHashData(`lines.${id}.planClickExploreWelcomePlanCount`, 0);
  setHashData(`lines.${id}.planClickSeeAllPlansNonWelcomePlansCount`, 0);
  setHashData(`lines.${id}.planClickSeeAllPlansWelcomePlanCount`, 0);
  setHashData(`lines.${id}.planClickChangeAddOnCount`, 0);
};

const setLinesCount = (value) => {
  setHashData('linesCount', value);
  // setCurrentLine(1);

  for (let i = 1; i <= value; i++) {
    if (!getHashData(`lines.${i}.planTitle`)) {
      createLine(i);
    }
  }

  // const lengthLines = Object.keys(resultHash.lines).length;
  while (value < keys(resultHash.lines).length) {
    // eslint-disable-next-line prefer-reflect
    delete resultHash.lines[keys(resultHash.lines).length];
  }
};
const getLinesCount = () => getHashData('linesCount');

const setPlan = (plan, line) => {
  const planName = plan?.core?.title ? plan.core.title : plan.name;
  setHashData(`lines.${line}.planId`, plan.id);
  setHashData(`lines.${line}.planTitle`, planName);
  setHashData(`lines.${line}.planPrice`, plan.price);
};

const setLinePrice = (price, line) => {
  setHashData(`lines.${line}.planPrice`, price);
};

const updateLinePrices = (priceForLine) => {
  each(values(resultHash.lines), (item, index) => {
    item.planPrice = priceForLine(item.planId, index);
  });
};

const getSelectedPlans = () => map(
  values(resultHash.lines),
  (item) => ({
    id: item.planId,
    title: item.planTitle,
    price: item.planPrice
  })
);

const getSelectedPlanIdByLine = (line) => {
  return resultHash.lines[line].planId;
};

const getTotal = () => resultHash.totalPrice;
const sendData = (customData = {}) => {
  const finalData = {
    ...resultHash,
    lines: values(resultHash.lines)
  };
  const postData = {
    ...(customData || {}),
    ...{
      kind: 'finishPlans',
      result: finalData
    }
  };

  (window.parent || window).postMessage(postData, "*");

  console.log('Finish Plans', finalData);
};

const removeHashLine = (lineNumber) => {
  // eslint-disable-next-line prefer-reflect
  delete resultHash.lines[lineNumber];
  const newLinesHash = {};
  each(values(resultHash.lines), (line, index) => {
    newLinesHash[index + 1] = line;
  });
  resultHash.lines = newLinesHash;
  setHashData('linesCount', (getLinesCount() - 1));
};

const getCurrentLineHash = (customLine) => (resultHash.lines[customLine || currentLine]);

const getCustomBuiltDataFromCurrentLine = (plansPrebuild, plansConstruct, perksList, customLine = null) => {
  const line = customLine || getCurrentLine();
  const {
    planId, planPerkIds, planAdditionalPerkIds, planBuildYourOwnTemplatePlanId
  } = getHashData(`lines.${line}`) || {};
  const corePlan = find(plansConstruct || [], (item) => item.id === planId)?.core;
  return {
    templatePlan: find(plansPrebuild || [], (item) => item.id === planBuildYourOwnTemplatePlanId),
    prebuildPlan: find(plansPrebuild || [], (item) => item.id === planId),
    corePlan,
    perks: corePlan ? filter(perksList || [], (item) => includes(planPerkIds || [], item.id)) : null,
    perksAdditional: corePlan ? filter(perksList || [], (item) => includes(planAdditionalPerkIds || [], item.id)) : null
  };
};

const anyLineContainsHybridPlan = (plansPrebuild, plansConstruct) => {
  return find(
    resultHash.lines || {},
    (lineItem) => find(
      [ ...(plansConstruct || []), ...(plansPrebuild || []) ],
      (item) => item.id === lineItem.planId
    )
  );
};

const isLinesFilled = () => Object.keys(resultHash.lines)
  .reduce((acc, idx) => acc && resultHash.lines[idx].planId !== null, true);

const increaseVariable = (key, line = null) => {
  const hashKey = line ? `lines.${line}.${key}` : key;
  const count = getHashData(hashKey);
  setHashData(hashKey, count ? count + 1 : 1);
};

export {
  setLinesCount,
  getLinesCount,
  setCurrentLine,
  getCurrentLine,
  setHashData,
  getHashData,
  updateHashByKey,
  setPlan,
  setHashDataForTemplatePlan,
  getCustomBuiltDataFromCurrentLine,
  anyLineContainsHybridPlan,
  createLine,
  getSelectedPlans,
  getTotal,
  isLinesFilled,
  sendData,
  setSetValue,
  getSelectedPlanIdByLine,
  updateLinePrices,
  removeHashLine,
  getCurrentLineHash,
  setLinePrice,
  increaseVariable
};
