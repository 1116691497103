import React, { useContext } from "react";
import { map } from "lodash";
import classnames from "classnames";
import { showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { IndexData } from '../../../index-context';

const TrRanking = ({ checkedConcepts }) => {
  const { init, data, averageMode, sort } = useContext(IndexData);

  const hashTop3 = data.analytics[data.titles.rank_top3];
  const hash1 = data.analytics[data.titles.rank1];
  const hash2 = data.analytics[data.titles.rank2];
  const hash3 = data.analytics[data.titles.rank3];

  const pTop3Text = showedAverageValue(hashTop3, averageMode, false, init.isInternal, data.view);
  const p1Text = showedAverageValue(hash1, averageMode, false, init.isInternal, data.view);
  const p2Text = showedAverageValue(hash2, averageMode, false, init.isInternal, data.view);
  const p3Text = showedAverageValue(hash3, averageMode, false, init.isInternal, data.view);

  return (
    <>
      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': data.titles.rank_top3 === sort }) }>
            { data.titles.rank_top3 }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td key={ `ranking_list_${concept.id}` } className="-js-n">
              {data.ranking[concept.id].has_ranking && <StatusCircle values={ hashTop3[concept.id] } box="top2" />}
              {!data.ranking[concept.id].has_ranking && <span>-</span>}
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { pTop3Text }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': data.titles.rank1 === sort }) }>
            { data.titles.rank1 }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td key={ `ranking_list_${concept.id}` } className="-js-n">
              {data.ranking[concept.id].has_ranking && <StatusCircle values={ hash1[concept.id] } box="top2" />}
              {!data.ranking[concept.id].has_ranking && <span>-</span>}
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { p1Text }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': data.titles.rank2 === sort }) }>
            { data.titles.rank2 }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td key={ `ranking_list_${concept.id}` } className="-js-n">
              {data.ranking[concept.id].has_ranking && <StatusCircle values={ hash2[concept.id] } box="top2" />}
              {!data.ranking[concept.id].has_ranking && <span>-</span>}
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { p2Text }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell">
          <span className={ classnames({ 'sort-link -current': data.titles.rank3 === sort }) }>
            { data.titles.rank3 }
          </span>
        </td>
        {
          map(checkedConcepts, (concept) => (
            <td key={ `ranking_list_${concept.id}` } className="-js-n">
              {data.ranking[concept.id].has_ranking && <StatusCircle values={ hash3[concept.id] } box="top2" />}
              {!data.ranking[concept.id].has_ranking && <span>-</span>}
            </td>
          ))
        }
        <td className="-js-n table-comparison_average-cell">
          { p3Text }
        </td>
      </tr>
    </>
  );
};

export default TrRanking;
