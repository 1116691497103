import React from "react";
import Popup from "reactjs-popup";
import cn from "classnames";

const ThImagePopup = ({ concept, openScorecardModal }) => {
  const trigger = (
    <div
      className={ cn("table-comparison_concept -concept-card", `js-${concept.id}`) }
      onClick={ openScorecardModal }
    >
      <div className="concept-preview table-comparison_concept-preview">
        <div
          className="concept-preview_thumb -less-rounded"
          style={ { 'backgroundImage': `url(${concept.image_url})` } }
        />
      </div>
      <div className="table-comparison_concept-name -max-comparison-width link -decorated">
        {concept.title}
      </div>
    </div>
  );

  return (
    <Popup
      trigger={ trigger }
      position="top center"
      on={ [ 'hover', 'focus' ] }
      className="th-image-popup"
      mouseEnterDelay={ 1 }
      mouseLeaveDelay={ 1 }
      offsetY={ -114 }
    >
      <div className="-contains-image in">
        <div className="tooltip-inner">
          <img className="concept-preview_pic-big" src={ concept.image_url } />
          <div className="button-place">
            <button className="image-preview" onClick={ openScorecardModal }>
              View Report
            </button>
          </div>
        </div>
      </div>

    </Popup>
  );
};

export default ThImagePopup;
