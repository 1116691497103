import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import remoteFiles from '../../../common/remote_files';

// usage <InfoPopup opened={ true } onClose={ (event) => {} } />

const ModalWindow = ({ style, backStyle, children }) => {
  const body = document.body || document.querySelector("body");

  const [ bodyOverflow ] = useState(body.style.overflow);
  const [ element ] = useState(document.createElement('div'));
  const [ backElement ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = "modal fade in";
    body.appendChild(element);

    backElement.className = "modal-backdrop fade in";
    body.appendChild(backElement);

    body.style.overflow = 'hidden';

    return () => {
      body.removeChild(element);
      body.removeChild(backElement);
      body.style.overflow = bodyOverflow;
    };
  }, []);
  return createPortal(children, element);
};

const InfoPopup = ({ opened, onClose }) => {
  const { popupImageApp, popupInfoIcon } = remoteFiles.verizon.tv;
  return (
    <>
      {
        opened &&
        <ModalWindow>
          <div className="popup-content">
            <div className="cross" onClick={ onClose } />
            <div className="inner-content">
              <div className="scrollable-content">
                <h3>With these devices you have 2 ways to connect.</h3>
                <h4>Use your own streaming device.</h4>
                <p>
                  The Fios TV Home App<img className="info-img-icon" src={ popupInfoIcon } alt="" />
                  {' '}
                  allows you to connect your Fios TV via an Apple TV or Fire TV device.
                  Enjoy the convenience of familiar interfaces, less hardware and fewer wires.
                </p>
                <img src={ popupImageApp } width="100%" alt="alt" />
                <h5>These are the devices that are currently compatible.</h5>
                <ul>
                  <li>Apple TV 4th Gen.</li>
                  <li>Apple TV 4K 5th Gen.</li>
                  <li>Fire TV Stick 4K 3rd Gen.</li>
                  <li>Fire TV Cube 1st & 2nd Gen.</li>
                </ul>
                <h4>Or you can still use a set-top box.</h4>
                <p>
                  You can still use a set-top box to watch your fios TV plan
                  {' '}
                  and watch your content on FIos TV One with features like
                  {' '}
                  Voice remote and 4K UHD picture quality.
                </p>
              </div>
              <div className="buttons">
                <button className="button" onClick={ onClose }>Got it</button>
              </div>
            </div>
          </div>
        </ModalWindow>
      }
    </>
  );
};

export default InfoPopup;
