import React, { useState } from 'react';
import cn from 'classnames';

const AnswerAdditionalLine = ({ title, bool, text, onChange }) => {
  const [ focusState, setFocusState ] = useState(false);

  const handleOnChange = (newBool, newText) => {
    onChange(newBool, newText);
  };

  return (
    <li className="form-question_answer">
      <div className="form-question_grid">
        <div className="form-question_grid-item -first-answer-item">
          <div className="form-question_answer-move">
            <div className="checkbox">
              <label className={ cn("checkbox_label -for-additional-answer -for-any", { '-checked': bool }) }>
                <input
                  type="checkbox"
                  checked={ bool }
                  onChange={ (event) => { handleOnChange(event.target.checked, text); } }
                />
              </label>
            </div>
          </div>
        </div>

        <div
          className={
            cn(
              "form-question_grid-item -grow -with-custom-placeholder",
              { '-disabled': !bool }
            )
          }
        >
          <input
            className="form-field -block -js-text"
            type="text"
            value={ text || '' }
            onChange={ (event) => { handleOnChange(bool, event.target.value); } }
            disabled={ !bool }
            placeholder={ title }
            onFocus={ () => { setFocusState(true); } }
            onBlur={ () => { setFocusState(false); } }
          />
          {
            (!!text || focusState) &&
            <div className="custom-placeholder-label">
              Substitute for &quot;{ title }&quot;
            </div>
          }
        </div>

        <div className="form-question_grid-item -buttons" />
      </div>
    </li>
  );
};

export default AnswerAdditionalLine;
