import React, { useState } from 'react';
import { map, values, trim, each, compact } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';
import { findOrInitLoop } from '../../logic/loop';
import ZoomImage from '../zoom_image';

const AttributesList = ({
  title, orCopy, concept, questions, result, nextStep
}) => {
  const initAnswers = () => {
    const res = {};
    each(questions, (question) => {
      each(question.answers, (answer) => {
        res[answer.id] = 0;
      });
    });
    return res;
  };
  const [ answers, setAnswers ] = useState(initAnswers);

  const disabledOption = () => {
    const hash = {};
    const res = compact(values(answers));
    if (res.length !== questions[0].answers.length) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswers = () => {
    findOrInitLoop(concept.confirmit_concept_id, result.loops, map(answers, (v, k) => `${k}_${v}`));
    setAnswers(initAnswers());
    nextStep();
  };

  const select = (answerId, responseId, orItem) => {
    if (orItem) {
      return;
    }
    setAnswers((oldHash) => (
      { ...oldHash, ...{ [answerId]: responseId } }
    ));
  };

  const splitItems = (label) => {
    const splitResult = (label || '').split('||');
    return map(
      [ splitResult[0], orCopy, splitResult[1] ],
      (phrase, index) => {
        let responseId = 0;
        if (!index) {
          responseId = 1;
        } else if (index === 2) {
          responseId = 2;
        }
        return {
          responseId,
          label: trim(phrase)
        };
      }
    );
  };

  const selected = (answerId, responseId) => (
    answers[answerId] === responseId
  );

  const isOr = (index) => (
    index === 1
  );

  return (
    <>
      <div className="survey-header">
        <div className="survey-header_title" { ...renderRawHtml(title) } />
      </div>
      <div className="survey-layout_content">
        <div className="survey-layout_container -attributes-list">
          <div className="survey-options -flex">
            <div className="survey-options_image-plate">
              <ZoomImage
                className="survey-options_image"
                src={ concept.url }
              >
                <img src={ concept.url } />
              </ZoomImage>
            </div>
            <div className="survey-attributes-plate">
              {
                map(questions, (question, index) => (
                  <ul
                    className="survey-attributes-list"
                    key={ `Question${index}${question.name}` }
                  >
                    {
                      map(question.answers, (answer, index) => (
                        <li
                          key={ `Answer${index}${answer.id}` }
                          className="survey-attribute-item"
                        >
                          {
                            map(splitItems(answer.label), (itemPart, index) => (
                              <div
                                className={
                                  classnames(
                                    "survey-attribute-part",
                                    { '-or': isOr(index) },
                                    { '-selected': selected(answer.id, itemPart.responseId) }
                                  )
                                }
                                onClick={ () => { select(answer.id, itemPart.responseId, isOr(index)); } }
                                key={ `AnswerPart${answer.id}${index}` }
                                { ...renderRawHtml(itemPart.label) }
                              />
                            ))
                          }
                        </li>
                      ))
                    }
                  </ul>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswers(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default AttributesList;
