import React, { useState, useContext, useEffect } from 'react';
import { map, filter } from 'lodash';
import { BaseContext } from '../../contexts';
import { getUserRecents } from '../../requests';
import ProjectSetup from './projectSetup';
import ReviewRespondents from './reviewRespondents';
import ConceptTags from './conceptTags';
import SurveyFlow from './surveyFlow';
import Report from './report';
import ProjectTitle from './projectTitle';

const Recents = () => {
  const {
    controllerName, actionName, currentProject, paths, projectsCount,
    initialData, platform
  } = useContext(BaseContext);
  const [ userRecents, setUserRecents ] = useState([]);
  const selectedProjectEditPath = paths.selected_project_edit;
  const userRecentsParams = initialData.recent_request_params;
  useEffect(() => {
    const recentsPath = paths.recents;
    getUserRecents(recentsPath, userRecentsParams, (response) => {
      setUserRecents(response.data);
    });
  }, []);

  const notMultilangOrNotMLOrNotShow = (
    !currentProject.multilang || controllerName !== 'ml_projects' || actionName !== 'show'
  );

  const filteredRecents = filter(
    userRecents,
    (item) => item.id !== (currentProject.id || 0)
  );

  return (
    <>
      {
        projectsCount > 0 &&
        <nav className="sidebar_nav">
          <h4 className="sidebar_nav-title">Recent</h4>
          <ul className="sidebar_nav-list">
            {
              currentProject.selected &&
              <li className="sidebar_nav-list-item">
                <ProjectTitle
                  active
                  href={ selectedProjectEditPath }
                  express={ currentProject.express }
                  title={ currentProject.title }
                />
                {
                  notMultilangOrNotMLOrNotShow &&
                  <ul className="sidebar_nav-list -sub">
                    <ProjectSetup />
                    <ReviewRespondents />
                    {
                      platform !== 'Verizon Customer Case V2024' &&
                      <>
                        <ConceptTags />
                      </>
                    }
                    <SurveyFlow />
                    <Report />
                  </ul>
                }
              </li>
            }
            {
              map(filteredRecents, (recent) => (
                <li key={ `recent-${recent.id}` }>
                  <ProjectTitle
                    href={ recent.concept_comparison_link }
                    express={ recent.project_express }
                    title={ recent.title }
                    projectId={ recent.id }
                  />
                </li>
              ))
            }
          </ul>
        </nav>
      }
    </>
  );
};

export default Recents;
