import React, { useState, useRef, useContext, useEffect } from "react";
import { map, forEach, filter, countBy, find, keys } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";
import Http from "../../../../common/http";
import { IndexData } from "../index-context";
import NoData from "./no-data";
import WithDataProject from "./with-data-project";
import { checkTimeout, checkResult } from "./http-helpers";
import Working from "./working";

const IndexProjectContent = () => {
  const [ working, setWorking ] = useState(false);
  const [ disabled, setDisabled ] = useState(false);

  const [ attempt, setAttempt ] = useState(0);
  const attemptRef = useRef(attempt);
  attemptRef.current = attempt;

  const { aiSummaries, setAiSummaries, data } = useContext(IndexData);

  const firstLoadRef = useRef(true);
  useEffect(() => {
    if (aiSummaries !== null && firstLoadRef.current) {
      firstLoadRef.current = false;

      const checkIds = map(
        filter(aiSummaries, (el) => (el.jid)),
        (el) => ({ id: el.id, jid: el.jid })
      );

      if (checkIds.length) {
        setWorking(true);
        setAttempt(0);
        checkResult(checkIds, setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking);
      }
    }
  }, [ aiSummaries ]);

  const runAI = (updateAll) => {
    setWorking(true);
    setAttempt(0);

    Http.post(
      '/express/api/ai_summaries/for_project',
      {
        "project_id": data.project_id,
        "update_all": updateAll
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          if (http.data.length > 0) {
            // eslint-disable-next-line lodash/prefer-filter
            forEach(http.data, (record) => {
              const summary = find(aiSummaries, (el) => (el.id === record.id));

              if (summary) {
                summary.jid = record.jid;
              } else {
                aiSummaries.push(record.record);
              }
            });
            setAiSummaries([ ...aiSummaries ]);
          }

          setTimeout(() => {
            checkResult(http.data, setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking);
          }, checkTimeout);
        } else {
          setWorking(false);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const updateSummary = () => {
    runAI(true);
  };

  const onChangeAddToReport = (newChecked) => {
    setDisabled(true);
    Http.post(
      "/express/api/ai_summaries/add_to_report",
      {
        project_id: data.project_id,
        add_to_report: newChecked
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          forEach(aiSummaries, (el) => {
            el.add_to_report = newChecked;
          });
          setAiSummaries([ ...aiSummaries ]);
        }
        setDisabled(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const checked = aiSummaries && !!aiSummaries.length && filter(aiSummaries, (el) => (el.add_to_report)).length === aiSummaries.length;
  const summariesForConcept = filter(aiSummaries, (item) => (!!item.database_concept_id));
  const summariesForProject = filter(aiSummaries, (item) => (!item.database_concept_id));
  const countForConcepts = keys(countBy(summariesForConcept, (el) => (el.database_concept_id))).length;
  const countForProject = summariesForProject.length;

  return (
    <div className="summary-panel comparisons_plate -js-project -mt-20">
      <SvgIcon name="scheme" />
      { working && <Working stubText="Working on a summary. May need some time..." />}

      {!working && aiSummaries && !aiSummaries.length &&
        <NoData
          runAI={ () => {runAI(false);} }
          text="Get AI Summary for all items"
        />
      }

      {!working && aiSummaries && !!aiSummaries.length &&
        <WithDataProject
          countForConcepts={ countForConcepts }
          countForProject={ countForProject }
          checked={ checked }
          handleOnChange={ onChangeAddToReport }
          handleUpdateSummary={ updateSummary }
          disabled={ disabled }
        />
      }
    </div>
  );
};

const IndexProject = () => {
  const { aiSummariesPlacesCount, aiSummaryAvailable } = useContext(IndexData);
  return (
    <>
      {aiSummaryAvailable && !!aiSummariesPlacesCount && <IndexProjectContent />}
    </>
  );
};

export default IndexProject;

