import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import renderRawHtml from '../../../../../../../../common/render_raw_html';
import { LocalizationContext } from '../../common/contexts';

const CantFindPortal = ({ children, className }) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = className;
    body.appendChild(element);
    return () => {
      body.removeChild(element);
    };
  }, []);
  return createPortal(children, element);
};

const CantFindProduct = ({ onCantFind }) => {
  const localization = useContext(LocalizationContext);
  const {
    cantFind,
    wantToGiveUp,
    wouldToContinue,
    cantFindProduct
  } = localization;
  const [ popupVisibility, setPopupVisibility ] = useState(false);
  const togglePopupVisibility = () => setPopupVisibility(!popupVisibility);
  return (
    <div className="zoom-area_give-up_wrapper">
      <button
        type="button"
        className="button -warning -height-44"
        onClick={ togglePopupVisibility }
        { ...renderRawHtml(cantFind) }
      />
      {
        popupVisibility &&
        <CantFindPortal className="zoom-area_give-up_popup-backdrop">
          <div className="zoom-area_give-up_popup-wrapper">
            <div className="zoom-area_give-up_popup">
              <span
                className="zoom-area_give-up_popup-title"
                { ...renderRawHtml(wantToGiveUp) }
              />
              <div className="zoom-area_give-up_popup-button-wrapper">
                <button
                  type="button"
                  className="button -full-width -height-44 -allow-wrap"
                  onClick={ togglePopupVisibility }
                  { ...renderRawHtml(wouldToContinue) }
                />
              </div>
              <div className="zoom-area_give-up_popup-button-wrapper">
                <button
                  type="button"
                  className="button -warning -full-width -height-44 -allow-wrap"
                  onClick={ onCantFind }
                  { ...renderRawHtml(cantFindProduct) }
                />
              </div>
            </div>
          </div>
        </CantFindPortal>
      }
    </div>
  );
};

export default CantFindProduct;
