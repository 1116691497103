import { useEffect } from 'react';
import { each } from 'lodash';

const useTransform = ({
  dataAreas,
  min, max, step,
  getScale, setScale,
  getShelfPosition, setShelfPosition,
  getAreaPositions, setAreaPositions,
  getContentWrapperStyles, setContentWrapperStyles,
  getWrapperStyles, setWrapperStyles,
  contentRef, shelfWrapperRef, shelfRef
}) => {
  const zoomIn = () => {
    const current = getScale() + step;
    if (current < max + step) {
      setScale(parseFloat(current.toFixed(2), 10));
    }
  };

  const zoomOut = () => {
    const current = getScale() - step;
    if (current > min - step) {
      setScale(parseFloat(current.toFixed(2), 10));
    }
  };

  const setRectPositions = () => {
    if (!shelfRef || !contentRef || !shelfRef.current || !contentRef.current) {
      return;
    }
    shelfWrapperRef.current.style = "";
    const scale = getScale();

    // Image
    const contentRect = contentRef.current.getBoundingClientRect();
    const naturalWidth = shelfRef.current.naturalWidth;
    const naturalHeight = shelfRef.current.naturalHeight;
    let newImageWidth = naturalWidth;
    let newImageHeight = naturalHeight;
    let marginLeft = 0;
    let marginTop = 0;
    const newImageStyle = {};

    if (naturalWidth > contentRect.width) {
      newImageWidth = contentRect.width;
      newImageHeight = naturalHeight * (newImageWidth / naturalWidth);
      if (newImageHeight > contentRect.height) {
        newImageHeight = contentRect.height;
        newImageWidth = naturalWidth * (newImageHeight / naturalHeight);
      }
    } else if (naturalHeight > contentRect.height) {
      newImageHeight = contentRect.height;
      newImageWidth = naturalWidth * (newImageHeight / naturalHeight);
      if (newImageWidth > contentRect.width) {
        newImageWidth = contentRect.width;
        newImageHeight = naturalHeight * (newImageWidth / naturalWidth);
      }
    }

    const scaledWidth = newImageWidth * scale;
    if (scaledWidth < contentRect.width) {
      marginLeft = (contentRect.width / 2) - (scaledWidth / 2);
    }
    const scaledHeight = newImageHeight * scale;
    if (scaledHeight < contentRect.height) {
      marginTop = (contentRect.height / 2) - (scaledHeight / 2);
    }

    setContentWrapperStyles({ marginLeft, marginTop });

    setShelfPosition({
      width: newImageWidth,
      height: newImageHeight,
      style: newImageStyle
    });

    // Area
    const areas = [];
    each(dataAreas, (area) => {
      const { left, top, width, height, imageWidth, imageHeight } = area;
      const proportionX = newImageWidth / imageWidth;
      const proportionY = newImageHeight / imageHeight;
      areas.push(
        {
          ...area,
          ...{
            left: left * proportionX,
            top: top * proportionY,
            width: width * proportionX,
            height: height * proportionY
          }
        }
      );
    });
    setAreaPositions(areas);

    // Scale
    if (scale > 1) {
      const scaleAction = `scale(${scale}, ${scale})`;
      const initBox = shelfWrapperRef.current.getBoundingClientRect();
      shelfWrapperRef.current.style = `transform: ${scaleAction}`;
      const scaleBox = shelfWrapperRef.current.getBoundingClientRect();
      shelfWrapperRef.current.style = "";
      const widthScale = scaleBox.width / initBox.width;
      const heightScale = scaleBox.height / initBox.height;
      const translateX =  -(scaleBox.left / widthScale) + (initBox.left / widthScale);
      const translateY = -(scaleBox.top / heightScale) + (initBox.top / heightScale);
      const translateAction = `translate(${translateX}px, ${translateY}px)`;
      setWrapperStyles({ transform: `${scaleAction} ${translateAction}` });
    } else {
      setWrapperStyles({});
    }
  };

  const getRectPositions = () => (getAreaPositions());

  useEffect(() => {
    setRectPositions();
  }, [ getScale() ]);

  const onResize = () => {
    setScale(1);
    setRectPositions();
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('orientationchange', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('orientationchange', onResize);
    };
  }, []);

  return {
    zoomIn, zoomOut,
    min, max, step,
    getScale, setScale,
    getShelfPosition, getWrapperStyles,
    getContentWrapperStyles,
    setRectPositions, getRectPositions,
    contentRef
  };
};

export default useTransform;
