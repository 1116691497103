import React, { useContext } from 'react';
import { LangData } from '../../lang-data';
import { IndexData } from "../../index-context";
import AnswerPlayControl from "./answer-play-control";
import AnswerHideControl from './answer-hide-control';
import AnswerPinControl from "./answer-pin-control";

const Sentiment = ({ conceptId, metric, answer, isAdmin }) => {
  const { answerByLanguage } = useContext(LangData);
  const { init } = useContext(IndexData);

  return (
    <li className="sentiments-list_item sentiment">
      <AnswerPlayControl conceptId={ conceptId } answer={ answer } projectId={ init.project.id } gaTrackingId={ init.google_analytics_id } />

      <div className="sentiment_content">
        <span className="answer-block -sm">
          <span className="answer-block_txt">{answerByLanguage(answer)}</span>
          <span className="answer-block_author">
            {answer.respondent.signature}
          </span>
        </span>
      </div>

      {isAdmin && <AnswerHideControl conceptId={ conceptId } metric={ metric } answer={ answer } projectId={ init.project.id } gaTrackingId={ init.google_analytics_id } />}

      <AnswerPinControl conceptId={ conceptId } metric={ metric } answer={ answer } projectId={ init.project.id } gaTrackingId={ init.google_analytics_id } />
    </li>
  );
};

export default Sentiment;
