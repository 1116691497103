const expressHowItWorksHash = (baseUrl) => {
  const url = [ baseUrl, "package_how_it_works" ].join("/");
  return {
    image1: `${url}/upload_no_image.png`,
    image2: `${url}/upload_previous_button.png`,
    image3: `${url}/upload_first.png`
  };
};

export default expressHowItWorksHash;
