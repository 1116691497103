import React, { useEffect, useState } from 'react';
import rangy from 'rangy';
import { each } from 'lodash';
import { BaseColorPickerContext } from '../contexts';
import colorPickerImage from './iconBase64';
import ColorChanger from './colorChanger';

const BackgroundClicks = ({ onBackClick }) => {
  const body = document.body || document.querySelector("body");
  useEffect(() => {
    body.addEventListener('click', onBackClick);
    return () => {
      body.removeEventListener('click', onBackClick);
    };
  }, []);
  return (<></>);
};

const Index = ({
  currentState, onChange, doCollapse, expanded, onExpandEvent
}) => {
  const { color, bgColor } = currentState;
  const [ complexExpanded, setComplexExpanded ] = useState(expanded);
  const [ , setInnerClick ] = useState(false);
  const [ initialSelRanges, setInitialSelRanges ] = useState([]);

  const getInitialRanges = () => {
    const selection = rangy.getSelection();
    const ranges = [];
    for (let i = 0; i < selection.rangeCount; i++) {
      ranges.push(selection.getRangeAt(i));
    }
    setInitialSelRanges(ranges);
  };

  const updateRanges = () => {
    if (!initialSelRanges.length) {
      return;
    }
    const selection = rangy.getSelection();
    selection.removeAllRanges();
    each(initialSelRanges, (el) => {
      selection.addRange(el);
    });
  };

  const handleApply = (event, kind, hexColor) => {
    event.stopPropagation();
    onChange(kind, hexColor);
    setComplexExpanded(false);
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    doCollapse();
    setComplexExpanded(false);
    updateRanges();
  };

  const handleOnClick = (event) => {
    event.stopPropagation();
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
    if (!complexExpanded) {
      getInitialRanges();
      return;
    }
    setInnerClick(true);
  };

  const handleMouseUp = (event) => {
    event.stopPropagation();
    if (!complexExpanded) {
      return;
    }
    setInnerClick(false);
  };

  const handleBackClick = (event) => {
    event.stopPropagation();
    let clickedInner;
    setInnerClick((val) => {
      clickedInner = val;
      return false;
    });
    if (!clickedInner) {
      handleCancel(event);
    } else {
      updateRanges();
    }
  };

  const handleOpenClick = (event) => {
    event.stopPropagation();
    onExpandEvent(event);
    setComplexExpanded(true);
    updateRanges();
  };

  const baseColorPickerContextValue = {
    updateRanges
  };

  return (
    <BaseColorPickerContext.Provider value={ baseColorPickerContextValue }>
      <div
        onClick={ handleOnClick }
        onMouseDown={ handleMouseDown }
        onMouseUp={ handleMouseUp }
      >
        <div
          className="rdw-option-wrapper -select-color"
          onClick={ handleOpenClick }
        >
          <img src={ colorPickerImage } alt="" />
        </div>
        {
          complexExpanded &&
            <div className="color-picker-expanded">
              <BackgroundClicks onBackClick={ handleBackClick } />
              <div className="color-picker-positioning">
                <ColorChanger
                  initColor={ color }
                  initBgColor={ bgColor }
                  onApply={ handleApply }
                  onCancel={ handleCancel }
                />
              </div>
            </div>
        }
      </div>
    </BaseColorPickerContext.Provider>
  );
};

export default Index;
