import React from 'react';

export const Subheader = () => {
  return (
    <div className="concept-subheader">
      <h2 className="concept-subheader-primary">Let’s choose a plan for your line.</h2>
      <h6 className="concept-subheader-secondary">
        Only pay for what you need and get more of the entertainment you want. Choose your Unlimited
        plans to mix, match and save.
      </h6>
      <span className="concept-subheader-additional">
        Plan prices reflect your $10/mo paper-free billing and Auto Pay discount.
        <span className="icon_info">
          <svg><use xlinkHref="#svg_info" /></svg>
        </span>
      </span>
    </div>
  );
};
