import React, { useContext } from "react";
import { map } from "lodash";
import classnames from 'classnames';
import { showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { IndexData, selectKind } from '../../../index-context';

const ShelfBaseComparisonProducts = ({ current, sectionTitle, kindName }) => {
  const { init, data, averageMode } = useContext(IndexData);

  const productsHashes = selectKind(data, kindName);

  return (
    <>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 3 }>
          <div className="table-comparison_title">
            { sectionTitle }
          </div>
        </td>
        <td className="table-comparison_average-cell">
          Avg
        </td>
      </tr>
      {
        map(productsHashes, (product, sortTitle) => (
          <tr key={ sortTitle }>
            <td
              className={
                classnames(
                  "table-comparison_name-cell",
                  { "table-comparison_bold-text": product.target }
                )
              }
              colSpan={ 2 }
            >
              { product.name } { product.target && <span>({ data.titles.main })</span> }
            </td>
            <td className="-js-n">
              <StatusCircle values={ product[current.id] } box="top2" noLetters />
            </td>
            <td className="table-comparison_average-cell">
              { showedAverageValue(product, averageMode, false, init.isInternal, data.view) }
            </td>
          </tr>
        ))
      }
    </>
  );
};

export default ShelfBaseComparisonProducts;
