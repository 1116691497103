import React, { Fragment, useContext } from 'react';
import { map } from 'lodash';
import cn from "classnames";
import StatusCircle from "../../components/status-circle";
import { getMetricKind, IndexData } from "../../index-context";
import { hideCustom, showedAverageValue } from '../../helpers';

const TrAttributeRatings = ({
  data, checkedConcepts, attributeRatings, groupName
}) => {
  const { sort, averageMode, init } = useContext(IndexData);

  return (
    <>
      <tr className="-highlighted">
        <td
          colSpan={ checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1 }
        >
          { getMetricKind(data, groupName) }
        </td>
        {
          checkedConcepts.length > 3 &&
          <td colSpan={ checkedConcepts.length + 1 - 3 } />
        }
        <td className="table-comparison_average-cell -highlighted" />
      </tr>

      {
        map(attributeRatings, (values, title) => (
          <Fragment key={ title }>
            <tr>
              <td rowSpan="2" className="table-comparison_name-cell">
                <span className={ cn({ 'sort-link -current': title === sort }) }>
                  {values.real_attribute_title || title}
                </span>
              </td>
              {
                map(checkedConcepts, (concept, index) => (
                  <td key={ `attributeT2B${title}${concept.id}${index}` }>
                    {!index && <div className="table-comparison_box-cell">T2B</div>}
                    <StatusCircle
                      values={ values[concept.id] }
                      box="top2"
                      hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                    />
                  </td>
                ))
              }
              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
              </td>
            </tr>

            <tr>
              {
                map(checkedConcepts, (concept, index) => (
                  <td key={ `attributeTB${title}${concept.id}${index}` }>
                    {
                      !index &&
                      <div className="table-comparison_box-cell">
                        TB
                      </div>
                    }
                    <StatusCircle
                      values={ values[concept.id] }
                      box="top"
                      hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                    />
                  </td>
                ))
              }
              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view, "top") }
              </td>
            </tr>
          </Fragment>
        ))
      }
    </>
  );
};

export default TrAttributeRatings;
