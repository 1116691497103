import { map } from 'lodash';

const scrollTop = () => {
  document.querySelector('html').scrollTop = 0;
};

const perkIconPath = "/images/verizon/plans_builder/perks/";
const imageUrl = (imageName) => (`${perkIconPath}${imageName}`);

const preparePopupData = (title, description, descriptions) => {
  let modalDescription;
  if (descriptions) {
    let descriptionsList = descriptions;
    if (description) {
      descriptionsList = [ ...[ { description } ], ...descriptionsList ];
    }
    modalDescription = map(
      descriptionsList,
      (item) => {
        const resultList = [ '<div class="popup-feature">' ];
        if (item.title) {
          resultList.push(`<div class='title'>${item.title}</div>`);
        }
        if (item.description) {
          resultList.push(`<div class='description'>${item.description}</div>`);
        }
        resultList.push('</div>');
        return resultList.join('');
      }
    ).join('');
  } else {
    modalDescription = description;
  }
  return { title, description: modalDescription };
};

export { preparePopupData, imageUrl, scrollTop };
