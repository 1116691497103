import React, { useState } from 'react';
import Card from './card';
import CardPlanTitle from './cardPlanTitle';
import PlanShortDescription from './planShortDescription';

const AccordionView = ({ isStatic, handleOpenChange, title, children }) => {
  const [open, setOpen] = useState(isStatic);
  const handleChange = () => {
    handleOpenChange();
    setOpen(!open);
  };
  return <div className="accordion" style={!open ? { marginBottom: '-20px' } : {}}>
    {!isStatic && <button onClick={handleChange} className="accordion-button">
      <span className={`accordion-button-text ${open ? '-open' : ''}`}>{title}</span>
    </button>}
    {open && children}
  </div>
};

export const EmptyCard = () => <div className="empty-card">
  <span className="empty-card-text">Not chosen yet</span>
</div>;

export const PlanCard = (props) => {
  const { 
    plan: {
      id, name, price, description
    },
    isAcardionStatic,
    accordionTitle,
    handleOpenChange,
    onShowDetails,
    renderChildrenInAccordion,
    children
  } = props;
  return <Card>
    <CardPlanTitle name={name} price={price} />
    <div className="hr -secondary" style={{ marginBottom: 15 }}/>
    <AccordionView
      isStatic={isAcardionStatic}
      handleOpenChange={handleOpenChange}
      title={accordionTitle}>
      <div className="cards-item-description">
        <PlanShortDescription
          description={description} />
        <div className="details-link-wrapper">
          <button type="button" className="details-link" onClick={() => onShowDetails(id)}>See plan details</button>
        </div>
      </div>
      {renderChildrenInAccordion && children}
    </AccordionView>
    {!renderChildrenInAccordion && children}
  </Card>
};