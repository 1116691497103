import React, { useContext, useState } from 'react';
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import { comparisonScorecardWordcloudShowHideAnswer } from "../../../../common/google-analytics/events";
import { urls } from './const';
import { WordsData } from "./index";

const AnswerHideControl = ({ conceptId, metric, answer, projectId, gaTrackingId }) => {
  const { loadWords, loadComments } = useContext(WordsData);

  const [ hidden, setHidden ] = useState(answer.hidden);

  const hideAnswer = () => {
    setHidden(true);
    comparisonScorecardWordcloudShowHideAnswer(gaTrackingId, { project_id: projectId, item_id: conceptId });
    const url = urls.hide.replace(':concept_id', conceptId);

    Http.post(
      url,
      {
        source_id: metric.source_id,
        source_type: metric.source_type,
        linked_id: answer.id,
        linked_type: answer.type
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          loadWords();
          if (loadComments) {
            loadComments('heatmap');
          }
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const unhideAnswer = () => {
    setHidden(false);
    comparisonScorecardWordcloudShowHideAnswer(gaTrackingId, { project_id: projectId, item_id: conceptId });
    const url = urls.unhide.replace(':concept_id', conceptId);

    Http.post(
      url,
      {
        source_id: metric.source_id,
        source_type: metric.source_type,
        linked_id: answer.id,
        linked_type: answer.type
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          loadWords();
          if (loadComments) {
            loadComments('heatmap');
          }
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  return (
    <div className="admin_control">
      {!hidden && <div className="info-tooltip -left-margin">
        <SvgIcon role="button" className="eye-button" name="eye-open" onClick={ hideAnswer } />
        <div className="info-tooltip_drop -left">
          <div className="info-tooltip_drop-inner -tiny">Hide for client</div>
        </div>
      </div>}

      {hidden && <div className="info-tooltip -left-margin">
        <SvgIcon role="button" className="eye-button" name="eye-closed" onClick={ unhideAnswer } />
        <div className="info-tooltip_drop -left">
          <div className="info-tooltip_drop-inner -tiny">Hidden</div>
        </div>
      </div>}
    </div>
  );
};

export default AnswerHideControl;
