import React, { useContext, useState } from 'react';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';
import remoteFiles from '../../../../../common/remote_files';
import { sendToFraudCheck, prepareParams, onPasteDetected } from "../../logic/fraud_check";
import { fraudContext } from "../../contexts";

const initValue = { input1: '', input2: '', input3: '' };

const ThoughtBubble = ({
  concept, title, description, showMan, result,
  tbKlass, nextStep, children, customBgClass
}) => {
  const {
    manImage, womanImage, packageMcImage
  } = remoteFiles.express.respondent.thoughtBubble;
  const { respondentId, projectId, antiFraud, setFraudProcessing } = useContext(fraudContext);
  let imageClass;
  let imageSrc;
  if (customBgClass) {
    imageClass = customBgClass;
    imageSrc = packageMcImage;
  } else {
    imageClass = showMan ? 'man' : 'woman';
    imageSrc = showMan ? manImage : womanImage;
  }
  const header = children;

  const [ inputs, updateInputs ] = useState(initValue);

  const disabledOption = () => {
    const hash = {};
    if (!inputs.input1 || !inputs.input2 || !inputs.input3) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const updateInputValue = (event, num) => {
    updateInputs({ ...inputs, ...{ [`input${num}`]: event.target.value } });
  };

  const processAnswers = () => {
    const answer = `${inputs.input1}. ${inputs.input2}. ${inputs.input3}`;
    result.thought_bubbles.push({ source_id: concept.id, source_type: tbKlass, answer });
    updateInputs({ ...initValue });
    const fraudParams = prepareParams({ antiFraud, projectId, respondentId }, [ answer ]);
    sendToFraudCheck(fraudParams, nextStep, setFraudProcessing);
  };

  return (
    <>
      {
        header ?
          header :
          <div className="survey-header">
            <div className="survey-header_title" { ...renderRawHtml(title) } />
          </div>
      }
      <div className="survey-layout_content">
        <div className="survey-claim-random_bubble -sm">
          <div className="survey-claim-random_bubble-inner">
            <div className="survey-claim-random_bubble-text" { ...renderRawHtml(description) } />
            <ul className="survey-claim-random_bubble-list-fields">
              <li className="survey-claim-random_bubble-list-field">
                <div className="survey-claim-random_bubble-list-field-num">1.</div>
                <input
                  className="survey-claim-random_bubble-list-field-input"
                  type="text"
                  value={ inputs.input1 }
                  onChange={ (event) => { updateInputValue(event, "1"); } }
                  onPaste={ () => { onPasteDetected(result); } }
                />
              </li>
              <li className="survey-claim-random_bubble-list-field">
                <div className="survey-claim-random_bubble-list-field-num">2.</div>
                <input
                  className="survey-claim-random_bubble-list-field-input"
                  type="text"
                  value={ inputs.input2 }
                  onChange={ (event) => { updateInputValue(event, "2"); } }
                  onPaste={ () => { onPasteDetected(result); } }
                />
              </li>
              <li className="survey-claim-random_bubble-list-field">
                <div className="survey-claim-random_bubble-list-field-num">3.</div>
                <input
                  className="survey-claim-random_bubble-list-field-input"
                  type="text"
                  value={ inputs.input3 }
                  onChange={ (event) => { updateInputValue(event, "3"); } }
                  onPaste={ () => { onPasteDetected(result); } }
                />
              </li>
            </ul>
          </div>
        </div>
        <div
          className={ `survey-claim-random -bg-${imageClass}` }
          style={ { backgroundImage: `url(${imageSrc})` } }
        />
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswers(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default ThoughtBubble;
