import React, { useContext, useEffect } from 'react';
import { map } from 'lodash';
import InfoToolTip from '../../infoToolTip';
import { QuotaModeGlobalContext, QuotaModeLocalContext } from '../contexts';

const QuotaModeSwitcher = () => {
  const { quotaModes, demographics } = useContext(QuotaModeGlobalContext);

  const {
    quotaModeLocal, setQuotaModeLocal, forceUpdateLocalValueToGlobal
  } = useContext(QuotaModeLocalContext);
  const text = (
    <>
      {
        (
          demographics ?
            'Will be applied to all demographics and optional demographics questions.' :
            'Will be applied to the current question only.'
        )
      }
      <br />
      "At Most" - set the quota's top limit.
      <br />
      "Exact" - set the quota's both bottom and top limits.
    </>
  );
  const headerTitle = quotaModes?.title;
  const titles = quotaModes?.item_titles;
  const list = quotaModes?.list;

  const handleChangeValue = (event, name) => {
    setQuotaModeLocal(name);
  };

  useEffect(() => {
    if (quotaModes) {
      forceUpdateLocalValueToGlobal();
    }
  }, []);

  const { available } = quotaModes;

  return (
    <>
      {
        available &&
        <div className="quota-mode -with-401-width">
          <div className="quota-mode-title">
            <div className="quota-mode-title-item">
              <h3>{ headerTitle }</h3>
            </div>
            <div className="quota-mode-title-item">
              <InfoToolTip remote position="bottom-right" text={ text } />
            </div>
          </div>
          <div className="quota-mode-selection">
            <div className="radio-tabs -borderer -gray-bg">
              {
                map(list, (num, name) => (
                  <label
                    key={ `${name}${num}` }
                    className="radio-tabs_item"
                    htmlFor={ `quota_mode_${num}${name}` }
                  >
                    <input
                      id={ `quota_mode_${num}${name}` }
                      type="radio"
                      checked={ quotaModeLocal === name }
                      onChange={ (event) => handleChangeValue(event, name) }
                    />
                    <span className="radio-tabs_label">{titles[name]}</span>
                  </label>
                ))
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default QuotaModeSwitcher;
