import React from "react";

const Footer = ({ footerButtons, footerHintAbove }) => {
  return (
    <footer className="footer">
      {!!footerHintAbove && <div className="footer_hint -above">{footerHintAbove}</div>}
      <div className="footer_buttons">
        { footerButtons }
      </div>
    </footer>
  );
};

export default Footer;

