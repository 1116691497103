import React, { useState, useEffect } from 'react';
import { LogicSwitcher, RemoveButton } from './section_logic';

const AddComplexFilter = ({ onAdd }) => {
  return (
    <div className="logic-operator-between-plus">
      <div className="cross-to-add-circle" onClick={ onAdd }>
        <div className="cross-to-add" />
      </div>
    </div>
  );
};

const BetweenLogic = ({
  logicOperator,
  onUpdate,
  onRemove
}) => {
  const initItem = () => (
    { name: logicOperator, logicOperator }
  );
  const [ item, setItem ] = useState(initItem);
  useEffect(() => {
    setItem(initItem());
  }, [ logicOperator ]);
  return (
    <div className="logic-operator-between-section">
      <div className="logic-operator-between-update">
        <LogicSwitcher
          item={ item }
          selectOperator={ onUpdate }
          reverseColors
        />
        <RemoveButton removeSection={ onRemove } reverseColors />
      </div>
    </div>
  );
};

export { AddComplexFilter, BetweenLogic };
