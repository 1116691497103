import { isEmpty } from 'lodash';

const upcaseByReportKind = (kind) => {
  if (kind === 'pptx') {
    return 'PPT';
  }
  if (kind === 'xlsx') {
    return 'XLSX';
  }
  if (kind === 'google') {
    return 'GOOGLE';
  }
  return 'N/A';
};

const classByReportKind = (item, index) => {
  const kind = item.kinds[index];
  if (kind === 'pptx') {
    return '-ppt';
  }
  if (kind === 'xlsx') {
    return '-xlsx';
  }
  if (kind === 'google') {
    return '-google';
  }
  return '';
};

const kindByIndex = (item, index) => (item.kinds[index]);
const nameByIndex = (item, index) => (item.name[index]);

const reportKindsArray = (item) => (isEmpty(item.kindTitles) ? item.kinds : item.kindTitles);
const reportKindTitle = (item, index) => (
  isEmpty(item.kindTitles) ? upcaseByReportKind(kindByIndex(item, index)) : item.kindTitles[index]
);

export { upcaseByReportKind, classByReportKind, reportKindsArray, reportKindTitle, kindByIndex, nameByIndex };
