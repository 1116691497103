import React, { useState } from 'react';
import QuantPortal from '../common/portal';
import Statistic from "../../statistic";

const PlanArticle = ({ article, planId, filledLines, planList }) => {
  const { title, text, icon, iconPath, iconClassName, showText } = article;
  const [showTextState, setShowTextState] = useState(typeof showText !== 'undefined' ? showText: true);

  const toggleClickHandle = () => {
    const idx = planList.findIndex(planListItem => planListItem.id === planId);
    const lineIndex = filledLines;
    setShowTextState(!showTextState);
  };

  return <div className="plan-details-value-wrapper">
    {
      icon && <div className="plan-details-icon">
        <svg className={ `icon -${ icon } ${iconClassName}` }>
            <use xlinkHref={ `#svg_${ icon }` }/>
        </svg>
      </div>
    }
    {
      iconPath && <img className={`icon ${iconClassName}`} src={iconPath} />
    }
    <h6 className={`plan-details-subtitle toggle-text_title`} onClick={toggleClickHandle}>
      <svg className={ `icon -dropdown toggle-text_dropdown-icon ${showTextState ? '-up': '-down'}` }>
        <use xlinkHref={ `#svg_arrow-down` }/>
      </svg>
      {
        title.split('\n').map((value, idx) => <span key={idx}>{value}</span>)
      }
    </h6>
    {
      showTextState ? text.split('\n').map((value, idx) => <p key={idx} className="plan-details-value" dangerouslySetInnerHTML={{__html: value}}/>) : ''
    }
  </div>;
}

const renderArticle = (planId, filledLines, planList) => (article, idx) =>
  <PlanArticle
    key={idx}
    article={article}
    planId={ planId }
    filledLines={ filledLines }
    planList={ planList }
  />;

const PlanDetailsDescription = ({ title, articles, alternative, index, planId, filledLines, planList  }) =>
  <div className={`plan-details -col-${index + 1}`}>
    {alternative && (
      <div className="plan-details-or-wrapper">
        <div className="plan-details-or">or</div>
      </div>
    )}
    <h6 className="plan-details-title">{title}</h6>
    <div className="hr -secondary" />
    {articles.map(renderArticle(planId, filledLines, planList))}
  </div>

const renderPlanDetailsDesciption = (planId, filledLines, planList) => ({ title, articles, alternative }, idx) =>
  <PlanDetailsDescription
    key={`${planId}${idx}`}
    planId={ planId }
    filledLines={ filledLines }
    planList={ planList }
    title={title}
    alternative={alternative}
    articles={articles}
    index={idx}
  />

const PlanDetailsModalFlex = ({
                            open, handleClose, planId,
                            planList, allPlansInclude, getPlanLong,
                            filledLines }) => {

  const allIncludeCol = {
    title: allPlansInclude.title,
    articles: allPlansInclude.description
  };

  const classNameGrid = getPlanLong(planId).classNameGrid;

  const allCols = getPlanLong(planId).description.concat(allIncludeCol);

  return <QuantPortal open={open} handleClose={handleClose} className="-flex">
    <div className="plan-details-short">
      <h6 className="plan-details-short_title">{getPlanLong(planId).name}</h6>
      <div className="plan-details-short_subtitle">
        <div className="plan-details-short_price">${getPlanLong(planId).price}</div>
        <div className="plan-details-short_price-tip">
          /line per month
          <br />
          Plus taxes & fees.
        </div>
      </div>
    </div>

    <div className={`plan-details-grid -flex ${classNameGrid ? classNameGrid : ''}`}>
      {
        allCols.map(
          renderPlanDetailsDesciption(planId, filledLines, planList)
        )
      }

    </div>
  </QuantPortal>
};

export default PlanDetailsModalFlex;