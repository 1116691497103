import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

const ModalWindow = ({ modal, style, backStyle, className, backClassName, id, children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));
  const [ backElement ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = className;
    if (id) {
      element.setAttribute('id', id);
    }
    element.setAttribute('style', style);
    body.appendChild(element);

    backElement.className = backClassName;
    backElement.setAttribute('style', backStyle);
    body.appendChild(backElement);

    if (modal) {
      body.style.overflow = 'hidden';
      html.scrollTo({ left: 0 });
    }

    return () => {
      body.removeChild(element);
      body.removeChild(backElement);
      if (modal) {
        body.style.overflow = null;
      }
    };
  }, []);

  useEffect(() => {
    element.className = className;
  }, [ className ]);

  return createPortal(children, element);
};

const Modal = ({
  showCross, onClose, className, dialogClassName, modalClassName,
  modal, children, modalSize, customZIndex, id, mcRef, backClassName,
  modalDisplay, modalContentClass, backDropModifier
}) => {
  const ms = modalSize || 'sm';
  const zIndexBack = parseInt(customZIndex) || 1061;
  const zIndexFront = zIndexBack + 1;

  return (
    <ModalWindow
      className={ classnames("modal fade in", modalClassName) }
      style={ `z-index: ${zIndexFront}; display: ${modalDisplay || 'block'};` }
      backClassName={ classnames("modal-backdrop fade", backClassName || 'in', backDropModifier) }
      backStyle={ `z-index: ${zIndexBack};` }
      modal={ modal }
      id={ id }
    >
      <div className={ classnames(`modal-dialog modal-${ms}`, dialogClassName) }>
        <div className={ classnames("modal-content", modalContentClass) } ref={ mcRef }>
          <div className={ classnames('normal-popup', className) }>
            {
              showCross &&
              <div className="close" onClick={ onClose } />
            }
            { children }
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default Modal;
