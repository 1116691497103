import {
  customMetricsByLocation,
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible
} from '../eligible.js';
import BaseForward from '../base_forward';
import {respondentQualified} from "../../../../common/google-analytics/events";

class Forward extends BaseForward {
  constructor(options) {
    super(options);
    this.updateGroupIndex = options.updateGroupIndex;
    this.updateRandomClaimIndex = options.updateRandomClaimIndex;
  }

  forward(step, data, result, projectId, antiFraud, gaTrackingId) {
    let newStep = step + 1, needForward = false;

    switch (newStep) {
      case 1: // title
        newStep = newStep + 1;
        break;
      case 2: //demographics
        if (!data.demographics.length) {
          needForward = true;
        }
        break;
      case 3: //optional demographics
        if (demographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.optional_demographics.length) {
          needForward = true;
        }
        break;
      case 4: //screening
        if (optionalDemographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.screenings.length) {
          needForward = true;
        }
        break;
      case 5: // Qualified
        if (screeningsNotEligible(data, result.answers, gaTrackingId)) {
          newStep = this.screenedActionNum;
          needForward = true;
        }
        break;
      case 6:
        respondentQualified(gaTrackingId, { project_id: projectId, respondent_id: data.respondent_id });
        if (customMetricsByLocation(data.survey_metrics, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 7: // Product Description
        if (!data.show_task) {
          needForward = true;
        }
        break;
      case 8: // Intro
        break;
      case 9: // Groups
        if (!data.claims_groups.length) {
          needForward = true;
        }
        break;
      case 10:
        if (customMetricsByLocation(data.survey_metrics, 'default').length === 0) {
          needForward = true;
        }
        break;
      case 11: // Thought Bubble Intro
        if (!data.tb_enabled) {
          needForward = true;
        }
        break;
      case 12: // Thought Bubbles
        if (!data.tb_enabled || !data.random_claims.length) {
          needForward = true;
        }
        break;
      case 13: // Markup
        if (!data.cf_enabled) {
          needForward = true;
        }
        break;
      case 14:
        if (customMetricsByLocation(data.survey_metrics, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
    }

    [ newStep, needForward ] = this.checkLastSteps(
      this, newStep, needForward, 15, data, result, projectId, antiFraud
    );
    if (!newStep) {
      return;
    }

    if (needForward) {
      this.forward(newStep, data, result, projectId, antiFraud);
    } else {
      this.updateStep(newStep);
    }
  }

  nextGroup(index, groups, goForward) {
    this.nextItem(
      index, groups.length,
      this.updateGroupIndex,
      goForward
    );
  }

  nextRandomClaim(index, randomClaims, goForward) {
    this.nextItem(
      index,
      randomClaims.length,
      this.updateRandomClaimIndex,
      goForward
    );
  }
}
export default Forward;
