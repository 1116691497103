import React from 'react';
import Chip from './chip';

const noop = () => {};

export const AccordionTitle = ({ title, children }) =>
  <div className="accordion-title-wrapper">
    <h6 className="accordion-title">{title}</h6>
    {children}
  </div>

export const accordionTitleFactory = (title, chipText, chipClassName) => () =>
  <AccordionTitle title={title}>
    <Chip className={chipClassName}>{chipText}</Chip>
  </AccordionTitle>;

export const OuterAccordion = ({ children, open }) => {
  const className = `outer-accordion ${open ? '-open' : '-close'}`;
  return <div className={className}>
    {children}
  </div>
};

export const InnerAccordion = ({ TitleComponent, open, canOpen, onChange, children }) => {
  const className = [
    'inner-accordion',
    open ? '-open' : '-close',
    canOpen ? '-can-open': ''
  ].join(' ').trim();
  return <>
    <div className={className} onClick={canOpen ? onChange : noop}>
      <div className="inner-accordion-title">
        <TitleComponent/>
      </div>
    </div>
    {open && <div className="inner-accordion">
      {children}
    </div>}
  </>
}