import React from 'react';
import { filter, map, remove } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from '../../../common/components/modal';
import SvgIcon from "../../../common/components/svg-icon";
import renderRawHtml from "../../../common/render_raw_html";
import { reorderQuestions } from "./custom_questions/helper";
import useDraggableInPortal from './../../researcher/common/components/customize-questions-modal/use-draggable-in-portal';

const QuestionsOrderModal = ({ questions, setQuestionsOrderData, onSave, title = "Custom Questions", demo = false }) => {
  const renderDraggable = useDraggableInPortal();
  const filteredList = filter(questions, (q) => !q._destroy);
  const deletedList = filter(questions, (q) => q._destroy);

  const onSubmit = () => {
    reorderQuestions(filteredList);
    onSave([ ...questions ]);
    setQuestionsOrderData(null);
  };

  const handleOnDragEnd = (result) => {
    if (result?.destination?.index === undefined) {
      return;
    }

    const items = Array.from(filteredList);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    reorderQuestions(filteredList);

    setQuestionsOrderData([ ...items, ...deletedList ]);
  };

  const delQuestion = (event, question) => {
    event.preventDefault();
    question._destroy = true;
    if (!question.id && demo) {
      remove(questions, question);
    }
    reorderQuestions(questions);
    onSave([ ...questions ]);
  };

  return (
    <Modal modalSize="sm" modalContentClass="-overflow-visible">
      <div className="modal-header -auto-height">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ () => { setQuestionsOrderData(null); } }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal-title-md -left">{ title }</h3>
      </div>

      {
        !!filteredList.length &&
        <>
          <div>
            <DragDropContext onDragEnd={ handleOnDragEnd }>
              <Droppable droppableId="columns-area">
                {
                  (provided) => (
                    <ul
                      className="form-question_answers"
                      { ...provided.droppableProps }
                      { ...provided.dragHandleProps }
                      ref={ provided.innerRef }
                    >
                      {
                        map(filteredList, (question, index) => (
                          <Draggable
                            key={ index }
                            draggableId={ `column-${index}` }
                            index={ index }
                          >
                            {renderDraggable((provided) => (
                              <div
                                className="form-draggable_item"
                                ref={ provided.innerRef }
                                { ...provided.draggableProps }
                                { ...provided.dragHandleProps }
                              >
                                <div className="form-question_grid -order-modal">
                                  <div
                                    className="form-question_grid-item -first-answer-item"
                                  >
                                    <div
                                      className="form-question_answer-move"
                                      { ...provided.dragHandleProps }
                                    >
                                      <SvgIcon name="i-move" />
                                    </div>
                                  </div>
                                  <div
                                    className="form-question_grid-item -grow"
                                  >
                                    { !demo && <span className="form-question_answer-item -draggable"{ ...renderRawHtml(question.text) } /> }
                                    { demo && <span className="form-question_answer-item -draggable"{ ...renderRawHtml(question.uiTitle()) } /> }
                                  </div>
                                  <div className="form-question_grid-item">
                                    {
                                      <button
                                        type="button"
                                        className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                        onClick={ (event) => { delQuestion(event, question); } }
                                      >
                                        <SvgIcon name="i-delete" />
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </ul>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
        </>
      }

      <div className="modal-footer">
        <button type="button" className="button modal_button" onClick={ onSubmit }>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default QuestionsOrderModal;
