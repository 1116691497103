import React from 'react';

export const Footer = ({ className, children }) => {
  return (
    <footer className={ `footer ${className}` }>
      <div className="container">
        {children}
      </div>
    </footer>
  );
};
