import React, { useState, useEffect, Fragment } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { splitTextAndImages, imageSrc } from '../logic/qualify_helper';
import StartButton from './start_button.js';
import ZoomImage from './zoom_image';

const CustomQualified = ({ nextStep, content, reactWysiwig }) => {
  const getTextAndImagesArray = () => (splitTextAndImages(content));
  const [ textAndImages, setTextAndImages ] = useState(getTextAndImagesArray);
  useEffect(() => {
    setTextAndImages(getTextAndImagesArray());
  }, [ content ]);

  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        <div
          className={
            classnames(
              "custom-qualify-content custom-title",
              {  "-react-wysiwig": reactWysiwig }
            )
          }
        >
          {
            map(textAndImages.content, (item, index) => {
              const { src, alt } = imageSrc(item, textAndImages.images);
              return (
                <Fragment key={ `contentItem${index}` }>
                  { !src && <div  { ...renderRawHtml(item) } /> }
                  {
                    !!src &&
                    <ZoomImage
                      withHeadWrapper
                      className="survey-start-hero-img -no-top-margin -no-margin"
                      src={ src }
                    >
                      <img src={ src } alt={ alt } />
                    </ZoomImage>
                  }
                </Fragment>
              );
            })
          }
        </div>
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default CustomQualified;
