import React from 'react';
import StartButton from './start_button.js';

const ThoughtBubbleIntro = ({ top, bottom, nextStep }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        <h3 className="survey-start_title -bolder">
          { top }
          {
            bottom &&
            <>
              <br />
              { bottom }
            </>
          }
        </h3>
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default ThoughtBubbleIntro;
