import React, { useContext } from 'react';
import InfoToolTip from '../../../../common/infoToolTip';
import { copyToClipboard } from '../../../../../../common/clipboard-helper';
import { AppContext } from '../../../contexts';

const ReadOnlyInput = ({
  title = 'Survey Url',
  inputContent,
  children,
  copyButtonTitle = 'Copy Url'
}) => {
  const { setNotificationObject } = useContext(AppContext);

  const handleCopy = (event) => {
    event.preventDefault();
    copyToClipboard({
      text: inputContent,
      goodCallback: () => {
        setNotificationObject({
          message: `${title} successfully copied to clipboard!`,
          success: true
        });
      },
      badCallback: () => {
        setNotificationObject({
          message: 'Copy to clipboard was failed',
          success: false
        });
      }
    });
  };
  return (
    <div className="form_section-text input-with-subtitle">
      <div className="subtitle-wrapper">
        <span className="subtitle">{ title }</span>
        <InfoToolTip remote position={ 'top-right' } className="-force-margin">
          { children }
        </InfoToolTip>
      </div>
      <div className="readonly-input">
        <div className="grid">
          <div className="grid-item" id="url-for-copy">
            { inputContent }
          </div>
          <div className="grid-item">
            <button className="button -secondary -copy-url survey-flow-button" onClick={ handleCopy }>
              {copyButtonTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyInput;
