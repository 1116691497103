import React, { useState } from 'react';
import { map, values, find, flatten } from 'lodash';
import FooterButton from '../../footer_button.js';
import { findOrInitLoop } from '../../../logic/loop';
import ZoomImage from '../../zoom_image';
import renderRawHtml from '../../../../../../common/render_raw_html';
import Question from './question';

const SetOfQuestions = ({
  concept, questions, result, nextStep, children,
  forceImage, questionNotice,
  underTextHtml, productDescriptionComponent, survey
}) => {
  let header;
  if (productDescriptionComponent) {
    header = (
      <div className="survey-header">
        <div className="survey-header_title">
          <h3 className="survey-name-label -normal" { ...renderRawHtml(concept.name) } />
          { productDescriptionComponent }
        </div>
      </div>
    );
  } else {
    header = children;
  }

  const [ disabledHash, setDisabledHash ] = useState({});
  const [ answers, setAnswers ] = useState({});

  const disabledOption = () => {
    const hash = {};
    const disabledArray = values(disabledHash);
    if (disabledArray.length !== questions.length || find(disabledArray, (item) => item === true)) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswers = () => {
    const resAnswers = flatten(values(answers));
    findOrInitLoop(concept.confirmit_concept_id, result.loops, resAnswers);
    setAnswers({});
    nextStep();
  };

  const selectAnswer = (index, disabled, values, otherAnswer) => {
    setDisabledHash({ ...disabledHash, ...{ [index]: disabled } });
    setAnswers({ ...answers, ...{ [index]: values } });
    if (!result.otherAnswers) {
      result.otherAnswers = {};
    }
    const destQuestionName = questions[index].name;
    const conceptId = concept.id;
    if (!result.otherAnswers[destQuestionName]) {
      result.otherAnswers[destQuestionName] = {};
    }
    if (result.otherAnswers[destQuestionName][conceptId]) {
      delete result.otherAnswers[destQuestionName][conceptId];
    }
    if (otherAnswer) {
      result.otherAnswers[destQuestionName][conceptId] = otherAnswer;
    }
  };

  return (
    <>
      { header }
      <div className="survey-layout_content">
        <div className="survey-layout_container">
          <div className="survey-options">
            {
              ((!header || (forceImage && !!concept.url)) && !survey) &&
              <div className="survey-options_image-plate">
                <ZoomImage
                  className="survey-options_image"
                  src={ concept.url }
                >
                  <img src={ concept.url } />
                </ZoomImage>
              </div>
            }
            <div className="survey-options_questions">
              {
                map(questions, (question, index) => (
                  <Question
                    key={ question.name }
                    question={ question }
                    custom={ question.custom }
                    questionNotice={ questionNotice }
                    underTextHtml={ underTextHtml }
                    selectAnswer={
                      (disabled, values, otherAnswer) => {
                        selectAnswer(index, disabled, values, otherAnswer);
                      }
                    }
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton
          nextStep={ () => { processAnswers(); } }
          options={ disabledOption() }
        />
      </div>
    </>
  );
};

export default SetOfQuestions;
