import React, { useContext } from "react";
import { n2, showedAverageValue } from '../../../helpers';
import StatusCircle from '../../status-circle';
import { IndexData } from '../../../index-context';

const ShelfEvaluationCart = ({ current }) => {
  const { init, data, averageMode } = useContext(IndexData);
  const { analytics, titles } = data;
  const includedTargetInCart = analytics[titles.percent];
  const numberTotalItemsInCart = analytics[titles.total];
  const numberAverageItemsInCart = analytics[titles.in_average];

  const addedPercText = showedAverageValue(
    includedTargetInCart,
    averageMode,
    false,
    init.isInternal,
    data.view
  );

  const avgCartTotalText = showedAverageValue(
    numberTotalItemsInCart,
    averageMode,
    false,
    init.isInternal,
    data.view
  );

  const avgProductTotalText = showedAverageValue(
    numberAverageItemsInCart,
    averageMode,
    false,
    init.isInternal,
    data.view
  );

  return (
    <>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { titles.percent }
        </td>
        <td className="-js-n">
          <StatusCircle values={ includedTargetInCart[current.id] } box="top2" noLetters />
        </td>
        <td className="table-comparison_average-cell">
          { addedPercText }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { titles.total }
        </td>
        <td className="-js-n">
          { n2(numberTotalItemsInCart[current.id].value) }
        </td>
        <td className="table-comparison_average-cell">
          { avgCartTotalText }
        </td>
      </tr>
      <tr>
        <td className="table-comparison_name-cell" colSpan={ 2 }>
          { titles.in_average }
        </td>
        <td className="-js-n">
          { n2(numberAverageItemsInCart[current.id].value) }
        </td>
        <td className="table-comparison_average-cell">
          { avgProductTotalText }
        </td>
      </tr>
    </>
  );
};

export default ShelfEvaluationCart;
