import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.removeChild(element);
      body.style.overflow = null;
      //hack for safari to correct behavior of modal in iframe
      const topCoord = html.scrollTop;
      html.scrollTo({ top: 0 });
      html.scrollTo({ top: topCoord });
    };
  }, [ body, element, html ]);


  return createPortal(children, element);
};

const CloseFollowUp = ({ onCloseYes, onCloseNo }) => {
  return (
    <Portal>
      <div className="close-follow-up-modal">
        <div className="close-follow-up-modal_backdrop" />
        <div className="close-follow-up-modal_dialog">
          <div className="close-follow-up-modal_content">
            <h2>
              Do you really want to leave the app experience and return to the survey?
            </h2>
          </div>
          <div className="close-follow-up-modal_buttons">
            <button className="button -full-width" onClick={ onCloseNo }>
              No – I hit the “X“ button accidentally and would like to continue the app experience
            </button>
            <button className="button -link -full-width" onClick={ onCloseYes }>
              Yes – I would leave the app at this point if I was doing this for real
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default CloseFollowUp;
