import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { QuestionContext } from "../../contexts";

const AdditionalAnswerLine = ({
  question, questionIndex, options,
  screenerQuestions, setScreenerQuestions,
  fieldBoolName, fieldTextName, fieldOptionsName
}) => {
  const [ focusState, setFocusState ] = useState(false);
  const { fivePtScaleMode } = useContext(QuestionContext);

  const onChangeNoneAnswer = (event) => {
    question[fieldBoolName] = event.target.checked;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeNoneAnswerText = (event) => {
    question[fieldTextName] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  return (
    <li className={ cn('form-question_answer', `-for-${fieldBoolName}`) }>
      <div className="form-question_grid">
        <div className="form-question_grid-item -first-answer-item">
          <div className={ cn("form-question_answer-move", { '-disabled': fivePtScaleMode }) }>
            <div className="checkbox">
              <input
                type="checkbox"
                id={ `checkbox-id-${fieldBoolName}-${questionIndex}` }
                checked={ !!question[fieldBoolName] }
                onChange={ onChangeNoneAnswer }
                disabled={ fivePtScaleMode }
              />
              <label
                className={
                  cn(
                    "checkbox_label -for-additional-answer",
                    { '-disabled': fivePtScaleMode }
                  )
                }
                htmlFor={ `checkbox-id-${fieldBoolName}-${questionIndex}` }
              />
            </div>
          </div>
        </div>

        <div
          className={
            cn(
              "form-question_grid-item -grow -with-custom-placeholder",
              { '-disabled': !question[fieldBoolName] }
            )
          }
        >
          <input
            className="form-field -block"
            type="text"
            value={ question[fieldTextName] || '' }
            onChange={ onChangeNoneAnswerText }
            disabled={ !question[fieldBoolName] }
            placeholder={ options[fieldOptionsName] }
            onFocus={ () => { setFocusState(true); } }
            onBlur={ () => { setFocusState(false); } }
          />
          {
            (!!question[fieldTextName] || focusState) &&
            <div className="custom-placeholder-label">
              Substitute for &quot;{ options[fieldOptionsName] }&quot;
            </div>
          }
        </div>
        <div className="form-question_grid-item -buttons" />
      </div>
    </li>
  );
};

export default AdditionalAnswerLine;
