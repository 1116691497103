import React from "react";
import FormControlReadOnly from "../components/formControlReadOnly";
import FormControl from '../components/formControl';

const AddCardForm = ({
  billingAddress, fakeData,
  cardNumber, setCardNumber,
  cardExpiration, setCardExpiration,
  cardCvv, setCardCvv, onScan
}) => {
  const billingAddressAsString = [
    billingAddress?.primary, billingAddress?.secondary
  ].join('<br/>');

  const iconCard = (
    <span className="form-control_card-icon">
      <svg className="icon -card-small">
        <use xlinkHref={ `#svg_card-small` } />
      </svg>
    </span>
  );

  const tooltipCVV = (
    <span className="form-control_tooltip">
      <button className="button-icon">
        <svg className="icon -question">
          <use xlinkHref={ `#svg_question` } />
        </svg>
      </button>
      {/*<span>here will be tooltip text</span>*/}
    </span>
  );

  const handleOnClickNumber = () => {
    setCardNumber(fakeData.cardNumber);
  };

  const handleOnClickExpiration = () => {
    setCardExpiration(fakeData.cardExpiration);
  };

  const handleOnClickCvv = () => {
    setCardCvv(fakeData.cardCvv);
  };

  const handleScanClick = () => {
    onScan && onScan();
    setCardNumber(fakeData.scanCardNumber);
    setCardExpiration(fakeData.scanCardExpiration);
    setCardCvv(fakeData.scanCardCvv);
  };

  return (
    <div className="add-card-form form">
      <div className="form_line -right">
        <button className="button-link">
          <svg className="icon -camera">
            <use xlinkHref={ `#svg_camera` } />
          </svg>
          <span onClick={ handleScanClick }>Scan</span>
        </button>
      </div>
      <div className="form_line">
        <FormControl
          disableChange
          initialText={ cardNumber }
          label="Bank debit card number"
          iconLeft={ iconCard }
          onClick={ handleOnClickNumber }
        />
      </div>
      <div className="form_line">
        <div className="form_grid">
          <div className="form_grid-item">
            <FormControl
              disableChange
              initialText={ cardExpiration }
              label="Expiration"
              onClick={ handleOnClickExpiration }
            />
          </div>
          <div className="form_grid-item">
            <FormControl
              disableChange
              initialText={ cardCvv }
              label="CVV"
              iconRight={ tooltipCVV }
              onClick={ handleOnClickCvv }
            />
          </div>
        </div>
      </div>
      <div className="form_line">
        <FormControlReadOnly label="Billing address" value={ billingAddressAsString } />
      </div>
    </div>
  );
};

export default AddCardForm;
