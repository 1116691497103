import React from 'react';
import classnames from 'classnames';

const PageLoader = ({ loading, modifier }) => (
  <div className={ classnames(
    "page-loader",
    { "-loaded": !loading },
    modifier
    ) } />
);

export default PageLoader;
