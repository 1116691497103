import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { each } from 'lodash';
import MarkupTool from '../markup-tool';
import defineRadius from '../../../../../common_utils/heatmap_radius.es6';

const getThreshold = (threshold) => { return (parseInt(threshold, 10) || 50) / 100; };

const getMinThreshold = (threshold) => { return (1 - getThreshold(threshold)); };

const getMaxThreshold = (maxValue, threshold) => { return maxValue * (2 - getThreshold(threshold)); };

const getScaledArea = (baseWidth, area, imageWidth) => {
  const scale = baseWidth / parseFloat(imageWidth);
  const scaledArea = {
    left: parseFloat(area.left) * scale,
    top: parseFloat(area.top) * scale,
    width: parseFloat(area.width) * scale,
    height: parseFloat(area.height) * scale
  };
  scaledArea.right = scaledArea.left + scaledArea.width;
  scaledArea.bottom = scaledArea.top + scaledArea.height;
  return scaledArea;
};

const Heatmap = ({ imageUrl, comments, reaction, label, resolution, threshold }) => {
  const [ imageLoaded, setImageLoaded ] = useState(false);
  const [ width, setWidth ] = useState(0);
  const [ height, setHeight ] = useState(0);
  const [ heatmap, setHeatmap ] = useState(null);

  const [ commentsReceived, setCommentsReceived ] = useState(false);

  const $img = useRef(null);
  const $container = useRef(null);
  const heatmapResolution = resolution || 50;
  const heatmapTreshold = threshold || 50;

  const renderHeatmap = (heatmapRenderWidth, heatmapRenderHeight, h) => {
    const heatmapAreaSizeX = heatmapRenderWidth / heatmapResolution;
    const heatmapAreaSizeY = heatmapRenderHeight / heatmapResolution;

    const filter = {};
    filter[reaction] = true;
    const markupTool = new MarkupTool([], filter, [], null, null, 'area_and_text');
    markupTool.setAllComments(comments);

    let list = markupTool.getMarkupComments(), cells = [], pX, pY, maxCount = 0;

    for (var temp = []; temp.push([]) < heatmapResolution;) {}

    each(list, (comment) => {
      each(comment.filterAreas, (area) => {
        const scaledArea = getScaledArea(heatmapRenderWidth, area, comment.image_width);

        if (!!scaledArea && scaledArea.width) {
          const x1 = Math.floor(scaledArea.left / heatmapAreaSizeX), y1 = Math.floor(scaledArea.top / heatmapAreaSizeY),
            x2 = Math.floor((scaledArea.left + scaledArea.width) / heatmapAreaSizeX), y2 = Math.floor((scaledArea.top + scaledArea.height) / heatmapAreaSizeY);
          for (let i = x1; i <= x2; i++) {
            if (!temp[i]) {temp[i] = [];}

            for (let j = y1; j <= y2; j++) {
              if (temp[i][j] == undefined) {
                temp[i][j] = { c: 0, id: null };
              }
              if (temp[i][j].id != comment.id) {
                temp[i][j].c = temp[i][j].c + (comment.counter || 1);
                temp[i][j].id = comment.id;
              }
            }
          }
        }
      });
    });

    for (let i = 0; i < heatmapResolution; i++) {
      for (let j = 0; j < heatmapResolution; j++) {
        if (temp[i][j]) {
          if (temp[i][j].c > maxCount) {maxCount = temp[i][j].c;}
          pX = i * heatmapAreaSizeX;
          pY = j * heatmapAreaSizeY;
          cells.push({
            value: temp[i][j].c,
            x: pX + heatmapAreaSizeX / 2,
            y: pY + heatmapAreaSizeY / 2
          });
        }
      }
    }

    if (h) {
      h.setData({
        max: getMaxThreshold(maxCount, heatmapTreshold),
        min: getMinThreshold(heatmapTreshold),
        data: cells
      });
    }
  };

  const initHeatmap = (w, h) => {
    if (heatmap) {
      const canvas = heatmap._renderer.canvas;
      canvas.remove();
    }

    const nuConfig = {
      container: $container.current,
      radius: defineRadius(w / heatmapResolution, h / heatmapResolution, heatmapResolution),
      maxOpacity: 0.5,
      minOpacity: 0,
      blur: 0.75,
      scale: 1
    };

    const link = window.h337.create(nuConfig);
    setHeatmap(link);

    return link;
  };

  const onImageLoad = (event) => {
    const w = $img.current.offsetWidth;
    const h = $img.current.offsetHeight;
    setWidth(w);
    setHeight(h);
    setImageLoaded(true);

    const link = initHeatmap(w, h);

    if (commentsReceived) {
      renderHeatmap(w, h, link);
    }
  };

  useEffect(() => {
    if (comments.length > 0 || commentsReceived) {
      setCommentsReceived(true);
      if (imageLoaded) {
        renderHeatmap(width, height, heatmap);
      }
    }
  }, [ comments, imageLoaded ]);

  return (
    <div className="survey-reactions-interface_image-canvas-wrapper">
      <div ref={ $container } className="survey-reactions-interface_image-canvas-inner">
        <img ref={ $img } className="survey-reactions-interface_image" src={ imageUrl } onLoad={ onImageLoad } />
        {label && label.show && <div className="widget-heatmap_label">{label.text}</div>}
        {label && label.show && <div className={ cn("widget-heatmap_value", label.class) }>{label.value}</div>}
      </div>
    </div>
  );
};

export default Heatmap;
