import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../common/render_raw_html.js';

const ModalWindow = ({
  className, sourceElement, onClickAround, children
}) => {
  const body = document.body || document.querySelector("body");

  const [ element ] = useState(document.createElement('div'));

  const computedStyles = () => {
    const margin = 0,
      sourceData = sourceElement.getBoundingClientRect(),
      elementData = element.getBoundingClientRect(),
      bodyData = document.getElementsByTagName("body")[0].getBoundingClientRect();

    let left = sourceData.left + (sourceData.width / 2) - (elementData.width / 2),
      top = sourceData.top - margin - elementData.height;

    if (bodyData.height - (sourceData.top + sourceData.height) > (elementData.height + margin)) {
      top = sourceData.top + sourceData.height + margin;
    }

    return `top: ${top}px; left: ${left}px; visibility: visible; opacity: 1`;
  };

  const setStyles = () => {
    element.setAttribute("style", computedStyles());
  };

  useEffect(() => {
    element.className = className;
    element.setAttribute("style", "top: 0px; left: 0px; visibility: hidden; opacity: 0");
    body.appendChild(element);
    setStyles();

    let pointedToElement = false;

    const bodyListenerFunction = (event) => {
      if (!pointedToElement) {
        onClickAround(event);
      }
      pointedToElement = false;
    };
    const elementListenerFunction = (event) => {
      pointedToElement = true;
    };
    const onScroll = () => {
      setStyles();
    };

    element.addEventListener("click", elementListenerFunction);
    body.addEventListener("click", bodyListenerFunction);

    document.addEventListener("scroll",  onScroll, true);

    return () => {
      element.removeEventListener("click", elementListenerFunction);
      body.removeEventListener("click", bodyListenerFunction);
      body.removeChild(element);

      document.removeEventListener("scroll",  onScroll, true);
    };
  }, []);

  return createPortal(children, element);
};

const DropdownList = ({
  onItemClick, list, className,
  linkTitle, popupClassName, listField
}) => {
  const [ opened, setOpened ] = useState(false);

  const getTitle = (item) => {
    const itemField = listField || 'title';
    const result = item[itemField];
    return (
      typeof result === 'function' ? result.apply() : result
    );
  };

  const handleItemClick = (event, item) => {
    event.preventDefault();
    onItemClick(event, item);
    setOpened(false);
  };
  const onLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpened(true);
  };
  const onClickAround = (event) => {
    setOpened(false);
  };

  const renderListItem = (item, index) => {
    return (
      <li key={ `listItem${index}` } >
        {
          item.disabled ?
            <span { ...renderRawHtml(getTitle(item)) } />
            :
            <a
              href="#"
              onClick={ (event) => { handleItemClick(event, item); } }
              { ...renderRawHtml(getTitle(item)) }
            />
        }
      </li>
    );
  };

  const srcElement = useRef(null);

  return (
    <>
      {
        opened &&
        <ModalWindow
          className={ classnames('express-inline-dropdown', popupClassName) }
          sourceElement={ srcElement.current }
          onClickAround={ (event) => { onClickAround(event); } }
        >
          {
            !!list.length &&
            <ul>{ map(list, (item, index) => renderListItem(item, index)) }</ul>
          }
          { !list.length && <p>No items available</p> }
        </ModalWindow>
      }
      <a
        href="#"
        ref={ srcElement }
        className={ className }
        onClick={ onLinkClick }
        { ...renderRawHtml(linkTitle) }
      />
    </>
  );
};

export default DropdownList;
