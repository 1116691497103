import React, { useState, useRef } from 'react';
import HoverPopup from './popup_inner/hover_popup.js';
import TouchModal from './popup_inner/touch_modal.js';
import { PopupImageContext } from './popup_inner/image_in_popup_context';

const ImageInPopup = ({ src, hoverClassName, className, children, customZIndex }) => {
  const [ hovered, setHovered ] = useState(false);
  const [ touched, setTouched ] = useState(false);
  const [ isTouch, setIsTouch ] = useState(false);
  const sourceElement = useRef(null);

  const setHoverState = (value) => {
    setHovered(value);
  };
  const setTouchState = (value) => {
    if (!isTouch) {
      setIsTouch(true);
    }
    setTouched(value);
  };
  const onClose = () => {
    setTouched(false);
  };

  const contextValue = {
    customZIndex
  };

  return (
    <PopupImageContext.Provider value={ contextValue }>
      <img
        className={ className }
        src={ src }
        ref={ sourceElement }
        onMouseEnter={ () => {
          setHoverState(true);
        } }
        onMouseMove={ () => {
          setHoverState(true);
        } }
        onMouseLeave={ () => {
          setHoverState(false);
        } }
        onTouchStart={ () => {
          setTouchState(true);
        } }
      />
      {
        hovered && !isTouch &&
        <HoverPopup
          className={ hoverClassName }
          sourceElement={ sourceElement.current }
        >
          {children}
        </HoverPopup>
      }
      {
        touched &&
        <TouchModal
          className={ '-image-in-modal' }
          sourceElement={ sourceElement.current }
          onClose={ () => {
            onClose();
          } }
        >
          {children}
        </TouchModal>
      }
    </PopupImageContext.Provider>
  );
};

export default ImageInPopup;
