import React, { useState } from 'react';
import { map, each } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';

const Group = ({
  desktopTitle, mobileTitle, group, mostLabel,
  counter, leastLabel, result, nextStep
}) => {
  const [ mostId, updateMostId ] = useState(null);
  const [ leastId, updateLeastId ] = useState(null);

  const saveAndNextGroup = () => {
    result.claim_answers.push({ claim_id: mostId, like: true });
    result.claim_answers.push({ claim_id: leastId, like: false });
    each(group, (claim) => {
      result.claim_views.push({ claim_id: claim.id });
    });
    updateMostId(null);
    updateLeastId(null);
    nextStep();
  };

  const selectClaim = (claimId, positive) => {
    positive ? updateMostId(claimId) : updateLeastId(claimId);
  };

  const renderClaim = (claim, index) => {
    return (
      <div
        className={ classnames('survey-claim-group_item', { "-most": mostId === claim.id }, { "-least": leastId === claim.id }) }
        key={ `claim-${index}` }
      >
        <div className="survey-claim-group_item-radio">
          <label className="radio">
            <input
              type="radio"
              value={ claim.id }
              checked={ claim.id === mostId }
              onChange={ () => { selectClaim(claim.id, true); } }
              disabled={ leastId === claim.id ? "disabled" : null }
            />
            <div className="radio_label -no-text js-most" />
          </label>
        </div>
        <div className="survey-claim-group_item-claim">
          <div className="group_item-claim-inner" { ...renderRawHtml(claim.text) } />
        </div>
        <div className="survey-claim-group_item-radio">
          <label className="radio">
            <input
              type="radio"
              value={ claim.id }
              checked={ claim.id === leastId }
              onChange={ () => { selectClaim(claim.id, false); } }
              disabled={ mostId === claim.id ? "disabled" : null }
            />
            <div className="radio_label -no-text js-least" />
          </label>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="survey-header">
        <div className="survey-header_title -hide-mobile" { ...renderRawHtml(desktopTitle) } />
        <div className="survey-header_title -show-mobile" { ...renderRawHtml(mobileTitle) } />
      </div>
      <div className="survey-layout_content">
        <div className="survey-layout_container -full-width">
          <div className={ `survey-claim-group -count-${group.length}` }>
            <div className="survey-claim-group_head">
              <div className="survey-claim-group_head-item">
                <div className="survey-claim-group_label" { ...renderRawHtml(mostLabel) } />
              </div>
              <div className="survey-claim-group_head-item -center">
                <div className="survey-claim-group_count" { ...renderRawHtml(counter) } />
              </div>
              <div className="survey-claim-group_head-item -right">
                <div className="survey-claim-group_label" { ...renderRawHtml(leastLabel) } />
              </div>
            </div>
            <div className="survey-claim-group_plate">
              { map(group, (claim, index) => renderClaim(claim, index)) }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton
          nextStep={ () => { saveAndNextGroup(); } }
          options={ { disabled: (mostId && leastId ? null : "disabled") } }
        />
      </div>
    </>
  );
};

export default Group;
