import React from 'react';
import SQAHeader from "./SQAHeader";
import SQAMultiOption from "./SQAMultiOption";
import SQAList from "./SQAList";
import SQAGridColumns from "./SQAGridColumns";
import SQAGridRows from "./SQAGridRows";

const ScreeningQuestionAnswers = ({
  options, question, screenerQuestions, setScreenerQuestions, questionIndex, needLocation
}) => {
  return (
    <div className="form_section -sm-space">
      <SQAHeader
        title={ question.kind === "Grid" ? "Columns" : "Answers" }
        options={ options }
        question={ question }
        screenerQuestions={ screenerQuestions }
        setScreenerQuestions={ setScreenerQuestions }
        needLocation={ needLocation }
      />

      {
        question.kind === "Grid" &&
        <>
          <SQAGridColumns
            options={ options }
            question={ question }
            screenerQuestions={ screenerQuestions }
            setScreenerQuestions={ setScreenerQuestions }
            questionIndex={ questionIndex }
          />
          <SQAHeader
            title="Rows"
            options={ options }
            question={ question }
            screenerQuestions={ screenerQuestions }
            setScreenerQuestions={ setScreenerQuestions }
            noToggles
          />
          <SQAGridRows
            options={ options }
            question={ question }
            screenerQuestions={ screenerQuestions }
            setScreenerQuestions={ setScreenerQuestions }
            questionIndex={ questionIndex }
          />
        </>
      }

      {
        question.kind !== "Grid" &&
        <SQAList
          options={ options }
          question={ question }
          screenerQuestions={ screenerQuestions }
          setScreenerQuestions={ setScreenerQuestions }
          questionIndex={ questionIndex }
        />
      }

      <SQAMultiOption
        options={ options }
        question={ question }
        screenerQuestions={ screenerQuestions }
        setScreenerQuestions={ setScreenerQuestions }
      />
    </div>
  );
};

export default ScreeningQuestionAnswers;
