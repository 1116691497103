import React, { useContext, useState } from 'react';
import { map, compact, findIndex } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../common/render_raw_html';
import PricingModal from "../common/pricingModal";
import { LineDataContext, SetContext } from "./contexts";
import { perkNameById } from './utils';

const Lines = ({
  linesCount, selectedPlans, total, selectLine,
  currentLine, changeLineCount, removeLine,
  finishStep
}) => {
  const { maxLines } = useContext(SetContext);
  const { pricing } = useContext(LineDataContext);
  const {
    plans, perks, perksNoRestriction,
    specialPricingMinPricePerLine,
    linesDescriptionKlass, linesDescription,
    linesEmptyPlanTitle, bau
  } = useContext(LineDataContext);

  const [ showPricingModal, setShowPricingModal ] = useState(false);

  const hasEmptyPlan = (list) => {
    return list.find((plan) => plan.id === null);
  };

  const hasEmptyPerks = (list) => {
    return list.find((plan) => (
      !perksNoRestriction &&
      plan.planSelectedPerks &&
        plan.planSelectedPerks.indexOf(null) !== -1
    ));
  };

  const definedFinal = (list) => {
    const isDefinedPlans = !hasEmptyPlan(list);
    const isDefinedPerks = !hasEmptyPerks(list);
    return isDefinedPlans && isDefinedPerks;
  };

  const isDefinedPerks = !hasEmptyPerks(selectedPlans);
  const isFinal = definedFinal(selectedPlans);

  const openModal = () => {
    setShowPricingModal(true);
  };

  const closeModal = () => {
    setShowPricingModal(false);
  };

  const decLine = (line) => {
    if (line > 1) {
      changeLineCount(line - 1);
      // after deleting current line set previous line as current
      if (currentLine === line) {
        selectLine(currentLine - 1);
      }
    }
  };

  const onRemoveLine = (event, lineNumber) => {
    event.preventDefault();
    event.stopPropagation();

    removeLine(lineNumber);
    if (currentLine >= lineNumber && currentLine > 1) {
      selectLine(currentLine - 1);
    }
  };

  const incLine = (line) => {
    if (line < maxLines) {
      changeLineCount(line + 1);
      if (definedFinal([ selectedPlans[currentLine - 1] ])) {
        const firstNotFullIndex = findIndex(
          selectedPlans,
          (item) => !definedFinal([ item ]),
          currentLine - 1
        );
        const newLine = firstNotFullIndex !== -1 ?
          (firstNotFullIndex + 1) :
          (line + 1);
        selectLine(newLine);
      }
    }
  };

  const changeLine = (clickedLine) => {
    selectLine(clickedLine);
  };

  const filteredSelectedPerks = (plan) => (
    perksNoRestriction ?
      compact(plan.planSelectedPerks) :
      plan.planSelectedPerks
  );

  const pricingModalKind = () => {
    if (bau) {
      return specialPricingMinPricePerLine ? 'specialBAU' : 'bau';
    }
    if (specialPricingMinPricePerLine) {
      return 'special';
    }
    return perksNoRestriction ? 'customPerks' : 'base';
  };

  return (
    <div className="lines">
      <div className="lines_section -flex">
        <h2 className="lines_title">Your lines</h2>
        <div className="lines_description">
          <span className={ classnames(linesDescriptionKlass) }>
            { linesDescription }
          </span>
          <br />
          <div className="lines_description-link" onClick={ () => { openModal(); } }>See pricing guide</div>
        </div>

        {
          showPricingModal &&
          <PricingModal
            close={ closeModal }
            pricing={ pricing }
            plans={ plans }
            kind={ pricingModalKind() }
          />
        }

        {
          !specialPricingMinPricePerLine &&
          <div className="lines_count">
            <button
              className="button -bordered -round -sm -minus lines_count-button"
              disabled={ linesCount <= 1 }
              onClick={ () => { decLine(linesCount); } }
            >
              -
            </button>
            <div className="lines_count-value">{linesCount}</div>
            <button
              className="button -bordered -round -sm -plus lines_count-button"
              onClick={ () => { incLine(linesCount); } }
              disabled={ linesCount >= maxLines }
            >
              +
            </button>
          </div>
        }

        <ul className="lines_list">
          {
            map(selectedPlans, (plan, index) => (
              <li
                key={ `row${index}` }
                className={ `lines_item ${index === currentLine - 1 ? '-active' : ''}` }
                onClick={ () => { changeLine(index + 1); } }
              >
                <div className="lines_item-number">
                  <span>Line {index + 1}</span>
                  {
                    !!specialPricingMinPricePerLine && (!!index || linesCount > 1) &&
                    <span
                      className="line_item-remove"
                      onClick={ (event) => { onRemoveLine(event, index + 1); } }
                    >
                      Remove
                    </span>
                  }
                </div>

                <table className="lines-table">
                  <thead>
                    <tr>
                      <th>
                        <h3 className="lines_item-title" >
                          {
                            plan.title ? (
                              <>
                                <span { ...renderRawHtml(plan.title) } />:
                                {
                                  !perksNoRestriction &&
                                  <>
                                    { ' ' }
                                    <span>${plan.price}</span>
                                  </>
                                }
                              </>
                            ) : (
                              <span>{ linesEmptyPlanTitle }</span>
                            )
                          }
                        </h3>
                      </th>
                      {
                        perksNoRestriction &&
                        <th className="col-price price-plan">
                          {
                            !!plan.title &&
                            <h3 className="lines_item-title" >
                              <span>${plan.price}</span>
                            </h3>
                          }
                        </th>
                      }
                    </tr>
                  </thead>
                  {
                    !!plan.title &&
                    !!plan.planSelectedPerks && !!plan.planSelectedPerks.length &&
                    <tbody className="lines_item-perks">
                      {
                        map(filteredSelectedPerks(plan), (perkId, perkIndex) => (
                          <tr
                            key={ `perkLine${perkId}_${index}_${perkIndex}` }
                            className={
                              classnames("lines_item-perk", { '-warning': !perkId })
                            }
                          >
                            <td>
                              { perkId ? perkNameById(perks, perkId) : 'Select perk' }
                              {
                                perksNoRestriction && !!perkId &&
                                <>:</>
                              }
                            </td>
                            {
                              perksNoRestriction && !!perkId &&
                                !!plan.planPerksPrice && !!plan.planPerksPrice.length &&
                                <td className="col-price price-perk">
                                  ${ plan.planPerksPrice[perkIndex] }
                                </td>
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  }
                </table>
              </li>
            ))
          }
          {
            !!specialPricingMinPricePerLine && (linesCount < maxLines) &&
            <li className="centered">
              <button
                className="button -bordered -auto-height -with-plus"
                onClick={ () => { incLine(linesCount); } }
              >
                <span className="title">Add a line</span>
                <br />
                <span className="description" >
                  Starting at ${specialPricingMinPricePerLine[linesCount]}/mo
                </span>
              </button>
            </li>
          }
        </ul>
      </div>
      <div className="lines_section -bottom">
        <div className="lines_total">
          <h3 className="lines_total-title">Monthly Total:</h3>

          <div className="lines_total-grid">
            <div className="lines_total-value">${total}</div>
            <div className="lines_total-hint">/mo</div>
          </div>
          <div className="lines_total-note">
            Plus taxes & fees.
          </div>
        </div>

        <div className="lines_button">
          <button
            className="button -full-width"
            disabled={ !isFinal }
            onClick={ () => {
              finishStep();
            } }
          >
            Continue to checkout
          </button>
          {
            !isDefinedPerks &&
            <div className="lines_button-note">
              Select missing perk to continue
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Lines;
