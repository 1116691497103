export const reviewTermsData = {
  termsIntroList: [
    {
      title: "Monthly fee",
      values: [
        { value: "$0" }
      ]
    },
    {
      title: "Per purchase",
      values: [
        { value: "$0" }
      ]
    },
    {
      title: "ATM withdrawal",
      values: [
        { value: "$2.50*", hint: "in-network" },
        { value: "$2.50*", hint: "out-of-network" }
      ]
    },
    {
      title: "Add cash at stores",
      values: [
        { value: "$3.95*" }
      ]
    }
  ],
  termsLinesList: [
    {
      name: "ATM balance inquiry",
      hint: "(in-network or out-of-network)",
      value: "$0"
    },
    {
      name: "Customer service",
      hint: "(automated or live agent)",
      value: "$0"
    },
    {
      name: "Inactivity",
      value: "$0"
    },
    {
      title: "We charge 7 other types of fees.",
      text: "Here are some of them:"
    },
    {
      name: "Electronic withdrawal <br />(standard / Instant Transfer)",
      value: "$0 / 1.75%<br/>(min. $0.25/max. $25)"
    },
    {
      name: "Buying or selling a cryptocurrency",
      value: "$2.49* or 1.8%*"
    }

  ],

  termsTable: {
    head: [ "Fees", "Amount", "Details" ],
    data: [
      {
        title: "Spending or sending money",
        content: [
          {
            fees: "International transaction",
            amount: "5%<br />(minimum $0.99 fee; maximum $4.99 fee)",
            details: "There's a fee when you send personal transactions to friends or family with eligible PayPal accounts outside the U.S. using your Balance Account balance." +
              "<br /><br />If you’re sending in a foreign currency, the fee is a comparable amount in that currency."
          },
          {
            fees: "Visa+ transaction (sending money using Visa+ to non-Venmo digital wallets)",
            amount: "1.75% (minimum $0.25; maximum $25)",
            details: "There’s a fee when you use Visa+ to send money to friends or family with non-Venmo Visa+-enabled digital wallets using your Balance Account balance."
          }
        ]
      },
      {
        title: "Adding money to your balance",
        content: [
          {
            fees: "Add cash at stores",
            amount: "$3.74 - $3.95",
            details: "There's a fee of up to $3.95 when you add cash to your  Balance Account balance using the PayPal app at participating stores. Amount varies by store."
          },
          {
            fees: "Check reload (faster check loads, payroll and government checks)",
            amount: "1.00% (minimum $5 fee)",
            details: "There's a 1.00% fee (with a minimum fee of $5.00) when you choose to add payroll or government checks (with pre-printed signature) to your Balance Account balance. No fee if your check can't be added."
          },
          {
            fees: "Check reload (faster check loads, all other accepted check types)",
            amount: "5.00% (minimum $5 fee)",
            details: "There’s a 5.00% fee (with a minimum fee of $5.00) when you choose to add non-payroll and non-government checks to your Balance Account balance in minutes using the PayPal app. No fee if your check can’t be added."
          }
        ]
      },
      {
        title: "Transferring money from your balance",
        content: [
          {
            fees: "Electronic withdrawal (standard or Instant Transfer)",
            amount: "$0 or 1.75% (minimum $0.25 fee; maximum $25 fee)",
            details: "There is no fee to transfer money from your Balance Account balance to your eligible linked bank account or debit card with the standard option. Money transferred with the standard option is typically available in 1 – 3 business days when sent to a linked bank account or approximately 48 hours when sent to an eligible linked debit card. There’s a fee to transfer money from your Balance Account balance to your eligible linked bank account or debit card with the Instant Transfer option. The money is typically available in minutes with that option."
          }
        ]
      },
      {
        title: "Using the PayPal Debit Mastercard® (“PayPal Debit Card”) (optional)",
        content: [
          {
            fees: "ATM withdrawal (out-of-network)",
            amount: "$2.50",
            details: "There's a fee for non-MoneyPass ATM withdrawals in the U.S. Fee amount varies by ATM owner. You may be charged an additional amount by the ATM owner even if you don't complete a transaction."
          },
          {
            fees: "ATM international withdrawal",
            amount: "$2.50",
            details: "There's a fee for non-U.S. ATM withdrawals. It applies even if the transaction doesn't need any currency conversion. A 2.50% international transaction fee described below also applies to international ATM withdrawals. You may be charged an additional amount by the ATM owner even if you don't complete a transaction."
          },
          {
            fees: "International transaction",
            amount: "2.50%",
            details: "There's a foreign transaction fee when you use your PayPal Debit Card to make purchases or withdrawals at stores or ATMs outside the U.S. This fee applies even when there's no currency conversion."
          },
          {
            fees: "Add cash at stores",
            amount: "$3.74 - $3.95",
            details: "There’s a fee to add cash to your Balance Account balance using your PayPal Debit Card at participating stores. Fee amount varies by store."
          }
        ]
      },
      {
        title: "Using the PayPal Business Debit Mastercard® (“PayPal Business Debit Card”) (optional)",
        content: [
          {
            fees: "ATM withdrawal (in-network and out-of-network)",
            amount: "$2.50",
            details: "This fee is charged for ATM withdrawals in the U.S. (plus any amounts charged by the ATM owner). You may be charged an additional fee by the ATM owner even if you don’t complete a transaction."
          },
          {
            fees: "ATM international withdrawal",
            amount: "$2.50",
            details: "There’s a fee for non-U.S ATM withdrawals. It applies even if the transaction doesn’t need any currency conversion. A 2.50% international transaction fee described below also applies to international ATM withdrawals. You may be charged an additional amount by the ATM owner even if you don’t complete a transaction."
          },
          {
            fees: "International transaction",
            amount: "2.50%",
            details: "There’s a foreign transaction fee when you use your PayPal Business Debit Card to make purchases or withdrawals at stores or ATMs outside the U.S. This fee applies even when there’s no currency conversion."
          },
          {
            fees: "Add cash at stores",
            amount: "$3.74 - $3.95",
            details: "There’s a fee to add cash to your Balance Account balance using your PayPal Business Debit Card at participating stores. Fee amount varies by store."
          }
        ]
      },
      {
        title: "Third-party digital wallets (optional)",
        content: [
          {
            fees: "International transaction",
            amount: "2.50%",
            details: "There's a foreign transaction fee to use PayPal in Google Pay and Samsung Pay to make a purchase from a non-U.S. merchant. This fee applies even when there's no currency conversion."
          }

        ]
      },
      {
        title: "Cryptocurrencies",
        content: [
          {
            fees: "Buying or selling a cryptocurrency:",
            amount: "",
            details: "There’s a fee for buying or selling cryptocurrencies. Fee will vary based on the U.S. dollar value of your cryptocurrency purchase or sale amount. These fees do not apply to buying and selling PYUSD."
          },
          {
            fees: "$1.00 - $4.99",
            amount: "$0.49 (minimum fee)",
            details: ""
          },
          {
            fees: "$5.00 - $24.99",
            amount: "$0.99",
            details: ""
          },
          {
            fees: "$25.00 - $74.99",
            amount: "$1.99",
            details: ""
          },
          {
            fees: "$75.00 - $200.00",
            amount: "$2.49",
            details: ""
          },
          {
            fees: "$200.01 - $1000.00",
            amount: "1.80%",
            details: ""
          },
          {
            fees: "$1000.01+",
            amount: "1.50%",
            details: ""
          },
          {
            fees: "Converting between PYUSD and another cryptocurrency:",
            amount: "",
            details: "There is a fee to convert PYUSD to/from another type of cryptocurrency. The fee will vary based on the U.S. dollar value of the cryptocurrency you convert."
          },
          {
            fees: "$1.00 - $9.99 ",
            amount: "$0.49 (minimum fee)",
            details: ""
          },
          {
            fees: "$10.00 - $29.99 ",
            amount: "$0.99",
            details: ""
          },
          {
            fees: "$30.00 - $79.99 ",
            amount: "$1.99",
            details: ""
          },
          {
            fees: "$80.00 - $249.99",
            amount: "$2.49",
            details: ""
          },
          {
            fees: "$250.00 - $1000.00",
            amount: "1.75%",
            details: ""
          },
          {
            fees: "$1000.01+",
            amount: "1.45%",
            details: ""
          },
          {
            fees: "Receiving a cryptocurrency transfer",
            amount: "$0",
            details: "No fee"
          },
          {
            fees: "Transferring cryptocurrency to another PayPal or Venmo account",
            amount: "$0",
            details: "No fee"
          },
          {
            fees: "Transferring cryptocurrency to an address outside of PayPal or Venmo",
            amount: "Amount varies (paid in cryptocurrency)",
            details: "You will be charged a cryptocurrency network fee, payable in the cryptocurrency you transfer, which varies. For more information, see the PayPal Cryptocurrency Terms and Conditions on the Legal Agreements page at <b>PayPal.com.</b> We will display such network fee right before you transfer any cryptocurrency."
          },
          {
            fees: "Using PayPal as a funding source to buy and transfer cryptocurrency in one step to external cryptocurrency wallet providers who support this feature",
            amount: "For cryptocurrency other than PYUSD, 1% + the applicable fee listed in the “Buying or selling a cryptocurrency” section above <br /><br />For PYUSD, a fee of 1.5%",
            details: "When you buy cryptocurrency to be transferred in one step, you will also be charged a cryptocurrency network fee, payable in the cryptocurrency you transfer. The cryptocurrency amount to be transferred in one step to an external wallet plus the cryptocurrency amount you will be charged to cover the cryptocurrency network fee is the “combined cryptocurrency purchase amount.” We will display the network fee right before you transfer any cryptocurrency." +
              "<br /><br />For cryptocurrency other than PYUSD, you will pay (1) the transaction fee listed in the “Buying or selling a cryptocurrency” section above on the U.S. dollar value of the combined cryptocurrency purchase amount and (2) 1% only on the U.S. dollar value of the cryptocurrency that you transfer to your external wallet." +
              "<br /><br />For PYUSD, you will pay 1.5% on the U.S. dollar value of the PYUSD that you transfer to your external wallet. You will not pay a transaction fee on the combined cryptocurrency purchase amount."
          },
          {
            fees: "Using PayPal to transfer and sell cryptocurrency out of supported external cryptocurrency wallets in one step",
            amount: "For cryptocurrency other than PYUSD, 1% + the applicable fee listed in the “Buying or selling a cryptocurrency” section above <br /><br />For PYUSD, a fee of 1.5%",
            details: "When you transfer and sell cryptocurrency out of a supported external cryptocurrency wallet in one step, you will pay a transaction fee on the USD value of the cryptocurrency you are selling." +
              "<br /><br />For cryptocurrency other than PYUSD, you will pay (1) the transaction fee listed in the “Buying or selling a cryptocurrency” section above and (2) 1% only on the USD value of the cryptocurrency that you receive from your external wallet." +
              "<br /><br />For PYUSD, you will pay 1.5% on the U.S. dollar value of the PYUSD that you receive from your external wallet. You will not pay a transaction fee listed in the “Buying or selling a cryptocurrency” section above."
          }
        ]
      }
    ]
  }
};
