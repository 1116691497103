import React, { useState, useRef, useContext } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { each, fromPairs, map, toPairs } from "lodash";
import cn from "classnames";
import SvgIcon from '../../../../../common/components/svg-icon';
import { calculateGridQuota } from '../helper';
import { QuestionContext } from '../contexts';

const SQAGridColumns = ({
  question, questionIndex, screenerQuestions, setScreenerQuestions,
  setManageFivePtToggleProp, setFivePtScaleModeProp
}) => {
  const { setManageFivePtToggle, setFivePtScaleMode } = useContext(QuestionContext);
  const [ , setFocusLastOnEnter ] = useState(false);

  const manageFivePtToggleFunc = setManageFivePtToggleProp || setManageFivePtToggle;
  const fivePtScaleModeFunc = setFivePtScaleModeProp || setFivePtScaleMode;

  const refs = useRef([]);

  const handleOnDragEnd = (result) => {
    if (result?.destination?.index === undefined) {
      return;
    }

    each(question.condition_list, (el, index) => {
      const items = toPairs(el.cond);
      const [ reorderedItem ] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      question.condition_list[index].cond = fromPairs(map(items, (el, index) => ([ index, el?.[1] ])));

      const quotas = toPairs(el.quota);
      const [ quotaItem ] = quotas.splice(result.source.index, 1);
      quotas.splice(result.destination.index, 0, quotaItem);
      question.condition_list[index].quota = fromPairs(map(quotas, (el, index) => ([ index, el?.[1] ])));
    });

    const items = Array.from(question.scales);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    question.scales = items;

    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeAnswer = (event, index) => {
    question.scales[index] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const delAnswer = (event, answer, index) => {
    event.preventDefault();

    if (question.scales.length <= 1) {
      return;
    }

    question.scales.splice(index, 1);

    each(question.condition_list, (el, ind) => {
      const items = toPairs(el.cond);
      items.splice(index, 1);
      question.condition_list[ind].cond = fromPairs(map(items, (el, index) => ([ index, el?.[1] ])));

      const quotas = toPairs(el.quota);
      quotas.splice(index, 1);
      question.condition_list[ind].quota = fromPairs(map(quotas, (el, index) => ([ index, el?.[1] ])));
    });

    updateFivePtToggleStates();
    calculateGridQuota(question);
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const pushAnswer = () => {
    question.scales.push('');
    addCondition();
    updateFivePtToggleStates();
    calculateGridQuota(question);
  };

  const addCondition = () => {
    each(question.condition_list, (item) => {
      if (typeof item.cond === 'object') {
        item.cond[question.scales.length - 1] = "";
      }
    });
  };

  const addAnswer = (event) => {
    event.preventDefault();
    pushAnswer();
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const updateFivePtToggleStates = () => {
    if (!manageFivePtToggleFunc) {
      return;
    }
    const toggleEnableCondition = question.scales.length === 5;
    manageFivePtToggleFunc(toggleEnableCondition);
    if (question.scales.length !== 5) {
      fivePtScaleModeFunc(false);
      question.as_point_scale = false;
      setScreenerQuestions([ ...screenerQuestions ]);
    }
  };

  const extractData = (event) => {
    const text = event.clipboardData.getData('text/plain');
    const lines = text.replace(/\r/g, '').replace(/\n$/, '').split("\n");
    if (lines.length === 1 && lines[0].indexOf("\t") === -1) {
      return null;
    }
    event.preventDefault();
    return lines;
  };

  const handlePaste = (event, fromIndex) => {
    const lines = extractData(event);
    const answersOldLength = question.scales.length;
    if (lines != null) {
      each(lines, (line, li) => {
        const correctedLi = li + fromIndex;
        question.scales[correctedLi] = line;
      });
    }
    if (question.scales.length > answersOldLength) {
      calculateGridQuota(question);
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const answerChangeRef = (item, index) => {
    if (item) {
      refs.current[index] = item;
    }

    if (!item || question.scales.length - 1 !== index) {
      return;
    }
    setFocusLastOnEnter(
      (focusLastRequest) => {
        if (focusLastRequest) {
          item.focus();
        }
        return false;
      }
    );
  };

  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (question.scales.length - 1 === index) {
        addAnswer(event);
        addCondition();
        setFocusLastOnEnter(true);
      } else {
        refs.current[index + 1].focus();
      }
    }
  };

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <Droppable droppableId="answers-area">
        {
          (provided) => (
            <ul
              className="form-question_answers -margin-bottom -columns-list"
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              {
                map(question.scales, (answer, index) => (
                  <Draggable
                    key={ index }
                    draggableId={ `x${questionIndex}-${index}` }
                    index={ index }
                  >
                    {
                      (provided) => (
                        <li
                          id={ `x${questionIndex}-${index}` }
                          { ...provided.draggableProps }
                          ref={ provided.innerRef }
                          className="form-question_answer"
                        >
                          <div className="form-question_grid">
                            <div
                              className="form-question_grid-item -first-answer-item"
                            >
                              <div
                                className="form-question_answer-move"
                                { ...provided.dragHandleProps }
                              >
                                <SvgIcon name="i-move" />
                              </div>
                            </div>

                            <div
                              className={
                                cn(
                                  "form-question_grid-item -grow",
                                  { '-sm': question?.kind === "Grid" },
                                  { 'has-error': question?.errors?.scales },
                                )
                              }
                            >
                              <input
                                ref={ (item) => answerChangeRef(item, index) }
                                className="form-field -block"
                                type="text"
                                value={ question.scales[index] }
                                onChange={ (event) => {
                                  onChangeAnswer(event, index);
                                } }
                                onPaste={ (event) => handlePaste(event, index) }
                                onKeyPress={ (event) => handleKeyPress(event, index) }
                                placeholder="Add Answer Text"
                              />
                            </div>

                            <div className="form-question_grid-item -buttons">
                              <button
                                className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                onClick={ (event) => {delAnswer(event, answer, index);} }
                                disabled={ question.scales.length === 1 }
                              >
                                <SvgIcon name="i-delete" />
                              </button>

                              {
                                question.scales.length - 1 === index &&
                                <button
                                  className="button -circle form-question_grid-button"
                                  onClick={ (event) => {addAnswer(event);} }
                                >
                                  <SvgIcon name="i-add" />
                                </button>
                              }
                            </div>
                          </div>
                        </li>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </ul>
          )
        }
      </Droppable>
    </DragDropContext>
  );
};


export default SQAGridColumns;
