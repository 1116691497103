import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import SvgIcon from '../../../../common/components/svg-icon';
import RemotePopup from './remotePopup';
import PopupDetails from './popupDetails';
import { classByPosition } from './helper';

const InfoToolTip = ({
  text, img, imgClassName, children, remote,
  position = "bottom-center",
  className, attachTo, showPopup = true
}) => {
  const [ positionClass ] = useState(() => {
    classByPosition(position);
  });
  const [ showRemotePopup, setShowRemotePopup ] = useState(false);
  const hoverElement = useRef(null);

  const baseClassName = classnames("info-tooltip_drop -with-img", positionClass);

  const handleMouseOver = (event) => {
    remote && setShowRemotePopup(true);
  };
  const handleMouseLeave = (event) => {
    remote && setShowRemotePopup(false);
  };

  const details = (
    <PopupDetails text={ text } imageClassName={ imgClassName } imageSrc={ img }>
      { children }
    </PopupDetails>
  );

  return (
    <div
      className={ classnames("info-tooltip", className) }
      ref={ hoverElement }
      onMouseOver={ handleMouseOver }
      onMouseLeave={ handleMouseLeave }
    >
      {
        (attachTo ?
          attachTo :
          <div className="info-tooltip_control">
            <SvgIcon name="i-info" />
          </div>
        )
      }
      {
        showPopup &&
        <>
          {
            remote ? (
              <RemotePopup
                className={ baseClassName }
                position={ position }
                hoverElement={ hoverElement }
                show={ showRemotePopup }
              >
                { details }
              </RemotePopup>
            ) : (
              <div className={ baseClassName }>{ details }</div>
            )
          }
        </>
      }
    </div>
  );
};

export default InfoToolTip;
