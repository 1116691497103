import { map } from "lodash";
import React from "react";
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html';

const Plan = ({
  plan, onPlanSelect, selectedPlanIdByLine, planPrice
}) => {
  const clickSelectButton = (event, plan) => {
    onPlanSelect(event, plan);
  };

  const buttonIsDisabled = (plan) => (
    plan.id === selectedPlanIdByLine
  );

  const buttonLabel = 'Select plan';

  return (
    <div className="overview_plans-item">
      <div
        className={
          classnames(
            "plan-plate",
            { '-active': plan.id === selectedPlanIdByLine },
          )
        }
      >
        <h3 className="plan-plate_title" { ...renderRawHtml(plan.name) } />
        <div className={ classnames("plan-plate_pricing", plan.pricePlateKlass) }>
          <div className="plan-plate_pricing-grid -bau-box">
            <div className="plan-plate_pricing-value">
              ${ planPrice }
            </div>
            <div className="plan-plate_pricing-hint">
              <div className="plan-plate_pricing-hint-line">/mo.</div>
              <div className="plan-plate_pricing-hint-line">Plus taxes & fees</div>
            </div>
          </div>
        </div>
        <div className="plan-plate_features -base-features">
          {
            map(plan.features, (feature, index) => (
              <div
                className={ classnames("plan-plate_feature -bau", feature.klass) }
                key={ `feature-${index}` }
              >
                {
                  !!feature.title &&
                  <div className="plan-plate_feature-title -no-margin">{feature.title}</div>
                }
                <div className="plan-plate_feature-description">
                  { feature.description }
                </div>
              </div>
            ))
          }
        </div>
        {
          plan.perks && plan.perks.length &&
          <div className="plan-plate_features -perks">
            <div className="plan-plate_feature -perks-header">
              <div className="plan-plate_feature-title -no-margin">Perks:</div>
              {
                plan.perksNote &&
                <div className="plan-plate_feature-description -note">
                  { plan.perksNote }
                </div>
              }
            </div>
            {
              map(plan.perks, (perk, index) => (
                <div
                  className={ classnames("plan-plate_feature -perk  -bau", perk.klass) }
                  key={ `perk-${index}` }
                >
                  {
                    (!!perk.picture  || !!perk.image) &&
                    <div className="perk-plate_feature-image-grid">
                      <div className={ `perk-plate_feature-image -${perk.pictureKlass || perk.image}` }>
                        { !!perk.picture && <img src={ perk.picture } alt="image" /> }
                        {
                          !!perk.image &&
                          <svg>
                            <use xlinkHref={ `#svg_${perk.image}` } />
                          </svg>
                        }
                      </div>
                    </div>
                  }
                  {
                    !!perk.title &&
                    <div className="plan-plate_feature-title -no-margin">{perk.title}</div>
                  }
                  {
                    !!perk.description &&
                    <div className="plan-plate_feature-description">
                      { perk.description }
                    </div>
                  }
                </div>
              ))
            }
          </div>
        }
        <div className="plan-plate_bottom">
          <div className="plan-plate_button">
            {
              !buttonIsDisabled(plan) &&
              <button
                className="button -bordered"
                onClick={ (event) => { clickSelectButton(event, plan, true); } }
              >
                { buttonLabel }
              </button>
            }
            {
              buttonIsDisabled(plan) &&
              <button className="button -bordered" disabled>
                { buttonLabel }
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
