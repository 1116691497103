import React from 'react';
import cn from "classnames";

const EditorCell1 = ({ value, editing, saving, tempText, setTempText, className, handleSave }) => {
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSave();
    }
  };

  return (
    <div className={ cn("form_section-text -inline", className) }>
      {!editing && <span>{value}</span>}
      {editing &&
      <input
        className="form-field -full-width-standalone"
        type="text"
        value={ tempText }
        onChange={ (event) => { setTempText(event.target.value); } }
        onKeyDown={ handleKeyPress }
        disabled={ saving }
      />}
    </div>
  );
};

export default EditorCell1;
