import React, { useContext } from 'react';
import { map } from 'lodash';
import { LineDataContext } from './contexts';

export const AllPlansInclude = ({ customTitle }) => {
  const { allPlansInclude } = useContext(LineDataContext);

  return (
    <div className="all-features">
      <div className="all-features_title">
        { customTitle || 'All plans include:' }
      </div>
      <div className="all-features_list">
        {
          map(allPlansInclude, (item, index) => (
            <span className="all-features_list-item" key={ `item-${index}` } >
              {index > 0 ? ', ' : '' }{item.title}
            </span>
          ))
        }
      </div>
    </div>
  );
};
