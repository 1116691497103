import React, { useEffect, useRef, useState, useContext } from 'react';
import { flushSync } from 'react-dom';
import {
  usePosition, INITIAL_SIDE_SIZE,
  check, notLessThan,
  notMoreThan
} from '../helper';
import Rect from './rect';
import Point from './point';
import { OptionsContext } from './contexts';
import DeleteButton from './delete_button';

// initialArea = { width, height, left, top, imageWidth, imageHeight }
const SelectionLayer = ({ index, initialArea, wrapperRef, onLoadDimensionsChange }) => {
  const [ higherIndex, setHigherIndex ] = useState(false);
  const [ rectStartPosition, setRectStartPosition ] = useState({ left: 0, top: 0 });
  const { position, setPosition, getSize, updatePosition } = usePosition({ x1: 0, x2: 0, y1: 0, y2: 0 });
  const { x1, x2, y1, y2 } = position;
  const { width, height } = getSize();
  const scrollContainer = useRef(null);
  const {
    viewOnly,
    onSelectionChange,
    getOriginSize, setOriginSize,
    scrollContainerElement,
    withDelete, handleDelete
  } = useContext(OptionsContext);
  const originSize = getOriginSize();
  const [ , setPreviousOriginSize ] = useState(originSize);

  const handleOnChange = (left, top, width, height, imageWidth, imageHeight) => {
    if (viewOnly) {
      return;
    }
    const newImageWidth =  (imageWidth || originSize.width);
    const newImageHeight = (imageHeight || originSize.height);

    onSelectionChange({
      left, top, width, height,
      imageWidth: newImageWidth, imageHeight: newImageHeight
    }, index);
  };

  const updateProportion = () => {
    if (!wrapperRef || !wrapperRef.current) {
      return {};
    }
    let currentOriginSize;
    flushSync(() => {
      setPreviousOriginSize((oldSaved) => {
        currentOriginSize = oldSaved;
        return oldSaved;
      });
    });
    const newDimensions = wrapperRef.current.getBoundingClientRect();
    const newImageWidth = newDimensions.width;
    const newImageHeight = newDimensions.height;
    const widthDiff = currentOriginSize ? newImageWidth / currentOriginSize.width : 0;
    const heightDiff = currentOriginSize ? newImageHeight / currentOriginSize.height : 0;
    setOriginSize({ width: newImageWidth, height: newImageHeight });
    return { widthDiff, heightDiff, newImageWidth, newImageHeight };
  };

  useEffect(() => {
    const onResize = () => {
      const updatedProportion = updateProportion();
      let pos;
      flushSync(() => {
        setPosition((oldPos) => {
          pos = oldPos;
          return oldPos;
        });
      });
      const position = {
        x1: pos.x1 * updatedProportion.widthDiff,
        y1: pos.y1 * updatedProportion.heightDiff,
        x2: pos.x2 * updatedProportion.widthDiff,
        y2: pos.y2 * updatedProportion.heightDiff
      };
      if (position.x2 && position.y2) {
        updatePosition(position, false);
        if (updatedProportion) {
          const width = updatedProportion.newImageWidth;
          const height = updatedProportion.newImageHeight;
          setPreviousOriginSize({ width, height });
          handleOnChange(
            position.x1, position.y1,
            (position.x2 - position.x1),
            (position.y2 - position.y1),
            width, height
          );
        }
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ ]);

  useEffect(() => {
    scrollContainer.current = (
      scrollContainerElement ||
      document.querySelector('.content_body').parentNode
    );
    if (!wrapperRef || !wrapperRef.current) {
      return;
    }
    let result;
    if (initialArea && initialArea.width) {
      const widthDiff = originSize.width / initialArea.imageWidth;
      const heightDiff = originSize.height / initialArea.imageHeight;
      result = {
        x1: initialArea.left * widthDiff,
        y1: initialArea.top * heightDiff,
        x2: (initialArea.left + initialArea.width) * widthDiff,
        y2: (initialArea.top + initialArea.height) * heightDiff
      };
    } else {
      const { offsetWidth, offsetHeight } = wrapperRef.current;
      const widthHalf = offsetWidth / 2;
      const heightHalf = offsetHeight / 2;
      const horizontalSize = (offsetWidth >= INITIAL_SIDE_SIZE ?  INITIAL_SIDE_SIZE : offsetWidth) / 2;
      const verticalSize = (offsetHeight >= INITIAL_SIDE_SIZE ?  INITIAL_SIDE_SIZE : offsetHeight) / 2;
      result = {
        x1: widthHalf - horizontalSize,
        x2: widthHalf + horizontalSize,
        y1: heightHalf - verticalSize,
        y2: heightHalf + verticalSize
      };
    }
    updatePosition(result);
    handleOnChange(
      result.x1, result.y1,
      (result.x2 - result.x1), (result.y2 - result.y1)
    );
  }, [ wrapperRef, initialArea, onLoadDimensionsChange ]);

  useEffect(() => {
    if (onLoadDimensionsChange?.width && onLoadDimensionsChange?.height) {
      setOriginSize(onLoadDimensionsChange);
      setPreviousOriginSize(onLoadDimensionsChange);
    }
  }, [ onLoadDimensionsChange ]);

  const updateResult = () => {
    handleOnChange(
      position.x1, position.y1,
      (position.x2 - position.x1),
      (position.y2 - position.y1)
    );
  };

  const updatePointPosition = (xName, yName) => ({ clientX, clientY }, { diffX, diffY }) => {
    const rect = wrapperRef.current.getBoundingClientRect();
    const tempX = Math.round(clientX) - Math.round(rect.left) + (diffX || 0);
    const tempY = Math.round(clientY) - Math.round(rect.top) + (diffY || 0);
    const position = {
      [xName]: check([
        notLessThan(0),
        notMoreThan(rect.width)
      ], tempX),
      [yName]: check([
        notLessThan(0),
        notMoreThan(rect.height)
      ], tempY)
    };
    updatePosition(position);
  };

  const updateRectPosition = ({ clientX, clientY }) => {
    const rect = wrapperRef.current.getBoundingClientRect();

    const tempLeft = Math.round(clientX) - Math.round(rect.left) + scrollContainer.current.scrollLeft - rectStartPosition.left;
    const tempTop = Math.round(clientY) - Math.round(rect.top) + scrollContainer.current.scrollTop - rectStartPosition.top;
    const position = {
      x1: check([
        notLessThan(0),
        notMoreThan(rect.width),
        notMoreThan(rect.width - width)
      ], tempLeft),
      y1: check([
        notLessThan(0),
        notMoreThan(rect.height),
        notMoreThan(rect.height - height)
      ], tempTop),
      x2: check([
        notLessThan(0),
        notMoreThan(rect.width),
        notLessThan(width)
      ], tempLeft + width),
      y2: check([
        notLessThan(0),
        notMoreThan(rect.height),
        notLessThan(height)
      ], tempTop + height)
    };
    updatePosition(position);
  };

  const startUpdatePosition = ({ clientX, clientY }) => {
    const rect = wrapperRef.current.getBoundingClientRect();

    const left = Math.round(clientX) - Math.round(rect.left) + scrollContainer.current.scrollLeft - x1;
    const top = Math.round(clientY) - Math.round(rect.top) + scrollContainer.current.scrollTop - y1;
    setRectStartPosition({ left, top });
  };

  const handleOnHoverStart = () => {
    setHigherIndex(true);
  };
  const handleOnHoverEnd = () => {
    setHigherIndex(false);
  };
  return (
    <>
      <Rect
        higherIndex={ higherIndex }
        width={ width }
        height={ height }
        left={ x1 }
        top={ y1 }
        onMouseEnter={ handleOnHoverStart }
        onMouseLeave={ handleOnHoverEnd }
        onPositionChange={ updateRectPosition }
        onStartPositionChange={ startUpdatePosition }
        onEndPositionChange={ updateResult }
        areaOptions={ initialArea }
      />
      {
        !viewOnly &&
        <>
          <Point
            higherIndex={ higherIndex }
            kind="tl"
            className="-top-left"
            left={ x1 }
            top={ y1 }
            onMouseEnter={ handleOnHoverStart }
            onMouseLeave={ handleOnHoverEnd }
            onPositionChange={ updatePointPosition('x1', 'y1') }
            onEndPositionChange={ updateResult }
          />
          <Point
            higherIndex={ higherIndex }
            kind="tr"
            className="-top-right"
            left={ x2 }
            top={ y1 }
            onMouseEnter={ handleOnHoverStart }
            onMouseLeave={ handleOnHoverEnd }
            onPositionChange={ updatePointPosition('x2', 'y1') }
            onEndPositionChange={ updateResult }
          />
          <Point
            higherIndex={ higherIndex }
            kind="br"
            className="-bottom-right"
            left={ x2 }
            top={ y2 }
            onMouseEnter={ handleOnHoverStart }
            onMouseLeave={ handleOnHoverEnd }
            onPositionChange={ updatePointPosition('x2', 'y2') }
            onEndPositionChange={ updateResult }
          />
          {
            withDelete &&
            <DeleteButton
              higherIndex={ higherIndex }
              className="-bottom-left"
              left={ x1 }
              top={ y2 }
              onMouseEnter={ handleOnHoverStart }
              onMouseLeave={ handleOnHoverEnd }
              onClick={ () => { handleDelete(index); } }
            />
          }
          {
            !withDelete &&
            <Point
              higherIndex={ higherIndex }
              kind="bl"
              className="-bottom-left"
              left={ x1 }
              top={ y2 }
              onMouseEnter={ handleOnHoverStart }
              onMouseLeave={ handleOnHoverEnd }
              onPositionChange={ updatePointPosition('x1', 'y2') }
              onEndPositionChange={ updateResult }
            />
          }
        </>
      }
    </>
  );
};

export default SelectionLayer;
