import React, { useState, useEffect, useContext } from 'react';
import { map, each, keys, find, values, compact, remove }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { onPasteDetected, prepareParams, sendToFraudCheck } from "../logic/fraud_check";
import { fraudContext } from "../contexts";
import FooterButton from './footer_button.js';

const MultiText = ({
  question, nextStep, result, resultField
}) => {
  const questionText = question.text;
  const answers = question.answers;
  const { respondentId, projectId, antiFraud, setFraudProcessing } = useContext(fraudContext);

  const [ disabled, updateDisabled ] = useState(true);
  const [ textAnswers, setTextAnswers ] = useState({});
  const [ checkboxAnswers, setCheckboxAnswers ] = useState({});

  const isOther = (id) => ((id || '').match(/_-2/));
  const isNone = (id) => ((id || '').match(/_-1/));

  const updateDisabledByKind = (kind) => {
    let newDisabled;

    if (kind === 'checkbox') {
      newDisabled = !isCheckboxNoneSelected();
    } else if (kind === 'text') {
      newDisabled = compact(values(textAnswers)).length === 0;
    }
    updateDisabled(newDisabled);
  };

  useEffect(() => {
    updateDisabledByKind('text');
  }, [ textAnswers ]);

  useEffect(() => {
    updateDisabledByKind('checkbox');
  }, [ checkboxAnswers ]);

  const nextQuestion = () => {
    const answers = compact(values(textAnswers));
    result[resultField] = answers;

    each(keys(checkboxAnswers), (key) => {
      if (checkboxAnswers[key]) {
        result.answers.push(key);
      } else {
        remove(result.answers, (el) => (el === key));
      }
    });

    setCheckboxAnswers({});
    setTextAnswers({});
    if (answers.length) {
      checkForFraud(answers);
    } else {
      nextStep();
    }
  };

  const checkForFraud = (answers) => {
    const params = prepareParams(
      { antiFraud, projectId, respondentId, checkMethods: [ 'profanity' ] },
      [ answers.join(" ") ]
    );
    sendToFraudCheck(params, nextStep, setFraudProcessing);
  };

  const isCheckboxNoneSelected = () => (
    find(keys(checkboxAnswers), (key) => (isNone(key) && checkboxAnswers[key]))
  );


  return (
    <>
      <div className="survey-layout_content">
        <div className="survey-options_question -one-question">
          <div className="survey-options_question-title  -center">
            <div className="survey-options_question-title-inner" { ...renderRawHtml(questionText) } />
          </div>
        </div>
        <div className={ classnames("survey-layout_container", "-one-question", "-two-columns") }>
          <div className={ classnames("survey-options", "-screening") }>
            <TextInputs
              answers={ answers }
              isNone={ isNone }
              isOther={ isOther }
              placeholder={ question.placeholder }
              textAnswers={ textAnswers }
              setTextAnswers={ setTextAnswers }
              checkboxAnswers={ checkboxAnswers }
              setCheckboxAnswers={ setCheckboxAnswers }
              isCheckboxNoneSelected={ isCheckboxNoneSelected }
              onPaste={ () => { onPasteDetected(result); } }
            />
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton
          nextStep={ () => { nextQuestion(); } }
          options={ { disabled } }
        />
      </div>
    </>
  );
};

const TextInputs = ({
  answers, isNone, isOther, textAnswers, placeholder, setTextAnswers, checkboxAnswers, setCheckboxAnswers,
  isCheckboxNoneSelected, onPaste
}) => {
  const textChange = (value, answerId) => {
    textAnswers[answerId] = value;
    setTextAnswers({ ...textAnswers });
  };

  const checkboxChange = (value, answerId) => {
    checkboxAnswers[answerId] = value;
    setCheckboxAnswers({ ...checkboxAnswers });
    setTextAnswers({});
  };

  return (
    <ul className={ classnames("survey-options_list") }>
      {
        map(answers, (answer) => (
          <li key={ answer.id } className={ classnames({ 'option-top-border': isNone(answer.id) }) }>
            <div className="option-list-inner">
              {!isOther(answer.id) && !isNone(answer.id) &&
                <input
                  className="form-field"
                  type="text"
                  disabled={ isCheckboxNoneSelected() }
                  value={ textAnswers[answer.id] || '' }
                  onChange={ (event) => { textChange(event.target.value, answer.id); } }
                  placeholder={ placeholder }
                  onPaste={ onPaste }
                />
              }
              {isNone(answer.id) &&
              <label className="checkbox -full-width">
                <input
                  type="checkbox"
                  checked={ !!checkboxAnswers[answer.id] }
                  onChange={ (event) => { checkboxChange(event.target.checked, answer.id); } }
                />
                <div className="checkbox_label -centered -full-width">
                  <div className="other-text_wrapper">
                    <div className="other-text_title" { ...renderRawHtml(answer.label) } />
                  </div>
                </div>
              </label>
              }
            </div>
          </li>
        ))
      }
    </ul>
  );
};

export default MultiText;

