import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { AtomicBlockUtils, EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classnames from "classnames";
import { map } from "lodash";
import { generateId } from '../../../verizon/verizon-5g/helpers';
import ColorPicker from "./colorPicker";
import HtmlCleaner from './htmlCleaner';
import { EditorContext } from './contexts';

// https://jpuri.github.io/react-draft-wysiwyg/#/docs

const Wysiwyg = ({
  placeholder, value, onChange,
  availableFontSizes, availableColors, enableIndent, enableAlign,
  toolbarClassName, editorClassName, wrapperClassName,
  customPicker
}) => {
  const [ id ] = useState(generateId());
  // https://github.com/jpuri/react-draft-wysiwyg/issues/609
  // Fix for the error: Invariant Violation: Unknown DraftEntity key: null.
  // This bug happened when the incoming HTML was missing a prefixed <p></p> tag.
  // When prepending a <p></p> to the incoming html it fixed the error.
  const getCheckedValue = () => {
    let text = value || '';
    if (!text.match(/^<p/)) {
      text = `<p>${text}</p>`;
    }
    return text;
  };
  const initState = () => {
    const contentBlock = htmlToDraft(getCheckedValue());
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const htmlFromState = (state) => (
    draftToHtml(convertToRaw(state.getCurrentContent()))
  );

  const [ editorState, updateEditorState ] = useState(initState());

  useLayoutEffect(() => {
    if (value !== htmlFromState(editorState)) {
      const newEditorState = EditorState.push(
        editorState,
        initState().getCurrentContent(),
        'insert-characters'
      );
      updateEditorState(newEditorState);
    }
  }, [ value ]);

  // HACK: We need to re-render the wysiwyg component
  // when the environment is loaded up,
  // otherwise you will not see dropdowns in popup
  const withDropdowns = availableFontSizes || availableColors;
  const [ array, setArray ] = useState(withDropdowns ? [] : [ 1 ]);
  useEffect(() => {
    if (withDropdowns) {
      setArray([ 1 ]);
    }
  }, []);

  const onEditorStateChange = (state) => {
    updateEditorState(state);
    onChange(htmlFromState(state));
  };

  const options = {
    options: [ 'inline', 'list', 'remove', 'history' ],
    inline: {
      options: [ 'bold', 'italic', 'underline' ]
    },
    list: {
      options: [ 'unordered', 'ordered' ]
    },
    history: {
      options: [ 'undo', 'redo' ]
    },
    remove: { component: HtmlCleaner }
  };

  if (enableAlign) {
    options.options.push('textAlign');
    options.textAlign = {
      options: [ 'left', 'center', 'right' ]
    };
  }

  if (availableFontSizes) {
    options.options.push("fontSize");
    options.fontSize = {
      options: availableFontSizes
    };
  }

  if (customPicker) {
    options.options.push("colorPicker");
    options.colorPicker = { component: ColorPicker };
  } else if (availableColors === 'default') {
    options.options.push("colorPicker");
  } else if (availableColors) {
    options.options.push("colorPicker");
    options.colorPicker = {
      colors: availableColors
    };
  }
  if (enableIndent) {
    options.list.options.push('indent', 'outdent');
  }

  const editorContextValue = {
    getHtml: () => (value),
    setHtml: (newValue) => { onChange(newValue); }
  };

  // https://daveteu.medium.com/draftjs-insert-paste-images-into-your-content-820159025258
  // Inserting of an image as base64 on copy/paste

  const insertImage = (base64) => {
    let currentState;

    updateEditorState((oldState) => {
      currentState = oldState;
      return oldState;
    });

    const contentState = currentState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { src: base64, alt: 'insertedImage' },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      currentState,
      { currentContent: contentStateWithEntity },
    );
    const resultingEditorState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState, entityKey, ' '
    );
    onEditorStateChange(resultingEditorState);
  };

  const handlePaste = (currentState, event) => {
    if (
      !event?.clipboardData?.files.length ||
      !event?.clipboardData?.types.length ||
      (
        event?.clipboardData?.types.length &&
        event.clipboardData.types[0].match(/plain/)
      )
    ) {
      return;
    }

    const file = event?.clipboardData?.files[0];
    const reader = new FileReader();

    reader.addEventListener("load",  () => {
      // convert image file to base64 string
      insertImage(reader.result);
    }, false);

    reader.readAsDataURL(file);
  };

  return (
    <EditorContext.Provider value={ editorContextValue }>
      {
        map(array, () => (
          <Editor
            key={ `wysiwyg${id}` }
            stripPastedStyles
            placeholder={ placeholder }
            editorState={ editorState }
            toolbarClassName={ classnames("draft-toolbar-custom", toolbarClassName) }
            wrapperClassName={ classnames("draft-wrapper-custom", wrapperClassName) }
            editorClassName={ classnames("draft-editor-custom", editorClassName) }
            onEditorStateChange={ onEditorStateChange }
            toolbar={ options }
            onPaste={ handlePaste }
          />
        ))
      }
    </EditorContext.Provider>

  );
};

export default Wysiwyg;
