import React, { useContext } from 'react';
import { map } from "lodash";
import classnames from 'classnames';
import SvgIcon from '../../../../../common/components/svg-icon';
import { QuestionContext } from '../contexts';
import ToggleFivePtScale from "./ToggleFivePtScale";
import DisplayOnDedicatedPage from './DisplayOnDedicatedPage';
import QuestionLocation from "./QuestionLocation";

const SQAHeader = ({ options, question, screenerQuestions, setScreenerQuestions, title, noToggles, needLocation }) => {
  const onChangeAnswersOrders = (event) => {
    question.answers_order = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };
  const {
    needLogicEdit, manageFivePtToggle, fivePtScaleMode, setFivePtScaleMode,
    needFivePtScaleToggle, needDedicatedPage
  } = useContext(QuestionContext);

  const hasToggleFivePtScale = !noToggles && needFivePtScaleToggle && manageFivePtToggle;
  const hasDisplayOnDedicatedPage = !noToggles && needDedicatedPage;
  const hasAnyControls = hasToggleFivePtScale || hasDisplayOnDedicatedPage || needLocation;

  return (
    <>
      {
        hasAnyControls &&
        <div className="form_section-title -grid-between -custom-controls">
          <div className="form_section-title-block">
            {(hasToggleFivePtScale || hasDisplayOnDedicatedPage) &&
              <div className="form_section-title-item">
                {
                  hasToggleFivePtScale &&
                  <ToggleFivePtScale
                    question={ question }
                    questions={ screenerQuestions }
                    setQuestions={ setScreenerQuestions }
                    manageFivePtToggle={ manageFivePtToggle }
                    fivePtScaleMode={ fivePtScaleMode }
                    setFivePtScaleMode={ setFivePtScaleMode }
                  />
                }
                {
                  hasDisplayOnDedicatedPage &&
                  <DisplayOnDedicatedPage
                    question={ question }
                    screenerQuestions={ screenerQuestions }
                    setScreenerQuestions={ setScreenerQuestions }
                    customMetricsDedicatedPage={ options.custom_metrics_dedicated_page }
                  />
                }
              </div>
            }
          </div>
          {
            needLocation &&
            <div className="form_section-title-item -grid">
              <QuestionLocation
                question={ question }
                screenerQuestions={ screenerQuestions }
                setScreenerQuestions={ setScreenerQuestions }
              />
            </div>
          }
        </div>
      }

      <h3 className="form_section-title">
        <div className="form-question_grid -title">
          <div className="form-question_grid-item -grow">
            { title }
            {
              !fivePtScaleMode && title !== "Columns" &&
              <span
                className={
                  classnames(
                    "form_inline-select",
                    `-${question.answers_order}`
                  )
                }
              >
                <select
                  className="js-random-order"
                  value={ question.answers_order }
                  onChange={ onChangeAnswersOrders }
                >
                  {
                    map(options.order, (value, name) => (
                      <option key={ name } value={ name }>
                        { value }
                      </option>
                    ))
                  }
                </select>
              </span>
            }
          </div>

          {
            needLogicEdit && question.kind !== "Grid" &&
            <div className="form-question_grid-item -select">
              <div className="form_section-title -grid">
                <div className="form_section-title-item">
                  Logic
                </div>
                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <SvgIcon name="i-info" />
                    </div>
                    <div className="info-tooltip_drop -top -big">
                      <div className="info-tooltip_drop-inner">
                        <span className="logic">Must select</span>
                        <span>&mdash; Respondent must select the answer to qualify</span>
                        <br />
                        <span className="logic">Must not select</span>
                        <span>&mdash; Respondent must not select the answer to qualify</span>
                        <br />
                        <span className="logic">Neutral</span>
                        <span>&mdash; Respondent can select the answer or not</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="form-question_grid-item -buttons" />
        </div>
      </h3>
    </>

  );
};

export default SQAHeader;
