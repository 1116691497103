import React, { useEffect }  from 'react';
import { createPortal } from 'react-dom';

const root = document.querySelector('.portal-content#portal-id');

const Portal = ({ children }) => {
  useEffect(() => {
    const rootContent = document.querySelectorAll('body');
    rootContent.forEach((content) => content.style.overflow = 'hidden');
    return () => {
      rootContent.forEach((content) => content.style.overflow = '');
    };
  });
  return createPortal(children, root);
};

const Modal = ({ title, handleClose, className, children }) => {
  return (
    <Portal>
      <div className={ `modal ${className ? className : ''}` }>
        <div className="modal_backdrop" onClick={ handleClose } />
        <div className="modal_dialog">
          <div className="modal_header">
            {title && <h6 className="modal_title">{title}</h6>}
            <button className="modal_close" onClick={ handleClose } type="button">
              <svg>
                <use xlinkHref={ `#svg_close` } />
              </svg>
            </button>
          </div>
          <div className="modal_content">
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
