import React, { useState, useContext } from 'react';
import { map, each, maxBy, keys } from 'lodash';
import classnames from 'classnames';
import Modal from '../../../common/components/modal';
import {
  invalidGridQuotas,
  totalGridQuotaSum
} from './logic/quotas_validation';
import QuotaModeSwitcher from './quotaMode/components/quotaModeSwitcher';
import { QuotaModeLocalContext } from './quotaMode/contexts';

const EditGridQuota = ({
  question, showCross, linkTitle, title, onUpdate,
  createList, convertOnSave, platformName
}) => {
  const [ list, updateList ] = useState([]);
  const [ showForm, updateShowForm ] = useState(false);
  const [ errors, updateErrors ] = useState([]);
  const { forceChangeGlobalValueToLocal } = useContext(QuotaModeLocalContext);

  const error = (errors || [])[0];

  const updateListIds = (newList) => {
    each(
      newList || list,
      (item, index) => {
        item.id = index;
      }
    );
  };

  const openModal = (event) => {
    event.preventDefault();

    const newList = JSON.parse(JSON.stringify(createList()));
    updateListIds(newList);
    updateList(newList);
    updateShowForm(true);
  };

  const onClose = (event) => {
    event.preventDefault();
    updateShowForm(false);
    updateErrors([]);
  };

  const valid = () => {
    let quotaMessages = null;

    if (question.manage_quota) {
      quotaMessages = invalidGridQuotas(question, list);
    }

    if (quotaMessages) {
      updateErrors(quotaMessages || []);
    } else {
      updateErrors([]);
    }
    return !quotaMessages;
  };

  const onSave = (event) => {
    event.preventDefault();
    if (valid()) {
      forceChangeGlobalValueToLocal();
      convertOnSave(list);
      onUpdate(event, question);
      updateShowForm(false);
    }
  };

  const changeText = (event, item, field) => {
    item.quota[field] = event.target.value;
    updateErrors([]);
  };

  const renderListItem = (item, index) => {
    return (
      <div key={ `listItem-${index}` }>
        <div className="question-headers">
          <h3 className="header-grid-item">{ item.answer || '' }</h3>
          {
            question.manage_quota &&
            <h3 className="header-item">
              Quotas, % (Total: { totalGridQuotaSum(list[index]) }%)
            </h3>
          }
        </div>
        {
          map(keys(item.selected), (key) => (
            <div key={ `listItem-${index}-${key}` } className="question-list-item">
              <span className="readonly-title -bordered">{ question.scales[key] || '' }</span>
              {
                question.manage_quota &&
                <input
                  type="text"
                  className={
                    classnames(
                      'question-list-input -grid form-field -list-right-margin -quota',
                      { 'has-error': item.highlightQuota }
                    )
                  }
                  value={ item.selected[key] ? (item.quota[key] || "") : "" }
                  onChange={ (event) => { changeText(event, item, key); } }
                  disabled={ !item.selected[key] }
                />
              }
            </div>
          ))
        }
      </div>
    );
  };

  const maxByAnswerLength = (
    (
      maxBy(
        list || [],
        (item) => (
          (item.answer || "").length
        )
      ) || {}
    ).answer || {}
  ).length || 0;

  return (
    <>
      <a className="quotas" href="#" onClick={ (event) => openModal(event) }>{ linkTitle }</a>
      {
        showForm &&
        <Modal
          className={ classnames("-quota", { "-long-items": maxByAnswerLength >= 20 }) }
          dialogClassName={`-fit-window -min-width ${platformName ? platformName + '-modifier':''}`}
          backDropModifier={platformName ? platformName + '-modifier':''}
          showCross={ showCross }
          onClose={ (event) => { onClose(event); } }
        >
          <>
            <h2 className="normal-popup-title">
              { question.uiTitle() } &rarr; { title }
            </h2>
            {
              !question.manage_quota && !error &&
              <div className="normal-popop-description">
                Here you can update question's options.
              </div>
            }
            {
              question.manage_quota && !error &&
              <div className="normal-popop-description">
                Please note your percent cannot be
                { ' ' }
                set to 0% and the sum of grid row available options should equal 100%.
              </div>
            }
            {
              error &&
              <div className="quota-error-message">
                <div>{ error }</div>
              </div>
            }
            { question.manage_quota && <QuotaModeSwitcher /> }
            <div className="question-list">
              { map(list, (item, index) => renderListItem(item, index)) }
            </div>
            <div className="normal-popup-buttons">
              <button
                className="button"
                type="button"
                onClick={ (event) => { onSave(event); } }
              >
                Save
              </button>
              <button
                className="button -secondary"
                type="button"
                onClick={ (event) => { onClose(event); } }
              >
                Cancel
              </button>
            </div>
          </>
        </Modal>
      }
    </>
  );
};

export default EditGridQuota;
