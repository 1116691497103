import React, { memo, useRef, useState, useEffect, useCallback } from 'react';

const Sticky = ({ stickTo = 'top',  children }) => {
  const className = `verison-tv-sticky -${stickTo}`;

  return <div className={className} >
    {children}
  </div>;
}

export default memo(Sticky)