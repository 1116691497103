import React, { useContext, useState, useEffect } from 'react';
import { map, remove } from 'lodash';
import classnames from 'classnames';
import { LocalizationContext } from '../../../common/contexts';
import { CartContext } from '../../contexts';
import ModalWindow from '../../../../../modal_window';
import ZoomImage from '../../../../../zoom_image';
import { priceView } from '../../helper';
import FooterButton from '../../../../../footer_button';

const PopupFrame = ({ onClose, children }) => {
  const [ modalView, setModalView ] = useState(false);
  const [ updatingView ] = useState(
    () => () => {
      // Because cart button is not in the actual center of the screen,
      // we need to position it relatively by button until 450px.
      // And for the full screen width if screen width is less.
      const mobileBreak640 = 640;
      const currentWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
      setModalView((currentWidth <= mobileBreak640));
    }
  );

  useEffect(() => {
    const clickOutside = () => {
      onClose();
    };
    updatingView();
    window.addEventListener('resize', updatingView);
    document.addEventListener('click', clickOutside);

    return () => {
      window.removeEventListener('resize', updatingView);
      document.removeEventListener('click', clickOutside);
    };
  }, []);

  return (
    <>
      <ModalWindow className="cart-modal" style={ modalView ? "" : "display: none;" }>
        <div className="cart-modal-close" onClick={ onClose } />
        { children }
      </ModalWindow>
      <div className="cart-popup-relative" style={ modalView ? { display: 'none' } : {} }>
        <div className="cart-modal-close" onClick={ onClose } />
        { children }
      </div>
    </>
  );
};

const CartPopup = ({ onClose }) => {
  const {
    cartContentTitle, currency, cartEmptyContent,
    maxBudgetTitle, cartTotalTitle, customEnlarge,
    productDelete, cartComplete
  } = useContext(LocalizationContext);
  const {
    getSelectedProducts, updateSelectedProducts, withPrices,
    setShowPopup, itemsSum, notFitBudget, budgetLimit,
    nextDisabled, handleNext
  } = useContext(CartContext);
  const products = getSelectedProducts();
  const handleDecreaseAmount = (product) => () => {
    product.amount -= 1;
    updateSelectedProducts([ ...products ]);
  };
  const handleIncreaseAmount = (product) => () => {
    product.amount += 1;
    updateSelectedProducts([ ...products ]);
  };

  const handleDeleteProduct = (product) => () => {
    remove(products, (item) => item.id === product.id);
    updateSelectedProducts([ ...products ]);
    if (!products.length) {
      setShowPopup(false);
    }
  };

  const handlePopupBodyClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const outOfBudget = notFitBudget();
  const totalSum = itemsSum();

  return (
    <PopupFrame onClose={ onClose }>
      <div className="cart-popup" onClick={ handlePopupBodyClick }>
        <div className="cart-popup-title">
          <h3>{ cartContentTitle }</h3>
          {
            withPrices &&
            <div className="cart-total-section">
              {
                budgetLimit &&
                <span className="cart-budget-title">
                  { maxBudgetTitle }
                  { ' ' }
                  { priceView(currency, budgetLimit) }
                  {' / '}
                </span>
              }
              <span
                className={
                  classnames(
                    "cart-total-title",
                    { "-error": outOfBudget }
                  )
                }
              >
                <span>{ cartTotalTitle }</span>
                { priceView(currency, totalSum) }
              </span>
            </div>
          }
        </div>
        <div
          className={
            classnames(
              "cart-table",
              { "-overflow-hidden": !products.length }
            )
          }
        >
          {
            !products.length &&
            <div className="empty-cart-content">
              <p>{ cartEmptyContent }</p>
            </div>
          }
          {
            map(products, (product, index) => (
              <div className="cart-product" key={ `cartProduct${product.id}-${index}` }>
                <div className="cart-product-image">
                  <div className="cart-product-image-item">
                    <ZoomImage
                      className="survey-options_image"
                      src={ product.url }
                      customNote={ customEnlarge }
                    >
                      <img src={ product.url } alt={ product.name } />
                    </ZoomImage>
                  </div>
                </div>
                <div className="cart-product-details">
                  <div className="cart-product-title-and-amount">
                    <div className="cart-product-title">{ product.name }</div>
                    {
                      withPrices &&
                      <div className="cart-price-font">
                        { priceView(currency, product.price) }
                      </div>
                    }
                  </div>
                  <div className="cart-product-actions">
                    <div className="cart-product-amount-section">
                      <div className="cart-product-amount">
                        <button
                          className="button amount-button -left"
                          disabled={ product.amount < 2 }
                          onClick={ handleDecreaseAmount(product) }
                        />
                        <div className="amount-text">{ product.amount }</div>
                        <button
                          className="button amount-button -right"
                          onClick={ handleIncreaseAmount(product) }
                        />
                      </div>
                    </div>
                    <div className="cart-product-delete">
                      <button
                        onClick={ handleDeleteProduct(product) }
                        className="button product-cart-delete-button"
                      />
                      <span onClick={ handleDeleteProduct(product) }>
                        { productDelete }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="actions">
          <FooterButton
            customName={ cartComplete }
            className="-cart-next-button"
            options={ { disabled: nextDisabled() } }
            nextStep={ handleNext }
          />
        </div>
      </div>
    </PopupFrame>
  );
};

export default CartPopup;
