import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

export const Header = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);
  return (

    <header className="header">
      <div className="header_top" />
      <div className="header_main">
        <div className="container">
          <div className="header_grid">
            <div className="header_grid-item">
              <div className="header_logo">
                <svg className="icon -header-logo">
                  <use xlinkHref="#svg_verizon-logo" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
