import React, { useState, useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import { each, filter, find, map } from "lodash";
import cn from "classnames";
import SvgIcon from '../../../../../common/components/svg-icon';
import {
  calculateGridQuotaItem,
  calculateQuotaForCustomScreening,
  reIndexConditionList,
  findGridConditionItem
} from '../helper';
import { QuestionContext } from '../contexts';
import ConditionSelect from './lines/conditionSelect';

const SQAGridRows = ({
  question, questionIndex, screenerQuestions, setScreenerQuestions
}) => {
  const [ , setFocusLastOnEnter ] = useState(false);
  const {
    needLogicEdit, needRows,
    setManageFivePtToggle, setFivePtScaleMode
  } = useContext(QuestionContext);

  const refs = useRef([]);

  const handleOnDragEnd = (result) => {
    const from = result?.source?.index,
      to = result?.destination?.index;

    if (to === undefined) {
      return;
    }

    const items = Array.from(question.answers);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    question.answers = items;

    each(question.condition_list, (el) => {
      const elIndex = parseInt(el.index);
      if (elIndex === from) {
        el.index = to;
      }
      if (from < to &&  from < elIndex  && elIndex <= to) {
        el.index = elIndex - 1;
      }
      if (from > to &&  to <= elIndex  && elIndex < from) {
        el.index = elIndex + 1;
      }
    });

    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeAnswer = (event, index) => {
    question.answers[index] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeCondition = (event, index, rowIndex) => {
    const found = find(question.condition_list, (el) => el.index === rowIndex);
    if (found.cond[index] || found.cond[index] === "") {
      const newValue = event.target.value;
      const previousValue = found.cond[index];
      found.cond[index] = newValue;

      if (newValue === 'is_not' || previousValue === 'is_not') {
        calculateGridQuotaItem(question, rowIndex);
      }
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const delAnswer = (event, answer, index) => {
    event.preventDefault();

    if (question.answers.length <= 1) {
      return;
    }

    question.answers.splice(index, 1);

    const foundConditionItem = find(question.condition_list, (c) => {
      return c.index === index;
    });

    if (foundConditionItem) {
      const conditionIdx = question.condition_list.indexOf(foundConditionItem);
      if (conditionIdx > -1) {
        question.condition_list.splice(conditionIdx, 1);
        reIndexConditionList(question, index);
      }
    }

    const mustSelectList = filter(question.condition_list, (item) => item.cond === 'is');
    const mustSelectListLength = mustSelectList.length;

    if (question.multi_condition >= mustSelectListLength && mustSelectListLength > 1) {
      question.multi_condition = mustSelectListLength - 1;
    }

    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const pushAnswer = () => {
    question.answers.push('');
    const answerIndex = question.answers.length - 1;
    findGridConditionItem(question, answerIndex);
    if (question.manage_quota) {
      calculateGridQuotaItem(question, answerIndex);
    }
  };

  const addAnswer = (event) => {
    event.preventDefault();
    pushAnswer();
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const extractData = (event) => {
    const text = event.clipboardData.getData('text/plain');
    const lines = text.replace(/\r/g, '').replace(/\n$/, '').split("\n");
    if (lines.length === 1 && lines[0].indexOf("\t") === -1) {
      return null;
    }
    event.preventDefault();
    return lines;
  };

  const handlePaste = (event, fromIndex) => {
    const lines = extractData(event);
    const answersOldLength = question.answers.length;
    if (lines != null) {
      each(lines, (line, li) => {
        const correctedLi = li + fromIndex;
        question.answers[correctedLi] = line;
        findGridConditionItem(question, correctedLi);
      });
    }
    if (question.answers.length > answersOldLength) {
      calculateQuotaForCustomScreening(question);
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const answerChangeRef = (item, index) => {
    if (item) {
      refs.current[index] = item;
    }

    if (!item || question.answers.length - 1 !== index) {
      return;
    }
    setFocusLastOnEnter(
      (focusLastRequest) => {
        if (focusLastRequest) {
          item.focus();
        }
        return false;
      }
    );
  };

  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (question.answers.length - 1 === index) {
        pushAnswer();
        updateFivePtToggleStates();
        setFocusLastOnEnter(true);
      } else {
        refs.current[index + 1].focus();
      }
    }
  };

  const updateFivePtToggleStates = () => {
    if (!setManageFivePtToggle || question.kind === 'Grid') {
      return;
    }
    const toggleEnableCondition = question.answers.length === 5 && question.kind === 'Single';
    setManageFivePtToggle(toggleEnableCondition);
    if (question.answers.length < 5) {
      setFivePtScaleMode(false);
      question.as_point_scale = false;
      setScreenerQuestions([ ...screenerQuestions ]);
    }
  };

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <Droppable droppableId="answers-area">
        {
          (provided) => (
            <ul
              className="form-question_answers -rows-list"
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              {
                map(question.answers, (answer, rowIndex) => (
                  <Draggable
                    key={ rowIndex }
                    draggableId={ `x${questionIndex}-${rowIndex}` }
                    index={ rowIndex }
                    isDragDisabled={ question.answers_order !== 'specific' }
                  >
                    {
                      (provided) => (
                        <>
                          <li
                            id={ `x${questionIndex}-${rowIndex}` }
                            { ...provided.draggableProps }
                            ref={ provided.innerRef }
                            className="form-question_answer"
                          >
                            <div className="form-question_grid">
                              <div
                                className={
                                  cn(
                                    "form-question_grid-item -first-answer-item",
                                    { '-transparent': question.answers_order !== 'specific' }
                                  )
                                }
                              >
                                <div
                                  className="form-question_answer-move"
                                  { ...provided.dragHandleProps }
                                >
                                  <SvgIcon name="i-move" />
                                </div>
                              </div>

                              <div
                                className={
                                  cn(
                                    "form-question_grid-item -grow",
                                    { '-sm': question?.kind === "Grid" },
                                    { 'has-error': question?.errors?.answers },
                                  )
                                }
                              >
                                <input
                                  ref={ (item) => answerChangeRef(item, rowIndex) }
                                  className="form-field -block"
                                  type="text"
                                  value={ question.answers[rowIndex] }
                                  onChange={ (event) => {
                                    onChangeAnswer(event, rowIndex);
                                  } }
                                  onPaste={ (event) => handlePaste(event, rowIndex) }
                                  onKeyPress={ (event) => handleKeyPress(event, rowIndex) }
                                  placeholder="Add Answer Text"
                                />
                              </div>

                              <div className="form-question_grid-item -buttons">
                                <button
                                  className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                  onClick={ (event) => {delAnswer(event, answer, rowIndex);} }
                                  disabled={ question.answers.length === 1 }
                                >
                                  <SvgIcon name="i-delete" />
                                </button>
                              </div>
                            </div>
                          </li>
                          {
                            needRows &&
                            map(question.scales, (item, index) => (
                              <div
                                key={ `row-${index}` }
                                className={
                                  cn(
                                    "form-question_row-item",
                                    { '-first': index === "1" },
                                    { 'has-error': question?.errors?.scales }
                                  )
                                }
                              >
                                <div className="form-question_type-text -grid">{ item }</div>
                                {
                                  needLogicEdit &&
                                  <div className="form-question_grid-item -select">
                                    <ConditionSelect
                                      value={ findGridConditionItem(question, rowIndex, index) }
                                      onChangeCondition={ (event, index) => { onChangeCondition(event, index, rowIndex); } }
                                      i={ index }
                                    />
                                  </div>
                                }
                              </div>
                            ))
                          }
                          <div className="form-question_row-hr" />
                        </>
                      )
                    }
                  </Draggable>
                ))
              }
              <div className="header_grid-item -margin-left">
                <button
                  className="button"
                  onClick={ (event) => {addAnswer(event);} }
                >
                  Add Row
                </button>
              </div>
              {provided.placeholder}
            </ul>
          )
        }
      </Droppable>
    </DragDropContext>
  );
};


export default SQAGridRows;
