import React, { useState } from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const NumberAndDescription = ({
  initPlatformId, platformIdField,
  initNote, internalField,
  initInternal, noteField,
  onChange
}) => {
  const [ platformId, setPlatformId ] = useState(initPlatformId || '');
  const [ note, setNote ] = useState(initNote || '');
  const [ internal, setInternal ] = useState(initInternal || false);

  const handleOnChangeInternal = (value) => {
    setInternal(value);
    onChange(internalField, value);
  };

  const handleOnChangePlatformId = (event) => {
    const value = event.target.value;
    setPlatformId(value);
    onChange(platformIdField, value);
  };

  const handleOnChangeNote = (event) => {
    const value = event.target.value;
    setNote(value);
    onChange(noteField, value);
  };

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-1-3">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>Buzzback Project Number</h3>
            </div>
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>

                <div className="info-tooltip_drop">
                  <div className="info-tooltip_drop-inner">
                    Enter the buzzback job number for this project for tracking purposes.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
              placeholder="Enter project number"
              className="form-field -block"
              maxLength="250"
              size="250"
              type="text"
              value={ platformId }
              onChange={ handleOnChangePlatformId }
            />
          </div>
        </div>
        <div className="form_grid-item -width-1-3">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>Client/Internal</h3>
            </div>
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>

                <div className="info-tooltip_drop">
                  <div className="info-tooltip_drop-inner">
                    Internal option to distinguish client projects from internal ones.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="radio-tabs -borderer -gray-bg">
              <label id="label-product" className="radio-tabs_item">
                <input
                  id="project_brand_type_product"
                  type="radio"
                  checked={ internal === false }
                  onChange={ () => handleOnChangeInternal(false)  }
                />
                <div className="radio-tabs_label">Client</div>
              </label>
              <label id="label-service" className="radio-tabs_item">
                <input
                  id="project_brand_type_service"
                  type="radio"
                  checked={ internal === true }
                  onChange={ () => handleOnChangeInternal(true) }
                />
                <div className="radio-tabs_label">Internal</div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="form_grid">
        <div className="form_grid-item -grow">
          <div className="form_section-title -grid">
            <div className="form_section-title-item"><h3>Description</h3></div>
          </div>
          <div className="form-group">
            <input
              placeholder="Enter project Description"
              className="form-field -block"
              type="text"
              value={ note }
              onChange={ handleOnChangeNote }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberAndDescription;
