import React from 'react';
import cn from "classnames"

const StatusBar = ({values, box, statTesting}) => {
  if(!values){
    return (<div className={cn('status-circle')} />)
  }

  const hl = box ? values.highlight[box] : values.highlight
  const pc = box ? values.percentage[box] : values.percentage

  const className = `-color-${ statTesting == "none" ? "par" : hl }`

  return (
    <div className={ cn('status-bar -no-max-limit', className) }>
      <div className={ cn("status-bar_inner")} style={ {'width': pc} } />
    </div>
  );
};

export default StatusBar;
