import React, { useState } from "react";
import { map, filter } from "lodash";
import { removeKeyboardClass, setKeyboardClass } from "../helpers/iphoneKeyboardHelper";

const normalizeStr = (str) => {
  return (str || '').toLowerCase().trim().replace(/\s{2,}/g, ' ');
};

const filterAnswers = (options, searchStr) => {
  return filter(options, (item) => {
    return (
      normalizeStr(item.primary).indexOf(normalizeStr(searchStr)) >= 0 || normalizeStr(item.secondary).indexOf(normalizeStr(searchStr)) >= 0
    );
  });
};

const FormControlSelect = ({ listOptions, selectOption }) => {
  const [ searchStr, setSearchStr ] = useState('');

  const deleteSearchStr = () => {
    setSearchStr("");
  };

  const onChangeFieldSearch = (event) => {
    setSearchStr(event.target.value);
  };

  const handleOnFocus = () => {
    setTimeout(() => {
      setKeyboardClass();
    }, 100);
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      removeKeyboardClass();
    }, 100);
  };

  return (
    <div className="form-control-select">
      <div className="form-control-select_field">
        <div className="form-control">
          <input
            type="text"
            className="form-control_field -round -narrow"
            value={ searchStr }
            onChange={ onChangeFieldSearch }
            onFocus={ handleOnFocus }
            onBlur={ handleOnBlur }
          />
          {
            !!searchStr &&
            <button className="button-icon form-control_button" onClick={ deleteSearchStr }>
              <svg className="icon form-control_icon -delete-circle">
                <use xlinkHref="#svg_delete-circle" />
              </svg>
            </button>
          }
        </div>
      </div>
      <ul className="form-control-select_list">
        {
          map(filterAnswers(listOptions, searchStr), (item, key) => (
            <li key={ key } className="form-control-select_list-item">
              <button
                className="form-control-select_item"
                type="button"
                onClick={ () => selectOption(item) }
              >
                <div className="form-control-select_item-line -primary">
                  {item.primary}
                </div>
                <div className="form-control-select_item-line -secondary">
                  {item.secondary}
                </div>
              </button>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default FormControlSelect;
