import React, { useContext } from 'react';
import { BaseContext } from '../../contexts';
import { sidebarBuzzbackTravelClick, sidebarVerizonTravelClick } from "../../../google-analytics/events";

const Travel = () => {
  const { initialData, paths, platform, gaTrackingId } = useContext(BaseContext);
  const hasBuzzbackClient = initialData.has_buzzback_client;
  const hasVerizonClient = initialData.has_verizon_client;
  const buzzbackDashboardPath = paths.dashboard;
  const expressPath = paths.express;
  const customerCaseV2024Path = paths.verizon_customer_case_v2024;
  return (
    <>
      {
        (hasBuzzbackClient) &&
        <li className="sidebar_nav-list-item -active">
          Dashboard Travel
          <ul className="sidebar_nav-list -sub">
            {
              hasBuzzbackClient &&
              <>
                <li className="sidebar_nav-list-item -sub">
                  <a
                    className="sidebar_nav-link -sub"
                    href={ buzzbackDashboardPath }
                    onClick={ () => sidebarBuzzbackTravelClick(gaTrackingId, { platform: "express", page_name: "admin" }) }
                  >
                    Buzzback
                  </a>
                </li>
                {
                  platform !== 'express' &&
                  <li className="sidebar_nav-list-item -sub">
                    <a
                      className="sidebar_nav-link -sub"
                      href={ expressPath }
                      onClick={ () => sidebarVerizonTravelClick('category', { platform: "express", page_name: "admin" }) }
                    >
                      Express
                    </a>
                  </li>
                }
              </>
            }
            {
              hasVerizonClient &&
              <>
                {
                  platform !== 'Verizon Customer Case V2024' &&
                  <li className="sidebar_nav-list-item -sub">
                    <a
                      className="sidebar_nav-link -sub"
                      href={ customerCaseV2024Path }
                      onClick={ () => sidebarVerizonTravelClick('category', { platform: "express", page_name: "admin" }) }
                    >
                      Verizon Customer Case
                    </a>
                  </li>
                }
              </>
            }
          </ul>
        </li>
      }
    </>
  );
};

export default Travel;
