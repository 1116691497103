import React, { useContext } from 'react';
import { map } from 'lodash';
import recommended1 from '../../../../../../images/paypal/everywhere/home/recommended1.png';
import recommended2 from '../../../../../../images/paypal/everywhere/home/recommended2.png';
import recommended3 from '../../../../../../images/paypal/everywhere/home/recommended3.png';
import recommended4 from '../../../../../../images/paypal/everywhere/home/recommended4.png';
import { GlobalContext } from '../contexts/appContexts';

const AppInterface = () => {
  const { nextStep } = useContext(GlobalContext);

  const recommendations = [
    {
      src: recommended1,
      title: "Macy’s",
      description: "Royal LuxeReversible Micromink to Faux-Sherpa",
      price: "$135.99",
      cashback: "2% cash back"
    },
    {
      src: recommended2,
      title: "Levi’s",
      description: "Original Fit Women’s Jeans",
      price: "$88.49",
      cashback: "2% cash back"
    },
    {
      src: recommended3,
      title: "Sephora",
      description: "Valentino Donna Born",
      price: "$135.25",
      cashback: "2% cash back"
    },
    {
      src: recommended4,
      title: "Levi’s",
      description: "501® Original Fit Women’s Jeans",
      price: "$98.99",
      cashback: "2% cash back"
    }
  ];

  const handleOnClick = () => {
    nextStep();
  };

  return (
    <div className="home" onClick={ handleOnClick }>
      <header className="home_header">
        <svg className="icon">
          <use xlinkHref="#svg_menu" />
        </svg>
        <div className="home_spacer" />
        <svg className="icon">
          <use xlinkHref="#svg_qr" />
        </svg>
        <svg className="icon -profile">
          <use xlinkHref="#svg_profile" />
        </svg>
      </header>

      <main className="home_content">
        <div className="big-sections">
          <div className="item -debit-card">
            <div className="main-text top-text">
              <p>PayPal Debit Card</p>
              <p>5% cash back in a monthly category you choose</p>
            </div>
            <svg className="icon -paypal-debitcard">
              <use xlinkHref="#svg_paypal-debitcard" />
            </svg>
            <button className="button plus-button">
              <span />
            </button>
          </div>
          {/*<div className="item -accept-money">*/}
          {/*  <div className="sub-item">*/}
          {/*    <div>*/}
          {/*      <svg className="icon -vertical-arrows">*/}
          {/*        <use xlinkHref="#svg_vertical-arrows" />*/}
          {/*      </svg>*/}
          {/*    </div>*/}
          {/*    <div className="main-text center-text">*/}
          {/*      <p>You received</p>*/}
          {/*      <p>$100.00</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <button className="button -full-width">Accept Money</button>*/}
          {/*</div>*/}
          <div className="item -crypto">
            <div className="main-text top-text">
              <p>Crypto</p>
              <p>Buy, sell, and send  Bitcoin, PayPal USD, and more</p>
            </div>
            <svg className="icon -crypto-chart">
              <use xlinkHref="#svg_crypto-chart" />
            </svg>
            <button className="button plus-button">
              <span />
            </button>
          </div>
          <div className="item -blue">
            <div className="main-text top-text">
              <p>PayPal Savings</p>
              <p>Get paid to save with 4.3% APY</p>
            </div>
            <svg className="icon -chart-line">
              <use xlinkHref="#svg_chart-line" />
            </svg>
            <button className="button plus-button">
              <span />
            </button>
          </div>
        </div>

        <div className="home_section">
          <div className="home_offers">
            <div className="home_grid">
              <h3>Featured offers</h3>
              <div className="home_see-all">See all</div>
            </div>
            <div className="featured-sections">
              <div className="item ticketmaster -black">
                <div className="item-header">
                  <div className="merchant-icon">
                    <svg className="icon -merchant">
                      <use xlinkHref="#svg_merchant" />
                    </svg>
                  </div>
                  <p>Ticketmaster</p>
                </div>
                <div className="item-footer">
                  <div className="main-text">
                    <p>2% cash back</p>
                    <p>Welcome offer</p>
                  </div>
                  <button className="button save-button">Save</button>
                </div>
              </div>
              <div className="item ticketmaster -blue">
                <div className="item-header">
                  <div className="merchant-icon">
                    <svg className="icon -merchant">
                      <use xlinkHref="#svg_merchant" />
                    </svg>
                  </div>
                  <p>Ticketmaster</p>
                </div>
                <div className="item-footer">
                  <div className="main-text">
                    <p>2% cash back</p>
                    <p>Welcome offer</p>
                  </div>
                  <button className="button save-button">Save</button>
                </div>
              </div>
            </div>
            <div className="home_hint -small">
              <span className="pseudo-link">Terms</span> & exclusions apply.
              { ' ' }
              Check offers for details.
            </div>
          </div>
        </div>

        <div className="home_section">
          <div className="home_offers">
            <div className="home_grid">
              <h3>Recommended for you</h3>
              <div className="home_see-all">See all</div>
            </div>
            <div className="recommendations-sections">
              {
                map(recommendations, (recommendation, index) => (
                  <div className="item" key={ index }>
                    <div className="item-header">
                      <img src={ recommendation.src } alt={ recommendation.title } />
                      <div className="text-body">
                        <p className="recommendation-title">{ recommendation.title }</p>
                        <p className="recommendation-description">{ recommendation.description }</p>
                        <p className="recommendation-price">{ recommendation.price }</p>
                        <p className="recommendation-cashback">{ recommendation.cashback }</p>
                      </div>
                    </div>
                    <div className="item-footer">
                      <button className="button plus-button" />
                      <button className="button like-button">
                        <svg className="icon -heart">
                          <use xlinkHref="#svg_heart" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="home_hint -small">
              <span className="pseudo-link">Terms</span> & exclusions apply.
              { ' ' }
              Store prices subject to change.
            </div>
          </div>
        </div>
      </main>
      <footer className="home_footer">
        <div className="home_footer-item -active">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_home" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Home
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_wallet" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Accounts
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon -blue">
            <svg className="icon">
              <use xlinkHref="#svg_arrows" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Send / Request
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_receipts" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Activity
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_bag" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Offers
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AppInterface;
