import ConceptBase from '../concept_base';

class Adcept extends ConceptBase {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  videoAd() {
    return this.projectData.video_ad === true || this.projectData.video_ad === 'true';
  }

  adPrice() {
    return (this.videoAd() ? this.priceData.video_ad_price : 0);
  }

  totalPrice() {
    return (this.basePrice() + this.baseConceptsPrice() + this.adPrice());
  }
}

export default Adcept;
