import { map } from "lodash";
import React from "react";
import classnames from 'classnames';
import renderRawHtml from '../../../common/render_raw_html';

const Plan = ({
  plan, onPlanSelect, selectedPlanIdByLine,
  classNameWrapper, planPrice
}) => {
  const clickSelectButton = (event, plan, canProceed) => {
    if (!canProceed) {
      return;
    }
    onPlanSelect(event, plan);
  };

  const buttonIsDisabled = (plan) => (
    plan.id === selectedPlanIdByLine
  );

  const buttonLabel = 'Select plan';

  return (
    <div className={ classNameWrapper }>
      <div
        className={
          classnames(
            "plan-plate",
            { '-active': plan.id === selectedPlanIdByLine },
            { '-clickable': plan.noSelectButton }
          )
        }
        onClick={ (event) => { clickSelectButton(event, plan, plan.noSelectButton); } }
      >
        <h3 className="plan-plate_title" { ...renderRawHtml(plan.name) } />
        <div className={ classnames("plan-plate_pricing", plan.pricePlateKlass) }>
          <div className="plan-plate_pricing-grid">
            <div className="plan-plate_pricing-value">
              ${ planPrice }
            </div>
            <div className="plan-plate_pricing-hint">
              <div className="plan-plate_pricing-hint-line">/mo.</div>
              <div className="plan-plate_pricing-hint-line">Plus taxes & fees</div>
            </div>
          </div>
        </div>
        <div className="plan-plate_features">
          {
            map(plan.features, (feature, index) => (
              <div
                className={ classnames("plan-plate_feature", feature.klass) }
                key={ `feature-${index}` }
              >
                {
                  !!feature.title &&
                  <div className="plan-plate_feature-title">{feature.title}</div>
                }
                <div className="plan-plate_feature-description">
                  { feature.description }
                </div>
              </div>
            ))
          }
        </div>
        <div className="plan-plate_feature-spacer" />
        {
          !plan.noSelectButton &&
          <div className="plan-plate_bottom">
            <div className="plan-plate_button">
              {
                !buttonIsDisabled(plan) &&
                <button
                  className="button -bordered"
                  onClick={ (event) => { clickSelectButton(event, plan, true); } }
                >
                  { buttonLabel }
                </button>
              }
              {
                buttonIsDisabled(plan) &&
                <button className="button -bordered" disabled>
                  { buttonLabel }
                </button>
              }
            </div>
            {
              !!plan.choosePerks &&
              <div className="plan-plate_bottom-note">
                { plan.choosePerks }
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default Plan;
