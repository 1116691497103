import React, { useState } from 'react';
import InfoIcon from './info-icon.js';
import InfoPopup from './info-popup.js';

const CompatibleTip = (props) => {
  const [showPopup, updateShowPopup] = useState(false);

  return(
    <>
      <div className="compatible-tip">
        <div className="combined-col">
          <div className="col">
            <InfoIcon />
          </div>
          <div className="col compatible-text">
            <h3>Do you have a compatible streaming device?</h3>
            <p>1 Set top box is included in your service, but you may be able to connect additional TV's via your own streaming device.</p>
          </div>
        </div>
        <div className="col">
          <button className="button" onClick={ (event) => { updateShowPopup(true); } }>
            Discover what's compatible
          </button>
        </div>
      </div>
      <InfoPopup opened={ showPopup } onClose={ (event) => { updateShowPopup(false); } }  />
    </>
  )

};

export default CompatibleTip;