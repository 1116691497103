import React, { useState, Fragment } from 'react';

// Usage: <InfoIcon popup={ true }/>

const InfoIcon = (props) => {
  const popup = props.popup;

  const [ show, updateShow ] = useState(false);

  const onOver = (event) => { updateShow(true); };
  const onLeave = (event) => { updateShow(false); };

  const propsHash = popup ? { onMouseOver: onOver, onMouseLeave: onLeave } : {};

  return(
    <div className="info-icon" { ...propsHash }>
      <span className="icon-copy">i</span>
      {
        show &&
        <Fragment>
          <div className="icon-popup">
            With one set-top box, an unlimited number of devices can connect to Fios TV. Only two will be able to stream at once.
          </div>
          <div className="icon-triangle"></div>
        </Fragment>
      }
    </div>
  )


};

export default InfoIcon;