import React from "react";
import cn from "classnames";
import { map } from "lodash";
import { n0, n2 } from "../../helpers";

const ShoppingCartPerformers = ({ data, current }) => {
  const info = data.cart_products[current.id];
  const products = info.products;

  return (
    <table className="table table-comparison -margins">
      <thead>
        <tr>
          <th className="table-comparison_cell-header">
            { data.titles.performers }
          </th>
          <th className="table-comparison_bold-text">
            { data.titles.percent }
          </th>
          <th className="">
            { data.titles.product_total }
          </th>
          <th className="">
            { data.titles.in_average }
          </th>
        </tr>
      </thead>
      <tbody>
        {map(info.sorted_ids, (id) => (
          <tr key={ id }>
            <td className={ cn({ "table-comparison_name-cell": !products[id].target, "table-comparison_bold-text": products[id].target }) }>
              {products[id].name} {products[id].target && <span>({data.titles.main})</span>}
            </td>
            <td><b>{ n0(products[id].added_perc) }%</b></td>
            <td>{ n0(products[id].product_total) }</td>
            <td>{ n2(products[id].avg_product_total) }</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ShoppingCartPerformers;
