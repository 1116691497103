import ConceptBase from '../concept_base';

class Package extends ConceptBase {
  constructor(projectData, priceData, size) {
    super(projectData, priceData, size);
  }

  shelf() {
    return this.projectData.shelf === true || this.projectData.shelf === 'true';
  }

  cartExercise() {
    return this.projectData.cart_exercise === true || this.projectData.cart_exercise === 'true';
  }

  shelfPrice() {
    return (
      (this.shelf() || this.cartExercise()) ? this.priceData.shelf_price : 0
    );
  }

  totalPrice() {
    return (this.basePrice() + this.baseConceptsPrice() + this.shelfPrice());
  }
}

export default Package;
