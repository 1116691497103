import React, { createContext } from "react";
const StateContext = createContext({});
const LineDataContext = createContext({});
const SetContext = createContext({});
const PageContext = createContext({});
const CloseCarrotsContext = createContext({});
const MobileCompareContext = createContext({});

export {
  StateContext, LineDataContext, SetContext, PageContext,
  CloseCarrotsContext, MobileCompareContext
};
