import React, { useContext } from 'react';
import { getCurrentLine, getLinesCount } from './statistics';
import { Back } from "./back";
import { BackContext } from './contexts';

export const Subheader = () => {
  const currentLine = getCurrentLine();
  const linesCount = getLinesCount();
  const { handleBackPageClick, showBackButton } = useContext(BackContext);

  const handleBackButton = () => {
    handleBackPageClick();
  };

  const showLines = linesCount > 1;
  const showBack = showBackButton();

  return (
    <>
      {
        (showLines || showBack) &&
        <div className="subheader">
          <div className="container">
            <div className="subheader_grid">
              {
                showBack &&
                <div className="subheader_grid-item">
                  <Back onBack={ handleBackButton } />
                </div>
              }
              {
                showLines &&
                <div className="subheader_grid-item">
                  <div className="subheader_plan">
                    <svg className="icon -plans subheader_plan-icon">
                      <use xlinkHref="#svg_plans" />
                    </svg>
                    <span className="subheader_plan-text">
                      Plan {currentLine}
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};
