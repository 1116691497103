import React, { useState, useEffect } from 'react';
import { findIndex, map } from 'lodash';
import classnames from 'classnames';
import Layout from '../../components/layout';
import AddingCardHandShake from '../../../../../../../images/paypal/everywhere/adding-card-hand-shake.png';
import renderRawHtml from '../../../../../common/render_raw_html';
import IosLoader from '../../components/iosLoader';
import CheckMark from '../../components/checkMark';
import Modal from '../../components/modal';
import Footer from '../../components/footer';
import Header from '../../components/header';
import NativeTerms from './nativeTerms';

const NativeApplePay = ({
  devicesList, firstName, lastName, cardNumber,
  nextStep, skipStep, onUseDefaultNext, onUseDefaultSkip
}) => {
  const [ animatedDisplayTerms, setAnimatedDisplayTerms ] = useState(false);
  const [ launchNextSteps, setLaunchNextSteps ] = useState(null);
  const [ selectedDevice, setSelectedDevice ] = useState(null);
  const [ nativeSteps ] = useState(() => (
    [
      {
        name: 'select_device',
        inProgress: true,
        topButtons: [ 'Cancel', null ],
        title: <>Add Card<br />to Apple Pay</>,
        description: (deviceName) => (
          '“PayPal Debit Card” can be added to iPhone or Apple Watch.'
        ),
        devicesList: true,
        showBottomText: false,
        loading: false,
        checkmark: false,
        terms: false,
        autoNext: false
      },
      {
        name: 'add_card_init',
        blackValues: true,
        inProgress: true,
        topButtons: [ 'Back', 'Next' ],
        title: 'Add Card',
        description: (deviceName) => (
          `“PayPal Debit Card” will be available in Wallet on “${firstName}’s ${deviceName}.”`
        ),
        devicesList: false,
        showBottomText: true,
        loading: false,
        checkmark: false,
        terms: false,
        autoNext: false
      },
      {
        name: 'issuer_contacting',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Next' ],
        title: 'Adding Card',
        description: (deviceName) => (
          `Contacting the Card issuer...`
        ),
        devicesList: false,
        showBottomText: true,
        loading: true,
        checkmark: false,
        terms: false,
        autoNext: true
      },
      {
        name: 'issuer_talking',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Next' ],
        title: 'Adding Card',
        description: (deviceName) => (
          `Talking to Card issuer...`
        ),
        devicesList: false,
        showBottomText: true,
        loading: true,
        checkmark: false,
        terms: false,
        autoNext: true
      },
      {
        name: 'setting_up_card',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Next' ],
        title: 'Adding Card',
        description: (deviceName) => (
          `Setting up Card for Apple Pay...`
        ),
        devicesList: false,
        showBottomText: true,
        loading: true,
        checkmark: false,
        terms: false,
        autoNext: true
      },
      {
        name: 'terms_and_conditions',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Next' ],
        title: 'Adding Card',
        description: (deviceName) => (
          `Setting up Card for Apple Pay...`
        ),
        devicesList: false,
        showBottomText: true,
        loading: true,
        checkmark: false,
        terms: true,
        autoNext: false
      },
      {
        name: 'setting_up_card_continue',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Next' ],
        title: 'Adding Card',
        description: (deviceName) => (
          `Setting up Card for Apple Pay...`
        ),
        devicesList: false,
        showBottomText: true,
        loading: true,
        checkmark: false,
        terms: false,
        autoNext: true
      },
      {
        name: 'adding_to_wallet',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Next' ],
        title: 'Adding Card',
        description: (deviceName) => (
          `Adding to Wallet...`
        ),
        devicesList: false,
        showBottomText: true,
        loading: true,
        checkmark: false,
        terms: false,
        autoNext: true
      },
      {
        name: 'card_added',
        blackValues: false,
        inProgress: true,
        topButtons: [ null, 'Done' ],
        title: 'Card Added',
        description: (deviceName) => (
          ''
        ),
        devicesList: false,
        showBottomText: true,
        loading: false,
        checkmark: true,
        terms: false,
        autoNext: false
      },
      {
        name: 'use_default_card',
        inProgress: false,
        topButtons: [ 'Use as Default Card', 'Not Now' ],
        title: <>Set as Default<br />Card in Wallet</>,
        description: (deviceName) => (
          'This will be automatically selected when you use Apple Pay.'
        ),
        devicesList: false,
        showBottomText: true,
        loading: false,
        checkmark: true,
        terms: false,
        autoNext: false
      }
    ]
  ));
  const [ nativeStep, setNativeStep ] = useState(nativeSteps[0]);

  const handleGlobalNext = () => {
    nextStep();
  };
  const handleGlobalSkip = () => {
    skipStep();
  };

  const findCurrentStepIndex = () => (
    findIndex(nativeSteps, (stepsItem) => (stepsItem === nativeStep))
  );

  const nextNativeStep = () => {
    const currentIndex = findCurrentStepIndex();
    if (currentIndex < nativeSteps.length - 1) {
      setNativeStep(nativeSteps[currentIndex + 1]);
    } else {
      handleNext();
    }
  };

  const handleSelectDevice = (deviceName) => {
    setSelectedDevice(deviceName);
    nextNativeStep();
  };

  const handleBack = () => {
    if (!nativeStep.topButtons[0]) {
      return;
    }
    const currentIndex = findCurrentStepIndex();
    if (currentIndex) {
      setNativeStep(nativeSteps[currentIndex - 1]);
    } else {
      handleGlobalSkip();
    }
  };

  const handleNext = () => {
    if (!nativeStep.topButtons[1]) {
      return;
    }
    const currentIndex = findCurrentStepIndex();
    if (currentIndex < nativeSteps.length - 1) {
      setLaunchNextSteps({ currentStepInfo: nativeSteps[currentIndex + 1] });
      setAnimatedDisplayTerms(false);
      setNativeStep(nativeSteps[currentIndex + 1]);
    }
  };

  useEffect(() => {
    if (launchNextSteps && launchNextSteps?.currentStepInfo?.autoNext) {
      setTimeout(() => {
        handleNext();
      }, 1000);
    }
  }, [ launchNextSteps ]);

  useEffect(() => {
    if (nativeStep) {
      const currentIndex = findCurrentStepIndex();
      const currentInfo = nativeSteps[currentIndex];
      if (currentInfo.terms) {
        setTimeout(() => {
          setAnimatedDisplayTerms(true);
        }, 300);
      }
    }
  }, [ nativeStep ]);

  const header = (
    <>
      <Header className="under-top-native-header" />
      <div className="wallet-native-header">
        <button
          disabled={ nativeStep.loading }
          className={
            classnames(
              "button-link -md",
              { '-back': findCurrentStepIndex() > 0 && nativeStep.topButtons[0] }
            )
          }
          onClick={ handleBack }
          { ...renderRawHtml(nativeStep.topButtons[0] || '') }
        />
        <button
          disabled={ nativeStep.loading }
          className="button-link -md"
          onClick={ handleNext }
          { ...renderRawHtml(nativeStep.topButtons[1] || '') }
        />
      </div>
    </>
  );

  const footerInProgress = (
    <footer className="footer">
      {
        nativeStep.showBottomText &&
        <div className="native-footer-bottom-text">
          <img src={ AddingCardHandShake } alt="Card Hand Shake" />
          <span>
            Card-related information, location, and information about device settings
            { ' ' }
            and use patterns may be sent to Apple and may be used together with account
            { ' ' }
            information to provide assessments to your card issuer or payment network
            { ' ' }
            to set up Apple Pay and prevent transaction fraud.
          </span>
        </div>
      }
    </footer>
  );

  const handleUseAsDefaultCard = () => {
    onUseDefaultNext && onUseDefaultNext();
    handleGlobalNext();
  };

  const handleSkipAsDefaultCard = () => {
    onUseDefaultSkip && onUseDefaultSkip();
    handleGlobalNext();
  };

  const doneFooterButtons = (
    <>
      <button
        className="button -full-width -native-use-as-default"
        onClick={ handleUseAsDefaultCard }
      >
        { nativeStep.topButtons[0] }
      </button>
      <button className="button -link -full-width" onClick={ handleSkipAsDefaultCard }>
        { nativeStep.topButtons[1] }
      </button>
    </>
  );

  const doneFooter = <Footer footerButtons={ doneFooterButtons } />;

  return (
    <>
      {
        nativeStep.inProgress ? (
          <Layout
            header={ header }
            footer={ footerInProgress }
            contentClassName={ 'wallet-native-content' }
          >
            <div className="wallet-native-steps">
              <div className="content_title">
                <h1>
                  { nativeStep.title }
                  { nativeStep.loading && <IosLoader /> }
                  { nativeStep.checkmark && <CheckMark className="card-checkmark" /> }
                </h1>
              </div>
              <div className="content_text">{ nativeStep.description(selectedDevice) }</div>
              <div className="wallet-native-items-list">
                {
                  nativeStep.devicesList ? (
                    <ul>
                      {
                        map(devicesList, (deviceName) => (
                          <li key={ deviceName } onClick={ () => { handleSelectDevice(deviceName); } }>
                            <span>{firstName}’s { deviceName }</span>
                          </li>
                        ))
                      }
                    </ul>
                  ) : (
                    <ul>
                      <li className="wallet-grid">
                        <span>Name</span>
                        <span className={ classnames({ "black-value": nativeStep.blackValues }) }>
                          { firstName } { lastName }
                        </span>
                      </li>
                      <li className="wallet-grid">
                        <span>Card Number</span>
                        <span className={ classnames({ "black-value": nativeStep.blackValues }) }>
                          { cardNumber }
                        </span>
                      </li>
                    </ul>
                  )
                }
              </div>
            </div>
            {
              nativeStep.terms &&
              <Modal
                headerTitle="Terms and conditions"
                className="native-terms"
                classNameDialog={ classnames("-mostly-full-height", { "-hidden": !animatedDisplayTerms }) }
                footer={
                  <div className="native-footer-buttons">
                    <button
                      className="button"
                      onClick={ handleGlobalSkip }
                    >
                      Disagree
                    </button>
                    <button
                      className="button"
                      onClick={ handleNext }
                    >
                      Agree
                    </button>
                  </div>
                }
              >
                <NativeTerms />
              </Modal>
            }
          </Layout>
        ) : (
          <Layout
            header={ <Header className="under-top-native-header" /> }
            footer={ doneFooter }
            contentClassName={ 'wallet-native-content' }
          >
            <div className="wallet-native-steps">
              <div className="svg-body">
                <svg className={ `card-wallet-default-svg` }>
                  <use xlinkHref={ `#svg_card-wallet-default` } />
                </svg>
              </div>
              <div className="content_title">
                <h1>{ nativeStep.title }</h1>
              </div>
              <div className="content_text">{ nativeStep.description(selectedDevice) }</div>
            </div>
            {
              nativeStep.terms &&
              <Modal
                headerTitle="Terms and conditions"
                className="native-terms"
                classNameDialog={ classnames("-mostly-full-height", { "-hidden": !animatedDisplayTerms }) }
                footer={
                  <div className="native-footer-buttons">
                    <button
                      className="button"
                      onClick={ handleGlobalSkip }
                    >
                      Disagree
                    </button>
                    <button
                      className="button"
                      onClick={ handleNext }
                    >
                      Agree
                    </button>
                  </div>
                }
              >
                <NativeTerms />
              </Modal>
            }
          </Layout>
        )
      }
    </>
  );
};

export default NativeApplePay;


