import React, { useState } from "react";
import { map } from "lodash";
import renderRawHtml from '../../../../common/render_raw_html';
import { reviewTermsData } from "./reviewTermsData";

const { termsIntroList, termsLinesList, termsTable } = reviewTermsData;

const TermsTableItem = ({ item }) => {
  const [ openContent, setOpenContent ] = useState(true);
  const toggleContent = () => {
    setOpenContent(!openContent);
  };
  return (
    <div className="review-terms_table-item">
      <div className="review-terms_table-item-title">
        <button
          onClick={ toggleContent }
          className={ `review-terms_table-item-toggle ${openContent ? '-opened' : '-closed'}` }
        >
          <span>{ item.title }</span>
          <svg className="icon">
            <use xlinkHref="#svg_chevron" />
          </svg>
        </button>
      </div>
      <div className={ `review-terms_table-item-content ${openContent ? '-opened' : '-closed'}` }>
        {
          map((item.content), (tr, index) => (
            <div key={ index } className="review-terms_table-item-tr">
              {
                map(Object.values(tr), (col, index) => (
                  <div key={ index } className="review-terms_table-item-col" { ...renderRawHtml(col) } />
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

const ReviewTerms = () => {
  return (
    <div className="review-terms">
      <div className="title review-terms-title">
        <h1 className="title_h1">Review terms</h1>
      </div>
      <div className="review-terms_plate">

        <div className="review-terms_plate-title">
          <svg className="icon">
            <use xlinkHref={ `#svg_card-pp-logo` } />
          </svg>
          <h4>Balance account terms</h4>
        </div>

        <ul className="review-terms_intro-list">
          {
            map(termsIntroList, (item, index) => (
              <li key={ index }>
                <div className="review-terms_intro-item">
                  <div className="review-terms_intro-item-name">
                    {item.title}
                  </div>
                  <div className="review-terms_intro-item-values">
                    {
                      map(item.values, (value, index) => (
                        <div key={ index } className="review-terms_intro-item-value">
                          <div className="review-terms_intro-item-value-value">
                            {value.value}
                          </div>
                          <div className="review-terms_intro-item-value-hint">
                            {value.hint}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </li>
            ))
          }
        </ul>

        <ul className="review-terms_lines-list">
          {
            map(termsLinesList, (line, index) => (
              <li key={ index } className={ `review-terms_lines-item ${line.title ? '-title' : ''}` }>
                { !!line.title && (
                  <div className="review-terms_lines-item-title">
                    <b { ...renderRawHtml(line.v) } />
                    { !!line.text && <span> { line.text }</span> }
                  </div>
                ) }
                { !!line.name && (
                  <div className="review-terms_lines-item-name">
                    <span { ...renderRawHtml(line.name) } />
                    { !!line.hint && <small> { line.hint }</small> }
                  </div>
                )}
                { !!line.value && (
                  <div className="review-terms_lines-item-value" { ...renderRawHtml(line.value) } />
                )}
              </li>
            ))
          }
        </ul>

        <div className="review-terms_text -xs">
          <p>
            *This fee can be lower depending on how and where this account is used.
          </p>
          <p>
            <b>No overdraft/credit feature.</b>
            <br />
            Your U.S. dollar funds (but not cryptocurrencies or non-U.S. dollar currencies) are FDIC insured, up to applicable limits, only if you opened a PayPal Debit Card account, enrolled in Direct Deposit, or bought or received cryptocurrency with your PayPal Balance account. FDIC insurance does not protect you against the failure of PayPal.
          </p>
          <p>
            For general information about prepaid accounts, visit <b>cfpb.gov/prepaid.</b>
            <br />
            Find details and conditions for all fees and services in the PayPal Balance Long Form, which can be accessed in the applicable Terms and Conditions on <b>PayPal.com.</b>
          </p>
        </div>
        <hr />
        <div className="review-terms_text">
          PayPal Balance accounts are issued by PayPal, Inc.
        </div>
        <h3 className="review-terms_subtitle">
          List of all fees for PayPal Balance account
        </h3>

        <div className="review-terms_table">
          <div className="review-terms_table-head">
            {
              map(termsTable.head, (item, index) => (
                <div key={ index } className="review-terms_table-head-col">
                  {item}
                </div>
              ))
            }
          </div>
          <div className="review-terms_table-body">
            {
              map(termsTable.data, (item, index) => (
                <TermsTableItem item={ item } key={ index } />
              ))
            }
          </div>
        </div>

        <div className="review-terms_text">
          <p>
            Your U.S. dollar funds (but not cryptocurrencies or non-U.S. dollar currencies) are FDIC insured, up to applicable limits, only if you opened a PayPal Debit Card account, enrolled in Direct Deposit, or bought or received cryptocurrency with your PayPal Balance account. FDIC insurance does not protect you against the failure of PayPal.
          </p>
          <p>
            No overdraft/credit feature.
          </p>
          <p>
            Contact us at 1-844-629-9108, by mail at PayPal Inc., Attention: Legal Department, 2211 North First Street, San Jose, CA 95131, or online at
            <br />
            <b>PayPal.com/us/smarthelp/contact_us.</b>
          </p>
          <p>
            For general information about prepaid accounts, visit <b>cfpb.gov/prepaid.</b>
          </p>
          <p>
            If you have a complaint about a prepaid account, call the Consumer Financial Protection Bureau at 1-855-411-2372 or visit <b>cfpb.gov/complaint.</b>
          </p>
          <p>
            By clicking <b>Agree and Continue</b>, you can confirm that you have read and agree to
            { ' ' }
            the <span className="pseudo-link"><b>PayPal Balance Terms and Conditions</b></span>.
          </p>
        </div>
      </div>

      <div className="review-terms_plate">
        <div className="review-terms_plate-title">
          <svg className="icon">
            <use xlinkHref={ `#svg_card-paypal` } />
          </svg>
          <h4>PayPal Debit Card terms</h4>
        </div>
        <p className="review-terms_plate-p -sm">
          The PayPal Debit Mastercard® is issued by The Bancorp Bank, N.A. (“Bancorp”) pursuant to a license by Mastercard International Incorporated and may be used everywhere Mastercard is accepted. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.
        </p>
        <p className="review-terms_plate-p -sm">
          Bancorp is issuer of the Card only and not responsible for the associated accounts or other products, services, or offers from PayPal.
        </p>
      </div>

    </div>
  );
};

export default ReviewTerms;
