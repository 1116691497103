import React, { useState, useEffect, useRef } from 'react';
import { map, find } from 'lodash';
import cn from 'classnames';

const CombinationsDropdown = ({
  currentValue, onUpdate, combinations
}) => {
  const sourceEl = useRef(null);

  const [ showDropDown, updateShowDropDown ] = useState(false);

  const elements = [
    { text: "Best of 1 combinations", value: 1 },
    { text: "Best of 2 combinations", value: 2 },
    { text: "Best of 3 combinations", value: 3 },
    { text: "Best of 4 combinations", value: 4 },
    { text: "Best of 5 combinations", value: 5 }
  ].slice(0, combinations);

  const currentText = (
    find(elements, (item) => item.value === currentValue) || {}
  ).text;

  useEffect(() => {
    const body = document.body || document.querySelector("body");
    let pointedToElement = false;

    const bodyListenerFunction = () => {
      if (!pointedToElement) {
        updateShowDropDown(false);
      }
      pointedToElement = false;
    };
    const elementListenerFunction = (event) => {
      event.preventDefault();
      pointedToElement = true;
    };

    const el = sourceEl.current;
    el.addEventListener("click", elementListenerFunction);
    body.addEventListener("click", bodyListenerFunction);

    return (() => {
      el.removeEventListener("click", elementListenerFunction);
      body.removeEventListener("click", bodyListenerFunction);
    });
  }, []);

  const openDropDown = (event) => {
    event.preventDefault();
    updateShowDropDown(true);
  };

  const onSelectElement = (event, item) => {
    onUpdate(item.value);
    updateShowDropDown(false);
  };

  return (
    <div
      className={
        cn(
          "chosen-container",
          "chosen-container-single",
          "chosen-container-single-nosearch",
          "chosen-container-active",
          "chosen-with-drop",
          "js-combinations"
        )
      }
      style={ { width: "100%" } }
      ref={ sourceEl }
    >
      <a
        className="chosen-single -default-cursor"
        tabIndex="-1"
        href="#"
        onClick={ openDropDown }
      >
        <span>{ currentText || "" }</span>
      </a>
      {
        showDropDown &&
        <div className="chosen-drop">
          <ul className="chosen-results">
            {
              map(elements, (item, index) => (
                <li
                  key={ `statTesting${index}` }
                  className={
                    cn(
                      'active-result',
                      { 'result-selected highlighted': currentValue === item.value }
                    )
                  }
                  onClick={ (event) => onSelectElement(event, item) }
                >
                  { item.text }
                </li>
              ))
            }
          </ul>
        </div>
      }
    </div>
  );
};

export default CombinationsDropdown;
