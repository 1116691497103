const equinoxHash = (baseUrl) => {
  const url = [ baseUrl, "equinox" ].join("/");
  return {
    AchievementsSvg: `${url}/Achievements.svg`,
    ActivitiesBarSvg: `${url}/ActivitiesBar.svg`,
    AthleticConditionsJpg: `${url}/AthleticConditions.jpg`,
    AndrewPng: `${url}/Andrew.png`,
    AndrewPreviewJpg: `${url}/AndrewPreview.jpg`,
    AthleticJpg: `${url}/Athletic.jpg`,
    BestAbsEverJpg: `${url}/BestAbsEver.jpg`,
    BreannaPng: `${url}/Breanna.png`,
    BreannaPreviewJpg: `${url}/BreannaPreview.jpg`,
    CaloriesBarSvg: `${url}/CaloriesBar.svg`,
    ChelseaPng: `${url}/Chelsea.png`,
    ChelseaPreviewJpg: `${url}/ChelseaPreview.jpg`,
    CollenPng: `${url}/Collen.png`,
    CollenPreviewJpg: `${url}/CollenPreview.jpg`,
    ContinuousLineSvg: `${url}/ContinuousLine.svg`,
    cyclingPng: `${url}/cycling.png`,
    DanceFinisherJpg: `${url}/DanceFinisher.jpg`,
    DavidPng: `${url}/David.png`,
    DavidPreviewJpg: `${url}/DavidPreview.jpg`,
    ElizabethPng: `${url}/Elizabeth.png`,
    ElizabethPreviewJpg: `${url}/ElizabethPreview.jpg`,
    EquinoxJpg: `${url}/Equinox.jpg`,
    EquinoxBgJpg: `${url}/EquinoxBg.jpg`,
    FirestarterJpg: `${url}/Firestarter.jpg`,
    GerardPng: `${url}/Gerard.png`,
    GerardPreviewJpg: `${url}/GerardPreview.jpg`,
    GerardPreviewLargeJpg: `${url}/GerardPreviewLarge.jpg`,
    HIITPng: `${url}/HIIT.png`,
    IsometricJpg: `${url}/Isometric.jpg`,
    KateyPng: `${url}/Katey.png`,
    KateyPreviewJpg: `${url}/KateyPreview.jpg`,
    KileyPng: `${url}/Kiley.png`,
    KileyPreviewJpg: `${url}/KileyPreview.jpg`,
    LegsAndCoreJpg: `${url}/LegsAndCore.jpg`,
    meditationPng: `${url}/meditation.png`,
    MetCon3Jpg: `${url}/MetCon3.jpg`,
    MetCon3FinishersJpg: `${url}/MetCon3Finishers.jpg`,
    PureYogaJpg: `${url}/PureYoga.jpg`,
    routineJpg: `${url}/routine.jpg`,
    runningPng: `${url}/running.png`,
    schedule1Jpg: `${url}/schedule1.jpg`,
    schedule2Jpg: `${url}/schedule2.jpg`,
    schedule3Jpg: `${url}/schedule3.jpg`,
    schedule4Jpg: `${url}/schedule4.jpg`,
    schedule5Jpg: `${url}/schedule5.jpg`,
    schedule6Jpg: `${url}/schedule6.jpg`,
    schedule7Jpg: `${url}/schedule7.jpg`,
    schedule8Jpg: `${url}/schedule8.jpg`,
    schedule9Jpg: `${url}/schedule9.jpg`,
    schedule10Jpg: `${url}/schedule10.jpg`,
    sculptPng: `${url}/sculpt.png`,
    SoulCycleJpg: `${url}/SoulCycle.jpg`,
    StackedLowerBodyJpg: `${url}/StackedLowerBody.jpg`,
    strengthPng: `${url}/strength.png`,
    stretchRecoveryPng: `${url}/stretch-recovery.png`,
    TanyshaPng: `${url}/Tanysha.png`,
    TanyshaPreviewJpg: `${url}/TanyshaPreview.jpg`,
    trendingJpg: `${url}/trending.jpg`,
    walkingPng: `${url}/walking.png`,
    yogaPng: `${url}/yoga.png`
  };
};

export default equinoxHash;
