import React from 'react';
import Header from './header';
import Sticky from './sticky';
import Tip from './tip';
import Preview from './preview';
import Footer from './footer';
import Chip from './chip';
import { OuterAccordion, AccordionTitle } from './accordion';

const Main = ({ total, disableCheckout, onCheckout, children }) => {
  return <>
    <Sticky stickTo="top">
      <Header total={total} />
    </Sticky>
    <Tip>
      Pair your plans. Get rewarded today with Mobile + Home Rewards.
    </Tip>
    <Preview />
    <div className="verison-tv-content">
      <OuterAccordion open={false}>
        <AccordionTitle title="Gigabit Connection">
          <Chip className="-green">Added</Chip>
        </AccordionTitle>
      </OuterAccordion>
      <OuterAccordion open={true}>
        <AccordionTitle title="Add Live TV"/>
        {children}
      </OuterAccordion>
      <OuterAccordion open={false}>
        <AccordionTitle title="Add Phone" />
      </OuterAccordion>
      <Footer>
        <button
          type="button"
          className="button"
          disabled={disableCheckout}
          onClick={onCheckout}>Checkout</button>
      </Footer>
    </div>
    <Tip>
      Save more when you order online. $99 set up charge waived.
    </Tip>
  </>;
}

export default Main;