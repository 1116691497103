import React from 'react';
import GoogleRecaptcha from '../components/google_recaptcha';
import Success from '../components/success';
import Fail from '../components/fail';
import { checkFraudOption } from './fraud_check';

const completeActionNum = 999;
const completeComponentNum = 1000;
const screenedActionNum = 9999;
const brandScreenedActionNum = 10000;
const screenedComponentNum = 10001;

const lastStepsLogic = (instance, currentStep, forwardValue, recaptchaStep, data, result, projectId, antiFraud) => {
  let needForward = forwardValue;
  let newStep = currentStep;

  switch (currentStep) {
    case recaptchaStep: // reCaptcha
      if (result.pasteDetected) {
        instance.updateStep(recaptchaStep);
        checkFraudOption(projectId, antiFraud, () => {
          instance.updateStep(recaptchaStep + 1);
        }, () => {
          instance.forward(completeActionNum - 1); // forward method will increase them by 1
        });
      } else {
        instance.forward(completeActionNum - 1); // forward method will increase them by 1
      }
      return [ null, null ];
    case (recaptchaStep + 2): // Forward after reCaptcha
      newStep = completeActionNum - 1; // forward method will increase them by 1
      needForward = true;
      break;
    case completeActionNum: // Finish actions
      instance.runSave();
      newStep = completeComponentNum;
      needForward = false;
      break;
    case screenedActionNum: // Screened actions
      instance.runSave('not_eligible');
      newStep = screenedComponentNum;
      needForward = false;
      break;
    case brandScreenedActionNum: // Brand screened action
      instance.runSave('brand_not_eligible');
      newStep = screenedComponentNum;
      needForward = false;
      break;
    case completeComponentNum: // Show finish component
      break;
    case screenedComponentNum: // Show Screened component
      break;
  }

  return [ newStep, needForward ];
};

const LastStepsComponents = ({ step, lastStepsFrom, translations, data, nextStep }) => {
  return (
    <>
      { step === lastStepsFrom && <GoogleRecaptcha loadingOnly /> }
      {
        step === (lastStepsFrom + 1) &&
        <GoogleRecaptcha
          projectId={ data.project_id }
          respondentId={ data.respondent_id }
          nextStep={ nextStep }
        />
      }
      { step === (lastStepsFrom + 2) && <GoogleRecaptcha loadingOnly /> }
      { step === completeComponentNum && <Success text={ translations.survey_passed } /> }
      { step === screenedComponentNum && <Fail text={ translations.survey_disqualified } /> }
    </>
  );
};

export {
  completeActionNum, screenedActionNum, brandScreenedActionNum,
  completeComponentNum, screenedComponentNum,
  LastStepsComponents, lastStepsLogic
};
