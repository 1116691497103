import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import renderRawHtml from '../../../../../../common/render_raw_html';

const popupWidth = 250;
const popupHeight = 100;

const ModalPopup = ({ baseElement, children }) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    const baseElementBox = baseElement.current.getBoundingClientRect();
    const top = baseElementBox.top - popupHeight - 5;
    const left = baseElementBox.left + (baseElementBox.width / 2) - (popupWidth / 2);
    element.setAttribute("style", `left: ${left}px; top: ${top}px; width: ${popupWidth}px; height: ${popupHeight}px;`);
    element.className = "filters-error-message-popup";
    body.appendChild(element);
    return () => {
      body.removeChild(element);
    };
  }, [ baseElement.current ]);
  return createPortal(children, element);
};

const ErrorMessage = ({ message }) => {
  const [ showPopup, setShowPopup ] = useState(false);
  const element = useRef(null);
  const handleMouseOver = () => {
    setShowPopup(true);
  };
  const handleMouseLeave = () => {
    setShowPopup(false);
  };
  return (
    <>
      <div
        ref={ element }
        className="info-tooltip -error"
        onMouseOver={ handleMouseOver }
        onMouseLeave={ handleMouseLeave }
      >
        <div className="info-tooltip_control">
          <svg className="icon -i-info">
            <use xlinkHref="#svg_i-info" />
          </svg>
        </div>
      </div>
      {
        showPopup &&
        <ModalPopup baseElement={ element }>
          <div className="popup-inner" { ...renderRawHtml(message) } />
        </ModalPopup>
      }
    </>
  );
};

export default ErrorMessage;
