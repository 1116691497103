import {
  each, values, map, keys, includes,
  find, filter, sumBy, isInteger, isEmpty
} from "lodash";
import { calculatePerksSum, calculatePerksSavings, fixedView } from "./helper";

const resultHash = {
  linesCount: 0,
  zipCode: '',
  lines: {},
  get backClick() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.forkBackClick || 0) +
        (item.popularBackClick || 0) +
        (item.perksBackClick || 0) +
        (item.coreBackClick || 0)
      )
    );
  },
  get editPlanClick() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.orderEditPopularPlanClick || 0) +
        (item.orderEditCustomizedPopularPlanClick || 0) +
        (item.orderEditBuiltOwnPlanClick || 0)
      )
    );
  },
  get removePlanClick() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.orderRemovePopularPlanClick || 0) +
        (item.orderRemoveCustomizedPopularPlanClick || 0) +
        (item.orderRemoveBuiltOwnPlanClick || 0)
      )
    );
  },
  get totalPrice() {
    return parseFloat(
      sumBy(
        values(this.lines),
        (item) => (item.planPrice || 0)
      ).toFixed(2)
    );
  },
  get totalSave() {
    return parseFloat(
      sumBy(
        values(this.lines),
        (item) => (item.planSave || 0)
      ).toFixed(2)
    );
  },
  get totalSaveInteger() {
    const totalSaveParts = fixedView(this.totalSave).split('.');
    return parseInt(totalSaveParts[0]);
  },
  get totalSaveFractional() {
    const totalSaveParts = fixedView(this.totalSave).split('.');
    return parseInt(totalSaveParts.length > 1 ? totalSaveParts[1] : 0);
  },
  get perksDisneyDoubleSelected() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.perksDisneyDoubleSelected || 0) +
        (item.popularMovieDisneyDoubleSelectedSelectPlanClick || 0)
      )
    );
  },
  get perksAppleOneDoubleSelected() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.perksAppleOneIndividualDoubleSelected || 0) +
        (item.perksAppleOneFamilyDoubleSelected || 0) +
        (item.popularAppleAppleOneDoubleSelectedSelectPlanClick || 0)
      )
    );
  },
  get perksWalmartDoubleSelected() {
    return sumBy(
      values(this.lines),
      (item) => (
        (item.perksWalmartDoubleSelected || 0) +
        (item.popularShoppingWalmartDoubleSelectedSelectPlanClick || 0)
      )
    );
  },
  get perksAppleMusicDoubleSelected() {
    return sumBy(
      values(this.lines),
      (item) => (item.perksAppleMusicDoubleSelected || 0)
    );
  },
  get perksCloudStorageDoubleSelected() {
    return sumBy(
      values(this.lines),
      (item) => (item.perksCloudStorageDoubleSelected || 0)
    );
  }
};

const setHashData = (path, value) => {
  const pathItems = path.split('.');
  let tempItem = resultHash;
  each(pathItems, (pathEl, index) => {
    if (index !== pathItems.length - 1) {
      if (!tempItem[pathEl]) {
        tempItem[pathEl] = {};
      }
      tempItem = tempItem[pathEl];
    } else { tempItem[pathEl] = value; }
  });
};

const getHashData = (path) => {
  const pathItems = path.split('.');
  let tempItem = resultHash, result = null;
  each(pathItems, (pathEl, index) => {
    if (!tempItem || !tempItem[pathEl]) {
      result = null;
      return false;
    } else if (index === pathItems.length - 1) {
      result = tempItem[pathEl];
      return false;
    }
    tempItem = tempItem[pathEl];
    return true;
  });
  return result;
};

const updateHashByKey = (hashKey, margin = 1) => {
  const prevValue = getHashData(hashKey) || 0;
  setHashData(hashKey, prevValue + margin);
};

let currentLine = 1;
const setCurrentLine = (val) => {
  currentLine = val;
};
const getCurrentLine = () => currentLine;

const setHashDataForTemplatePlan = (line, allPerks, templatePlan, isCorePlan, perks, variantsHash) => {
  const templatePlanId = templatePlan?.id;
  const removedPerkIds = [];
  const addedPerkIds = [];
  const changedPerkVariantIds = {};

  if (templatePlanId) {
    const templatePerks = templatePlan.perks;
    const templatePerkIds = map(templatePerks, (el) => el.id);
    const selectedPerkIds = map(perks, (el) => el.id);
    each(
      templatePerkIds,
      (perkId) => {
        if (!includes(selectedPerkIds, perkId)) {
          removedPerkIds.push(perkId);
        }
      }
    );
    each(
      selectedPerkIds,
      (perkId) => {
        if (!includes(templatePerkIds, perkId)) {
          addedPerkIds.push(perkId);
        }
      }
    );
    each(variantsHash, (variantId, perkId) => {
      const templatePerk = find(templatePerks, (perk) => perk.id === parseInt(perkId));
      const selectedPerk = find(perks, (perk) => perk.id === parseInt(perkId));
      if (templatePerk && selectedPerk && templatePerk.variants) {
        const templateVariantId = find(
          templatePerk.variants, (variant) => variant.default
        )?.id;
        if (templateVariantId !== parseInt(variantId)) {
          if (!changedPerkVariantIds[perkId]) {
            changedPerkVariantIds[perkId] = [];
          }
          changedPerkVariantIds[perkId].push(templateVariantId);
        }
      }
    });
  }
  setHashData(`lines.${line}.planTemplatePlanId`, (templatePlanId && !isCorePlan) ? templatePlanId : null);
  setHashData(`lines.${line}.planRemovedPerkIds`, removedPerkIds);
  setHashData(`lines.${line}.planAddedPerkIds`, addedPerkIds);
  each(allPerks || [], (perk) => {
    if (perk.variants) {
      setHashData(`lines.${line}.planChanged${perk.statisticsName}FromVariantId`, changedPerkVariantIds[perk.id] || []);
    }
  });
};

const setPlan = (planObject, line, allPerks) => {
  const planName = planObject?.core?.title ? planObject.core.title : planObject?.name;
  setHashData(`lines.${line}.planId`, planObject.id || null);
  setHashData(`lines.${line}.planTitle`, planName || '');
  setHashData(`lines.${line}.planPerkIds`, map(planObject.perks || [], ({ id }) => id));
  setHashData(`lines.${line}.planPerkVariants`, planObject.variantsHash || {});
  setHashData(`lines.${line}.specialCustom`, planObject.specialCustom || false);
  setHashData(`lines.${line}.baseCustom`, planObject.baseCustom || false);
  setHashData(`lines.${line}.originalPlanId`, planObject.originalPlanId);
  setHashData(`lines.${line}.planCustomBuilt`, !!planObject.originalPlanId);

  each(allPerks || [], (perk) => {
    if (perk.variants) {
      setHashData(`lines.${line}.plan${perk.statisticsName}VariantId`, null);
    }
  });
  each(planObject.variantsHash || {}, (variantId, perkId) => {
    const perk = find(planObject.perks || [], (perk) => perk.id === parseInt(perkId)) || {};
    const variant = find(perk.variants || [], (variant) => variant.id === parseInt(variantId));
    if (variant) {
      setHashData(`lines.${line}.plan${perk.statisticsName}VariantId`, variantId);
    }
  });
  setHashData(`lines.${line}.planPrice`, parseFloat(
    calculatePerksSum(
      planObject.price || 0, planObject.perks || [], planObject.variantsHash || {}
    ).toFixed(2)
  ));
  const lineSave = fixedView(
    calculatePerksSavings(planObject.perks || [], planObject.variantsHash || {})
  );
  const lineSaveParts = lineSave.split('.');

  setHashData(`lines.${line}.planSave`, parseFloat(lineSave));
  setHashData(`lines.${line}.planSaveInteger`, parseInt(lineSaveParts[0]));
  setHashData(`lines.${line}.planSaveFractional`, parseInt(lineSaveParts.length > 1 ? lineSaveParts[1] : 0));

  setHashData(`lines.${line}.planSave`, parseFloat(
    calculatePerksSavings(
      planObject.perks || [], planObject.variantsHash || {}
    ).toFixed(2)
  ));

  // planBuildType:
  // 1 = Popular Plan
  // 2 = Customized Popular Plan when clicked "Customize" button
  // in the popular plan box.
  // 3 = Customized Popular Plan when clicked "Customize" button
  // in the popup telling about identical perks added on the other line.
  // 4 = Built Own Plan
  let buildTypeId;
  if (!isEmpty(planObject)) {
    if (planObject.popularPlan) {
      buildTypeId = 1;
    } else if (planObject.originalPlanId) {
      buildTypeId = planObject.specialCustom ? 3 : 2;
    } else {
      buildTypeId = 4;
    }
  }
  setHashData(`lines.${line}.planBuildType`, buildTypeId);
};

const statisticsAdditionalItems = [
  'forkSelectPopular', 'forkSelectBuildOwn', 'forkBackClick',

  'popularBackClick', 'popularSelectBuildOwn',
  'popularMovieCoreDetailsClick', 'popularMovieTermsClick',
  'popularMoviePerkDisneyDetailsClick', 'popularMoviePerkHotspotDetailsClick',
  'popularMovieSelectClick', 'popularMovieCustomizeClick',
  'popularAppleCoreDetailsClick', 'popularAppleTermsClick',
  'popularApplePerkAppleOneDetailsClick',
  'popularAppleSelectClick', 'popularAppleCustomizeClick',
  'popularShoppingCoreDetailsClick', 'popularShoppingTermsClick',
  'popularShoppingPerkWalmartDetailsClick',
  'popularShoppingSelectClick', 'popularShoppingCustomizeClick',

  'perksBackClick', 'perksSummaryTermsClick',
  'perksDisneyTurnedOn', 'perksDisneyTurnedOff', 'perksDisneyDetailsClick',
  'perksHotspotTurnedOn', 'perksHotspotTurnedOff', 'perksHotspotDetailsClick',
  'perksAppleOneTurnedOn', 'perksAppleOneTurnedOff', 'perksAppleOneDetailsClick',
  'perksWalmartTurnedOn', 'perksWalmartTurnedOff', 'perksWalmartDetailsClick',
  'perksAppleMusicTurnedOn', 'perksAppleMusicTurnedOff', 'perksAppleMusicDetailsClick',
  'perksSmartWatchTurnedOn', 'perksSmartWatchTurnedOff', 'perksSmartWatchDetailsClick',
  'perksPlusPlayTurnedOn', 'perksPlusPlayTurnedOff', 'perksPlusPlayDetailsClick',
  'perksTravelPassTurnedOn', 'perksTravelPassTurnedOff', 'perksTravelPassDetailsClick',
  'perksCloudStorageTurnedOn', 'perksCloudStorageTurnedOff', 'perksCloudStorageDetailsClick',
  'perksSwipePerksInDetails',

  'orderRemovePopularPlanClick', 'orderRemoveCustomizedPopularPlanClick',
  'orderRemoveBuiltOwnPlanClick', 'orderPickPlanClick', 'orderEditPopularPlanClick',
  'orderEditCustomizedPopularPlanClick', 'orderEditBuiltOwnPlanClick',

  'coreUnlimitedPlusReviewClick', 'coreUnlimitedWelcomeReviewClick',
  'coreUnlimitedPlusClick', 'coreUnlimitedWelcomeClick', 'coreCompareClick',
  'coreCompareUnlimitedPlusClick', 'coreCompareUnlimitedWelcomeClick', 'coreBackClick',

  'perksAppleOneIndividualSelectedAppleMusicTryFormError',
  'perksAppleOneIndividualSelectedAppleMusicTryAppleOneFamilyChosen',
  'perksAppleOneIndividualSelectedAppleMusicTryAppleOneIndividualChosen',
  'perksAppleOneIndividualSelectedAppleMusicTryAppleMusicChosen',

  'perksAppleOneFamilySelectedAppleMusicTryFormError',

  'perksAppleMusicSelectedAppleOneTryFormError',
  'perksAppleMusicSelectedAppleOneTryAppleMusicChosen',
  'perksAppleMusicSelectedAppleOneTryAppleOneFamilyChosen',

  'perksDisneyDoubleSelected',
  'perksAppleOneIndividualDoubleSelected', 'perksAppleOneFamilyDoubleSelected',
  'perksWalmartDoubleSelected', 'perksAppleMusicDoubleSelected', 'perksCloudStorageDoubleSelected',

  'popularMovieDisneyDoubleSelectedSelectPlanClick', 'popularAppleAppleOneDoubleSelectedSelectPlanClick',
  'popularShoppingWalmartDoubleSelectedSelectPlanClick',
  'popularMovieDisneyDoubleSelectedCustomizePlanClick', 'popularAppleAppleOneDoubleSelectedCustomizePlanClick',
  'popularShoppingWalmartDoubleSelectedCustomizePlanClick',
  'popularMovieDisneyDoubleSelectedCustomizeInForm', 'popularAppleAppleOneDoubleSelectedCustomizeInForm',
  'popularShoppingWalmartDoubleSelectedCustomizeInForm',
  'popularMovieDisneyDoubleSelectedKeepInForm', 'popularAppleAppleOneDoubleSelectedKeepInForm',
  'popularShoppingWalmartDoubleSelectedKeepInForm'
];

const statisticsBackPageKey = {
  'Fork': 'forkBackClick',
  'bestPlans': 'popularBackClick',
  'buildYourOwn': 'coreBackClick',
  'selectPerks': 'perksBackClick'
};

const setAdditionalStatisticsItems = (id) => {
  each(statisticsAdditionalItems, (fieldName) => {
    const path = `lines.${id}.${fieldName}`;
    if (!isInteger(getHashData(path))) {
      setHashData(path, 0);
    }
  });
};

const createLine = (id, plan = {}, allPerks) => {
  setPlan(plan, id);
  setHashData(`lines.${id}.planCustomBuilt`, false);
  setHashDataForTemplatePlan(id, allPerks);
  setAdditionalStatisticsItems(id);
};

const setLinesCount = (value, allPerks) => {
  setHashData('linesCount', value);

  for (let i = 1; i <= value; i++) {
    if (!getHashData(`lines.${i}.planTitle`)) {
      createLine(i, {}, allPerks);
    }
  }

  while (value < keys(resultHash.lines).length) {
    delete resultHash.lines[keys(resultHash.lines).length];
  }
};
const getLinesCount = () => getHashData('linesCount');

const setZipCode = (value) => {
  setHashData('zipCode', value);
};

const getSelectedPlans = (existingPlans) => map(
  values(resultHash.lines),
  (item) => (
    find(existingPlans, (el) => el.id === item.planId)
  )
);

const getSelectedPlanIdByLine = (line) => {
  return resultHash.lines[line].planId;
};

const sendData = (customData = {}) => {
  const finalData = {
    ...resultHash,
    clicksKeys: statisticsAdditionalItems,
    lines: values(resultHash.lines)
  };
  const postData = {
    ...(customData || {}),
    ...{
      kind: 'finishPlans',
      result: finalData
    }
  };

  (window.parent || window).postMessage(postData, "*");

  console.log('Finish Plans', finalData);
};

const getCustomBuiltDataFromCurrentLine = (plansPrebuild, plansConstruct, perksList, customLine = null, options = {}) => {
  const { takeNoData } = options;
  const line = customLine || getCurrentLine();
  const lineData = takeNoData ? {} : (getHashData(`lines.${line}`) || {});
  const {
    planId, planPerkIds, planPerkVariants, planCustomBuilt,
    specialCustom, baseCustom, originalPlanId
  } = lineData;
  const popularPlan = find(plansPrebuild, (item) => item.id === planId);
  const popularPlanNotChanged = popularPlan && !planCustomBuilt;
  return {
    linePresent: !!lineData.planId,
    planCustomBuilt,
    specialCustom,
    baseCustom,
    originalPlanId,
    selectedPlan: (
      popularPlanNotChanged ?
        null :
        find([ ...plansPrebuild, ...plansConstruct ], (item) => item.id === planId)
    ),
    perks: (
      popularPlanNotChanged ?
        [] :
        filter(perksList || [], (item) => includes(planPerkIds || [], item.id))
    ),
    planPerkVariants: popularPlanNotChanged ? {} : planPerkVariants
  };
};

const isLinesFilled = () => keys(resultHash.lines)
  .reduce((acc, idx) => acc && resultHash.lines[idx].planId !== null, true);

const increaseVariable = (key, line = null) => {
  const hashKey = line ? `lines.${line}.${key}` : key;
  const count = getHashData(hashKey);
  setHashData(hashKey, count ? count + 1 : 1);
};

const increaseVariableForCurrentLine = (key, line = null) => {
  increaseVariable(key, line || getCurrentLine());
};

export {
  setLinesCount,
  getLinesCount,
  setZipCode,
  setCurrentLine,
  getCurrentLine,
  setHashData,
  getHashData,
  updateHashByKey,
  setPlan,
  setHashDataForTemplatePlan,
  getCustomBuiltDataFromCurrentLine,
  createLine,
  getSelectedPlans,
  isLinesFilled,
  sendData,
  getSelectedPlanIdByLine,
  increaseVariable,
  increaseVariableForCurrentLine,
  statisticsBackPageKey
};
