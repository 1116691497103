import React, { useContext } from 'react';
import { LocalizationContext } from '../../common/contexts';
import renderRawHtml from '../../../../../../../../common/render_raw_html';
import FooterButton from '../../../../footer_button';

const ChoseWrongPopup = ({ onClick }) => {
  const localization = useContext(LocalizationContext);
  return (
    <div className="zoom-area_success-popup-wrapper disable-zoom">
      <div className="zoom-area_success-popup">
        <span
          className="zoom-area_success-popup-text"
          { ...renderRawHtml(localization.fail) }
        />
        <FooterButton className="-short" nextStep={ onClick } />
      </div>
    </div>
  );
};

export default ChoseWrongPopup;
