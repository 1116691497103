import React, { Fragment, useContext, useState } from 'react';
import { map, filter } from 'lodash';
import renderRawHtml from "../../../../common/render_raw_html";
import lineName from '../line_name';
import { LineDataContext } from '../contexts';
import Popup from '../popup';

const noBreaks = (text) => {
  return text.replace(/<br\/>/, ' ').replace(/\s+/, ' ');
}

const UnlimitedPlan = ({ planObject, onPlanSelect }) => {
  const [showPopup, updateShowPopup] = useState(false);
  const clickPlanDetails = (event) => {
    event.preventDefault();
    updateShowPopup(true);
  };
  return (
    <div className="box ng-scope">
      <div className="description-box">
        <h2 className="title ng-binding" {...renderRawHtml(planObject.title)}>
        </h2>
        <div className="text-16 description" {...renderRawHtml(planObject.description)}>
        </div>
        <div className="box-pricing">
          <div>
            <div className="price">
              <h2 className="big-value">${ planObject.price }</h2>
              <div className="value-hint">
                <div className="text-10">/line per month</div>
                <div className="text-10">Plus taxes & fees.</div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="select-billing-plan"
          onClick={ (event) => { onPlanSelect(event, planObject) } }
        >
          Select plan
        </button>
      </div>
      <div className="plan_features text-14">
        {
          map([...planObject.features, ...planObject.perks], (feature, index) => {
            return (
              <Fragment key={ `unlimitedPlanFeature${planObject.id}${index}` }>
                {
                  feature.before &&
                  <div className="plan_feature -bold"  {...renderRawHtml(noBreaks(feature.before))}>
                  </div>
                }
                <div className="plan_feature"  {...renderRawHtml(noBreaks(feature.title))}>
                </div>
              </Fragment>

            )
          })
        }

      </div>
      {
        showPopup &&
          <Popup onClose={ () => { updateShowPopup(false) } }>
            <Fragment>
              <h2 className="popup-header">{ planObject.name } details</h2>
              <div className="popup-body">
                {
                  map(filter([...planObject.features, ...planObject.perks], (item) => !!item.title && item.title != "&nbsp;"), (feature, index) => {
                    return (
                        <div key={ `unlimitedPlanPopupFeature${planObject.id}${index}` } className="popup-feature">
                          <h4  {...renderRawHtml(noBreaks(feature.title))}>
                          </h4>
                          <span className="text-14"  {...renderRawHtml(feature.description) }>
                          </span>
                        </div>
                    )
                  })
                }
              </div>
            </Fragment>
          </Popup>
      }
    </div>
  )
};

const UnlimitedPage = ({ currentLine, onPlanSelect, onChangePage, showChangePage }) => {
  const { unlimited } = useContext(LineDataContext);
  return(
    <div className="content">
      <div className="header"></div>
      <div className="unlimited-page">
        <div className="inner-content">
          <div className="top-description">
            <div className="ad-left">
              <h2>Let’s choose a plan for your { lineName(currentLine) } line.</h2>
              <span className="text-20">
                Enroll in paper-free billing and Auto Pay after account creation to save an additional $10/mo on these plans.
                <svg className="info"><use xlinkHref="#svg_tooltip"/></svg>
              </span>
            </div>
            <div className="ad-right">
              <h4>All Unlimited plans include</h4>
              <span className="text-14">
                {
                  map(unlimited.allPlansInclude, (feature, index) => {
                    return(
                      <Fragment key={ `allFeaturesUnlimited${index}` }>
                        { !!index && <br/> }
                        { feature }
                      </Fragment>
                    )
                  })
                }
              </span>
            </div>
          </div>
          {
            showChangePage &&
            <div className="split-button-content">
              <button className="select-billing-plan" onClick={ onChangePage }>See our other plans<span>&gt;</span></button>
            </div>
          }
          <ul className="plans-view-selector"></ul>
          <div className="inner-content">
            <div className="list_view">
              {
                map(unlimited.unlimitedPlans, (planObject) => {
                  return(
                    <UnlimitedPlan
                      key={ `unlimitedPlanItem${planObject.id}` }
                      planObject={ planObject }
                      onPlanSelect={ onPlanSelect }
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlimitedPage;