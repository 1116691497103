import { each, range, find } from 'lodash';

const getAgeOptions = (question) => {
  const result = [];
  each(question.answers, (answer) => {
    const matchingRes = answer.label.match(/(\d+)-(\d+)/);
    if (matchingRes) {
      each(range(matchingRes[1] * 1, (matchingRes[2] * 1) + 1), (el) => {
        result.push(el);
      });
    } else {result.push(answer.label);}
  });
  return result;
};

const getAgeAnswerId = (question, value) => {
  const isNumber = typeof value == 'number';
  let intValue = null;

  if (!isNumber) {
    intValue = value.match(/^\d+$/) ? parseInt(value, 10) : false;
  } else {
    intValue = value;
  }

  const answerItem = find(question.answers, (answer) => {
    if (intValue) {
      const matchingRes = answer.label.match(/(\d+)-(\d+)/);
      if (matchingRes) {
        return (intValue >= matchingRes[1] && intValue <= matchingRes[2]);
      }
      return false;
    }
    return value === answer.label;
  });

  return answerItem ? answerItem.id : '';
};

export { getAgeOptions as getAgeOptions, getAgeAnswerId as getAgeAnswerId };
