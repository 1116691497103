import React, { useState, useEffect, useContext } from 'react';
import { map } from "lodash";
import TagsInput from "../tags-input";
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import renderRawHtml from "../../../../../common/render_raw_html";
import SentimentList from './sentiment-list';
import { urls } from "./const";
import { ExchangeData } from "./exchange-data";

const RightPanel = ({ data, metric, filter, answerHelp, isAdmin, setLoading }) => {
  const [ afterFilterIds, setAfterFilterIds ] = useState([]);
  const [ initTags, setInitTags ] = useState([]);
  const lcKey = `openEndsSection${data?.id}_${metric?.tag}_${metric?.title}`.replace(/\s+/g, '_');

  const loadKeywords = (q, resolve) => {
    const url = urls.keywords.replace(':concept_id', metric.concept_id);
    return Http.post(
      url,
      {
        q, filter,
        source_id: metric.source_id,
        source_type: metric.source_type
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          resolve(map(http.data, (el) => ({ value: el.text, label: el.text })));
        } else {
          resolve([]);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const filterAnswers = (tags) => {
    if (tags.length > 0) {
      setLoading(true);

      const q = tags.join('|');
      Http.post(
        urls.filter.replace(':concept_id', data.concept_id),
        {
          source_id: metric.source_id,
          source_type: metric.source_type,
          q,
          filter: (filter || [])
        }
      ).then(
        (http) => {
          if (http.response.status === 200) {
            setAfterFilterIds(map(http.data, (el) => el.id));
          }
          setLoading(false);
        },
        (reject) => {console.log('something went wrong', reject);}
      );
    } else {
      setAfterFilterIds(map(data.all_neutral_answers, (el) => el.id));
    }
  };

  const onTagsChange = (tags) => {
    window.localStorage.setItem(lcKey, JSON.stringify(tags));
    filterAnswers(tags);
  };

  useEffect(() => {
    const parsedTags = JSON.parse(window.localStorage.getItem(lcKey) || '[]');
    setInitTags(parsedTags);

    setAfterFilterIds(map(data.all_neutral_answers, (el) => el.id));
    filterAnswers(parsedTags);
  }, [ data ]);

  const { message, setMessage } = useContext(ExchangeData);

  return (
    <div className="sentiments">
      <div className="sentiments_header">
        <div className="tile-header -no-border -nowrap-in-standalone">
          <div className="tile-header_main">
            <TagsInput
              initTags={ initTags }
              loadKeywords={ loadKeywords }
              onTagsChange={ onTagsChange }
              autoTag={ message }
              setAutoTag={ setMessage }
            />
          </div>
        </div>
      </div>

      <div className="sentiments_content">
        <div className="sentiments_content-main">
          {answerHelp && <div className="sentiments-help">
            <div className="info-tooltip_control -help-on -help-top">
              <span>
                <SvgIcon name="i-info" />
                <span className="-help -margin-top -right">
                  <span className="inner" { ...renderRawHtml(answerHelp) } />
                </span>
              </span>
            </div>
          </div>}

          <div className="sentiments_content-scrollable">
            <SentimentList data={ data } metric={ metric } isAdmin={ isAdmin } afterFilterIds={ afterFilterIds } />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
