import React, { useContext } from 'react';
import { BaseContext } from '../../contexts';

const ConceptsDatabase = () => {
  const { initialData, paths } = useContext(BaseContext);
  const canReviewConceptsDatabase = initialData.can_review_concepts_database;
  const conceptsDatabaseUrl = paths.concepts_database;
  return (
    <>
      {
        canReviewConceptsDatabase &&
        <li className="sidebar_nav-list-item">
          <a
            className="sidebar_nav-link -with-icon"
            href={ conceptsDatabaseUrl }
          >
            <svg className="sidebar_nav-link-icon icon -menu-tags">
              <use xlinkHref="#svg_menu-concepts-database" />
            </svg>
            <span className="sidebar_nav-link-text">Concepts Database</span>
          </a>
        </li>
      }
    </>
  );
};

export default ConceptsDatabase;
