import React, { useState, useRef, useEffect } from 'react';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { each, map, find } from 'lodash';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import classnames from 'classnames';

const UploadButton = (props) => {
  const {
    className,
    filenameOnBottom,
    allowedTypes,
    multiple,
    children,
    onUploaded,
    onError,
    onInit,
    needPreview,
    thumbnailWidth,
    customProcessingTitle
  } = props;
  const [ fileState, setFileState ] = useState({});
  const [ filenamesList, setFilenamesList ] = useState([]);
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const fileFieldRef = useRef(null);

  const uploadedFilesData = (results) => {
    return map(results, (file) => {
      const srcPath = `${file.xhrUpload.endpoint}/${file.meta.key}`;
      return {
        previewSrc: file.preview,
        cacheSrc: srcPath,
        metaData: JSON.stringify({
          id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
          storage: 'cache',
          metadata: { size: file.size, filename: file.name, mime_type: file.type }
        }),
        origin: file
      };
    });
  };

  const createUppyInstance = () => {
    const service = new Uppy()
      .use(AwsS3, { companionUrl: '/' })
      .on('file-added', (file) => { uppy.upload(); })
      .on('upload-success', (result, response) => {
        setFileState((value) => {
          return {
            file: result,
            selected: value.selected - 1,
            uploaded: [ ...(value.uploaded || []), ...[ result ] ],
            previewed: [ ...(value.previewed || []), ...[ result ] ]
          };
        });
      })
      .on('thumbnail:generated', (result) => {
        setFileState((currentValue) => {
          currentValue.previewed = [ ...(currentValue.previewed || []), ...[ result ] ];
          return currentValue;
        });
      })
      .on('error', (result, response) => {
        console.log('Upload errors');
        setLoading(false);
        setError(true);
        if (onError) {
          onError();
        }
      })
      .on('upload-error', (result, response) => {
        console.log('Upload errors');
        setLoading(false);
        setError(true);
        if (onError) {
          onError();
        }
      });
    if (needPreview) {
      service.use(
        ThumbnailGenerator,
        {
          thumbnailWidth: thumbnailWidth || 800,
          waitForThumbnailsBeforeUpload: true
        }
      );
    }
    return service;
  };

  const [ uppy ] = useState(createUppyInstance);

  const onClick = () => {
    fileFieldRef.current.click();
  };
  const resetFiles = () => {
    uppy.getState().files = {};
    uppy.getState().totalProgress = null;
  };
  const onFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setLoading(true);
    setError(false);
    setFileState({ selected: files.length, uploaded: [], previewed: [] });
    onInit && onInit();
    resetFiles();
    each(files, (file) => {
      uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file
      });
    });
    fileFieldRef.current.value = '';
  };

  useEffect(() => {
    const { file, selected, uploaded, previewed } = (fileState || {});
    if (file) {
      // sometimes we don't have a preview on this stage
      const fileWithPreview = find(previewed, (item) => item.id === file.id);
      if (fileWithPreview) {
        file.preview = fileWithPreview.preview;
      }
      setFilenamesList(map(uploaded, (item) => item.meta.name));
      if (!selected && onUploaded) {
        setLoading(false);
        setError(false);
        onUploaded(uploadedFilesData(uploaded));
      }
    }
  }, [ fileState ]);

  return (
    <>
      <input
        type="file"
        name="file"
        ref={ fileFieldRef }
        style={ { width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: 'absolute', zIndex: -1 } }
        accept={ (allowedTypes || []).join(', ') }
        multiple={ multiple }
        onChange={ onFileSelect }
      />
      {
        loading &&
        <button disabled type="button" className={ classnames("upload-button-processing", className) } >
          {
            loading &&
            (customProcessingTitle ? customProcessingTitle : 'Processing...')
          }
        </button>
      }
      {
        (!loading && !error) &&
        <button type="button" className={ className } onClick={ onClick }>{ children }</button>
      }
      {
        (!loading && error) &&
        <span className="upload-button-with-error-message">
          <button type="button" className={ className } onClick={ onClick }>{ children }</button>
          {
            error &&
            <span className="upload-button-error-message">The upload error happened last time.</span>
          }
        </span>
      }
      {
        filenameOnBottom && !!filenamesList.length &&
        <ul>
          {
            map(filenamesList, (item, index) => (
              <li key={ `${item}${index}` }>{ item }</li>
            ))
          }
        </ul>
      }
    </>
  );
};

export default UploadButton;
