import React, { useContext } from 'react';
import classnames from "classnames";
import { TransformContext } from '../contexts';

const ScaleControlsBody = ({ className }) => {
  const { getScale, max, min, setScale } = useContext(TransformContext);
  const scale = getScale();
  const setInitialZoom = () => {
    setScale(min);
  };
  const middleZoom = min + ((max - min) / 2);
  const setMiddleZoom = () => {
    setScale(middleZoom);
  };
  const setMaxZoom = () => {
    setScale(max);
  };

  return (
    <div className={ classnames("scale-control", className) }>
      <div className="scale-control-inner">
        <div className="scale-button-wrapper -left">
          <button
            type="button"
            className="scale-control_button -min"
            disabled={ scale === min }
            onClick={ setInitialZoom }
          />
        </div>
        <div className="scale-button-wrapper -middle">
          <button
            type="button"
            className="scale-control_button -x -middle"
            disabled={ scale === middleZoom }
            onClick={ setMiddleZoom }
          >2x</button>
        </div>
        <div className="scale-button-wrapper -right">
          <button
            type="button"
            className="scale-control_button -x -max"
            disabled={ scale === max }
            onClick={ setMaxZoom }
          >3x</button>
        </div>
      </div>
    </div>
  );
};

const ScaleControls = ({ className, withWrapper }) => {
  const bodyComponent = (
    <ScaleControlsBody className={ className } />
  );

  return (
    <>
      {
        withWrapper &&
        <div className="scale-control_wrapper">
          { bodyComponent }
        </div>
      }
      { !withWrapper &&  bodyComponent }
    </>
  );
};

export default ScaleControls;
