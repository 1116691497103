import React from 'react';
import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Header from "../../components/header";

const FirstPage = ({ nextStep, skipStep, headerStep, numberOfHeaderSteps }) => {
  const handleNext = () => {
    nextStep();
  };
  const handleSkip = () => {
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      <button
        className="button -full-width -wallet"
        onClick={ handleNext }
      >
        <svg className={ `wallet-icon` }>
          <use xlinkHref={ `#svg_wallet-icon` } />
        </svg>
        Add to Apple Wallet
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  return (
    <Layout header={ header } footer={ footer }>
      <div className="base-intent">
        <div className="content_title">
          <h1>Add your PayPal Debit Card to Apple Wallet</h1>
        </div>
        <div className="content_text">
          Make it your default card so it’s easy to earn cash back
          { ' ' }
          in your category when you pay in stores.
        </div>
        <div className="svg-body">
          <svg className={ `card-to-wallet-svg` }>
            <use xlinkHref={ `#svg_card-to-wallet` } />
          </svg>
        </div>
      </div>
    </Layout>
  );
};

export default FirstPage;


