const customList = [
  { name: 'vertical', label: 'Vertical wordcloud', width: 400, height: 800 },
  { name: 'horizontal', label: 'Horizontal wordcloud', width: 800, height: 400 }
];

const breakPoint = 1280;

const defineOrientation = () => {
  return window.innerWidth  < breakPoint ? 'vertical' : 'horizontal';
};

export { customList, defineOrientation };
