import React from 'react';

const ConceptFocusHeader = ({reaction, negativeQuestion, positiveQuestion, n}) => {
  return (
    <div className="concept-focus-description">
      <p>
        {reaction=='positive' && <span><b>Positive:</b> {positiveQuestion}</span>}
        {reaction=='negative' && <span><b>Negative:</b> {negativeQuestion}</span>}
      </p>
      <span> N={n} </span>
    </div>
  );
};

export default ConceptFocusHeader;