import { each } from 'lodash';

const extractUrls = (text) => (text || '').match(/https?:\/\/[^<\s\\]+/g);
const replaceUrlWithLinks = (text) => {
  const urls = extractUrls(text) || [];
  let result = text;
  each(urls, (url) => {
    const updatedUrl = url.replace('&amp;', '&');
    const template = `<a class='link clickable-link' target='_blank' href='${updatedUrl}'>${url}</a>`;
    result = result.replace(url, template);
  });
  return result;
};

export { extractUrls, replaceUrlWithLinks };
