import React from 'react';
import remoteFiles from '../../../common/remote_files';

const Header = ({ total }) => {
  const { logo, language, faq, bag } = remoteFiles.verizon.tv;
  return (<header className="verison-tv-header">
    <img src={ logo } className="verison-tv-img-logo" />
    <div className="verison-tv-stuff">
      <div className="verison-tv-stuff-row">
        <img src={ language } className="verison-tv-img-lenguage" />
      </div>
      <div className="verison-tv-stuff-row">
        <img src={ bag } className="verison-tv-img-bag" />
        <span className="verison-tv-stuff-amount">${total}.00/mo.</span>
        <img src={ faq } className="verison-tv-img-faq" />
      </div>
    </div>
  </header>);
};

export default Header;
