import React, { useState, useEffect } from 'react';
import { map }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../../common/render_raw_html';

const mobileTrigger = 767;

const Grid = ({
  question, gridAnswers, updateGridAnswers,
  updateStateAndSend, alwaysMobileView
}) => {
  const [ isMobile, setIsMobile ] = useState(alwaysMobileView);

  useEffect(() => {
    const handleResizeEvent = () => {
      setIsMobile(alwaysMobileView || window.innerWidth <= mobileTrigger);
    };
    handleResizeEvent();
    addEventListener("resize", handleResizeEvent);
    return () => {
      removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    updateStateAndSend(true);
  }, [ gridAnswers ]);

  const selectGridAnswer = (event, row) => {
    updateGridAnswers({ ...gridAnswers, ...{ [row.id]: event.target.value } });
  };

  const gridItemChecked = (row, scale) => {
    const rowId = row?.id ? (gridAnswers || {})[row.id] : null;

    return parseInt(rowId) === parseInt(scale?.id);
  };

  const renderInnerScales = (row, scale) => {
    return (
      <td key={ `${row.id}-${scale.id}` }>
        <div>
          <label className="radio">
            <input
              type="radio"
              name={ row.id }
              value={ scale.id }
              onChange={ (event) => { selectGridAnswer(event, row); } }
              checked={ gridItemChecked(row, scale) }
            />
            <div className="radio_label -no-text" />
          </label>
        </div>
      </td>
    );
  };

  const renderMobileInnerScales = (row, scale) => {
    return (
      <div className="survey-options_rating-col -custom-fix" key={ `${row.id}-${scale.id}` }>
        <div
          className={
            classnames(
              "survey-options_rating-col-header -show-mobile",
              { '-custom_columns': question.custom_columns }
            )
          }
        >
          <div className="survey-options_rating-legend" { ...renderRawHtml(scale.label) } />
          <div className="survey-options_rating-value">{ scale.id }</div>
        </div>
        <label className="radio">
          <input
            type="radio"
            name={ row.id }
            value={ scale.id }
            onChange={ (event) => { selectGridAnswer(event, row); } }
            checked={ gridItemChecked(row, scale) }
          />
          <div className="radio_label -no-text" />
        </label>
      </div>
    );
  };

  const renderRow = (question, row) => {
    return (
      <tr key={ row.id }>
        <td>
          <div className="survey-options_row-name">
            <div { ...renderRawHtml(row.label) } />
          </div>
        </td>
        { map(question.scales, (scale) => renderInnerScales(row, scale)) }
      </tr>
    );
  };

  const renderMobileRow = (question, row) => {
    return (
      <div className="survey-options_rating-grid-row" key={ row.id }>
        <div className="survey-options_rating-col -name">
          <div className="survey-options_rating-name" { ...renderRawHtml(row.label) } />
        </div>
        { map(question.scales, (scale) => renderMobileInnerScales(row, scale)) }
      </div>
    );
  };

  const renderTopScale = (scale) => (
    <th className={ classnames({ '-custom_columns': question.custom_columns }) } key={ scale.id }>
      <div
        className="survey-options_rating-legend"
        { ...renderRawHtml(scale.label) }
      />
      <div className="survey-options_scale-value">{ scale.id }</div>
    </th>
  );

  const renderMobileTopScale = (scale) => (
    <div
      className={
        classnames(
          "survey-options_rating-col -custom-fix",
          { '-custom_columns': question.custom_columns }
        )
      }
      key={ scale.id }
    >
      <div
        className="survey-options_rating-legend"
        { ...renderRawHtml(scale.label) }
      />
      <div className="survey-options_rating-value">{ scale.id }</div>
    </div>
  );

  const renderQuestion = (question) => {
    return (
      <>
        <thead>
          <tr>
            <th />
            { map(question.scales, (scale) => renderTopScale(scale)) }
          </tr>
        </thead>
        <tbody>
          { map(question.answers, (row) => renderRow(question, row)) }
        </tbody>
      </>
    );
  };

  const renderMobileQuestion = (question) => {
    return (
      <div
        className={
          classnames(
            "survey-options_rating",
            { '-force-mobile': alwaysMobileView }
          )
        }
        key={ question.name }
      >
        <div className="survey-options_rating-header -hide-mobile">
          <div className="survey-options_rating-grid-row">
            <div className="survey-options_rating-col -name" />
            { map(question.scales, (scale) => renderMobileTopScale(scale)) }
          </div>
        </div>
        <div className="survey-options_rating-grid">
          { map(question.answers, (row) => renderMobileRow(question, row)) }
        </div>
      </div>
    );
  };

  const gridOutput = () => (
    <div className="survey-options_table-wrapper">
      <table className="survey-options-table">
        {
          renderQuestion(question)
        }
      </table>
    </div>
  );

  const gridMobileOutput = () => (
    <ul className="survey-options_list">
      {
        renderMobileQuestion(question)
      }
    </ul>
  );

  return (
    <>
      { !isMobile && gridOutput() }
      { isMobile && gridMobileOutput() }
    </>
  );
};

export default Grid;
