import { map, isArray, find, compact, each, sum, includes, minBy, maxBy } from 'lodash';
import React from "react";
import renderRawHtml from "./render_raw_html";
import { getCurrentLine, getHashData } from './statistics';

const scrollTop = () => {
  document.querySelector('html').scrollTop = 0;
};

const capitalize = (text) => {
  if (!text) {
    return '';
  }
  const firstLetter = text.charAt(0).toUpperCase();
  const remainingLetters = text.slice(1);
  return `${firstLetter}${remainingLetters}`;
};

const scrollInModal = (baseElement, headerElement, toElement) => {
  baseElement.scrollTop = 0;
  const baseElementTop = baseElement.getBoundingClientRect()?.top || 0;
  const headerElementHeight = headerElement.getBoundingClientRect()?.height || 0;
  const scrollTop = toElement.getBoundingClientRect()?.top || 0;
  baseElement.scrollTop = (scrollTop - baseElementTop - headerElementHeight);
};

const perkImagePath = "/images/verizon/bayou/perks/";
const perkImageUrl = (imageName) => (`${perkImagePath}${imageName}`);

const fixedView = (number) => ((number || 0).toFixed(2));

const sumAndFormat = (a, b) => (fixedView(a + b));

const sumAndFormatCheckFractional = (a, b) => {
  const formatted = sumAndFormat(a, (b || 0));
  const parts = formatted.split('.');
  if (!parseInt(parts[1])) {
    return parseInt(parts[0]);
  }
  return formatted;
};

const prepareText = ({ texts, type }) => {
  if (!isArray(texts)) {
    return (<p { ...renderRawHtml(texts) } />);
  }
  return (
    (!!type && type === "list") ?
      (
        <ul>
          {
            map(texts, (text, idx) => (
              <li key={ `li-${idx}` } { ...renderRawHtml(text) } />
            ))
          }
        </ul>
      ) :
      (
        map(texts, (text, idx) => (
          <p key={ `p-${idx}` } { ...renderRawHtml(text) } />
        ))
      )
  );
};

const openCoreModal = ({ plan, openModal }) => {
  openModal({
    data: {
      plate: {
        note: plan.note,
        title: plan.title,
        description: plan.description
      },
      listDetails: plan.descriptions
    },
    showFooterButton: true
  });
};

const openTermsModal = ({ perks, openModal }) => {
  openModal({
    title: "Perk terms & conditions",
    data: {
      listDetails: map(perks, (perk) => ({ title: perk.title, description: perk.details.terms })),
      option: {
        listTitle: "md"
      }
    },
    showFooterButton: true
  });
};

const openConflictModal = (item, openModal) => {
  openModal({
    title: item.title,
    data: {
      description: item.descriptions,
      buttonsBottom: item.buttons
    }
  });
};

const findConflicted = (perk, selectedList) => {
  if (!perk.cantStand) {
    return [];
  }
  return compact([
    find(
      selectedList,
      (selectedPerk) => includes(
        selectedPerk.cantStand || [],
        perk.conflictName
      )
    ),
    perk
  ]);
};

const findPerkVariantInTheOtherLine = (perk, variantId) => {
  const currentLine = getCurrentLine();
  if (!perk.doubleAddConflictName) {
    return {};
  }
  const variant = find(perk.variants || [], (item) => item.id === variantId);
  let found = false;
  const dataKeyForAlreadyShown = [
    "perks",
    capitalize(perk.doubleAddConflictName),
    "DoubleSelected"
  ].join('');
  const alreadyShown = !!(getHashData(dataKeyForAlreadyShown) || 0);

  for (let lineId = 1; lineId <= 5; lineId++) {
    if (lineId !== currentLine) {
      const lineData = getHashData(`lines.${lineId}`) || {};
      const { planPerkIds, planPerkVariants } = lineData;
      const foundPerkId = find(planPerkIds || [], (perkId) => perkId === perk.id);
      const foundVariantId = (planPerkVariants || {})[perk.id];
      if (
        !alreadyShown &&
        (
          (
            variant &&
            (
              (variant.doubleAddConflictName && foundVariantId === variantId) ||
              (variant.doubleAddConflictWithVariantId === foundVariantId)
            )
          ) || (!variant && foundPerkId)
        )
      ) {
        found = true;
      }
    }
  }
  return { found, perk, variant };
};

const selectedItems = (perks, variantsHash = {}) => {
  const items = [];
  each(perks, (perk) => {
    if (perk.price) {
      items.push(perk);
    } else if (perk.variants) {
      const foundVariant = find(perk.variants, (variant) => (
        variantsHash[perk.id] ? variant.id === variantsHash[perk.id] : variant.default
      ));
      items.push(foundVariant);
    }
  });
  return items;
};

const calculatePerksSum = (planPrice, perks, variantsHash = {}) => (
  parseFloat(
    (
      (planPrice || 0) +
      (sum(map(selectedItems(perks, variantsHash), (item) => item.price)) || 0)
    ).toFixed(2)
  ) || 0
);

const calculatePerksSavings = (perks, variantsHash = {}) => (
  parseFloat(
    sum(
      map(selectedItems(perks, variantsHash), (item) => item.save)
    ).toFixed(2)
  ) || 0
);

const planIsCore = (plan, coreList) => (
  find(coreList, (item) => item.id === plan?.id)
);

const getDefaultVariant = (perk) => (
  perk.variants ? find(perk.variants, (item) => item.default) : null
);

const allVariantsPriceRange = (perk) => (
  [
    minBy(perk.variants || [], (item) => item.price)?.price || 0,
    maxBy(perk.variants || [], (item) => item.price)?.price || 0
  ]
);

const allVariantsMaxSum = (perk) => (
  parseFloat(
    (maxBy(perk.variants || [], (item) => item.save)?.save || 0).toFixed(2)
  )
);

export {
  prepareText, capitalize,
  perkImageUrl, scrollTop, scrollInModal,
  fixedView, sumAndFormat, sumAndFormatCheckFractional,
  openCoreModal, openTermsModal, openConflictModal,
  findConflicted, findPerkVariantInTheOtherLine, getDefaultVariant,
  calculatePerksSum, calculatePerksSavings, planIsCore,
  allVariantsPriceRange, allVariantsMaxSum
};
