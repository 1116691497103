import React, { useState } from 'react';
import classnames from "classnames";
import { map, isArray } from 'lodash';
import { generateId } from "../../helpers";

const RadioSet = ({
  id, error, label,
  disabled, value, onChange, fields,
  className, classNameLabel
}) => {
  const [ identifier ] = useState(id || `survey_${generateId()}`);
  const nameIdentifier = (name) => (`${identifier}-${name}`);

  const fieldsArray = isArray(fields) ? map(fields, (el) => ([ el, el ])) : map(fields, (l, n) => ([ l, n ]));

  return (
    <div className="form-group -radio-set">
      <h4>{ label }</h4>
      <div className="radio-set-body">
        {
          map(fieldsArray, (ln) => {
            const label = ln[0];
            const name = ln[1];
            return (
              <div className="form-group -radio" key={ nameIdentifier(name) }>
                {
                  !!label &&
                  <label
                    className={
                      classnames(
                        "form-group_label -radio",
                        { '-upper': !classNameLabel },
                        { [classNameLabel]: !!classNameLabel }
                      )
                    }
                    htmlFor={ nameIdentifier(name) }
                  >
                    { label }
                  </label>
                }
                <div className="verizon-radio table_control-over-line">
                  <input
                    id={ nameIdentifier(name) }
                    type="radio"
                    name={ name }
                    checked={ name === value }
                    disabled={ disabled }
                    onChange={ () => {onChange(name);} }
                  />
                  <label
                    htmlFor={ nameIdentifier(name) }
                    className={ classnames("radio", className) }
                  />
                </div>
              </div>
            );
          })
        }
        { error && <p className="field-error">{ error }</p> }
      </div>
    </div>
  );
};

export default RadioSet;
