import React, { useState } from 'react';
import { compact, fill, map } from "lodash";
import Modal from "../common/modal";
import Perk from "./perk";


const PerksModal = ({ close, perks, planPerkCount, reselectPerks, planName }) => {
  const perkSelection = () => {
    reselectPerks(selectedPerks);
    close();
  };

  const [ selectedPerks, setSelectedPerks ] = useState(planPerkCount ? fill(Array(planPerkCount), null) : []);

  const onPerkSelect = (perkId, mode) => {
    if (mode === "change") {
      setSelectedPerks([ perkId ]);
    } else if (mode === "select") {
      const indexOfNull = selectedPerks.indexOf(null);
      if (indexOfNull !== -1) {
        setSelectedPerks((oldList) => {
          oldList[indexOfNull] = perkId;
          return [ ...oldList ];
        });
      }
    } else if (mode === "deselect") {
      setSelectedPerks((oldList) => {
        const index = oldList.indexOf(perkId);
        if (index !== -1) {
          oldList[index] = null;
        }
        return [ ...oldList ];
      });
    }
  };


  return (
    <Modal handleClose={ close }>
      <div className="perks-modal">
        <h2 className="perks-modal_title">Available perks</h2>
        <div className="perks-modal_descr">{planPerkCount} perk{planPerkCount > 0 ? 's' : ''} are included on us in {planName}.</div>
        <div className="perks-modal_list">
          <div className={ `overview_perks` } >
            {
              map(perks, (perk) => (
                <Perk
                  key={ `perkItem${perk.id}` }
                  perk={ perk }
                  onPerkSelect={ onPerkSelect }
                  classNameWrapper="overview_perks-item"
                  availablePerksCount={ planPerkCount }
                  selectedPerks={ selectedPerks }
                />
              ))
            }
          </div>
        </div>
        <div className="perks-modal_button">
          <button
            className="button"
            disabled={ compact(selectedPerks).length < planPerkCount }
            onClick={ (event) => { perkSelection(event); } }
          >
            Confirm perk selection
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PerksModal;


