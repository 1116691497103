import React, { Fragment, useContext } from 'react';
import { filter, includes, map } from 'lodash';
import cn from "classnames";
import { getMetricKind, IndexData } from "../../index-context";
import StatusBar from "../../components/status-bar";
import StatusCircle from "../../components/status-circle";
import { hideCustom, showedAverageValue, columnsOrder, columnsOrderChanged } from '../../helpers';

const TableCustomMetrics = ({
  data, current, customMetrics, groupName,
  className, withN, withBar = false
}) => {
  const { averageMode, init, reportView } = useContext(IndexData);
  const boxes = { "top2": "T2B", "top": "TB" };
  const top3Boxes = { "top2": "T3B", "top": "TB" };
  let itemName = '';
  const nText = withN ? `, N=${current.custom_metrics_count}` : '';

  const fivePointScaleMetric = (values) => (values.five_point_metric === true);
  const filteredCustomMetrics = () => {
    return filter(customMetrics, (metric) => {
      return ((includes(metric.title, 'QFF') || metric.five_point_metric === true) ||
        (metric.five_point_metric !== true && !metric.comparison_skip));
    });
  };

  const renderFivePointScaleMetric = (values) => (
    map(values.top3_mode ? top3Boxes : boxes, (text, box) => (
      <Fragment key={ box }>
        {
          gridMetric(values.sub_kind) && box !== "top" &&
          <tr>
            <td className="table-comparison_name-cell">
              { values.option_text }
            </td>
            <td />
            { withBar && <td className="-max-width" /> }
            <td />
            <td className="table-comparison_average-cell" />
          </tr>
        }
        <tr>
          <td className="table-comparison_name-cell">
            { text }
          </td>
          <td />
          {
            withBar &&
            <td className="-max-width">
              <StatusBar
                values={ values[current.id] }
                box={ box }
                statTesting={ data.stat_testing }
              />
            </td>
          }
          <td>
            <StatusCircle
              values={ values[current.id] }
              box={ box }
              noLetters
              hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
            />
          </td>
          <td className="table-comparison_average-cell">
            {showedAverageValue(values, averageMode, false, init.isInternal, data.view, box)}
          </td>
        </tr>
      </Fragment>
    ))
  );

  const renderCustomMetric = (values) => (
    <tr>
      <td className="table-comparison_name-cell">
        { values.option_text }
        {
          !!values.image_url &&
          <div className="concept-report_header-item concept-preview">
            <div
              className="concept-preview_thumb"
              style={ { backgroundImage: `url(${values.image_url})` } }
            />
          </div>
        }
      </td>
      <td />
      {
        withBar &&
        <td className="-max-width">
          <StatusBar
            values={ values[current.id] }
            box="top2"
            statTesting={ data.stat_testing }
          />
        </td>
      }
      <td>
        <StatusCircle
          values={ values[current.id] }
          box="top2"
          noLetters
          hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
        />
      </td>
      <td className="table-comparison_average-cell">
        {showedAverageValue(values, averageMode, false, init.isInternal, data.view)}
      </td>
    </tr>
  );

  const gridMetric = (kind) => (kind === 'grid');

  const renderCustomGridMetric = (values) => (
    <>
      <tr>
        <td className="table-comparison_name-cell">
          { values.option_text }
        </td>
        <td />
        { withBar && <td className="-max-width" /> }
        <td />
        <td className="table-comparison_average-cell" />
      </tr>
      {
        map(values.columns, (column, colIndex) => (
          <tr key={ `col-${column}` }>
            <td className="table-comparison_name-cell">
              { column }
            </td>
            <td />
            {
              withBar &&
              <td>
                <StatusBar
                  values={ values[current.id][colIndex + 1] }
                  box="top2"
                  noLetters
                  hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                />
              </td>
            }
            <td>
              <StatusCircle
                values={ values[current.id][colIndex + 1] }
                box="top2"
                noLetters
                hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
              />
            </td>
            <td className="table-comparison_average-cell">
              {showedAverageValue(values.avg[colIndex + 1], averageMode, false, init.isInternal, data.view)}
            </td>
          </tr>
        ))
      }
    </>
  );

  const renderChangedCustomGridMetric = (values) => {
    return (
      <>
        <tr>
          <td className="table-comparison_name-cell">
            { values.option_text }
          </td>
          <td />
          { withBar && <td className="-max-width" /> }
          <td />
          <td className="table-comparison_average-cell" />
        </tr>
        {
          map(columnsOrder(reportView, values.columns_keys, values.id), (colIndex) => (
            <tr key={ `col-${values.columns[colIndex - 1]}` }>
              <td className="table-comparison_name-cell">
                { values.columns[colIndex - 1] }
              </td>
              <td />
              {
                withBar &&
                  <td>
                    <StatusBar
                      values={ values[current.id][colIndex] }
                      box="top2"
                      noLetters
                      hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                    />
                  </td>
              }
              <td>
                <StatusCircle
                  values={ values[current.id][colIndex] }
                  box="top2"
                  noLetters
                  hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                />
              </td>
              <td className="table-comparison_average-cell">
                {showedAverageValue(values.avg[colIndex], averageMode, false, init.isInternal, data.view)}
              </td>
            </tr>
          ))
        }
      </>
    );
  };

  const metricRenderFunction = (values, title) => {
    if (fivePointScaleMetric(values)) {
      return renderFivePointScaleMetric(values, title);
    } else if (gridMetric(values.sub_kind)) {
      if (columnsOrderChanged(reportView, values.id)) {
        return renderChangedCustomGridMetric(values, title);
      }
      return renderCustomGridMetric(values, title);
    }

    return renderCustomMetric(values, title);
  };

  return (
    <table className={ cn("table table-comparison", className) }>
      <thead>
        <tr>
          <th
            className="table-comparison_name-cell"
            colSpan={ withBar ? 4 : 3 }
          >
            <div className="table-comparison_title">
              { getMetricKind(data, groupName) }{ nText }
            </div>
          </th>
          <th className="table-comparison_average-cell">
            Avg
          </th>
        </tr>
      </thead>
      <tbody>
        {
          map(filteredCustomMetrics(), (values, cMIndex) => {
            const title = values.title;
            const changedName = itemName !== values.name;
            itemName = values.name;
            return (
              <Fragment key={ `scorecard_five_${title}_${cMIndex}` }>
                {
                  changedName &&
                  <tr>
                    <td className="table-comparison_name-cell">
                      { reportView?.customMetrics?.[values.id]?.custom_title || values.title }
                    </td>
                    <td />
                    { withBar && <td className="-max-width" /> }
                    <td />
                    <td className="table-comparison_average-cell" />
                  </tr>
                }
                { metricRenderFunction(values, title) }
              </Fragment>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default TableCustomMetrics;
