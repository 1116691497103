import React, { useState, useCallback } from "react";
import classnames from 'classnames';
import { map } from 'lodash';
import { Header } from './header';

const MIN = 1;
const MAX = 5;

export const Lines = ({ nextStep, customTitle, customButtonName }) => {
  const [ line, setLine ] = useState(1);

  const checkMin = useCallback((value) => MIN > value, []);

  const checkMax = useCallback((value) => MAX < value, []);

  const getValue = useCallback((value) => {
    if (checkMin(value)) {
      return MIN;
    }
    if (checkMax(value)) {
      return MAX;
    }
    return value;
  }, [ checkMax, checkMin ]);

  const update = (count) => () => {
    const next = getValue(line + count);
    setLine(next);
  };

  return (
    <div className="content">
      <Header />
      <div>
        <div className="inner-content -base -column -center -max-w-370">
          <h2 className="lines_question -plans-builder">
            { !!customTitle && customTitle }
            { !customTitle && <>How many phone lines would&nbsp;you&nbsp;like?</> }
          </h2>
          <div className="counter-button-wrapper">
            <button className="counter-button -minus" disabled={ checkMin(line - 1) } onClick={ update(-1) } />
            <span className="counter-button-value">{ line }</span>
            <button className="counter-button -plus" disabled={ checkMax(line + 1) } onClick={ update(1) } />
          </div>
          <div className="start_buttons">
            <button className="button -start -full-width" onClick={ (event) => { nextStep(event, line); } }>
              { !!customButtonName && customButtonName }
              { !customButtonName && <>Build your plans</> }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DefaultLines = ({ nextStep }) => {
  const [ line, updateLine ] = useState(null);
  const selectLine = (line) => {
    updateLine(line);
  };

  return (
    <div className="content">
      <Header />
      <div>
        <div className="inner-content -base">
          <h2 className="lines_question">
            How many lines do you need?
          </h2>
          <div className="text-20 lines_note">
            You’ll need a line per device.
          </div>
          <div className="lines_start-counts -billing">
            {
              map([ 1, 2, 3, 4, 5 ], (currentLine) => {
                return (
                  <h2
                    key={ `line${currentLine}` }
                    className={ classnames('lines_start-counts-item', { '-selected': line === currentLine }) }
                    onClick={ () => { selectLine(currentLine); } }
                  >
                    { currentLine }
                  </h2>
                );
              })
            }
          </div>
          <div className="start_buttons">
            <button className="button -start" disabled={ !line } onClick={ (event) => { nextStep(event, line); } }>Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLines;
