import React, { Fragment } from 'react';
import { cloneDeep, each, filter, find, findIndex, map, remove } from 'lodash';
import {
  ageBreaksOptionRegEx,
  atLeastOptionRegEx,
  anyLogicOperatorRegEx,
  operatorNotRegEx,
  operatorLogicRegEx,
  selectAtLeastOption,
  selectAgeBreaksOption,
  logicNotOperatorRegEx, logicOperatorRegEx, atLeastRegEx,
  ageBreaksRegEx, defaultLogicOperator
} from '../../helpers/filters_helper';
import AtLeast from './at_least';
import AgeBreaks from './age_breaks';
import Tags from './tags';
import ResultsPortal from './portal';

const Results = ({ resultsId, selectedList, optionsList, onUpdate }) => {
  const removeAllLogicOperators = (event, item) => {
    remove(selectedList, (el) => el.match(anyLogicOperatorRegEx(item.name)));
  };
  const removeLogicOperator = (event, item) => {
    remove(selectedList, (el) => el.match(operatorLogicRegEx(item.name)));
  };

  const removeOption = (event, item, answer) => {
    remove(selectedList, (el) => el == answer.inFilter);
    if (item.answers.length === 1) {
      removeLogicOperator(event, item);
    }
    onUpdate(selectedList);
  };

  const selectNotOperator = (event, item, operator) => {
    if (!operator) {
      remove(selectedList, (el) => el.match(operatorNotRegEx(item.name)));
    } else {
      const index = findIndex(
        selectedList,
        (el) => el.match(operatorNotRegEx(item.name))
      );
      const newValue = `${item.name}=logic:${operator}`;
      if (index !== -1) {
        selectedList[index] = newValue;
      } else {
        selectedList.push(newValue);
      }
    }
    onUpdate(selectedList);
  };

  const selectLogicOperator = (event, item, operator) => {
    const index = findIndex(selectedList, (el) => el.match(operatorLogicRegEx(item.name))),
      newValue = `${item.name}=logic:${operator}`;

    if (index !== -1) {
      selectedList[index] = newValue;
    } else {
      selectedList.push(newValue);
    }

    onUpdate(selectedList);
  };

  const onAtLeastChange = (event, item, newNumber) => {
    const index = findIndex(
      selectedList,
      (el) => el.match(atLeastOptionRegEx(item.name))
    );
    selectedList[index] = selectAtLeastOption(item.name, newNumber);
    onUpdate(selectedList);
  };

  const removeAtLeastSection = (event, item) => {
    remove(selectedList, (el) => el.match(atLeastOptionRegEx(item.name)));
    onUpdate(selectedList);
  };

  const onAgeBreaksChange = (item, leftBorder, rightBorder) => {
    const index = findIndex(selectedList, (el) => el.match(ageBreaksOptionRegEx(item.name)));
    selectedList[index] = selectAgeBreaksOption(item.name, leftBorder, rightBorder);
    onUpdate(selectedList);
  };

  const removeSection = (event, item) => {
    removeAllLogicOperators(event, item);
    each(item.answers, (answer) => {
      remove(selectedList, (el) => el === answer.inFilter);
    });
    onUpdate(selectedList);
  };

  const translateSelectedOptions = (selected, options) => {
    const selectedResults = [];
    const toRemove = [];
    const logicOperators = {};
    const logicNotOperators = {};

    each(filter(selected, (el) => el.match(logicNotOperatorRegEx)), (el) => {
      const matchedResult = el.match(logicNotOperatorRegEx);
      logicNotOperators[matchedResult[1]] = true;
    });

    each(filter(selected, (el) => el.match(logicOperatorRegEx)), (el) => {
      const matchedResult = el.match(logicOperatorRegEx);
      logicOperators[matchedResult[1]] = matchedResult[2];
    });

    each(
      filter(selected, (el) => (
        !el.match(logicOperatorRegEx) && !el.match(logicNotOperatorRegEx)
      )),
      (el) => {
        let [ name, option ] = el.split(/=/);
        const atLeastResult = el.match(atLeastRegEx);
        const ageBreaksResult = el.match(ageBreaksRegEx);

        let kind = "default";
        if (atLeastResult) {
          kind = atLeastResult[2];
        } else if (ageBreaksResult) {
          kind = "age_breaks";
        }

        if (atLeastResult) {
          option = atLeastResult[2];
        } else if (ageBreaksResult) {
          const itemAge = find(options, (item) => !!item.age_breaks);
          name = itemAge ? itemAge.name : "";
          option = "age_breaks";
        }

        const optionsItem = find(options, (item) => {
          return item.name === name && !!find(item.answers, (answer) => answer.precode === option);
        });

        if (optionsItem) {
          let selectedItem = find(selectedResults, (item) => item.name === name && item.kind === kind);

          if (!selectedItem) {
            selectedItem = { name, kind, title: optionsItem.title, answers: [] };
            selectedResults.push(selectedItem);
          }
          selectedItem.logicOperator = (logicOperators[name] || defaultLogicOperator);
          selectedItem.logicNotOperator = logicNotOperators[name];
          if (atLeastResult) {
            selectedItem.atLeastTitle = optionsItem.at_least_title;
            selectedItem.atLeastMax = optionsItem.at_least_max;
            selectedItem.atLeastSelected = atLeastResult[3];
          } else if (ageBreaksResult) {
            selectedItem.ageRanges = optionsItem.age_breaks;
            selectedItem.leftBorder = ageBreaksResult[2];
            selectedItem.rightBorder = ageBreaksResult[3];
          }
          const selectedAnswer = cloneDeep(find(optionsItem.answers, (answer) => answer.precode === option));
          selectedAnswer.inFilter = el;
          selectedItem.answers.push(selectedAnswer);
        } else {
          // Remove element itself and all logic operators
          each(
            filter(selected, (el) => el.match(anyLogicOperatorRegEx(name))),
            (item) => {
              toRemove.push(item);
            }
          );
          toRemove.push(el);
        }
      }
    );

    each(toRemove, (removeItem) => {
      remove(selected, (item) => item == removeItem);
    });

    return selectedResults;
  };

  const translatedAnswers = translateSelectedOptions(selectedList, optionsList);

  return (
    <ResultsPortal resultsId={ resultsId }>
      <>
        {
          map(translatedAnswers, (item, index) => {
            const special = item.kind === 'at_least' || item.kind === 'age_breaks';
            return (
              <Fragment key={ `selectedOptionsIndex${index}` }>
                {
                  item.kind === 'at_least' &&
                  <AtLeast
                    index={ index }
                    title={ item.atLeastTitle }
                    minNumber={ 1 }
                    maxNumber={ item.atLeastMax }
                    selectedNumber={ item.atLeastSelected }
                    removeSection={ (event) => {
                      removeAtLeastSection(event, item);
                    } }
                    onChange={ (event, newNumber) =>
                      onAtLeastChange(event, item, newNumber)
                    }
                  />
                }
                {
                  item.kind === 'age_breaks' &&
                  <AgeBreaks
                    ageRanges={ item.ageRanges }
                    leftBorder={ item.leftBorder }
                    rightBorder={ item.rightBorder }
                    onChange={ (leftBorder, rightBorder) => onAgeBreaksChange(item, leftBorder, rightBorder) }
                    item={ item }
                    selectNotOperator={ (event, operator) => { selectNotOperator(event, item, operator); } }
                    removeSection={ (event) => { removeSection(event, item); } }
                  />
                }
                {
                  !special &&
                  <Tags
                    item={ item }
                    index={ index }
                    removeOption={ (event, answer) => { removeOption(event, item, answer); } }
                    selectNotOperator={ (event, operator) => { selectNotOperator(event, item, operator); } }
                    selectOperator={ (event, operator) => { selectLogicOperator(event, item, operator); } }
                    removeSection={ (event) => { removeSection(event, item); } }
                  />
                }
              </Fragment>
            );
          })
        }
      </>
    </ResultsPortal>
  );
};

export default Results;
