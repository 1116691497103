const splitByLast = (s, separator) => {
  const lastIndex = s.lastIndexOf(separator);
  return [ s.substring(0, lastIndex), s.substring(lastIndex + 1) ];
};

let plainTextDiv;
const plainText = (html) => {
  if (!html) {
    return '';
  }
  let result;
  try {
    plainTextDiv = document.createElement("div");
    plainTextDiv.innerHTML = html;
    result = plainTextDiv.innerText;
  } catch (e) {
    result = html.replace(/(<([^>]+)>)/gi, "").replace(/[\n\r\t]/, "");
  }
  return result
    .replace(/^\s+/, '')
    .replace(/\s+$/, '');
};

export { splitByLast, plainText };
