import equinoxHash from "./equinox_hash";
import expressHowItWorksHash from './express/how_it_works_hash';
import expressPackagemcHash from './express/packagemc_hash';
import verizonTvHash from './verizon/tv_hash';
import visibleComHash from './visible_com_hash';
import expressStepsHash from './express/steps_hash';
import expressLandingHash from './express/landing_hash';
import expressPackagemcQuestionsHash from './express/packagemc_questions_hash';
import expressRespondentHash from './express/respondent_hash';
const s3Url = "https://procanvas-production.s3.amazonaws.com";
const cdnUrl = "https://d2d755s474y4ld.cloudfront.net";
const defaultDir = "procanvas_default_assets";
const expressDir = "express";
const verizonDir = "stimuli";

const url = (s3Only) => ([ (s3Only ? s3Url : cdnUrl), defaultDir ].join('/'));
const expressUrl = (s3Url) => ([ url(s3Url), expressDir ].join("/"));
const verizonUrl = (s3Url) => ([ url(s3Url), verizonDir ].join("/"));

const remoteFiles = {
  equinox: equinoxHash(url(false)),
  visibleCom: visibleComHash(url(false)),
  express: {
    howItWorks: expressHowItWorksHash(expressUrl(false)),
    packageMc: expressPackagemcHash(expressUrl(false)),
    packageMcQuestions: expressPackagemcQuestionsHash(expressUrl(false)),
    steps: expressStepsHash(expressUrl(false)),
    landing: expressLandingHash(expressUrl(false)),
    respondent: expressRespondentHash(expressUrl(false))
  },
  verizon: {
    tv: verizonTvHash(verizonUrl(false))
  }
};

export default remoteFiles;


