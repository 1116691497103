import React, { useContext } from 'react';
import { LineDataContext } from '../contexts';
import UnlimitedPart from './components/unlimited_part';
import SharedPart from './components/shared_part';
import PrepaidPart from "./components/prepaid_part";

const Landing = ({ excludeShared, nextStep }) => {
  const { unlimited, shared, prepaid } = useContext(LineDataContext);
  return(
    <div className="content -split-concept">
      <div className="text-16 -concept-a top-message">
        Please scroll down to view all plan options before making your selection. Select the “Get Started” button above each plan type to learn more about the plan and make your selections. As you review, you can use the “See our other plans” button to review another plan type in detail, if needed.
      </div>
      <div className="header"></div>
      <div>
        <div className="inner-content -landing">
          <UnlimitedPart
            allList={ unlimited.allPlansInclude }
            list={ unlimited.unlimitedPlans }
            onClick={ (event) => { nextStep(event, 'unlimited') }}
          />
          {
            !excludeShared &&
            <SharedPart list={ shared.sharedPlans } onClick={ (event) => { nextStep(event, 'shared') }} />
          }
          <PrepaidPart
            allList={ prepaid.allPlansInclude }
            list={ prepaid.prepaidPlans }
            onClick={ (event) => { nextStep(event, 'prepaid') }}
          />
        </div>
      </div>
    </div>
  )
};

export default Landing;