import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const ClaimsLegend = ({ q, singular, plural }) => {
  return (
    <div className="comparisons_legend -claims">
      <div className="comparisons_legend-text">
        <p>
          <SvgIcon className="comparisons_legend-arrow-top" name="arrow-top" />indicates {singular} score is significantly
          higher than the next consecutive Net Score at 95% confidence.
        </p>
        <p> Question text: "{ q ? q.replace(/.$/, '?') : '' }"</p>
      </div>

      <div className="comparisons-maxdiff-description">
        <p>
          MaxDiff is an advanced analytic technique designed to obtain preference/importance scores (utilities) for
          multiple options of a single item (such as product features, {plural}, etc.). In MaxDiff, respondents are shown a
          set (or subset) of the options, and are then asked to indicate which one is "best" and which one is "worst".
          MaxDiff uses the respondent's choices to estimate scores for each of the options.
        </p>

        <p>
          MaxDiff is used as an alternative to rating scales, and assumes that respondents are better at making choices
          than rating options along a scale. Since respondents are asked to choose rather than express strength of
          preference, scale bias is also eliminated. It is also referred to as Best-Worst Scaling.
        </p>
      </div>
    </div>
  );
};

export default ClaimsLegend;
