import React, { useState, useContext } from 'react';
import cn from "classnames";
import SvgIcon from '../../../../common/components/svg-icon';
import { DataContext } from "../withData";
import EditorCell1 from "./EditorCell1";
import EditorCell2 from "./EditorCell2";
import EditorCell3 from "./EditorCell3";

const Level2Line = ({ modalInfo, tagObj, className, tagType }) => {
  const { deleteTag, updateTag } = useContext(DataContext);
  const [ saving, setSaving ] = useState(false);
  const [ editing, setEditing ] = useState(false);
  const [ tempText, setTempText ] = useState(tagObj.label);

  const handleDelete = () => {
    if (!window.confirm(`Do you really want to delete "${tagObj.tag}" tag?`)) {
      return;
    }
    setSaving(true);
    deleteTag(tagType, tagObj.value, () => {});
  };

  const cancelEditing = () => {
    setEditing(false);
  };

  const handleSave = () => {
    setSaving(true);
    updateTag(tagType, tagObj.value, tempText, () => {
      setSaving(false);
      setEditing(false);
    });
  };

  const enableEditing = () => {
    setEditing(true);
    setTempText(tagObj.label);
  };

  return (
    <div className={ cn("form_grid -v-middle -js-level2", className) }>
      <div className="form_grid-item -grow">
        {!editing && <SvgIcon name="i-tag" />}
        <EditorCell1
          value={ tagObj.label }
          editing={ editing }
          saving={ saving }
          tempText={ tempText }
          setTempText={ setTempText }
          className="-margin-left"
          handleSave={ handleSave }
        />
      </div>
      <div className="form_grid-item">
        <EditorCell2
          modalInfo={ modalInfo }
          tagId={ tagObj.value }
          count={ tagObj.count }
          editing={ editing }
          saving={ saving }
          cancelEditing={ cancelEditing }
        />
      </div>
      <div className="form_grid-item">
        <EditorCell3
          editing={ editing }
          enableEditing={ enableEditing }
          tempText={ tempText }
          count={ tagObj.count }
          saving={ saving }
          handleDelete={ handleDelete }
          handleSave={ handleSave }
        />
      </div>
    </div>
  );
};

export default Level2Line;
