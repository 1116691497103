import React, { useContext } from 'react';
import { IndexData } from '../index-context';
import SvgIcon from "../../../../common/components/svg-icon";
import {comparisonExportConceptsForAverage} from "../../../common/google-analytics/events";

const AverageModeSelector = ({ showLabel }) => {
  const { averageMode, statTestingVal, exportReport, init, data } = useContext(IndexData);

  const showReportIcon = init.isInternal && !data.show_filter_message &&
    (statTestingVal === 'quintiles' || statTestingVal === 'average' && averageMode !== 'project');

  const averageTitle = () => {
    switch (averageMode) {
      case "platform":
        return "Category\n Average";
      case "client":
        return "Client\n Average";
      default:
        return "Project\n Average";
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    comparisonExportConceptsForAverage(init.google_analytics_id, { project_id: init.project.id });
    exportReport(event, null, null, { name: 'concepts_for_average' }, 'xlsx', {}, true);
  };

  return (
    <>
      { showLabel && averageTitle() }
      { showReportIcon &&
        <div className="-padding-top">
          <div className="info-tooltip">
            <SvgIcon
              name="i-cloud-download"
              className="info-tooltip_control -no-bg -link"
              onClick={ handleClick }
            />
            <div className="info-tooltip_drop -left">
              <div className="info-tooltip_drop-inner -tiny">
                Download concepts for average
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

export default AverageModeSelector;
