import React, { useContext } from 'react';
import NativeModal from "../components/nativeModal";
import { GlobalContext } from "../contexts/appContexts";

const SavePasswordNotification = ({ nextStep }) => {
  const { mergeData } = useContext(GlobalContext);
  const handleNotAllow = () => {
    mergeData({ passwordSaveAllow: false });
    nextStep();
  };

  const handleOk = () => {
    mergeData({ passwordSaveAllow: true });
    nextStep();
  };

  const nativeModalButtons = (
    <>
      <button className="button-native -bold" onClick={ () => { handleNotAllow(); } }>
        Don't Allow
      </button>
      <button className="button-native" onClick={ () => { handleOk(); } }>
        Save Password
      </button>
    </>
  );

  return (
    <div className="native-notification-background">
      <NativeModal buttonsAsRows buttons={ nativeModalButtons }>
        <>
          <h2>
            Would you like to save this password in your iCloud Keychain to use with
            { ' ' }
            apps and websites on all your devices?
          </h2>
          <p>
            You can view and remove saved passwords in Settings { ">" } Passwords.
          </p>
        </>
      </NativeModal>
    </div>
  );
};

export default SavePasswordNotification;


