import React, { useState, useContext } from 'react';
import { map, remove, each, filter, concat, maxBy } from 'lodash';
import classnames from 'classnames';
import Modal from '../../../common/components/modal';
import { invalidAgesList, invalidQuotas, totalQuotaSum } from './logic/quotas_validation';
import { calculateQuotasForList } from './logic/calculate_quotas';
import QuotaModeSwitcher from './quotaMode/components/quotaModeSwitcher';
import { QuotaModeLocalContext } from './quotaMode/contexts';

const EditQuota = ({
  question, showCross, linkTitle, title, editable, onUpdate,
  createList, convertOnSave, platformName
}) => {
  const [ list, updateList ] = useState([]);
  const [ showForm, updateShowForm ] = useState(false);
  const [ errors, updateErrors ] = useState([]);
  const { forceChangeGlobalValueToLocal } = useContext(QuotaModeLocalContext);

  const filteredList = (newList) => {
    return filter(newList || list, (item) => !item.disabled);
  };

  const error = (errors || [])[0];

  const updateListIds = (newList) => {
    each(
      newList || list,
      (item, index) => {
        item.id = index;
      }
    );
  };

  const openModal = (event) => {
    event.preventDefault();

    const newList = createList();
    const enabledList = filteredList(newList);

    if (!filter(enabledList, (item) => item.selected).length) {
      enabledList[0].selected = true;
      if (question.manage_quota) {
        calculateQuotasForList(enabledList);
      }
    }
    updateListIds(newList);
    updateList(newList);
    updateShowForm(true);
  };

  const onClose = (event) => {
    event.preventDefault();
    updateShowForm(false);
    updateErrors([]);
  };

  const valid = () => {
    const enabledList = filteredList(),
      ageMessages = question.is_age ? invalidAgesList(question, enabledList) : null;
    let quotaMessages = null;

    if (question.manage_quota && !ageMessages) {
      quotaMessages = invalidQuotas(question, enabledList);
    }

    if (!!ageMessages || !!quotaMessages) {
      updateErrors((ageMessages || []).concat(quotaMessages || []));
    } else {
      updateErrors([]);
    }
    return !ageMessages && !quotaMessages;
  };

  const onSave = (event) => {
    event.preventDefault();
    if (valid()) {
      forceChangeGlobalValueToLocal();
      convertOnSave(list);
      onUpdate(event, question);
      updateShowForm(false);
    }
  };

  const toggleSelection = (item) => {
    item.selected = !item.selected;
    if (item.quota === undefined) {
      item.quota = '';
    }
    if (question.manage_quota && question.kind !== "Multi") {
      calculateQuotasForList(filteredList());
    }
    updateErrors([]);
  };

  const changeText = (event, item, field) => {
    item[field] = event.target.value;
    updateErrors([]);
  };

  const removeItem = (event, item) => {
    event.preventDefault();
    remove(list, item);
    updateListIds(list);
    if (question.manage_quota && item.selected) {
      calculateQuotasForList(filteredList(list));
    }
    updateList(concat([], list));
  };

  const addItemAfter = (event, item) => {
    event.preventDefault();
    const index = filteredList().indexOf(item) + 1;
    list.splice(index, 0, {
      answer: null,
      selected: false,
      quota: null
    });
    updateListIds(list);
    updateList(concat([], list));
  };

  const renderListItem = (item, index) => {
    return (
      <div key={ `listItem-${index}` } className="question-list-item">
        <div className="question-list-checkbox -list-right-margin checkbox">
          <input
            type="checkbox"
            id={ `checkbox-${index}` }
            checked={ item.selected }
            onChange={ () => {} }
          />
          <label
            className="checkbox_label -for-non-answer"
            onClick={ () => { toggleSelection(item); } }
          />
        </div>
        {
          editable ?
            <input
              type="text"
              className={
                classnames(
                  'question-list-input form-field -list-right-margin -text',
                  { '-readonly': !editable, 'has-error': item.highlightRange }
                )
              }
              value={ item.answer || '' }
              onChange={ (event) => { changeText(event, item, 'answer'); } }
              disabled={ !editable }
            />
            :
            <span className="readonly-title">{ item.answer || '' }</span>
        }
        {
          question.manage_quota &&
          <input
            type="text"
            className={
              classnames(
                'question-list-input form-field -list-right-margin -quota',
                { 'has-error': item.highlightQuota }
              )
            }
            value={ item.selected ? (item.quota || "") : "" }
            onChange={ (event) => { changeText(event, item, 'quota'); } }
            disabled={ !item.selected }
          />
        }
        {
          editable &&
          <div className="form-question_grid-item -buttons quota-buttons">
            <button
              className="button -secondary -circle -delete-color form-question_grid-button -dark-grey -list-right-margin"
              onClick={ (event) => { removeItem(event, item); } }
              disabled={ list.length <= 1 }
            >
              <svg className="icon -i-delete">
                <use xlinkHref="#svg_i-delete" />
              </svg>
            </button>
            <button
              className="button -circle form-question_grid-button"
              onClick={ (event) => { addItemAfter(event, item); } }
            >
              <svg className="icon -i-add">
                <use xlinkHref="#svg_i-add" />
              </svg>
            </button>
          </div>
        }
      </div>
    );
  };

  const maxByAnswerLength = (
    (
      maxBy(
        filteredList() || [],
        (item) => (
          (item.answer || "").length
        )
      ) || {}
    ).answer || {}
  ).length || 0;

  const isMulti = question.kind === 'Multi';

  return (
    <>
      <a className="quotas" href="#" onClick={ (event) => openModal(event) }>{ linkTitle }</a>
      {
        showForm &&
        <Modal
          className={ classnames("-quota", { "-long-items": maxByAnswerLength >= 20 }, { "-editable": editable }) }
          dialogClassName={`-fit-window -min-width ${platformName ? platformName + '-modifier':''}`}
          backDropModifier={platformName ? platformName + '-modifier':''}
          showCross={ showCross }
          onClose={ (event) => { onClose(event); } }
        >
          <>
            <h2 className="normal-popup-title">
              { question.uiTitle() } &rarr; { title }
            </h2>
            {
              !question.manage_quota && !error &&
              <div className="normal-popop-description">
                Here you can update question's options.
              </div>
            }
            {
              question.manage_quota && !error &&
              <div className="normal-popop-description">
                {
                  question.kind !== 'Multi' &&
                  <>
                    Please note your percent cannot be
                    { ' ' }
                    set to 0% and the sum of all options should equal 100%.
                  </>
                }
                {
                  isMulti &&
                  <>
                    Please note the percent of each option cannot be
                    { ' ' }
                    set to 0% or be greater than 100%.
                  </>
                }
              </div>
            }
            {
              error &&
              <div className="quota-error-message">
                <div>{ error }</div>
              </div>
            }
            { question.manage_quota && <QuotaModeSwitcher /> }
            <div className="question-headers -with-401-width">
              <h3 className="header-item">{ question.uiTitle() }</h3>
              {
                question.manage_quota &&
                <h3 className="header-item">
                  Quotas
                  {
                    !isMulti &&
                    <>
                      {' '}
                      , % (Total: { totalQuotaSum(list) }%)
                    </>
                  }
                </h3>
              }
            </div>
            <div className="question-list -with-401-width">
              { map(filteredList(), (item, index) => renderListItem(item, index)) }
            </div>
            <div className="normal-popup-buttons">
              <button
                className="button"
                type="button"
                onClick={ (event) => { onSave(event); } }
              >
                Save
              </button>
              <button
                className="button -secondary"
                type="button"
                onClick={ (event) => { onClose(event); } }
              >
                Cancel
              </button>
            </div>
          </>
        </Modal>
      }
    </>
  );
};

export default EditQuota;
