import { sum, map } from 'lodash';

const randomValue = (max) => Math.floor(Math.random() * max);

const generatePassword = () => {
  let generatedString = '';
  const symbols = [ '!', '@', '#', '$', '%', '^' ];
  generatedString += symbols[randomValue(symbols.length - 1)];
  generatedString += Math.random().toString(36).slice(2, 10);
  generatedString += symbols[randomValue(symbols.length - 1)];
  generatedString += Math.random().toString(36).slice(2, 10);
  return map(
    generatedString,
    (character) => (randomValue(3) === 2 ? character.toUpperCase() : character.toLowerCase())
  ).join('');
};

const validatePasswordLength = (text) => (text?.length >= 8 && text?.length <= 20);

const validatePasswordContents = (text) => {
  return sum([
    text?.match(/\d/) ? 1 : 0,
    text?.match(/[A-Za-z]/) ? 1 : 0,
    text?.match(/[^\dA-Za-z\s]/) ? 1 : 0
  ]) >= 2;
};

const validatePassword = (text) => (validatePasswordLength(text) && validatePasswordContents(text));

export { generatePassword, validatePassword, validatePasswordContents, validatePasswordLength };
