import React, { useState, useEffect, useContext } from 'react';
import classnames from "classnames";
import { IndexData } from "../index-context";
import { comparisonViewChartsClick } from "../../../common/google-analytics/events";
import StatTestingDropdown from './components/stat_testing_dropdown';
import AveragesDropdown from "./components/averages_dropdown";
import ClientsDropdown from "./components/clients_dropdown";
import TabButtons from "./components/tab_buttons";
import ClientCategoryToggle from "./components/client_category_toggle";

const App = (
  {
    statTesting,
    scorecard,
    statTestingItemTitle,
    data,
    loadData,
    init
  }) => {
  const {
    openOverviewModal, averageMode,
    onClientsUpdate, clientList,
    selectedOptions, setSelectedOptions,
    allowDatabaseCompare
  } = useContext(IndexData);

  const [ statTestingValue, updateStatTestingValue ] = useState("average");
  const [ compareMode, setCompareMode ] = useState(averageMode);
  const [ avgForStatTesting, setAvgForStatTesting ] = useState(averageMode);
  const [ averageSelectorEnable, updateAverageSelectorEnable ] = useState(statTesting !== "none");
  const [ quintileDisabled, updateQuintilesDisabled ] = useState(false);

  useEffect(() => {
    if (data) {
      updateStatTestingValue(data.stat_testing);
      setCompareMode(data.average_mode);
      setAvgForStatTesting(data.average_mode);
      setSelectedOptions(data.selected_clients);
      updateQuintilesDisabled(!data.client_cmp_enabled && !data.category_cmp_enabled);
    }
  }, [ data ]);

  const onViewChartsClick = () => {
    comparisonViewChartsClick(init.google_analytics_id, { project_id: init.project.id });
    openOverviewModal();
  };

  const onStatTestingUpdate = (newValue) => {
    let avgMode = "project";
    switch (newValue) {
      case "quintiles":
        // eslint-disable-next-line no-case-declarations
        const availableStatTesting = getAvailableAverageModes();
        setAvgForStatTesting(availableStatTesting);
        avgMode = avgForStatTesting === "project" ? availableStatTesting : avgForStatTesting;
        break;
      case "average":
        updateAverageSelectorEnable(true);
        avgMode = !init.isInternal && avgForStatTesting === "platform" ? "project" : avgForStatTesting;
        break;
      default:
        setAvgForStatTesting("project");
        updateAverageSelectorEnable(false);
    }
    updateStatTestingValue(newValue);
    loadData(null, null, null, newValue, { averageMode: avgMode });
  };

  const getAvailableAverageModes = () => {
    switch (true) {
      case data.client_cmp_enabled:
        return "client";
      case data.category_cmp_enabled:
        return "platform";
      default:
        return "project";
    }
  };

  const onAverageUpdate = (newValue) => {
    loadData(null, null, null, statTestingValue, { averageMode: newValue });
    setAvgForStatTesting(newValue);
  };

  const onChangeCompareMode = (newAverageMode) => {
    setCompareMode(newAverageMode);
    const stat_testing = newAverageMode === 'project' ? 'average' : 'quintiles';
    loadData(null, null, null, stat_testing, { averageMode: newAverageMode });
  };

  const isQuintiles = (statTestingValue === "quintiles");

  return (
    <>
      <div className="tab-panel">
        {
          allowDatabaseCompare &&
          <TabButtons
            statTestingValue={ statTestingValue }
            onStatTestingUpdate={ onStatTestingUpdate }
            quintileDisabled={ quintileDisabled }
          />
        }
        <div
          className={
            classnames(
              "tab-panel__content",
              { '-with-left-top-corner': !allowDatabaseCompare }
            )
          }
        >
          <div className="comparisons_plate" id="top-averages">
            <div className="comparisons_plate-grid" id="top-averages-body">
              {
                isQuintiles &&
                <ClientCategoryToggle
                  compareMode={ compareMode }
                  data={ data }
                  onChangeCompareMode={ onChangeCompareMode }
                />
              }
              {
                !isQuintiles &&
                <>
                  <div
                    className={
                      classnames(
                        "comparisons_plate-item",
                        { "-width-1-3": init.isInternal },
                        { "-width-1-2": !init.isInternal }
                      )
                    }
                  >
                    <StatTestingDropdown
                      itemTitle={ statTestingItemTitle }
                      scorecard={ scorecard }
                      currentValue={ statTestingValue }
                      onUpdate={ onStatTestingUpdate }
                      averageMode={ data.average_mode }
                    />
                  </div>
                  <div
                    className={
                      classnames("comparisons_plate-item",
                        { "-width-1-3": init.isInternal },
                        { "-width-1-2": !init.isInternal }
                      )
                    }
                  >
                    <AveragesDropdown
                      currentValue={ avgForStatTesting }
                      onUpdate={ onAverageUpdate }
                      disabled={ !averageSelectorEnable || !allowDatabaseCompare }
                      data={ data }
                      buzzbackUser={ init.isInternal }
                    />
                  </div>
                </>
              }
              {
                init.isInternal &&
                  <ClientsDropdown
                    onUpdate={ onClientsUpdate }
                    averageMode={ data.average_mode }
                    clientList={ clientList }
                    selectedOptions={ selectedOptions }
                    setSelectedOptions={ setSelectedOptions }
                  />
              }
              {
                !scorecard && isQuintiles &&
                <button
                  className="button -single"
                  type="button"
                  onClick={ onViewChartsClick }
                >
                  View Charts
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
