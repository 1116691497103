import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { GlobalContext } from '../contexts/appContexts';

const LoadingPage = ({ title, duration = 5000, delay = 2000 }) => {
  const { nextStep } = useContext(GlobalContext);
  const [ width, setWidth ] = useState(0);
  const [ updateWidth, setUpdateWidth ] = useState(null);

  useEffect(() => {
    let intervalId = null;
    const timeStart = Date.now();
    setTimeout(() => {
      intervalId = setInterval(() => {
        if (Date.now() - timeStart >= duration) {
          setWidth(100);
          clearInterval(intervalId);
        } else {
          setUpdateWidth({});
        }
      }, 1000);
    }, delay);
  }, []);

  useEffect(() => {
    if (updateWidth) {
      setWidth(width + Math.floor(Math.random() * (100 - width - 1)));
    }
  }, [ updateWidth ]);

  useLayoutEffect(() => {
    if (width === 100) {
      setTimeout(() => {
        nextStep();
      }, delay);
    }
  }, [ width ]);

  return (
    <div className="loading-page">
      <h1 className="loading-page_title">{title}</h1>
      <div className="loading-page_progress">
        <div
          className="loading-page_progress-completed"
          style={ { 'width': `${width}%` } }
        />
      </div>
    </div>
  );
};

export default LoadingPage;
