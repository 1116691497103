import React, { useContext } from "react";
import { map } from "lodash";
import { n0, idToColor } from '../../helpers';
import { IndexData } from '../../index-context';

const CombinationRow = ({ r }) => {
  const { conceptById, claimById } = useContext(IndexData);

  return (
    <tr>
      <td className="table-comparison_name-cell">
        {
          map(r.concept_ids, (cid, i) => (
            <span key={ cid }>
              {
                !!i &&
                <span>&nbsp;&bull;&nbsp;</span>
              }
              <span style={ { color: idToColor(cid) } }>
                { conceptById(cid).title || claimById(cid).label }
              </span>
            </span>
          ))}
      </td>
      <td>
        <div className="status-bar -color-above -with-left-text">
          <div
            className="status-bar_inner"
            style={ { width: `${r.reported_reach * 100}%` } }
          />
          <div className="status-bar_text">
            { n0(r.reported_reach * 100) }%
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CombinationRow;
