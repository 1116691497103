import React, { useContext, useState } from 'react';
import cn from "classnames";
import renderRawHtml from '../../../../../common/render_raw_html.js';
import FooterButton from '../footer_button.js';
import ZoomImage from '../zoom_image';
import { onPasteDetected, prepareParams, sendToFraudCheck } from "../../logic/fraud_check";
import { fraudContext } from "../../contexts";

const PreferenceReasons = ({
  title, note, noteClassName, placeholder, concept, result, nextStep,
  children, forceImage
}) => {
  const header = children;
  const { respondentId, projectId, antiFraud, setFraudProcessing } = useContext(fraudContext);

  const [ text, updateText ] = useState("");

  const disabledOption = () => {
    const hash = {};
    if (!text) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswer = () => {
    result.preference_answers = [ { concept_id: concept.id, answer: text } ];
    updateText('');
    const fraudParams = prepareParams({ antiFraud, projectId, respondentId }, [ text ]);
    sendToFraudCheck(fraudParams, nextStep, setFraudProcessing);
  };

  const updateAnswer = (event) => {
    updateText(event.target.value);
  };

  return (
    <>
      {
        header ?
          header :
          <div className="survey-header">
            <div className="survey-header_title" { ...renderRawHtml(title) } />
          </div>
      }
      <div className="survey-layout_content">
        <div className="survey-layout_container -reasons-for-preference">
          <div className="survey-options">
            {
              forceImage && !!concept.url &&
              <div className="survey-options_image-plate reason-for-preference">
                <ZoomImage
                  withHeadWrapper
                  className="survey-options_image"
                  src={ concept.url }
                >
                  <img src={ concept.url } />
                </ZoomImage>
              </div>
            }
            <div className={ cn("note", noteClassName) }>
              <div className="survey-options_question-title-inner" { ...renderRawHtml(note) } />
            </div>
            <textarea
              className="js-text form-field survey-claim-random_textarea naming"
              placeholder={ placeholder }
              onChange={ (e) => { updateAnswer(e); } }
              onPaste={ () => { onPasteDetected(result); } }
            />
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton nextStep={ () => { processAnswer(); } } options={ disabledOption() } />
      </div>
    </>
  );
};

export default PreferenceReasons;
