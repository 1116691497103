import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import { GlobalContext } from '../contexts/appContexts';
import Modal from '../components/modal';
import ReviewTerms from '../modals/reviewTerms';

const ReviewTermsPartial = ({
  buttonTitle, buttonClassName, buttonDisabled,
  onOpenModalClick, onAgreeClick, onScrollToBottomClick
}) => {
  const { mergeData } = useContext(GlobalContext);
  const [ animatedDisplayTerms, setAnimatedDisplayTerms ] = useState(false);
  const [ openModalReviewTerms, setOpenModalReviewTerms ] = useState(false);

  const handleOpenModalReviewTerms = () => {
    onOpenModalClick && onOpenModalClick();
    mergeData({ idCheckReviewTermsShown: true });
    setOpenModalReviewTerms(true);
  };

  useEffect(() => {
    if (openModalReviewTerms) {
      setTimeout(() => {
        setAnimatedDisplayTerms(true);
      }, 100);
    } else {
      setAnimatedDisplayTerms(false);
    }
  }, [ openModalReviewTerms ]);


  const handleOnScrollToBottom = () => {
    onScrollToBottomClick && onScrollToBottomClick();
    mergeData({ idCheckScrollToBottomClick: true });
  };

  const closeModalReviewTerms = () => {
    mergeData({ idCheckReviewTermsCloseClick: true });
    setOpenModalReviewTerms(false);
  };

  const handleNext = () => {
    mergeData({ idCheckAgreeButtonClick: true });
    onAgreeClick && onAgreeClick();
  };

  const footerModalReviewTerms = (
    <>
      <div className="modal_footer-hint -above">
        By clicking “Agree”, you confirm that you have read and agree to the disclosures and the
        { " " }
        <span className="pseudo-link">PayPal Balance Account Terms and Conditions</span>
        , the
        { " " }
        <span className="pseudo-link">PayPal Debit Mastercard® Cardholder Agreement</span>
        { " " }
        and
        { " " }
        <span className="pseudo-link">Bancorp Privacy Notice</span>.
      </div>
      <div className="modal_footer-button">
        <button className="button -full-width" onClick={ handleNext }>
          Agree and Continue
        </button>
      </div>
    </>
  );

  return (
    <>
      <button
        className={ buttonClassName }
        onClick={ handleOpenModalReviewTerms }
        disabled={ buttonDisabled }
      >
        { buttonTitle }
      </button>
      {
        openModalReviewTerms &&
        <Modal
          modal
          classNameDialog={ classnames("-review-terms", { "-hidden": !animatedDisplayTerms }) }
          handleClose={ closeModalReviewTerms }
          footerInContent={ footerModalReviewTerms }
          scrollBottomButton
          scrollBottomButtonText="Scroll to Review"
          onScrollToBottom={ handleOnScrollToBottom }
        >
          <ReviewTerms />
        </Modal>
      }
    </>
  );
};

export default ReviewTermsPartial;
