import React, { useContext } from 'react';
import classnames from "classnames";
import { IndexData } from '../../index-context';

const SortableHeader = ({ className, title, field }) => {
  const { loadData, dir, sort } = useContext(IndexData);

  const classes = () => {
    return {
      '-asc': field === sort && dir === 'asc',
      '-desc': field === sort && dir === 'desc'
    };
  };

  const change = (e) => {
    e.preventDefault();
    if (sort !== field || !dir || dir === 'desc') {
      loadData(null, null, { sort: field, dir: 'asc' });
    } else if (dir === 'asc') {
      loadData(null, null, { sort: field, dir: 'desc' });
    }
  };

  return (
    <th className={ className }>
      <a
        href="#"
        className={ classnames("sort-link", classes()) }
        onClick={ change }
      >
        { title }
      </a>
    </th>
  );
};

export default SortableHeader;
