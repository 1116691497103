import React, { Fragment } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import {
  NegativeSwitcher,
  LogicSwitcher,
  RemoveButton
} from './section_logic';

const titleMinSymbolsCountToReduceFontSize = 24;
const tagMinSymbolsCountToReduceFontSize = 100;

const Tags = ({
  item, index, removeOption,
  selectOperator, selectNotOperator,
  removeSection
}) => {
  const titleNeedReduceFontSize = item.title.length > titleMinSymbolsCountToReduceFontSize;
  const tagReduceFontSize = (answer) => (answer.value.length > tagMinSymbolsCountToReduceFontSize);

  return (
    <div className="chosen-container chosen-container-multi comparasion_filter localytics-chosen chosen-container-active chosen-with-drop -low-index filter-item">
      <ul className="chosen-choices -no-dropdown">
        <li className="search-choice -title">
          <span className={ classnames({ '-big-one': titleNeedReduceFontSize }) }>
            { item.title }
          </span>
          <NegativeSwitcher
            selectOperator={ (event, operator) => { selectNotOperator(event, operator); } }
            item={ item }
          />
        </li>
        {
          map(item.answers, (answer, j) => {
            return (
              <Fragment key={ `selectedAnswersIndex${index}_${j}` }>
                {
                  (j > 0) &&
                  <li
                    className={
                      classnames(
                        'search-choice -between colored',
                        `-${item.logicOperator.toLowerCase()}`
                      )
                    }
                  >
                    <span>{ item.logicOperator }</span>
                  </li>
                }
                <li className="search-choice">
                  <span className={ classnames({ 'tag -big-one': tagReduceFontSize(answer) }) }>
                    { answer.value }
                  </span>
                  <a
                    className="search-choice-close"
                    onClick={ (event) => { removeOption(event, answer); } }
                  />
                </li>
              </Fragment>
            );
          })
        }
      </ul>
      <div className="control-panel">
        <LogicSwitcher
          selectOperator={
            (event, operator) => {
              selectOperator(event, operator);
            }
          }
          item={ item }
        />
        <RemoveButton removeSection={ removeSection } />
      </div>
    </div>
  );
};

export default Tags;
