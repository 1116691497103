import React, { useContext } from 'react';
import { EditorContext } from '../contexts';
import iconEraser from './iconBase64';

const HtmlCleaner = () => {
  const { getHtml, setHtml } = useContext(EditorContext);
  const handleErase = () => {
    const html = getHtml();
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    setHtml(tempElement.textContent || tempElement.innerText || '');
  };
  return (
    <div className="rdw-inline-wrapper">
      <div
        className="rdw-option-wrapper -drop-styles"
        onClick={ handleErase }
      >
        <img src={ iconEraser } alt="" />
      </div>
    </div>
  );
};

export default HtmlCleaner;
