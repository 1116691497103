import React, { useContext } from 'react';
import { RouteContext } from "../contexts";
import { setHashData } from "../statistic";
import { scrollTop } from "../helpers";

const Footer = () => {
  const { setPath } = useContext(RouteContext);

  const goHome = () => {
    setPath('/');
    scrollTop();
  };

  const goAbout = () => {
    setPath('/about');
    setHashData(`aboutUsClicked`, true);
    scrollTop();
  };

  const footerMenu = [
    {
      title: 'Apps',
      items: [
        { name: 'iOS' },
        { name: 'Andriod' }
      ]
    },
    {
      title: 'Shop',
      items: [
        { name: 'Apple' },
        { name: 'Google' },
        { name: 'Samsung' }
      ]
    },
    {
      title: 'Services',
      items: [
        { name: 'Community' },
        { name: 'Visible Protect' },
        { name: 'Finance with Affirm' },
        { name: 'Swap Program' },
        { name: 'Party Pay' },
        { name: 'Coverage' },
        { name: 'Returns Policy' }
      ]
    },
    {
      title: 'Company',
      items: [
        { name: 'About Us', click: 'goAbout()' },
        { name: 'Visible Connect' },
        { name: 'Newsroom' },
        { name: 'Careers' }
      ]
    }
  ];


  return (
    <footer className="footer">

      <div className="footer_form">
        <div className="footer_form-title">Subscribe to stay in the loop.</div>
        <div className="footer_form-control">
          <input type="text" placeholder="Email address" className="form-control" />
        </div>
        <div className="footer_form-control">
          <button className="button -full-width">Submit</button>
        </div>
      </div>

      <nav className="footer_grid">
        {footerMenu.map((menu, index) => {
          return (
            <div className="footer_grid-section" key={ `footer-menu-${index}` }>
              <div className="footer_grid-section-title">{menu.title}</div>
              <ul className="footer_grid-menu">
                {menu.items.map((menuItem, index) => {
                  return (
                    <li className="footer_grid-menu-item" key={ `footer-menu-item-${index}` }>
                      <div
                        className={ `footer_grid-menu-link ${menuItem.click ? '-clickable' : ''}` }
                        onClick={ () => eval(menuItem.click) }
                      >{menuItem.name}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </nav>

      <ul className="footer_social-menu">
        <li className="footer_social-menu-item">
          <div className="footer_social-menu-link">
            <svg className="icon -facebook">
              <use xlinkHref="#svg_facebook" />
            </svg>
          </div>
        </li>
        <li className="footer_social-menu-item">
          <div className="footer_social-menu-link">
            <svg className="icon -instagram">
              <use xlinkHref="#svg_instagram" />
            </svg>
          </div>
        </li>
        <li className="footer_social-menu-item">
          <div className="footer_social-menu-link">
            <svg className="icon -twitter">
              <use xlinkHref="#svg_twitter" />
            </svg>
          </div>
        </li>
      </ul>

      <div className="footer_logo">
        <div className="footer_logo-link" onClick={ () => goHome() }>
          <svg className="icon -logo">
            <use xlinkHref="#svg_logo" />
          </svg>
        </div>
      </div>

      <div className="footer_copyrights">
        <div className="footer_copyrights-section">
          8350 E Crescent Parkway, Suite 200,
          <br />
          Greenwood Village, CO 80111
          <br />
          © 2020 Visible. All rights reserved.
        </div>
        <div className="footer_copyrights-section">
          Terms & Conditions | Terms of Use | Legal Disclosures | About our Ads | Supplemental Terms |
          Privacy Policy | Sitemap | Do Not Sell My
          Personal Information
        </div>
      </div>
    </footer>
  );
};

export default Footer;
