import React, { useContext, useState } from 'react';
import Modal from '../components/modal';
import { GlobalContext } from '../contexts/appContexts';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";

const Passkey = ({ headerStep, numberOfHeaderSteps }) => {
  const { fakeData, nextStep, prevStep, skipStep, mergeData } = useContext(GlobalContext);
  const [ showHint, setShowHint ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);

  const handleSignIn = () => {
    setShowModal(true);
  };

  const handleCloseSignIn = () => {
    mergeData({ passkeyCloseSignInPopup: false });
    setShowModal(false);
  };

  const handleNext = () => {
    mergeData({ passkeySkip: false });
    nextStep();
  };

  const handleSkip = () => {
    mergeData({ passkeySkip: true });
    skipStep();
  };

  const footerButtons = (
    <>
      <button className="button -full-width" onClick={ handleSignIn }>
        Next
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  const handleBack = () => {
    mergeData({ passkeyBackClick: true });
    prevStep();
  };

  const header = (
    <Header
      onBack={ handleBack }
      currentStep={ headerStep }
      numberOfSteps={ numberOfHeaderSteps }
    />
  );

  const handleOpenTip = () => {
    mergeData({ passkeyHowItWorksClick: true });
    setShowHint(true);
  };

  const handleCloseTip = () => {
    setShowHint(false);
  };

  return (
    <>
      <Layout header={ header } footer={ footer }>
        <div className="password">
          <div className="title passkey_title">
            <h1 className="title_h1">Create your passkey</h1>
          </div>
          <div className="form_hint">
            Get stronger login protection with a phishing-resistant passkey.
            { ' ' }
            Set up by using Face ID or Touch ID to sign in.
          </div>
          <div className="passkey_works">
            <button className="button-link -underlined" onClick={ handleOpenTip }>
              How it works
            </button>
          </div>
          <div className="passkey_form form">
            <svg className="icon form-control_icon -passkey-icon">
              <use xlinkHref="#svg_passkey-icon" />
            </svg>
          </div>
        </div>
      </Layout>
      {
        showHint &&
        <Modal handleClose={ handleCloseTip } classNameHeader="passkey-hint-header">
          <div className="passkey-hint">
            <div className="title -center">
              <h3>How passkeys work</h3>
            </div>
            <div className="passkey-hint_tips">
              <div className="tip-item">
                <h4>What’s a passkey?</h4>
                <span>
                  A passkey is a unique digital key that lets you log in to websites and apps
                  { ' ' }
                  without a password.
                  { ' ' }
                  It’s always strong and never guessable by a hacker.
                </span>
              </div>
              <div className="tip-item">
                <h4>Skip the password</h4>
                <span>
                  Passkeys are saved with your device so you never lose it.
                  { ' ' }
                  There’s nothing you have to create, protect, or remember.
                </span>
              </div>
              <div className="tip-item">
                <h4>Easier log in</h4>
                <span>
                  Securely log in with your passkey using your face or fingerprint to identify you.
                </span>
              </div>
              <div className="tip-item">
                <h4>Syncs across devices</h4>
                <span>
                  Log in to PayPal with your passkey on any device synced to your cloud service.
                </span>
              </div>
            </div>
          </div>
        </Modal>
      }
      {
        showModal &&
        <Modal
          handleClose={ handleCloseSignIn }
          classNameHeader="passkey-sign-in-header"
          classNameContent="passkey-sign-in-content"
        >
          <div className="passkey-sign-in">
            <div className="title -center">
              <h3>Sign In</h3>
            </div>
            <div className="confirm-passkey form">
              <svg className="icon form-control_icon -passkey-small-icon">
                <use xlinkHref="#svg_passkey-icon" />
              </svg>
              <h2>
                Use Face ID to sign in?
              </h2>
              <div className="sign-in-note">
                A passkey for { '"' }{ fakeData.email }{ '"' } will be saved in
                { ' ' }
                iCloud Keychain and available on all your devices.
              </div>
              <footer className="footer">
                <button className="button" onClick={ handleNext }>Continue</button>
              </footer>
            </div>
          </div>
        </Modal>
      }
    </>

  );
};

export default Passkey;


