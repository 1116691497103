import React from 'react';
import classnames from "classnames";

const ToggleField = ({ error, label, value, onChange, className, disabled }) => {
  return(
    <div className="form-group">
      <div className="form-group_label -upper -inline">{ label }</div>
      <div className="toggle table_control-over-line">
        <input name="selected" type="checkbox"
               id={`survey_id_${label}`}
               value={`survey_id_${label}`}
               checked={value}
               onChange={ (event) => onChange(event.target.checked) }
               disabled={ disabled }
        />
        <label htmlFor={`survey_id_${label}`} className={ classnames("toggle_label", className) }></label>
      </div>
      { error && <p className="field-error">{ error }</p> }
    </div>

  );
};

export default ToggleField;