import React, { useState, useCallback } from 'react';
import PlanPerkModal from './planPerksModal';

const ArticleText = ({ text }) => <li className="perk-articles-list-item">{text}</li>

const Article = ({ article }) => {
  const { title, iconPath, iconClassName } = article;
  return <>
    <li className="perk-articles-list-item -icon">
      <img className={`icon ${iconClassName}`} src={iconPath} />
    </li>
    <ArticleText text={title} />
  </>;
};

const renderArticles = (article, idx) =>
  article.iconPath
    ? <Article key={idx} article={article} />
    : <ArticleText key={idx} text={article.title} />;

export const Perk = ({ perk, onDetailsClick, children }) => {
  const { title, articles } = perk;
  return <div className="perk-item">
    <p className="perk-title">{title}</p>
    <ul className="perk-articles-list">
      {articles.map(renderArticles)}
    </ul>
    <div className="perk-footer">
      <button type="button" className="details-link" onClick={onDetailsClick}>See perk details</button>
      {children}
    </div>
  </div>;
};

const SelectablePerk = ({ perk, selected, disableSelect, onSelect, onDetailsClick }) =>
  <div className={`perk-item-wrapper ${selected && '-selected'}`.trim()}>
    <Perk perk={perk} selected={selected} onDetailsClick={onDetailsClick}>
      <button
        disabled={disableSelect}
        className="button -bordered -choose-perk-button"
        onClick={() => onSelect(perk)}>
          {selected ? 'Remove' : 'Choose perk'}
      </button>
    </Perk>
  </div>

const PerkListBase = ({ list, iterator, onDetailsClick }) => {
  const [openPerkModal, setOpenPerkModal] = useState(null);
  const handleOpen = (perk, idx) => {
    setOpenPerkModal({ name: `${perk.title} details`, description: [perk] });
    onDetailsClick(idx);
  };
  const handleClose = () => setOpenPerkModal(null);
  return <div>
    <ul className="perk-list">
      {list.map(iterator(handleOpen))}
    </ul>
    <PlanPerkModal open={!!openPerkModal} handleClose={handleClose} plan={openPerkModal} />
  </div>;
};

const renderPerks = (onDetailsClick) => (perk, idx) => {
  return <li className="perk-list-item" key={perk.id}>
    <Perk
      perk={perk}
      onDetailsClick={() => onDetailsClick(perk, idx)} />
  </li>
};

const renderSelectablePerk = (selectedList, onSelect, maxSelectCount) =>
  (onDetailsClick) =>
  (perk, idx) => {
    const checkSelected = 
    useCallback(
      (perkId) => selectedList.some(({ id }) => id === perkId),
      [selectedList]
    );
    return <li className="perk-list-item" key={perk.id}>
        <SelectablePerk
            perk={perk}
            disableSelect={checkSelected(perk.id) ? false : selectedList.length === maxSelectCount}
            selected={checkSelected(perk.id)}
            onSelect={(perk) => onSelect(perk, !checkSelected(perk.id), idx)}
            onDetailsClick={() => onDetailsClick(perk, idx)} />
    </li>
  };

export const PerkList = ({ list, onDetailsClick }) => 
  <PerkListBase
    list={list}
    iterator={renderPerks}
    onDetailsClick={onDetailsClick} />;

export const SelectablePerkList = ({ list, selectedList, onSelect, maxSelectCount, onDetailsClick }) => 
  <PerkListBase
    list={list}
    iterator={renderSelectablePerk(selectedList, onSelect, maxSelectCount)}
    onDetailsClick={onDetailsClick} />;
