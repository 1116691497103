import React, { useContext, useState } from 'react';
import HeaderLinks from "../../components/header-links";
import { IndexData } from "../../index-context";
import render_raw_html from '../../../../../common/render_raw_html';
import InternalViewWarningModal from "../internal-view-warning-modal";
import FontSelectionModal from "../font-selection-modal";
import CustomizeViewModal from "./customize-view-modal";
import OverviewModal from "./../overview-modal/overview-modal";

const Header = ({
  customTitle, reports, plural, sortGroups, showCustomizeView = true,
  generateExportParams
}) => {
  const [ showCustomize, setShowCustomize ] = useState(false);

  const onCustomizeClose = () => {
    setShowCustomize(false);
  };

  const {
    init, showOverviewModal, closeOverviewModal, fontSelectionModalData, exportData
  } = useContext(IndexData);

  return (
    <>
      <div className="content_top">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item -grow">
              <h1
                className="header_title"
                { ...render_raw_html(customTitle || init.project.title) }
              />
            </div>
            <div className="header_grid-item">
              {showCustomizeView &&
                <button
                  className="button -secondary header_button button"
                  type="button"
                  onClick={ () => {setShowCustomize(true);} }
                >
                  Customize View
                </button>
              }
              <HeaderLinks
                className="-right-sided -no-min-width"
                reports={ reports }
                generateExportParams={ generateExportParams }
                tourName="projectComparisonExport"
              />
            </div>
          </div>
        </header>
      </div>
      {
        showCustomize &&
        <CustomizeViewModal
          onClose={ onCustomizeClose }
          plural={ plural }
          sortGroups={ sortGroups }
        />
      }
      {
        showOverviewModal &&
        <OverviewModal
          onClose={ closeOverviewModal }
        />
      }
      {
        exportData &&
        <InternalViewWarningModal />
      }
      { !!fontSelectionModalData && <FontSelectionModal /> }
    </>
  );
};

export default Header;
