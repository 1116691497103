import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import cn from "classnames";
import { getMetricKind, IndexData } from "../../index-context";
import StatusCircle from "../../components/status-circle";
import { hideCustom, showedAverageValue } from '../../helpers';

const TableAttributeRatings = ({ data, current, attributeRatings, groupName, className }) => {
  const { averageMode, init } = useContext(IndexData);
  return (
    <table className={ cn("table table-comparison -long-first-cell", className) }>
      <thead>
        <tr>
          <th className="table-comparison_name-cell" colSpan="2">
            <div className="table-comparison_title">
              { getMetricKind(data, groupName) }
            </div>
          </th>
          <th className="table-comparison_average-cell">Avg</th>
        </tr>
      </thead>
      <tbody>
        {
          map(attributeRatings, (values, title) => (
            <Fragment key={ `scorecard_attrs_${title}` }>
              <tr>
                <td rowSpan="2" className="table-comparison_name-cell">
                  {values.real_attribute_title || title}
                </td>
                <td>
                  <div className="table-comparison_box-cell">T2B</div>
                  <StatusCircle
                    hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                    values={ values[current.id] } box="top2"
                    noLetters
                  />
                </td>
                <td className="table-comparison_average-cell">
                  {showedAverageValue(values, averageMode, false, init.isInternal, data.view)}
                </td>
              </tr>

              <tr>
                <td>
                  <div className="table-comparison_box-cell">TB</div>
                  <StatusCircle
                    values={ values[current.id] }
                    box="top"
                    noLetters
                    hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                  />
                </td>
                <td className="table-comparison_average-cell">
                  {showedAverageValue(values, averageMode, false, init.isInternal, data.view, "top")}
                </td>
              </tr>
            </Fragment>
          ))
        }
      </tbody>
    </table>
  );
};

export default TableAttributeRatings;
