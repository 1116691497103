import React, {useState} from 'react';
import Statistic from "../../statistic";

const PerkArticle = ({ article, lineIndex, isLanding, perksList, perk }) => {
  const { title, text, icon, iconPath, iconClassName, showText } = article;

  const [showTextState, setShowTextState] = useState(typeof showText !== 'undefined' ? showText: true);

  const toggleClickHandle = () => {
    const perkIndex = perksList.findIndex(perksListItem => perksListItem.id === perk.id);
    Statistic.updateExpandValuePerks(isLanding, lineIndex, perkIndex, title, !showTextState);
    setShowTextState(!showTextState);
  };

  return <div className="perk-article">
    {
      icon && <div className="perk-article-icon">
        <svg className={ `icon -${ icon } ${iconClassName}` }>
          <use xlinkHref={ `#svg_${ icon }` }/>
        </svg>
      </div>
    }
    {
      iconPath && <div className="perk-article-icon">
        <img className={`icon ${iconClassName}`} src={iconPath} />
      </div>
    }
    <h6 className={`perk-article-subtitle toggle-text_title`} onClick={toggleClickHandle}>
      <svg className={ `icon -dropdown toggle-text_dropdown-icon ${showTextState ? '-up': '-down'}` }>
        <use xlinkHref={ `#svg_arrow-down` }/>
      </svg>
      {
        title.split('\n').map((value, idx) => <span key={idx}>{value}</span>)
      }
    </h6>
    {
      showTextState ? text.split('\n').map((value, idx) => <p key={idx} className="perk-article-description" dangerouslySetInnerHTML={{__html: value}}/>) : ''
    }
  </div>;
};

export default PerkArticle;