import {
  compact, each, findKey, find, includes, keys, map, remove, uniq,
  values, sortBy, filter
} from "lodash";

const getSelectedKeys = (title, answers, condition, disallowedOptions = {}, conditionValues) => {
  const answerKeys = keys(answers);

  const selectedValue = Object.hasOwn(conditionValues, 'selected') ? conditionValues.selected : 'is';

  const selectedKeys = (includes(uniq(values(condition || {})), selectedValue) ?
    compact(map(condition, (v, k) => (v === selectedValue ? k : null))) :
    answerKeys);

  each(
    disallowedOptions[title] || [],
    (option) => {
      const key = findKey(answers, (answer) => option === answer) || option; // to allow -2 or "other text"
      remove(selectedKeys, (item) => item === key);
    }
  );
  if (!selectedKeys.length) {
    selectedKeys.push(answerKeys[0]);
  }

  return selectedKeys;
};

const getSelectedAnswers = (title, answers, condition, conditionValues) => (
  map(
    getSelectedKeys(title, answers, condition, {}, conditionValues),
    (num) => answers[num]
  )
);

const getSelectedMappedAnswers = (mappingList, title, answers, condition, conditionValues) => {
  return uniq(
    map(
      getSelectedAnswers(title, answers, condition, conditionValues),
      (answer) => (
        find(
          mappingList,
          (listItem) => listItem.title === answer
        ).answer
      )
    )
  );
};

const getSelectedKeysAfterMapping = (mappingList, title, answers, condition, quotaAnswers, conditionValues) => {
  return sortBy(map(
    getSelectedMappedAnswers(mappingList, title, answers, condition, conditionValues),
    (answer) => findKey(quotaAnswers, (val) => val === answer)
  ), (key) => key * 1);
};

const getSelectedItemsAfterMappingForQuestion = (q, conditionValues) => {
  const selectedAnswers = getSelectedAnswers(q.title, q.answers, q.condition, conditionValues);
  return map(
    getSelectedKeysAfterMapping(q.quotaMappingList, q.title, q.answers, q.condition, q.quotaMappingAnswers, conditionValues),
    (key) => {
      const mappedTitle = q.quotaMappingAnswers[key];
      return {
        id: key,
        title: mappedTitle,
        baseAnswers: filter(
          selectedAnswers,
          (baseAnswer) => (
            find(
              q.quotaMappingList,
              (item) => (
                item.title === baseAnswer &&
                item.answer === mappedTitle
              )
            )
          )
        )
      };
    }
  );
};

export {
  getSelectedKeys,
  getSelectedItemsAfterMappingForQuestion,
  getSelectedKeysAfterMapping
};
