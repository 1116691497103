import React, { useContext, useState } from 'react';
import { map } from "lodash";
import Modal from "../components/modal";
import Notification from "../components/notification";
import TermsApply from "../modals/termsApply";
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import { GlobalContext } from '../contexts/appContexts';
import BonusTermsApply from '../modals/bonusTermsApply';
import ReviewTermsPartial from './reviewTermsPartial';

const BaseIntroSetup = ({
  headerStep, numberOfHeaderSteps,
  title, notificationText, withReviewTerms
}) => {
  const { nextStep, skipStep, mergeData, fakeData } = useContext(GlobalContext);
  const [ openModalTerms, setOpenModalTerms ] = useState(false);
  const [ openBonusTerms, setOpenBonusTerms ] = useState(false);
  const { introSetupList } = fakeData;

  const handleNext = () => {
    mergeData({ introSkip: false });
    nextStep();
  };

  const handleSkip = () => {
    mergeData({ introSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      {
        withReviewTerms ? (
          <ReviewTermsPartial
            buttonTitle="Set Up Your PayPal Debit Card"
            buttonClassName="button -full-width"
            onAgreeClick={ handleNext }
          />
        ) : (
          <button className="button -full-width" onClick={ handleNext }>
            Set Up Your PayPal Debit Card
          </button>
        )
      }
      <button className="button -link -full-width" onClick={ handleSkip }>
        Set this up later
      </button>
    </>
  );

  const handleOpenTerms = () => {
    mergeData({ introOpenTerms: true });
    setOpenModalTerms(true);
  };

  const closeModalTerms = () => {
    setOpenModalTerms(false);
  };

  const handleOpenBonusTerms = (event) => {
    event.preventDefault();
    event.stopPropagation();
    mergeData({ introBonusTermsClick: true });
    setOpenBonusTerms(true);
  };

  const closeBonusModalTerms = () => {
    setOpenBonusTerms(false);
  };

  const footerHintAbove = (
    <>
      5% cash back on up to $1000 spend/month everywhere Mastercard is accepted.
      { " " }
      <button className="button-link -underlined" onClick={ handleOpenTerms }>Terms apply</button>.
      { " " }
      Cash back earned as points you redeem for cash and other options.
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } footerHintAbove={ footerHintAbove } />;

  return (
    <Layout header={ header } footer={ footer }>
      {
        !!notificationText &&
        <Notification status="success" delay={ 3000 } icon="checked-circle">
          { notificationText }
        </Notification>
      }

      <div className="intro-setup">
        <div className="title intro-setup_title">
          <h1 className="title_h1">{ title }</h1>
        </div>

        <ul className="intro-setup_list">
          {
            map(introSetupList, (item, index) => (
              <li key={ index } className="intro-setup_list-item">
                <div className="intro-setup_item">
                  <div className="intro-setup_item-icon">
                    <svg className={ `intro-setup_item-svg -${item.id}` }>
                      <use xlinkHref={ `#svg_intro-${item.id}` } />
                    </svg>
                  </div>
                  <div className="intro-setup_item-content">
                    <h4 className="intro-setup_item-title">
                      { item.title }
                    </h4>
                    <div className="intro-setup_item-text">
                      {
                        item.rewards ? (
                          <>
                            Add money with auto reload, add PayPal Debit card to Apple Wallet,
                            { " " }
                            and make purchases.
                            { " " }
                            <a href="#" onClick={ handleOpenBonusTerms }>Bonus Terms</a>
                            {" "}
                            apply.
                          </>
                        ) : (
                          <>
                            {item.text}
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
      {
        openModalTerms &&
        <Modal
          handleClose={ closeModalTerms }
          headerTitle="Terms apply"
          classNameDialog="-full-height"
          classNameContent="-bg-gray"
        >
          <TermsApply />
        </Modal>
      }
      {
        openBonusTerms &&
        <Modal
          handleClose={ closeBonusModalTerms }
          headerTitle="PayPal Rewards Program Agreement"
          classNameDialog="-full-height"
          classNameContent="-bg-gray"
        >
          <BonusTermsApply />
        </Modal>
      }
    </Layout>
  );
};

export default BaseIntroSetup;


