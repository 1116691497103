import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { map, find } from 'lodash';

const Dropdown = ({
  onChange, selectedValue, list,
  defaultValue = null, defaultText = 'Total'
}) => {
  const [ , setClickedInside ] = useState(false);
  const [ dropdownOpened, setDropdownOpened ] = useState(false);

  useEffect(() => {
    const body = document.body || document.querySelector('body');
    const handler = () => {
      let currentClickedInside;
      setClickedInside((oldVal) => {
        currentClickedInside = oldVal;
        return false;
      });
      if (!currentClickedInside) {
        setDropdownOpened(false);
      }
    };
    body.addEventListener('click', handler);
    return () => {
      body.removeEventListener('click', handler);
    };
  });

  const clickInside = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setClickedInside(true);
    setDropdownOpened(!dropdownOpened);
  };

  const defaultItem = [ { title: defaultText, value: defaultValue } ];

  const fullList = [ ...defaultItem, ...(list ? list : []) ];

  const selectedItem = find(
    fullList,
    (item) => (item.value === selectedValue)
  ) || defaultItem;

  return (
    <>
      {
        !!list && !!list.length &&
        <div
          className={
            classnames(
              "custom-dropdown",
              { '-opened': dropdownOpened }
            )
          }
          onClick={ clickInside }
        >
          <div className="selected">
            <div className="selected-text">
              { selectedItem.title }
            </div>
            <div className="carrot" />
          </div>
          {
            dropdownOpened &&
            <ul className="list">
              {
                map(fullList, (item) => (
                  <li
                    key={ `filterItem${item.title}` }
                    className={
                      classnames({
                        '-selected': item.value === selectedValue
                      })
                    }
                    onClick={ () => { onChange(item.value); } }
                  >
                    { item.title }
                  </li>
                ))
              }
            </ul>
          }
        </div>
      }
    </>
  );
};

export default Dropdown;
