import { map } from "lodash";
import React, { useContext, useState } from "react";
import classnames from 'classnames';
import renderRawHtml from '../../../common/render_raw_html';
import Perk from "./perk";
import { perkById } from './utils';
import { LineDataContext } from "./contexts";
import PerksModal from "./perksModal";

const PlanWithPerks = ({
  plan, onPlanSelect, selectedPlanIdByLine,
  classNameWrapper, planPrice, reselectPerksInPlan
}) => {
  const {
    perks
  } = useContext(LineDataContext);

  const [ showPerksModal, setShowPerksModal ] = useState(false);
  const [ perksInPlan, setPerksInPlan ] = useState(() => (
    plan.getCustomPerks(true)
  ));

  const clickSelectButton = (event, plan) => {
    onPlanSelect(event, plan, perksInPlan);
  };

  const buttonIsDisabled = (plan) => (
    plan.id === selectedPlanIdByLine
  );

  const openPerksModal = () => {
    setShowPerksModal(true);
  };

  const closePerksModal = () => {
    setShowPerksModal(false);
  };

  const reselectPerks = (perksList) => {
    setPerksInPlan(perksList);
    reselectPerksInPlan(plan, perksList);
  };

  const buttonLabel = 'Select plan';

  return (
    <div className={ classNameWrapper }>
      <div
        className={
          classnames(
            "plan-plate",
            { '-active': plan.id === selectedPlanIdByLine }
          )
        }
      >
        <h3 className="plan-plate_title" { ...renderRawHtml(plan.name) } />
        <div className={ classnames("plan-plate_pricing", plan.pricePlateKlass) }>
          <div className="plan-plate_pricing-grid">
            <div className="plan-plate_pricing-value">
              ${ planPrice }
            </div>
            <div className="plan-plate_pricing-hint">
              <div className="plan-plate_pricing-hint-line">/mo.</div>
              <div className="plan-plate_pricing-hint-line">Plus taxes & fees</div>
            </div>
          </div>
        </div>
        <div className="plan-plate_features -base-features">
          {
            map(plan.features, (feature, index) => (
              <div
                className={ classnames("plan-plate_feature", feature.klass) }
                key={ `feature-${index}` }
              >
                {
                  !!feature.title &&
                  <div className="plan-plate_feature-title">{feature.title}</div>
                }
                <div className="plan-plate_feature-description">
                  { feature.description }
                </div>
              </div>
            ))
          }
        </div>
        {plan.perksCount > 0 && (
          <div className="plan-plate_perks">
            <div className="plan-plate_perks-title">{plan.perksCount} perk{plan.perksCount > 1 ? 's' : ''} included</div>
            <div className="plan-plate_perks-note">Most popular perk{plan.perksCount > 1 ? 's are' : ' is'} pre-selected.</div>

            <ul className="plan-plate_perks-list">
              {
                map(perksInPlan, (perkId) => {
                  const perkInPlan = perkById(perks, perkId);

                  return perkInPlan ? (
                    <Perk
                      key={ `perkInPlan${perkInPlan.id}` }
                      perk={ perkInPlan }
                      classNameWrapper="plan-plate_perks-item"
                      readOnly={ false }
                    />
                  ) : null;
                })
              }
            </ul>

            <div className="plan-plate_perks-action">
              <div className="plan-plate_perks-link" onClick={ () => { openPerksModal(); } }>Change your perk{plan.perksCount > 1 ? 's' : ''}</div>

              {
                showPerksModal &&
                <PerksModal
                  close={ closePerksModal }
                  perks={ perks }
                  planName={ plan.name }
                  planPerkCount={ plan.perksCount }
                  reselectPerks={ reselectPerks }
                />
              }

            </div>
          </div>
        )}
        <div className="plan-plate_feature-spacer" />

        <div className="plan-plate_bottom -with-less-top-margin">
          <div className="plan-plate_button">
            {
              !buttonIsDisabled(plan) &&
              <button
                className="button -bordered"
                onClick={ (event) => { clickSelectButton(event, plan); } }
              >
                { buttonLabel }
              </button>
            }
            {
              buttonIsDisabled(plan) &&
              <button className="button -bordered" disabled>
                { buttonLabel }
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanWithPerks;
