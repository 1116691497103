import { find, each, map, includes } from 'lodash';

const findOrInitLoop = (confirmit_concept_id, loops, newAnswers) => {
  let loop = find(loops, (el) => el.confirmit_concept_id === confirmit_concept_id);
  const found = !!loop;
  if (!loop) {
    loop = { confirmit_concept_id, answers: [] };
  }
  each(newAnswers, (answer) => {
    loop.answers.push(answer);
  });
  if (!found) {
    loops.push(loop);
  }
};

const answerToQuestionInLoop = (concept, question, loops, getItem = false) => {
  const loopItem = find(
    loops,
    (item) => (
      item.confirmit_concept_id === concept.confirmit_concept_id
    )
  );
  if (!loopItem) {
    return null;
  }
  const qIds = map(question.answers, (item) => (item.id));
  const selected = find(
    loopItem.answers || [],
    (answerId) => includes(qIds, answerId)
  );
  if (!selected) {
    return null;
  }
  if (getItem) {
    return find(
      question.answers,
      (item) => (item.id === selected)
    );
  }
  return selected;
};

export { findOrInitLoop, answerToQuestionInLoop };
