import React from "react";
import { trackGoogleAnalyticsEvent } from "./googleAnalyticsTracking";

const adminParams = { page_name: "admin", platform: "express" };
const researcherParams = { page_name: "researcher", platform: "express" };
const respondentParams = { page_name: "respondent", platform: "express" };

const sidebarRecentClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_recent_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarLogoClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_logo_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarBuzzbackTravelClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_buzzback_travel_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarVerizonTravelClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_verizon_travel_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarProfileClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_profile_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarExpressReportClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_express_report_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarTagManagerClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_tag_manager_click",
    "label",
    { ...adminParams, ...params }
  );
};

const sidebarEntityClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "sidebar_entity_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const profileUpdateClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "profile_update_click",
    "label",
    { ...adminParams, ...params }
  );
};

const creditsAddSubAccount = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "credits_add_sub_account",
    "label",
    { ...adminParams, ...params }
  );
};

const creditsRemoveSubAccount = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "credits_remove_sub_account",
    "label",
    { ...adminParams, ...params }
  );
};

const creditsAdd = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "credits_add",
    "label",
    { ...adminParams, ...params }
  );
};

const creditsRemove = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "credits_remove",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsGridViewClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_grid_view_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsListViewClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_list_view_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortTitleClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_title_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortTypeClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_type_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortClientClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_client_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortCreatedAtClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_created_at_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortInFieldAtClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_in_field_at_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortClosedAtClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_closed_at_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortStatusClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_status_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortCompletesClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_completes_click",
    "label",
    { ...adminParams, ...params }
  );
};

const allProjectsSortTargetCompletesClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "all_projects_sort_target_completes_click",
    "label",
    { ...adminParams, ...params }
  );
};

const projectSetupCustomizeQualifyMessageClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "project_setup_customize_qualify_message",
    "label",
    { ...adminParams, ...params }
  );
};

const projectSetupForceMarketplaceClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "project_setup_force_marketplace_click",
    "label",
    { ...adminParams, ...params }
  );
};

const projectPreviewReportTourClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "project_preview_report_tour_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const reviewRespondentsReviewClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "review_respondents_review_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const reviewRespondentsBanClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "review_respondents_ban_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const reviewRespondentsExportClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "review_respondents_export_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonExportConceptsForAverage = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_export_concepts_for_average",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonCompareToDatabaseClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_compare_to_database_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonCompareClientDatabaseClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_compare_client_database_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonCompareCategoryDatabaseClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_compare_category_db_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonViewChartsClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_view_charts_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonCompareAddClient = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_compare_add_client",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonFiltersAddComplexFilter = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_filters_add_complex_filter",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonCustomizeViewSortByChange = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_customize_view_sort_by_change",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonCustomizeViewShowAsChange = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_customize_view_show_as_change",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudPlayAnswer = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_play_answer",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudWordClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_word_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudShowSentimentsChange = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_sentiment_toggle",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudGearClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_gear_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudPinAnswer = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_pin_answer",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudShowHideAnswer = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_show_hide_answer",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudGearShowHideWord = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_gear_toggle_word",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardWordcloudGearMergeWords = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_wc_gear_merge_words",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardSwitchConceptClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_switch_concept",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardCfCreateRegion = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_cf_create_region",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardCfChangeReactions = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_cf_change_reactions",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardCfChangeRegionSettings = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_cf_region_settings",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonScorecardCfChangeOpacity = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_scorecard_cf_change_opacity",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonVerbatimsExportClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_verbatims_export_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonDisqualifiedExportClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_disqualified_export_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonConceptQuotaDetailsExportClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_quota_details_export",
    "label",
    { ...researcherParams, ...params }
  );
};

const comparisonReportTourClick = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "comparison_report_tour_click",
    "label",
    { ...researcherParams, ...params }
  );
};

const respondentStartSurvey = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "respondent_start_survey",
    "label",
    { ...respondentParams, ...params }
  );
};

const respondentQualified = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "respondent_qualified",
    "label",
    { ...respondentParams, ...params }
  );
};

const respondentCompletedSurvey = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "respondent_completed_survey",
    "label",
    { ...respondentParams, ...params }
  );
};

const respondentFailedSurvey = (trackingId, params = {}) => {
  trackGoogleAnalyticsEvent(
    trackingId,
    "category",
    "respondent_failed_survey",
    "label",
    { ...respondentParams, ...params }
  );
};

export {
  sidebarRecentClick, sidebarLogoClick, sidebarBuzzbackTravelClick,
  sidebarVerizonTravelClick, sidebarProfileClick, sidebarExpressReportClick,
  sidebarTagManagerClick, sidebarEntityClick, profileUpdateClick,
  creditsAddSubAccount, creditsRemoveSubAccount, creditsAdd,
  creditsRemove, allProjectsGridViewClick, allProjectsListViewClick,
  allProjectsSortTitleClick, allProjectsSortTypeClick, allProjectsSortClientClick,
  allProjectsSortCreatedAtClick, allProjectsSortInFieldAtClick,
  allProjectsSortClosedAtClick, allProjectsSortStatusClick,
  allProjectsSortCompletesClick, allProjectsSortTargetCompletesClick,
  projectSetupCustomizeQualifyMessageClick, projectSetupForceMarketplaceClick,
  projectPreviewReportTourClick, reviewRespondentsReviewClick, reviewRespondentsBanClick,
  reviewRespondentsExportClick, comparisonExportConceptsForAverage, comparisonCompareToDatabaseClick,
  comparisonCompareClientDatabaseClick, comparisonCompareCategoryDatabaseClick,
  comparisonViewChartsClick, comparisonCompareAddClient, comparisonFiltersAddComplexFilter,
  comparisonCustomizeViewSortByChange, comparisonCustomizeViewShowAsChange,
  comparisonScorecardWordcloudPlayAnswer, comparisonScorecardWordcloudWordClick,
  comparisonScorecardWordcloudShowSentimentsChange, comparisonScorecardWordcloudGearClick,
  comparisonScorecardWordcloudPinAnswer, comparisonScorecardWordcloudShowHideAnswer,
  comparisonScorecardWordcloudGearShowHideWord, comparisonScorecardWordcloudGearMergeWords,
  comparisonScorecardSwitchConceptClick, comparisonScorecardCfCreateRegion,
  comparisonScorecardCfChangeReactions, comparisonScorecardCfChangeRegionSettings,
  comparisonScorecardCfChangeOpacity, comparisonVerbatimsExportClick,
  comparisonDisqualifiedExportClick, comparisonConceptQuotaDetailsExportClick,
  comparisonReportTourClick, respondentStartSurvey, respondentQualified,
  respondentCompletedSurvey, respondentFailedSurvey
};
