import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../contexts/appContexts';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Header from "../components/header";

const Notifications = ({ nextStep, skipStep, headerStep, numberOfHeaderSteps }) => {
  const { mergeData } = useContext(GlobalContext);

  useEffect(() => {
    mergeData({ sendNotificationsShown: true });
  }, []);

  const handleNext = () => {
    mergeData({ sendNotificationsSkip: false });
    nextStep();
  };

  const handleSkip = () => {
    mergeData({ sendNotificationsSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      <button
        className="button -full-width"
        onClick={ handleNext }
      >
        Turn On Notifications
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  return (
    <Layout header={ header } footer={ footer }>

      <div className="base-intent">
        <div className="content_title">
          <h1>Get updates about your money</h1>
        </div>
        <div className="content_text">
          Stay on top of your spending and rewards.
        </div>
        <div className="svg-body">
          <svg className={ `notifications-svg` }>
            <use xlinkHref={ `#svg_notifications` } />
          </svg>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;


