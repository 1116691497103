import React from 'react';
import { getLinesCount } from './statistics';

const lineNameList = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth"
};

export const Subheader = ({ currentLine, desktop }) => {
  const linesCount = getLinesCount();

  const lineName = linesCount > 1
    ? (<span className="red-highlight">
      { lineNameList[currentLine] } line
    </span>)
    : 'line';
  return (
    <div className="subheader">
      <div className="inner-content -base">
        <h2 className="subheader-primary">
          Let’s choose a plan for&nbsp;your
          { ' ' }
          { lineName }.
        </h2>
        { desktop ? (
          <h6 className="subheader-secondary information-icon">
            Enroll in Auto Pay and paper-free billing to save an additional $10/mo on&nbsp;these plans.
          </h6>
        ) : (
          <h6 className="subheader-secondary information-icon">
            Enroll in Auto Pay and paper-free billing after account creation to save an additional $10/mo on&nbsp;these plans.
          </h6>
        ) }

      </div>
    </div>
  );
};
