import React from 'react';
import classnames from 'classnames';

const Checkbox = ({ title, value, onChange, className, disabled }) => {
  const id = `${title}`;
  const handleOnChange = () => {
    if (disabled) {
      return;
    }
    onChange(!value);
  };

  return (
    <div className="form_group">
      <div className="checkbox">
        <input
          id={ id }
          type="checkbox"
          checked={ value }
          onChange={ () => {} }
        />
        <label
          className={ classnames("checkbox_label", className, { '-disabled': disabled }) }
          htmlFor={ id }
          onClick={ handleOnChange }
        >
          { title }
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
