import React, { useState, useEffect, useContext } from "react";
import CreatableSelect from 'react-select/creatable';
import { map, filter } from "lodash";
import { IndexData } from "../../../index-context";
import { comparisonScorecardWordcloudGearMergeWords } from "../../../../../common/google-analytics/events";

const MergeName = ({ keywords, merge, deselectAll, conceptId }) => {
  const { init } = useContext(IndexData);
  const options = map(filter(keywords, (el1) => el1.checked), (el) => ({ label: el.text, value: el.text }));
  const [ selected, setSelected ] = useState(options[0]);
  const [ manual, setManual ] = useState(false);

  const handleChange = (newValue) => {
    setSelected(newValue);
    setManual(true);
  };

  const onMerge = () => {
    comparisonScorecardWordcloudGearMergeWords(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: conceptId });
    merge(selected.value);
  };

  useEffect(() => {
    const tempOptions = map(filter(keywords, (el1) => el1.checked), (el) => ({ label: el.text, value: el.text }));
    if (!manual) {
      setSelected(tempOptions[0]);
    }
  }, [ keywords ]);

  return (
    <div className="keywords-control -no-border">
      <div className="keywords-control_main">
        <CreatableSelect
          value={ selected }
          options={ options }
          formatCreateLabel={ (v) => (`Use "${v}"`) }
          onChange={ handleChange }
        />
      </div>
      <div className="keywords-control_addon">
        <div className="keywords-control_addon-child">
          <div className="button-group -no-width">
            <button className="button -js-merge" onClick={ onMerge }>
              Merge
            </button>
            <button className="button -outline" onClick={ deselectAll }>
              Deselect All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MergeName;
