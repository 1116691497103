const expressRespondentHash = (baseUrl) => {
  const url = [ baseUrl, "respondent" ].join("/");
  return {
    thoughtBubble: {
      manImage: `${url}/claim-random-bg-man.png`,
      womanImage: `${url}/claim-random-bg-woman.png`,
      packageMcImage: `${url}/claim-random-package-mc.png`,
      bubbleTailImage: `${url}/bubble-tail.png`
    }
  };
};

export default expressRespondentHash;
