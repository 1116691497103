import React, { useEffect, useContext } from 'react';
import { includes, map } from "lodash";
import { IndexData } from "../index-context";
import ExpressOpenEndCard from "./express-open-end-card/index";

const ConceptTabWordcloud = (
  {
    current, wcFilter, pinCount, customTitle, answerHelp,
    answerHelpFor, pinTitle, unpinTitle, isAdmin, wcLoading,
    loadWords, loadComments, noConceptFocus
  }) => {
  const { selected } = useContext(IndexData);

  useEffect(() => {
    loadWords(true);
  }, [ current ]);

  const helpText = (index) => {
    if (!answerHelpFor) {
      return answerHelp;
    }
    const split = map(answerHelpFor.split(','), (el) => parseInt(el, 10));
    return includes(split, index) ? answerHelp : '';
  };

  return (
    <div className="concept-report_tab wordcloud">
      { wcLoading && <div className="page-loader -inner-loader" /> }

      {
        !wcLoading &&
        <>
          {
            !noConceptFocus &&
            <>
              <h3 className="concept-focus-main-title">
                Concept Focus - Word Cloud
              </h3>
              {
                map(selected, (card, i) => {
                  const customTitleTag = customTitle(
                    card.metric.reaction, card.data.respondents_number
                  );
                  return (
                    <div
                      key={ card.metric.id }
                      className="concept-report_section"
                    >
                      <ExpressOpenEndCard
                        metric={ card.metric }
                        cardData={ card.data }
                        mode="editing"
                        filter={ wcFilter }
                        pinCount={ pinCount }
                        customTitle={ customTitleTag }
                        answerHelp={ helpText(i) }
                        pinTitle={ pinTitle }
                        unpinTitle={ unpinTitle }
                        isAdmin={ isAdmin }
                        loadWords={ loadWords }
                        loadComments={ loadComments }
                      />
                    </div>
                  );
                })
              }
            </>
          }
        </>
      }
    </div>
  );
};

export default ConceptTabWordcloud;
