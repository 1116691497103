import React, { useContext } from 'react';
import cn from 'classnames';
import { includes } from "lodash";
import { IndexData } from "../index-context";

const ComparisonsLegend = ({ forModal = false, statTesting, averageMode, overviewModal = false, view = "internal" }) => {
  const { init } = useContext(IndexData);
  if (forModal && !includes([ 'average', 'quintiles' ], statTesting)) {
    return null;
  }

  return (
    <div className={ cn("comparisons_legend", { "-scorecard": forModal }) }>
      {
        statTesting === 'average' &&
        <>
          {
            averageMode === 'project' &&
            <div className="comparisons_legend-title">
              Compared to other items in this project
            </div>
          }
          {
            averageMode === 'client' &&
            <div className="comparisons_legend-title">
              Compared to same category items for client
            </div>
          }
          {
            averageMode === 'platform' &&
            <div className="comparisons_legend-title">
              Compared to same category items in the database
            </div>
          }
          <div className="comparisons_legend-grid">
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-above">
                Green
              </div>
              <div className="comparisons_legend-text">
                Statistically higher than average
              </div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-neutral">
                Gray
              </div>
              <div className="comparisons_legend-text">
                Statistically at parity with average
              </div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-below">
                Red
              </div>
              <div className="comparisons_legend-text">
                Statistically lower than average
              </div>
            </div>
          </div>
        </>
      }

      {
        statTesting === 'each' &&
        <>
          <div className="comparisons_legend-letters">
            <div className="letter-item">
              <b>Uppercase letters</b> indicate a significantly higher percentage than the referenced column(s) at the 95%
              confidence level.
            </div>
            <div className="letter-item">
              <b>Lowercase letters</b> indicate a significantly higher percentage than the referenced column(s) at the 90%
              confidence level.
            </div>
          </div>
        </>
      }

      {statTesting === 'quintiles' &&
        <>
          {
            averageMode === 'client' &&
            <div className="comparisons_legend-title">
              Compared to same category items for client
            </div>
          }
          {
            averageMode === 'platform' &&
            <div className="comparisons_legend-title">
              Compared to same category items in the database
            </div>
          }
          <div className="comparisons_legend-grid">
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-above" />
              <div className="comparisons_legend-text">
                Top 20%
              </div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-top_40" />
              <div className="comparisons_legend-text">
                Top 40%
              </div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-middle_20" />
              <div className="comparisons_legend-text">
                Middle 20%
              </div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-bottom_40" />
              <div className="comparisons_legend-text">
                Bottom 40%
              </div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label -color-bottom_20" />
              <div className="comparisons_legend-text">
                Bottom 20%
              </div>
            </div>
            {overviewModal && ((init.isInternal && view !== "client") || averageMode !== 'platform') &&
              <div className="comparisons_legend-grid-item">
                <div className="comparisons_legend-label -empty-gray" />
                <div className="comparisons_legend-text -bold">
                  { averageMode === 'client' ? "Client Average" : "Category Average" }
                </div>
              </div>
            }
          </div>
        </>
      }

      {
        averageMode !== 'project' &&
        <div className="comparisons_legend-text -center">
          <br />
          * Custom questions / attributes are not included in the Database Comparison
        </div>
      }
    </div>
  );
};

export default ComparisonsLegend;
