import React, { useContext, useEffect, useRef } from 'react';
import { IndexData } from "../../index-context";
import ConceptTags from '../concept-tags';
import ThImagePopup from "./th-image-popup";
import ThTextPopup from "./th-text-popup";

const Th = ({ alphabet, concept, index }) => {
  const { data, openEntityModal, closeOverviewModal, getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs(
    (!index ? "projectFirstComparisonItem" : null),
    tourElement
  );
  useEffect(callback, condition);

  const openScorecardModal = () => {
    closeOverviewModal();
    openEntityModal(data, concept);
  };

  return (
    <th className="-with-concept" ref={ tourElement }>
      <ConceptTags concept={ concept } />
      {
        concept.image_present &&
        <ThImagePopup
          concept={ concept }
          openScorecardModal={ openScorecardModal }
        />
      }
      {
        !concept.image_present &&
        <ThTextPopup
          concept={ concept }
          openScorecardModal={ openScorecardModal }
          alphabet={ alphabet }
        />
      }
      {
        alphabet &&
        <div className="table-comparison_concept-letter">
          { alphabet[concept.id] }
        </div>
      }
    </th>
  );
};

export default Th;
