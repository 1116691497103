import { find } from "lodash";

const hashToId = (field, hash) => {
  //  #!?concept_id=1187&api=123 => 1187
  const pp1 = hash.split('?');
  if (!pp1.length) {
    return null;
  }

  const pp2 = pp1.last().split('&');
  if (!pp2.length) {
    return null;
  }

  const pp3 = find(pp2, (p) => (p.startsWith(field)));
  if (!pp3) {
    return null;
  }

  const pp4 = pp3.split('=');

  return parseInt(pp4[1]);
};

export { hashToId };
