import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import renderRawHtml from '../../../../../../common/render_raw_html';

const ModalWindow = ({ children, left, top }) => {
  const body = document.body || document.querySelector('body');
  const [ element ] = useState(() => (document.createElement('div')));

  useEffect(() => {
    element.setAttribute(
      'style',
      `left: ${left}px; top: ${top}px; position: absolute; z-index: 9999`
    );
    body.appendChild(element);
    return () => {
      body.removeChild(element);
    };
  }, []);

  return createPortal(children, element);
};

const DownloadPopup = ({ left, top, offsetX, customText, onShowList, onClose }) => {
  const [ messageSize ] = useState({ width: 300, height: 74 });
  const findPlacement = () => (
    {
      top: (top - messageSize.height / 2) > 0 ? (top - messageSize.height / 2) : top,
      left: (offsetX - messageSize.width) > 0 ? (left - messageSize.width) : left
    }
  );
  const [ placement, setPlacement ] = useState(findPlacement);

  useEffect(() => {
    setPlacement(findPlacement());
  }, [ left, top ]);

  const handleShowList = (event) => {
    onShowList(event);
  };

  const handleClose = (event) => {
    onClose(event);
  };

  const elementInNodesArray = (event, el) => {
    let curEl = event.target;
    const bodyEl = document.body || document.querySelector('body');
    let found = false;

    while (curEl !== bodyEl) {
      if (!found) {
        found = (curEl === el);
      }
      curEl = curEl.parentNode;
    }

    return found;
  };

  const topEl = useRef(null);
  useEffect(() => {
    const bodyEl = document.body || document.querySelector('body');
    const monitorClickOutside = (event) => {
      if (!elementInNodesArray(event, topEl.current)) {
        handleClose();
      }
    };

    bodyEl.addEventListener('mousedown', monitorClickOutside);
    return () => {
      bodyEl.removeEventListener('mousedown', monitorClickOutside);
    };
  }, []);

  return (
    <ModalWindow left={ placement.left } top={ placement.top }>
      <div onClick={ handleShowList } ref={ topEl }>
        <div className="tooltip -contains-help fade top-left in" role="tooltip" />
        <div className="tooltip-arrow">
          <div className="tooltip-inner -no-max-width" style={ { width: messageSize.width } }>
            <div className="tooltip-inner_content">
              <div className="tooltip-inner_icon-wrap">
                <svg className="tooltip-inner_icon icon -menu-downloads">
                  <use xlinkHref="#svg_menu-downloads" />
                </svg>
              </div>
              {
                customText ?
                  (
                    <div className="tooltip-inner_text" { ...renderRawHtml(customText) } />
                  ) : (
                    <div className="tooltip-inner_text">
                      We have started to prepare your report for download
                    </div>
                  )
              }

              <div className="tooltip-inner_buttons" onClick={ handleClose }>
                <span className="tooltip-inner_close icon-link -no-text -gray" role="button" title="Close">
                  <svg className="icon-link_icon icon -i-cross"><use xlinkHref="#svg_i-cross" /></svg>
                  <span className="icon-link_text"> Close </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default DownloadPopup;
