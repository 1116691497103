import React, { useContext } from "react";
import { averageCountWithFlag, n0 } from '../../../helpers';
import { IndexData } from '../../../index-context';

const ShelfEvaluationShelfNsizes = ({ current }) => {
  const { init, data, averageMode } = useContext(IndexData);
  const [ averageCountNum, hasValueNum ] = averageCountWithFlag(
    n0(data.shelf_avg_num), averageMode, init.isInternal, data.view
  );

  return (
    <>
      <tr className="shelf-scorecard-nsizes">
        <td className="table-comparison_name-cell" colSpan={ 2 }>{ data.titles.n }</td>
        <td className="-js-n">({current.shelf_counters.total_count})</td>
        <td className="table-comparison_average-cell">
          { hasValueNum && '(' }
          { averageCountNum }
          { hasValueNum && ')' }
        </td>
      </tr>
    </>
  );
};

export default ShelfEvaluationShelfNsizes;
