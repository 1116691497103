import React, { useContext } from 'react';
import { map } from "lodash";
import { ModalContext } from './contexts';

const AllPlansIncludeWithModal = ({ plans }) => {
  const { openModal } = useContext(ModalContext);

  const onClick = () => {
    openModal({
      title: 'All plans include',
      data: {
        listDetails: plans
      },
      showFooterButton: true
    });
  };
  return (
    <div className="all-plans">
      <h4 className="all-plans_title">All plans include</h4>
      <ul className="all-plans_list">
        {
          map(plans, (plan, idx) => (
            <li className="all-plans_item" key={ `all-plans-${idx}` }>
              { plan.title }
            </li>
          ))
        }
      </ul>
      <div className="all-plans_more">
        <button
          className="button-link -inherit"
          onClick={ onClick }
        >
          Review details
        </button>
      </div>
    </div>
  );
};

export default AllPlansIncludeWithModal;

