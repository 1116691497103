import React, { useContext } from 'react';
import { IndexData } from "../../index-context";
import {
  comparisonCompareCategoryDatabaseClick,
  comparisonCompareClientDatabaseClick
} from "../../../../common/google-analytics/events";

const ClientCategoryToggle = ({ compareMode, data, onChangeCompareMode }) => {
  const { init } = useContext(IndexData);
  const onModeChange = (mode) => {
    if (mode === "client") {
      comparisonCompareClientDatabaseClick(init.google_analytics_id, { project_id: data.project_id });
    } else {
      comparisonCompareCategoryDatabaseClick(init.google_analytics_id, { project_id: data.project_id });
    }
    onChangeCompareMode(mode);
  };

  return (
    <div className="radio-tabs">
      <label className="radio-tabs_item -width-1-2">
        <input
          id="client"
          name="client"
          checked={ compareMode === "client" }
          disabled={ !data.client_cmp_enabled }
          onChange={ () => {
            onModeChange("client");
          }
          }
          type="radio"
        />
        <span className="radio-tabs_label -normal-font -width-1-1 -centered">
          <span>Client Database</span>
        </span>
      </label>

      <label className="radio-tabs_item -width-1-2">
        <input
          id="platform"
          name="platform"
          checked={ compareMode === "platform" }
          disabled={ !data.category_cmp_enabled }
          onChange={ () => {
            onModeChange("platform");
          }
          }
          type="radio"
        />
        <span className="radio-tabs_label -normal-font -width-1-1 -centered">
          <span>Category Database</span>
        </span>
      </label>
    </div>
  );
};

export default ClientCategoryToggle;
