import React, { useState, useEffect } from 'react';
import { sendCustomSubmitButtonMessage } from '../navigation';
import Modal from '../../../../common/components/modal';

const FormCheckScreener = ({ needCheck, errors, pageUrl, closed, modalModifier }) => {
  const [ logicCheck, setLogicCheck ] = useState(needCheck);
  const [ showDialog, setShowDialog ] = useState(() => (
    needCheck && errors.length === 1 && errors[0] === 'project_check_screener_logic_failed'
  ));
  const [ sendForm, updateSendForm ] = useState(null);
  const hiddenValue = logicCheck ? '1' : '0';

  useEffect(() => {
    if (sendForm) {
      sendCustomSubmitButtonMessage();
    }
  }, [ sendForm ]);

  const handleOnClose = () => {
    setShowDialog(false);
  };

  const handleOkClick = () => {
    setLogicCheck(false);
    updateSendForm({});
  };

  const handleOnRollback = () => {
    location.href = pageUrl;
  };
  return (
    <>
      {
        showDialog &&
        <Modal dialogClassName={ modalModifier } backDropModifier={ modalModifier }>
          <h2 className="normal-popup-title">
            WARNING:
            {' '}
            {
              closed ? (
                <>The survey is closed,</>
              ) : (
                <>The survey is actively fielding,</>
              )
            }
            {' '}
            changes made now could cause inconsistent data results.
          </h2>
          <div className="normal-popop-description">
            "Save" button will save the updated data to a server,
            {' '}
            click only if you are really sure about the decision.
            <br />
            "Rollback changes" button will reload the page with the data from server.
            <br />
            "Close popup" button will close the popup and leave the changes in form without saving them.
          </div>
          <div className="normal-popup-buttons">
            <button className="button" type="button" onClick={ handleOkClick }>
              Save
            </button>
            <button className="button -secondary" type="button" onClick={ handleOnRollback }>
              Rollback changes
            </button>
            <button className="button -secondary" type="button" onClick={ handleOnClose }>
              Close popup
            </button>
          </div>
        </Modal>
      }
      {
        needCheck &&
        <input type="hidden" name="project[project_check_screener_logic_change]" value={ hiddenValue } />
      }
    </>
  );
};

export default FormCheckScreener;
