import React from 'react';
import QuantPortal from './portal';
import Article from './article';

const renderArticle = (article, idx) => <Article key={idx} article={article} />

const PerkDetailsDescription = ({ title, articles }) => <div className="plan-details">
  {articles.map(renderArticle)}
</div>

const renderPerkDetailsDesciption = ({ title, articles }, idx) => 
  <PerkDetailsDescription key={idx} title={title} articles={articles} />

const PlanPerksModal = ({ open, handleClose, plan }) => {
  if (!open) return null;
  
  const { name, description } = plan;

  return <QuantPortal title={name} open={open} handleClose={handleClose}>
    {description.map(renderPerkDetailsDesciption)}
  </QuantPortal>
};

export default PlanPerksModal;