import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import cn from "classnames";
import { getMetricKind, getMetricTitle, IndexData } from "../../index-context";
import StatusBar from "../../components/status-bar";
import StatusCircle from "../../components/status-circle";
import { hideCustom, showedAverageValue } from '../../helpers';

const TableFivePointsScales = ({
  data, current, fivePointScales, groupName,
  className, withBar = false, withN = true
}) => {
  const nText = withN ? `, N=${current.five_pt_scale_count}` : '';
  const { averageMode, init } = useContext(IndexData);

  return (
    <table className={ cn("table table-comparison", className) }>
      <thead>
        <tr>
          <th
            className="table-comparison_name-cell"
            colSpan={ withBar ? 4 : 3 }
          >
            <div className={ "table-comparison_title" }>
              { getMetricKind(data, groupName) }{ nText }
            </div>
          </th>
          <th className="table-comparison_average-cell">
            Avg
          </th>
        </tr>
      </thead>
      <tbody>
        {
          map(fivePointScales, (values, title) => (
            <Fragment key={ `scorecard_five_${title}` }>
              <tr>
                <td rowSpan="2" className="table-comparison_name-cell">
                  { getMetricTitle(data, title) }
                </td>
                <td>
                  <div className="table-comparison_box-cell">T2B</div>
                </td>
                {
                  withBar &&
                  <td className="-max-width">
                    <StatusBar
                      values={ values[current.id] }
                      box="top2"
                      statTesting={ data.stat_testing }
                    />
                  </td>
                }
                <td>
                  <StatusCircle
                    values={ values[current.id] }
                    box="top2"
                    noLetters
                    hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                  />
                </td>
                <td className="table-comparison_average-cell">
                  { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
                </td>
              </tr>
              <tr>
                <td>
                  <div className="table-comparison_box-cell">TB</div>
                </td>
                {
                  withBar &&
                  <td className="-max-width">
                    <StatusBar
                      values={ values[current.id] }
                      box="top"
                      statTesting={ data.stat_testing }
                    />
                  </td>
                }
                <td>
                  <StatusCircle
                    values={ values[current.id] }
                    box="top"
                    noLetters
                    hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                  />
                </td>
                <td className="table-comparison_average-cell">
                  { showedAverageValue(values, averageMode, false, init.isInternal, data.view, "top") }
                </td>
              </tr>
            </Fragment>
          ))
        }
      </tbody>
    </table>
  );
};

export default TableFivePointsScales;
