const defaultMaxConcepts = 40;
const defaultMaxConceptsAdmin = 100;
const defaultConceptsTurfMax = 15;
const defaultMinConcepts = 2;
const defaultMaxClaims = 30;
const defaultMinClaims = 10;
const defaultMaxPerRespondent = 10;

const swipeNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  }
};

const claimNumbers = {
  claimsMax: {
    admin: defaultMaxClaims,
    express: defaultMaxClaims
  },
  claimsMin: {
    admin: defaultMinClaims,
    express: defaultMinClaims
  }
};

const conceptsTestNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  },
  itemsMin: {
    admin: defaultMinConcepts,
    express: defaultMinConcepts
  }
};

const namingNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  },
  itemsSeqMin: {
    admin: defaultMinConcepts,
    express: defaultMinConcepts
  },
  itemsMin: {
    admin: 1,
    express: 1
  }
};

const flavorNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  },
  itemsMin: {
    admin: defaultMinConcepts,
    express: defaultMinConcepts
  },
  itemsTurfMax: {
    admin: defaultConceptsTurfMax,
    express: defaultConceptsTurfMax
  },
  claimsMax: {
    admin: defaultMaxClaims,
    express: defaultMaxClaims
  },
  claimsMin: {
    admin: defaultMinClaims,
    express: defaultMinClaims
  }
};

const packageNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  },
  itemsMin: {
    admin: defaultMinConcepts,
    express: defaultMinConcepts
  }
};

const packageMcNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  }
};

const adceptNumbers = {
  itemsMax: {
    admin: defaultMaxConceptsAdmin,
    express: defaultMaxConcepts
  },
  itemsMin: {
    admin: defaultMinConcepts,
    express: defaultMinConcepts
  }
};

const brandSnapshotNumbers = {
  itemsMax: {
    admin: 0,
    express: 0
  }
};

export {
  swipeNumbers, claimNumbers, conceptsTestNumbers, namingNumbers,
  flavorNumbers, packageNumbers, packageMcNumbers, adceptNumbers,
  brandSnapshotNumbers, defaultMaxPerRespondent
};
