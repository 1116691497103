import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

const ModalWindow = ({ style, className, children }) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = className;
    element.setAttribute("style", style);
    body.appendChild(element);
    return () => {
      body.removeChild(element);
    };
  }, []);

  return createPortal(children, element);
};

const TouchModal = ({ onClose, children, customClickEventName, className }) => {
  const paramsHash = {
    [`${customClickEventName || 'onClick'}`]: (event) => {
      event.preventDefault();
      event.stopPropagation();
      onClose();
    }
  };
  return (
    <ModalWindow
      className="modal-backdrop fade in -no-transparency"
      style={ "z-index: 1040" }
    >
      <div
        className={
          classnames(
            "modal -base-modal-survey js-selectable-list-image-modal fade in",
            { [className]: !!className }
          )
        }
        style={ { zIndex: 1050 } }
        { ...paramsHash }
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button type="button" className="close fa fa-window-close" />
            <div className="modal-body">{ children }</div>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};


export default TouchModal;
