import React, { useContext } from 'react';
import { IndexData } from "../index-context";
import Modal from "../../../../common/components/modal";
import SvgIcon from "../../../../common/components/svg-icon";

const InternalViewWarningModal = () => {
  const { onInternalViewWarningModalClose, exportData, openFontPreselectionModal } = useContext(IndexData);

  const exportReport = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { exportFunction, params, showFontPreselectionModal } = exportData;

    if (showFontPreselectionModal) {
      openFontPreselectionModal(event, exportFunction, params);
    } else {
      exportFunction(event, params);
    }
    onInternalViewWarningModalClose(false);
  };

  return (
    <Modal modalSize="md2" modalClassName="centered">
      <span
        className="modal_close icon-link -no-text -gray"
        title="Close"
        role="button"
        onClick={ onInternalViewWarningModalClose }
      >
        <SvgIcon className="icon-link_icon" name="i-close" />
        <span className="icon-link_text">Close</span>
      </span>

      <div className="warning-icon">
        <div className="warning-icon-inside" />
        <div className="warning-icon-point-inside" />
      </div>

      <h3 className="warning_title" id="clientLabel">Customize View</h3>

      <div className="warning-text">
        These files include information about the <strong>Category Averages</strong> – they are for
        <span className="warning-text -red"> internal use only</span>
        . To export client-ready files, please go to Customize View and change the View to <strong>For Client</strong>.
      </div>
      <div className="warning_footer">
        <div className="warning_footer-item">
          <button className="button -next" type="button" onClick={ (e) => { exportReport(e); } }>
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default InternalViewWarningModal;
