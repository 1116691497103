import React from 'react';
import remoteFiles from '../../../../../common/remote_files';
import { adceptNumbers } from '../../express_numbers';

const ReadMoreAdcept = ({ onGetStarted }) => {
  const {
    addAds, conceptFocus, preference, thoughtBubble,
    videoAd
  } = remoteFiles.express.steps.adcept;
  const { step12x, screenerQuestion } = remoteFiles.express.steps.base;
  const { mode } = remoteFiles.express.steps.package;
  return (
    <>
      <div className="modal_body">
        <div className="express-steps">
          <h3 className="express-steps_title">Advertising</h3>
          <h2 className="express-steps_subtitle">Here’s how it works</h2>
          <div className="express-steps_list">
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Select your audience</p>
                  <p className="-descr">Choose demographics filters.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ step12x } />
                </div>
              </div>
            </div>
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Create up to 3 screener questions</p>
                  <p className="-descr">Adding screener questions may impact cost and timing.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ screenerQuestion } />
                </div>
              </div>
            </div>
            <div className="express-steps_item">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Select exercise mode</p>
                  <p className="-descr">Choose from Monadic and Sequential Monadic.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ mode } />
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-bottom-line">
              <div className="express-steps_col">
                <div className="express-steps_item-text">
                  <p>Add Ads</p>
                  <p className="-descr">You can evaluate up to {adceptNumbers.itemsMax.express} items.</p>
                </div>
              </div>
              <div className="express-steps_col">
                <div className="express-steps_item-img">
                  <img src={ addAds } />
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex -no-bottom-line">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">buzzback Projectives</h3>
                <div className="express-steps_item-description">
                  For a subset of ads randomly selected, respondents will react to buzzback
                  { ' ' }
                  projective techniques.
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Video Ad</p>
                    <p className="-descr">
                      With Video Ad, respondents will be able to watch the advertising
                      { ' ' }
                      as a preliminary part of the each item flow to get more knowledge
                      { ' ' }
                      about the evaluated item.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow -with-padding -white">
                    <img src={ videoAd } />
                  </div>
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Thought Bubble</p>
                    <p className="-descr">
                      With Thought Bubble, respondents describe the ad in three words.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img">
                    <img src={ thoughtBubble } />
                  </div>
                </div>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Concept Focus</p>
                    <p className="-descr">
                      With Concept Focus, respondents mark up what they like and dislike and
                      { ' ' }
                      describe why.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img">
                    <img src={ conceptFocus } />
                  </div>
                </div>
              </div>
            </div>
            <div className="express-steps_item -no-flex">
              <div className="express-steps_header-wrapper">
                <h3 className="express-steps_item-header">Preference</h3>
              </div>
              <div className="express-steps_inner-col">
                <div className="express-steps_col">
                  <div className="express-steps_item-text">
                    <p>Preference question</p>
                    <p className="-descr">
                      Respondents will select their preference. Only available if {adceptNumbers.itemsMin.express}+ ads are
                      { ' ' }
                      included.
                    </p>
                  </div>
                </div>
                <div className="express-steps_col">
                  <div className="express-steps_item-img -narrow">
                    <img src={ preference } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer -bg-gray -center">
        <div className="modal_footer-button">
          <button className="button modal_button" onClick={ onGetStarted }>
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default ReadMoreAdcept;
