import React, { useState, useEffect } from 'react';
import { filter, map, slice, find, keys } from 'lodash';
import Screening from '../../screening';
import Reason from './reason';

const WithOpenEnd = ({ question, nextStep, mainResult, result }) => {
  const [ step, setStep ] = useState(0);
  useEffect(() => {
    setStep(0);
  }, [ question ]);
  const [ answers, setAnswers ] = useState({});
  const hasReasons = question.open_end_show && !!question.reasons?.length;
  const handleNextStep = () => {
    if (hasReasons && step === 0) {
      const sliceLimit = question.open_end_selected_max_display;
      const selectedIds = slice(
        filter(
          result.answers || keys(result),
          (item) => (
            typeof item == 'string' && !!item.match(new RegExp(`^${question.name}`))
          )
        ),
        0,
        sliceLimit
      );
      const selectedData = map(
        selectedIds,
        (id) => (
          {
            id,
            imageUrl: find(
              question.answer_images || [],
              (el) => el.id === id
            )?.image_url,
            text: (
              id.match(/_-2/) ?
                (result.otherAnswers || {})[question.name] :
                find(question.answers || [], (el) => el.id === id)?.label
            )
          }
        )
      );
      setAnswers(selectedData);
      setStep(1);
    } else {
      nextStep();
    }
  };

  return (
    <>
      {
        !step &&
        <Screening
          question={ question }
          nextStep={ handleNextStep }
          result={ result }
        />
      }
      {
        step === 1 && hasReasons &&
        <Reason
          question={ question.reasons[0] }
          answers={ answers }
          nextStep={ handleNextStep }
          mainResult={ mainResult }
          result={ result }
        />
      }
    </>
  );
};

export default WithOpenEnd;
