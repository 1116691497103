import React, { useState, useEffect } from 'react';
import { find, keys, each, includes, cloneDeep, isArray } from 'lodash';
import InfoToolTip from '../infoToolTip';
import {
  waitingMessageOnSizeChange, waitingMessageOnFormChange, smartUpdateProjectsData,
  calculatePrice
} from './helper';

const ProjectPrice = ({ launchData }) => {
  const options = launchData.price_data.options;
  const [ projectDataInitialKeys ] = useState(() => keys(launchData.project_data));
  // Resolves the following problem: when we add screener questions or custom metric questions and
  // then delete, we don't get the corresponding keys when the array is blank, so we need to store
  // the change log keys to pass them blank if form doesn't have them now.
  // field => type (array, object, scalar)
  const [ projectDataChangeKeys ] = useState({});
  const [ projectData, setProjectData ] = useState(
    () => smartUpdateProjectsData(launchData.project_data)
  );
  const [ priceData ] = useState(() => (launchData.price_data));
  const getCalculatedPrice = () => (calculatePrice(projectData, priceData));
  const [ price, setPrice ] = useState(() => {
    if (launchData.final_step) {
      const item = find(options, (el) => el.recommended);
      return item.price;
    }
    return getCalculatedPrice();
  });

  useEffect(() => {
    return waitingMessageOnSizeChange((nsize) => {
      const item = find(options, (el) => el.id === nsize);
      setPrice(item.price);
    });
  }, []);

  const addLostKeysToFormHash = (formHash) => {
    const newFormHash = cloneDeep(formHash);
    // Add keys to change keys list
    each(newFormHash, (formHashValue, formHashKey) => {
      if (!includes(keys(projectDataChangeKeys), formHashKey)) {
        let valueKind = 'scalar';
        if (typeof formHashValue === 'object') {
          valueKind = isArray(formHashValue) ? 'array' : 'object';
        }
        projectDataChangeKeys[formHashKey] = valueKind;
      }
    });
    // Add blank keys to form hash if it doesn't have them
    each(projectDataChangeKeys, (changeKind, changeKey) => {
      if (!newFormHash[changeKey]) {
        switch (changeKind) {
          case 'array':
            newFormHash[changeKey] = [];
            break;
          case 'object':
            newFormHash[changeKey] = {};
            break;
          default:
            newFormHash[changeKey] = null;
        }
      }
    });
    return newFormHash;
  };

  useEffect(() => {
    return waitingMessageOnFormChange(projectDataInitialKeys, (formHash) => {
      const newFormHash = addLostKeysToFormHash(formHash);
      setProjectData((oldData) => (smartUpdateProjectsData(oldData, newFormHash)));
    });
  }, []);

  useEffect(() => {
    if (projectData && !launchData.final_step) {
      setPrice(getCalculatedPrice());
    }
  }, [ projectData ]);

  const payedPrice = projectData.payed_price;
  const definedPrice = payedPrice || price;

  return (
    <div className="admin-price-group">
      <div className="price-flex">
        <div className="form-pay_price -no-height">
          <span>$ { definedPrice }</span>
        </div>
        <div className="price-info">
          {
            payedPrice ? (
              <InfoToolTip remote position="bottom-left">
                The project is purchased and launched.
              </InfoToolTip>
            ) : (
              <InfoToolTip remote position="bottom-left">
                The price of your study is determined by how many concepts or ideas you have +
                {' '}
                number of respondents + incidence.
                <br /><br />
                As you add screener questions, lower incidence studies will have higher costs.
              </InfoToolTip>
            )
          }
        </div>
      </div>
      {
        payedPrice ? (
          <p className="estimated-price">purchase total price</p>
        ) : (
          <p className="estimated-price">estimated total price</p>
        )
      }
    </div>
  );
};

export default ProjectPrice;
