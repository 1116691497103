import { createContext } from "react";
const StateContext = createContext({});
const LineDataContext = createContext({});
const SetContext = createContext({});
const PageContext = createContext({});
const CloseCarrotsContext = createContext({});
const MobileCompareContext = createContext({});
const PlanDataProvider = createContext({});
const ModalContext = createContext({});
const BackContext = createContext({});

export {
  StateContext, LineDataContext, SetContext, PageContext,
  CloseCarrotsContext, MobileCompareContext, PlanDataProvider,
  ModalContext, BackContext
};
