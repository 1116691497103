import React, {useState} from 'react';
import QuantPortal from './portal';
import PerkArticle from './perkArticle'
import Statistic from "../../statistic";

const PlanPerksModal = ({ open, handleClose, perk, perksList, filledLines,
                          isSelectable, isCheckout, planIndex, clickedLineIndex }) => {
  if (!open) return null;

  const [currentPerk, setCurrentPerk] = useState(perk);

  let lineIndex = null, isLanding = typeof filledLines === 'undefined';

  if (isSelectable) {
    lineIndex = filledLines
  }
  else if (isCheckout){
    lineIndex = typeof clickedLineIndex !== 'undefined' ? clickedLineIndex : filledLines - 1;
  }
  else {
    if (typeof filledLines !== 'undefined'){
      lineIndex = filledLines;
    }
  }

  const changePlan = (perk) => {
    setCurrentPerk(perk);
    const idx = perksList.findIndex(perksListItem => perksListItem.id === perk.id);
    // for customizating plan
    if (isSelectable) {
      Statistic.updateValue(`lines.${lineIndex}.plans.${planIndex}.perks.${idx}.DetailsInCustomizationCount`);
    } else if (isCheckout) {
      // for checkout
      Statistic.updateValue(`lines.${lineIndex}.selectedPlanPerkDetailsCount`);
    } else {
      // for landing
      if (typeof filledLines === 'undefined') {
        Statistic.updateValue(`perks.${idx}.LandingDetailsCountInPopup`);
      }
      // plan list
      else {
        Statistic.updateValue(`lines.${lineIndex}.perks.${idx}.PlansListDetailsCountInPopup`);
      }
    }
  };

  const tabs = (
    <ul className="tabs-list">
      {perksList && perksList.map((perkItem) => {
        return (
          <li className="tabs-list-item" key={perkItem.id}>
            <div className={`tabs-list-link ${currentPerk.id === perkItem.id ? '-active':''}`} onClick={() => changePlan(perkItem)}>{perkItem.title}</div>
          </li>
        )
      })}
    </ul>
  );

  return <QuantPortal tabs={tabs} open={open} handleClose={handleClose}>
    <h6 className="perk-details-title">{currentPerk.title}</h6>
    <div className="perk-details-grid">
      {currentPerk.articles.map((article, idx) => {
        return(
          <div key={`${currentPerk.id}${idx}`} className="perk-details-grid-col">
            <PerkArticle
              article={article}
              lineIndex={ lineIndex }
              isLanding={ isLanding }
              perksList={ perksList }
              perk={ currentPerk }
            />
          </div>
        );
      })}
    </div>
  </QuantPortal>
};

export default PlanPerksModal;