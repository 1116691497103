import React, { useContext, useState, useEffect } from 'react';
import { map, each, find, filter, isEmpty, cloneDeep, startsWith } from "lodash";
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import { IndexData, selectGroup, selectKind, getMetricTitle, getMetricKind } from "../../index-context";
import {
  comparisonCustomizeViewShowAsChange,
  comparisonCustomizeViewSortByChange
} from "../../../../common/google-analytics/events";
import ConceptsList from "./concepts-list";

const CustomizeViewModal = ({ onClose, plural, sortGroups = [] }) => {
  const { init, data, concepts, sort, loadData } = useContext(IndexData);

  const [ loadingSorting, setLoadingSorting ] = useState(false);
  const [ localSort, setLocalSort ] = useState(sort);
  const getLocalConcepts = () => (cloneDeep(concepts));
  const [ localConcepts, setLocalConcepts ] = useState(getLocalConcepts);
  useEffect(() => {
    setLocalConcepts(getLocalConcepts());
  }, [ concepts ]);
  const [ view, updateView ] = useState(data.view);
  const [ formLoading, setFormLoading ] = useState(false);

  const selectedIds = map(filter(localConcepts, (el1) => (el1.checked)), (el2) => (el2.id));

  const [ localSelectedIds, setLocalSelectedIds ] = useState(selectedIds);

  const sortSelectOptions = () => {
    const result = [];

    const funcResultPush = (v, k, col, index, metricKind) => {
      if (startsWith(v.name, 'QFF') && v.five_point_metric === undefined) {
        return;
      }

      if (col) {
        result.push({
          title: `${getMetricTitle(data, k)} - ${col} (${getMetricKind(data, metricKind)})`,
          value: `${k} || ${v.columns_keys[index]}`
        });
      } else {
        result.push({
          title: `${getMetricTitle(data, k)} (${getMetricKind(data, metricKind)})`,
          value: k
        });
      }
    };

    each(sortGroups, (dd) => {
      let group;
      let custom;

      if (typeof dd === "string") {
        group = selectGroup(data, dd);
      } else if (typeof dd['group_name'] === 'string') {
        group = selectGroup(data, dd['group_name'], dd['hidden']);
        custom = dd['custom'];
      } else if (typeof dd['kind'] === 'string') {
        group = selectKind(data, dd['kind']);
      } else {
        group = [];
      }

      each(group,  (v, k) => {
        if (v.columns) {
          each(v.columns, (col, index) => {
            funcResultPush(v, k, col, index, custom || v.group_name);
          });
        } else {
          funcResultPush(v, k, null, null, custom || v.group_name);
        }
      });
    });

    return result;
  };

  const sortBySelectedMetric = (event) => {
    setLoadingSorting(true);
    setLocalSort(event.target.value);

    const sortConcepts = (resultingIds) => {
      comparisonCustomizeViewSortByChange(init.google_analytics_id, { project_id: init.project.id });

      const newList = [];
      each(resultingIds, (id) => {
        const sortedElement = find(localConcepts, (el) => el.id === id);
        newList.push(sortedElement);
      });
      setLocalConcepts(newList);
    };

    Http.put(
      init.urls.sorting,
      {
        sorting: { sort: event.target.value, dir: data.sorting.dir },
        analytics: data.full_analytics,
        all_concept_ids: data.filter.all_concept_ids
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          sortConcepts(http.data);
        }
        setLoadingSorting(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const onViewChanged = (event) => {
    comparisonCustomizeViewShowAsChange(init.google_analytics_id, { project_id: init.project.id });
    updateView(event.target.value);
  };

  const saveView = () => {
    setFormLoading(true);
    each(localConcepts, (el) => {
      el.checked = localSelectedIds.findIndex(el.id) > -1;
    });

    const filter = {
      all_concept_ids: map(localConcepts, (c) => c.id),
      concept_ids: localSelectedIds,
      demographics: data.filter.demographics
    };

    loadData(filter, null, { sort: localSort, dir: data.sorting.dir }, null, { view }, () => {
      setFormLoading(false);
      onClose();
    });
  };

  const applyIsDisabled = loadingSorting || isEmpty(localSelectedIds);

  const viewOptions = [
    { value: 'internal', label: "For internal use only" },
    { value: 'client', label: "For client" }
  ];

  return (
    <Modal modalSize="md" modalContentClass="-fit-screen">
      { formLoading && <div className="page-loader -inner-loader" /> }
      <div className="modal-header -auto-height">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ onClose }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title" id="clientLabel">Customize View</h3>
      </div>

      <div className="modal-description">
        <p>Select the {plural} to include in your report. Drag them to rearrange the order.</p>
      </div>

      <div className="modal-plate">
        <div className="modal-grid">
          <div className="modal-grid_item">
            <div className="modal-plate_text">Sort by</div>
          </div>
          <div className="modal-grid_item -grow">
            <select
              className="form-field -block"
              value={ localSort }
              onChange={ sortBySelectedMetric }
            >
              <option value="">Select Metric</option>
              { map(sortSelectOptions(), (option, i) => (
                <option key={ i } value={ option.value }>{option.title}</option>
              ))}
            </select>
          </div>
          <div className="modal-grid_item">
            <div className="info-tooltip">
              <div className="info-tooltip_control">
                <SvgIcon name="i-info" />
              </div>
              <div className="info-tooltip_drop -concepts-popup">
                <div className="info-tooltip_drop-inner -concepts-popup">
                  Sort the {plural} based on performance (highest to lowest) on a specific metric.
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          init.isInternal &&
          <div className="modal-grid">
            <div className="modal-grid_item">
              <div className="modal-plate_text">Show as</div>
            </div>
            <div className="modal-grid_item -grow">
              <select
                className="form-field -block"
                value={ view }
                onChange={ onViewChanged }
              >
                { map(viewOptions, (option, i) => (
                  <option key={ `view${i}` } value={ option.value }>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="modal-grid_item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>
                <div className="info-tooltip_drop -concepts-popup">
                  <div className="info-tooltip_drop-inner -concepts-popup">
                    Sort the {plural} based on performance (highest to lowest) on a specific metric.
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div className="modal_body -relative">
        {loadingSorting && <div className="page-loader -inner-loader loading-sorting" />}
        <ConceptsList
          concepts={ localConcepts }
          setConcepts={ setLocalConcepts }
          selectedIds={ localSelectedIds }
          setSelectedIds={ setLocalSelectedIds }
          setLocalSort={ setLocalSort }
        />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="button modal_button"
          onClick={ saveView }
          disabled={ applyIsDisabled }
        >Apply</button>
        <button
          type="button"
          className="-nav-secondary button -secondary modal_button"
          onClick={ onClose }
        >Cancel</button>
      </div>

    </Modal>
  );
};

export default CustomizeViewModal;
