import React, { useContext } from 'react';
import { map } from "lodash";
import classnames from 'classnames';
import { QuestionContext } from "../contexts";
import ToggleFivePtScale from '../../../common/custom_questions/components/ToggleFivePtScale';

const QAHeader = ({ options, question, questions, setQuestions }) => {
  const onChangeAnswersOrders = (event) => {
    question.answers_order = event.target.value;
    setQuestions([ ...questions ]);
  };

  const { manageFivePtToggle, fivePtScaleMode, setFivePtScaleMode } = useContext(QuestionContext);

  return (
    <>
      {
        manageFivePtToggle &&
        <ToggleFivePtScale
          question={ question }
          questions={ questions }
          setQuestions={ setQuestions }
          manageFivePtToggle={ manageFivePtToggle }
          fivePtScaleMode={ fivePtScaleMode }
          setFivePtScaleMode={ setFivePtScaleMode }
        />
      }
      <div className="form_section-title">
        <div className="form-question_grid -title">
          <div className="form-question_grid-item -grow">
            <h3>
              { question.kind === "Grid" ? "Columns" : "Answers" }
              {
                !fivePtScaleMode && question.kind !== "Grid" &&
                <span
                  className={
                    classnames(
                      "form_inline-select",
                      `-${question.answers_order}`
                    )
                  }
                >
                  <select
                    className="js-random-order"
                    value={ question.answers_order }
                    onChange={ onChangeAnswersOrders }
                  >
                    {
                      map(options.order, (value, name) => (
                        <option key={ name } value={ name }>
                          { value }
                        </option>
                      ))
                    }
                  </select>
                </span>
              }
            </h3>
          </div>

          <div className="form-question_grid-item -buttons" />
        </div>
      </div>
    </>
  );
};

export default QAHeader;
